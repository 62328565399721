import yup from 'utils/formSchemas/common';
export const termsAndDurationSchema = yup.object({}).shape({
	executionDate: yup.mixed().nullable(true).label('Execution Date'),
	// .required('Input value for Execution Date'),
	autoRenew: yup
		.string()
		.nullable(true)
		.label('Is Auto Renew?')
		// .required('Select value for Auto-Renew')
		.oneOf(['0', '1']),
	terminationDate: yup
		.mixed()
		.nullable(true)
		.label('Termination Date')
		.when('isAutoRenew', {
			is: '0',
			then: (s) => s.required('Input value for Termination Date'),
		}),
	renewalFrequency: yup
		.string()
		.nullable(true)
		.label('Renewal Frequency')
		.oneOf([
			'ANNUALLY',
			'SEMI_ANNUALLY',
			'MONTHLY',
			'QUARTERLY',
			'EVERY_N_YEARS',
		])
		.when('isAutoRenew', {
			is: '1',
			then: (s) => s.required('Select Renewal Frequency'),
		}),
	frequencyInterval: yup
		.number()
		.min(1, 'Value should be 1 or any positive number')
		.max(100, 'The maximum value is 100')
		.nullable()
		.label('Frequency Interval (years)')
		.when('renewalFrequency', {
			is: 'EVERY_N_YEARS',
			then: (s) => s.required('Select Frequency Interval'),
		}),
});
