import React from 'react'
import styles from 'containers/ChannelManagement/Terminal/style.module.css';
import TerminalInformation from 'containers/ChannelManagement/Terminal/Form/TerminalInformation';
import TerminalIntegration from 'containers/ChannelManagement/Terminal/Form/TerminalIntegration';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from "react-hook-form";
import { TerminalDetails } from 'containers/ChannelManagement/Terminal/types';
import { terminalDetails } from 'containers/ChannelManagement/Terminal/Schema';
import { TerminalFormProps } from 'containers/ChannelManagement/Terminal/types';

const TerminalForm: React.FC<TerminalFormProps> = ({
    terminalFormValues,
    terminalInfoPayload,
    onChange,
    disabled,
    refetchTerminal,
}) => {
    const TerminalDetailsUseFormMethods = useForm<TerminalDetails>({
        mode: 'all',
        resolver: yupResolver(terminalDetails),
        defaultValues: terminalFormValues
    });

    return (
        <FormProvider {...TerminalDetailsUseFormMethods}>
            <div className={styles.container}>
                <TerminalInformation
                    terminalFormValues={terminalFormValues}
                    terminalInfoPayload={terminalInfoPayload}
                    onChange={onChange}
                    disabled={disabled}
                />
                <hr className={styles.divider} />
                <TerminalIntegration
                    terminalFormValues={terminalFormValues}
                    terminalInfoPayload={terminalInfoPayload}
                    onChange={onChange}
                    disabled={disabled}
                    refetchTerminal={refetchTerminal}
                />
            </div>
        </FormProvider>
    )
}

export default TerminalForm