import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input as Textfield } from '@salesforce/design-system-react';
import { InputProps } from './types';
import Label from 'components/Inputs/Label/Label';

/**
 * Input Component
 *
 * This component creates a text field input. It checks if the component is
 * wrapped within a FormProvider context from 'react-hook-form'. If it is,
 * it uses a Controller to link the input field to the form data. If it isn't,
 * it uses a normal Textfield component and manages its own state.
 *
 * @param {Props} props - Properties passed to the component.
 * @param {string} props.name - Name of the input field. Used for form identification.
 * @param {string} [props.label] - Label for the input field. Optional.
 * @param {boolean} [props.disabled=false] - Indicates whether the input field is disabled. Optional. False by default.
 * @param {boolean} [props.required=false] - Indicates whether the input field is required. Optional. False by default.
 * @param {string} [props.defaultValue=''] - Default value for the input field. Optional. Empty by default.
 * @returns {JSX.Element} - Rendered input component.
 */
const Input: FC<InputProps> = ({
	name,
	label,
	disabled = false,
	defaultValue = '',
	required = false,
}) => {
	const { control } = useFormContext() || {};
	const [value, setValue] = useState(defaultValue);

	const TextFieldComponent = (
		<Textfield
			id={name}
			value={control ? undefined : value}
			disabled={disabled}
			onChange={control ? undefined : (e) => setValue(e.target.value)}
		/>
	);

	if (control && name) {
		return (
			<>
				{label && <Label required={required}>{label}</Label>}
				<Controller
					name={name}
					control={control}
					defaultValue={defaultValue}
					render={({ field }) => React.cloneElement(TextFieldComponent, field)}
				/>
			</>
		);
	} else {
		return (
			<>
				{label && <Label required={required}>{label}</Label>}
				{TextFieldComponent}
			</>
		);
	}
};

export default Input;
