export const CHANNEL_ACCOUNT_NAME = 'channelAccountName';
export const CHANNEL_ACCOUNT_CODE = 'channelAccountCode';
export const BRANCH_NAME = 'branchName';
export const BRANCH_CODE = 'branchCode';
export const CHANNEL_TRANSACTION_TYPE = 'channelTransactionType';
export const CHANNEL_TYPE = 'channelType';
export const POS_TYPE = 'posType';
export const SETTLEMENT_SETUP = 'settlementSetup';
export const STORE_TYPE = 'storeType';
export const STORE_FORMAT = 'storeFormat';
export const MACHINE_LOCATION = 'machineLocation';
export const PLDT_TELLER_CODE = 'pldtTellerCode';
export const CIGNAL_TELLER_CODE = 'cignalTellerCode';
export const MERALCO_PAYMENT_CENTER_CODE = 'meralcoPaymentCenterCode';

export const LOCATION_BASE = 'locationBase';
export const COUNTRY = 'country';
export const AREA = 'area';
export const REGION = 'region';
export const STATE_OR_PROVINCE = 'stateOrProvince';
export const STATE_OR_PROVINCE_LABEL = 'State/Province';
export const MUNICIPALITY_OR_CITY = 'municipalityOrCity';
export const MUNICIPALITY_OR_CITY_LABEL = 'Municipality/City';
export const BARANGAY = 'barangay';
export const BUILDING_NAME_OR_NUMBER = 'buildingNameOrNumber';
export const BUILDING_NAME_OR_NUMBER_LABEL = 'Building Name/No.';
export const STREET = 'street';
export const ZIP_CODE = 'zipCode';

export const BRANCH_ADDRESS = 'branchAddress';
export const BRANCH_BASIC_INFORMATION = 'branchBasicInformation';
