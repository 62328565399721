export const BASE_PATH = 'v2/channels/';
export const VIEW_PRODUCT_SCOPE = 'bip.channels.view.product';
export const VIEW_CHANNEL_DETAILS_SCOPE = 'bip.channels.view';
export const CHANNEL_ENABLE_PRODUCT_TYPE = 'bip.channels.enable.product';
export const CHANNEL_DISABLE_PRODUCT_TYPE = 'bip.channels.disable.product';
export const CHANNEL_ENABLE_PARTNER = 'bip.channels.enable';
export const CHANNEL_DISABLE_PARTNER = 'bip.channels.disable';
export const CHANNES_VIEW_PARTNER_LIST = 'bip.channels.view.partner-list';

export const CHANNEL_TYPES = [
	{ id: 1, name: 'Center' },
	{ id: 2, name: 'Partner' },
	{ id: 3, name: 'Agent' },
	{ id: 4, name: 'Machine (Inside Office)' },
	{ id: 5, name: 'Machine (Outside Office)' },
	{ id: 6, name: 'Collect' },
	{ id: 7, name: 'Digital (Online/Mobile App)' },
	{ id: 8, name: 'Financial Institution' },
];

export const FEE_COLUMNS = [
	{
		Header: 'Subsidize Fee',
		accessor: 'subsidized_fee',
	},
	{
		Header: 'Pass-On Fee',
		accessor: 'pass_on_fee',
	},
	{
		Header: 'Total Fixed Rate',
		accessor: 'total_fixed_rate',
	},
	{
		Header: 'Non-shareable Fee',
		accessor: 'non_share_fixed_rate',
		width: '200px',
	},
	{
		Header: 'Total Amount for SF Share',
		accessor: 'total_amount_for_sf_share',
		width: '200px',
	},
];

export const REGULAR_PERCENTAGE_COLUMNS = [
	{
		Header: 'Partner Share',
		accessor: 'partner_share',
	},
	{
		Header: 'Bayad Share',
		accessor: 'bayad_share',
	},
];

export const RATE_COLUMNS = [
	{
		Header: 'Subsidized Rate',
		accessor: 'subsidized_rate',
	},
	{
		Header: 'Pass-On Rate',
		accessor: 'pass_on_rate',
	},
	{
		Header: 'Total Percentage Rate',
		accessor: 'total_percentage_rate',
	},
	{
		Header: 'Non-shareable Percentage',
		accessor: 'non_share_percentage',
		width: '200px',
	},
	{
		Header: 'Total Percentage for SF Share',
		accessor: 'total_amount_for_sf_share_rate',
		width: '200px',
	},
];

export const TIER_COLUMNS = [
	{
		Header: 'Tier',
		accessor: 'tier_number',
	},
	{
		Header: 'Lower Limit',
		accessor: 'lower_limit',
	},
	{
		Header: 'Upper Limit',
		accessor: 'upper_limit',
	},
	{
		Header: 'Subsidize Fee',
		accessor: 'subsidized_fee',
	},
	{
		Header: 'Pass-On Fee',
		accessor: 'pass_on_fee',
	},
	{
		Header: 'Total Fixed Rate',
		accessor: 'total_fixed_rate',
	},
	{
		Header: 'Non-shareable Fixed Rate',
		accessor: 'non_share_fixed_rate',
		width: '200px',
	},
	{
		Header: 'Total Amount for SF Share',
		accessor: 'total_amount_for_sf_share',
		width: '200px',
	},
];

export const TIER_RATE_COLUMNS = [
	{
		Header: 'Tier',
		accessor: 'tier_number',
	},
	{
		Header: 'Lower Limit',
		accessor: 'lower_limit',
	},
	{
		Header: 'Upper Limit',
		accessor: 'upper_limit',
	},
	{
		Header: 'Subsidize Rate',
		accessor: 'subsidized_rate',
	},
	{
		Header: 'Pass-On Rate',
		accessor: 'pass_on_rate',
	},
	{
		Header: 'Total Fixed Percentage',
		accessor: 'total_percentage_rate',
		width: '200px',
	},
	{
		Header: 'Non-shareable Fixed Percentage',
		accessor: 'non_share_percentage',
		width: '250px',
	},
	{
		Header: 'Total Amount for SF Share',
		accessor: 'total_amount_for_sf_share_rate',
		width: '200px',
	},
];

export const CHANNEL_TYPE_COLUMNS = [
	{
		Header: 'Channel Type',
		accessor: 'channel_type_id',
	},
	{
		Header: 'Subsidized Fee',
		accessor: 'subsidized_fee',
	},
	{
		Header: 'Pass-On Fee',
		accessor: 'pass_on_fee',
	},
	{
		Header: 'Total Fixed Rate',
		accessor: 'total_fixed_rate',
		width: '200px',
	},
	{
		Header: 'Non-shareable Fixed Rate',
		accessor: 'non_share_fixed_rate',
		width: '200px',
	},
	{
		Header: 'Total Amount for SF Share',
		accessor: 'total_amount_for_sf_share',
		width: '200px',
	},
];

export const CHANNEL_TYPE_RATE_COLUMNS = [
	{
		Header: 'Channel Type',
		accessor: 'channel_type',
	},
	{
		Header: 'Subsidized Rate',
		accessor: 'subsidized_rate',
	},
	{
		Header: 'Pass-On Rate',
		accessor: 'pass_on_rate',
	},
	{
		Header: 'Total Percentage Rate',
		accessor: 'total_percentage_rate',
		width: '200px',
	},
	{
		Header: 'Non-shareable Percentage',
		accessor: 'non_shareable_percentage',
		width: '200px',
	},
	{
		Header: 'Total Percentage for SF Share',
		accessor: 'total_amount_for_sf_share_rate',
		width: '200px',
	},
];

export const TIER_COLUMNS_FIELD = [
	{
		Header: 'Tier',
		accessor: 'tier_number',
	},
	{
		Header: 'Lower Limit',
		accessor: 'lower_limit',
	},
	{
		Header: 'Upper Limit',
		accessor: 'upper_limit',
	},
	{
		Header: 'Partner Share',
		accessor: 'partner_share',
	},
	{
		Header: 'Bayad Share',
		accessor: 'bayad_share',
	},
];

export const CHANNEL_TYPE_COLUMS_FIELD = [
	{
		Header: 'Channel Type',
		accessor: 'channel_type_id',
	},
	{
		Header: 'Partner Share',
		accessor: 'partner_share',
	},
	{
		Header: 'Bayad Share',
		accessor: 'bayad_share',
	},
];
