import PrimaryButton from 'components/Buttons/PrimaryButton';
import SuccessModalOriginal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import { SuccessModalProps } from 'containers/ChannelManagement/Branch/BranchForm/SuccessModal/types';
import styles from './SuccessModal.module.css';

const SuccessModal: React.FC<SuccessModalProps> = ({
	open,
	onClose,
	successMessage,
	onOkay,
	okayLabel = 'Okay',
}) => {
	return (
		<>
			<SuccessModalOriginal open={open} onClose={onClose}>
				<SuccessModalBody>
					<SuccessText>
						<div className={styles.successHeader}>Success!</div>
						<div className={styles.successBody}>{successMessage}</div>
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.successModalBtn}
						onClick={() => onOkay?.()}
					>
						{okayLabel}
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModalOriginal>
		</>
	);
};

export default SuccessModal;
