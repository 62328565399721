import client from 'helpers/ApiClient';
import { UseQueryResult, useQueries, useQuery } from 'react-query';
import {
	GET_CHANNEL_DETAILS_PRIMARY_INFORMATION,
	GET_CHANNEL_DETAILS_CONTRACT_INFO,
	GET_CHANNEL_DETAILS_REPORT_INFO,
	GET_BRANCH_DETAILS,
	GET_TERMINAL_DETAILS,
	GET_CHANNEL_AUTO_SAVE,
	GET_BRANCH_AUTO_SAVE,
	GET_TERMINAL_AUTO_SAVE,
} from './const';
import { BranchResponseType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/types';

export const useGetChannelDetails = (channelId: number) => {
	return useQueries([
		{
			queryKey: [GET_CHANNEL_DETAILS_PRIMARY_INFORMATION, channelId],
			queryFn: async () => {
				const response = await client.get(
					`v2/channels/${channelId}/primary-info`
				);
				return response.data;
			},
			enabled: !!channelId,
		},
		{
			queryKey: [GET_CHANNEL_DETAILS_CONTRACT_INFO, channelId],
			queryFn: async () => {
				const response = await client.get(
					`v2/channels/${channelId}/contract-details`
				);
				return response.data;
			},
			enabled: !!channelId,
		},
		{
			queryKey: [GET_CHANNEL_DETAILS_REPORT_INFO, channelId],
			queryFn: async () => {
				const response = await client.get(`v2/channels/${channelId}/reports`);
				return response.data;
			},
			enabled: !!channelId,
		},
	]);
};

export const useGetBranchDetails = (
	branchId: number
): UseQueryResult<BranchResponseType, unknown> => {
	return useQuery(
		[GET_BRANCH_DETAILS, branchId],
		async () => {
			const response = await client.get<BranchResponseType>(
				`v2/branches/${branchId}`
			);
			return response.data;
		},
		{ enabled: !!branchId }
	);
};

export const useGetTerminalDetails = (terminalId: number) => {
	return useQuery(
		[GET_TERMINAL_DETAILS, terminalId],
		async () => {
			const response = await client.get(`v2/terminals/${terminalId}`);
			return response.data;
		},
		{ enabled: !!terminalId }
	);
};

export const useGetChannelAutoSave = () => {
	return useQuery([GET_CHANNEL_AUTO_SAVE], async () => {
		const response = await client.get(`/v2/autosave/channels`);
		return response.data;
	});
};

export const useGetBranchAutoSave = (activeType: string) => {
	return useQuery(
		[GET_BRANCH_AUTO_SAVE],
		async () => {
			const response = await client.get(`/v2/autosave/branches`);
			return response.data;
		},
		{ enabled: activeType === 'branch' }
	);
};

export const useGetTerminalAutoSave = (activeType: string) => {
	return useQuery(
		[GET_TERMINAL_AUTO_SAVE],
		async () => {
			const response = await client.get(`/v2/autosave/terminals`);
			return response.data;
		},
		{ enabled: activeType === 'terminal' }
	);
};
