import cx from 'classnames';
import Table from 'components/CWSTable/Table';
import Checkbox from 'components/Checkbox/Checkbox';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import client from 'helpers/ApiClient';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToggle } from 'utils/hooks';
import Actions from '../../Actions';
import ChannelApprovalTabFilter, {
    getUniqueValues,
} from '../../ApprovalTabFilter';
import {
    ApproveErrorModal,
    ApproveRejectModal,
    ApproveSuccessModal,
    PreHeader,
} from '../../ChannelApprovalModals';
import {
    UseApproveRejectServiceFee,
    UseGetChannelPrimaryInfo,
    UseGetServiceFeeData,
    useGetFeeApprovalList,
} from '../../ChannelApprovalQuery';
import {
    APPROVAL_LIST_FILTER,
    LOADING_MESSAGE,
    SERVICE_FEE_TABLE_COLUMNS,
    TABLE_FILTER,
} from '../../constants';
import styles from '../../styles.module.css';
import {
    ChannelFilter,
    ChannelTableRowData,
    ErrorMessage,
    OptionValues,
} from '../../types';
import ServiceFeeApprovalModal from './ServiceFeeApprovalModal';
type Props = {
    name: string;
    selectedIndex: number;
};
const ServiceFeeApprovalTab: React.FC<Props> = ({ name, selectedIndex }) => {
    const { watch, reset, control, getValues } = useFormContext<ChannelFilter>();

    const [tableFilter, setTableFilter] = useState({ ...TABLE_FILTER });
    const [approvalListParam, setApprovalListParam] = useState<
        typeof APPROVAL_LIST_FILTER | typeof TABLE_FILTER
    >({ ...APPROVAL_LIST_FILTER, ...TABLE_FILTER });
    const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
        header: '',
        message: '',
        type: '',
        isCommonError: true,
    });
    const [successMessage, setSuccessMessage] = useState<any>('');
    const [approvalRemarks, setApprovalRemarks] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<ChannelTableRowData[]>([]);
    const [isAllRowsSelected, setAllRowsSelected] = useState<any>(false);
    const [tableData, setTableData] = useState<ChannelTableRowData[]>([]);
    const [queryData, setQueryData] = useState<any>();
    const [optionValues, setOptionValues] = useState<OptionValues>({});
    const [serviceFeeData, setServiceFeeData] = useState<any>({});
    const [channelPrimaryInfo, setChannelPrimaryInfo] = useState<any>({});
    const {
        value: isLoading,
        valueOn: showLoading,
        valueOff: hideLoading,
    } = useToggle();
    const {
        value: isServiceFeeDetailsOpen,
        valueOn: showServiceFeeDetailsModal,
        valueOff: hideServiceFeeDetailsModal,
    } = useToggle();
    const {
        value: isApprove,
        valueOn: showApprove,
        valueOff: hideApprove,
    } = useToggle();
    const {
        value: isReject,
        valueOn: showReject,
        valueOff: hideReject,
    } = useToggle();
    const {
        value: isError,
        valueOn: showIsError,
        valueOff: hideIsError,
    } = useToggle();
    const {
        value: isSuccess,
        valueOn: showIsSuccess,
        valueOff: hideIsSuccess,
    } = useToggle();

    const {
        value: isBatchApprove,
        valueOn: showBatchApprove,
        valueOff: hideBatchApprove,
    } = useToggle();
    const {
        value: isBatchReject,
        valueOn: showBatchReject,
        valueOff: hideBatchReject,
    } = useToggle();

    const approvalListQuery = useGetFeeApprovalList(approvalListParam);
    const filterDebounce = useCallback(
        debounce(() => {
            const { serviceFeeTabFilter } = getValues();
            setApprovalListParam((prevValues) => ({
                ...prevValues,
                ...serviceFeeTabFilter,
                ...tableFilter,
            }));
            approvalListQuery.refetch();
        }, 500),
        [tableFilter, approvalListParam, selectedIndex]
    );
    useEffect(() => {
        if (selectedIndex == 1) {
            filterDebounce();
        } else return;
    }, [watch('channelTabFilter.search'), tableFilter, selectedIndex]);

    useEffect(() => {
        if (selectedIndex == 1) {
            if (approvalListQuery.isSuccess) {
                setQueryData(approvalListQuery.data);
                setTableData((prev) =>
                    approvalListQuery.data.data.map((element) => ({
                        ...element,
                        checked: false,
                    }))
                );
                setOptionValues({
                    optionsSubmittedBy: getUniqueValues(
                        approvalListQuery.data.data,
                        'submittedBy'
                    ),
                    optionsChannelAccount: getUniqueValues(
                        approvalListQuery.data.data,
                        'channelAccountName'
                    ),
                    optionsProductType: getUniqueValues(
                        approvalListQuery.data.data,
                        'productType'
                    ),
                    optionsPartner: getUniqueValues(
                        approvalListQuery.data.data,
                        'partner'
                    ),
                });
                hideLoading();
            }
            if (approvalListQuery.isFetching) {
                setQueryData({});
                setTableData([]);
                showLoading();
            }
            if (approvalListQuery.isError) {
                showIsError();
                setErrorMessage({
                    header: 'Network Error',
                    message: '',
                    isCommonError: true,
                });
                hideLoading();
            }
        }
    }, [
        approvalListQuery.isLoading,
        approvalListQuery.isSuccess,
        approvalListQuery.isError,
        approvalListQuery.isFetching,
    ]);

    const handleAction = async (action: string, value: ChannelTableRowData) => {
        if (action == 'details') {
            showLoading();
            setSelectedRows([{ ...value, checked: true }]);
            try {
                const [serviceFeeData, primaryInfo] = await Promise.all([
                    UseGetServiceFeeData(value.productId, value.channelId),
                    UseGetChannelPrimaryInfo(value.channelId),
                ]);
                hideLoading();
                setServiceFeeData(serviceFeeData.data);
                setChannelPrimaryInfo(primaryInfo.data);
                showServiceFeeDetailsModal();
            } catch (error) {
                setErrorMessage({
                    header: 'Network Error',
                    message: '',
                    isCommonError: true,
                });
                showIsError();
                hideLoading();
            }
        }
        if (action == 'approve') {
            setSelectedRows([{ ...value, checked: true }]);
            showApprove();
        }
        if (action == 'reject') {
            setSelectedRows([{ ...value, checked: true }]);
            showReject();
        }
    };

    const approve = async (
        remarks: string,
        isRetry: boolean,
        isApprove: boolean
    ) => {
        setApprovalRemarks(remarks);
        setSelectedRows(tableData.filter((element) => element.checked));
        const idList = selectedRows.map((element) => {
            return element.id;
        });
        if (selectedRows.length > 0) {
            showLoading();
            await UseApproveRejectServiceFee(idList, remarks, isApprove)
                .then(() => {
                    hideLoading();
                    if (selectedRows.length > 1) {
                        setSuccessMessage(
                            <>Partner requests are {isApprove ? 'Approved' : 'Rejected'}.</>
                        );
                        showIsSuccess();
                        isApprove ? hideBatchApprove() : hideBatchReject();
                        approvalListQuery.refetch();
                    }
                    if (selectedRows.length == 1) {
                        setSuccessMessage(
                            <>
                                {selectedRows[0].name} is now{' '}
                                <strong>{isApprove ? 'Approved' : 'Rejected'}</strong>
                            </>
                        );
                        showIsSuccess();
                        isApprove ? hideApprove() : hideReject();
                        hideServiceFeeDetailsModal();
                        approvalListQuery.refetch();
                    }
                })
                .catch((_response) => {
                    hideLoading();
                    setErrorMessage({
                        header: 'Something went wrong.',
                        message: '',
                        type: isApprove ? 'Approved' : 'Rejected',
                        isCommonError: false,
                    });
                    isRetry ? null : showIsError();
                });
        }
        return;
    };
    const retry = (param) => {
        approve(approvalRemarks, true, param == 'approve');
    };

    const updatedColumn = SERVICE_FEE_TABLE_COLUMNS.map((element) => {
        if (element.id === 'action') {
            return {
                ...element,
                Header: () => <div className={cx(styles.centerAlign)}>Action</div>,
                accessor: (values: any) => values,
                Cell: ({ value }) => {
                    return <Actions value={value} action={handleAction} />;
                },
            };
        }
        if (element.id === 'status') {
            return {
                ...element,
                accessor: ({ status }) =>
                    status
                        .toLowerCase()
                        .split('_')
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' '),
                Cell: ({ value }) => {
                    return (
                        <div className={styles[value.replaceAll(' ', '')]}>{value}</div>
                    );
                },
            };
        }
        if (element.id === 'checked') {
            return {
                ...element,
                Header: () => {
                    return (
                        <div>
                            <Checkbox
                                label=""
                                type="checkbox"
                                onChange={(v) => {
                                    const updateData = tableData.map((element, index) => {
                                        if (element.status == 'FOR_REVIEW' && index < 10) {
                                            return { ...element, checked: v };
                                        }
                                        return { ...element, checked: false };
                                    });
                                    setTableData(updateData);
                                    setAllRowsSelected(v);
                                }}
                                checked={isAllRowsSelected}
                            />
                        </div>
                    );
                },
                accessor: (values: any) => values,
                Cell: ({ value }) => {
                    if (value.status == 'FOR_REVIEW') {
                        return (
                            <div>
                                <Checkbox
                                    label=""
                                    type="checkbox"
                                    onChange={(v) => {
                                        if (
                                            tableData.filter((element) => element.checked).length < 10
                                        ) {
                                            const updateData = tableData.map((element) => {
                                                if (value.id == element.id) {
                                                    return { ...element, checked: v };
                                                }
                                                return element;
                                            });
                                            setTableData(updateData);
                                        } else {
                                            showIsError();
                                            setErrorMessage({
                                                header: '',
                                                message:
                                                    'Only max of 10 request can be processed at a time',
                                                isCommonError: true,
                                            });
                                        }
                                    }}
                                    checked={value.checked}
                                />
                            </div>
                        );
                    } else return null;
                },
            };
        }
        return element;
    });

    return (
        <>
            <ChannelApprovalTabFilter
                control={control}
                name={name}
                reset={reset}
                filter={filterDebounce}
                optionValues={optionValues}
            />
            <Table
                columns={updatedColumn}
                data={tableData}
                count={queryData?.total}
                pageSize={queryData?.pageSize}
                page={queryData?.page}
                sortBy={'createdAt'}
                onPageChange={(page, limit) => {
                    setTableFilter((prev) => ({ ...prev, page, limit }));
                    filterDebounce();
                }}
                onSort={(sortBy, sort) => {
                    setTableFilter((prev) => ({
                        ...prev,
                        sortBy,
                        sort: sort.toLowerCase(),
                    }));
                    filterDebounce();
                }}
                isNotEvenRowColor={false}
                modalIsOpen={false}
                preHeader={
                    <PreHeader
                        selectedRows={tableData.filter((element) => element.checked)}
                        showApprove={showBatchApprove}
                        showReject={showBatchReject}
                    />
                }
                withRowField={false}
            />
            {isLoading && (
                <FullPageLoader open={isLoading} message={LOADING_MESSAGE} />
            )}
            {isServiceFeeDetailsOpen && (
                <ServiceFeeApprovalModal
                    isOpen={isServiceFeeDetailsOpen}
                    onRequestClose={hideServiceFeeDetailsModal}
                    data={{ serviceFeeData, channelPrimaryInfo }}
                    selectedRow={selectedRows[0]}
                    showApproveModal={showApprove}
                    showRejectModal={showReject}
                />
            )}
            {isApprove && (
                <ApproveRejectModal
                    isOpen={isApprove}
                    hideApprove={hideApprove}
                    approveReject={approve}
                    rowData={selectedRows}
                    isApprove={true}
                />
            )}
            {isReject && (
                <ApproveRejectModal
                    isOpen={isReject}
                    hideApprove={hideReject}
                    approveReject={approve}
                    rowData={selectedRows}
                    isApprove={false}
                />
            )}
            {isError && (
                <ApproveErrorModal
                    errorMessage={errorMessage}
                    hideErrorModal={hideIsError}
                    isErrorModalShown={isError}
                    retry={retry}
                />
            )}
            {isSuccess && (
                <ApproveSuccessModal
                    hideSuccessModal={hideIsSuccess}
                    isSuccessModalShown={isSuccess}
                    successMessage={successMessage}
                />
            )}
            {isBatchApprove && (
                <ApproveRejectModal
                    isOpen={isBatchApprove}
                    hideApprove={hideBatchApprove}
                    approveReject={approve}
                    rowData={tableData.filter((element) => element.checked)}
                    isApprove={true}
                />
            )}
            {isBatchReject && (
                <ApproveRejectModal
                    isOpen={isBatchReject}
                    hideApprove={hideBatchReject}
                    approveReject={approve}
                    rowData={tableData.filter((element) => element.checked)}
                    isApprove={false}
                />
            )}
        </>
    );
};

export default ServiceFeeApprovalTab;
