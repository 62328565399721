import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import {
    ChannelTypeDigital,
    ChannelTypePhysical,
} from 'utils/lookup'

export const useTerminal = () => {
    const channelTypes = useSelector((state: ReducerStateType) => state.channels.channelTypes)
    const channelDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedChannel ?? {}
	);
	const branchDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedBranch ?? {}
	);
    const terminalDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedTerminal ?? {}
	);
    const channelFormValues = useSelector(
		(state: ReducerStateType) => state.channels.channelFormValues
	);
    const branchFormValues = useSelector(
		(state: ReducerStateType) => state.channels.branchFormValues
	);
    const terminalFormValues = useSelector(
		(state: ReducerStateType) => state.channels.terminalFormValues
	);

    const [terminalInfoPayload, setTerminalInfoPayload] = useState<any>()

    const allChannelTypes = [...ChannelTypeDigital, ...ChannelTypePhysical]

    useEffect(() => {
        setTerminalInfoPayload({
            accountId: channelDetails.id,
            branchId: branchDetails.id,
            spmNumber: '',
            activationDate: '',
            channelTransactionType: channelFormValues?.primaryInfo?.channelPrimaryInfo?.channelTransactionType,
            channelTypeId: channelFormValues?.primaryInfo?.channelPrimaryInfo?.channelType,
            channelType: allChannelTypes.find(option => option.value === channelFormValues?.primaryInfo?.channelPrimaryInfo?.channelType)?.label,
            posType: branchFormValues?.data?.branchPrimaryInfos?.posType,
            meralcoPaymentCenterId: branchFormValues?.data?.branchPrimaryInfos?.meralcoPaymentCenterId,
            remarks: '',
            tokenIdReg: ''
        })
	}, [channelFormValues, branchFormValues])

    const handleChange = (field, value) => {
        setTerminalInfoPayload((terminalInfoPayload) => ({
            ...terminalInfoPayload,
            [field]: value
        }))
    }

    return {
        channelTypes,
        channelDetails,
        branchDetails,
        terminalDetails,
        channelFormValues,
        branchFormValues,
        terminalFormValues,
        terminalInfoPayload,
        handleChange
    }
}