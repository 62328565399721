import yup from 'utils/formSchemas/common';

export const collectionDepositoryAccountSchema = yup.object({}).shape({
	bankName: yup.string().nullable().label('Select Bank Name').nullable(true),
	accountName: yup
		.string()
		.nullable(true)
		.label('Account Name')
		.transform((o, c) => (o === '' ? null : c))
		.matches(
			/^[a-zA-Z0-9 ]+$/,
			'This field only allows alphanumeric characters.'
		)
		.max(255, 'You can only input a max of 100 characters.'),
	accountNumber: yup
		.string()
		.label('Account Number')
		.transform((o, c) => (o === '' ? null : c))
		.min(
			9,
			'This field requires to input 9-15 characters. Kindly input the number of characters needed.'
		)
		.max(
			15,
			'This field requires to input 9-15 characters. Kindly input the number of characters needed.'
		)
		.nullable(true),
	accountType: yup.string().label('Account Type').nullable(),
	bankBranch: yup
		.string()
		.label('Bank Branch')
		.nullable(true)
		.transform((o, c) => (o === '' ? null : c))
		.matches(
			/^[a-zA-Z0-9 ]+$/,
			'This field only allows alphanumeric characters.'
		)
		.max(255, 'You can only input a max of 255 characters.'),
});
