import React from 'react';
import { connect } from 'react-redux';
import styles from './TransactionSubFilter.module.css';
import SLDSDatepicker from '../Inputs/Datepicker/SLDSDatepicker';
import InputWithIcon from '../Inputs/InputWithIcon/InputWithIcon';
import LookupField from '../Inputs/LookupField/LookupField';
import Select from '../Inputs/Select/Select';
import PrimaryButton from '../Buttons/PrimaryButton';
import { useForm, Controller } from 'react-hook-form';
import {
	handleFilterSubmit,
	convertBillersToOptions,
	filterActionCreator,
	filterActionCreatorCount,
	searchBillersActionCreator,
	changeShowAll,
} from '../../redux/modules/transaction';
import {
	validateDateFrom,
	validateDateTo,
	validateAlphaNumeric,
	validateDecimal,
	isTransactionIsDisabled,
} from '../../utils/validation';
import cx from 'classnames';
import { BillersTable, billersColumnConfig } from '../../components';
import { Modal } from '@salesforce/design-system-react/module/components';

function TransactionSubFilter({
	filterActionCreator,
	filterActionCreatorCount,
	searchBillersActionCreator,
	billers,
	count,
	changeShowAll,
	showBillers,
}) {
	const {
		register,
		getValues,
		handleSubmit,
		control,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		mode: 'onTouched',
	});

	const watchAllFields = watch('filter');

	const [dropdown, selectDropdown] = React.useState(0);
	return (
		<>
			<Modal
				isOpen={showBillers}
				onRequestClose={() => {
					changeShowAll({ show: false });
				}}
				size="small"
			>
				<BillersTable columns={billersColumnConfig(setValue, changeShowAll)} />
			</Modal>
			<form
				onSubmit={handleSubmit(
					(data) => {
						handleFilterSubmit(data, dropdown, filterActionCreator);
						handleFilterSubmit(data, dropdown, filterActionCreatorCount);
					},
					function (error) {
						console.log(error);
					}
				)}
			>
				<div className={styles.container}>
					<p className={styles.filterTitle}>Filter:</p>
					<div className="slds-grid">
						<div className="slds-col slds-size_11-of-12">
							<div className={cx('slds-grid', styles.inputContainer)}>
								<div className="slds-col">
									<SLDSDatepicker
										placeholder="Date From"
										label="Date From"
										className={cx({
											[styles.errorDtp]:
												errors.filter && errors.filter.dateFrom,
										})}
										{...register('filter.dateFrom', {
											validate: (v) =>
												validateDateFrom(v, getValues('filter.dateTo')),
										})}
									/>
									{errors.filter && errors.filter.dateFrom && (
										<div className={styles.error}>
											{errors.filter.dateFrom.message}
										</div>
									)}
								</div>
								<div className="slds-col">
									<SLDSDatepicker
										placeholder="Date To"
										label="Date To"
										name="dateTo"
										className={cx({
											[styles.errorDtp]: errors.filter && errors.filter.dateTo,
										})}
										{...register('filter.dateTo', {
											validate: (v) =>
												validateDateTo(getValues('filter.dateFrom'), v),
										})}
									/>
									{errors.filter && errors.filter.dateTo && (
										<div className={styles.error}>
											{errors.filter.dateTo.message}
										</div>
									)}
								</div>
								<div className="slds-col">
									<InputWithIcon
										placeholder="TPAID"
										id="tpid"
										label="TPAID"
										className={cx({
											[styles.errorInput]: errors.filter && errors.filter.tpaid,
										})}
										{...register('filter.tpaid', {
											maxLength: {
												value: 4,
												message: 'Invalid value',
											},
											validate: (v) => validateAlphaNumeric(v),
										})}
									/>
									{errors.filter && errors.filter.tpaid && (
										<div className={styles.error}>
											{errors.filter.tpaid.message}
										</div>
									)}
								</div>
								<div className="slds-col">
									<InputWithIcon
										placeholder="Account No"
										id="account"
										label="Account No"
										className={cx({
											[styles.errorInput]:
												errors.filter && errors.filter.accountNo,
										})}
										{...register('filter.accountNo', {
											maxLength: {
												value: 20,
												message: 'Invalid value',
											},
											validate: (v) => validateAlphaNumeric(v),
										})}
									/>
									{errors.filter && errors.filter.accountNo && (
										<div className={styles.error}>
											{errors.filter.accountNo.message}
										</div>
									)}
								</div>
								
								<div className="slds-col">
									<label className="slds-form-element__label" htmlFor="clientid">
										Client ID
									</label>
									<InputWithIcon
										placeholder="Client ID"
										id="clientid"
										className={cx({
											[styles.errorInput]: errors.filter && errors.filter.clientid,
										})}
										{...register('filter.clientid', {
											maxLength: {
												value: 4,
												message: 'Invalid value',
											},
											validate: (v) => validateAlphaNumeric(v),
										})}
									/>
									{errors.filter && errors.filter.clientid && (
										<div className={styles.error}>
											{errors.filter.clientid.message}
										</div>
									)}
								</div>

								<div className="slds-col slds-size_2-of-12">
									<label
										className="slds-form-element__label"
										htmlFor="billerName"
									>
										Biller Name
									</label>
									<Controller
										name="filter.billerId"
										control={control}
										render={({ field }) => (
											<LookupField
												{...field}
												options={convertBillersToOptions(billers).slice(0, 10)}
												hasSeeMore={count > 10}
												seeMoreClick={() => {
													// show modal
													changeShowAll({
														show: true,
													});
												}}
												placeholder="Biller Name"
												onInputChange={(data) => {
													if (data.length > 2 || data === '') {
														searchBillersActionCreator(data);
													}
												}}
											/>
										)}
									/>
								</div>
								<div className="slds-col">
									<InputWithIcon
										placeholder="Amount"
										id="amount"
										label="Amount"
										className={cx({
											[styles.errorInput]:
												errors.filter && errors.filter.amount,
										})}
										{...register('filter.amount', {
											validate: (v) => validateDecimal(v),
										})}
									/>
									{errors.filter && errors.filter.amount && (
										<div className={styles.error}>
											{errors.filter.amount.message}
										</div>
									)}
								</div>
								<div className="slds-col" style={{ width: '19.9%' }}>
									<label className="slds-form-element__label" htmlFor="status">
										Status
									</label>
									<Select
										placeholder="Status"
										onChange={selectDropdown}
										options={[
											{ label: '', value: 0 },
											{ label: 'Success', value: 1 },
											{ label: 'Failed', value: 2 },
											{ label: 'Voided', value: 3 },
											{ label: 'Processing', value: 4 },
											{
												label: 'Re-Processing',
												value: 5,
											},
										]}
									/>
								</div>
							</div>
						</div>
						<div className="slds-col">
							<label
								className="slds-form-element__label"
								style={{
									fontSize: '12px',
									lineHeight: '18px',
									display: 'block',
								}}
							>
								&nbsp;
							</label>
							<PrimaryButton
								type="submit"
								className={styles.filterButton}
								disabled={
									errors.filter ||
									isTransactionIsDisabled(watchAllFields, dropdown)
								}
							>
								Filter
							</PrimaryButton>
						</div>
					</div>
				</div>
			</form>
		</>
	);
}

export default connect(
	(state) => ({
		billers: state.transaction.billers.searchResult,
		count: state.transaction.billers.pagination.count,
		showBillers: state.transaction.billers.showAll,
	}),
	{
		filterActionCreator,
		filterActionCreatorCount,
		searchBillersActionCreator,
		changeShowAll,
	}
)(TransactionSubFilter);
