import Grid from 'components/Grid/Grid';
import { SectionRow, SectionTitle } from 'components/Section/Section';
import Configurations from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/Configurations';
import { useGetCsrColumnsQuery } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/hooks';
import { CsrColumn } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/types';
import { CsrColumnTemplate } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnTemplate';
import CsrReportPreview from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/CsrReportPreview';
import { SIDE_HEADERS } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/constants';
import { FtpContainerProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/types';
import { PartnerReportsFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

const FtpContainer: React.FC<FtpContainerProps> = ({ name, disabled }) => {
	const { control, watch } = useFormContext<PartnerReportsFormData>();
	const { data } = useGetCsrColumnsQuery();
	const namePrefix = `${name}.csrConfiguration` as const;

	const columns = watch(`${namePrefix}.columns`);
	const selectedColumns = useMemo<CsrColumn[]>(() => {
		if (!data) return [];
		if (!columns) return [];

		return columns
			.map(({ id }) => data.find((d) => d.id === id))
			.filter(Boolean) as CsrColumn[];
	}, [data, columns]);

	return (
		<>
			<Configurations
				name={namePrefix}
				title="Channel Collection Summary Report (CCSR) Configuration"
				disabled={disabled}
			/>
			<SectionRow vertical gutters={false}>
				<Grid column>
					<SectionTitle>Select columns for the CCSR template:</SectionTitle>
				</Grid>
				<Grid column>
					{data && (
						<CsrColumnTemplate
							control={control}
							pool={data}
							name={`${namePrefix}.columns`}
							disabled={disabled}
						/>
					)}
				</Grid>
			</SectionRow>
			<CsrReportPreview sideHeaders={SIDE_HEADERS} columns={selectedColumns} />
		</>
	);
};
export default FtpContainer;
