import { DraftsFilterFormDataType } from 'containers/ChannelManagement/Drafts/DraftsFilter/types';
import { DraftsData } from 'containers/ChannelManagement/Drafts/DraftsTable/types';

export const DATE_CREATED = 'dateCreated';
export const SUBMITTED_BY = 'submittedBy';
export const CHANNEL_ACCOUNT_NAME = 'channelAccountName';
export const BRANCH_NAME = 'branchName';
export const TPAID = 'tpaid';
export const DATE_UPDATED = 'dateUpdated';
export const UPDATED_BY = 'updatedBy';
export const STATUS = 'status';
export const REMARKS = 'remarks';
export const SEARCH = 'search';
export const DATE_PLACE_HOLDER = 'MM/DD/YYYY';

export const BACKEND_SORTBY = {
	[DATE_CREATED]: 'created_at',
	[SUBMITTED_BY]: 'submitted_by',
	[CHANNEL_ACCOUNT_NAME]: 'channel_name',
	[BRANCH_NAME]: 'branch_name',
	[TPAID]: 'tpa_name',
	[DATE_UPDATED]: 'updated_at',
	[UPDATED_BY]: 'updated_by',
};

export const DRAFT_MOCK_DATA: DraftsData[] = [
	{
		[DATE_CREATED]: '06/21/2023',
		[SUBMITTED_BY]: 'submittedBy',
		[CHANNEL_ACCOUNT_NAME]: 'Channel A',
		[BRANCH_NAME]: 'Branch 1',
		[TPAID]: 'TP01',
		[DATE_UPDATED]: '06/28/2023',
		[UPDATED_BY]: 'aryu',
		[STATUS]: 'DRAFT',
		[REMARKS]: 'Test remarks...',
	},
];

export const DRAFTS_FILTER_DEFAULT_VALUES: DraftsFilterFormDataType = {
	dateCreated: '',
	dateUpdated: '',
	channelAccountName: '',
	branchName: '',
	tpaid: '',
	search: '',
};

export const CHANNEL_ACCOUNT_NAMES_ENDPOINT =
	'/v2/channels/utils/list-channels';
export const BRANCH_NAME_ENDPOINT = '/v2/channels/utils/list-branches';
export const TPAID_ENDPOINT = '/v2/channels/utils/list-terminals';
