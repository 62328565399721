import SelectField from 'components/Inputs/SelectField/SelectField';
import { SelectFieldProps } from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useZipCodeQuery } from 'utils/queries/location';

export type ZipCodeSelectProps = {
	barangayId?: string;
	selectFieldProps?: SelectFieldProps;
};

const ZipCodeSelect: React.FC<ZipCodeSelectProps> = ({
	barangayId = '',
	selectFieldProps,
}) => {
	const { data, isLoading } = useZipCodeQuery(
		'zipcodes',
		{ barangayId },
		{ enabled: !!barangayId }
	);

	const zip_codes = useMemo(() => {
		const options =
			data?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [];

		return options;
	}, [data]);

	const { setValue } = useFormContext();
	const firstItem = zip_codes[0]?.value;
	selectFieldProps?.name &&
		firstItem &&
		setValue(selectFieldProps?.name, firstItem);

	return (
		<>
			<SelectField
				label={'Zip Code'}
				options={zip_codes}
				isLoading={isLoading}
				{...selectFieldProps}
			/>
		</>
	);
};

export default ZipCodeSelect;
