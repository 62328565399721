import Checkbox from 'components/Checkbox/Checkbox';
import MultiCheckboxContainer from 'components/Checkbox/MultiCheckboxContainer';
import useMultipleSelect from 'components/Checkbox/useMultiSelect';
import HTTP, { ApiResponse } from 'helpers/ApiClient';
import { useQuery } from 'react-query';
import cx from 'classnames';
import styles from './style.module.css';
import {
	Control,
	FieldValues,
	Path,
	useController,
	useWatch,
} from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import { useDebounce } from 'react-use';
import { FixMeLater } from 'types';
import React from 'react';
import { debounce } from 'lodash';

export const csrColumnSchema = yup.object({
	id: yup.string().required(),
	value: yup.string().optional(),
	code: yup.string().optional(),
	sample: yup.string().optional(),
	is_required: yup.boolean().optional(),
	ordinal: yup.number().required(),
});

type ControlledField<T extends FieldValues> = { control: Control<T> };

export type CsrColumn = yup.InferType<typeof csrColumnSchema>;

export function useGetCsrColumnsQuery() {
	return useQuery({
		queryKey: ['getCsrColumns'],
		queryFn: async () => {
			return HTTP.get<ApiResponse<CsrColumn[]>>(
				'/v2/utils/reports/csr-columns'
			);
		},
		select: (response) => response.data.data,
		staleTime: Infinity,
	});
}

type CsrColumnCheckboxProps = {
	onChange?: (entries: CsrColumn[]) => void;
	control?: Control<any>;
	name: string;
	value?: CsrColumn[];
	pool?: CsrColumn[];
	disabled?: boolean;
};

type ControlledCsrColumnCheckboxProps<T extends FieldValues> =
	ControlledField<T> & CsrColumnCheckboxProps & { name: Path<T> };

const MIN_SEARCH_TERM_LENGTH = 3;

function useCsrColumns(pool: CsrColumn[], initialValue: CsrColumn[]) {
	const {
		selectedEntries,
		setSelectedEntries,
		entries,
		isSelected,
		toggle,
		allSelected,
		toggleAll,
		filterEntries,
		resetEntries,
	} = useMultipleSelect<CsrColumn>(pool ?? [], (entry) => entry.id, {
		initialValue,
		frozenEntries: pool?.filter(({ is_required }) => is_required) ?? [],
	});

	const [searchKeyword, setSearchKeyword] = useState('');

	useEffect(() => {
		debounce(() => {
			if (!searchKeyword) {
				resetEntries();
				return;
			}
			if (searchKeyword?.length < MIN_SEARCH_TERM_LENGTH) {
				return;
			}
			filterEntries(({ value }) => {
				const v = value?.toLowerCase();
				const s = searchKeyword?.toLowerCase();
				const match = v?.indexOf(s) !== -1;

				return match;
			});
		}, 400)();
	}, [searchKeyword]);

	return {
		entries,
		selectedEntries,
		setSelectedEntries,
		isSelected,
		toggle,
		allSelected,
		toggleAll,
		filterEntries,
		resetEntries,
		searchKeyword,
		setSearchKeyword,
	};
}

const CsrColumnCheckbox: React.FC<CsrColumnCheckboxProps> = ({
	onChange,
	value = [],
	pool = [],
	disabled = false,
	name,
}) => {
	const columns = useWatch({ name, defaultValue: value });
	const {
		toggleAll,
		entries,
		toggle,
		selectedEntries,
		searchKeyword,
		setSearchKeyword,
	} = useCsrColumns(pool, value);

	const handleSearchChange = (v: string) => {
		console.log('searching');
		setSearchKeyword(v);
	};
	useEffect(() => {
		onChange?.(selectedEntries);
	}, [selectedEntries]);

	const isSelected = useCallback(
		(v: CsrColumn) => {
			if (!columns) return v.is_required;
			return columns.some((entry) => entry.id === v.id) || v.is_required;
		},
		[columns]
	);

	const allSelected = useMemo(() => {
		return entries.length > 0 && entries.every(isSelected);
	}, [entries, isSelected]);

	return (
		<MultiCheckboxContainer
			selectAll={allSelected}
			onSelectAll={() => toggleAll()}
			disabled={disabled}
		>
			<InputWithIcon
				placeholder="Search"
				value={searchKeyword}
				onChange={handleSearchChange}
				disabled={disabled}
				icons={[
					{
						path: 'utility/search',
						isLeft: true,
					},
				]}
			/>
			<div className={styles.entryContainer}>
				{entries.map((entry) => (
					<div
						key={entry.id}
						className={cx('slds-col', styles.entry, {
							[styles.readonly]: entry.is_required || disabled,
						})}
					>
						<Checkbox
							label={entry.value}
							checked={isSelected(entry)}
							onChange={() => toggle(entry)}
							disabled={Boolean(entry.is_required) || disabled}
						/>
					</div>
				))}
			</div>
		</MultiCheckboxContainer>
	);
};

export function ControlledCsrColumnCheckbox<T extends FieldValues>({
	control,
	name,
	onChange,
	...rest
}: ControlledCsrColumnCheckboxProps<T>): FixMeLater {
	const {
		field: { value, onChange: hookOnChange },
	} = useController({ control, name });

	function handleChange(entries: CsrColumn[]) {
		hookOnChange(entries);
		onChange?.(entries);
	}

	return (
		<CsrColumnCheckbox
			value={value as CsrColumn[]}
			onChange={handleChange}
			control={control}
			name={name}
			{...rest}
		/>
	);
}

export default CsrColumnCheckbox;
