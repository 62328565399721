import { Modal } from '@salesforce/design-system-react/';
import Button from '@salesforce/design-system-react/components/button';
import { ReactComponent as ApproveIcon } from 'assets/icons/ic-approve.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/ic-reject.svg';
import cx from 'classnames';
import ChannelStatusSelect from 'components/PartnerStatusSelect/PartnerStatusSelect';
import ServiceFeeContainer from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeContainer';
import React from 'react';
import { formatDate } from 'utils/common';
import { ChannelTableRowData } from '../../types';
import styles from './styles.module.css';

type Props = {
    isOpen: boolean
    onRequestClose: () => void
    data: { serviceFeeData, channelPrimaryInfo };
    selectedRow: any;
    showApproveModal,
    showRejectModal
}
const ServiceFeeApprovalModal: React.FC<Props> = ({
    isOpen = false,
    onRequestClose,
    data = { serviceFeeData: {}, channelPrimaryInfo: {} },
    selectedRow,
    showApproveModal,
    showRejectModal
}) => {
    console.log(data, selectedRow)
    const dataSet = { productId: data?.serviceFeeData.config.product_id }
    const selectedChannel = { channelId: selectedRow?.channelId }
    return (
        <>
            {data &&
                <Modal
                    isOpen={isOpen}
                    onRequestClose={onRequestClose}
                    heading={<Header selectedRow={selectedRow} data={data} />}
                    footer={<Footer showApproveModal={showApproveModal} showRejectModal={showRejectModal} selectedRow={selectedRow} />}
                    headerClassName={styles.headerContainer}
                    size="medium"
                    containerClassName={styles.modalContainer}
                    contentClassName={styles.modal}
                    ariaHideApp={false}
                >
                    <div className='slds-p-around_large slds-scrollable_y'>
                        <div className={styles.tableTitle}>Service Fee</div>
                        <ServiceFeeContainer
                            itemKey={1}
                            serviceFees={[data.serviceFeeData]}
                            data={dataSet}
                            selectedChannel={selectedChannel}
                            customStyles={{ ...styles }}
                        />
                    </div>
                </Modal>
            }
        </>
    )
}

type HeaderProps = {
    selectedRow: ChannelTableRowData,
    data
}
const Header: React.FC<HeaderProps> = ({ selectedRow, data }) => {
    return (
        <>
            {data && selectedRow &&
                <div className="slds-grid_vertical grid_align-start slds-gutters slds-m-around_xx-small">
                    <div className="slds-col slds-m-vertical_medium slds-small-size slds-text-align_left ">Service Fee Share Details</div>
                    <div className="slds-col slds-m-bottom_medium">
                        <div className="slds-grid slds-grid_align-spread">
                            <div className={cx(styles.bold, 'slds-text-heading_small')}>{selectedRow.channelAccountName}</div>
                            <div className={cx(styles.italic, 'slds-text-body_small')}>Last updated at {selectedRow.updatedAt ? formatDate(selectedRow.updatedAt) : ''} by {selectedRow.updatedBy}</div>
                        </div>
                    </div>
                    <div className="slds-col slds-m-bottom_medium slds-text-body_small">
                        <div className='slds-grid slds-grid_vertical-align-center slds-text-align_left'>
                            <div>Channel Status :</div>
                            <div className={cx(styles.statusPill)}>
                                <ChannelStatusSelect
                                    value={data.channelPrimaryInfo.channelPrimaryInfo.status}
                                    disabled={false}
                                    onSelect={() => { }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="slds-col slds-m-bottom_medium slds-text-body_small">
                        <div className="slds-grid_vertical slds-text-align_left">
                            <div>Product Type: <strong>{selectedRow.productType}</strong></div>
                            <div>Partner: <strong>{selectedRow.partner}</strong></div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

type FooterProps = {
    showApproveModal,
    showRejectModal,
    selectedRow
}

const Footer: React.FC<FooterProps> = ({ showApproveModal, showRejectModal, selectedRow }) => {
    const disabled = selectedRow.status == 'APPROVED' || selectedRow.status == 'REJECTED'
    return (
        <>
            {selectedRow &&
                <div className='slds-grid slds-grid_align-end'>
                    <Button
                        className={cx(styles.btn, styles.btnApprove)}
                        onClick={() => { showApproveModal() }}
                        disabled={disabled}
                    >
                        <ApproveIcon className={styles.btnApproveIcon} /> Approve
                    </Button>
                    <Button
                        className={cx(styles.btn, styles.btnReject)}
                        onClick={() => { showRejectModal() }}
                        disabled={disabled}
                    >
                        <RejectIcon className={styles.btnApproveIcon} /> Reject
                    </Button>
                </div>
            }
        </>
    )
}

export default ServiceFeeApprovalModal