import React, { useEffect, useState } from 'react';

import { Modal } from '@salesforce/design-system-react';
import Table from 'components/CWSTable/Table';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';

import client from 'helpers/ApiClient';
import { formatDate, formatParams } from 'utils/common';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import {
	useErrorModal,
	useLoader,
	useTablePagination,
	useToggle,
} from 'utils/hooks';

import PartnerDetailsTable from './PartnerDetailsTable';
import {
	AuditTrail,
	AuditTrailListResp,
	AuditTrailListFilter,
	PartnerLogDetails,
	PartnerAuditTrailProps,
	PaginationOptions,
} from './types';
import styles from './PartnerDetailsBody.module.css';

const LOGS_COL_WIDTH = `${100 / 7}%`;

const PartnerAuditTrail: React.FC<PartnerAuditTrailProps> = ({
	partnerId,
	refetchData,
	setRefetchData,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [data, setData] = useState<AuditTrail>();
	const [logDetails, setLogDetails] = useState([]);

	const [filter, setFilter] = useState<PaginationOptions>({});

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		value: isPartnerAuditModalVisible,
		valueOn: showPartnerAuditModal,
		valueOff: hidePartnerAuditModal,
	} = useToggle();

	const mapViewLogs: any = (details, oldValue, newValue) => {
		if (!oldValue || !newValue) return [];

		const viewLogArray: PartnerLogDetails[] = [];

		// IF PARTNER IS UPDATED
		if (oldValue?.channels) {
			const partnerLog = {
				partnerName: details?.partnerName || '',
				productTypeName: details?.productTypeName || '',
				channelName: '-',
				branchName: '-',
				terminalName: '-',
				oldValues: oldValue.status || '',
				newValues: newValue.status || '',
			};

			viewLogArray.push(partnerLog);

			oldValue.channels.forEach(
				(
					{ name: channelName, status: channelStatus, branches },
					channelInd
				) => {
					const channelLog = {
						partnerName: details?.partnerName || '',
						productTypeName: details?.productTypeName || '',
						channelName: channelName || '',
						branchName: '-',
						terminalName: '-',
						oldValues: channelStatus || '',
						newValues: newValue.channels[channelInd]?.status || '',
					};

					viewLogArray.push(channelLog);

					branches.forEach(
						(
							{ name: branchName, status: branchStatus, terminals },
							branchInd
						) => {
							const tpaLogs = terminals
								? terminals.map(({ tpaId, status: tpaStatus }, tpaInd) => {
										return {
											partnerName: details?.partnerName || '',
											productTypeName: details?.productTypeName || '',
											channelName: channelName || '',
											branchName: branchName || '',
											terminalName: tpaId || '',
											oldValues: tpaStatus || '',
											newValues:
												newValue.channels[channelInd]?.branches[branchInd]
													?.terminals[tpaInd]?.status || '',
										};
								  })
								: [];

							const branchLog = {
								partnerName: details?.partnerName || '',
								productTypeName: details?.productTypeName || '',
								channelName: channelName || '',
								branchName: branchName || '',
								terminalName: '-',
								oldValues: branchStatus || '',
								newValues:
									newValue.channels[channelInd]?.branches[branchInd]?.status ||
									'',
							};
							viewLogArray.push(branchLog, ...tpaLogs);
						}
					);
				}
			);
		}
		// IF TPA ACCOUNT IS UPDATED
		else if (oldValue?.branches) {
			const channelLog = {
				partnerName: details.partnerName || '',
				productTypeName: details?.productTypeName || '',
				channelName: oldValue.name || '',
				branchName: '-',
				terminalName: '-',
				oldValues: oldValue.status || '',
				newValues: newValue.status || '',
			};
			viewLogArray.push(channelLog);

			oldValue.branches.forEach(
				(
					{ name: branchName, status: branchStatus, terminals },
					branchIndex
				) => {
					const tpaLogs = terminals
						? terminals.map(({ tpaId, status: tpaStatus }, tpaIndex) => {
								return {
									partnerName: details?.partnerName || '',
									productTypeName: details?.productTypeName || '',
									channelName: channelLog.channelName || '',
									branchName: branchName || '',
									terminalName: tpaId || '',
									oldValues: tpaStatus || '',
									newValues:
										newValue.branches[branchIndex]?.terminals[tpaIndex]
											?.status || '',
								};
						  })
						: [];

					const branchLog = {
						partnerName: details?.partnerName || '',
						productTypeName: details?.productTypeName || '',
						channelName: channelLog.channelName,
						branchName: branchName || '',
						terminalName: '-',
						oldValues: branchStatus || '',
						newValues: newValue.branches[branchIndex].status || '',
					};

					viewLogArray.push(branchLog, ...tpaLogs);
				}
			);
		}
		// IF BRANCH IS UPDATED
		else if (oldValue?.terminals) {
			const branchesLog = {
				partnerName: details?.partnerName || '',
				productTypeName: details?.productTypeName || '',
				channelName: details?.channelName || '',
				branchName: oldValue.name || '',
				terminalName: '-',
				oldValues: oldValue.status || '',
				newValues: newValue.status || '',
			};
			const terminalsLog: PartnerLogDetails[] = oldValue.terminals.map(
				({ tpaId, status: tpaStatus }, tpaIndex) => {
					return {
						partnerName: details?.partnerName || '',
						productTypeName: details?.productTypeName || '',
						channelName: details?.channelName || '',
						branchName: oldValue.name || '',
						terminalName: tpaId || '',
						oldValues: tpaStatus || '',
						newValues: newValue.terminals[tpaIndex]?.status || '',
					};
				}
			);
			viewLogArray.push(branchesLog, ...terminalsLog);
		}
		// IF TERMINAL IS UPDATED
		else {
			const terminalsLog = {
				partnerName: details?.partnerName || '',
				productTypeName: details?.productTypeName || '',
				channelName: details?.channelName || '',
				branchName: details?.branchName || '',
				terminalName: oldValue.tpaId || '',
				oldValues: oldValue.status || '',
				newValues: newValue.status || '',
			};

			viewLogArray.push(terminalsLog);
		}

		return viewLogArray;
	};

	const fetchAuditTrailList = async (params?: AuditTrailListFilter) => {
		if (!hasUserPermission('partner.audit-trail')) {
			return;
		}
		hideErrorModal();
		showLoadingMessage();

		try {
			const result: AuditTrailListResp = await client.get(
				'v2/bfa-admin-portal/partner/logs',
				{ params: { ...params, partnerId } }
			);

			if (result?.error) {
				throw new Error(result?.error?.message);
			}

			setData(result?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchAuditTrailList(params)
			);
		} finally {
			hideLoading();
			setRefetchData && setRefetchData(false);
		}
	};

	const handleOnClickViewLogs = (row) => {
		setLogDetails(
			mapViewLogs(
				{
					partnerName: row?.original?.partnerName,
					productTypeName: row?.original?.productTypeName,
					channelName: row?.original?.channelName,
					branchName: row?.original?.branchName,
					auditType: row?.original?.auditType,
				},
				row?.original?.oldValues,
				row?.original?.newValues
			)
		);
		showPartnerAuditModal();
	};

	const handleTableFetch = (params) => {
		const newFilter = formatParams({ ...filter, ...params });
		setFilter(newFilter);
		fetchAuditTrailList(newFilter);
	};
	const tablePaginationProps = useTablePagination(handleTableFetch, data?.meta);

	const tableProps = {
		columns: [
			{
				Header: 'Date and Time',
				id: 'createdAt',
				sortable: false,
				width: '25%',
				accessor: ({ createdAt }) => formatDate(createdAt),
			},
			{
				Header: 'Logged By',
				id: 'loggedBy',
				sortable: false,
				width: '12.5%',
			},
			{
				Header: 'Action',
				id: 'action',
				sortable: false,
				width: '25%',
			},
			{
				Header: 'Source IP',
				id: 'ipAddress',
				sortable: false,
				width: '12.5%',
			},
			{
				Header: 'Remarks',
				id: 'remarks',
				sortable: false,
				width: '12.5%',
			},
			{
				Header: '',
				id: 'logs',
				sortable: false,
				width: '12.5%',
				Cell: ({ row }) => (
					<div className={styles.actionContainer}>
						<div
							className={styles.action}
							onClick={() => handleOnClickViewLogs(row)}
						>
							View Logs
						</div>
					</div>
				),
			},
		],
		scrollable: true,
		data: data?.data,
		showPagination: true,
		className: styles.tableContainerAuditTrail,
		...tablePaginationProps,
	};

	const VIEW_LOGS_COLUMNS = [
		{
			Header: 'Partner',
			id: 'partnerName',
			sortable: false,
			width: LOGS_COL_WIDTH,
		},
		{
			Header: 'Product Type',
			id: 'productTypeName',
			sortable: false,
			width: LOGS_COL_WIDTH,
		},
		{
			Header: 'TPA Account',
			id: 'channelName',
			sortable: false,
			width: LOGS_COL_WIDTH,
		},
		{
			Header: 'Branch',
			id: 'branchName',
			sortable: false,
			width: LOGS_COL_WIDTH,
		},
		{
			Header: 'TPAID',
			id: 'terminalName',
			sortable: false,
			width: LOGS_COL_WIDTH,
		},
		{
			Header: 'Old Values',
			id: 'oldValues',
			sortable: false,
			width: LOGS_COL_WIDTH,
			Cell: ({ value }) => (value === 'ACTIVE' ? 'Enabled' : 'Disabled'),
		},
		{
			Header: 'New Values',
			id: 'newValues',
			sortable: false,
			width: LOGS_COL_WIDTH,
			Cell: ({ value }) => (value === 'ACTIVE' ? 'Enabled' : 'Disabled'),
		},
	];

	useEffect(() => {
		fetchAuditTrailList();
	}, []);

	useEffect(() => {
		if (refetchData) {
			fetchAuditTrailList();
		}
	}, [refetchData]);

	return (
		<div>
			<PartnerDetailsTable tableProps={tableProps} />
			<Modal
				ariaHideApp={false}
				isOpen={isPartnerAuditModalVisible}
				onRequestClose={hidePartnerAuditModal}
				size="medium"
				align="center"
			>
				<div className={styles.customModalHeader}>View Logs</div>
				<Table
					columns={VIEW_LOGS_COLUMNS}
					data={logDetails}
					showPagination={false}
					isNotEvenRowColor={false}
					preHeader={null}
					modalIsOpen={false}
					className={styles.auditTrailContainer}
				/>
			</Modal>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</div>
	);
};
export default PartnerAuditTrail;
