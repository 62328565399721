import {
	addThousandsSeparator,
	isANumber,
} from 'components/Format/NumberFormatter';
import { isEmpty } from 'lodash';

export const TextFieldFormatter = {
	commaSeparated: (v: any) => {
		try {
			const sanitized = v.replaceAll(',', '');
			if (!isANumber(sanitized)) return v;
			else if (-sanitized)
				return sanitized
					?.split('')
					?.reverse()
					?.join('')
					?.replace(/(\d{3}\B)/g, '$1,')
					?.split('')
					?.reverse()
					?.join('');

			return v;
		} catch (error) {
			return v;
		}
	},
	decimalPlaces:
		(decimalPlaces = 2) =>
		(v: any) => {
			const allNum = v.replaceAll(',', '').replace(/[^\d.-]/g, '');

			const res = new Intl.NumberFormat('en-US', {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			}).format(allNum);
			return res;
		},
	amountFormat: (v: any): string => {
		try {
			const sanitized = v.replaceAll(',', '')?.split('.');
			const _biggo = BigInt(sanitized[0]);

			// Reset to empty string once text field value is  deleted
			if (isEmpty(sanitized[0]) && (!sanitized[1] || sanitized[1] == 0))
				return '';

			return (
				_biggo.toLocaleString('en-US') +
				'.' +
				(sanitized[1] || '00').toString().slice(0, 2).padEnd(2, '0')
			);
		} catch (e) {
			return v;
		}
	},
};

export const truncateDecimal = (
	number: string | number,
	digits: number
): BigInt | string => {
	if (!number.toString().includes('.'))
		return BigInt(number.toString() + '.' + '00'.substr(0, digits));

	const _numArray = number.toString()?.split('.');
	return BigInt(_numArray[0]) + '.' + _numArray[1].substr(0, digits);
};
