import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@salesforce/design-system-react/components/button';
import { ReactComponent as EditIcon } from 'assets/icons/ic-edit.svg';
import cx from 'classnames';
import Grid from 'components/Grid/Grid';
import SelectField from 'components/Inputs/SelectField/SelectField';
import Section, { SectionRow } from 'components/Section/Section';
import BranchForm from 'containers/ChannelManagement/Branch/BranchForm/BranchForm';
import { branchSchema } from 'containers/ChannelManagement/Branch/BranchForm/BranchSchema';
import { BranchType } from 'containers/ChannelManagement/Branch/BranchForm/types';
import { BranchDetailsProps } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/types';
import { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import styles from './BranchDetails.module.css';

const BranchDetails: React.FC<BranchDetailsProps> = ({
	defaultValues,
	updatedAt,
	updatedBy,
}) => {
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const branchForm = useForm<BranchType>({
		mode: 'all',
		defaultValues: defaultValues,
		resolver: yupResolver(branchSchema),
	});

	const branchName = useWatch<BranchType>({
		control: branchForm.control,
		name: 'branchBasicInformation.branchName',
	});

	return (
		<>
			<Section>
				<SectionRow verticalAlign="center">
					<Grid column size={6} of={12}>
						<div className={styles.title}>{branchName}</div>
					</Grid>
					<Grid column size={6} of={12}>
						<Grid container align="end">
							<Button
								onClick={() => setIsDisabled((isDisabled) => !isDisabled)}
								className={styles.linkBtn + ' ' + styles.edit}
							>
								<EditIcon />
								{isDisabled ? ' Edit Branch Details' : 'Exit Edit Mode'}
							</Button>
						</Grid>
					</Grid>
				</SectionRow>
				<SectionRow verticalAlign="center">
					<Grid column size={2} of={12}>
						<div className={styles.statusLabel}>Branch Status:</div>
					</Grid>
					<Grid column size={2} of={12}>
						<SelectField
							className={cx(styles.select, styles.active)}
							options={[{ label: 'Active', value: 'ACTIVE' }]}
							size="x-small"
							value={'ACTIVE'}
							disabled={isDisabled}
						/>
					</Grid>
					<Grid column size={8} of={12}>
						<Grid container align="end">
							<div className={styles.titleActionTextContainer}>
								<em className={styles.titleActionText}>
									{'Last updated at ' + updatedAt}
									{' by ' + updatedBy}
								</em>
							</div>
						</Grid>
					</Grid>
				</SectionRow>
			</Section>

			<FormProvider {...branchForm}>
				<BranchForm
					branchBasicInformationProp={{
						isDisabledAllEditableFields: isDisabled,
					}}
					branchAddress={{ isDisabledAllEditableFields: isDisabled }}
					branchContactDetails={{ disabled: isDisabled }}
					operatingScheduleProps={{ isDisabled: isDisabled }}
				/>
			</FormProvider>
		</>
	);
};

export default BranchDetails;
