import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { client } from 'helpers/ApiClient';
import { useToggle } from 'utils/hooks';
import _ from 'lodash';
import Grid from 'components/Grid/Grid';
import ChannelForm from 'containers/ChannelManagement/Channel/ChannelForm/ChannelForm';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import BillerStatusSelect from 'components/BillerForm/BillerStatusSelect/BillerStatusSelect';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import styles from 'containers/ChannelManagement/Channel/ChannelDetails/index.module.css';
import {
	AccountOfficerFields,
	BasicInformationFields,
	BillerContactFields,
	ChannelDetailsProps,
	LocalBillerAddressFields,
} from 'containers/ChannelManagement/Channel/ChannelDetails/types';

const defaultValue: any = {
	primaryInformation: {
		basicInformation: {},
		billerAddress: [
			{
				locationBase: 'LOCAL',
				country: {
					label: 'Philippines',
					value: '1',
				},
			},
		],
		billerContactDetails: [{ name: '', email: [], contactNumber: [] }],
	},
};

const capitalize = (s: any) =>
	(s && s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()) || 'Engaged';

const ChannelDetails: React.FC<ChannelDetailsProps> = ({ selectedChannel }) => {
	const [channelInfo, setChannelInfo] = useState<
		BasicInformationFields &
			LocalBillerAddressFields &
			BillerContactFields &
			AccountOfficerFields
	>();

	const [billerStatusSTG, setBillerStatusSTG] = useState<any>();
	const [billerStatusPROD, setBillerStatusPROD] = useState<any>();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const formattedChannelInfo: any = channelInfo
		? {
				primaryInformation: {
					basicInformation: {
						tpaAccountId: channelInfo.channelPrimaryInfo.tpaAccountId,
						channelName: channelInfo.channelName,
						businessGroup: channelInfo.channelPrimaryInfo.businessGroup,
						channelTransactionType:
							channelInfo.channelPrimaryInfo.transactionType,
						channelType: channelInfo.channelPrimaryInfo.channelType,
						tin: channelInfo.channelPrimaryInfo.tin,
						inCfs: channelInfo.channelPrimaryInfo.inCfs ? '1' : '0',
						businessType: channelInfo.channelPrimaryInfo.businessType,
						otherBusinessType: channelInfo.channelPrimaryInfo.otherBusinessType,
					},
					channelAddresses: !_.isEmpty(channelInfo?.channelAddresses)
						? channelInfo.channelAddresses?.map((address: any) => ({
								locationBase: address.locationBase,
								country: address.country,
								category: address.address_category,
								province: address.province,
								city: address.municipality,
								barangay: address.barangay,
								street: address.street,
								buildingNo: address.buildingName,
								zipCode: address.zipCode,
								area: address.area,
								region: address.region,
						}))
						: defaultValue.primaryInformation.billerAddress,
					channelContactDetails: _.isEmpty(channelInfo?.contact_details)
						? channelInfo.channelContactDetails?.map((contact: any) => ({
								name: contact.name,
								areaHandled: contact.area_handled,
								position: contact.position,
								department: contact.department,
								email:
									contact.email.length > 1
										? contact.email.split('|')
										: contact.email,
								contactNumber:
									contact.contactNumber.length > 1
										? contact.contactNumber.split('|')
										: contact.contactNumber,
						}))
						: defaultValue.primaryInformation.channelContactDetails,
					accountOfficer: {
						name: channelInfo.channelAccountOfficer.name,
						email:
							channelInfo.channelAccountOfficer.email.length > 1
								? channelInfo.channelAccountOfficer.email.split('|')
								: channelInfo.channelAccountOfficer.email,
						contactNumber:
							channelInfo.channelAccountOfficer.contactNumber.length > 1
								? channelInfo.channelAccountOfficer.contactNumber.split('|')
								: channelInfo.channelAccountOfficer.contactNumber,
					},
				},
			}
		: defaultValue;

	useEffect(() => {
		if (selectedChannel) {
			showLoading();

			const fetchBillerDetails = async () => {
				try {
					const response = await client.get(
						`/v1/channels/${selectedChannel.id}/primary-info`
					);
					console.log('Channel API', response.data.result);
					setChannelInfo({
						...response.data.result,
						channelName: selectedChannel.name,
					});
					await client
						.get(`/v1/passthru/biller/${selectedChannel.id}/status`)
						.then(({ data }) => {
							setBillerStatusSTG(data);
						})
						.catch((e) => {
							setBillerStatusSTG('Engage');
							hideLoading();
						});

					await client
						.get(`/v1/biller/${selectedChannel.id}/status`)
						.then(({ data }) => {
							setBillerStatusPROD(data);
						})
						.catch(() => {
							setBillerStatusPROD('Engage');
							hideLoading();
						});
				} finally {
					hideLoading();
				}
			};

			fetchBillerDetails();
		}
	}, [hideLoading, selectedChannel, showLoading]);

	return selectedChannel ? (
		<div className={styles.container}>
			<FullPageLoader
				open={isLoading}
				message="Please wait while biller details is being loaded"
			/>
			{selectedChannel && !isLoading && (
				<>
					<div className={styles.title}>
						{selectedChannel ? selectedChannel.name : 'Biller Name'}
					</div>
					<div className={styles.header}>
						<Grid container gutters="xx-small">
							<Grid column size={1} of={6}>
								<BillerStatusSelect
									labelPrefix="In STG"
									value={capitalize(billerStatusSTG?.staging?.biller_status)}
									disabled
								/>
							</Grid>
							<Grid column size={1} of={6}>
								<BillerStatusSelect
									labelPrefix="In Prod"
									value={capitalize(billerStatusPROD?.dev?.biller_status)}
									disabled
								/>
							</Grid>
						</Grid>
					</div>

					<ChannelForm disabled initialValues={formattedChannelInfo} />
				</>
			)}
		</div>
	) : (
		<>
			<PanelPlaceholder title={''} subtitle={''} />
		</>
	);
};

export default connect(
	(state: any) => ({ selectedChannel: state.channels.selectedChannel }),
	{}
)(ChannelDetails);
