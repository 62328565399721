import Grid from 'components/Grid/Grid';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import Section, { SectionRow } from 'components/Section/Section';
import { useFormContext } from 'react-hook-form';
import {
	IS_AUTO_RENEW_OPTIONS,
	RENEWAL_FREQUENCY_OPTIONS,
} from '../ContractDetailsConstants';
import { ContractDetailsType } from '../ContractDetailsSchemas';
type Props = {
	title: string;
	disabled: boolean;
	name: string;
};
const TermsAndDuration: React.FC<Props> = ({ disabled, title, name }) => {
	const { control, watch } = useFormContext<ContractDetailsType>();
	const { termsAndDuration } = watch();
	return (
		<Section title={title}>
			<SectionRow>
				<Grid column size={1} of={3}>
					<DatePickerField
						label="Execution Date"
						name={`${name}.executionDate`}
						control={control}
						disabled={disabled}
						placeholder="MM/DD/YYYY"
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<RadioGroup
						label="Is Auto Renew"
						control={control}
						name={`${name}.autoRenew`}
						disabled={disabled}
						options={IS_AUTO_RENEW_OPTIONS}
					/>
				</Grid>
				{termsAndDuration?.autoRenew == '0' && (
					<Grid column size={1} of={3}>
						<DatePickerField
							label="Termination Date"
							name={`${name}.terminationDate`}
							control={control}
							disabled={disabled}
							placeholder="MM/DD/YYYY"
							shouldUnregister
						/>
					</Grid>
				)}
				{termsAndDuration?.autoRenew == '1' && (
					<Grid container column size={1} of={3} gutters="xx-small">
						<Grid
							column
							size={1}
							of={
								termsAndDuration?.renewalFrequency === 'EVERY_N_YEARS' ? 2 : 1
							}
						>
							<SelectField
								label="Renewal Frequency"
								placeholder="Select Renewal Frequency"
								control={control}
								name={`${name}.renewalFrequency`}
								options={RENEWAL_FREQUENCY_OPTIONS}
								disabled={disabled}
								shouldUnregister
							/>
						</Grid>
						{termsAndDuration?.renewalFrequency === 'EVERY_N_YEARS' && (
							<Grid column size={1} of={2}>
								<TextField
									required
									label="Frequency Interval"
									placeholder={'0'}
									name={`${name}.frequencyInterval`}
									control={control}
									disabled={disabled}
									fixedTextRight="Years"
									shouldUnregister
								/>
							</Grid>
						)}
					</Grid>
				)}
			</SectionRow>
		</Section>
	);
};

export default TermsAndDuration;
