import styles from './Filter.module.css';
import { Controller, UseFormRegister, Control } from 'react-hook-form';
import Error, { IErrors } from './Error';
import SLDSDatepicker from '../../../components/Inputs/Datepicker/SLDSDatepicker';

export function validateDateFrom(dateFrom: any, dateTo: string): string | null {
	const message = 'Invalid date input';

	if (!dateFrom && dateTo) {
		return message;
	}

	if (dateTo) {
		return new Date(dateFrom) > new Date(dateTo) ? message : null;
	}
	return null;
}

export function validateDateTo(dateFrom: any, dateTo: any): string | null {
	const message = 'Invalid date input';

	if (dateFrom && !dateTo) {
		return message;
	}

	if (dateFrom) {
		return new Date(dateFrom) > new Date(dateTo) ? message : null;
	}
	return null;
}

interface IDatePicker<TFormValues> {
	register?: UseFormRegister<TFormValues>;
	errors: IErrors;
	label: string;
	name: string;
	control: Control<TFormValues>;
	validate: (v: any) => any;
	isVoidList?: boolean;
}

const DatePicker: React.FC<IDatePicker<any>> = ({
	register,
	errors,
	label,
	name,
	control,
	validate = (v) => ({}),
	isVoidList = false
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={{ validate }}
				render={({ field: { onChange, ...rest } }) => (
					<SLDSDatepicker
						{...rest}
						placeholder={label}
						className={!isVoidList && styles.datePicker}
						label={label}
						controlled
						onChange={(value: any) => onChange(value.date)}
					/>
				)}
			/>
			<Error name={name} errors={errors} />
		</>
	);
};

export default DatePicker;
