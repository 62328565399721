import React, { useEffect, useState, CSSProperties  } from 'react';
import styles from 'containers/ChannelManagement/List/style.module.css';
import { Button } from '@salesforce/design-system-react';
import {
    branchListFilter,
} from 'redux/modules/channelList'
import { AiOutlineClose, AiOutlineCheck, AiOutlineCaretDown } from 'react-icons/ai';
import { BranchDropdownCheckboxProps } from 'containers/ChannelManagement/List/types';

const BranchDropdownCheckbox: React.FC<BranchDropdownCheckboxProps> = ({
    branchFilter,
    dispatch
}) => {
    const [posType, setPosType] = useState<Array<any>>([])
    const [storeType, setStoreType] = useState<Array<any>>([])
    const [storeFormat, setStoreFormat] = useState<Array<any>>([])
    const [machineLocation, setMachineLocation] = useState<Array<any>>([])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectAll, setSelectAll] = useState<boolean>(false)

    const handleToggle = () => {
        setIsOpen(!isOpen)
    }

    const watchCheckbox = () => {
        if (posType.length === 6 &&
            storeType.length === 2 &&
            storeFormat.length === 2 &&
            machineLocation.length === 2) {
                setSelectAll(!selectAll)
        }
    }

    const handleSelectAll = () => {
        setSelectAll(!selectAll)
        if (!selectAll) {
            setPosType(['ASENSO', 'BFA', 'PCS', 'BIOS', 'KIOSK', 'FRONT_END'])
            setStoreType(['FULL-BOOTH', 'BOOTH'])
            setStoreFormat(['STANDALONE', 'MALL-BASED'])
            setMachineLocation(['INSIDE', 'OUTSIDE'])
            dispatch(branchListFilter({branchFilter: {
                name: branchFilter.name,
                posType: 'ASENSO|BFA|PCS|BIOS|KIOSK|FRONT_END',
                storeType: 'BOOTH|FULL-BOOTH',
                storeFormat: 'MALL-BASED|STANDALONE',
                machineLocation: 'INSIDE|OUTSIDE'
            }}))
        } else {
            setPosType([])
            setStoreType([])
            setStoreFormat([])
            setMachineLocation([])
            dispatch(branchListFilter({branchFilter: {
                name: branchFilter.name,
                posType: '',
                storeType: '',
                storeFormat: '',
                machineLocation: ''
            }}))
        }
    }

    const clearBranchFilter = () => {
        setSelectAll(false)
        setPosType([])
        setStoreType([])
        setStoreFormat([])
        setMachineLocation([])
        dispatch(branchListFilter({branchFilter: {
            name: '',
            posType: '',
            storeType: '',
            storeFormat: '',
            machineLocation: ''
        }}))
    }

    const handlePosTypeCheckbox = (value) => {
        if (posType.includes(value)) {
            setPosType(posType.filter((item) => item !== value))
        } else {
            setPosType([...posType, value])
        }
        watchCheckbox()
    }

    const handleStoreTypeCheckbox = (value) => {
        if (storeType.includes(value)) {
            setStoreType(storeType.filter((item) => item !== value))
        } else {
            setStoreType([...storeType, value])
        }
        watchCheckbox()
    }

    const handleStoreFormatCheckbox = (value) => {
        if (storeFormat.includes(value)) {
            setStoreFormat(storeFormat.filter((item) => item !== value))
        } else {
            setStoreFormat([...storeFormat, value])
        }
        watchCheckbox()
    }

    const handleMachineLocationCheckbox = (value) => {
        if (machineLocation.includes(value)) {
            setMachineLocation(machineLocation.filter((item) => item !== value))
        } else {
            setMachineLocation([...machineLocation, value])
        }
        watchCheckbox()
    }

    useEffect(() => {
        dispatch(branchListFilter({branchFilter: {
            name: branchFilter.name,
            posType: posType.join('|'),
            storeType: branchFilter.storeType,
            storeFormat: branchFilter.storeFormat,
            machineLocation: branchFilter.machineLocation
        }}))
    }, [posType])

    useEffect(() => {
        dispatch(branchListFilter({branchFilter: {
            name: branchFilter.name,
            posType: branchFilter.posType,
            storeType: storeType.join('|'),
            storeFormat: branchFilter.storeFormat,
            machineLocation: branchFilter.machineLocation
        }}))
    }, [storeType])

    useEffect(() => {
        dispatch(branchListFilter({branchFilter: {
            name: branchFilter.name,
            posType: branchFilter.posType,
            storeType: branchFilter.storeType,
            storeFormat: storeFormat.join('|'),
            machineLocation: branchFilter.machineLocation
        }}))
    }, [storeFormat])

    useEffect(() => {
        dispatch(branchListFilter({branchFilter: {
            name: branchFilter.name,
            posType: branchFilter.posType,
            storeType: branchFilter.storeType,
            storeFormat: branchFilter.storeFormat,
            machineLocation: machineLocation.join('|')
        }}))
    }, [machineLocation])

    const checkIcon = (
        <AiOutlineCheck style={{ color: "#fff", fontSize: "16px" }}/>
    )
    
    const customCheckboxStyle: CSSProperties = {
        top: "0",
        left: "0",
        width: "16px",
        height: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
    }

    const spanDynamicStyle: CSSProperties = {
        border: selectAll ? "" : "1px solid #706e6b",
        backgroundColor: selectAll ? "#f26122" : "#fff",
    }

    const asensoDynamicStyle: CSSProperties = {
        border: posType.includes("ASENSO") ? "" : "1px solid #706e6b",
        backgroundColor: posType.includes("ASENSO") ? "#f26122" : "#fff",
    }

    const bfaDynamicStyle: CSSProperties = {
        border: posType.includes("BFA") ? "" : "1px solid #706e6b",
        backgroundColor: posType.includes("BFA") ? "#f26122" : "#fff",
    }

    const pcsDynamicStyle: CSSProperties = {
        border: posType.includes("PCS") ? "" : "1px solid #706e6b",
        backgroundColor: posType.includes("PCS") ? "#f26122" : "#fff",
    }

    const biosDynamicStyle: CSSProperties = {
        border: posType.includes("BIOS") ? "" : "1px solid #706e6b",
        backgroundColor: posType.includes("BIOS") ? "#f26122" : "#fff",
    }

    const kioskDynamicStyle: CSSProperties = {
        border: posType.includes("KIOSK") ? "" : "1px solid #706e6b",
        backgroundColor: posType.includes("KIOSK") ? "#f26122" : "#fff",
    }

    const feDynamicStyle: CSSProperties = {
        border: posType.includes("FRONT_END") ? "" : "1px solid #706e6b",
        backgroundColor: posType.includes("FRONT_END") ? "#f26122" : "#fff",
    }

    const fullBoothDynamicStyle: CSSProperties = {
        border: storeType.includes("FULL-BOOTH") ? "" : "1px solid #706e6b",
        backgroundColor: storeType.includes("FULL-BOOTH") ? "#f26122" : "#fff",
    }

    const boothDynamicStyle: CSSProperties = {
        border: storeType.includes("BOOTH") ? "" : "1px solid #706e6b",
        backgroundColor: storeType.includes("BOOTH") ? "#f26122" : "#fff",
    }

    const standaloneDynamicStyle: CSSProperties = {
        border: storeFormat.includes("STANDALONE") ? "" : "1px solid #706e6b",
        backgroundColor: storeFormat.includes("STANDALONE") ? "#f26122" : "#fff",
    }

    const mallBasedDynamicStyle: CSSProperties = {
        border: storeFormat.includes("MALL-BASED") ? "" : "1px solid #706e6b",
        backgroundColor: storeFormat.includes("MALL-BASED") ? "#f26122" : "#fff",
    }

    const insideDynamicStyle: CSSProperties = {
        border: machineLocation.includes("INSIDE") ? "" : "1px solid #706e6b",
        backgroundColor: machineLocation.includes("INSIDE") ? "#f26122" : "#fff",
    }

    const outsideDynamicStyle: CSSProperties = {
        border: machineLocation.includes("OUTSIDE") ? "" : "1px solid #706e6b",
        backgroundColor: machineLocation.includes("OUTSIDE") ? "#f26122" : "#fff",
    }

    const checkboxText: CSSProperties = {
        paddingLeft: "10px"
    }

    return (
        <>
            <div>
                <Button className={styles.filterButton} onClick={handleToggle}>
                    Filter<AiOutlineCaretDown className={styles.filterIcon}/>
                </Button>
                {isOpen && ( 
                    <div className={styles.panel}>
                        <div className={styles.topPanel}>
                            <label className={styles.selectAll}>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                                <span style={{ ...customCheckboxStyle, ...spanDynamicStyle }}>
                                    {selectAll && checkIcon}
                                </span>
                                <span style={checkboxText}>Select All</span>
                            </label>
                            <label className={styles.clearAll}>
                                <button className={styles.clearButton} onClick={clearBranchFilter}>
                                    <AiOutlineClose className={styles.clearIcon} />
                                    Clear All Fields
                                </button>
                            </label>
                        </div>
                        <div className={styles.panelContent}>
                            <div className={styles.panelBody}>
                                <div className={styles.posCol}>
                                    <div><b>POS Type</b></div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="ASENSO"
                                            checked={posType.includes("ASENSO")}
                                            onChange={() => handlePosTypeCheckbox("ASENSO")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...asensoDynamicStyle }}>
                                            {posType.includes("ASENSO") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>Asenso</span>
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="BFA"
                                            checked={posType.includes("BFA")}
                                            onChange={() => handlePosTypeCheckbox("BFA")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...bfaDynamicStyle }}>
                                            {posType.includes("BFA") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>BFA</span>
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="PCS"
                                            checked={posType.includes("PCS")}
                                            onChange={() => handlePosTypeCheckbox("PCS")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...pcsDynamicStyle }}>
                                            {posType.includes("PCS") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>PCS</span>
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="BIOS"
                                            checked={posType.includes("BIOS")}
                                            onChange={() => handlePosTypeCheckbox("BIOS")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...biosDynamicStyle }}>
                                            {posType.includes("BIOS") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>BIOS</span>
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="KIOSK"
                                            checked={posType.includes("KIOSK")}
                                            onChange={() => handlePosTypeCheckbox("KIOSK")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...kioskDynamicStyle }}>
                                            {posType.includes("KIOSK") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>Kiosk/Self-Service</span>
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="FRONT_END"
                                            checked={posType.includes("FRONT_END")}
                                            onChange={() => handlePosTypeCheckbox("FRONT_END")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...feDynamicStyle }}>
                                            {posType.includes("FRONT_END") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>Own Front-end System</span>
                                    </label>
                                </div>
                                <div className={styles.storeCol}>
                                    <div><b>Store Type</b></div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="FULL-BOOTH"
                                            checked={storeType.includes("FULL-BOOTH")}
                                            onChange={() => handleStoreTypeCheckbox("FULL-BOOTH")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...fullBoothDynamicStyle }}>
                                            {storeType.includes("FULL-BOOTH") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>Full Booth</span>
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="BOOTH"
                                            checked={storeType.includes("BOOTH")}
                                            onChange={() => handleStoreTypeCheckbox("BOOTH")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...boothDynamicStyle }}>
                                            {storeType.includes("BOOTH") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>Booth</span>
                                    </label>
                                    <div style={{marginTop: '35px', marginBottom: '20px'}}><b>Store Format</b></div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="STANDALONE"
                                            checked={storeFormat.includes("STANDALONE")}
                                            onChange={() => handleStoreFormatCheckbox("STANDALONE")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...standaloneDynamicStyle }}>
                                            {storeFormat.includes("STANDALONE") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>Standalone</span>
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="MALL-BASED"
                                            checked={storeFormat.includes("MALL-BASED")}
                                            onChange={() => handleStoreFormatCheckbox("MALL-BASED")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...mallBasedDynamicStyle }}>
                                            {storeFormat.includes("MALL-BASED") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>Mall-based</span>
                                    </label>
                                </div>
                                <div className={styles.machineLocCol}>
                                    <div><b>Machine Location</b></div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="INSIDE"
                                            checked={machineLocation.includes("INSIDE")}
                                            onChange={() => handleMachineLocationCheckbox("INSIDE")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...insideDynamicStyle }}>
                                            {machineLocation.includes("INSIDE") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>Inside Biller Office</span>
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            value="OUTSIDE"
                                            checked={machineLocation.includes("OUTSIDE")}
                                            onChange={() => handleMachineLocationCheckbox("OUTSIDE")}
                                        />
                                        <span style={{ ...customCheckboxStyle, ...outsideDynamicStyle }}>
                                            {machineLocation.includes("OUTSIDE") && checkIcon}
                                        </span>
                                        <span style={checkboxText}>Outside Biller Office</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default BranchDropdownCheckbox