import client from "helpers/ApiClient";
import { useQuery } from "react-query";
import moment from 'moment';

export const useGetChannelApprovalList = (params: any) => {
    const transformParams = {
        ...params,
        createdAt: params.createdAt ? moment(params.createdAt).format('YYYY/MM/DD') : '',
        updatedAt: params.updatedAt ? moment(params.updatedAt).format('YYYY/MM/DD') : ''
    }
    return useQuery(['getApprovalList', params], async () => {
        const response = await client.get('/v2/channels/approvals', { params: transformParams })
        return response.data
    }, { initialData: { data: [] } })
}

export const useGetFeeApprovalList = (params: any) => {
    const transformParams = {
        ...params,
        createdAt: params.createdAt ? moment(params.createdAt).format('YYYY/MM/DD') : '',
        updatedAt: params.updatedAt ? moment(params.updatedAt).format('YYYY/MM/DD') : ''
    }
    return useQuery(['getApprovalList', params], async () => {
        const response = await client.get('/v2/channels/fees/approvals', { params: transformParams })
        return response.data
    }, { initialData: { data: [] } })
}

export const UseGetChannelPrimaryInfo = async (params: string | number | null | undefined) => {
    if (params) {
        const response = await client.get(`/v2/channels/${params}/primary-info`)
        return response.data
    } else return new Error('Invalid Channel ID')

}

export const UseGetChannelContract = async (params: number | null) => {
    const response = await client.get(`/v2/channels/${params}/contract-details`)
    return response.data
}

export const UseGetChannelReports = async (params: number | null) => {
    const response = await client.get(`/v2/channels/${params}/reports`)
    return response.data
}

export const UseApproveRejectChannel = async (ids, remarks, isApprove: boolean) => {
    const response = await client.post(`/v2/channels/${(isApprove ? 'approve' : 'reject')}`, { channelIds: ids, remarks: remarks })
    return response
} 

export const UseApproveRejectServiceFee = async (ids, remarks, isApprove: boolean) => {
    const response = await client.post(`/v2/channels/fees/${(isApprove ? 'approve' : 'reject')}`, { ids: ids, remarks: remarks })
    return response
} 

export const UseGetServiceFeeData = async (productId?: string, channelId?: string) => {
    const response = await client.get(`/v2/channels/${channelId}/products/${productId}/fees`)
    if (response.status) {
        console.log(response.status, response.statusText)
    }
    return response.data
}