import React, { FC } from 'react';
import { useToggle } from 'utils/hooks';
import styles from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal.module.css';
import { Modal } from '@salesforce/design-system-react/module/components';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import { restrictSpecialChars } from 'utils/common';
import cx from 'classnames';
import { ChannelProductsProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';

const ChannelProductModal: FC<ChannelProductsProps> = ({
	heading,
	message,
	isOpen,
	isDisabled,
	isPrimaryBtnDisabled,
	onClose,
	onCancelBtnClick,
	onConfirmBtnClick,
	cancelBtnLabel,
	confirmBtnLabel,
	showRemarks,
	data,
	error,
	handleChangeOfRemarks,
	disableOnClick = false,
	showTablefield,
	tableContent,
	headerClassName,
	containerClassName,
	contentClassName,
	removeHeading,
}) => {
	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				disable={isDisabled}
				headerClassName={headerClassName}
				containerClassName={containerClassName}
				contentClassName={contentClassName}
				heading={
					!removeHeading ? (
						<h2 className={styles.customHeading}>{heading}</h2>
					) : (
						''
					)
				}
				footer={
					!removeHeading ? (
						<div className={styles.rejectionModalFooter}>
							<OutlineButton
								onClick={onCancelBtnClick}
								className={styles.confirmationModalBtn}
							>
								{cancelBtnLabel}
							</OutlineButton>
							<PrimaryButton
								onClick={onConfirmBtnClick}
								className={styles.confirmationModalBtn}
								disabled={isPrimaryBtnDisabled}
							>
								{confirmBtnLabel}
							</PrimaryButton>
						</div>
					) : (
						''
					)
				}
			>
				<div className={styles.bodyHeader}>{message}</div>
				{showRemarks ? (
					<>
						<div className={styles.bodyContent}>
							<div
								className={cx(
									'slds-form-element',
									error.hasError && 'slds-has-error'
								)}
							>
								<label className="slds-form-element__label">
									Remarks
									<abbr className="slds-required" title="required">
										*
									</abbr>
								</label>
								<div className="slds-form-element__control">
									<textarea
										className={'slds-textarea' + ' ' + styles.remarks}
										maxLength={255}
										onInput={restrictSpecialChars}
										onChange={handleChangeOfRemarks}
									></textarea>
								</div>
								{error.hasError && (
									<div className="slds-form-element__help">
										<div>{error.status}</div>
									</div>
								)}
							</div>
							<em className={styles.remarksSubLabel}>
								*Maximum of 255 characters only.
							</em>
						</div>
					</>
				) : null}
				{showTablefield ? (
					<div>
						{tableContent}
						<>
							{removeHeading ? (
								<div className={styles.btnStyleClass}>
									<div className={styles.rejectionModalFooter}>
										<OutlineButton
											onClick={onCancelBtnClick}
											className={styles.confirmationModalBtn}
										>
											{cancelBtnLabel}
										</OutlineButton>
										<PrimaryButton
											onClick={onConfirmBtnClick}
											className={styles.confirmationModalBtn}
											disabled={isPrimaryBtnDisabled}
										>
											{confirmBtnLabel}
										</PrimaryButton>
									</div>
								</div>
							) : (
								''
							)}
						</>
					</div>
				) : null}
			</Modal>
		</>
	);
};

export default ChannelProductModal;
