import BasicInformation from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelBasicInformation/ChannelBasicInformation';
import ChannelAddress from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelAddress/ChannelAddress';
import ChannelContactDetails from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelContactDetails/ChannelContactDetails';
import AccountOfficer from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelOfficer/ChannelOfficer';
import { PrimaryInformationTabProps } from 'containers/ChannelManagement//Channel/ChannelForm/Tabs/PrimaryInformation/types';
import styles from './style.module.css';

const PrimaryInformationTab: React.FC<PrimaryInformationTabProps> = ({
	disabled,
}) => {
	return (
		<div className={styles.container}>
			<BasicInformation disabled={disabled} />
			<hr className={styles.divider} />
			<ChannelAddress maxEntries={20} disabled={disabled} />
			<hr className={styles.divider} />
			<ChannelContactDetails maxEntries={20} disabled={disabled} />
			<hr className={styles.divider} />
			<AccountOfficer disabled={disabled} />
		</div>
	);
};

export default PrimaryInformationTab;
