import React, { ReactNode, useState } from 'react';

import { SwitchWithTooltip } from 'components/Switch';
import ConfirmModalWithRemarks from 'components/Modal/ConfirmModalWithRemarks';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import SuccessModalContainer from 'components/Modal/SuccessModalContainer';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

import { useErrorModal, useLoader, useToggle } from 'utils/hooks';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import { UpdateProduct } from './types';
import styles from './Card.module.css';

type ProductCardProps = {
	code: string;
	productName: string;
	cardTitle: ReactNode | string;
	iconData: {
		icon: ReactNode;
		iconOff: ReactNode;
		color?: string;
		defaultColor?: string;
	};
	onClick?: (productName: string) => void;
	initialStatus: ReactNode | string;
	withConfirmModal?: boolean;
	handleSubmit?: (params: UpdateProduct) => void;
	setRefetchData?: (action: boolean) => void;
};

const ProductCard: React.FC<ProductCardProps> = ({
	code,
	productName,
	cardTitle,
	iconData,
	initialStatus,
	withConfirmModal = false,
	onClick,
	handleSubmit,
	setRefetchData,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const initialBoolStatus = initialStatus === 'ENABLED' ? true : false;
	const [status, setStatus] = useState<boolean>(initialBoolStatus);
	const [successMessage, setSuccessMessage] = useState<ReactNode | string>('');
	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();
	const [remarks, setRemarks] = useState<string>('');

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isConfirmModalShown,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const actionText = initialBoolStatus ? 'Disable' : 'Enable';
	const modalHeader = `${actionText} Product`;
	const bodyHeader = (
		<div>
			Are you sure you want to {actionText.toLowerCase()}{' '}
			<span className={styles.bodyHeaderEmphasis}>{productName}</span>?
		</div>
	);
	const defaultColor = iconData?.defaultColor ?? '#8b9095';

	return (
		<div className={styles.cardContainer}>
			<div className={styles.cardHeader}>
				<div className={styles.cardIcon}>
					{status ? iconData?.icon : iconData?.iconOff}
				</div>
				<div
					className={status ? styles.cardTitle : ''}
					style={{ color: status ? iconData?.color : defaultColor }}
					onClick={() => status && onClick && onClick(productName)}
				>
					{cardTitle}
				</div>
			</div>
			<div className={styles.cardDivider} />
			<div className={styles.cardFooter}>
				Status
				<div
					className={
						status ? styles.cardStatusEnabled : styles.cardStatusDisabled
					}
				>
					{status ? 'Enabled' : 'Disabled'}
					<SwitchWithTooltip
						name={productName}
						checked={status}
						onChange={(newvalue) => {
							if (
								(status && hasUserPermission('product.disable')) ||
								(!status && hasUserPermission('product.enable'))
							) {
								setStatus(newvalue);
								showConfirmModal();
							}
						}}
						toolTipContent={`${actionText} ${productName}`}
					/>
				</div>
			</div>
			{withConfirmModal && (
				<ConfirmModalWithRemarks
					isOpen={isConfirmModalShown}
					onClose={hideConfirmModal}
					onCancel={() => {
						setStatus(!status);
						hideConfirmModal();
					}}
					modalDisabled={false}
					confirmOnClick={() => {
						handleSubmit &&
							handleSubmit({
								showLoader: showLoadingMessage,
								hideLoader: hideLoading,
								showError: showErrorMessage,
								setSuccessLabel: setSuccessMessage,
								showSuccessPopup: showSuccessModal,
								hideError: hideErrorModal,
								apiPayload: {
									code,
									name: productName,
									bfaStatus: status ? 'ACTIVE' : 'INACTIVE',
									remarks,
								},
							});
					}}
					remarksOnChange={setRemarks}
					header={modalHeader}
					bodyHeader={bodyHeader}
					remarksSubLabel="Maximum of 1000 characters only."
					remarksMaxLength={1000}
				/>
			)}
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={() => {
					hideErrorModal();
					setRefetchData && setRefetchData(true);
				}}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
			<SuccessModalContainer
				isOpen={isSuccessModalOpen}
				onClose={() => {
					hideSuccessModal();
					setRefetchData && setRefetchData(true);
				}}
				successMessage={successMessage}
			/>
			{isLoading && withConfirmModal && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}
		</div>
	);
};

export default ProductCard;
