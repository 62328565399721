import client from 'helpers/ApiClient';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { resolveValue } from 'utils/common';

async function getPrimaryInfo(
	productType?: string,
	productId?: string
): Promise<any> {
	const response = await client.get(
		`v2/products/${resolveValue(productType)}/${resolveValue(
			productId
		)}/primary-info`
	);
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch primary info.');
	list.meta = response?.data?.meta;
	return list;
}

async function getContractDetails(
	productType?: string,
	productId?: string
): Promise<any> {
	const response = await client.get(
		`v2/products/${resolveValue(productType)}/${resolveValue(
			productId
		)}/contract-details`
	);
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch contract details.');
	return list;
}

async function getBusinessRules(
	productType?: string,
	productId?: string
): Promise<any> {
	const response = await client.get(
		`v2/products/${resolveValue(productType)}/${resolveValue(
			productId
		)}/business-rules`
	);
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch business rules.');
	return list;
}

async function getServiceFeeSettings(
	productType?: string,
	productId?: string
): Promise<any> {
	const response = await client.get(
		`v2/products/${resolveValue(productType)}/${resolveValue(
			productId
		)}/service-fee`
	);
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch service fee.');
	return list;
}

async function getReports(
	productType?: string,
	productId?: string
): Promise<any> {
	const response = await client.get(
		`v2/products/${resolveValue(productType)}/${resolveValue(
			productId
		)}/reports`
	);
	const list = response?.data?.data;
	if (!list) throw new Error('Unable to fetch service fee.');
	return list;
}

export type UsePartnerTabQueryKey =
	| 'primaryInfo'
	| 'contractDetails'
	| 'businessRules'
	| 'serviceFeeSettings'
	| 'reports';

export function usePartnerQuery(
	queryKey: UsePartnerTabQueryKey,
	params?: { productType?: string; productId?: string },
	config?: UseQueryOptions<any>
): UseQueryResult<any, Error> {
	return useQuery<any, Error>(
		[queryKey, params?.productId],
		(_key, ...rest) => {
			if (queryKey === 'primaryInfo') {
				return getPrimaryInfo(params?.productType, params?.productId);
			}
			if (queryKey === 'contractDetails') {
				return getContractDetails(params?.productType, params?.productId);
			}
			if (queryKey === 'businessRules') {
				return getBusinessRules(params?.productType, params?.productId);
			}
			if (queryKey === 'serviceFeeSettings') {
				return getServiceFeeSettings(params?.productType, params?.productId);
			}
			if (queryKey === 'reports') {
				return getReports(params?.productType, params?.productId);
			}
		},
		config
	);
}
