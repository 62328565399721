import React from 'react';
import Table from 'components/CWSTable/Table';
import styles from 'containers/ChannelManagement/Channel/ChannelIntegration/ChannelIntegration.module.css';
interface Props {
	columns: Array<any>;
	data: Array<any>;
	pageSize: number;
	count: number;
	onPageChange: (page, pageSize) => void;
	page: number;
}

const ChannelIntegrationTable: React.FC<Props> = ({
	columns,
	data,
	pageSize,
	count,
	onPageChange,
	page,
}) => {

	const mockData = [
		{
		  id: 1,
		  created_at: '2023-07-01T12:34:56Z',
		  certificate: 'KYSHANF331',
        },
        {
            id: 2,
            created_at: '2023-07-02T08:15:30Z',
            certificate: 'KYSQDGE153',
        },
		// Add more sample data objects as needed
	  ];

	return (
		<Table
			page={page}
			data={data || mockData}
			count={count || mockData.length}
			preHeader={null}
			scrollable={true}
			columns={columns}
			modalIsOpen={false}
			pageSize={pageSize}
			onPageChange={onPageChange}
			className={styles.withPreHeader}
		/>
	);
};

export default ChannelIntegrationTable;