import { useEffect, useMemo, useState } from 'react';
import { Control, UseFormSetValue, UseFormGetValues } from 'react-hook-form';
import { ServiceFeeSettingsFormData } from '../../ServiceFee/ServiceFeeSettings';
import {
	ServiceFeeField,
	TotalRateFee,
	useConditionalColumn,
} from '../../ServiceFee/ServiceFeesFields';

import Table from 'components/CWSTable/Table';

type RegularSchemaProps = {
	disabled?: boolean;
	control: Control<ServiceFeeSettingsFormData>;
	serviceFeeBillingType: ServiceFeeSettingsFormData['billing_type'];
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	withNonShareableAmount?: string;
	action?: string;
	modalIsOpen?:boolean;
};

const COLUMN_CONFIG = (
	disabled: RegularSchemaProps['disabled'],
	control: RegularSchemaProps['control'],
	serviceFeeBillType: RegularSchemaProps['serviceFeeBillingType'],
	getValues: RegularSchemaProps['getValues'],
	setValue?: RegularSchemaProps['setValue'],
	withNonShareableAmount?: RegularSchemaProps['withNonShareableAmount'],
	action?: string,
) => {
	const conditionalColumn = useConditionalColumn({ control });
	const [dynamicWidth, setDynamicWidth] = useState(0);

	useEffect(() => {
		if (withNonShareableAmount == '0')
			setDynamicWidth(100 / defaultColumns.length);
		else setDynamicWidth(100 / [...defaultColumns, nonShareableColumn].length);
	}, [withNonShareableAmount]);

	const nonShareableColumn = {
		Header: conditionalColumn.nonShareable.headerName,
		id: conditionalColumn.nonShareable.id,
		width: `${dynamicWidth}%`,
		Cell: ({ column: { id } }) => (
			<ServiceFeeField
				name={`regular.${id}`}
				defaultValue={action === 'ADD' ? '0' : undefined}
				control={control}
				placeholder={conditionalColumn.nonShareable.headerName}
				nonShareableColumn={conditionalColumn.nonShareable}
				isAmountField
				disabled={disabled || withNonShareableAmount === '0'}
			/>
		),
	};

	const defaultColumns = [
		{
			Header: conditionalColumn.subsidized.headerName,
			id: conditionalColumn.subsidized.id,
			Cell: ({ column: { id } }) => (
				<ServiceFeeField
					name={`regular.${id}`}
					defaultValue={action === 'ADD' ? '0' : undefined}
					control={control}
					placeholder={conditionalColumn.subsidized.headerName}
					nonShareableColumn={conditionalColumn.nonShareable}
					isAmountField={serviceFeeBillType?.SUBSIDIZED}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
					getValues={getValues}
					setValue={setValue}
					conditionalColumn={conditionalColumn}
				/>
			),
			width: `${dynamicWidth}%`,
		},
		{
			Header: conditionalColumn.passOn.headerName,
			id: conditionalColumn.passOn.id,
			width: `${dynamicWidth}%`,
			Cell: ({ column: { id } }) => (
				<ServiceFeeField
					name={`regular.${id}`}
					defaultValue={action === 'ADD' ? '0' : undefined}
					control={control}
					placeholder={conditionalColumn.passOn.headerName}
					nonShareableColumn={conditionalColumn.nonShareable}
					isAmountField={serviceFeeBillType?.PASS_ON}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
					getValues={getValues}
					setValue={setValue}
					conditionalColumn={conditionalColumn}
				/>
			),
		},
		{
			Header: conditionalColumn.total.headerName,
			id: conditionalColumn.total.id,
			width: `${dynamicWidth}%`,
			Cell: ({ column: { id } }) => (
				<TotalRateFee
					idName={conditionalColumn.total.id}
					name={`regular`}
					control={control}
					subsidizedName={conditionalColumn.subsidized.id}
					passOnName={conditionalColumn.passOn.id}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
					action={action}
				/>
			),
		},
	];

	if (withNonShareableAmount != '0')
		return [...defaultColumns, nonShareableColumn];

	return defaultColumns;
};

const RegularSchema: React.FC<RegularSchemaProps> = ({
	control,
	serviceFeeBillingType,
	withNonShareableAmount,
	getValues,
	setValue,
	disabled,
	action,
	modalIsOpen
}) => {
	const rows = useMemo(() => [{}], []);

	return (
		<>
			<Table
				columns={COLUMN_CONFIG(
					disabled,
					control,
					serviceFeeBillingType || {},
					getValues,
					setValue,
					withNonShareableAmount,
					action
				)}
				showPagination={false}
				data={rows}
				preHeader={null}
				modalIsOpen={modalIsOpen}
			/>
		</>
	);
};

export default RegularSchema;