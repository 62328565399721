import { decrypt } from 'utils/codeGenerator';
import { isEmpty } from 'lodash';
import {
	convertValue,
	convertValueToString,
	filterArrayValues,
	removeCharacterInStringValues,
	handleArrayWithSpaceValues,
} from 'utils/filter';

export const getProductStatus = (data) => {
	const prodStatusFields = [{ key: 'status', label: 'Status' }];

	const productStatusData = prodStatusFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.[field.key]?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);
	return productStatusData;
};

export const getProductBasicInfo = (data) => {
	const prodBasicInfoFields = [
		{ key: 'logo', label: 'Logo' },
		{ key: 'code', label: 'Merchant ID' },
		{ key: 'product_code', label: 'Partner Code' },
		{ key: 'merchant_trade_name', label: 'Merchant Trade Name' },
		{ key: 'product_trade_name', label: 'Product Trade Name' },
		{ key: 'product_short_name', label: 'Product Short Name' },
		{ key: 'category', label: 'Industry' },
		{ key: 'other_category', label: 'Other Industry' },
		{ key: 'sub_brand', label: 'Sub Brand' },
		{ key: 'place_of_incorporation', label: 'Place of Incorporation' },
		{ key: 'date_of_incorporation', label: 'Date of Incorporation' },
		{ key: 'website_address', label: 'Website Address' },
		{ key: 'legal_structure', label: 'Legal Structure' },
		{ key: 'tin', label: 'TIN' },
		{ key: 'consumer_base_count', label: 'Consumer Base Count' },
	];

	const partnerAddressFields = [
		{ key: 'location_base', label: 'Location Base' },
		{ key: 'address_category', label: 'Address Category' },
		{ key: 'building_no', label: 'Building Name/No.' },
		{ key: 'street', label: 'Street' },
		{ key: 'country', label: 'Country' },
		{ key: 'province', label: 'State/Province' },
		{ key: 'city', label: 'Municipality/City' },
		{ key: 'barangay', label: 'Barangay' },
		{ key: 'zip_code', label: 'Zip Code' },
	];
	const partnerContactFields = [
		{ key: 'name', label: 'Name' },
		{ key: 'position', label: 'Position' },
		{ key: 'department', label: 'Department' },
		{ key: 'email', label: 'Email Address' },
		{ key: 'tel_no', label: 'Telephone Number' },
		{ key: 'mobile_no', label: 'Mobile Number' },
	];
	const partnerProductAffiliatesFields = [
		{ key: 'code', label: 'Affiliate/Brand Merchant ID' },
		{ key: 'name', label: 'Affiliate/Brand Name' },
	];
	const partnerProductOfficerFields = [
		{ key: 'name', label: 'Name' },
		{ key: 'email', label: 'Email Address' },
		{ key: 'tel_no', label: 'Telephone Number' },
		{ key: 'mobile_no', label: 'Mobile Number' },
	];

	const productBasicInfoData = prodBasicInfoFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_primary_info?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_primary_info?.[field.key]?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);

	const partnerAddressData = partnerAddressFields
		.map(
			(field) =>
				data?.audit_detail?.product_primary_info?.product_addresses?.map(
					(detail,index) => {
						const eventIndex = index + 1;
						const eventLabel = `${eventIndex}/${data?.audit_detail?.product_primary_info?.product_addresses?.length}`;
						const label = field.label;
						const oldValues = detail?.[field.key]?.old_value;
						const newValues = detail?.[field.key]?.new_value;

						return {
							field_name: `${label} (${eventLabel})`,
							old_values: handleArrayWithSpaceValues(oldValues),
							new_values: handleArrayWithSpaceValues(newValues),
							index: index,
						};
					}
				) ?? []
		)
		.flat()
		.sort((a, b) => a.index - b.index)
		.filter(filterArrayValues);

	const partnerContactDetailsData = partnerContactFields
		.map(
			(field) =>
				data?.audit_detail?.product_primary_info?.product_contact_details?.map(
					(detail,index) => {
						const eventIndex = index + 1;
						const eventLabel = `${eventIndex}/${data?.audit_detail?.product_primary_info?.product_contact_details?.length}`;
						const label = field.label;
						const oldValues = detail?.[field.key]?.old_value;
						const newValues = detail?.[field.key]?.new_value;

						return {
							field_name: `${label} (${eventLabel})`,
							old_values: handleArrayWithSpaceValues(oldValues),
							new_values: handleArrayWithSpaceValues(newValues),
							index: index,
						};
					}
				) ?? []
		)
		.flat()
		.sort((a, b) => a.index - b.index)
		.filter(filterArrayValues);

	const partnerProductAffiliatesData = partnerProductAffiliatesFields
		.map(
			(field) =>
				data?.audit_detail?.product_primary_info?.product_affiliates?.map(
					(detail,index) => {
						const eventIndex = index + 1;
						const eventLabel = `${eventIndex}/${data?.audit_detail?.product_primary_info?.product_affiliates?.length}`;
						const label = field.label;
						const oldValues = detail?.[field.key]?.old_value;
						const newValues = detail?.[field.key]?.new_value;

						return {
							field_name: `${label} (${eventLabel})`,
							old_values: handleArrayWithSpaceValues(oldValues),
							new_values: handleArrayWithSpaceValues(newValues),
							index: index,
						};
					}
				) ?? []
		)
		.flat()
		.sort((a, b) => a.index - b.index)
		.filter(filterArrayValues);

	const partnerProductOfficerData = partnerProductOfficerFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_primary_info?.product_officer?.[field.key]
					?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_primary_info?.product_officer?.[field.key]
					?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);
	return [
		productBasicInfoData,
		partnerAddressData,
		partnerContactDetailsData,
		partnerProductAffiliatesData,
		partnerProductOfficerData,
	];
};

export const getProductContractDetail = (data) => {
	const collectionUploadAndRemittanceFields = [
		{ key: 'upload_schedule', label: 'Upload Schedule' },
		{ key: 'upload_time', label: 'Time of Upload' },
		{ key: 'deposit_schedule', label: 'Deposit Schedule' },
	];
	const oneTimeFeeFields = [
		{ key: 'one_time_fee', label: 'One Time Fee' },
		{ key: 'one_time_fee_amount', label: 'One Time Fee Amount' },
		{ key: 'collection_date', label: 'Date of Collection' },
	];
	const uploadPenaltyFields = [
		{ key: 'upload_penalty_type', label: 'Upload Penalty Type' },
		{ key: 'upload_penalty_rate', label: 'Upload Penalty Rate' },
	];

	const depositPenaltyFields = [
		{ key: 'deposit_penalty_type', label: 'Deposit Penalty Type' },
		{ key: 'deposit_penalty_rate', label: 'Deposit Penalty Rate' },
	];
	const otherPenaltiesFields = [
		{ key: 'penalty_name', label: 'Other Penalties' },
		{ key: 'penalty_type', label: 'Other Penalties Type' },
		{ key: 'penalty_rate', label: 'Other Penalties Rate' },
	];
	const partnerProductCashCollectionFields = [
		{ key: 'bank', label: 'Bank Name' },
		{ key: 'bank_branch', label: 'Bank Branch' },
		{ key: 'account_name', label: 'Account Name' },
		{ key: 'account_no', label: 'Account Number' },
		{ key: 'account_type', label: 'Account Type' },
	];
	const partnerProductCheckCollectionFields = [
		{ key: 'bank', label: 'Bank Name' },
		{ key: 'bank_branch', label: 'Bank Branch' },
		{ key: 'account_name', label: 'Account Name' },
		{ key: 'account_no', label: 'Account Number' },
		{ key: 'account_type', label: 'Account Type' },
	];
	const partnerProductTermsDurationFields = [
		{ key: 'execution_date', label: 'Execution Date' },
		{ key: 'auto_renew', label: 'Is Auto Renew?' },
		{ key: 'renewal_frequency', label: 'Renewal Frequency' },
		{ key: 'termination_date', label: 'Termination Date' },
	];
	const partnerProductDPOFields = [
		{ key: 'name', label: 'Name' },
		{ key: 'email', label: 'Email Address' },
		{ key: 'contact_no', label: 'Contact Number' },
		{ key: 'location_base', label: 'Local Base' },
		{ key: 'country', label: 'Country' },
		{ key: 'province', label: 'State/Province' },
		{ key: 'city', label: 'Municipality/City' },
		{ key: 'barangay', label: 'Barangay' },
		{ key: 'street', label: 'Street' },
		{ key: 'building_no', label: 'Building Name/No.' },
		{ key: 'zip_code', label: 'Zip Code' },
		{ key: 'execution_date', label: 'Execution Date' },
		{ key: 'termination_date', label: 'Termination Date' },
	];
	const partnerBondFields = [
		{ key: 'bond_type', label: 'Bond Type' },
		{ key: 'other_bond_type', label: 'Other Bond Type' },
		{ key: 'bond_amount', label: 'Bond Amount' },
		{ key: 'bond_premium_type', label: 'Bond Premium Type' },
		{ key: 'bond_premium_rate', label: 'Bond Premium Rate' },
		{ key: 'bond_renewal', label: 'Bond Renewal' },
		{ key: 'prefunded_amount', label: 'Prefunded Amount' },
		{ key: 'prefunded_threshold_amt', label: 'Prefunded Threshold Amount' },
		{ key: 'execution_date', label: 'Execution Date' },
		{ key: 'termination_date', label: 'Termination Date' },
	];
	const partnerProductAccreditationFields = [
		{ key: 'cbci_aml_question', label: 'Accomplished CBCI AML Questionnaire ' },
		{ key: 'annual_income_tax', label: 'Annual Income Tax Return (2 years)' },
		{
			key: 'audited_financial_stmt',
			label:
				'Audited Financial Statements (previous 2 years with Auditor’s Opinion & Notes to Financial Statements)',
		},
		{
			key: 'bir_certificate_form',
			label: 'BIR Certificate of Registration Form No. 2303',
		},
		{
			key: 'corp_secretary_cert',
			label:
				'Corporate Secretary’s Certificate or Board Resolution stating names and specimen signatures of all authorized signatories to transact business with CBCI',
		},
		{
			key: 'business_permit',
			label: 'Business Permit/Mayor’s Permit (updated/ current year)',
		},
		{ key: 'company_profile', label: 'Company Profile' },
		{
			key: 'govt_id',
			label: 'Government Issued ID of the authorized signatory',
		},
		{ key: 'gen_info_sheet', label: 'Latest General Information Sheet (GIS)' },
		{ key: 'non_disclosure', label: 'Non-disclosure Agreement (NDA)' },
		{ key: 'sec_reg_cert', label: 'SEC Registration Certificate' },
		{
			key: 'sec_article_inc',
			label:
				'SEC Articles of Incorporation & By-Laws (including all amendments, if any)',
		},
		{
			key: 'secondary_license',
			label:
				'Secondary license or certificate of authority issued by the supervising authority or other government agency (if applicable)',
		},
		{ key: 'passbook', label: 'First Page of Passbook' },
		{ key: 'data_privacy_pia', label: 'Data Privacy: PIA' },
		{ key: 'data_privacy_pat', label: 'Data Privacy: PAT' },
		{
			key: 'third_party_security_check',
			label: 'Third-Party Security Checklist',
		},
	];

	const collectionUploadAndRemittanceData = collectionUploadAndRemittanceFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.[field.key]?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);

	const oneTimeFeeData = oneTimeFeeFields
		.map((field) => {
			let newValue =
				data?.audit_detail?.product_contract_detail?.[field.key]?.new_value;
			let oldValue =
				data?.audit_detail?.product_contract_detail?.[field.key]?.old_value;

			if (field.key === 'one_time_fee') {
				newValue = convertValue(newValue);
				oldValue = convertValue(oldValue);
			}

			return {
				field_name: field.label,
				old_values: handleArrayWithSpaceValues(oldValue),
				new_values: handleArrayWithSpaceValues(newValue),
			};
		})
		.filter(filterArrayValues);

	const depositPenaltyData = depositPenaltyFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.[field.key]?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);

	const uploadPenaltyData = uploadPenaltyFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.[field.key]?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);

	const otherPenaltiesData = otherPenaltiesFields
		.flatMap(
			(field) =>
				data?.audit_detail?.product_contract_detail?.product_other_penalties?.map(
					(detail, index) => {
						const eventIndex = index + 1;
						const eventLabel = `${eventIndex}/${data?.audit_detail?.product_contract_detail?.product_other_penalties?.length}`;
						const label = field.label;

						const oldValues = detail?.[field.key]?.old_value;
						const newValues = detail?.[field.key]?.new_value;

						const oldValuesAsString = convertValueToString(oldValues);
						const newValuesAsString = convertValueToString(newValues);

						return {
							field_name: `${label} (${eventLabel})`,
							old_values: handleArrayWithSpaceValues(oldValuesAsString),
							new_values: handleArrayWithSpaceValues(newValuesAsString),
							index: index,
						};
					}
				) ?? []
		)
		.sort((a, b) => a.index - b.index)
		.filter(filterArrayValues);

	const partnerProductCashCollectionData = partnerProductCashCollectionFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.product_cash_collection?.[
					field.key
				]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.product_cash_collection?.[
					field.key
				]?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);

	const partnerProductCheckCollectionData = partnerProductCheckCollectionFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.product_check_collection?.[
					field.key
				]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.product_check_collection?.[
					field.key
				]?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);

	const partnerProductTermsDurationData = partnerProductTermsDurationFields
		.map((field) => {
			let newValue =
				data?.audit_detail?.product_contract_detail?.product_terms_duration?.[
					field.key
				]?.new_value;
			let oldValue =
				data?.audit_detail?.product_contract_detail?.product_terms_duration?.[
					field.key
				]?.old_value;

			if (field.key === 'auto_renew') {
				newValue = convertValue(newValue);
				oldValue = convertValue(oldValue);
			}

			return {
				field_name: field.label,
				old_values: handleArrayWithSpaceValues(oldValue),
				new_values: handleArrayWithSpaceValues(newValue),
			};
		})
		.filter(filterArrayValues);

	const partnerProductDPOData = partnerProductDPOFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.product_dpo_detail?.[
					field.key
				]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.product_dpo_detail?.[
					field.key
				]?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);

	const partnerProductAccreditationData = partnerProductAccreditationFields
		.map((field) => {
			let newValue =
				data?.audit_detail?.product_contract_detail?.product_accreditation?.[
					field.key
				]?.new_value;
			let oldValue =
				data?.audit_detail?.product_contract_detail?.product_accreditation?.[
					field.key
				]?.old_value;

			newValue = removeCharacterInStringValues(newValue);
			oldValue = removeCharacterInStringValues(oldValue);

			return {
				field_name: field.label,
				old_values: handleArrayWithSpaceValues(oldValue),
				new_values: handleArrayWithSpaceValues(newValue),
			};
		})
		.flat()
		.filter(filterArrayValues);

	const partnerBondData = partnerBondFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.product_dsa_bond?.[
					field.key
				]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_contract_detail?.product_dsa_bond?.[
					field.key
				]?.new_value
			),
		}))
		.flat()
		.filter(filterArrayValues);

	return [
		collectionUploadAndRemittanceData,
		oneTimeFeeData,
		uploadPenaltyData,
		depositPenaltyData,
		otherPenaltiesData,
		partnerProductCashCollectionData,
		partnerProductCheckCollectionData,
		partnerProductTermsDurationData,
		partnerProductDPOData,
		partnerBondData,
		partnerProductAccreditationData,
	];
};

export const getProductServiceFeeDetail = (data) => {
	const serviceFeeBillingTypeFields = [
		{ key: 'billing_type', label: 'Billing Type' },
	];

	const valueAddedTaxFields = [{ key: 'vat', label: 'Value Added Tax (VAT)' }];

	const withNonShareableAmountFields = [
		{ key: 'with_non_shareable', label: 'With Non-Shareable Amount?' },
		{ key: 'non_shareable_rate_type', label: 'Non-Shareable Rate Type' },
	];
	const serviceFeeSchemeTypeFields = [
		{ key: 'scheme_type', label: 'Service Fee Scheme Type' },
		{ key: 'tier_base_type', label: 'Tier Base Type' },
		{ key: 'tier_rate_type', label: 'Tier Rate Type' },
	];

	const withSFSettlementDeductionFields = [
		{
			key: 'with_sf_settle_deduction',
			label: 'With SF Settlement Deduction on Collections (Net Settlement)?',
		},
		{ key: 'frequency_type', label: 'Frequency Type' },
		{ key: 'frequency_month', label: 'Month' },
		{ key: 'frequency_day', label: 'Day' },
	];

	const partnerServiceFeeTiersFields = [
		{ key: 'lower_limit', label: 'Lower Limit' },
		{ key: 'upper_limit', label: 'Upper Limit' },
		{ key: 'subsidized_fee', label: 'Subsidized Fee' },
		{ key: 'pass_on_fee', label: 'Pass-on Fee' },
		{ key: 'total_fixed_rate', label: 'Total Fixed Rate' },
		{ key: 'subsidized_rate', label: 'Subsidized Rate' },
		{ key: 'pass_on_rate', label: 'Pass-on Rate' },
		{ key: 'total_percentage_rate', label: 'Total Percentage Rate' },
		{ key: 'non_share_fixed_rate', label: 'Non-Shareable Fixed Rate' },
		{ key: 'tier_number', label: 'No. of Tiers' },
		{ key: 'non_share_percentage', label: 'Non-Shareable Percentage' },
	];

	const serviceFeeBillingTypeData = serviceFeeBillingTypeFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_service_fee?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_service_fee?.[field.key]?.new_value
			),
		}))
		.filter(filterArrayValues);

	const valueAddedTaxData = valueAddedTaxFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_service_fee?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_service_fee?.[field.key]?.new_value
			),
		}))
		.filter(filterArrayValues);

	const withNonShareableAmountData = withNonShareableAmountFields
		.map((field) => {
			let newValue =
				data?.audit_detail?.product_service_fee?.[field.key]?.new_value;
			let oldValue =
				data?.audit_detail?.product_service_fee?.[field.key]?.old_value;

			if (field.key === 'with_non_shareable') {
				newValue = convertValue(newValue);
				oldValue = convertValue(oldValue);
			}

			return {
				field_name: field.label,
				old_values: handleArrayWithSpaceValues(oldValue),
				new_values: handleArrayWithSpaceValues(newValue),
			};
		})
		.filter(filterArrayValues);

	const serviceFeeSchemeTypeData = serviceFeeSchemeTypeFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_service_fee?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_service_fee?.[field.key]?.new_value
			),
		}))
		.filter(filterArrayValues);

	const withSFSettlementDeductionData = withSFSettlementDeductionFields
		.map((field) => {
			let newValue =
				data?.audit_detail?.product_service_fee?.[field.key]?.new_value;
			let oldValue =
				data?.audit_detail?.product_service_fee?.[field.key]?.old_value;

			if (field.key === 'with_sf_settle_deduction') {
				newValue = convertValue(newValue);
				oldValue = convertValue(oldValue);
			}

			return {
				field_name: field.label,
				old_values: handleArrayWithSpaceValues(oldValue),
				new_values: handleArrayWithSpaceValues(newValue),
			};
		})
		.filter(filterArrayValues);

	const partnerServiceFeeTiersData = partnerServiceFeeTiersFields
		.flatMap(
			(field) =>
				data?.audit_detail?.product_service_fee?.product_service_fee_tiers?.map(
					(detail, index) => {
						const eventIndex = index + 1;
						const eventLabel = `${eventIndex}/${data?.audit_detail?.product_service_fee?.product_service_fee_tiers?.length}`;
						const label = field.label;

						const oldValues = detail?.[field.key]?.old_value;
						const newValues = detail?.[field.key]?.new_value;

						const oldValuesAsString = convertValueToString(oldValues);
						const newValuesAsString = convertValueToString(newValues);
						return {
							field_name: `${label} (${eventLabel})`,
							old_values: handleArrayWithSpaceValues(oldValuesAsString),
							new_values: handleArrayWithSpaceValues(newValuesAsString),
							index: index,
						};
					}
				) || []
		)
		.sort((a, b) => a.index - b.index)
		.filter(filterArrayValues);
	return [
		serviceFeeBillingTypeData,
		valueAddedTaxData,
		withNonShareableAmountData,
		serviceFeeSchemeTypeData,
		withSFSettlementDeductionData,
		partnerServiceFeeTiersData,
	];
};

export const getProductBusinessRuleDetail = (data) => {
	const paymentModeFields = [
		{ key: 'payment_mode', label: 'Payment Mode' },
		{ key: 'pos_type', label: 'POS Type' },
	];
	const acceptedCheckTypesFields = [
		{ key: 'accepted_check_types', label: 'Accepted Check Types' },
		{ key: 'other_check_types', label: 'Other Check Types' },
	];
	const requiredDocumentsFields = [
		{ key: 'required_documents', label: 'Required Documents' },
		{ key: 'other_required_documents', label: 'Other Forms' },
	];

	const areaRestrictionFields = [
		{ key: 'area_restriction', label: 'Area Restriction' },
		{ key: 'memo', label: 'Memo' },
	];

	const amountValidationCashFields = [
		{ key: 'min_amount_cash', label: 'Min Amount cash' },
		{ key: 'max_amount_cash', label: 'Max Amount cash' },
	];
	const amountValidationCheckFields = [
		{ key: 'min_amount_check', label: 'Min Amount check' },
		{ key: 'max_amount_check', label: 'Max Amount check' },
	];
	const paymentAcceptedFields = [
		{ key: 'payment_accepted', label: 'Payment Accepted' },
	];
	const paymentAcceptancePolicyFields = [
		{ key: 'payment_acceptance_policy', label: 'Payment Acceptance Policy' },
		{ key: 'grace_period', label: 'Grace Period' },
	];

	const paymentModeData = paymentModeFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_business_rule?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_business_rule?.[field.key]?.new_value
			),
		}))
		.filter(filterArrayValues);

	const acceptedCheckTypesData = acceptedCheckTypesFields
		.map((field) => {
			const oldValues =
				data?.audit_detail?.product_business_rule?.[field.key]?.old_value;
			const newValues =
				data?.audit_detail?.product_business_rule?.[field.key]?.new_value;

			const newValue = removeCharacterInStringValues(oldValues);
			const oldValue = removeCharacterInStringValues(newValues);
			return {
				field_name: field.label,
				old_values: handleArrayWithSpaceValues(oldValue),
				new_values: handleArrayWithSpaceValues(newValue),
			};
		})
		.filter(filterArrayValues);

	const requiredDocumentsData = requiredDocumentsFields
		.map((field) => {
			const oldValues =
				data?.audit_detail?.product_business_rule?.[field.key]?.old_value;
			const newValues =
				data?.audit_detail?.product_business_rule?.[field.key]?.new_value;

			const newValue = removeCharacterInStringValues(oldValues);
			const oldValue = removeCharacterInStringValues(newValues);
			return {
				field_name: field.label,
				old_values: handleArrayWithSpaceValues(oldValue),
				new_values: handleArrayWithSpaceValues(newValue),
			};
		})
		.filter(filterArrayValues);

	const areaRestrictionData = areaRestrictionFields
		.map((field) => {
			let newValue =
				data?.audit_detail?.product_business_rule?.[field.key]?.new_value;
			let oldValue =
				data?.audit_detail?.product_business_rule?.[field.key]?.old_value;

			if (field.key === 'area_restriction') {
				newValue = convertValue(newValue);
				oldValue = convertValue(oldValue);
			}

			if (field.key === 'memo') {
				newValue = removeCharacterInStringValues(newValue);
				oldValue = removeCharacterInStringValues(oldValue);
			}

			return {
				field_name: field.label,
				old_values: handleArrayWithSpaceValues(oldValue),
				new_values: handleArrayWithSpaceValues(newValue),
			};
		})
		.filter(filterArrayValues);

	const amountValidationCashData = amountValidationCashFields
		.map((field) => {
			const oldValues =
				data?.audit_detail?.product_business_rule?.[field.key]?.old_value;
			const newValues =
				data?.audit_detail?.product_business_rule?.[field.key]?.new_value;

			const oldValuesAsString = convertValueToString(oldValues);
			const newValuesAsString = convertValueToString(newValues);

			return {
				field_name: field.label,
				old_values: handleArrayWithSpaceValues(oldValuesAsString),
				new_values: handleArrayWithSpaceValues(newValuesAsString),
			};
		})
		.filter(filterArrayValues);

	const amountValidationCheckData = amountValidationCheckFields
		.map((field) => ({
			field_name: field.label,
			old_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_business_rule?.[field.key]?.old_value
			),
			new_values: handleArrayWithSpaceValues(
				data?.audit_detail?.product_business_rule?.[field.key]?.new_value
			),
		}))
		.filter(filterArrayValues);

	const paymentAcceptedData = paymentAcceptedFields
		.map((field) => ({
			field_name: field.label,
			old_values: data?.audit_detail?.product_business_rule?.[
				field.key
			]?.old_value.replaceAll(',', ', '),
			new_values: data?.audit_detail?.product_business_rule?.[
				field.key
			]?.new_value.replaceAll(',', ', '),
		}))
		.filter(filterArrayValues);
	const paymentAcceptancePolicyData = paymentAcceptancePolicyFields
		.map((field) => {
			const oldValues = handleArrayWithSpaceValues(
				data?.audit_detail?.product_business_rule?.[field.key]?.old_value
			);
			const newValues = handleArrayWithSpaceValues(
				data?.audit_detail?.product_business_rule?.[field.key]?.new_value
			);
			return {
				field_name: field.label,
				old_values: oldValues,
				new_values: newValues,
			};
		})
		.filter(filterArrayValues);

	return [
		paymentModeData,
		acceptedCheckTypesData,
		requiredDocumentsData,
		areaRestrictionData,
		amountValidationCashData,
		amountValidationCheckData,
		paymentAcceptedData,
		paymentAcceptancePolicyData,
	];
};

export const getProductReportDetail = (data) => {
	const partnerReportConfigFields = [
		{ key: 'host', label: 'Host' },
		{ key: 'username', label: 'Username' },
		{ key: 'password', label: 'Password' },
		{ key: 'path', label: 'Path' },
		{ key: 'sender', label: 'Sender' },
		{ key: 'receivers', label: 'Receivers' },
	];
	const partnerReportEventsFields = [
		{ key: 'event_time', label: 'Generation Time' },
		{ key: 'covered_schedule', label: 'Covered Schedule' },
		{ key: 'covered_time_from', label: 'Covered Time From' },
		{ key: 'covered_time_to', label: 'Covered Time To' },
	];

	const partnerReportFields = [
		{ key: 'filename_format', label: 'Filename Format' },
		{ key: 'upload_schedule', label: 'Upload Schedule' },
		{ key: 'upload_frequency', label: 'Upload Frequency' },
		{ key: 'report_columns', label: 'Report Columns' },
	];

	const getTransmissionData = (transmissionType, fields, data) => {
		return fields
			.map((field) =>
				data?.audit_detail?.product_report
					?.filter((detail) => detail?.transmission_type === transmissionType)
					.map(
						(detail) =>
							detail?.report_events?.map((event, index) => {
								const eventIndex = index + 1;
								const eventLabel = `${eventIndex}/${detail.report_events.length}`;
								let label = field.label;

								if (field.key === 'event_time') {
									const eventType = event.event_type;
									if (eventType === 'GENERATE') {
										label = 'Generation Time';
									} else if (eventType === 'UPLOAD') {
										label = 'Time of Upload';
									}
								}
								return {
									index: eventIndex,
									field_name: `${label} (${eventLabel})`,
									old_values: handleArrayWithSpaceValues(
										event[field.key]?.old_value
									),
									new_values: handleArrayWithSpaceValues(
										event[field.key]?.new_value
									),
								};
							}) ?? []
					)
			)
			.sort((a, b) => a.index - b.index)
			.filter(filterArrayValues);
	};

	const getPartnerReportData = (transmissionType, fields, data) => {
		return partnerReportFields
			.map(
				(field) =>
					data?.audit_detail?.product_report
						?.filter((detail) => detail?.transmission_type === transmissionType)
						.map((detail) => ({
							field_name: field.label,
							old_values: handleArrayWithSpaceValues(
								detail?.[field.key]?.old_value
							),
							new_values: handleArrayWithSpaceValues(
								detail?.[field.key]?.new_value
							),
						})) || []
			)
			.filter(filterArrayValues);
	};

	const partnerProductConfigData = partnerReportConfigFields.flatMap(
		(field) =>
			data?.audit_detail?.product_report
				?.flatMap((detail) => {
					if (isEmpty(detail?.config)) return null;

					const decryptedConfig = decrypt(
						detail?.config,
						process.env.REACT_APP_APP_KEY,
						true,
						true
					);

					return {
						field_name: field.label,
						old_values: decryptedConfig?.[field.key]?.old_value.replaceAll(
							',',
							', '
						),
						new_values: decryptedConfig?.[field.key]?.new_value.replaceAll(
							',',
							', '
						),
					};
				})
				.filter(filterArrayValues) ?? []
	);

	const partnerReportSFTPFTPData = getPartnerReportData(
		'SFTP',
		partnerReportFields,
		data
	);
	const partnerSFTPFTPData = getTransmissionData(
		'SFTP',
		partnerReportEventsFields,
		data
	);

	const partnerReportSFTPUCData = getPartnerReportData(
		'SFTP_UC',
		partnerReportFields,
		data
	);

	const partnerSFTPUCData = getTransmissionData(
		'SFTP_UC',
		partnerReportEventsFields,
		data
	);

	const partnerReportSMTPData = getPartnerReportData(
		'SMTP',
		partnerReportFields,
		data
	);

	const partnerSMTPData = getTransmissionData(
		'SMTP',
		partnerReportEventsFields,
		data
	);

	const partnerReportSMTP_UCData = getPartnerReportData(
		'SMTP_UC',
		partnerReportFields,
		data
	);

	const partnerSMTP_UCData = getTransmissionData(
		'SMTP_UC',
		partnerReportEventsFields,
		data
	);

	return [
		partnerProductConfigData,
		partnerReportSFTPFTPData,
		partnerSFTPFTPData,
		partnerSFTPUCData,
		partnerReportSFTPUCData,
		partnerReportSMTPData,
		partnerSMTPData,
		partnerReportSMTP_UCData,
		partnerSMTP_UCData,
	];
};
