import { Modal } from '@salesforce/design-system-react/module/components';
import { useEffect, useState } from 'react';
import Grid from 'components/Grid/Grid';
import styles from './FilePreviewModal.module.css';
import { ImageFileTypes } from 'components/FilePreview/FileThumbnail';
import { ProgressBar, Viewer } from '@react-pdf-viewer/core';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { ReactComponent as NoPreviewIcon } from 'assets/icons/ic-no-preview.svg';
import { ReactComponent as TimeoutIcon } from 'assets/icons/ic-broken-image.svg';
import downloadButton from '../../assets/icons/ic-download4.svg';
import HTTP from 'helpers/ApiClient';
import Button from 'components/Buttons/Button';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import bg from '../../assets/icons/ic-img.svg';

type FilePreviewModalProps = {
	open?: boolean;
	onClose?: () => void;
	file?: any;
	downloadUrl?: string;
	handleRetry?: () => void;
	isTrimFileName?: string;
};
type ImagePreviewProps = {
	url: string;
	alt?: string;
};

const ImagePreview: React.FC<ImagePreviewProps> = ({
	url: filePath,
	alt = 'preview',
}) => <img src={filePath} alt={alt} className={styles.imagePreview} />;

const FilePreview: React.FC<{
	fileType: string;
	filePath: string;
	error?: boolean;
	handleRetry?: () => void;
}> = ({ filePath, fileType, error, handleRetry }) => {
	const [percentage, setPercentage] = useState(0);

	useEffect(() => {
		if (fileType.toLowerCase() === 'pdf') {
			setPercentage(0);
			const min = 50;
			const max = 100;
			const interval = Math.floor(Math.random() * (max - min + 1)) + min;
			const timer = setInterval(() => {
				setPercentage((prevProgress) => {
					if (prevProgress < 100) {
						return prevProgress + 10;
					}
					return prevProgress;
				});
			}, interval);

			return () => {
				clearInterval(timer);
			};
		}
	}, []);

	if (!open) return null;

	const f = fileType.toLowerCase();
	if (error) {
		return (
			<div className={styles.noPreview}>
				<TimeoutIcon />
				<span style={{ fontSize: '0.7vw' }}>
					Failed to load file. Kindly click reload.
				</span>
				{handleRetry && (
					<Button onClick={handleRetry} className={styles.retryButton}>
						Reload
					</Button>
				)}
			</div>
		);
	}
	if (ImageFileTypes.includes(f)) {
		return <ImagePreview url={filePath} />;
	}

	if (f === 'pdf' && filePath) {
		return (
			<Viewer
				fileUrl={filePath}
				renderLoader={() => (
					<>
						<div className={styles.container}>
							<div className={styles.progressContainer}>
								<img src={bg} className={styles.BG}></img>
								<div>Please wait while file is being loaded</div>
								<div className={styles.progress}>
									<CircularProgressbar
										value={percentage}
										text={`${percentage}%`}
										styles={buildStyles({
											textSize: '1.5em',
											pathColor: '#f26122',
											textColor: 'black',
											trailColor: `rgba(0, 0, 0, ${percentage / 100})`,
											backgroundColor: '#3e98c7',
										})}
									/>
								</div>
							</div>
						</div>
					</>
				)}
			/>
		);
	}

	return (
		<div className={styles.noPreview}>
			<NoPreviewIcon />
			<span style={{ fontSize: '0.7vw' }}>This file has no preview.</span>
		</div>
	);
};

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({
	open,
	onClose,
	file,
	downloadUrl,
	handleRetry,
	isTrimFileName,
}) => {
	const [fileUrl, setFileUrl] = useState<any>('');
	useEffect(() => {
		if (file.url?.error) {
			setFileUrl('');
			return;
		}
		if (file.url) {
			setFileUrl(file.url);
		} else {
			setFileUrl(URL.createObjectURL(file.file));
		}
	}, [file]);
	const [response, setResponse] = useState({
		isOpen: false,
		bodyText: '',
		bodyHeader: 'File is being downloaded',
		respondButton: {
			name: 'Done',
			event: () => {
				setResponse({
					...response,
					isOpen: false,
				});
			},
		},
		isRespondButton2Shown: false,
		small: true,
	});
	const handleDownload = async () => {
		const download = await HTTP.get(downloadUrl, {
			params: { key: file.fileKey },
		});
		fetch(download.data.data.download_url)
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				a.download = file.fileName.slice(37);
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			});
	};
	return (
		<>
			{file?.name}
			{file && (
				<Modal
					isOpen={open}
					onRequestClose={onClose}
					headerClassName={styles.header}
					size="small"
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
				>
					<Grid vertical verticalAlign="center" className={styles.container}>
						<div className={styles.title}>
							{isTrimFileName ? file?.fileName?.slice(37) : file?.fileName}
							{downloadUrl && (
								<img
									src={downloadButton}
									key={file}
									alt="download"
									className={styles.download}
									onClick={() => handleDownload()}
									type="button"
								/>
							)}
							<span className={styles.closeButton} onClick={onClose}>
								<CloseIcon height="22px" width="22px" />
							</span>
						</div>

						<Grid column className={styles.modalBody}>
							<FilePreview
								fileType={file.fileName?.split('.').slice(-1)[0]}
								filePath={fileUrl}
								error={file?.url?.error ?? false}
								handleRetry={handleRetry}
							/>
						</Grid>
					</Grid>
				</Modal>
			)}
		</>
	);
};

export default FilePreviewModal;
