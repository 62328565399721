import React, { useState } from 'react';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import SearchFilter from './SearchFilter/SearchFilter';
import AccordionContainer from './AccordionContainer/AccordionContainer';
import {
	Filter,
	PartnerProductDetailsProps,
} from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import { debounce } from 'lodash';

const PartnerProductDetails: React.FC<PartnerProductDetailsProps> = ({
	tab,
	data,
}) => {
	const initialFilterFields: Filter = {
		searchFilter: '',
		statusItemChecked: [],
	};
	const [filterFields, setFilterFields] = useState<Filter>(initialFilterFields);

	const activeTab = {
		title: 'Product Partners',
		action: 'Action',
	};

	const filterDebounce = debounce((value) => {
		setFilterFields(value);
	}, 800);

	return (
		<div style={{ maxHeight: '100px' }}>
			<Breadcrumbs tab={tab} />
			<SearchFilter
				onFilterChange={filterDebounce}
				hasStatusFilter
				size={4}
				textBoxName="filterPartner.partnerName"
				textBoxPlaceholder="Search Partner Name"
				dropDownPlaceholder="Status"
				dropDownName="filter.status"
			/>
			<AccordionContainer
				data={activeTab}
				selectedChannel={data}
				isEnabledFilter={filterFields}
			/>
		</div>
	);
};

export default PartnerProductDetails;
