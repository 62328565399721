import Grid from 'components/Grid/Grid';
import styles from './style.module.css';
import { SideHeadersProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/SideHeaders/types';

const SideHeaders: React.FC<SideHeadersProps> = ({ values }) => {
	return (
		<Grid container>
			<Grid container vertical column size={1} of={3}>
				{values.map(({ label, value }) => (
					<Grid
						key={label}
						container
						align="start"
						className={styles.headerRow}
					>
						<Grid column size={1} of={2}>
							<div className={styles.bold}>{label}</div>
						</Grid>
						<Grid column size={1} of={2}>
							{value}
						</Grid>
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};

export default SideHeaders;
