import { common } from './common';
import * as yup from 'yup';
import { DateValue } from 'components/Inputs/Datepicker/NewPartnerSelectDatepicker';
import { product_address_schema } from './product_address';
import { product_affiliate_schema } from './product_affiliate';
import { product_contact_detail_schema } from './product_contact_detail';
import { product_officer_schema } from './product_officer';
import {
	transformDate,
	transformDateFormat,
	validateDateSchema,
	websiteSchema,
} from 'utils/formSchemas/common';
import { noWhiteSpace } from 'utils/common';

export type product_primary_info = {
	logo?: { objectKey?: string; url?: string; filename?: string };
	product_trade_name?: string;
	merchant_trade_name?: string;
	product_short_name?: string;
	category_id?: number;
	other_category?: string;
	sub_brand?: string;
	place_of_incorporation?: string;
	date_of_incorporation?: string | DateValue;
	//date_of_incorporation?: string | MonthValue;
	//date_of_incorporation?: string | DayValue;
	//date_of_incorporation?: string | YearValue;
	//date_of_incorporation_month?: string | MonthValue;
	//date_of_incorporation_day?: string | DayValue;
	//date_of_incorporation_year?: string | YearValue;
	website_address?: string;
	legal_structure?: string;
	other_legal_struture?: string;
	tin?: string;
	consumer_base_count?: number;
	remarks?: string;
	created_by?: string;
	created_by_email?: string;
	updated_by?: string;
	updated_by_email?: string;
	submitted_by?: string;
	submitted_by_email?: string;
	product_code?: string;
} & common;

export const product_primary_info_schema = yup.object().shape({
	product_trade_name: yup
		.string()
		.nullable()
		.label('Partner Trade Name')
		.required()
		.max(255, 'You can only input a max of 255 characters.')
		.matches(/^[^:/;<>=]*$/, {
			excludeEmptyString: false,
			message: `This field accepts any characters except the following: :/;<>=`,
		})
		.test(noWhiteSpace()),
	merchant_trade_name: yup
		.string()
		.nullable()
		.label('Merchant Trade Name')
		.required()
		.max(255, 'You can only input a max of 255 characters.')
		.matches(/^[^:/;<>=]*$/, {
			excludeEmptyString: false,
			message: `This field accepts any characters except the following: :/;<>=`,
		})
		.test(noWhiteSpace()),

	product_short_name: yup
		.string()
		.nullable()
		.label('Partner Short Name')
		.required()
		.max(100, 'You can only input a max of 100 characters.')
		.test(noWhiteSpace()),

	category_id: yup
		.string()
		.nullable()
		.label('Industry')
		.required('Select Industry.'),
	other_category: yup
		.string()
		.nullable()
		.label('Other Industry')
		.max(50, 'You can only input a max of 50 alphanumeric characters.')
		.matches(/^[a-z|A-Z|0-9\s]*$/, {
			excludeEmptyString: false,
			message: `This field only allows alphanumeric characters.`,
		})
		.when('category_id', {
			is: 'Others',
			then: yup.string().required('Input Other Industry.'),
		}),
	sub_brand: yup
		.string()
		.nullable()
		.label('Sub-Brands')
		.max(50, 'You can only input a max of 50 alphanumeric characters')
		.matches(/^[a-z|A-Z|0-9\s]*$/, {
			excludeEmptyString: false,
			message: `This field only allows alphanumeric characters.`,
		})
		.test(noWhiteSpace()),
	place_of_incorporation: yup
		.string()
		.nullable()
		.label('Place of Incorporation')
		.required()
		.max(1000, 'You can only input a max of 1000 characters.')
		.test(noWhiteSpace()),
	date_of_incorporation: yup
		.mixed()
		.nullable()
		.label('Date of Incorporation')
		.transform(transformDateFormat)
		.test('valid-date', 'Invalid date format.', validateDateSchema)
		.required('Input Date of Incorporation'),
	website_address: websiteSchema
		.label('Website Address')
		.max(255, 'You can only input a max of 255 characters.'),
	legal_structure: yup
		.string()
		.nullable()
		.label('Legal Structure')
		.required('Select Legal Structure.'),
	other_legal_struture: yup
		.string()
		.nullable()
		.label('Other Legal Structure')
		.max(50, 'You can only input a max of 50 alphanumeric characters.')
		.matches(/^[a-z|A-Z|0-9\s]*$/, {
			excludeEmptyString: false,
			message: `This field only allows alphanumeric characters.`,
		})
		.when('legal_structure', {
			is: 'OTHERS',
			then: yup
				.string()
				.required('Input Other Legal Structure')
				.test(noWhiteSpace()),
		}),
	tin: yup
		.string()
		.nullable()
		.label('TIN')
		.required()
		.max(12, 'Input 12 numeric character only.')
		.matches(/^[0-9]{12}$/, {
			excludeEmptyString: false,
			message: 'Input 12 numeric character only.',
		}),
	consumer_base_count: yup
		.string()
		.nullable()
		.label('Consumer Base Count')
		.transform((curr) => (curr ? curr.replaceAll(',', '') : curr))
		.matches(/^[0-9]*$/, {
			excludeEmptyString: false,
			message: 'You can only input numeric characters.',
		})
		.when('$isRmtProduct', (isRmtProduct: boolean, schema) =>
			isRmtProduct ? schema : schema.required()
		),
});

export const primaryInformationSchema = yup.object().shape({
	code: yup
		.string()
		.nullable()
		.label('Merchant ID')
		.required()
		.max(5, 'You can only input a max of 5 characters.')
		.matches(/^[a-z|A-Z|0-9_]{1,5}$/, {
			excludeEmptyString: false,
			message: `This field only allows alphanumeric characters.`,
		}),
	product_code: yup
		.string()
		.nullable()
		.label('Partner Code')
		.when('product_code_type', {
			is: (v: string) => v !== 'AUTO',
			then: () => yup.string().required(),
		}),
	product_primary_info: product_primary_info_schema,
	product_affiliates: yup.array().nullable().of(product_affiliate_schema),
	product_addresses: yup.array().nullable().of(product_address_schema),
	product_contact_details: yup
		.array()
		.nullable()
		.of(product_contact_detail_schema),
	product_officer: product_officer_schema,
});
