import Grid from "components/Grid/Grid"
import SelectField from "components/Inputs/SelectField/SelectField"
import TextField from "components/Inputs/TextField/TextField"
import Section, { SectionRow } from "components/Section/Section"
import { useFormContext } from "react-hook-form"
import { VAT_TYPE_OPTIONS } from "../ContractDetailsConstants"
import { ContractDetailsType } from "../ContractDetailsSchemas"

type Props = {
    title: string;
    name: string;
    disabled: boolean;
}
const ValueAddedTax: React.FC<Props> = ({
    title, name, disabled
}) => {
    const { control, watch } = useFormContext<ContractDetailsType>();
    const { valueAddedTax } = watch()

    return (
        <Section title={title}>
            <SectionRow>
                <Grid column size={1} of={3}>
                    <SelectField
                        required
                        label="Account Type"
                        name={`${name}.vatType`}
                        control={control}
                        disabled={disabled}
                        placeholder="Select Account Type"
                        options={VAT_TYPE_OPTIONS}
                    />
                </Grid>
                {
                    valueAddedTax?.vatType === 'VATABLE' &&
                    <Grid column size={1} of={3}>
                        <TextField
                            required
                            label="Vat Rate"
                            disabled={true}
                            placeholder="12%"
                        />
                    </Grid>
                }

            </SectionRow>
        </Section>
    )
}

export default ValueAddedTax