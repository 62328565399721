import { useMemo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import Grid from 'components/Grid/Grid';
import { SelectFieldWithOthers } from 'components/Inputs/SelectFieldWithOthers/SelectFieldsWithOthers';
import TextField from 'components/Inputs/TextField/TextField';
import TimeInput from 'components/Inputs/TimeField/TimeField';
import Section, { SectionRow } from 'components/Section/Section';
import { UploadSchedule } from 'utils/lookup';
import {
	COVERED_SCHEDULE,
	PartnerReportsFormData,
	TransferProtocolKey,
} from '..';
import styles from './style.module.css';
import { FILE_FORMATS } from '../CsrConfiguration';

type NamePrefix = `${TransferProtocolKey}.${
	| 'uploadConfiguration'
	| 'csrConfiguration'}`;

type Props = {
	name: NamePrefix;
	title: string;
	disabled?: boolean;
};

type TimeEntryProps = {
	name: `${Props['name']}.timestamps.${number}`;
	labelSuffix?: string;
	disabled?: boolean;
};

const TimeEntry: React.FC<TimeEntryProps> = ({
	name,
	labelSuffix,
	disabled,
}) => {
	const { control, watch } = useFormContext<PartnerReportsFormData>();

	function appendSuffix(label: string) {
		if (!labelSuffix) return label;

		return `${label} ${labelSuffix}`;
	}

	return (
		<SectionRow className={styles.timeContainer}>
			<Grid column container vertical className={styles.time}>
				<Grid column container>
					<Grid column size={1} of={2} className={styles.grid}>
						<TimeInput
							label={appendSuffix('Generation Time')}
							required
							control={control}
							name={`${name}.generationTime`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={2} className={styles.grid}>
						<TimeInput
							label={appendSuffix('Time of Upload')}
							required
							control={control}
							name={`${name}.timeOfUpload`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
				</Grid>
				<Grid column container>
					<Grid column size={1} of={3} className={styles.grid}>
						<SelectFieldWithOthers
							label={appendSuffix('Covered Schedule')}
							required
							control={control}
							name={`${name}.coveredSchedule`}
							shouldUnregister
							options={COVERED_SCHEDULE}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3} className={styles.grid}>
						<TimeInput
							label={appendSuffix('Covered Time (from)')}
							required
							control={control}
							name={`${name}.coveredTime.from`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3} className={styles.grid}>
						<TimeInput
							label={appendSuffix('Covered Time (to)')}
							required
							control={control}
							name={`${name}.coveredTime.to`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>
		</SectionRow>
	);
};

const UploadConfiguration: React.FC<Props> = ({
	name,
	title,
	children,
	disabled,
}) => {
	const { control, setValue, watch } = useFormContext<PartnerReportsFormData>();
	const uploadSchedule = watch(`${name}.uploadSchedule`);
	const uploadFrequency = watch(`${name}.uploadFrequency`);
	const timestamps = watch(`${name}.timestamps`);

	useEffect(() => {
		if (uploadSchedule !== 'NEXT_DAY') return;

		setValue(`${name}.uploadFrequency`, 1);
	}, [uploadSchedule, name]);

	const fields = useMemo(() => {
		if (!uploadFrequency || uploadFrequency > 25) return 0;
		else if (uploadFrequency < 0) return 0;
		return +uploadFrequency;
	}, [uploadFrequency]);

	return (
		<Section title={title}>
			<SectionRow>
				<Grid column size={1} of={3} className={styles.grid}>
					<SelectFieldWithOthers
						label="Filename Format"
						required
						control={control}
						name={`${name}.filenameFormat`}
						options={FILE_FORMATS.map((i) => ({
							label: i,
							value: i,
						}))}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3} className={styles.grid}>
					<SelectFieldWithOthers
						label="Upload Schedule"
						required
						control={control}
						name={`${name}.uploadSchedule`}
						options={UploadSchedule}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={1} of={3} className={styles.grid}>
					<TextField
						label="Upload Frequency"
						required
						type="number"
						control={control}
						onKeyPress={(e) => {
							if (['e', '.', '-', '+'].indexOf(e.key) > -1) {
								e.preventDefault();
							}
						}}
						name={`${name}.uploadFrequency`}
						disabled={disabled}
					/>
				</Grid>
			</SectionRow>
			{Array(fields)
				.fill(0)
				.map((field, index) => (
					<TimeEntry
						key={index}
						labelSuffix={`(${index + 1}/${fields})`}
						name={`${name}.timestamps.${index}`}
						disabled={disabled}
					/>
				))}

			{children}
		</Section>
	);
};

export default UploadConfiguration;
