import Section, { SectionRow } from 'components/Section/Section';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import SelectField, {
	SelectOption,
} from 'components/Inputs/SelectField/SelectField';
import {
	Control,
	FieldPath,
	FieldValues,
	useFieldArray,
	UseFormClearErrors,
	UseFormGetValues,
	UseFormSetFocus,
	UseFormSetValue,
	UseFormTrigger,
	UseFormWatch,
	useWatch,
} from 'react-hook-form';
import { useEffect, useState, useRef, useMemo, ReactNode } from 'react';
import Button from 'components/Buttons/Button';
import styles from './PartnerAddress.module.css';
import { ReactComponent as AddIcon } from 'assets/icons/ic-add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete.svg';
import TextField from 'components/Inputs/TextField/TextField';
import { Country, useCountryQuery } from 'utils/queries/location';
import ProvinceSelect from '../../../../Inputs/SelectField/Types/LocationSelect/ProvinceSelect';
import CountrySelect from '../../../../Inputs/SelectField/Types/LocationSelect/CountrySelect';
import CitySelect from '../../../../Inputs/SelectField/Types/LocationSelect/CitySelect';
import BarangaySelect from '../../../../Inputs/SelectField/Types/LocationSelect/BarangaySelect';
import ZipCodeSelect from '../../../../Inputs/SelectField/Types/LocationSelect/ZipCodeSelect';
import Grid from 'components/Grid/Grid';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useToggle } from 'utils/hooks';
import ConfirmModal from 'components/Modal/ConfirmModal';
import {
	AddressCategoryPartner,
	AddressType,
	AddressDefaultValue,
} from 'utils/lookup';
import { product_address } from 'utils/models/product_address';
import { product } from 'utils/models/product';
import { orderBy as _orderBy, uniqBy as _uniqBy, some as _some } from 'lodash';

import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';

export type BarangayOptionValue = {
	id: string;
	value: string;
	cityId: string;
};

export type ZipCodeOptionValue = {
	id: string;
	value: string;
	barangayId: string;
};

type WithControl<T extends FieldValues> = {
	control: Control<T>;
};

type PartnerAddressEntryProps = {
	setValue: UseFormSetValue<any>;
	clearErrors: UseFormClearErrors<any>;
	control: Control<any>;
	isDirty?: boolean;
	data?: product;
	resetField: (v: any, o?: any) => void;
	getValues: UseFormGetValues<any>;
	watch: UseFormWatch<any>;
	setFocus: UseFormSetFocus<any>;
	trigger: UseFormTrigger<any>;
};

type AddressEntryProps<
	T extends FieldValues,
	U
> = WithControl<product_address> & {
	field: U;
	name: FieldPath<T>;
	disabled?: boolean;
	index: number;
	onAdd: () => void;
	onRemove: (index: number) => void;
	onCountrySelect?: (option: SelectOption, index: number) => void;
	onCitySelect?: (option: SelectOption, index: number) => void;
	onBarangaySelect?: (option: SelectOption, index: number) => void;
	onZipCodeSelect?: (option: SelectOption, index: number) => void;
	canAdd?: boolean;
	setValue: PartnerAddressEntryProps['setValue'];
	clearErrors: PartnerAddressEntryProps['clearErrors'];
	isDirty?: boolean;
	resetField: PartnerAddressEntryProps['resetField'];
	getValues: PartnerAddressEntryProps['getValues'];
	watch: UseFormWatch<FieldValues>;
	setFocus: UseFormSetFocus<any>;
	trigger: UseFormTrigger<any>;
};

const AddressEntry: React.FC<AddressEntryProps<product, any>> = ({
	control,
	field,
	disabled,
	index,
	name: n,
	onCountrySelect,
	onCitySelect,
	onBarangaySelect,
	onZipCodeSelect,
	resetField,
	setValue,
	getValues,
	watch,
	setFocus,
	trigger
}) => {
	const name = `${n}.${index}` as FieldPath<product_address[]>;

	const handleLocationBaseSelect = (v) => {
		if (v === 'INTERNATIONAL') {
			setValue(`${name}.country_id`, null)
			trigger(`${name}.country_id`)
		} else {
			setValue(`${name}.country_id`, 175);
			resetField(`${name}.province_id`);
			resetField(`${name}.city_id`);
			resetField(`${name}.barangay_id`);
			resetField(`${name}.zip_code_id`);
			resetField(`${name}.province_obj`);
			resetField(`${name}.city_obj`);
			resetField(`${name}.barangay_obj`);
			resetField(`${name}.zip_code_obj`);
			resetField(`${name}.building`);
			resetField(`${name}.building_no`);
			resetField(`${name}.street`);
		}
	};

	const watchAddresses = watch('product_addresses');

	const areaHandledCities = useMemo<SelectOption[]>(() => {
		const allCities: any = [];
		watchAddresses?.length &&
			watchAddresses.forEach((address) => {
				const { cities } = address;
				if (cities && cities.length) {
					cities.forEach((c) => c && allCities.push(c));
				}
			});

		const areas = _orderBy(
			_uniqBy<SelectOption>(allCities, 'value'),
			[(city) => city.label.toLowerCase()],
			['asc']
		);
		return areas;
	}, [watchAddresses]);

	const handleProvince = () => {
		setValue(`${name}.city_id`, null);
		setValue(`${name}.barangay_id`, null);
		setValue(`${name}.zip_code_id`, null);
		setValue(`${name}.city_obj`, null);
		setValue(`${name}.barangay_obj`, null);
		setValue(`${name}.zip_code_obj`, null);
		setFocus(`${name}.province_id`, { shouldSelect: false })
		getValues().product_contact_details.forEach((e, i) => {
			const currentArea = getValues(
				`product_contact_details[${i}].area_handled`
			);
			if (!_some(areaHandledCities, { value: currentArea })) {
				setValue(`product_contact_details[${i}].area_handled`, null);
				setValue(`product_contact_details[${i}].city_id`, null);
			}
		});
	};

	const handleProvinceRef = useRef(handleProvince);

	useEffect(() => {
		handleProvinceRef.current = handleProvince;
	}, [areaHandledCities, watchAddresses]);

	const handleCountrySelect = (v: any, options: any, index: number) => {
		const match = options.find(({ value }: SelectOption) => value == v);
		onCountrySelect && onCountrySelect(match, index);
	};

	const handleCitySelect = (v: any, options: any, index: number) => {
		const match = options.find(({ value }: SelectOption) => value == v);
		onCitySelect && onCitySelect(match, index);
		setValue(`${name}.barangay_id`, null);
		setValue(`${name}.zip_code_id`, null);
		setValue(`${name}.barangay_obj`, null);
		setValue(`${name}.zip_code_obj`, null);
		setFocus(`${name}.city_id`, { shouldSelect: false })
	};

	const handleBarangaySelect = (v: any, options: any, index: number) => {
		const match = options.find(({ value }: SelectOption) => value.id == v);
		onBarangaySelect && onBarangaySelect(match, index);
		setValue(`${name}.zip_code_id`, null);
		setValue(`${name}.zip_code_obj`, null);
		setFocus(`${name}.barangay_id`, { shouldSelect: false })
	};

	const handleZipCodeSelect = (v: any, options: any, index: number) => {
		const match = options.find(({ value }: SelectOption) => value == v);
		onZipCodeSelect && onZipCodeSelect(match, index);
	};
	return (
		<div className={styles.address}>
			<SectionRow align="spread">
				<Grid column size={1} of={3}>
					<RadioGroup
						label="Location Base"
						isHorizontalLabel
						disabled={disabled}
						options={AddressType}
						name={`${name}.location_base`}
						control={control}
						defaultValue={field.location_base}
						onChange={handleLocationBaseSelect}
					/>
				</Grid>
			</SectionRow>
			{field.location_base === 'LOCAL' ? (
				<>
					<SectionRow>
						<Grid column size={1} of={3}>
							<CountrySelect
								control={control}
								disabled
								required
								name={`${name}.country_id`}
								onChange={(v: any, options: any) =>
									handleCountrySelect(v, options, index)
								}
								defaultValue={AddressDefaultValue.country_id}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<SelectField
								label="Address Category"
								disabled={disabled}
								control={control}
								name={`${name}.address_category`}
								required
								defaultValue={field.address_category}
								options={AddressCategoryPartner}
							/>
						</Grid>
					</SectionRow>
					<SectionRow>
						<Grid column size={1} of={3}>
							<ProvinceSelect
								disabled={disabled}
								control={control}
								name={name}
								defaultValue={field.province_id}
								onChange={() => handleProvinceRef.current()}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<CitySelect
								control={control}
								name={name}
								provinceId={field.province_id}
								disabled={!field.province_id || disabled}
								defaultValue={field.city_id}
								onChange={(v: any, options: any) => {
									handleCitySelect(v, options, index);
								}}
								setValue={setValue}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<BarangaySelect
								control={control}
								name={name}
								cityId={field.city_id}
								disabled={!field.city_id || disabled}
								defaultValue={field.barangay_id}
								onChange={(v: any, options: any) =>
									handleBarangaySelect(v, options, index)
								}
							/>
						</Grid>
					</SectionRow>
					<SectionRow>
						<Grid column size={1} of={3}>
							<TextField
								label="Street"
								disabled={disabled}
								control={control}
								name={`${name}.street`}
								defaultValue={field.street}
								optional
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<TextField
								label="Building Name/No."
								disabled={disabled}
								control={control}
								name={`${name}.building_no`}
								defaultValue={field.building_no}
								required
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<ZipCodeSelect
								control={control}
								name={`${name}.zip_code_id`}
								barangayId={field.barangay_id}
								disabled={!field.barangay_id || disabled}
								onChange={(v: any, options: any) =>
									handleZipCodeSelect(v, options, index)
								}
								defaultValue={field.zip_code_id}
								required
							/>
						</Grid>
					</SectionRow>
				</>
			) : (
				<SectionRow>
					<Grid column size={1} of={3}>
						<CountrySelect
							control={control}
							disabled={disabled}
							required
							name={`${name}.country_id`}
							countryFilter={(country: Country) =>
								country.country_code !== 'PH'
							}
							defaultValue={disabled ? field.country_id : { label: 'Country' }}
						/>
					</Grid>
				</SectionRow>
			)}
		</div>
	);
};

const PartnerAddress: React.FC<
	PartnerAddressEntryProps & {
		maxEntries?: number;
		disabled?: boolean;
		action?: string;
	}
> = ({
	control,
	setValue,
	maxEntries,
	clearErrors,
	disabled,
	isDirty,
	resetField,
	getValues,
	watch,
	trigger,
	setFocus
}) => {
	const name = 'product_addresses';
	const [activeEntry, setActiveEntry] = useState<
		{ index: number; value: product_address } | undefined
	>(undefined);
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const watchAddresses = useWatch({ name, control });

	const controlledFields = watchAddresses
		? fields.map((field, i) => ({
				...field,
				...watchAddresses[i],
		  }))
		: [];

	const lastEntryRef = useRef<HTMLDivElement>(null);
	const {
		value: isAddressErrorModalOpen,
		valueOn: openAddressErrorModal,
		valueOff: closeAddressErrorModal,
	} = useToggle();
	const {
		value: isConfirmDeleteModalOpen,
		valueOn: openConfirmDeleteModal,
		valueOff: closeConfirmDeleteModal,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });

	const handleAddEntry = () => {
		if (maxEntries && controlledFields.length >= maxEntries) {
			openAddressErrorModal();
			return;
		}

		append(AddressDefaultValue);
	};

	const handleConfirmDeleteEntry = (index: number, value: product_address) => {
		setActiveEntry({ index, value });
		openConfirmDeleteModal();
	};

	const [successMessage, setSuccessMessage] = useState<ReactNode | string>('');
	const showSuccessMessage = (
		message: ReactNode | string,
		onDoneBtnClick?: () => void
	) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	const handleDeleteAddress = () => {
		const modalValue = activeEntry;
		activeEntry && remove(activeEntry.index);
		closeConfirmDeleteModal();
		modalValue && showSuccessMessage(
			<div className={styles.successModalText}>
				Partner Address {modalValue.index + 1} successfully deleted.
			</div>
			);
	};

	const handleCountrySelect = (option: SelectOption, index: number) => {
		setValue(`product_addresses[${index}].country_obj`, option);
	};

	const handleCitySelect = (option: SelectOption, index: number) => {
		setValue(`product_addresses.${index}.city_obj`, option);
	};

	const handleBarangaySelect = (option: SelectOption, index: number) => {
		setValue(`product_addresses.${index}.barangay_obj`, option.value);
	};

	const handleZipCodeSelect = (option: SelectOption, index: number) => {
		setValue(`product_addresses.${index}.zip_code_obj`, option.value);
	};

	return (
		<>
			{controlledFields.map((field: product_address, i) => (
				<Section
					title={() => {
						return (
							<>
								{`Partner Address${i > 0 ? ` ${i + 1}` : ''}`}
								<div style={{ float: 'right' }}>
									{i === 0 && !disabled ? (
										<Button
											fullWidth
											onClick={handleAddEntry}
											className={styles.addButton}
											disabled={disabled}
										>
											<AddIcon /> Add Partner Address
										</Button>
									) : (
										!disabled && (
											<Button
												onClick={() => handleConfirmDeleteEntry(i, field)}
												disabled={disabled}
											>
												<DeleteIcon />
											</Button>
										)
									)}
								</div>
							</>
						);
					}}
					titleClassName={styles.sectionTitle}
					key={field.id}
				>
					<AddressEntry
						control={control}
						disabled={disabled}
						field={field}
						index={i}
						name={name}
						onAdd={handleAddEntry}
						onRemove={(index) => handleConfirmDeleteEntry(index, field)}
						setValue={setValue}
						clearErrors={clearErrors}
						onCountrySelect={handleCountrySelect}
						onCitySelect={handleCitySelect}
						onBarangaySelect={handleBarangaySelect}
						onZipCodeSelect={handleZipCodeSelect}
						isDirty={isDirty}
						resetField={resetField}
						getValues={getValues}
						watch={watch}
						setFocus={setFocus}
						trigger={trigger}
					/>
					{i === controlledFields.length - 1 && <div ref={lastEntryRef} />}
				</Section>
			))}
			<ErrorModal
				open={isAddressErrorModalOpen}
				onClose={closeAddressErrorModal}
			>
				<ErrorModalBody>
					<ErrorText>Failed to Add Partner Address</ErrorText>
					<ErrorSubText>
						You have reached max no. of accepted partner address
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={closeAddressErrorModal}
					>
						Close
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>

			{activeEntry && (
				<ConfirmModal
					open={isConfirmDeleteModalOpen}
					disableClose={false}
					onClose={() => {
						setActiveEntry(undefined);
						closeConfirmDeleteModal();
					}}
					headerText={
						activeEntry && `Delete Partner Address ${activeEntry.index + 1}`
					}
					bodyText={[
						() => (
							<>
								Are you sure you want to delete{' '}
								<span className={styles.confirmTextName}>
									Partner Address {activeEntry.index + 1}
								</span>
								?
							</>
						),
					]}
					confirmButton={{
						name: 'Delete',
						event: handleDeleteAddress,
					}}
					cancelButton={{
						name: 'Back',
						event: closeConfirmDeleteModal,
					}}
				/>
			)}

			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successText}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
		</>
	);
};

export default PartnerAddress;
