import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { FixMeLater } from 'types';
import { UsePartnerTabQueryKey } from 'utils/queries/partner';

export const ROUTE_PRODUCT_CATEGORY_MAPPING = {
	'bills-payment': 'BPY',
	'buy-load': 'BUY_LOAD',
	remittance: 'REMITTANCE',
	'cash-out-pay-out': 'CASH_OUT',
	'cash-in-top-up': 'CASH_IN',
	'e-ctpl': 'E_CTPL',
	instasurance: 'INSTASURANCE',
	'bayad-checkout': 'BAYAD_CHECKOUT',
	'bayad-checkout-via-otc': 'BAYAD_CHECKOUT_OTC',
	'bayad-atm': 'BAYAD_ATM',
	'bayad-med-assist': 'BAYAD_MED',
	'bayad-travel': 'BAYAD_TRAVEL',
} as const;

type Params = {
	search?: string;
	category?: string;
	status?: string;
};

export type CategoriesPayloadTypes = {
	created_at: string;
	id: number | string;
	name?: string;
	status?: string;
	updated_at: string;
};

export type TProductState = {
	category: string;
	search: string;
	status: string;
	productList: Array<any>;
	categories: Array<any>;
	selectedBiller: any;
	currentBillerTab: string;
	productCategory: string;
	lastActionButton: null | 'SAVE' | 'DRAFT' | 'AUTOSAVE';
	autoSaveValues: any;
	setAutoSaveValues: (v: any) => void;
	isSubmittingAll: boolean;
	isSubmittedAll: boolean;
	allValues: any;
	drafting: boolean;
	editing: boolean;
	primaryInfo: any;
	contractDetails: any;
	serviceFeeSettings: any;
	businessRules: any;
	reports: any;
	isSetSampleValues: boolean;
	setIsSampleValues: (v: FixMeLater) => void;
	trackPrimaryInfo: any;
};

const initialState: TProductState = {
	category: '',
	search: '',
	status: '',
	productList: [],
	categories: [],
	selectedBiller: {},
	currentBillerTab: 'primary-info',
	productCategory: '',
	lastActionButton: null,
	autoSaveValues: {},
	setAutoSaveValues: () => {},
	isSubmittingAll: false,
	isSubmittedAll: false,
	allValues: {},
	drafting: false,
	editing: false,
	//for collecting fields
	primaryInfo: {},
	contractDetails: {},
	serviceFeeSettings: {},
	businessRules: {},
	reports: {},
	// for debugging
	isSetSampleValues: false,
	setIsSampleValues: () => {},
	//
	trackPrimaryInfo: {},
};
const products = createSlice({
	name: 'products',
	initialState,
	reducers: {
		prepare: (state: TProductState, { payload }) => {
			payload.category
				? (state.category = payload.category)
				: (state.category = '');
			payload.search ? (state.search = payload.search) : (state.search = '');
			payload.status ? (state.status = payload.status) : (state.status = '');
		},
		load: (state, payload: FixMeLater) => {
			state.productList = payload.result.data;

			const partner = state.selectedBiller[state.productCategory];

			if (partner) {
				state.productList = state.productList.map((x) =>
					x.id === partner.id ? { ...x, isSelected: true } : x
				);
			}
		},
		error: () => {},
		prepCategory: () => {},
		loadCategory: (state: TProductState, payload: any) => {
			state.categories = payload.result;
		},
		errorCategory: (_, payload) => {
			console.log('Categories Error: ', payload);
		},
		selectPartner: (state: TProductState, { payload }) => {
			state.currentBillerTab = 'primary-info';
			if (!payload) state.selectedBiller = {};
			state.productList = state.productList.map((partner: any) => {
				if (partner.name === payload.selected) {
					state.selectedBiller[state.productCategory] = partner;

					return {
						...partner,
						isSelected: true,
					};
				}
				return {
					...partner,
					isSelected: false,
				};
			});
		},
		setCurrentBillerTab: (state: TProductState, { payload }) => {
			state.currentBillerTab = payload;
		},
		clearFilter: (state: TProductState) => {
			state.category = '';
			state.status = '';
		},
		setLastActionButton: (state: TProductState, { payload }) => {
			state.lastActionButton = payload;
		},

		setAutoSaveValues: (state: TProductState, { payload }) => {
			state.autoSaveValues = payload;
		},
		setSubmittingAll: (state: TProductState, { payload }) => {
			state.isSubmittingAll = payload;
		},
		setDrafting: (state: TProductState, { payload }) => {
			state.drafting = payload;
		},
		setEditing: (state: TProductState, { payload }) => {
			state.editing = payload;
		},
		clearFetchedForms: (state: TProductState) => {
			state.selectedBiller = {};
			state.productList = state.productList.map(
				(partner: Record<string, unknown>) => {
					return {
						...partner,
						isSelected: false, //reset all selected partner
					};
				}
			);
			state.isSubmittingAll = false;
			state.isSubmittedAll = false;
			state.drafting = false;
			state.editing = false;
			state.primaryInfo = {};
			state.contractDetails = {};
			state.serviceFeeSettings = {};
			state.businessRules = {};
			state.reports = {};
		},
		setPrimaryInfo: (state: TProductState, { payload }) => {
			state.primaryInfo = payload;
		},
		setContractDetails: (state: TProductState, { payload }) => {
			state.contractDetails = payload;
		},
		setServiceFeeSettings: (state: TProductState, { payload }) => {
			state.serviceFeeSettings = payload;
		},
		setBusinessRules: (state: TProductState, { payload }) => {
			state.businessRules = payload;
		},
		setReports: (state: TProductState, { payload }) => {
			state.reports = payload;
		},
		updateProductStatus: (
			state,
			{ payload: { id, status } }: { payload: { id: number; status: string } }
		) => {
			state.selectedBiller[state.productCategory].status = status;
			state.productList = state.productList.map((x) =>
				x.id === id ? { ...x, status } : x
			);
		},
		setProductCategoryBaseOnPath: (
			state,
			{ payload: pathname }: PayloadAction<string>
		) => {
			if (!pathname.startsWith('/products/management/')) {
				state.productCategory = '';
				return;
			}

			const routeCode = pathname.split('/')[3];

			state.productCategory = ROUTE_PRODUCT_CATEGORY_MAPPING[routeCode];
		},
		clearProducts: (state) => {
			state.productList = [];
		},
		setIsSampleValues: (state, { payload }) => {
			state.isSetSampleValues = payload;
		},
		updateValidForm: (
			state,
			{
				payload,
			}: PayloadAction<{
				formTab: UsePartnerTabQueryKey;
				isValid?: boolean;
				isDirty?: boolean;
			}>
		) => {
			state[payload.formTab] = {
				...state[payload.formTab],
				...(payload.isValid !== undefined && { isValid: payload.isValid }),
				...(payload.isDirty !== undefined && { isDirty: payload.isDirty }),
			};
		},
		setTrackPrimaryInfo: (state: TProductState, { payload }) => {
			state.trackPrimaryInfo = payload;
		},
	},
});

const {
	prepare,
	load,
	error,
	prepCategory,
	loadCategory,
	errorCategory,
	selectPartner,
	setCurrentBillerTab,
	clearFilter,
	setLastActionButton,
	setAutoSaveValues,
	setSubmittingAll,
	setDrafting,
	setEditing,
	setPrimaryInfo,
	setContractDetails,
	setServiceFeeSettings,
	setBusinessRules,
	setReports,
	updateProductStatus,
	setProductCategoryBaseOnPath,
	clearProducts,
	setIsSampleValues,
	updateValidForm,
	setTrackPrimaryInfo,
	clearFetchedForms,
} = products.actions;

const getProducts = (payload: Params = {}): any => {
	return {
		types: [prepare.type, load.type, error.type],
		promise: (client: any, state: any) => {
			const params: Params = {};
			const { category, search, status } = state.products;

			if (category !== '') {
				params.category = category;
			}

			if (search !== '') {
				params.search = search;
			}

			if (status !== '') {
				params.status = status;
			}

			return client.get(`/v2/products/${state.sidebar.itemId}`, {
				params,
			});
		},
		payload,
	};
};

const getCategories = (payload: Params = {}): any => {
	return {
		types: [prepCategory.type, loadCategory.type, errorCategory.type],
		promise: async (client: AxiosInstance) => {
			const response = await client
				.get('/v2/products/categories')
				.then(
					({
						data: { data = [] },
					}: {
						data: { data: CategoriesPayloadTypes[] };
					}) =>
						data
							.filter((c) => c.status === 'ACTIVE')
							.map((c: CategoriesPayloadTypes) => {
								return { label: c.name, value: `${c.id}` };
							})
				);
			return { data: response };
		},
		payload,
	};
};

export {
	getProducts,
	selectPartner,
	setCurrentBillerTab,
	getCategories,
	clearFilter,
	setLastActionButton,
	setAutoSaveValues,
	setSubmittingAll,
	setDrafting,
	setEditing,
	setPrimaryInfo,
	setContractDetails,
	setServiceFeeSettings,
	setBusinessRules,
	setReports,
	updateProductStatus,
	setProductCategoryBaseOnPath,
	clearProducts,
	setIsSampleValues,
	setTrackPrimaryInfo,
	updateValidForm,
	clearFetchedForms,
};

export default products.reducer;
