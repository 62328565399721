import { createSlice } from '@reduxjs/toolkit';
import { channelStatuses, channelTypes } from '../../constants/channelType';

const initialState = {
	channelTypes: channelTypes,
	channelStatuses: channelStatuses,
	search: '',
	channels: [],
	meta: {},
	selectedChannel: null,
	selectedBranch: null,
	selectedTerminal: null,
	activeType: '',
	selectedApproval: null,
	channelFilter: {
		search: '',
		category: '',
		status: '',
	},
	branchFilter: {
		name: '',
		posType: '',
		storeType: '',
		storeFormat: '',
		machineLocation: '',
	},
	terminalFilter: {
		tpaId: '',
	},
	channelFormValues: {
		primaryInfo: {},
		contractDetails: {},
		reports: {},
	},
	branchFormValues: {},
	terminalFormValues: {},
	formErrors: {} as Record<string, boolean>,
};

export type GetChannelsParams = {
	search: string | null;
	category: string | null;
	status: string | null;
};

const channelSlice = createSlice({
	name: 'channels',
	initialState,
	reducers: {
		prepare: (state: any, { payload }) => {
			if (payload.channelType != null) {
				state.channelType = payload.channelType;
			}

			if (payload.name != null) {
				state.search = payload.name;
			}
		},
		load: (state, payload: any) => {
			state.channels = payload.result.result;
			state.meta = payload.result.meta;
		},
		error: (state, payload) => {},
		selectChannel: (state: any, { payload }) => {
			console.log('PAYLOAD', payload);
			console.log('CHANNEL', state);
			state.channels = state.channels.map((channel: any) => {
				console.log('PAYLOAD', payload);
				console.log('CHANNEL', channel);
				if (channel.id === payload.selected) {
					state.selectedChannel = channel;
					return {
						...channel,
						isSelected: true,
					};
				}
				return {
					...channel,
					isSelected: false,
				};
			});
		},
		clearSelectedChannel: (state: any) => {
			state.selectedChannel = null;
		},
		searchChannel: (state, payload) => {
			console.log({ state, payload });
		},
		selectedChannel: (state: any, { payload }) => {
			state.selectedChannel = payload.channel;
		},
		selectedBranch: (state: any, { payload }) => {
			state.selectedBranch = payload.branch;
		},
		selectedTerminal: (state: any, { payload }) => {
			state.selectedTerminal = payload.terminal;
		},
		activeType: (state: any, { payload }) => {
			state.activeType = payload.type;
		},
		channelListFilter: (state: any, { payload }) => {
			state.channelFilter = payload.channelFilter;
		},
		branchListFilter: (state: any, { payload }) => {
			state.branchFilter = payload.branchFilter;
		},
		terminalListFilter: (state: any, { payload }) => {
			state.terminalFilter = payload.terminalFilter;
		},
		setChannelFormValues: (state: any, { payload }) => {
			state.channelFormValues = payload;
		},
		setBranchFormValues: (state: any, { payload }) => {
			state.branchFormValues = payload;
		},
		setTerminalFormValues: (state: any, { payload }) => {
			state.terminalFormValues = payload;
		},
		resetChannelFormValues: (state) => {
			state.channelFormValues = {
				primaryInfo: {},
				contractDetails: {},
				reports: {},
			};
		},
		setFormErrorStatus: (state, action) => {
			state.formErrors[action.payload.formId] = action.payload.hasError;
		},
	},
});

const getChannels = (payload: GetChannelsParams): any => {
	return {
		types: [prepare.type, load.type, error.type],
		promise: (client: any, state: any) => {
			const { search, category, status } = payload;
			const params = {
				search: search ? search : '',
				category: category ? category : '',
				status: status ? status : '',
			};
			return client.get('/v2/channels/', {
				params,
			});
		},
		payload,
	};
};

export const {
	prepare,
	load,
	error,
	selectChannel,
	clearSelectedChannel,
	searchChannel,
	selectedChannel,
	activeType,
	selectedBranch,
	selectedTerminal,
	channelListFilter,
	branchListFilter,
	terminalListFilter,
	setChannelFormValues,
	setBranchFormValues,
	setTerminalFormValues,
	resetChannelFormValues,
	setFormErrorStatus,
} = channelSlice.actions;

export { getChannels };

export default channelSlice.reducer;
