import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import BranchDetails from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/BranchDetails';
import { BranchResponseType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/types';
import { transformBranchResponseToBranchType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/utils';
import ChannelAuditTrail from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrail';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FixMeLater } from 'types';

const BranchTab: React.FC = () => {
	const selectedBranch = useSelector(
		(state: FixMeLater) => state.channels.selectedBranch
	);

	const selectedBranchValues = useSelector((state: FixMeLater) => {
		return state.channels.branchFormValues as BranchResponseType;
	});

	let updatedAt: string;

	if (selectedBranchValues.data?.branchPrimaryInfos?.updatedAt) {
		updatedAt = moment(
			selectedBranchValues.data?.branchPrimaryInfos?.updatedAt
		).format('MM/DD/YYYY hh:mm:ss a');
	} else {
		updatedAt = 'unknown';
	}

	const primaryTabProps = {
		tabs: [
			{
				title: 'Branch Details',
				component:
					selectedBranch.id && Object.keys(selectedBranchValues).length > 0 ? ( // If there is selected branch
						<BranchDetails
							defaultValues={transformBranchResponseToBranchType(
								selectedBranchValues
							)}
							updatedAt={updatedAt}
							updatedBy={
								selectedBranchValues.data?.branchPrimaryInfos?.updatedBy ||
								'unknown'
							}
						/>
					) : (
						<PanelPlaceholder
							title={'No Selected Branch'}
							subtitle={'Please select a branch'}
						/>
					),
			},
			{
				title: 'Audit Trail',
				component:
					selectedBranch.id && Object.keys(selectedBranchValues).length > 0 ? ( // If there is selected branch
						<ChannelAuditTrail initialValues={{}} />
					) : (
						<PanelPlaceholder
							title={'No Selected Branch'}
							subtitle={'Please select a branch'}
						/>
					),
			},
		],
		defaultIndex: 0,
	};
	return <PrimaryTab {...primaryTabProps} />;
};

export default BranchTab;
