import { Modal } from '@salesforce/design-system-react/module/components';
import Grid from 'components/Grid/Grid';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import BranchCheckbox from 'components/Checkbox/BranchCheckbox';
import TAPIDCheckbox from 'components/Checkbox/TAPIDCheckbox';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FixMeLater } from 'types';
import { ChannelLevel, WalletType } from 'utils/lookup';
import HTTP from 'helpers/ApiClient';
import styles from './CreateChannelWallet.module.css';
import { useToggle } from 'utils/hooks';
import {
	ContactNumberTagInput,
	EmailTagInput,
} from 'components/TagInput/TagInput';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { yupResolver } from '@hookform/resolvers/yup';
import yup, {
	defaultCheckboxRequired,
	multipleContactNumberSchema,
	multipleEmailSchema,
	selectDefaultRequiredTemplate,
} from 'utils/formSchemas/common';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import { isANumber } from 'components/Format/NumberFormatter';
import { connect } from 'react-redux';
import ProductTypeCheckbox from 'components/Checkbox/ProductTypeCheckbox';
import { isEmpty } from 'lodash';

type Props = {
	open?: boolean;
	onClose?: () => void;
	state: any;
};

interface IProductType {
	value: string;
	label: string;
}

interface IProductName {
	value: number;
	label: string;
	className?: string;
	disabled?: boolean;
}

const Row: React.FC<{ gutters?: boolean }> = ({ gutters, children }) => (
	<Grid container gutters={gutters} className={styles.vspaced}>
		{children}
	</Grid>
);

const resolveParam = (value, defaultValue = undefined) => {
	return value || defaultValue;
};

export const schema = yup.object().shape({
	walletType: yup
		.string()
		.label('Wallet Type')
		.required(selectDefaultRequiredTemplate),
	productType: yup
		.mixed()
		.label('Product Type')
		.when('showProductName', {
			is: true,
			then: yup.string().required(selectDefaultRequiredTemplate),
		}),
	channelId: yup
		.string()
		.label('Channel Name')
		.when('showProductName', {
			is: false,
			then: yup.string().required(selectDefaultRequiredTemplate),
		}),
	channelLevel: yup
		.string()
		.label('Channel Level')
		.when('showProductName', {
			is: false,
			then: yup.string().required(selectDefaultRequiredTemplate),
		}),
	internalEmail: multipleEmailSchema.label('Internal Email Address'),
	internalMobileNumber: multipleContactNumberSchema.label(
		'Internal Mobile Number'
	),
	externalEmail: multipleEmailSchema.label('External Email Address'),
	externalMobileNumber: multipleContactNumberSchema.label(
		'External Mobile Number'
	),
	thresholdAmount: yup
		.string()
		.label('Threshold Amount')
		.required('Please enter a threshold amount value.')
		.transform((curr) => curr.replaceAll(',', ''))
		.test(
			'is-number',
			'Please enter a threshold amount value.',
			(value) => isANumber(value) && parseFloat(value ?? '') > 0
		),
	branches: yup
		.array()
		.label('Branch')
		.when('channelLevel', {
			is: 'BRANCH',
			then: yup.array().min(1, defaultCheckboxRequired),
		})
		.default([]),
	tpaIds: yup
		.array()
		.label('TPAID')
		.when('channelLevel', {
			is: 'TPA_ID',
			then: yup.array().min(1, defaultCheckboxRequired),
		})
		.default([]),
	showProductName: yup.bool().default(false),
	productName: yup
		.string()
		.label('Product Name')
		.when('showProductName', {
			is: true,
			then: yup.string().required(selectDefaultRequiredTemplate),
		}),
});

export async function getBranches({
	page = 1,
	limit = 25,
	name = '',
	channel_id = '',
} = {}): Promise<any> {
	const result = await HTTP.get(
		`/v1/cws/util/accounts/${channel_id}/branches`,
		{
			params: {
				name: resolveParam(name),
				page,
				limit,
			},
		}
	);
	return result.data;
}

type BranchParams = {
	name?: string;
	page?: number;
	limit?: number;
	channel_id?: string;
};

const CreateChannelWallet: React.FC<Props> = ({ open, onClose = () => {} }) => {
	const {
		getValues,
		control,
		setValue,
		formState: { isValid, isDirty, errors },
		handleSubmit,
		reset,
		watch,
		clearErrors,
		trigger,
	} = useForm({
		mode: 'all',
		resolver: yupResolver(schema),
	});

	const [ProductName, setProductName] = useState<IProductName[]>([]);
	const [ProductType, setProductType] = useState<IProductType[]>([]);
	const [channels, setChannels] = useState<FixMeLater>([]);
	const [branches, setBranches] = useState<FixMeLater>([]);
	const [tpaIds, setTPAIds] = useState<any>([]);
	const [types, setTypes] = useState([]);
	const {
		value: isFetchingChannels,
		valueOn: startFetchingChannels,
		valueOff: stopFetchingChannels,
	} = useToggle();

	const {
		value: isFetchingProductType,
		valueOn: startFetchingProductType,
		valueOff: stopFetchingProductType,
	} = useToggle();

	const {
		value: isFetchingProductName,
		valueOn: startFetchingProductName,
		valueOff: stopFetchingProductName,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: openSuccessModal,
		valueOff: closeSuccessModal,
	} = useToggle();

	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const walletType = watch('walletType');
	const productType = watch('productType');
	const channelLevel = watch('channelLevel');
	const channelId = watch('channelId');

	const handleClose = () => {
		reset();
		clearErrors();
		setProductName([]);
		setProductType([]);
		onClose();
	};

	const closeSuccess = () => {
		window.location.reload();
		handleClose();
	};

	const handleSave = (values) => {
		hideErrorModal();

		const {
			selectedChannel,
			channelId,
			thresholdAmount,
			channelLevel,
			productType,
			walletType: type,
			internalEmail,
			internalMobileNumber,
			externalEmail,
			externalMobileNumber,
			productName,
			tpaIds,
		} = values;
		const user = JSON.parse(localStorage.getItem('userInfo') || '');

		const _product = ProductName.find(
			(product) => product.value == productName
		) || { label: undefined, value: undefined };

		const join = (arr: string[]): string => arr.join('|');
		const data = {
			channelId: +channelId,
			channelName: selectedChannel?.value?.name,
			thresholdAmount,
			channelLevel: channelLevel || '',
			productTypeConfig: productType || '',
			createdBy: user.firstname,
			branches: branches.filter((e) => e.checked),
			tpaIds: tpaIds.filter((t) => t.checked),
			type,
			productName: _product.label,
			productId: _product.value,
			internalEmailAddresses: join(internalEmail),
			internalContactNumber: join(internalMobileNumber),
			externalEmailAddresses: join(externalEmail),
			externalContactNumber: join(externalMobileNumber),
			status: 'Active',
		};

		if (type === 'cws') {
			delete data.productTypeConfig;
		}

		HTTP.post('/v2/wallets', data)
			.then((res) => {
				if (res.data.data.message === 'Wallet created successfully') {
					openSuccessModal();
				} else if (
					res?.data &&
					res.data.data.message.includes('CWS error encountered')
				) {
					showErrorModal();
				}
			})
			.catch(() => {
				showErrorModal();
			});
	};

	const channelOptions = channels.map(({ name, id, has_wallet }) => ({
		label: name,
		value: {
			name,
			id,
			has_wallet: !!has_wallet,
		},
	}));

	const fetchBranches = async ({
		name,
		page = 1,
		limit = 25,
		channel_id,
	}: BranchParams = {}) => {
		try {
			const res = await getBranches({ name, page, limit, channel_id });
			const data = res.result || [];

			setBranches(data);
		} catch (e) {
			setBranches([]);
		}
	};

	useEffect(() => {
		if (walletType) {
			setValue('channelId', '');
			setValue('selectedChannel', '');
			setTPAIds([]);
			setBranches([]);
		}
	}, [walletType]);

	useEffect(() => {
		if (channelId || walletType) {
			setValue('channelLevel', '');
			clearErrors('tpaIds');
			clearErrors('branches');
		}
	}, [channelId, walletType]);

	useEffect(() => {
		if (channelId) {
			const selectedChannel = channelOptions.find(
				({ value: { id } }) => channelId === id
			);
			setValue('selectedChannel', selectedChannel);
		}
	}, [channelId, channelOptions]);

	useEffect(() => {
		clearErrors('tpaIds');
		clearErrors('branches');
		setValue('branches', []);
		setValue('tpaIds', []);
		if (channelId && channelLevel === 'BRANCH') {
			fetchBranches({ channel_id: channelId });
		}
	}, [channelId, channelLevel]);

	useEffect(() => {
		(async () => {
			if (channelId && channelLevel === 'TPA_ID') {
				const {
					data: { result },
				} = await HTTP.get(`/v1/cws/util/accounts/${channelId}/terminals`, {
					params: {
						name: '',
						page: 1,
						limit: 25,
					},
				});

				setTPAIds(result);
			}
		})();
	}, [channelId, channelLevel]);

	const handleBranchClick = (branch, checked) => {
		const updatedBranches = (prevBranches) =>
			prevBranches.map((b) => {
				if (b.id === branch.id && !branch.has_wallet)
					return {
						...b,
						checked,
					};
				return b;
			});
		const selBranches = updatedBranches(branches).filter((b) => b.checked);
		setBranches(updatedBranches);
		return selBranches;
	};
	const fetchProductTypes = async () => {
		const response = await HTTP.get('v2/products/types');
		if (response.status === 200) {
			return setTypes(response.data.data);
		}
		return;
	};
	useEffect(() => {
		fetchProductTypes();
	}, []);
	const handleProductClick = (
		productType: Record<string, unknown>,
		checked: boolean,
		isSubOption?: boolean,
		isSubOptionChecked?: boolean,
		isToggleAll?: boolean
	) => {
		const productSelected = (ProductType) =>
			ProductType.map((p) => {
				const codefinder = types?.find(
					(code) =>
						code.name === (isToggleAll ? p.label : productType.label) &&
						code.code
				);
				if (isToggleAll) {
					return {
						...p,
						code: codefinder?.id,
						// enabled: codefinder?.status === 'ACTIVE' ? 1 : 0,
						allow_deduction: isSubOption ? 1 : 0,
						checked: p?.enabled ? checked : false,
						...(isSubOption && { isAllowDeduction: isSubOptionChecked }),
					};
				}
				if (p.label === productType.label) {
					return {
						...p,
						code: codefinder?.id,
						enabled: codefinder?.status === 'ACTIVE' ? 1 : 0,
						allow_deduction: isSubOption ? 1 : 0,
						checked,
						...(isSubOption && { isAllowDeduction: isSubOptionChecked }),
					};
				}
				return p;
			});

		const selProducts = productSelected(ProductType).filter((p) => p.checked);
		setProductType(productSelected);
		return selProducts;
	};

	const handleTPAhClick = (tpa, checked) => {
		const updatedTPA = (prevState) =>
			prevState.map((t) => {
				if (t.id === tpa.id)
					return {
						...t,
						checked,
					};
				return t;
			});
		const selTPAs = updatedTPA(tpaIds).filter((t) => t.checked);
		setTPAIds(updatedTPA);
		return selTPAs;
	};

	useEffect(() => {
		(async () => {
			if (productType) {
				startFetchingProductName();
				const res = await HTTP.get(`/v1/products/names`, {
					params: { product_type: productType },
				});
				setProductName(
					res.data.result.map(({ name, id, is_available }) => ({
						label: name,
						value: id,
						className: !is_available ? 'unavailable-menu-item' : '',
						disabled: !is_available,
					}))
				);
				stopFetchingProductName();
			}
		})();
	}, [productType]);

	useEffect(() => {
		(async () => {
			if (['cws', 'prefunded', 'bonded'].includes(walletType)) {
				setValue('showProductName', walletType === 'cws');
				setValue('selectedChannel', '');
				setValue('channelLevel', '');
				startFetchingChannels();
				const res2 = await HTTP.get(`/v1/cws/util/accounts`, {
					params: { walletType },
				});
				stopFetchingChannels();
				setChannels(res2.data.result);
				setValue('productType', null);

				startFetchingProductType();
				if (walletType === 'cws') {
					const res = await HTTP.get(`/v1/products/types`);
					setProductType(
						res.data.result.map(({ name, code, ...rest }) => ({
							label: name,
							value: code,
							...rest,
						}))
					);

					stopFetchingProductType();
					return;
				}
				const res = await HTTP.get(`/v2/channels/product-type-access`);
				setProductType(
					res.data.data.map(({ name, code, ...rest }) => ({
						label: name,
						value: code,
						...rest,
					}))
				);

				stopFetchingProductType();
			}
		})();
	}, [walletType]);

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={handleClose}
				headerClassName={styles.headerContainer}
				contentClassName={styles.modal}
				heading="Add New Wallet"
				footer={
					<div className={styles.footer}>
						<OutlineButton onClick={handleClose} className={styles.btn}>
							Cancel
						</OutlineButton>

						<PrimaryButton
							onClick={handleSubmit(handleSave)}
							className={styles.btn}
							disabled={!isValid || (!isEmpty(errors) && isDirty)}
						>
							Submit
						</PrimaryButton>
					</div>
				}
				size="small"
			>
				<Grid container vertical>
					<Row gutters>
						<Grid column size={1} of={2}>
							<TextField
								name="walletId"
								control={control}
								label="Wallet ID"
								disabled
							/>
						</Grid>
						<Grid column size={1} of={2}>
							<SelectField
								label="Wallet Type"
								name="walletType"
								control={control}
								placeholder="Select Wallet Type"
								options={WalletType}
							/>
						</Grid>
					</Row>
					{(walletType === 'prefunded' || walletType === 'bonded') && (
						<Row>
							<Grid column container vertical>
								<h3 className={styles.textLabel}>Product Type</h3>
								<div>
									<ProductTypeCheckbox
										control={control}
										isShowTooltip={true}
										name="productType"
										entries={ProductType}
										onEntryClick={handleProductClick}
										getEntryValue={(productType) => productType}
										disabled={false}
									/>
								</div>
							</Grid>
						</Row>
					)}
					{
						<Grid container vertical>
							{walletType === 'cws' ? (
								<>
									<Row>
										<SelectField
											label="Product Type"
											name="productType"
											control={control}
											isLoading={isFetchingProductType}
											placeholder="Select Product Type"
											options={ProductType}
											required
										/>
									</Row>
									<Row>
										<SelectField
											label="Product Name"
											name="productName"
											control={control}
											isLoading={isFetchingProductName}
											options={ProductName}
											required
										/>
									</Row>
									<Row>
										<Grid column size={1} of={2}>
											<TextField
												name="thresholdAmount"
												control={control}
												label="Threshold Amount"
												formatValue={TextFieldFormatter.commaSeparated}
												onBlur={(e) => {
													setValue(
														'thresholdAmount',
														TextFieldFormatter.decimalPlaces()(e.target.value)
													);
												}}
											/>
										</Grid>
									</Row>
								</>
							) : (
								<>
									<Row>
										<SelectField
											label="Channel Name"
											name="channelId"
											control={control}
											getOptionValue={(v) => v.value.id}
											getComparator={({ value }, id) => value.id == id}
											isLoading={isFetchingChannels}
											placeholder="Select Channel Name"
											options={channelOptions}
										/>
									</Row>
									<Row gutters>
										<Grid column size={1} of={2}>
											<SelectField
												label="Channel Level"
												name="channelLevel"
												control={control}
												isLoading={isFetchingChannels}
												placeholder="Select Channel Level"
												options={ChannelLevel.map((c) => {
													const selChannel = getValues('selectedChannel');
													if (
														selChannel &&
														selChannel.value.has_wallet &&
														c.value === 'TPA_ACCOUNT'
													) {
														c.disabled = true;
														c.className = 'unavailable-menu-item';

														return c;
													}

													c.disabled = false;
													c.className = '';
													return c;
												})}
											/>
										</Grid>
										<Grid column size={1} of={2}>
											<TextField
												name="thresholdAmount"
												control={control}
												label="Threshold Amount"
												formatValue={TextFieldFormatter.commaSeparated}
												onBlur={(e) => {
													setValue(
														'thresholdAmount',
														TextFieldFormatter.decimalPlaces()(e.target.value)
													);
												}}
											/>
										</Grid>
									</Row>
								</>
							)}
							{walletType !== 'cws' && (
								<>
									{channelLevel === 'BRANCH' && (
										<Row>
											<Grid column container vertical>
												<label
													className="slds-form-element__label"
													htmlFor="internal_email"
												>
													Branch
												</label>
												<div className={styles.subtext}>
													<em>
														*Please select branches to be covered by this
														wallet.
													</em>
												</div>
												<div>
													<BranchCheckbox
														name="branches"
														control={control}
														isShowTooltip={true}
														entries={branches}
														onEntryClick={handleBranchClick}
														getEntryValue={(branch) => branch.name}
														disabled={false}
													/>
												</div>
											</Grid>
										</Row>
									)}

									{channelLevel === 'TPA_ID' && (
										<Row>
											<Grid column container vertical>
												<label
													className="slds-form-element__label"
													htmlFor="internal_email"
												>
													TPA ID
												</label>
												<div className={styles.subtext}>
													<em>
														*Please select TPA IDs to be covered by this wallet.
													</em>
												</div>
												<div>
													<TAPIDCheckbox
														name="tpaIds"
														control={control}
														isShowTooltip={true}
														entries={tpaIds}
														onEntryClick={handleTPAhClick}
														getEntryValue={(tpa) => tpa.name}
													/>
												</div>
											</Grid>
										</Row>
									)}
								</>
							)}

							<Row>
								<h2 className={styles.sectionLabel}>
									Internal Channel Contact Person(s)
								</h2>
							</Row>
							<Row>
								<EmailTagInput control={control} name="internalEmail" />
							</Row>
							<Row>
								<ContactNumberTagInput
									label="Mobile Number"
									control={control}
									name="internalMobileNumber"
									subtext="*Can input mobile numbers separated by comma."
								/>
							</Row>
							<Row>
								<h2 className={styles.sectionLabel}>
									External Channel Contact Person(s)
								</h2>
							</Row>
							<Row>
								<EmailTagInput control={control} name="externalEmail" />
							</Row>
							<Row>
								<ContactNumberTagInput
									label="Mobile Number"
									control={control}
									name="externalMobileNumber"
									subtext="*Can input mobile numbers separated by comma."
								/>
							</Row>
						</Grid>
					}
				</Grid>
			</Modal>

			<SuccessModal open={isSuccessModalOpen} onClose={closeSuccessModal}>
				<SuccessModalBody>
					<SuccessText>
						<div className={styles.successHeader}>
							Success!
							<br />
							You've added a wallet
						</div>
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton onClick={closeSuccess}>Done</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
				<ErrorModalBody>
					<ErrorText>Failed to add a wallet</ErrorText>
					<ErrorSubText>
						Something went wrong with your request. <br />
						Would you like to retry?
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					<>
						<OutlineButton
							className={styles.walletAdjustmentErrorCloseBtn}
							onClick={hideErrorModal}
						>
							Cancel
						</OutlineButton>
						<PrimaryButton
							className={styles.walletAdjustmentErrorCloseBtn}
							onClick={handleSubmit((v) => handleSave(v))}
						>
							Retry
						</PrimaryButton>
					</>
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default connect((state) => ({ state }))(CreateChannelWallet);
