import cx from 'classnames';
import Checkbox from 'components/Checkbox/Checkbox';
import MultiCheckboxContainer from 'components/Checkbox/MultiCheckboxContainer';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
	useWatch
} from 'react-hook-form';
import styles from './style.module.css';
import { CsrColumn, CsrColumnCheckboxProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/types';
import { useCsrColumns } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/hooks';

const CsrColumnCheckbox: React.FC<CsrColumnCheckboxProps> = ({
	onChange,
	value = [],
	pool = [],
	disabled = false,
	name,
}) => {
	const columns = useWatch({ name, defaultValue: value });
	const {
		toggleAll,
		entries,
		toggle,
		selectedEntries,
		searchKeyword,
		setSearchKeyword,
	} = useCsrColumns(pool, value);

	const handleSearchChange = (v: string) => {
		setSearchKeyword(v);
	};
	useEffect(() => {
		onChange?.(selectedEntries);
	}, [selectedEntries]);

	const isSelected = useCallback(
		(v: CsrColumn) => {
			if (!columns) return v.is_required;
			return columns.some((entry) => entry.id === v.id) || v.is_required;
		},
		[columns]
	);

	const allSelected = useMemo(() => {
		return entries.length > 0 && entries.every(isSelected);
	}, [entries, isSelected]);

	return (
		<MultiCheckboxContainer
			selectAll={allSelected}
			onSelectAll={() => toggleAll()}
			disabled={disabled}
		>
			<InputWithIcon
				placeholder="Search"
				value={searchKeyword}
				onChange={handleSearchChange}
				disabled={disabled}
				icons={[
					{
						path: 'utility/search',
						isLeft: true,
					},
				]}
			/>
			<div className={styles.entryContainer}>
				{entries.map((entry) => (
					<div
						key={entry.id}
						className={cx('slds-col', styles.entry, {
							[styles.readonly]: entry.is_required || disabled,
						})}
					>
						<Checkbox
							label={entry.value}
							checked={isSelected(entry)}
							onChange={() => toggle(entry)}
							disabled={Boolean(entry.is_required) || disabled}
						/>
					</div>
				))}
			</div>
		</MultiCheckboxContainer>
	);
};

export default CsrColumnCheckbox;
