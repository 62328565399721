export const remappedDataHandler = (data: any, type: string | undefined, result: string | undefined) => {
	if (type === "channel") {
	  	if (result === 'basicInfo') {
			const channelPrimaryInfo = data.result?.channelPrimaryInfo;
			if (!channelPrimaryInfo) {
			return [];
			}
  
			const channelPrimaryInfoArray: any[] = [];
  
			const fieldsMapping = {
				name: "Name",
				partnershipType: "Partnership Type",
				businessGroup: "Business Group",
				settlementSetup: "Settlement Setup",
				otherBusinessType: "Other Business Type",
				tin: "Tin",
				logo: "Logo",
				channelType: "Channel Type",
				businessType: "Business Type",
			};
  
			for (const [key, value] of Object.entries(fieldsMapping)) {
				const fieldValue = channelPrimaryInfo[key];
				if (fieldValue) {
					channelPrimaryInfoArray.push({
					field_name: value,
					old_values: fieldValue.oldValue,
					new_values: fieldValue.newValue,
					});
				}
			}
  
			if (channelPrimaryInfoArray.length > 0) {
				return channelPrimaryInfoArray;
			} else {
				return [];
			}
	  	}
  
	  	if (result === 'channelAddress') {
			const channelAddresses = data.result?.channelPrimaryInfo?.channelAddresses;
			if (!channelAddresses || channelAddresses.length === 0) {
				return [];
			}
  
			const channelAddressArray: any[] = [];
  
			for (const address of channelAddresses) {
				const addressFieldsMapping = {
					country: "Country",
					province: "Province",
					city: "City",
					barangay: "Barangay",
					zipCode: "Zip Code",
				};
	
				for (const [key, value] of Object.entries(addressFieldsMapping)) {
					const fieldValue = address[key];
					if (fieldValue) {
					channelAddressArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
					}
				}
			}
  
  
			if (channelAddressArray.length > 0) {
				return channelAddressArray;
			} else {
				return [];
			}
	  	}
  
	  	if (result === 'channelContactDetails') {
			const channelContactDetails = data.result?.channelPrimaryInfo?.channelContactDetails;
			if (!channelContactDetails || channelContactDetails.length === 0) {
				return [];
			}
  
			const channelContactDetailsArray: any[] = [];
  
			for (const contact of channelContactDetails) {
				const contactFieldsMapping = {
					name: "Name",
					position: "Position",
					department: "Department",
					emailAddress: "Email Address",
					telNo: "Telephone Number",
					mobileNo: "Mobile Number",
				};
		
				for (const [key, value] of Object.entries(contactFieldsMapping)) {
					const fieldValue = contact[key];
					if (fieldValue) {
					channelContactDetailsArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
					}
				}
			}
  
			if (channelContactDetailsArray.length > 0) {
				return channelContactDetailsArray;
			} else {
				return [];
			}
	  	} 
		
		if (result === 'channelOfficer') {
			const channelOfficer = data.result?.channelPrimaryInfo?.channelOfficer;
			if (!channelOfficer) {
				return [];
			}
  
			const channelOfficerArray: any[] = [];
  
			const officerFieldsMapping = {
				name: "Name",
				emailAddress: "Email Address",
				telNo: "Telephone Number",
				mobileNo: "Mobile Number",
			};
  
			for (const [key, value] of Object.entries(officerFieldsMapping)) {
				const fieldValue = channelOfficer[key];
				if (fieldValue) {
					channelOfficerArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				}
			}
  
			if (channelOfficerArray.length > 0) {
				return channelOfficerArray;
			} else {
				return [];
			}
	  	}
  
		if (result === 'channelContractDetails') {
			const channelContractDetails = data.result?.channelContractDetails;
			if (!channelContractDetails) {
				return [];
			}
	
			const channelContractDetailsArray: any[] = [];
  
			const contractFieldsMapping = {
				bondStartDate: "Bond Start Date",
				bondEndDate: "Bond End Date",
				settlementAgreement: "Settlement Agreement",
				thresholdAmount: "Threshold Amount",
				bondType: "Bond Type",
				bondAmount: "Bond Amount",
				depositConsolidation: "Deposit Consolidation",
				depositMode: "Deposit Mode",
				accreditationFee: "Accreditation Fee",
				annualFee: "Annual Fee",
				vatType: "VAT Type",
				vatRate: "VAT Rate",
				otherBusinessLines: "Other Business Lines",
			};
  
			for (const [key, value] of Object.entries(contractFieldsMapping)) {
				const fieldValue = channelContractDetails[key];
				if (fieldValue) {
					channelContractDetailsArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				}
			}
  
			if (channelContractDetailsArray.length > 0) {
				return channelContractDetailsArray;
			} else {
				return [];
			}
		}

		if (result === 'channelCashCollection') {
			const cashCollection = data.result?.channelContractDetails?.channelCashCollection;
			const channelCashCollectionArray: any[] = [];

			if (cashCollection && cashCollection.length > 0) {	
				for (const collection of cashCollection) {
					const cashCollectionFieldsMapping = {
						acctName: "Account Name",
						acctNo: "Account Number",
						acctRype: "Account Type",
						bankBranch: "Bank Branch",
						name: "Bank Name",
					};
		
					for (const [key, value] of Object.entries(cashCollectionFieldsMapping)) {
						const fieldValue = collection[key];
						if (fieldValue) {
							channelCashCollectionArray.push({
								field_name: value,
								old_values: fieldValue.oldValue,
								new_values: fieldValue.newValue,
							});
						}
					}
				}
			}
  
			if (channelCashCollectionArray.length > 0) {
				return channelCashCollectionArray;
			} else {
				return [];
			}
		}

		if (result === 'channelCheckCollection') {
			const checkCollection = data.result?.channelContractDetails?.channelCheckCollection;
			const channelCheckCollectionArray: any[] = [];

			if (checkCollection && checkCollection.length > 0) {
				for (const collection of checkCollection) {
					const checkCollectionFieldsMapping = {
						acctName: "Account Name",
						acctNo: "Account Number",
						acctType: "Account Type",
						bankBranch: "Bank Branch",
						name: "Bank Name",
					};
		
					for (const [key, value] of Object.entries(checkCollectionFieldsMapping)) {
					const fieldValue = collection[key];
					if (fieldValue) {
						channelCheckCollectionArray.push({
							field_name: value,
							old_values: fieldValue.oldValue,
							new_values: fieldValue.newValue,
						});
					}
					}
				}
			}
  
			if (channelCheckCollectionArray.length > 0) {
				return channelCheckCollectionArray;
			} else {
				return [];
			}
		}

		if (result === 'channelAccreditation') {
	
			const accreditation = data.result?.channelContractDetails?.channelAccreditation;
			const channelAccreditationArray: any[] = [];

			if (accreditation) {
				const accreditationFieldsMapping = {
					birCertReg: "BIR Certification/Registration",
					corpSecCert: "Corporate Secretary Certification",
					companyProfile: "Company Profile",
					govtIds: "Government IDs",
					genInfoSheets: "General Information Sheets",
					nonDisclosure: "Non-disclosure Agreement",
					secRegCert: "SEC Registration Certificate",
					secArticleIincorp: "SEC Articles of Incorporation",
					certAuthority: "Certificate of Authority",
					signedNda: "Signed NDA",
					auditFinancialStmt: "Audit Financial Statements",
					incomeTaxReturn: "Income Tax Return",
					cbciAmlQuestion: "CBCI AML Questionnaire",
					secondAuditFinStmt: "Second Audit Financial Statements",
					dataPivacyPia: "Data Privacy PIA",
					dataPrivacyPat: "Data Privacy PAT",
					thirdPartySecChecklist: "Third Party Security Checklist",
				};
		
				for (const [key, value] of Object.entries(accreditationFieldsMapping)) {
					const fieldValue = accreditation[key];
					if (fieldValue) {
						channelAccreditationArray.push({
							field_name: value,
							old_values: fieldValue.oldValue,
							new_values: fieldValue.newValue,
					});
					}
				}
			}
  
			if (channelAccreditationArray.length > 0) {
				return channelAccreditationArray;
			} else {
				return [];
			}
		}

		if (result === 'channelTermsDuration') {
	
			const termsDuration = data.result?.channelContractDetails?.channelTermsDuration;
			const channelTermsDurationArray: any[] = [];

			if (termsDuration) {
			const termsDurationFieldsMapping = {
				executionDate: "Execution Date",
				autoRenew: "Auto Renew",
				renewalFrequency: "Renewal Frequency",
				terminationDate: "Termination Date",
			};
	
			for (const [key, value] of Object.entries(termsDurationFieldsMapping)) {
				const fieldValue = termsDuration[key];
				if (fieldValue) {
					channelTermsDurationArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
				});
				}
			}
			}
  
			if (channelTermsDurationArray.length > 0) {
				return channelTermsDurationArray;
			} else {
				return [];
			}
		}
	  
		if (result === 'channelReports') {
			const channelReports = data.result?.channelReports;
			if (!channelReports || channelReports.length === 0) {
				return [];
			}
		
			const channelReportsArray: any[] = [];
		
			for (const report of channelReports) {
				const transmissionType = report.transmissionType;
				const reportEvents = report.reportEvents;

				if (transmissionType && reportEvents && reportEvents.length > 0) {
					for (const event of reportEvents) {
						const eventType = event.event_type;
						if (eventType) {
							channelReportsArray.push({
								transmission_type: transmissionType,
								event_type: eventType,
								pair_id: event.pair_id ? event.pair_id : null,
								event_time: {
									old_values: event.event_time.oldValue,
									new_values: event.event_time.newValue,
								},
								covered_schedule: {
									old_values: event.covered_schedule.oldValue,
									new_values: event.covered_schedule.newValue,
								},
								covered_time_from: {
									old_values: event.covered_time_from.oldValue,
									new_values: event.covered_time_from.newValue,
								},
								covered_time_to: {
									old_values: event.covered_time_to.oldValue,
									new_values: event.covered_time_to.newValue,
								},
							});
						}
					}
				}
			}
  
			if (channelReportsArray.length > 0) {
				return channelReportsArray;
			} else {
				return [];
			}
		}

	} else if(type == "branch"){
		if(data.result.channelContractDetails.length > 0){
			return [];
		}else{
			return [];
		}
	}else if(type == "terminal"){
		const auditDetail = data?.auditDetail;
		if (!auditDetail) {
			return [];
		}

		if (result === 'status') {
			const auditDetail = data.auditDetail;
			if (!auditDetail) {
			  return [];
			}
		  
			const status = auditDetail.status;
			if (!status) {
			  return [];
			}
		  
			const statusArray: any[] = [];
			statusArray.push({
				field_name: "Status",
				old_values: status.oldValue,
				new_values: status.newValue,
			});
  
			if (statusArray.length > 0) {
				return statusArray;
			} else {
				return [];
			}
		}
		  
		  if (result === 'terminals') {
			const auditDetail = data.auditDetail;
			if (!auditDetail) {
			  	return [];
			}
		  
			const terminals = auditDetail.terminals;
			if (!terminals) {
			  	return [];
			}
		  
			const terminalsArray: any[] = [];
		  
			const fieldsMapping = {
				tpaId: "TPA ID",
				spmNumber: "SPM Number",
				activationDate: "Activation Date",
				channelTransactionType: "Channel Transaction Type",
				channelTypeId: "Channel Type ID",
				posType: "POS Type",
				settlementSetup: "Settlement Setup",
				meralcoPaymentCenterId: "Meralco Payment Center ID",
				remarks: "Remarks",
			};
		  
			for (const [key, value] of Object.entries(fieldsMapping)) {
				const fieldValue = terminals[key];
				if (fieldValue) {
					terminalsArray.push({
						field_name: value,
						old_values: fieldValue.oldValue,
						new_values: fieldValue.newValue,
					});
				}
			}
		  
			if (terminalsArray.length > 0) {
			  	return terminalsArray;
			} else {
			  	return [];
			}
		  }
	}else{
		return [];
	}
  
	return [];
  };

export const actionChecker = (data: any,) => {	
	const showAllChannelTabs = [
		'Added Channel Profile',
		'Saved draft',
		'Edited draft',
		'Added Profile',
		'Approved New Channel',
		'Rejected New Channel',
	].map(actions=>actions.toUpperCase());

	const showChannelPrimaryInfo = [
		'Edited Channel Details - Primary Info',
		'Edited details',
		'Enable Product Channel Level',
		'Disable Product Channel Level',
		'Enable Partner Channel Level',
		'Disable Partner Channel Level',
		'Enable Branch Channel Level',
		'Disable Branch Channel Level',
		'Enable TPAID Channel Level',
		'Disable TPAID Channel Level',
		'Added Service Fee Share',
		'Edited Service Fee Share',
	].map(actions=>actions.toUpperCase());

	const showChannelBasicInfo = [
		'Updated Channel status',
		'Edited Channel details',
		'Edited Channel details',
		'Approved new Channel',
		'Rejected new Channel',
		'Updated Status',
		'Added Terminal Profile',
		'Updated Terminal status',
		'Edited Terminal details',
		'Updated Channel status',
		'Updated Branch status',
	].map(actions=>actions.toUpperCase());

	const showChannelContractDetails = [
		'Approved Service Fee Share',
		'Rejected Service Fee Share',
		'Edited Channel Details - Contract Details',
	].map(actions=>actions.toUpperCase());

	const showChannelReports = [
		'Downloaded file',
		'Edited Channel Details - Reports',
		'Send Channel Client Credential',
		'Download Channel Public Certificate',
		'Regenerate Channel Client Credential',
	].map(actions=>actions.toUpperCase());

	const action = data;

	if(showAllChannelTabs.some((keyword) => action && action === keyword)){
		return 'SHOW ALL CHANNEL TABS';
	}else if(showChannelPrimaryInfo.some((keyword) => action && action === keyword)){
		return 'SHOW CHANNEL PRIMARY INFO';
	}else if(showChannelBasicInfo.some((keyword) => action && action === keyword)){
		return 'SHOW CHANNEL BASIC INFO';
	}else if(showChannelContractDetails.some((keyword) => action && action === keyword)){
		return 'SHOW CHANNEL CONTRACT DETAILS';
	}else if(showChannelReports.some((keyword) => action && action === keyword)){
		return 'SHOW CHANNEL REPORTS';
	}else{
		return [];
	}
};