import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import styles from './WalletReplenishment.module.css';
import DownloadButton from 'components/Buttons/DownloadButton/DownloadButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { debounce as _debounce } from 'lodash';
import FilterSelect from '../../Filter/FilterSelect';
import DatePicker, {
	validateDateFrom,
	validateDateTo,
} from '../../Filter/Datepicker';
import ChannelNameFilter from '../../Filter/ChannelNameFilter';
import { connect, useDispatch, useSelector } from 'react-redux';
import { load } from 'redux/modules/walletReplenishment';
import { showAccessDeniedModal } from 'redux/modules/access';
import verifyPermission, { IScope } from 'utils/verifyPermission';
import { resolveValue } from 'utils/common';
import { calibrateDate } from 'utils/date';
import { ReducerStateType } from 'redux/modules/reducers';
import Grid from 'components/Grid/Grid';
import { useToggle } from 'utils/hooks';
import PasswordModal from 'components/Modal/PasswordModal';
import HTTP from 'helpers/ApiClient';

const CREATE_CWS_WALLET_PERMISSION = {
	scope: 'cws.create.wallet.replenishment',
	resource: '/wallet-replenishments',
};

const CREATE_BIP_WALLET_PERMISSION = {
	scope: 'bip.create.wallet.replenishment',
	resource: '/wallet-replenishments',
};
const DOWNLOAD_WALLET_REPLENISHMENT = {
	scope: 'bip.wallet.download.replenishment',
	resource: '/v1/wallet-replenishments/{walletAdjustmentId}/download',
};

const shouldDisableSubmission = (values) => {
	const { dateFrom, dateTo, partnerName, status } = values;
	return ![partnerName, status, dateFrom, dateTo].some((v) => v);
};

type WalletReplenishmentFilterFormData = {
	filter: {
		requestNumber?: string;
		dateTo?: string;
		dateFrom?: string;
		partnerName?: string;
		serviceType?: string;
		status?: string;
	};
};

type Props = {
	onSubmit: (values: any, isSearch: boolean) => void;
	initialValues: Partial<WalletReplenishmentFilterFormData['filter']>;
};

const WalletReplenishmentFilter: React.FC<Props> = ({
	onSubmit,
	initialValues = {},
}) => {
	const {
		register,
		getValues,
		setValue,
		clearErrors,
		handleSubmit,
		control,
		watch,
		formState: { errors, isValid, isDirty },
		reset,
	} = useForm<WalletReplenishmentFilterFormData>({
		mode: 'all',
		defaultValues: { filter: initialValues },
	});

	const dispatch = useDispatch();

	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const handleOnClick = () => {
		if (
			verifyPermission(scopes, CREATE_CWS_WALLET_PERMISSION) ||
			verifyPermission(scopes, CREATE_BIP_WALLET_PERMISSION)
		) {
			dispatch(load());
		} else {
			dispatch(showAccessDeniedModal());
		}
	};
	if (window.location.hash) {
		if (window.location.hash.substring(1) == 'Add') {
			_debounce(() => {
				handleOnClick();
			}, 500);
		}
	}
	const [initialized, setInitialized] = useState(false);
	const [isReset, setIsReset] = useState(false);

	const handleFilterSubmit = (values, isSearch = false) => {
		onSubmit(values || {}, isSearch);
	};

	const transactionNumber = watch('filter.requestNumber');
	const allValues = watch('filter');

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const filters = getValues('filter') || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...filters, transactionNumber: value }, true);
			}
		}, 500),
		[]
	);

	const handleReset = () => {
		if (isDirty) {
			reset({ filter: initialValues });
			setValue('filter', initialValues);
			handleFilterSubmit(initialValues);
			setIsReset(true);
		}
	};

	useEffect(() => {
		setInitialized(true);
	}, []);
	useEffect(() => {
		if (initialized && !isReset) {
			handleSearch(transactionNumber);
		}
	}, [transactionNumber]);

	useEffect(() => {
		if (isReset) {
			setIsReset(false);
		}
	}, [isReset]);

	const {
		value: isSelectFileTypeModalShown,
		valueOn: showFileModal,
		valueOff: hideFileModal,
	} = useToggle();
	const {
		value: isPasswordModalShown,
		valueOn: showPasswordModal,
		valueOff: hidePasswordModal,
	} = useToggle();
	const [password, setPassword] = useState('');
	const handleDownloadFiles = () => {
		if (verifyPermission(scopes, DOWNLOAD_WALLET_REPLENISHMENT)) {
			const { dateFrom, dateTo, partnerName, status, ...rest } = allValues;
			const dates = calibrateDate(dateFrom, dateTo);
			const params = {
				...rest,
				...dates,
				partnerName:
					resolveValue(partnerName?.['label']) == ''
						? undefined
						: resolveValue(partnerName?.['label']),
				status: resolveValue(status) == '' ? undefined : resolveValue(status),
			};
			HTTP.get('/v2/wallet/replenishment-list/download', {
				params,
			}).then((value) => {
				setPassword(value.data.data.password);
				showPasswordModal();
			});
		} else {
			dispatch(showAccessDeniedModal());
		}
	};
	const WalletReplenishmentTitle = 'Wallet Replenishment List';
	return (
		<>
			<Filter title="Wallet Replenishment">
				<FilterOuter>
					<Grid column>
						<Grid container align="end" gutters>
							<Grid column size={3} of={11}>
								<PrimaryButton type="submit" fullWidth onClick={handleOnClick}>
									Add New Replenishment
								</PrimaryButton>
							</Grid>
							<Grid column size={5} of={12}>
								<InputWithIcon
									placeholder="Search Request No."
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
											className: styles.inputIcon,
										},
									]}
									name="filter.requestNumber"
									control={control}
								/>
							</Grid>
							<Grid column size={2} of={12}>
								<DownloadButton
									className={styles.downloadButton}
									fullWidth
									onClick={handleDownloadFiles}
								/>
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<Grid
						container
						align="spread"
						verticalAlign="start"
						gutters="x-small"
					>
						<Grid column size={3} of={12}>
							<DatePicker
								control={control}
								name="filter.dateFrom"
								label="Date From"
								errors={errors}
								validate={(v) =>
									validateDateFrom(v, getValues('filter.dateTo'), () =>
										clearErrors('filter.dateFrom')
									)
								}
							/>
						</Grid>
						<Grid column size={3} of={12}>
							<DatePicker
								control={control}
								name="filter.dateTo"
								label="Date To"
								errors={errors}
								validate={(v) =>
									validateDateTo(getValues('filter.dateFrom'), v, () =>
										clearErrors('filter.dateTo')
									)
								}
							/>
						</Grid>
						<Grid column size={3} of={12}>
							<ChannelNameFilter
								displayName="Partner Name"
								name="filter.partnerName"
								control={control}
								value={allValues.partnerName}
							/>
						</Grid>
						<Grid column size={3} of={12}>
							<FilterSelect
								name="filter.status"
								label="Status"
								control={control}
								options={[
									{ label: '', value: null },
									{ label: 'Pending', value: 'Pending' },
									{ label: 'Approved', value: 'Approved' },
									{ label: 'Declined', value: 'Declined' },
								]}
							/>
						</Grid>
						<Grid
							column
							container
							size={4}
							of={12}
							verticalAlign="end"
							align="spread"
							className={styles.innerButtons}
						>
							<Grid column size={4} of={7}>
								<OutlineButton fullWidth onClick={handleReset}>
									Clear All Filters
								</OutlineButton>
							</Grid>
							<Grid column size={3} of={7}>
								<PrimaryButton
									disabled={shouldDisableSubmission(allValues)}
									onClick={handleSubmit(({ filter = {} }) =>
										handleFilterSubmit(filter, true)
									)}
									fullWidth
								>
									Filter
								</PrimaryButton>
							</Grid>
						</Grid>
					</Grid>
				</FilterInner>
			</Filter>
			<PasswordModal
				password={password}
				open={isPasswordModalShown}
				onClose={hidePasswordModal}
				title={WalletReplenishmentTitle}
			/>
		</>
	);
};

export default WalletReplenishmentFilter;
