/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { padStart } from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import styles from './SelectDatepicker.module.css';
import SelectField, { SelectOption } from '../SelectField/SelectField';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import Grid from 'components/Grid/Grid';
import { useWatch } from 'react-hook-form';
import { useFormState } from 'react-hook-form';

export type DateValue = {
	month?: string | number;
	day?: string | number;
	year?: string | number;
};

type Props = {
	label?: string;
	required?: boolean;
	name: string;
	disabled?: boolean;
	onChange?: (value: DateValue) => void;
	value?: DateValue;
	defaultValue?: any;
	yearsInPast?: number;
	yearsInFuture?: number;
	control?: Control<any>;
};

const monthOptions: SelectOption[] = moment.months().map((month, i) => {
	return {
		label: month,
		value: `${i + 1}`,
	};
});

type SelectProps = {
	name?: string;
	value?: string;
	defaultValue?: string;
	disabled?: boolean;
	onChange?: (v: SelectOption) => void;
	onBlur?: () => void;
	placeholder?: string;
	options?: SelectOption[];
	error?: string;
	inputRef?: any;
};

const DateSelect: React.FC<SelectProps> = ({
	name,
	value,
	disabled,
	onChange,
	onBlur,
	placeholder = 'DD',
	options = [],
	...rest
}) => {
	return (
		<SelectField
			placeholder={placeholder}
			size="xx-small"
			name={name}
			value={value}
			onBlur={onBlur}
			onChange={onChange}
			options={options}
			displayErrorMessage={false}
			disabled={disabled}
			{...rest}
		/>
	);
};

const SelectDatepicker: React.FC<Props> = ({
	label,
	required,
	name,
	yearsInFuture = 0,
	yearsInPast = 50,
	control,
	value,
	defaultValue,
	disabled,
}) => {
	const getDateValue = (
		data: string | DateValue | undefined,
		mdy: 'month' | 'day' | 'year',
		isDefault?: boolean
	): string | undefined => {
		if (!data) return undefined;

		const formats = {
			month: isDefault ? 'MM' : 'MMMM',
			day: 'DD',
			year: 'YYYY',
		};

		return typeof data === 'string'
			? moment(data).format(formats[mdy]).toString()
			: data?.[mdy]?.toString();
	};
	
	const [months, setMonths] = useState(getDateValue(value, 'month', true));
	const [days, setDays] = useState(getDateValue(value, 'day', true));
	const [years, setYears] = useState(getDateValue(value, 'year', true));

	const yearOptions = useMemo(() => {
		const yearNow = new Date().getFullYear();
		const futureYears = Array(yearsInFuture)
			.fill(null)
			.map((_, i) => `${yearNow + i + 1}`)
			.reverse();
		const presentToPastYears = Array(yearsInPast)
			.fill(null)
			.map((_, i) => `${yearNow - i}`);

		return futureYears.concat(presentToPastYears).map((v) => ({
			label: v,
			value: v,
		}));
	}, [yearsInFuture]);

	const formatDateOnChange = (month, day, year) => {
		const monhtOrDefault = month || "";
		const dayOrDefault = day || "";
		const yearOrDefault = year || "";
		return `${`${monhtOrDefault}`.padStart(
			2,
			'0'
		)}/${`${dayOrDefault}`.padStart(2, '0')}/${yearOrDefault}`;
	};

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState: { error } }) => (
				<div>
					<div className={cx(styles.label, { [styles.required]: required })}>
						{label}
					</div>
					<Grid container className={styles.selectContainer}>
						<Grid column size={1} of={3}>
							<DateSelect
								value={months}
								defaultValue={getDateValue(field.value, 'month')}
								placeholder="MM"
								options={monthOptions}
								error={error?.message}
								onChange={(v) => {
									setMonths(v.value);
									field.onChange(formatDateOnChange(v.value, days, years));
								}}
								disabled={disabled}
								inputRef={field.ref}
								onBlur={field.onBlur}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<DateSelect
								value={days}
								defaultValue={getDateValue(field.value, 'day')}
								placeholder="DD"
								error={error?.message}
								options={
									Array(moment(months).daysInMonth()) // dynamic month
									.fill(null)
									.map((_, i) => {
										const v = `${i + 1}`;
										return {
											label: padStart(v, 2, '0'),
											value: +v,
										};
									})}
								onChange={(v) => {
									setDays(v.value);
									field.onChange(formatDateOnChange(months, v.value, years));
								}}
								disabled={disabled}
								onBlur={field.onBlur}
							/>
						</Grid>
						<Grid column size={1} of={3}>
							<DateSelect
								value={years}
								defaultValue={getDateValue(field.value, 'year')}
								placeholder="YYYY"
								options={yearOptions}
								error={error?.message}
								onChange={(v) => {
									setYears(v.value);
									field.onChange(formatDateOnChange(months, days, v.value));
								}}
								disabled={disabled}
								onBlur={field.onBlur}
							/>
						</Grid>
					</Grid>
					{error && (
						<div
							className={cx({
								'slds-has-error': !!error,
							})}
						>
							<div className={cx(styles.helper, 'slds-form-element__help')}>
								{error.message}
							</div>
						</div>
					)}
				</div>
			)}
		/>
	);
};

export default SelectDatepicker;
