import Grid from 'components/Grid/Grid';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import SelectField from 'components/Inputs/SelectField/SelectField';
import AmountField from 'components/Inputs/TextField/AmountField';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import Section, { SectionRow } from 'components/Section/Section';
import { useFormContext } from 'react-hook-form';
import {
	BOND_TYPE_OPTIONS,
	DEPOSIT_CONSOLIDATION_OPTIONS,
	DEPOSIT_MODE_OPTIONS,
	SETTLEMENT_AGREEMENT_OPTIONS,
} from '../ContractDetailsConstants';
import { ContractDetailsType } from '../ContractDetailsSchemas';

type Props = {
	title?: string;
	disabled?: boolean;
	name: string;
};
const CollectionServiceAgreement: React.FC<Props> = ({
	disabled,
	title,
	name,
}) => {
	const { control, watch } = useFormContext<ContractDetailsType>();
	return (
		<Section title={title}>
			<SectionRow>
				<Grid column size={1} of={3}>
					<DatePickerField
						label="Bond Start Date"
						name={`${name}.bondStartDate`}
						control={control}
						disabled={disabled}
						placeholder="MM/DD/YYYY"
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<DatePickerField
						label="Bond End Date"
						name={`${name}.bondEndDate`}
						control={control}
						disabled={disabled}
						placeholder="MM/DD/YYYY"
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="Settlement Arrangement"
						placeholder="Select Settlement Arrangement"
						control={control}
						name={`${name}.settlementArrangement`}
						disabled={disabled}
						options={SETTLEMENT_AGREEMENT_OPTIONS}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<AmountField
						label="Threshold Amount"
						placeholder="0.00"
						name={`${name}.thresholdAmount`}
						control={control}
						disabled={disabled}
						formatValue={TextFieldFormatter.amountFormat}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label="Bond Type"
						placeholder="Select Bond Type"
						name={`${name}.bondType`}
						control={control}
						disabled={disabled}
						options={BOND_TYPE_OPTIONS}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<AmountField
						label="Bond Amount"
						placeholder="0.00"
						name={`${name}.bondAmount`}
						control={control}
						disabled={disabled}
						formatValue={TextFieldFormatter.amountFormat}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						required
						label="Deposit Consolidation"
						placeholder="Select Deposit Consolidation"
						name={`${name}.depositConsolidation`}
						control={control}
						disabled={disabled}
						options={DEPOSIT_CONSOLIDATION_OPTIONS}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label="Deposit Mode"
						placeholder="Select Deposit Consolidation"
						name={`${name}.depositMode`}
						control={control}
						disabled={disabled}
						options={DEPOSIT_MODE_OPTIONS}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<AmountField
						required
						label="Accreditation Fee"
						placeholder="0.00"
						name={`${name}.accreditationFee`}
						control={control}
						disabled={disabled}
						formatValue={TextFieldFormatter.amountFormat}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<AmountField
						required
						label="Annual Fee"
						placeholder="0.00"
						name={`${name}.annualFee`}
						control={control}
						disabled={disabled}
						formatValue={TextFieldFormatter.amountFormat}
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default CollectionServiceAgreement;
