import cx from 'classnames';
import styles from 'containers/ChannelManagement/Channel/ChannelProfile/index.module.css';
import TerminalModal from 'containers/ChannelManagement/Terminal/Modal/TerminalModal';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
	clearSelectedChannel,
	setBranchFormValues,
	setChannelFormValues,
	setTerminalFormValues,
} from 'redux/modules/channelList';
import { useToggle } from 'utils/hooks';
import ChannelModal from '../ChannelModal/ChannelModal';
import { ChannelProfileProps } from '../ChannelProfile/types';

import BranchModal from 'containers/ChannelManagement/Branch/BranchModal';
import ChannelManagementList from 'containers/ChannelManagement/List/ChannelManagementList';
import ChannelTab2 from 'containers/ChannelManagement/List/ChannelTab';
import {
	useGetBranchAutoSave,
	useGetBranchDetails,
	useGetChannelAutoSave,
	useGetChannelDetails,
	useGetTerminalAutoSave,
	useGetTerminalDetails,
} from './query';
import { ReducerStateType } from 'redux/modules/reducers';
import { useEffect } from 'react';
import Alert from 'components/Alert/Alert';
import { ReactComponent as WarningIcon } from 'assets/icons/ic-warning.svg';

import TerminalTab from 'containers/ChannelManagement/Terminal/Tab/TerminalTab';
import BranchTab from 'containers/ChannelManagement/Branch/BranchTab/BranchTab';

export const initialValues = {
	primaryInformation: {
		basicInformation: {
			channelName: '',
		},
		channelAddresses: [
			{
				locationBase: 'LOCAL',
				country: '1',
				area: '',
				region: '',
			},
		],
		channelContactDetails: [{ name: '', email: [], contactNumber: [] }],
		// accountOfficer: { name: '' },
	},
};

const ChannelProfile: React.FC<ChannelProfileProps> = ({
	clearSelectedChannel,
}) => {
	const dispatch = useDispatch();

	const {
		value: isAddChannelProfileModalShowing,
		valueOn: showAddChannelProfileModal,
		valueOff: hideAddChannelProfileModal,
	} = useToggle();

	const {
		value: isAddBranchModalShowing,
		valueOn: showBranchModal,
		valueOff: hideAddBranchModal,
	} = useToggle();

	const {
		value: isAddTerminalModalShowing,
		valueOn: showTerminalModal,
		valueOff: hideAddTerminalModal,
	} = useToggle();

	const showAddChannelProfileModalViewClear = () => {
		showAddChannelProfileModal();
		clearSelectedChannel();
	};

	const activeType = useSelector<ReducerStateType, string>(
		(state) => state.channels.activeType
	);
	const channel = useSelector<ReducerStateType, string>(
		(state) => state.channels
	);
	console.log({ channel });

	const channels = useSelector<ReducerStateType>((state) => state.channels);

	console.log({ channels });

	const { selectedChannel, selectedBranch, selectedTerminal } = useSelector<
		ReducerStateType,
		{
			selectedChannel: { id: number };
			selectedBranch: { id: number };
			selectedTerminal: { id: number };
		}
	>((state) => state.channels ?? {});

	const channelResult = useGetChannelDetails(
		selectedChannel && selectedChannel.id
	);

	const allSuccess = channelResult.every((data) => data.isSuccess === true);

	const { data: branchDetails, isSuccess: isBranchSuccess } =
		useGetBranchDetails(selectedBranch && selectedBranch.id);
	const { data: terminalDetails, isSuccess: isTerminalSuccess, refetch: refetchTerminal } =
		useGetTerminalDetails(selectedTerminal && selectedTerminal.id);

	const {
		data: channelAutoSave,
		isSuccess: isChannelAutoSaveSuccess,
		isError: isChannelAutoSaveError,
	} = useGetChannelAutoSave();
	const {
		data: branchAutoSave,
		isSuccess: isBranchAutoSaveSuccess,
		isError: isBranchAutoSaveError,
	} = useGetBranchAutoSave(activeType);
	const {
		data: terminalAutoSave,
		isSuccess: isTerminalAutoSaveSuccess,
		isError: isTerminalAutoSaveError,
	} = useGetTerminalAutoSave(activeType);

	const hasChannelAutoSave =
		!isChannelAutoSaveError && isChannelAutoSaveSuccess && !!channelAutoSave;
	const hasBranchAutoSave =
		!isBranchAutoSaveError && isBranchAutoSaveSuccess && !!branchAutoSave;
	const hasTerminalAutoSave =
		!isTerminalAutoSaveError && isTerminalAutoSaveSuccess && !!terminalAutoSave;

	useEffect(() => {
		if (activeType === 'channel') {
			if (allSuccess) {
				let payload = {};
				channelResult.map((data: any, index) => {
					if (index === 0) {
						payload = { primaryInfo: data.data.data };
					}
					if (index === 1) {
						payload = { ...payload, contractDetails: data.data.data };
					}
					if (index === 2) {
						payload = { ...payload, reports: data.data.data };
					}
				});
				dispatch(setChannelFormValues(payload));
			}
		} else if (activeType === 'branch') {
			console.log(' triggered');
			if (isBranchSuccess && !!branchDetails) {
				dispatch(setBranchFormValues(branchDetails));
			}
		} else if (activeType === 'terminal') {
			if (isTerminalSuccess && !!terminalDetails) {
				dispatch(setTerminalFormValues(terminalDetails));
			}
		}
	}, [
		activeType,
		allSuccess,
		branchDetails,
		isBranchSuccess,
		isTerminalSuccess,
		terminalDetails,
	]);

	return (
		<div className={cx('slds-card', styles.paperContainer)}>
			{(hasChannelAutoSave || hasBranchAutoSave || hasTerminalAutoSave) && (
				<Alert
					bodyText={`You have unfinished work for adding a new ${
						activeType ? activeType : 'channel'
					}. Would you like to continue?`}
					cancelFn={() => console.log}
					confirmFn={() => {
						// setFromAutoSave(true);
						// showAddPartnerProfileModal();
					}}
					iconLeft={() => <WarningIcon />}
				/>
			)}
			<div className={cx(styles.title)}>Channel Profile</div>
			<div className={cx('slds-grid slds-gutters', styles.mainContainer)}>
				<ChannelManagementList
					addNewChannelButtonClick={showAddChannelProfileModalViewClear}
					addNewBranchButtonClick={showBranchModal}
					addNewTerminalButtonClick={showTerminalModal}
				/>
				{(activeType === 'channel' || activeType === '') && <ChannelTab2 />}
				{activeType === 'branch' && <BranchTab />}
				{activeType === 'terminal' && <TerminalTab refetchTerminal={refetchTerminal} />}
			</div>
			<ChannelModal
				hasAutoSave={hasChannelAutoSave}
				autoSaveValue={channelAutoSave}
				open={isAddChannelProfileModalShowing}
				onClose={hideAddChannelProfileModal}
				// initialValues={initialValues}
				// selectedChannel={undefined}
			/>
			<BranchModal
				open={isAddBranchModalShowing}
				onClose={hideAddBranchModal}
			/>
			<TerminalModal
				open={isAddTerminalModalShowing}
				onClose={hideAddTerminalModal}
			/>
		</div>
	);
};

export default connect((state) => state, { clearSelectedChannel })(
	ChannelProfile
);
