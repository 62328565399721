import Grid from 'components/Grid/Grid';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import Section, { SectionRow } from 'components/Section/Section';
import { AddressProps } from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import BarangaySelect from 'containers/ChannelManagement/Branch/BranchForm/LocationSelect/BarangaySelect';
import CitySelect from 'containers/ChannelManagement/Branch/BranchForm/LocationSelect/CitySelect';
import CountrySelect from 'containers/ChannelManagement/Branch/BranchForm/LocationSelect/CountrySelect';
import ProvinceSelect from 'containers/ChannelManagement/Branch/BranchForm/LocationSelect/ProvinceSelect';
import RegionSelect from 'containers/ChannelManagement/Branch/BranchForm/LocationSelect/RegionSelect';
import ZipCodeSelect from 'containers/ChannelManagement/Branch/BranchForm/LocationSelect/ZipCodeSelect';
import { AddressType, Area } from 'utils/lookup';

const Address: React.FC<AddressProps> = ({
	sectionTitle,
	control,
	locationBase,
	country,
	area,
	region,
	stateOrProvince,
	municipalityOrCity,
	barangay,
	street,
	buildingNameOrNumber,
	zipCode,
	isAreaToZipCodeVisible = true,
}) => {
	return (
		<>
			<Section title={sectionTitle || 'Address'}>
				<SectionRow verticalAlign="start">
					<Grid column size={12} of={12} verticalAlign="start">
						<RadioGroup
							label="Location Base"
							control={control}
							options={AddressType}
							isHorizontalLabel
							{...locationBase}
						/>
					</Grid>
				</SectionRow>
				<SectionRow verticalAlign="start">
					<Grid column size={4} of={12} verticalAlign="start">
						<CountrySelect
							{...country}
							selectFieldProps={{
								control: control,
								required: true,
								...country?.selectFieldProps,
							}}
						/>
					</Grid>
					{isAreaToZipCodeVisible && (
						<>
							<Grid column size={4} of={12} verticalAlign="start">
								<SelectField
									label="Area"
									control={control}
									options={Area.map((i) => ({
										label: i,
										value: i,
									}))}
									required
									{...area}
								/>
							</Grid>
							<Grid column size={4} of={12} verticalAlign="start">
								<RegionSelect
									{...region}
									selectFieldProps={{
										control: control,
										required: true,
										...region?.selectFieldProps,
									}}
								/>
							</Grid>
						</>
					)}
				</SectionRow>
				{isAreaToZipCodeVisible && (
					<>
						<SectionRow verticalAlign="start">
							<Grid column size={4} of={12} verticalAlign="start">
								<ProvinceSelect
									{...stateOrProvince}
									selectFieldProps={{
										control: control,
										required: true,
										...stateOrProvince?.selectFieldProps,
									}}
								/>
							</Grid>
							<Grid column size={4} of={12} verticalAlign="start">
								<CitySelect
									{...municipalityOrCity}
									selectFieldProps={{
										control: control,
										required: true,
										...municipalityOrCity?.selectFieldProps,
									}}
								/>
							</Grid>
							<Grid column size={4} of={12} verticalAlign="start">
								<BarangaySelect
									{...barangay}
									selectFieldProps={{
										control: control,
										required: true,
										...barangay?.selectFieldProps,
									}}
								/>
							</Grid>
						</SectionRow>
						<SectionRow verticalAlign="start">
							<Grid column size={4} of={12} verticalAlign="start">
								<TextField
									label="Building Name/No."
									control={control}
									required
									{...buildingNameOrNumber}
								/>
							</Grid>
							<Grid column size={4} of={12} verticalAlign="start">
								<TextField label="Street" control={control} {...street} />
							</Grid>
							<Grid column size={4} of={12} verticalAlign="start">
								<ZipCodeSelect
									{...zipCode}
									selectFieldProps={{
										control: control,
										...zipCode?.selectFieldProps,
									}}
								/>
							</Grid>
						</SectionRow>
					</>
				)}
			</Section>
		</>
	);
};

export default Address;
