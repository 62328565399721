import React, { useState, useEffect, useMemo } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { connect, useSelector } from 'react-redux';
import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';
import _, { isEmpty } from 'lodash';
import { IconSettings, Button } from '@salesforce/design-system-react';

import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';

import SuccessFileDownloadModal, {
	SuccessFileDownloadModalBody,
	SuccessFileDownloadText,
	SuccessFileDownloadModalActions,
} from 'components/Modal/SuccessFileDownloadModal';

import SuccessModal, {
	SuccessModalBody,
	SuccessText,
	SuccessModalActions,
} from 'components/Modal/SuccessModal';

import PartnerAuditModal from 'components/PartnerAuditModal/PartnerAuditModal';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ChannelCertificateTable from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Certificate/ChannelCertificateTable';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Credentials/ChannelCredentials.module.css';
import Grid from 'components/Grid/Grid';
import HTTP from 'helpers/ApiClient';
import cx from 'classnames';
import BasicInformation, {
	BasicInformationFields,
} from 'components/BillerForm/Tabs/PrimaryInformation/sections/BasicInformation/BasicInformation';

type GetAuditTrailArgs = {
	page?: number;
	limit?: number;
};

interface ICell {
	value: any;
	cell?: any;
}

export type ChannelCredentialsFormData = {

	basicInformation?: BasicInformationFields;
};
type Props = {
	initialValues: ChannelCredentialsFormData;
	disabled?: boolean;
	onSubmit?: (values: any) => void;
};

// const ChannelIntegration = ({
// 	selectedBiller: selectedBranchPerCategory,
// 	product_type_id,
// }) => {

const ChannelCredentials: React.FC<Props> =  ({ 
	initialValues,
	disabled,
	onSubmit,
}) => {

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);
	const hasUserPermission = useHasUserPermission('products');

	const hasAuditTrailPermission = useMemo(
		() => hasUserPermission(productCategory, 'list.audit-trail'),
		[hasUserPermission, productCategory]
	);

	// const selectedBiller = useMemo(
	// 	() => selectedBranchPerCategory[productCategory],
	// 	[productCategory, selectedBranchPerCategory]
	// );
	const selectedBiller = initialValues;

	const product_id = selectedBiller;
	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [fileSuccessModalOnClick, setFileSuccessModalOnClick] = useState({ action: () => {
		showSuccessFileDownloadModal();} });
    const [successModalOnClick, setSuccessModalOnClick] = useState({ action: () => {
        showSuccessModal();} });
	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessFileDownloadModalShown,
		valueOn: showSuccessFileDownloadModal,
		valueOff: hideSuccessFileDownloadModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isPartnerAuditModalVisible,
		valueOn: showPartnerAuditModal,
		valueOff: hidePartnerAuditModal,
	} = useToggle();

	const [data, setData] = useState([]);
	const [auditDetailsList, setAuditDetailsList] = useState([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [count, setCount] = useState(0);

	const errorMessageDuringLoading = 'A problem occurred with loading the data';

	useEffect(() => {
		initialFetch();
	}, []);

	useEffect(() => {
		initialFetch();
		setPage(1);
	}, [selectedBiller]);

	const getAuditDetails = async (audit_id, product_type_id, product_id) => {
		showLoadingMessage(
			"Please wait while the selected audit log's entries are being loaded"
		);
		const result = await HTTP.get(
			`/v2/products/${product_type_id}/${product_id}/audit-trails/${audit_id}`
		)
			.then((resp) => {
				const data = resp.data.data;
				if (!data) {
					throw new Error('Invalid response data');
				}
				const formattedData = isEmpty(data.audit_detail)
					? []
					: typeof data.audit_detail === 'string'
					? JSON.parse(data.audit_detail)
					: data;
				setAuditDetailsList(formattedData);
			})
			.finally(() => {
				hideLoading();
			});
	};

	const getAuditTrails = async function (
		values: GetAuditTrailArgs,
		product_type_id,
		product_id
	) {
		const { page, limit } = values;
		const params = { page, limit };

		const result = await HTTP.get(
			`/v2/products/${product_type_id}/${product_id}/audit-trails`,
			{
				params,
			}
		);

		return result.data;
	};

	const fetchAuditTrailList = async (
		values,
		retry = false,
		withLoader = true
	) => {
		const doRequest = async (p) => {
			try {
				withLoader && showLoadingMessage(
					'Please wait while audit logs are being loaded'
				);
				const result = await getAuditTrails(p, initialValues, product_id);
				const { data, meta } = result;
				setData(data);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e) {
				if (withLoader) {
					showErrorMessage(errorMessageDuringLoading);
					setRetryBtnOnClick({
						action: () => {
							fetchAuditTrailList(values, retry, withLoader);
						},
					});
					return;
				}
				throw e;
			} finally {
				withLoader && hideLoading();
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const params = {
			page,
			limit: pageSize,
			...values,
		};

		await doRequest(params);
	};

	const initialFetch = async () => {
		if (!hasAuditTrailPermission) return;

		try {
			await fetchAuditTrailList({}, false, true);
		} catch (e: any) {
			showErrorMessage(errorMessageDuringLoading);
			setRetryBtnOnClick({
				action: () => {
					initialFetch();
				},
			});
		} finally {
			hideLoading();
		}
	};

	const handleOnClickViewLogs = async (row) => {
		const audit_id = row.original.id;
		await getAuditDetails(audit_id, initialValues, product_id);
		showPartnerAuditModal();
	};

	const handlePageChange = async (_page, _pageSize) => {
		const params = { page, pageSize };
		const { page: _, pageSize: __ } = params;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			await fetchAuditTrailList({ page: _page, limit: _pageSize });
		}
	};

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
		showErrorModal();
	};

	const CERTIFICATE_COLUMNS = (handleViewLogs) => [
		{
			Header: 'Date & Time Generated',
			id: 'created_at',
			sortable: false,
			width: '40%',
			accessor: ({ created_at }) => formatDate(created_at),
		},
		{
			Header: 'Certificate',
			id: 'certificate',
			sortable: false,
			width: '30%',
		},
		{
			Header: (): JSX.Element => <div className={styles.actionHeader}>Actions</div>,
			id: 'actions',
			width: '30%',
			//accessor: (values: any) => values,
			Cell: ({ value }: ICell): JSX.Element => {
				return (
					<div id="td-actions" className={styles.actions}>	
						<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
							<div className={cx(styles.actionCell, 'slds-grid')}>
								<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
									<Button
										assistiveText={{ icon: 'Icon Bare Small' }}
										className={styles.downloadIcon}
										iconCategory="utility"
										iconName="download"
										iconSize="medium"
										iconVariant="bare"
										iconClassName={cx(styles.iconBtn)}
										onClick={() => {
                                            fileSuccessModalOnClick.action();
                                            //successModalOnClick.action();
                                        }}
										variant="icon"
									/>
								</div>
							</div>
						</IconSettings>
					</div>
				);
			},
		},
	];

// 	return selectedBiller ? (
// 		<>
// 			{isLoading && (
// 				<FullPageLoader open={isLoading} message={loadingMessage} />
// 			)}
// 			{isSuccessModalShown && (
// 				<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
// 					<SuccessModalBody>
// 						<SuccessText>
// 							<div className={styles.successHeader}>Success!</div>
// 							<div className={styles.successBody}>{successMessage}</div>
// 						</SuccessText>
// 					</SuccessModalBody>
// 					<SuccessModalActions>
// 						<PrimaryButton
// 							className={styles.successModalBtn}
// 							onClick={() => {
// 								hideSuccessModal();
// 								doneBtnOnClick.action();
// 							}}
// 						>
// 							Done
// 						</PrimaryButton>
// 					</SuccessModalActions>
// 				</SuccessModal>
// 			)}
// 			{isErrorModalShown && (
// 				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
// 					<ErrorModalBody>
// 						<div className={styles.errorHeader}>Timeout Error!</div>
// 						<div className={styles.errorBody}>{errorMessage}</div>
// 						<div className={styles.errorFooter}>Please try again</div>
// 					</ErrorModalBody>
// 					<ErrorModalActions>
// 						<PrimaryButton
// 							fullWidth
// 							onClick={() => {
// 								hideErrorModal();
// 								retryBtnOnClick.action();
// 							}}
// 							className={styles.errorModalBtn}
// 						>
// 							Retry
// 						</PrimaryButton>
// 					</ErrorModalActions>
// 				</ErrorModal>
// 			)}
// 			{selectedBiller && !isLoading && (
// 				<>
// 					<div className={styles.title}>{selectedBiller.name}</div>
// 					<Grid container gutters="xx-small">
// 						<Grid column>
// 							<div className={styles.statusContainer}>
// 								<div className={styles.statusLabel}>Partner Status:</div>
// 								<div className={styles.statusField}>
// 									<ChannelStatusSelect value={selectedBiller.status} disabled />
// 								</div>
// 							</div>
// 						</Grid>
// 					</Grid>
// 					<div className={styles.partnerAuditTrailTableContainer}>
// 						<ChannelIntegrationTable
// 							columns={CERTIFICATE_COLUMNS(handleOnClickViewLogs)}
// 							data={data}
// 							pageSize={pageSize}
// 							page={page}
// 							count={count}
// 							onPageChange={handlePageChange}
// 						/>
// 					</div>
// 				</>
// 			)}
// 			<PartnerAuditModal
// 				isOpen={isPartnerAuditModalVisible}
// 				data={auditDetailsList}
// 				onClose={() => {
// 					hidePartnerAuditModal();
// 				}}
// 			/>
// 		</>
// 	) : (
// 		<></>
// 	);
// };

// export default connect((state: any) => ({
// 	selectedBiller: state.products.selectedBiller,
// 	product_type_id: state.sidebar.itemId,
// }))(ChannelIntegration);

const mockData = [
	{
	  id: 1,
	  created_at: '2023-07-01T12:34:56Z',
	  certificate: 'KYSHANF331',
	},
	{
	  id: 2,
	  created_at: '2023-07-02T08:15:30Z',
	  certificate: 'KYSQDGE153',
	},
	// Add more sample data objects as needed
  ];

return (
    <>
        {isLoading && <FullPageLoader open={isLoading} message={loadingMessage} />}
      
        {isSuccessFileDownloadModalShown && (
            <SuccessFileDownloadModal open={isSuccessFileDownloadModalShown} onClose={hideSuccessFileDownloadModal}>
                <SuccessFileDownloadModalBody>
                    <SuccessFileDownloadText>
                        <div className={styles.successHeader}>File is being Downloaded</div>
							<div className={styles.successBody}>{successMessage}</div>
                    </SuccessFileDownloadText>
                </SuccessFileDownloadModalBody>
                <SuccessFileDownloadModalActions>
                    <PrimaryButton
                        className={styles.successModalBtn}
                        onClick={() => {
                            hideSuccessFileDownloadModal();
                            doneBtnOnClick.action();
                        }}
                    >
                        Done
                    </PrimaryButton>
                </SuccessFileDownloadModalActions>
            </SuccessFileDownloadModal>
        )}
      
			{isSuccessModalShown && (
				<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								hideSuccessModal();
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>Timeout Error!</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorFooter}>Please try again</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								hideErrorModal();
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
      {!isLoading && (
        <>
          <div className={styles.channelCeretificateTableContainer}>
            <ChannelCertificateTable
              columns={CERTIFICATE_COLUMNS(handleOnClickViewLogs)}
              // data={data}
              // count={count}
              data={mockData}
              count={mockData.length}
              pageSize={pageSize}
              page={page}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      )}
      <PartnerAuditModal
        isOpen={isPartnerAuditModalVisible}
        data={auditDetailsList}
        onClose={() => {
          hidePartnerAuditModal();
        }}
      />
    </>
  );
};

export default ChannelCredentials;