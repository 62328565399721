import React from 'react';
import styles from './ProductTypeContainer.module.css';
import ProductScrollableList from './ProductScrollableList/ProductScrollableList';
import cx from 'classnames';
import { ProductTypeContainerProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';

const ProductTypeContainer: React.FC<ProductTypeContainerProps> = ({
	name,
	channel,
	activePage,
}) => {
	return (
		<div
			className={cx(
				'slds-col slds-small-size_5-of-12 slds-large-size_3-of-12',
				styles.listContainer
			)}
		>
			<div className={styles.containerTitle}>{name}</div>
			<ProductScrollableList channel={channel} activePage={activePage} />
		</div>
	);
};

export default ProductTypeContainer;
