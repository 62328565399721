import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from 'components/Inputs/SelectField/SelectField';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import DraftsErrorModal from 'containers/ChannelManagement/Drafts/ErrorModal/ErrorModal';
import DraftsFilter from 'containers/ChannelManagement/Drafts/DraftsFilter/DraftsFilter';
import { draftsFilterSchema } from 'containers/ChannelManagement/Drafts/DraftsFilter/DraftsFilterSchema';
import DraftsTable from 'containers/ChannelManagement/Drafts/DraftsTable/DraftsTable';
import { TableStates } from 'containers/ChannelManagement/Drafts/DraftsTable/types';
import {
	BRANCH_NAME_ENDPOINT,
	CHANNEL_ACCOUNT_NAMES_ENDPOINT,
	DATE_CREATED,
	DRAFTS_FILTER_DEFAULT_VALUES,
	SEARCH,
	TPAID_ENDPOINT,
} from 'containers/ChannelManagement/Drafts/constants';

import {
	fetchAndUpdateTableStates,
	updateDraftsDropdownOptions,
} from 'containers/ChannelManagement/Drafts/utils';
import { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useImmer } from 'use-immer';
import { useToggle } from 'utils/hooks';

const Drafts: React.FC = () => {
	const [tableStates, setTableStates] = useImmer<TableStates>({
		data: [],
		count: 0,
		page: 1,
		pageSize: 25,
		sortBy: DATE_CREATED,
		sortOrder: 'desc',
	});

	const [isToRefreshTableData, setIsToRefreshTableData] =
		useImmer<boolean>(true);

	const [channelAccountNameOptions, setChannelAccountNameOptions] = useImmer<
		SelectOption[]
	>([]);

	const [channelAccountNameIsLoading, setChannelAccountNameIsLoading] =
		useImmer<boolean>(false);

	const [branchNameOptions, setbranchNameOptions] = useImmer<SelectOption[]>(
		[]
	);

	const [branchNameIsLoading, setbranchNameIsLoading] =
		useImmer<boolean>(false);

	const [tpaidOptions, setTpaidOptions] = useImmer<SelectOption[]>([]);

	const [tpaidIsLoading, setTpaidIsLoading] = useImmer<boolean>(false);

	const [errorMessage, setErrorMessage] = useImmer<string>('');

	const [isToUpdateDraftDropDowns, setIsToUpdateDraftDropDowns] =
		useImmer<boolean>(true);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isError,
		valueOn: showError,
		valueOff: hideError,
	} = useToggle();

	const {
		value: searchAvailability,
		valueOn: disableSearch,
		valueOff: enableSearch,
	} = useToggle();

	const draftsFilterForm = useForm({
		mode: 'all',
		defaultValues: DRAFTS_FILTER_DEFAULT_VALUES,
		resolver: yupResolver(draftsFilterSchema),
	});

	const timeoutIdentifier = useRef<NodeJS.Timeout>();

	const refreshTableData = useCallback(() => {
		fetchAndUpdateTableStates(
			draftsFilterForm.handleSubmit,
			tableStates,
			setTableStates,
			showLoading,
			hideLoading,
			enableSearch,
			disableSearch,
			setErrorMessage,
			showError
		);
	}, [
		disableSearch,
		draftsFilterForm.handleSubmit,
		enableSearch,
		hideLoading,
		setErrorMessage,
		setTableStates,
		showError,
		showLoading,
		tableStates,
	]);

	useEffect(() => {
		if (isToRefreshTableData) {
			refreshTableData();
			setIsToRefreshTableData(false);
		}
	}, [isToRefreshTableData, refreshTableData, setIsToRefreshTableData]);

	useEffect(() => {
		const dropDowns = [
			{
				url: CHANNEL_ACCOUNT_NAMES_ENDPOINT,
				setStateOfDropDownOptions: setChannelAccountNameOptions,
				setStateOfIsLoading: setChannelAccountNameIsLoading,
			},
			{
				url: BRANCH_NAME_ENDPOINT,
				setStateOfDropDownOptions: setbranchNameOptions,
				setStateOfIsLoading: setbranchNameIsLoading,
			},
			{
				url: TPAID_ENDPOINT,
				setStateOfDropDownOptions: setTpaidOptions,
				setStateOfIsLoading: setTpaidIsLoading,
			},
		];

		if (isToUpdateDraftDropDowns) {
			dropDowns.map((item) => {
				updateDraftsDropdownOptions(
					item.setStateOfDropDownOptions,
					item.setStateOfIsLoading,
					setErrorMessage,
					showError,
					item.url
				);
			});

			setIsToUpdateDraftDropDowns(false);
		}
	}, [
		isToUpdateDraftDropDowns,
		setChannelAccountNameIsLoading,
		setChannelAccountNameOptions,
		setErrorMessage,
		setIsToUpdateDraftDropDowns,
		setTpaidIsLoading,
		setTpaidOptions,
		setbranchNameIsLoading,
		setbranchNameOptions,
		showError,
	]);

	return (
		<>
			<FormProvider {...draftsFilterForm}>
				<DraftsFilter
					onFilterClick={() => setIsToRefreshTableData(true)}
					onClearAllFiltersClick={() => {
						draftsFilterForm.reset();
						setIsToRefreshTableData(true);
						setIsToUpdateDraftDropDowns(true);
					}}
					onSearchChange={() => {
						clearTimeout(timeoutIdentifier.current);
						timeoutIdentifier.current = setTimeout(() => {
							const search = draftsFilterForm.getValues(SEARCH);
							search && search.length > 2 && setIsToRefreshTableData(true);
						}, 1500);
					}}
					searchAvailability={searchAvailability}
					channelAccountNameIsLoading={channelAccountNameIsLoading}
					channelAccountNameOptions={channelAccountNameOptions}
					branchNameIsLoading={branchNameIsLoading}
					branchNameOptions={branchNameOptions}
					tpaidIsLoading={tpaidIsLoading}
					tpaidOptions={tpaidOptions}
				/>
			</FormProvider>

			<DraftsTable
				tableStates={tableStates}
				onPageChange={(page: number, pageSize: number) => {
					setTableStates((tableStates) => {
						tableStates.page = page;
						tableStates.pageSize = pageSize;
					});
					setIsToRefreshTableData(true);
				}}
				onSort={(sortBy: string, sortOrder: string) => {
					setTableStates((tableStates) => {
						tableStates.sortBy = sortBy;
						tableStates.sortOrder = sortOrder;
					});
					setIsToRefreshTableData(true);
				}}
			/>
			{isLoading && (
				<FullPageLoader open={isLoading} message={'Please wait...'} />
			)}
			{isError && (
				<DraftsErrorModal
					open={isError}
					onClose={hideError}
					errorMessage={errorMessage}
					onRetry={() => setIsToRefreshTableData(true)}
				/>
			)}
		</>
	);
};

export default Drafts;
