import { SelectOption } from 'components/Inputs/SelectField/SelectField';
import {
	ChannelAccreditation,
	ChannelAccreditationItem,
	CollectionDepositoryAccount,
	CollectionServiceAgreement,
	ContractDetails,
	TermsAndDuration,
	ValueAddedTax,
} from './types';
export const SETTLEMENT_AGREEMENT_OPTIONS: SelectOption[] = [
	{ label: 'Bonded/Conventional', value: 'Bonded' },
	{ label: 'Pre-funded (Wallet)', value: 'Pre-funded' },
	{ label: 'Hybrid', value: 'Hybrid' },
];

export const BOND_TYPE_OPTIONS: SelectOption[] = [
	{ label: 'Cash-MOA', value: 'CASHMOA' },
	{ label: 'DOA-TD', value: 'DOA-TD' },
	{ label: 'DOA-SA', value: 'DOA-SA' },
	{ label: 'DOA-LOC', value: 'DOA-LOC' },
	{ label: 'Surety', value: 'SURETY' },
	{ label: 'Others', value: 'OTHERS' },
];

export const DEPOSIT_CONSOLIDATION_OPTIONS: SelectOption[] = [
	{ label: 'Direct to CBCI', value: 'CBCI' },
	{ label: 'Direct to Biller', value: 'BILLER' },
];

export const DEPOSIT_MODE_OPTIONS: SelectOption[] = [
	{ label: 'Over the Counter', value: 'OTC' },
	{ label: 'Digital/Mobile', value: 'DIGITAL' },
];

export const accountType = [
	{ label: 'Savings', value: 'SAVINGS' },
	{ label: 'Current', value: 'CURRENT' },
];

export const IS_AUTO_RENEW_OPTIONS = [
	{ label: 'No', value: '0' },
	{ label: 'Yes', value: '1' },
];

export const RENEWAL_FREQUENCY_OPTIONS = [
	{ label: 'Annually', value: 'ANNUALLY' },
	{ label: 'Semi-Annually', value: 'SEMI_ANNUALLY' },
	{ label: 'Quarterly', value: 'QUARTERLY' },
	{ label: 'Monthly', value: 'MONTHLY' },
	{ label: 'Every N Years', value: 'EVERY_N_YEARS' },
];

export const VAT_TYPE_OPTIONS = [
	{ label: 'VATtable', value: 'VATABLE' },
	{ label: 'VAT-Zero', value: 'VATZERO' },
	{ label: 'VAT-Exempt', value: 'VATEXEMPT' },
	{ label: 'Non-VAT', value: 'NONVAT' },
];

export const BUSINESS_LINES_OPTIONS = [
	{
		label: 'E-loading',
		value: 'E-LOADING',
	},
	{
		label: 'Remittance',
		value: 'REMITTANCE',
	},
	{
		label: 'CTPL',
		value: 'CTPL',
	},
	{
		label: 'Insurance',
		value: 'LOCAL_CASHIER_CHECK',
	},
	{
		label: 'Beep',
		value: 'BEEP',
	},
	{
		label: 'Cash Payout',
		value: 'CASH-PAYOUT',
	},
	{
		label: 'Cash Disbursement',
		value: 'CASH-DISBURSEMENT',
	},
];

export const ACCREDITATION_REQUIREMENTS_OPTIONS = [
	{
		label: 'BIR Certificate of Registration Form No. 2303',
		name: 'bir_certificate_form',
		required: false,
	},
	{
		label:
			'Corporate Secretary’s Certificate or Board Resolution stating names and specimen signatures of all authorized signatories to transact business with CBCI',
		name: 'corp_secretary_cert',
		required: false,
	},
	{
		label: 'Business Permit/Mayor’s Permit (updated/ current year)',
		name: 'business_permit',
		required: false,
	},
	{ label: 'Company Profile', name: 'company_profile', required: false },
	{
		label: 'Government Issued ID of the authorized signatory',
		name: 'govt_id',
		required: false,
	},
	{
		label: 'Latest General Information Sheet (GIS)',
		name: 'gen_info_sheet',
		required: false,
	},
	{
		label: 'Non-disclosure Agreement (NDA)',
		name: 'non_disclosure',
		required: false,
	},
	{
		label: 'SEC Registration Certificate',
		name: 'sec_reg_cert',
		required: false,
	},
	{
		label:
			'SEC Articles of Incorporation & By-Laws (including all amendments, if any)',
		name: 'sec_article_inc',
		required: false,
	},
	{
		label:
			'Secondary license or certificate of authority issued by the supervising authority or other government agency (if applicable)',
		name: 'secondary_license',
		required: false,
	},
	{
		label: 'Signed Non-disclosure Agreement (NDA)',
		name: 'signed_non_disclosure',
		required: false,
	},
	{
		label:
			'Audited Financial Statements (with Auditor’s Opinion & Notes to Financial Statements)',
		name: 'audited_financial_stmt',
		required: false,
	},
	{
		label: 'Income Tax Return',
		name: 'income_tax',
		required: false,
	},
	{
		label: 'Accomplished CBCI AML Questionnaire',
		name: 'cbci_aml_question',
		required: false,
	},
	{
		label:
			'Audited Financial Statements (previous 2 years with Auditor’s Opinion and Notes to Financial Statements)',
		name: 'annual_income_tax',
		required: false,
	},
	{ label: 'Data Privacy: PIA', name: 'data_privacy_pia', required: false },
	{ label: 'Data Privacy: PAT', name: 'data_privacy_pat', required: false },
	{
		label: 'Third-Party Security Checklist',
		name: 'third_party_security_check',
		required: false,
	},
];

export const CollectionDepositoryAccountDefault: CollectionDepositoryAccount = {
	accountName: '',
	accountNumber: '',
	accountType: '',
	bankBranch: '',
	bankName: '',
};

export const CollectionServiceAgreementDefault: CollectionServiceAgreement = {
	accreditationFee: null!,
	annualFee: null!,
	bondAmount: null!,
	bondEndDate: null,
	bondStartDate: null,
	bondType: '',
	depositConsolidation: '',
	depositMode: '',
	settlementArrangement: '',
	thresholdAmount: null!,
};
export const TermsAndDurationDefault: TermsAndDuration = {
	executionDate: null,
	frequencyInterval: null,
	autoRenew: '',
	renewalFrequency: '',
	terminationDate: undefined,
};

export const ValueAddedTaxDefault: ValueAddedTax = {
	vatType: '',
};
export const ChannelAccreditationItemDefault: ChannelAccreditationItem = {
	file: '',
	filename: '',
	label: '',
	name: '',
	objectKey: '',
	status: '',
};

export const ChannelAccreditationDefault: ChannelAccreditation = {
	accreditationFee: ChannelAccreditationItemDefault,
	annualFee: ChannelAccreditationItemDefault,
	auditedFinancialStmt: ChannelAccreditationItemDefault,
	birCertificateForm: ChannelAccreditationItemDefault,
	bondCopy: ChannelAccreditationItemDefault,
	businessPermit: ChannelAccreditationItemDefault,
	cbciAmlQuestion: ChannelAccreditationItemDefault,
	companyProfile: ChannelAccreditationItemDefault,
	corpSecretaryCert: ChannelAccreditationItemDefault,
	dataPrivacyPat: ChannelAccreditationItemDefault,
	dataPrivacyPia: ChannelAccreditationItemDefault,
	genInfoSheet: ChannelAccreditationItemDefault,
	govtId: ChannelAccreditationItemDefault,
	incomeTax: ChannelAccreditationItemDefault,
	nonDisclosure: ChannelAccreditationItemDefault,
	prevAuditedFinancialStmt: ChannelAccreditationItemDefault,
	secArticleInc: ChannelAccreditationItemDefault,
	secondaryLicense: ChannelAccreditationItemDefault,
	secRegCert: ChannelAccreditationItemDefault,
	signedNonDisclosure: ChannelAccreditationItemDefault,
	thirdPartySecurityCheck: ChannelAccreditationItemDefault,
};

export const CONTRACT_DETAILS_DEFAULT_VALUE: ContractDetails = {
	cashCollection: [CollectionDepositoryAccountDefault],
	checkCollection: [CollectionDepositoryAccountDefault],
	channelAccreditation: ChannelAccreditationDefault,
	collectionServiceAgreement: CollectionServiceAgreementDefault,
	termsAndDuration: TermsAndDurationDefault,
	valueAddedTax: ValueAddedTaxDefault,
};

export const ContractDetailsIdentifiers = {
	CollectionServiceAgreement: {
		title: 'Collection Service Agreement, Extension, and NDA',
		controlName: 'collectionServiceAgreement',
	},
	CashCollection: {
		title: 'Cash Collection Depository Account',
		controlName: 'cashCollection',
	},
	CheckCollection: {
		title: 'Check Collection Depository Account',
		controlName: 'checkCollection',
	},
	TermsAndDuration: {
		title: 'Terms and Duration',
		controlName: 'termsAndDuration',
	},
	ValueAddedTax: {
		title: 'Value-added Tax (VAT)',
		controlName: 'valueAddedTax',
	},
	OtherBusinessLines: {
		title: 'Other Business Lines',
		controlName: 'otherBusinessLines',
	},
	AccreditationRequirements: {
		title: 'Accreditation Requirements',
		controlName: 'channelAccreditation',
	},
};
