import React, { useState } from 'react';

import { Modal } from '@salesforce/design-system-react/module/components';
import PartnerDetailsHeader from '../PartnerDetailsHeader';
import PartnerDetailsBody from '../PartnerDetailsBody';

import { PartnerDetailsProps } from '../types';
import styles from './PartnerDetails.module.css';
import client from 'helpers/ApiClient';

const PartnerDetails: React.FC<PartnerDetailsProps> = ({
	partnerData,
	onClose,
}) => {
	const [refetchData, setRefetchData] = useState<boolean>(false);
	const [totalCollection, setTotalCollection] = useState<number>();
	const [totalVolume, setTotalVolume] = useState<number>();

	const fetchPartnerCounts = async (params) => {
		try {
			const response = await client.get(
				`/v2/bfa-admin-portal/products/volume-count/${partnerData?.id}`,
				{
					params,
				}
			);
			if (params.type === 'collection') {
				setTotalCollection(response?.data?.data?.totalCollection);
			} else {
				setTotalVolume(response?.data?.data?.totalVolume);
			}

			return null;
		} catch (error) {
			return error;
		}
	};

	const data = {
		header: {
			id: partnerData?.id,
			productId: partnerData?.productId,
			productName: partnerData?.productName,
			logoSrc: partnerData?.logo || undefined,
			partnerName: partnerData?.name,
			partnerStatus: partnerData?.bfaStatus,
			totalVolume: partnerData?.totalVolume || totalVolume || 0,
			totalCollection: partnerData?.totalCollection || totalCollection || 0,
			sfPassOnTiers:
				partnerData?.schemeType === 'TIER' ? partnerData?.serviceFee : [],
			sfPassOnSpecial:
				partnerData?.schemeType === 'SPECIAL' ? partnerData?.serviceFee : [],
			partnerCode: partnerData?.partnerCode,
			serviceCode: partnerData?.serviceCode,
			category: partnerData?.category,
			sfSchemeType: partnerData?.schemeType,
			sfPassOn:
				partnerData?.schemeType === 'REGULAR' ||
				partnerData?.schemeType === 'PERCENTAGE'
					? partnerData?.serviceFeePassOn
					: '',
			dateActivated: partnerData?.dateActivated || '',
			activatedBy: partnerData?.activatedBy || '',
		},
	};

	return (
		<div>
			<Modal
				isOpen={true}
				ariaHideApp={false}
				disableClose={false}
				onRequestClose={onClose}
				size="medium"
				containerClassName={styles.modalContainer}
				headerClassName={styles.headerContainer}
				heading={<div className={styles.header}>{partnerData.partnerName}</div>}
			>
				<PartnerDetailsHeader
					setRefetchData={setRefetchData}
					{...data.header}
				/>
				<PartnerDetailsBody
					data={data.header}
					refetchData={refetchData}
					setRefetchData={setRefetchData}
					fetchPartnerCounts={fetchPartnerCounts}
				/>
			</Modal>
		</div>
	);
};
export default PartnerDetails;
