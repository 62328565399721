import { ReactNode, useEffect } from 'react';
import { Modal } from '@salesforce/design-system-react';
import { connect, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { setCurrentBillerTab } from 'redux/modules/products';
import { useToggle } from 'utils/hooks';
import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import {useState } from 'react';
import {
	convertValue,
	convertValueToString,
	filterArrayValues,
	removeCharacterInStringValues,
	handleArrayWithSpaceValues,
} from 'utils/filter';
//containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/TableModal/
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import folderIcon from 'assets/icons/ic-folder.svg';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/LogsModal/ChannelAuditLogsModal.module.css';
import Section from 'components/Section/Section';
import Table from 'components/CWSTable/Table';
import { channelLogsModalTabs } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/LogsModal/channel_logs_modal_tabs';
import ChannelAuditTableModal from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/TableModal/ChannelAuditTableModal';
import { remappedDataHandler, actionChecker } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditDataHandler';
import cx from 'classnames';
import {merge, cloneDeep} from 'lodash';

type TabLabelProps = {
	label: string;
	done?: boolean;
	disabled?: boolean;
	action?: string;
};

export const TabLabel: React.FC<TabLabelProps> = ({
	label,
	done,
	disabled,
	action,
}) => {
	return (
		<div
			className={cx(
				action !== 'VIEW' &&
					styles.tabLabel + ' ' + { [styles.tabDisabled]: disabled }
			)}
		>
			{label}
			{/* {done && <FaCheckCircle className={cx(styles.tabCheck, {})} />} */}
		</div>
	);
};

type Props = {
	action?: string;
	tabStatus?: TabStatus;
	activeType?: any;
	listId?: any;
	detailId?: any;
	data?: any;
	onClose?: () => void;
	isOpen: boolean;
	currentLogTab?: string;
	setCurrentBillerTab: (v?: string) => void;
};

type PartialRecord<K extends keyof any, T> = {
	[P in K]?: T;
};

type Tabs = {
	primaryInformation?: any;
	contractDetails?: any;
	reports?: any;
};

type TabStatusValue = { finished?: boolean; disabled?: boolean };

type RemappedData = Record<string, { oldValue: string; newValue: string }>;

export type TabStatus = PartialRecord<keyof Tabs, TabStatusValue>;

const initTabStatus: TabStatus = {
	primaryInformation: { disabled: false },
	contractDetails: { disabled: false },
	reports: { disabled: false },
};
	
const panelPlaceholderProps = {
	title: 'No Selected Channel',
	subtitle: 'Please select a channel.',
};

const ChannelBranchAuditPlaceholder: React.FC<{ title: string }> = ({ title }) => {
	return (
		<div
			className={cx(
				'slds-grid slds-grid_align-center slds-grid_vertical-align-center',
				styles.tabMaxheightAndWidth
			)}
		>
			<div>
				<img src={folderIcon} className={styles.placeholderImg} />
				<div className={styles.placeholderTitle}>{title}</div>
				<div className={styles.placeholderSubTitle}>
					There are no changes in this tab.
				</div>
			</div>
		</div>
	);
};

const ChannelAuditLogsModal: React.FC<Props> = ({
	tabStatus = initTabStatus,
	activeType,
	listId,
	detailId,
	data,
	currentLogTab,
	isOpen,
	onClose = () => {},
	...props
}) => {

	// const activeType: any = useSelector<ReducerStateType>(
	// 	(state) => state.channels.activeType
	// );
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	console.log("ChannelAuditLogsModal CONSOLE");
	console.log("activeType:"+ activeType);
	console.log("listId:"+ listId);
	console.log("detailId:"+ detailId);
	console.log("DATA VALUE");
	console.log(data);
	console.log(data[0].result);

	const {
		value: isChannelAuditTableModalVisible,
		valueOn: showChannelAuditTableModal,
		valueOff: hideChannelAuditTableModal,
	} = useToggle();


	function findIndexName(selected: number): string {
		const idx = channelLogsModalTabs.findIndex((tab) => tab.index == selected);
		return channelLogsModalTabs[idx].name;
	}
	const [showView, setShowView] = useState(false);
	
	console.log("ACTION CHECKER");
	console.log(actionChecker(data[0]?.action?.toUpperCase()));

	console.log("DATA QUERIED");
	// const primaryInfoChkr = data.result.channelPrimaryInfo;
	// console.log(primaryInfoChkr);
	console.log(remappedDataHandler(data[0], activeType, 'channelAddress'));
	console.log("QUERY COMPLETE");
	useEffect(() => {

		const index = channelLogsModalTabs.find((tab) => tab.name === currentLogTab);

		if (index) setSelectedIndex(index.index);

		//const isEditMode = props.action === 'EDIT';

		//if (!isEditMode) return;

		//const hasViewPermission_ = hasViewPermission(currentBillerTab);

		// if (!hasViewPermission_ && showView !== hasViewPermission_) {
		// 	dispatch(showAccessDeniedModal());
		// }

		setShowView(true);
	}, [currentLogTab, props.action, showView]);

	
	useEffect(() => {
		if (!currentLogTab) return;

		const isViewMode = props.action === 'VIEW';

		if (!isViewMode) {
			setShowView(true);
			return;
		}

		// const hasViewPermission_ = hasViewPermission(currentBillerTab);

		// if (!hasViewPermission_) {
		// 	dispatch(showAccessDeniedModal());
		// }

		setShowView(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return (
		<>
		<Modal
			size="medium"
			isOpen={isOpen}
			ariaHideApp={false}
			onRequestClose={onClose}
			contentClassName={styles.ChannelBranchAuditModal}
			appElement={document.getElementById('app')}
			containerClassName={styles.channelBranchAuditModalContainer}
			headerClassName={styles.channelBranchAuditModalHeaderContainer}
			heading={
				<div className={styles.header}>
					<div className={styles.headerLeftPanel}>
						<div className={styles.headerTitle}>View Logs</div>
					</div>
					<div className={styles.headerRightPanel}></div>
				</div>
				
			}
		>
			{activeType && activeType == 'channel' ?(
				<Tabs
				className={styles.tabs}
				onSelect={(i: number) => {
					setSelectedTabIndex(i);
					const auditTab = findIndexName(i);

					console.log("auditTab: "+auditTab)
					setCurrentBillerTab(auditTab);
				}}
				selectedIndex={selectedTabIndex}
			>
				<TabsPanel
					label={
						<TabLabel
							label="Primary Information"
							disabled={
								remappedDataHandler(data[0], activeType, 'basicInfo').length === 0 &&
								remappedDataHandler(data[0], activeType, 'channelAddress').length === 0
							}
							done={
								tabStatus.primaryInformation?.finished || true
							}
							action={props.action}
						/>
					}
				>
					{showView && (
						<div className={cx(styles.content)}>
							{remappedDataHandler(data[0], activeType, 'basicInfo').length > 0 && (
							<>
								<h1>Channel Basic Information</h1>
								<ChannelAuditTableModal
								isOpen={true}
								data={remappedDataHandler(data[0], activeType, 'basicInfo')}
								/>
							</>
							)}
					
							{remappedDataHandler(data[0], activeType, 'channelAddress').length > 0 && (
							<>
								<h1>Channel Address</h1>
								<ChannelAuditTableModal
								isOpen={true}
								data={remappedDataHandler(data[0], activeType, 'channelAddress')}
								/>
							</>
							)}
							
							{remappedDataHandler(data[0], activeType, 'channelContactDetails').length > 0 && (
							<>
								<h1>Channel Contact Details</h1>
								<ChannelAuditTableModal
								isOpen={true}
								data={remappedDataHandler(data[0], activeType, 'channelContactDetails')}
								/>
							</>
							)}
					
							{remappedDataHandler(data[0], activeType, 'channelOfficer').length > 0 && (
							<>
								<h1>Channel Officers</h1>
								<ChannelAuditTableModal
								isOpen={true}
								data={remappedDataHandler(data[0], activeType, 'channelOfficer')}
								/>
							</>
							)}
						</div>
					)}
				</TabsPanel>

				<TabsPanel
					label={
						<TabLabel
							label="Contract Details"
							done={tabStatus.reports?.finished || true}
							action={props.action}
						/>
					}
					disabled={tabStatus.contractDetails?.disabled}
				>
				{showView && (
					<div className={cx(styles.content)}>
						{remappedDataHandler(data[0], activeType, 'channelContractDetails').length > 0 && (
						<>
							<h1>Collection Service Agreement, Extension, and NDA</h1>
							<ChannelAuditTableModal
							isOpen={true}
							data={remappedDataHandler(data[0], activeType, 'channelContractDetails')}
							/>
						</>
						)}
				
						{remappedDataHandler(data[0], activeType, 'channelCashCollection').length > 0 && (
						<>
							<h1>Cash Collection Depository Account</h1>
							<ChannelAuditTableModal
							isOpen={true}
							data={remappedDataHandler(data[0], activeType, 'channelCashCollection')}
							/>
						</>
						)}
				
						{remappedDataHandler(data[0], activeType, 'channelCheckCollection').length > 0 && (
						<>
							<h1>Check Collection Depository Account</h1>
							<ChannelAuditTableModal
							isOpen={true}
							data={remappedDataHandler(data[0], activeType, 'channelCheckCollection')}
							/>
						</>
						)}
						
						{remappedDataHandler(data[0], activeType, 'channelAccreditation').length > 0 && (
						<>
							<h1>Accreditations</h1>
							<ChannelAuditTableModal
							isOpen={true}
							data={remappedDataHandler(data[0], activeType, 'channelAccreditation')}
							/>
						</>
						)}
				
						{remappedDataHandler(data[0], activeType, 'channelTermsDuration').length > 0 && (
						<>
							<h1>Terms and Durations</h1>
							<ChannelAuditTableModal
							isOpen={true}
							data={remappedDataHandler(data[0], activeType, 'channelTermsDuration')}
							/>
						</>
						)}
					</div>
				)}
				</TabsPanel>

				<TabsPanel
					label={
						<TabLabel
							label="Reports"
							done={tabStatus.reports?.finished || true}
							action={props.action}
						/>
					}
					disabled={tabStatus.reports?.disabled}
				>
				{showView && (
					<div className={cx(styles.content)}>
						<h1>SFTP / FTP</h1>
						<ChannelAuditTableModal
							isOpen={true}
							data={[]}
						/>

						<div>
							<h1>Channel Collection Summary Report CCSR Configuration</h1>
							<ChannelAuditTableModal
								isOpen={true}
								data={[]}
							/>
						</div>

						<div>
							<h1>SMTP</h1>
							<ChannelAuditTableModal
								isOpen={true}
								data={[]}
							/>
						</div>
					</div>
				)}
				</TabsPanel>
			</Tabs>
			):(
				<div className={cx(styles.content)}>
				{activeType && activeType == 'branch' ?(
						<h1>Branch Basic Information</h1>
						) : activeType && activeType == 'terminal' ? (
							<>
							  <div className={cx(styles.content)}>
								{remappedDataHandler(data[0], activeType, 'status').length > 0 && (
									<>
										<h1>Terminal Status</h1>
										<ChannelAuditTableModal
											isOpen={true}
											data={remappedDataHandler(data[0], activeType, 'status')}
										/>

									</>
								)}
								{remappedDataHandler(data[0], activeType, 'terminals').length > 0 && (
									<>
										<h1>Terminals</h1>
										<ChannelAuditTableModal
										isOpen={true}
										data={remappedDataHandler(data[0], activeType, 'terminals')}
										/>
									</>
								)}
							  </div>
							</>
						  ) : (
						  
						//<PanelPlaceholder {...panelPlaceholderProps} />
						<div></div>
				)}
				<ChannelAuditTableModal
					isOpen={true}
					data={[]}
				/>
				</div>
			)}
			
			</Modal>
		</>
	);
};

export default connect(
	(state: any) => ({
		currentLogTab: state.products.currentBillerTab,
		isPrimaryInfoValid: state.channels.primaryInfo?.isValid,
		isContractDetailsValid: state.channels.contractDetails?.isValid,
		isReportsValid: state.channels.reports?.isValid,
	}),
	{ setCurrentBillerTab }
)(ChannelAuditLogsModal);