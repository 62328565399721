import { FixMeLater } from 'types';
import { useMemo, useEffect, useState } from 'react';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { ServiceFeeSettingsFormData } from '../../ServiceFee/ServiceFeeSettings';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import {
	ServiceFeeField,
	TotalRateFee,
	useConditionalColumn,
} from '../../ServiceFee/ServiceFeesFields';

import TextField from 'components/Inputs/TextField/TextField';
import Table from 'components/CWSTable/Table';

type TierSchemaProps = {
	disabled?: boolean;
	control: Control<ServiceFeeSettingsFormData>;
	serviceFeeBillingType: ServiceFeeSettingsFormData['billing_type'];
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	withNonShareableAmount?: string;
	tierCount: ServiceFeeSettingsFormData['tier_count'];
	action?: string | undefined;
	modalIsOpen?:boolean;
};

const COLUMN_CONFIG = (
	disabled: TierSchemaProps['disabled'],
	control: TierSchemaProps['control'],
	serviceFeeBillType: TierSchemaProps['serviceFeeBillingType'],
	getValues: UseFormGetValues<any>,
	setValue: UseFormSetValue<any>,
	withNonShareableAmount: TierSchemaProps['withNonShareableAmount'],
	action: TierSchemaProps['action']
) => {
	const conditionalColumn = useConditionalColumn({ control });
	const [dynamicWidth, setDynamicWidth] = useState(0);

	useEffect(() => {
		if (withNonShareableAmount == '0')
			setDynamicWidth(100 / defaultColumns.length);
		else setDynamicWidth(100 / [...defaultColumns, nonShareableColumn].length);
	}, [withNonShareableAmount]);

	const nonShareableColumn = {
		Header: conditionalColumn.nonShareable.headerName,
		id: conditionalColumn.nonShareable.id,
		width: `${dynamicWidth}%`,
		Cell: ({ row: { index }, column: { id } }) => (
			<ServiceFeeField
				name={`tiers.${index}.${id}`}
				defaultValue={action === 'ADD' ? '0' : undefined}
				control={control}
				placeholder={conditionalColumn.nonShareable.headerName}
				isAmountField
				nonShareableColumn={conditionalColumn.nonShareable}
				disabled={disabled || withNonShareableAmount === '0'}
				isFieldTable={true}
			/>
		),
	};

	const defaultColumns = [
		{
			Header: 'Tier',
			id: 'tier',
			Cell: ({ row: { index } }) => <div>{index + 1}</div>,
			width: `${dynamicWidth}%`,
		},
		{
			Header: 'Lower Limit',
			id: 'lower_limit',
			width: `${dynamicWidth}%`,
			Cell: ({ row: { index }, column: { id } }) => (
				<div>
					<TextField
						label=""
						placeholder="Lower Limit"
						control={control}
						name={`tiers.${index}.${id}`}
						fullWidth
						numericCharactersOnly
						formatValue={TextFieldFormatter.commaSeparated}
						disabled={disabled}
						isFieldTable={true}
					/>
				</div>
			),
		},
		{
			Header: 'Upper Limit',
			id: 'upper_limit',
			width: `${dynamicWidth}%`,
			Cell: ({ row: { index }, column: { id } }) => (
				<TextField
					label=""
					placeholder="Upper Limit"
					control={control}
					name={`tiers.${index}.${id}`}
					fullWidth
					formatValue={TextFieldFormatter.commaSeparated}
					numericCharactersOnly
					disabled={disabled}
					isFieldTable={true}
				/>
			),
		},
		{
			Header: conditionalColumn.subsidized.headerName,
			id: conditionalColumn.subsidized.id,
			width: `${dynamicWidth}%`,
			Cell: ({ row: { index }, column: { id } }) => (
				<ServiceFeeField
					name={`tiers.${index}.${id}`}
					defaultValue={action === 'ADD' ? '0' : undefined}
					control={control}
					placeholder={conditionalColumn.subsidized.headerName}
					nonShareableColumn={conditionalColumn.nonShareable}
					isAmountField={serviceFeeBillType?.SUBSIDIZED}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
					getValues={getValues}
					setValue={setValue}
					conditionalColumn={conditionalColumn}
					isFieldTable={true}
				/>
			),
		},
		{
			Header: conditionalColumn.passOn.headerName,
			id: conditionalColumn.passOn.id,
			width: `${dynamicWidth}%`,
			Cell: ({ row: { index }, column: { id } }) => (
				<ServiceFeeField
					name={`tiers.${index}.${id}`}
					defaultValue={action === 'ADD' ? '0' : undefined}
					control={control}
					placeholder={conditionalColumn.passOn.headerName}
					nonShareableColumn={conditionalColumn.nonShareable}
					isAmountField={serviceFeeBillType?.PASS_ON}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
					getValues={getValues}
					setValue={setValue}
					conditionalColumn={conditionalColumn}
					isFieldTable={true}
				/>
			),
		},
		{
			Header: conditionalColumn.total.headerName,
			id: conditionalColumn.total.id,
			width: `${dynamicWidth}%`,
			Cell: ({ row: { index } }) => (
				<TotalRateFee
					idName={conditionalColumn.total.id}
					name={`tiers.${index}`}
					control={control}
					subsidizedName={conditionalColumn.subsidized.id}
					passOnName={conditionalColumn.passOn.id}
					isPercentage={conditionalColumn.nonShareable.isPercentage}
					disabled={disabled}
				/>
			),
		},
	];

	if (withNonShareableAmount != '0')
		return [...defaultColumns, nonShareableColumn];

	return defaultColumns;
};

const TierSchema: React.FC<TierSchemaProps> = ({
	control,
	serviceFeeBillingType,
	withNonShareableAmount,
	tierCount = 0,
	getValues,
	setValue,
	disabled,
	action,
	modalIsOpen
}) => {
	const cnt: FixMeLater = tierCount;
	const rowCount = parseInt(cnt) >= 50 ? 50 : parseInt(cnt);
	const rows = useMemo(
		() => Array(rowCount > 0 ? +rowCount : 0).fill({}),
		[rowCount]
	);

	return (
		<>
			<Table
				columns={COLUMN_CONFIG(
					disabled,
					control,
					serviceFeeBillingType || {},
					getValues,
					setValue,
					withNonShareableAmount,
					action
				)}
				showPagination={false}
				data={rows}
				preHeader={null}
				withRowField={true}
				modalIsOpen={modalIsOpen}
			/>
		</>
	);
};

export default TierSchema;