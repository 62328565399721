import { IconSettings, Button as SFButton, } from "@salesforce/design-system-react";
import cx from 'classnames';
import Button from 'components/Buttons/Button';
import { FaCheck, FaTimes } from 'react-icons/fa';
import styles from './styles.module.css';

const Actions: React.FC<{ value: any, action: any }> = ({ value, action }) => {
    const emitAction = (actionButton) => {
        action(actionButton, value)
    }
    return (
        <>
            <IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
                <div className={cx(styles.actionCell, 'slds-grid')}>
                    <div
                        className={cx(
                            'slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center',
                            styles.actionBtnContainer
                        )}
                    >
                        <SFButton
                            assistiveText={{ icon: 'Icon Bare Small' }}
                            className={styles.crossIcon}
                            iconCategory="utility"
                            iconName="picklist_type"
                            iconSize="small"
                            iconVariant="bare"
                            iconClassName={cx(styles.iconBtn)}
                            onClick={() => { emitAction('details') }}
                            variant="icon"
                        />
                        {
                            value.status == 'FOR_REVIEW' ? (
                                <>
                                    {true && (
                                        <Button
                                            onClick={() => { emitAction('approve') }}
                                        >
                                            <FaCheck
                                                className={styles.iconBtn}
                                                color="#6fcf6c"
                                                size="1.3em"
                                            />
                                        </Button>
                                    )}
                                    {true && (
                                        <Button
                                            onClick={() => { emitAction('reject') }}
                                        >
                                            <FaTimes
                                                className={styles.iconBtn}
                                                color="#db312c"
                                                size="1.3em"
                                            />
                                        </Button>
                                    )}
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </IconSettings>
        </>
    )
}

export default Actions