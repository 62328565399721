import Section, { SectionRow } from 'components/Section/Section';
import SelectField, {
	SelectOption,
} from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import partnerContactDetailsStyles from './PartnerContactDetails.module.css';
import successModalStyles from 'components/Modal/SuccessModal.module.css';
import errorModalStyles from 'components/Modal/ErrorModal.module.css';
import { ReactComponent as AddIcon } from 'assets/icons/ic-add.svg';
import Button from 'components/Buttons/Button';
import {
	EmailTagInput,
	MobileNumberTagInput,
	TelephoneNumberTagInput,
} from 'components/TagInput/TagInput';
import {
	Control,
	useFieldArray,
	UseFormClearErrors,
	UseFormSetValue,
	useWatch,
	UseFormResetField,
} from 'react-hook-form';
import Grid from 'components/Grid/Grid';
import { orderBy as _orderBy, uniqBy as _uniqBy, forEach } from 'lodash';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete.svg';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useToggle } from 'utils/hooks';
import { ReactNode, useState, useRef } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { product_contact_detail } from 'utils/models/product_contact_detail';
import { product_address } from 'utils/models/product_address';
import { product } from 'utils/models/product';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { ContactDefaultValue } from 'utils/lookup';
import { useEffectOnce } from 'react-use';
import AreaHandledSelect from 'components/Inputs/SelectField/Types/LocationSelect/AreaHandled';

const styles = {
	...partnerContactDetailsStyles,
	...successModalStyles,
	...errorModalStyles,
};

type PartnerContactDetailsEntryProps = {
	setValue: UseFormSetValue<any>;
	clearErrors: UseFormClearErrors<any>;
	control: Control<any>;
	isDirty?: boolean;
	data?: product;
	resetField: UseFormResetField<any>;
	action?: string;
};

const PartnerContactDetails: React.FC<
	PartnerContactDetailsEntryProps & {
		maxEntries?: number;
		disabled?: boolean;
	}
> = ({ control, maxEntries, setValue, resetField, disabled, action }) => {
	const name = 'product_contact_details';
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [confirmationHeader, setConfirmationHeader] = useState<ReactNode>('');
	const [confirmationMessage, setConfirmationMessage] = useState<ReactNode>('');
	const [onConfirmationClose, setOnConfirmationClose] = useState({
		action: () => {},
	});
	const [confirmBtnOnClick, setConfirmBtnOnClick] = useState({
		action: () => {},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const watchContactDetails = useWatch({ control, name });

	const watchAddresses = useWatch({
		control,
		name: 'product_addresses',
	});

	const controlledFields = fields.map((field, i) => ({
		...field,
		...watchContactDetails[i],
	}));
	const lastEntryRef = useRef<HTMLDivElement>(null!);
	const firstEntryRef = useRef<HTMLDivElement>(null!);

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const showErrorMessage = (
		header: any,
		message: any,
		onRetryBtnClick?: () => void
	) => {
		setErrorHeader(header);
		setErrorMessage(message);
		showErrorModal();
		setRetryBtnOnClick({
			action: () => {
				hideErrorModal();
				onRetryBtnClick && onRetryBtnClick();
			},
		});
	};

	const showSuccessMessage = (message: any, onDoneBtnClick?: () => void) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	const showConfirmationMessage = (
		header: any,
		message: any,
		onConfirmBtnClick?: () => void,
		onClose?: () => void
	) => {
		setConfirmationHeader(header);
		setConfirmationMessage(message);
		showConfirmationModal();
		setConfirmBtnOnClick({
			action: () => {
				hideConfirmationModal();
				onConfirmBtnClick && onConfirmBtnClick();
			},
		});
		setOnConfirmationClose({
			action: () => {
				hideConfirmationModal();
				onClose && onClose();
			},
		});
	};

	const handleAddEntry = () => {
		if (maxEntries && controlledFields.length >= maxEntries) {
			showErrorMessage(
				'Failed to Add Partner Contact Details',
				'You have reached max no. of accepted Partner Contact Details'
			);
			return;
		}
		append(ContactDefaultValue);
	};

	const handleConfirmDeleteEntry = (index: number) => {
		if (index) {
			showConfirmationMessage(
				`Delete Partner Contact Details ${index + 1}`,
				<>
				<span>
					Are you sure you want to delete{' '}
					<span className={styles.confirmTextName}>
						Partner Contact Details {index + 1}?
					</span>						
				</span>
					
						
					
				</>,
				() => {
					remove(index);
					showSuccessMessage(
						<div className={styles.successModalText}>
							Partner Contact Details {index + 1} successfully deleted.
						</div>
					);
					autoScroll('head');
				}
			);
		}
	};

	const areaHandledCities = useMemo<SelectOption[]>(() => {
		const allCities = [];

		const hasLocal =
			watchAddresses.length &&
			watchAddresses.some(({ location_base }) => location_base === 'LOCAL');

		watchAddresses.length &&
			watchAddresses.forEach(({ cities = [] }) => {
				if (cities.length) {
					cities.forEach((c) => c && allCities.push(c));
				}
			});

		return hasLocal
			? _orderBy(
					_uniqBy<SelectOption>(allCities, 'value'),
					[(city) => city.label.toLowerCase()],
					['asc']
			  )
			: [];
	}, [watchAddresses]);

	useEffectOnce(() => {
		if (fields.length == 0) append({});
	});

	const autoScroll = (scrollAction: string) => {
		if (scrollAction === 'tail') {
			lastEntryRef.current &&
				lastEntryRef.current.scrollIntoView({ behavior: 'smooth' });
		}
		if (scrollAction === 'head') {
			firstEntryRef.current &&
				firstEntryRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<>
			{controlledFields.map((field, index) => {
				const n = `${name}[${index}]`;
				return (
					<>
						{index === controlledFields.length - 2 ? (
							<div ref={firstEntryRef} />
						) : (
							''
						)}
						<Section
							key={field.id}
							titleClassName={styles.sectionTitle}
							title={() => {
								return (
									<>
										{`Partner Contact Details${
											index > 0 ? ` ${index + 1}` : ''
										}`}
										<div style={{ float: 'right' }}>
											{index === 0 && !disabled ? (
												<Button
													fullWidth
													onClick={() => {
														handleAddEntry();
														autoScroll('tail');
													}}
													className={styles.addButton}
													disabled={disabled}
												>
													<AddIcon /> Add Contact Details
												</Button>
											) : (
												!disabled && (
													<Button
														onClick={() => handleConfirmDeleteEntry(index)}
														disabled={disabled}
													>
														<DeleteIcon />
													</Button>
												)
											)}
										</div>
									</>
								);
							}}
						>
							<SectionRow>
								<Grid column size={1} of={2}>
									<TextField
										label="Name"
										required
										name={`${n}.name`}
										control={control}
										defaultValue={field.name}
										disabled={disabled}
									/>
								</Grid>
								<Grid column size={1} of={2}>
									<AreaHandledSelect
										cities={areaHandledCities}
										optional
										name={`${n}.area_handled`}
										control={control}
										disabled={disabled || areaHandledCities.length === 0}
										overrideOption={false}
										setValue={setValue}
									/>
								</Grid>
							</SectionRow>
							<SectionRow>
								<Grid column size={1} of={2}>
									<TextField
										label="Position"
										required
										name={`${n}.position`}
										control={control}
										defaultValue={field.position}
										disabled={disabled}
									/>
								</Grid>
								<Grid column size={1} of={2}>
									<TextField
										label="Department"
										required
										name={`${n}.department`}
										control={control}
										defaultValue={field.department}
										disabled={disabled}
									/>
								</Grid>
							</SectionRow>
							<SectionRow>
								<Grid column size={1} of={2}>
									<EmailTagInput
										required
										control={control}
										name={`${n}.email`}
										defaultValue={field.email}
										disabled={disabled}
									/>
								</Grid>
							</SectionRow>
							<SectionRow>
								<Grid column size={1} of={2}>
									<TelephoneNumberTagInput
										control={control}
										name={`${n}.tel_no`}
										disabled={disabled}
									/>
								</Grid>
								<Grid column size={1} of={2}>
									<MobileNumberTagInput
										required
										secondarySubtext="* Mobile Number should start with +639"
										control={control}
										name={`${n}.mobile_no`}
										disabled={disabled}
										validateType="639"
									/>
								</Grid>
							</SectionRow>
							{index === controlledFields.length - 1 ? (
								<div ref={lastEntryRef} />
							) : (
								''
							)}
						</Section>
					</>
				);
			})}
			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalOpen && (
				<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
					<ErrorModalBody>
						<ErrorText>{errorHeader}</ErrorText>
						<ErrorSubText>{errorMessage}</ErrorSubText>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Close
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					heading={confirmationHeader}
					message={confirmationMessage}
					onClose={() => {
						onConfirmationClose.action();
					}}
					onCancelBtnClick={() => {
						onConfirmationClose.action();
					}}
					onConfirmBtnClick={() => {
						confirmBtnOnClick.action();
					}}
					cancelBtnLabel="Back"
				/>
			)}
		</>
	);
};

export default PartnerContactDetails;
