import SelectField from 'components/Inputs/SelectField/SelectField';
import { SelectFieldProps } from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import { useMemo } from 'react';
import { Country, useCountryQuery } from 'utils/queries/location';

export type CountrySelectProps = {
	countryFilter?: (country: Country) => boolean;
	selectFieldProps?: SelectFieldProps;
};

const CountrySelect: React.FC<CountrySelectProps> = ({
	countryFilter = () => true,
	selectFieldProps,
}) => {
	const { data } = useCountryQuery(
		'countries',
		{},
		{
			initialData: [{ id: '1', name: 'Philippines', country_code: 'PH' }],
		}
	);

	const options = useMemo(() => {
		if (selectFieldProps?.options) return selectFieldProps?.options;
		return (
			data?.filter(countryFilter).map(({ id: value, name: label }) => ({
				label,
				value,
			})) || []
		);
	}, [countryFilter, data, selectFieldProps?.options]);

	return (
		<>
			<SelectField label="Country" options={options} {...selectFieldProps} />
		</>
	);
};

export default CountrySelect;
