import cx from 'classnames';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import Grid from 'components/Grid/Grid';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import styles from 'containers/ChannelManagement/Approval/styles.module.css';
import { ChannelTableRowData, OptionValues } from './types';
import { DEFAULT_OPTIONS, STATUS_OPTIONS } from './constants';
import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';

type Props = {
    control;
    reset;
    name: string;
    filter;
    optionValues: OptionValues;
};

export const getUniqueValues = (array: ChannelTableRowData[], key: keyof ChannelTableRowData): SelectOption[] => {
    const arrayCreated = array.map(element => element[key])
        .filter((value, index, array) => array.indexOf(value) === index).filter(element => element)
    return arrayCreated.map(element => ({ label: element, value: element }))
}

const ApprovalTabFilter: React.FC<Props> = ({
    control,
    name,
    reset,
    filter,
    optionValues = {
        optionsChannelAccount: [],
        optionsPartner: [],
        optionsProductType: [],
        optionsSubmittedBy: [],
    }
}) => {
    const disableSubmission = () => {
        return false;
    };

    return (
        <>
            <Filter title="">
                <FilterOuter>
                    <Grid container align="end">
                        <Grid
                            container
                            column
                            size={11}
                            of={12}
                            align="end"
                            gutters="x-small"
                        >
                            <Grid column size={5} of={12}>
                                <InputWithIcon
                                    name={`${name}.search`}
                                    control={control}
                                    placeholder="Search"
                                    className={styles.searchBar}
                                    defaultValue=""
                                    icons={[
                                        {
                                            path: 'utility/search',
                                            isLeft: true,
                                            className: styles.inputIcon,
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </FilterOuter>
                <FilterInner>
                    <Grid container gutters={"xx-small"} verticalAlign="end">
                        <Grid column size={2} of={12}>
                            <DatePickerField
                                control={control}
                                name={`${name}.createdAt`}
                                label={"Date Created"}
                                placeholder="MM/DD/YYYY"
                            />
                        </Grid>
                        <Grid column size={2} of={12}>
                            <SelectField
                                label="Submitted By"
                                placeholder="Name"
                                control={control}
                                name={`${name}.submittedBy`}
                                options={optionValues.optionsSubmittedBy || DEFAULT_OPTIONS}
                            />
                        </Grid>

                        <Grid column size={2} of={12}>
                            <DatePickerField
                                control={control}
                                name={`${name}.updatedAt`}
                                label="Date Updated"
                                placeholder="MM/DD/YYYY"
                            />
                        </Grid>
                        <Grid column size={2} of={12}>
                            <SelectField
                                label="Channel Account Name"
                                placeholder="Channel A"
                                control={control}
                                name={`${name}.channelName`}
                                options={optionValues.optionsChannelAccount || DEFAULT_OPTIONS}
                            />
                        </Grid>
                        {name === 'serviceFeeTabFilter' ?
                            <>
                                <Grid column size={2} of={12}>
                                    <SelectField
                                        label="Product type"
                                        placeholder="Product type"
                                        control={control}
                                        name={`${name}.productType`}
                                        options={optionValues.optionsProductType || DEFAULT_OPTIONS}
                                    />
                                </Grid>
                                <Grid column size={2} of={12}>
                                    <SelectField
                                        label="Partner"
                                        placeholder="Partner"
                                        control={control}
                                        name={`${name}.partner`}
                                        options={optionValues.optionsPartner || DEFAULT_OPTIONS}
                                    />
                                </Grid>
                            </> : null
                        }
                        <Grid column size={1} of={12}>
                            <SelectField
                                label="Status"
                                placeholder="Status"
                                control={control}
                                name={`${name}.status`}
                                options={STATUS_OPTIONS}
                            />
                        </Grid>
                        <Grid
                            container
                            size={3}
                            of={12}
                            className={cx('slds-p-around_xxx-small')}
                        >
                            <Grid column size={2} of={4}>
                                <OutlineButton fullWidth onClick={reset}>
                                    Clear All Filters
                                </OutlineButton>
                            </Grid>
                            <Grid column size={2} of={4}>
                                <PrimaryButton
                                    disabled={disableSubmission()}
                                    onClick={filter}
                                    fullWidth
                                >
                                    Filter
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </FilterInner>
            </Filter>
        </>
    );
};

export default ApprovalTabFilter;
