import styles from './Filter.module.css';
import { DeepMap, FieldError } from 'react-hook-form';
import { get as _get } from 'lodash';

export type IErrors = DeepMap<any, FieldError>;

type Props = {
	name: string;
	errors?: IErrors;
};

const Error: React.FC<Props> = ({ name, errors }) => {
	if (!errors) return null;
	const error = _get(errors, `${name}.message`, null);

	if (!error) return null;

	return <div className={styles.error}>{error}</div>;
};

export default Error;
