import client from "helpers/ApiClient";
import { useQuery } from "react-query";

export const useGetChannelPrimaryInfo = (channelId: number) => {
    return useQuery(['getChannelPrimayInfo', channelId], async () => {
        const response = await client.get(`/v2/channels/${channelId}/primary-info`)
        return response.data
    })
}

export const useGetChannelContractDetails = (channelId: number) => {
    return useQuery(['getChannelContractDetails', channelId], async () => {
        const response = await client.get(`/v2/channels/${channelId}/contract-details`)
        return response.data
    })
}

export const useGetChannelReports = (channelId: number) => {
    return useQuery(['getChannelReports', channelId], async () => {
        const response = await client.get(`/v2/channels/${channelId}/reports`)
        return response.data
    })
}

export const useGetBranch = (branchId: number) => {
    return useQuery(['getBranch', branchId], async () => {
        const response = await client.get(`/v2/branches/${branchId}`)
        return response.data
    })
}

export const useGetTerminal = (terminalId: number) => {
    return useQuery(['getTerminal', terminalId], async () => {
        const response = await client.get(`/v2/terminals/${terminalId}`)
        return response.data
    })
}

export const UseSubmitTerminal = async (terminalInfo: any) => {
    const response = await client.post(`/v2/terminals/submit`, { terminalInfo: terminalInfo })
    return response
}

export const UseDraftTerminal = async (terminalInfo: any) => {
    const response = await client.post(`/v2/terminals/drafts`, { terminalInfo: terminalInfo })
    return response
}

export const UseUpdateTerminal = async (terminalId: number, terminalInfo: any) => {
    const response = await client.put(`/v2/terminals/${terminalId}`, { terminalInfo: terminalInfo })
    return response
}
