import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'utils/hooks';
import HTTP from 'helpers/ApiClient';
import { Modal } from '@salesforce/design-system-react/module/components';
import BillerStatusSelect from 'components/BillerForm/BillerStatusSelect/BillerStatusSelect';
import Grid from 'components/Grid/Grid';
import styles from 'components/BillerModal/BillerModal.module.css';
import { submit as submitAC } from 'redux/modules/form';
import { BarangayOptionValue } from 'components/BillerForm/Tabs/PrimaryInformation/sections/BillerAddress/BillerAddress';
import ConfirmModal from 'components/Modal/ConfirmModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { PrimaryInformationFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/types';
import ChannelForm from 'containers/ChannelManagement/Channel/ChannelForm/ChannelForm';
import {
	ChannelModalProps,
	Dispatch,
	FormState,
	PrimaryInfoSaveResponse,
} from 'containers/ChannelManagement/Channel/ChannelModal/types';
import _ from 'lodash';
import { ReducerStateType } from 'redux/modules/reducers';

const Footer: React.FC<{
	disableSave?: boolean;
	isPrimarySuccess?: boolean;
}> = ({ disableSave, isPrimarySuccess }) => {
	const formErrors = useSelector(
		(state: ReducerStateType) => state.channels.formErrors
	);
	const isFormInvalid = Object.values(formErrors).some((error) => error);
	const dispatch = useDispatch();
	const handleClick = () => dispatch(submitAC());

	return (
		<div className={styles.footer}>
			<PrimaryButton
				onClick={handleClick}
				disabled={disableSave || !isPrimarySuccess || isFormInvalid}
				className={styles.btn}
			>
				Save
			</PrimaryButton>
			<PrimaryButton onClick={() => {}} className={styles.btn}>
				Next
			</PrimaryButton>
		</div>
	);
};

const initialState = {
	isValid: false,
	isDirty: false,
};

export const ChannelModalContext = React.createContext<{
	state: FormState;
	dispatch: Dispatch;
}>({ state: initialState, dispatch: () => {} });

async function savePrimaryInformation(
	values: PrimaryInformationFormData
): Promise<PrimaryInfoSaveResponse> {
	const { username: userName } = JSON.parse(
		localStorage.getItem('userInfo') || ''
	);
	const {
		basicInformation: {
			logo,
			businessType,
			channelTransactionType,
			...restBasicInfo
		} = {},
		channelAddresses,
		accountOfficer,
		...rest
	} = values;

	const { objectKey = '', url = '', filename = '' } = logo || {};

	const body = {
		logo: { objectKey, url, filename },
		current_user: {
			userName,
		},
		channelPrimaryInfo: {
			...restBasicInfo,
			inCfs: restBasicInfo.inCfs === '1' ? true : false,
			transactionType: channelTransactionType,
			businessType: _.isEmpty(businessType) ? '' : businessType,
		},
		channelAddresses: channelAddresses?.map((address?: any) => {
			if (address.locationBase === 'LOCAL') {
				const {
					barangayObj: { value: { zipCode: { name: zipCode } = {} } = {} } = {},
				} = address as unknown as {
					barangayObj: { value: BarangayOptionValue };
				};
				return {
					...address,
					country: 'Philippines',
					state: address.province,
					municipality: address.city,
					buildingName: address.buildingNo,
					zipCode: zipCode,
				};
			}

			return {
				country: address.country,
				locationBase: address.locationBase,
				zipCode: '',
			};
		}),
		channelAccountOfficer: accountOfficer,
		...rest,
	};
	const response = await HTTP.post('/v1/channels', body);
	if (response.status == 400) {
		const { errors = [] } = response.data;
		return {
			status: 'FAILED',
			errors,
		};
	}

	return {
		status: 'OK',
	};
}

const ChannelModal: React.FC<ChannelModalProps> = ({
	autoSaveValue,
	hasAutoSave,
	open,
}) => {
	const {
		value: isLoading,
		valueOn: showLoader,
		valueOff: hideLoader,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const {
		value: isErrorRetryShowing,
		valueOn: showErrorRetry,
		valueOff: hideErrorRetry,
	} = useToggle();

	// const [tabStatus, setTabStatus] = useState<TabStatus | undefined>({
	// 	primaryInformation: { disabled: false, finished: false },
	// 	contractDetails: { disabled: true, finished: false },
	// 	reports: { disabled: true, finished: false },
	// });

	const dispatch = useDispatch();
	const handleClick = () => dispatch(submitAC());

	useEffect(() => {
		// !open &&
		// 	setTabStatus((prev: any) => ({
		// 		...prev,
		// 		primaryInformation: {
		// 			...prev?.primaryInformation,
		// 			finished: false,
		// 		},
		// 	}));
	}, [open]);

	const handleSubmit = async (
		values: PrimaryInformationFormData
	): Promise<PrimaryInfoSaveResponse> => {
		try {
			showLoader();
			const response = await savePrimaryInformation(values);

			if (response.status === 'OK') {
				// setTabStatus((prev: any) => ({
				// 	...prev,
				// 	primaryInformation: {
				// 		...prev?.primaryInformation,
				// 		finished: true,
				// 	},
				// }));
				showSuccessModal();
			}
			return response;
		} catch (e) {
			showErrorRetry();
			return {
				status: 'FAILED',
				errors: [],
			};
		} finally {
			hideLoader();
		}
	};

	const handleConfirmClose = () => {
		hideConfirmModal();
		onclose;
	};

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={
					() => {}
					// () =>
					// tabStatus?.primaryInformation?.finished
					// 	? onclose
					// 	: showConfirmModal()
				}
				headerClassName={styles.headerContainer}
				size="medium"
				containerClassName={styles.modalContainer}
				contentClassName={styles.modal}
				heading={
					<div className={styles.header}>
						<div className={styles.title}>Add New Channel</div>
						<Grid container gutters="xx-small">
							<Grid column>
								<BillerStatusSelect
									labelPrefix="In STG"
									value="Engaged"
									disabled
								/>
							</Grid>
							<Grid column>
								<BillerStatusSelect
									labelPrefix="In Prod"
									value="Engaged"
									disabled
								/>
							</Grid>
						</Grid>
					</div>
				}
				footer={
					<Footer
						disableSave={isLoading}
						isPrimarySuccess={
							true
							// tabStatus?.primaryInformation?.finished
						}
					/>
				}
			>
				<div className={styles.body}>
					<ChannelForm
						autoSaveValue={autoSaveValue}
						contentClassName={styles.bodyContent}
						onSubmit={handleSubmit}
						// tabStatus={tabStatus}
					/>
				</div>
			</Modal>
			<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>
						<div>
							Updates on Primary Information <br></br> are successfully saved!
						</div>
					</SuccessText>
					<div className={styles.subTextNext}>
						You may click the <b>Next</b> button.
					</div>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.doneButton}
						onClick={hideSuccessModal}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			<ConfirmModal
				open={isConfirmModalShowing}
				disableClose={false}
				onClose={hideConfirmModal}
				headerText="Close Add New Channel"
				bodyText={[
					() => (
						<>
							<div>
								Are you sure you want to close <b>Add New Channel</b>?
							</div>
							<div>
								Your progress will not be saved, if you wish to continue.
							</div>
						</>
					),
				]}
				confirmButton={{
					name: 'Confirm',
					event: handleConfirmClose,
				}}
				cancelButton={{
					name: 'Back',
					event: hideConfirmModal,
				}}
			/>
			<ErrorModal open={isErrorRetryShowing} onClose={hideErrorRetry}>
				<ErrorModalBody className={styles.errorBody}>
					<div className={styles.errorHeader}>
						<b>Timeout Error!</b>
					</div>
					<div className={styles.errorText}>
						<div>A problem occured while saving the data.</div>
						<div>Please try again.</div>
					</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.contactDetailsErrorCloseBtn}
						onClick={() => {
							hideErrorRetry();
							handleClick();
						}}
					>
						Retry
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default ChannelModal;
