import React, { useState, useEffect } from 'react';
import moment from 'moment';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import SummaryCards from 'components/SummaryCards';

import client from 'helpers/ApiClient';
import { useErrorModal, useLoader, useToggle } from 'utils/hooks';
import { formatParams } from 'utils/common';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import { PartnerTableColumn, getSummaryData } from './PartnerTableColumns';
import PartnerTable from './PartnerTable';
import PartnerHeader from './PartnerHeader';
import ProductList from './ProductList';

import { Summary, PartnerFilterOptions, ProductListResp } from './types';
import styles from './ProductMgt.module.css';

const ProductMgt: React.FC = () => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [partnerList, setPartnerList] = useState<ProductListResp | undefined>();
	const [productList, setProductList] = useState<any>();
	const [clickedCardTitle, setClickedCardTitle] = useState<string>('');
	const [filter, setFilter] = useState<PartnerFilterOptions>({});
	const [totalSummary, setTotalSummary] = useState<Summary>({
		totalPartners: 0,
		totalPartnerBrands: 0,
		totalModules: 0,
		totalActivatedPartners: 0,
		totalPartnersNoTxn: 0,
	});
	const [refetchProductList, setRefetchProductList] = useState<boolean>(false);

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		PARTNER_COLUMNS,
		selectedPartner,
		isPartnerProfileShown,
		hidePartnerProfile,
	} = PartnerTableColumn();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		value: isPartnerListShown,
		valueOn: showPartnerList,
		valueOff: hidePartnerList,
	} = useToggle();

	const fetchProductList = async () => {
		if (!hasUserPermission('list.product')) {
			return;
		}

		hideErrorModal();
		showLoadingMessage();

		try {
			const result: ProductListResp = await client.get(
				`v2/bfa-admin-portal/products/list`
			);

			if (result?.error) {
				throw new Error(result?.error?.message);
			}

			setProductList(result?.data?.data);
			setTotalSummary(result?.data?.summary);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchProductList()
			);
		} finally {
			hideLoading();
			setRefetchProductList(false);
		}
	};

	const searchedProduct = productList?.filter(
		({ name }) => name === clickedCardTitle
	)[0];

	const fetchPartnerList = async ({ productId, params }) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const apiUrl = 'v2/bfa-admin-portal/products/partner/list';

			await client
				.get(apiUrl, {
					params: {
						id: productId,
						...params,
					},
				})
				.then((response) => {
					const formattedResult = response?.data?.data.map((item) => {
						return {
							...item,
							serviceFee: item.serviceFee,
							serviceFeePassOn:
								item?.sfSchemeType?.toUpperCase() === 'TIER' ||
								item?.sfSchemeType?.toUpperCase() === 'SPECIAL'
									? 'Refer to details'
									: item.serviceFee[0]?.passOnFee || '',
							bfaStatus:
								item?.bfaStatus?.toUpperCase() === 'ACTIVE'
									? 'ENABLED'
									: 'DISABLED',
						};
					});

					const formattedData: any = {
						data: formattedResult,
						meta: response?.data?.meta,
					};

					setPartnerList(formattedData);
					setTotalSummary(response?.data?.summary);
				});
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => {
					fetchPartnerList({ productId, params });
				}
			);
		} finally {
			hideLoading();
		}
	};

	const handleFilterSubmit = async (params: PartnerFilterOptions) => {
		const { limit, sort, sortBy } = filter;
		const newFilter = formatParams({ limit, sort, sortBy, ...params });
		setFilter(newFilter);
		fetchPartnerList({ productId: searchedProduct?.id, params: newFilter });
	};

	const handleTableFetch = (params: PartnerFilterOptions) => {
		const newFilter = formatParams({ ...filter, ...params });
		setFilter(newFilter);
		fetchPartnerList({ productId: searchedProduct?.id, params: newFilter });
	};

	const tableProps = {
		columns: PARTNER_COLUMNS,
		data: partnerList,
		selectedPartner: {
			productId: searchedProduct?.id,
			productName: clickedCardTitle,
			...selectedPartner,
		},
		isPartnerProfileShown: isPartnerProfileShown,
		hidePartnerProfile: hidePartnerProfile,
		handleTableFetch,
	};

	useEffect(() => {
		fetchProductList();
	}, []);

	useEffect(() => {
		if (refetchProductList) {
			fetchProductList();
		}
	}, [refetchProductList]);

	useEffect(() => {
		if (!isPartnerListShown) {
			setTotalSummary({
				totalPartners: 0,
				totalPartnerBrands: 0,
				totalModules: 0,
				totalActivatedPartners: 0,
				totalPartnersNoTxn: 0,
			});
			setProductList([]);
			setFilter({});
			setPartnerList(undefined);
			fetchProductList();
		}
	}, [isPartnerListShown]);

	return (
		<div>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<SummaryCards
				data={getSummaryData(totalSummary)}
				footer={`* ${isPartnerListShown ? 'YTD' : ''} as of ${String(
					moment().format('MMMM D, YYYY')
				)}`}
				className={styles.summaryCards}
			/>
			{!isPartnerListShown ? (
				<ProductList
					isProductLoading={isLoading}
					showPartnerList={showPartnerList}
					productList={productList}
					fetchPartnerList={fetchPartnerList}
					setClickCardTitle={setClickedCardTitle}
					setRefetchProductList={setRefetchProductList}
				/>
			) : (
				<>
					<PartnerHeader
						onSubmit={handleFilterSubmit}
						clickedCardTitle={clickedCardTitle}
						hidePartnerList={hidePartnerList}
						productId={searchedProduct?.id}
					/>
					<PartnerTable {...tableProps} />
				</>
			)}
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</div>
	);
};

export default ProductMgt;
