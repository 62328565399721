import { Route } from 'react-router-dom';
import Reports from './Reports/Reports';
import ReportsMaintenance from './ReportsMaintenance/ReportsMaintenance';
const BusinessReports: React.FC = () => {
    return (
        <>
            <Route exact path="/business-reports/reports" component={Reports} />
            <Route path="/business-reports/reports-maintenance" component={ReportsMaintenance} />
        </>
    )
};
export default BusinessReports;