import React, { useState, useEffect, useMemo } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { connect, useSelector } from 'react-redux';
import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';
import _, { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import client from "helpers/ApiClient";
import { IconSettings, Button } from '@salesforce/design-system-react';
import PrimaryCard from 'components/Cards/PrimaryCard/PrimaryCard';
import RenderByType from 'components/common/RenderByType';

import OtpModal, {
	OtpModalBody,
	OtpText,
	OtpSubText,
	OtpModalActions,
	ResendOtpButton,
	ResendOtpViaEmailButton
} from 'components/Modal/OtpModal';

import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';

import SuccessModal, {
	SuccessModalBody,
	SuccessText,
	SuccessModalActions,
} from 'components/Modal/SuccessModal';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OtpInput from 'components/Modal/OtpInput';
import Grid from 'components/Grid/Grid';
import BasicInformation, {
	BasicInformationFields,
} from 'components/BillerForm/Tabs/PrimaryInformation/sections/BasicInformation/BasicInformation';

import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Credentials/ChannelCredentials.module.css';
import ChannelCredentialsTable from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Credentials/ChannelCredentialsTable';
import HTTP from 'helpers/ApiClient';
import cx from 'classnames';

type GetAuditTrailArgs = {
	page?: number;
	limit?: number;
};

interface ICell {
	value: any;
	row: any;
	cell?: any;
}

export type ChannelCredentialsFormData = {

	basicInformation?: BasicInformationFields;
};
type Props = {
	initialValues: ChannelCredentialsFormData;
	disabled?: boolean;
	onSubmit?: (values: any) => void;
};

type ApprovalFilterFormData = {
	filter: {
		createdAt?: string;
		updatedAt?: string;
		status?: string;
		partnerName?: string;
	};
};
// const ChannelIntegration = ({
// 	selectedBiller: selectedBranchPerCategory,
// 	product_type_id,
// }) => {

const ChannelCredentials: React.FC<Props> =  ({ 
	initialValues,
	disabled,
	onSubmit,
}) => {
	const [allChannelData, setChannelData] = useState<{ data: any[] }>({ data: [] });

    const {
		handleSubmit,
	} = useForm<ApprovalFilterFormData>({
		mode: 'all',
	});

	const handleFilterSubmit = (values, isSearch = false) => {
		const createdAt = values['createdAt'],
			updatedAt = values['updatedAt'];
	};

	const selectedChannel = useSelector<ReducerStateType, string>(
		(state) => state.channels.selectedChannel
	);

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);
	const hasUserPermission = useHasUserPermission('channel');

	const hasAuditTrailPermission = useMemo(
		() => hasUserPermission(productCategory, 'list.audit-trail'),
		[hasUserPermission, productCategory]
	);
	console.log("SELECTED CHANNEL: " + selectedChannel);
	// const selectedBiller = useMemo(
	// 	() => selectedBranchPerCategory[productCategory],
	// 	[productCategory, selectedBranchPerCategory]
	// );
	const selectedBiller = initialValues;

	const product_id = selectedBiller;
	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [otpModalOnClick, setOtpModalOnClick] = useState({ action: () => {
		showOtpModal();} });

	const [otp, setOTP] = useState("");

	const handleOTPChange = (val) => {
		// Handle the OTP change
		setOTP(val);
		console.log("OTP Input: "+ otp);
		console.log("VAL Input: "+ val);

	};
	
	const renderInput = (inputProps, index) => {
		return <input {...inputProps} 
			type="text"
			value={inputProps.value.replace(/./g, '*')}
		/>;
	};

	const {
		value: isOtpModalShown,
		valueOn: showOtpModal,
		valueOff: hideOtpModal,
	} = useToggle();
	
	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isChannelModalVisible,
		valueOn: showChannelModal,
		valueOff: hideChannelModal,
	} = useToggle();

	const [data, setData] = useState([]);
	const [auditDetailsList, setAuditDetailsList] = useState([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [count, setCount] = useState(0);

	const errorMessageDuringLoading = 'A problem occurred with loading the data';

	useEffect(() => {
		const fetchClientData = async () => {
			
			if (selectedChannel) {
				const response = await client.get(`/v2/channels/${selectedChannel}/primary-info`)
				setChannelData(response.data);
				console.log("setChannelData: " + setChannelData(response.data));
			}
		}
		fetchClientData()
	}, [selectedChannel]);

	const getAuditDetails = async (audit_id, product_type_id, product_id) => {
		showLoadingMessage(
			"Please wait while the selected audit log's entries are being loaded"
		);
		const result = await HTTP.get(
			`/v2/products/${product_type_id}/${product_id}/audit-trails/${audit_id}`
		)
			.then((resp) => {
				const data = resp.data.data;
				if (!data) {
					throw new Error('Invalid response data');
				}
				const formattedData = isEmpty(data.audit_detail)
					? []
					: typeof data.audit_detail === 'string'
					? JSON.parse(data.audit_detail)
					: data;
				setAuditDetailsList(formattedData);
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleOnClickViewLogs = async (row) => {
		const audit_id = row.original.id;
		await getAuditDetails(audit_id, initialValues, product_id);
		showChannelModal();
	};

	const handlePageChange = async (_page, _pageSize) => {
		const params = { page, pageSize };
		const { page: _, pageSize: __ } = params;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			await false;
		}
	};

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
		showErrorModal();
	};

	const CREDENTIAL_COLUMNS = (handleViewLogs) => [
		{
			Header: 'Date & Time Generated',
			id: 'created_at',
			sortable: false,
			width: '40%',
			accessor: ({ created_at }) => formatDate(created_at),
		},
		{
			Header: 'Recipient',
			id: 'recipient',
			sortable: false,
			width: '30%',
		},
		{
			Header: (): JSX.Element => <div className={styles.actionHeader}>Actions</div>,
			id: 'actions',
			width: '28%',
			//accessor: (values: any) => values,
			Cell: ({ row }: ICell): JSX.Element => {
				const isLatestRow = row.index == 0;
				return (
					<div id="td-actions" className={styles.actions}>
						<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
								<button
									className={`${styles.sendButton} ${!isLatestRow ? styles.disabled : ''}`}
									disabled={!isLatestRow}
									onClick={() => {
									// Handle the send button click event
									}}
								> Send </button>
						</div>
					</div>
					
				);
			},
		},
	];

// 	return selectedBiller ? (
// 		<>
// 			{isLoading && (
// 				<FullPageLoader open={isLoading} message={loadingMessage} />
// 			)}
// 			{isSuccessModalShown && (
// 				<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
// 					<SuccessModalBody>
// 						<SuccessText>
// 							<div className={styles.successHeader}>Success!</div>
// 							<div className={styles.successBody}>{successMessage}</div>
// 						</SuccessText>
// 					</SuccessModalBody>
// 					<SuccessModalActions>
// 						<PrimaryButton
// 							className={styles.successModalBtn}
// 							onClick={() => {
// 								hideSuccessModal();
// 								doneBtnOnClick.action();
// 							}}
// 						>
// 							Done
// 						</PrimaryButton>
// 					</SuccessModalActions>
// 				</SuccessModal>
// 			)}
// 			{isErrorModalShown && (
// 				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
// 					<ErrorModalBody>
// 						<div className={styles.errorHeader}>Timeout Error!</div>
// 						<div className={styles.errorBody}>{errorMessage}</div>
// 						<div className={styles.errorFooter}>Please try again</div>
// 					</ErrorModalBody>
// 					<ErrorModalActions>
// 						<PrimaryButton
// 							fullWidth
// 							onClick={() => {
// 								hideErrorModal();
// 								retryBtnOnClick.action();
// 							}}
// 							className={styles.errorModalBtn}
// 						>
// 							Retry
// 						</PrimaryButton>
// 					</ErrorModalActions>
// 				</ErrorModal>
// 			)}
// 			{selectedBiller && !isLoading && (
// 				<>
// 					<div className={styles.title}>{selectedBiller.name}</div>
// 					<Grid container gutters="xx-small">
// 						<Grid column>
// 							<div className={styles.statusContainer}>
// 								<div className={styles.statusLabel}>Partner Status:</div>
// 								<div className={styles.statusField}>
// 									<ChannelStatusSelect value={selectedBiller.status} disabled />
// 								</div>
// 							</div>
// 						</Grid>
// 					</Grid>
// 					<div className={styles.partnerAuditTrailTableContainer}>
// 						<ChannelIntegrationTable
// 							columns={CREDENTIAL_COLUMNS(handleOnClickViewLogs)}
// 							data={data}
// 							pageSize={pageSize}
// 							page={page}
// 							count={count}
// 							onPageChange={handlePageChange}
// 						/>
// 					</div>
// 				</>
// 			)}
// 			<PartnerAuditModal
// 				isOpen={isPartnerAuditModalVisible}
// 				data={auditDetailsList}
// 				onClose={() => {
// 					hidePartnerAuditModal();
// 				}}
// 			/>
// 		</>
// 	) : (
// 		<></>
// 	);
// };

// export default connect((state: any) => ({
// 	selectedBiller: state.products.selectedBiller,
// 	product_type_id: state.sidebar.itemId,
// }))(ChannelIntegration);

const mockData = [
	{
	  id: 1,
	  created_at: '2023-07-04T08:15:30Z',
	  recipient: 'jane@example.com',
	  actions: '2',
	},
	{
	  id: 2,
	  created_at: '2023-07-03T12:34:56Z',
	  recipient: 'john@example.com',
	  actions: '1',
	},
	{
	  id: 3,
	  created_at: '2023-07-02T12:34:56Z',
	  recipient: 'john@example.com',
	  actions: '4',
	},
	{
	  id: 4,
	  created_at: '2023-07-01T12:34:56Z',
	  recipient: 'john@example.com',
	  actions: '3',
	},
	// Add more sample data objects as needed
  ];
  
return (
    <>
      	{isLoading && <FullPageLoader open={isLoading} message={loadingMessage} />}
		{isOtpModalShown && (
			<OtpModal open={isOtpModalShown} onClose={hideOtpModal}>
				<OtpModalBody>
					<OtpText>
						<div className={styles.otpHeader}>One Time PIN</div>
					</OtpText>
					<OtpSubText>
						<div className={styles.otpModalSubtext}>Enter 6-digit code sent to the number ending .....</div>
					</OtpSubText>
					<OtpSubText>
						<div>
						<OtpInput
							value={otp}
							numInputs={6}
							onChange={handleOTPChange}
							renderInput={renderInput}
							shouldAutoFocus={true}
							placeholder=""
							renderSeparator=""
							containerStyle={{ display: 'flex', justifyContent: 'center' }}
							inputStyle={{
							width: '2em',
							height: '2em',
							margin: '0 0.5em',
							fontSize: '1.5em',
							textAlign: 'center',
							border: 'none',
							borderBottom: '1px solid #000',
							}}
							inputType="text"
						/>
						</div>
					</OtpSubText>
				</OtpModalBody>
				<OtpModalActions>
					<div>
						<PrimaryButton
						className={styles.otpModalBtn}
						onClick={() => {
							hideOtpModal();
							console.log("Done OTP: " + otp);
							doneBtnOnClick.action();
						}}
						>
						Submit
						</PrimaryButton>
						<div className={styles.resendOtpButtonsContainer}>
						<div className={styles.resendOtpButton}>
							<ResendOtpButton />
						</div>
						<div className={styles.resendOtpViaEmailButton}>
							<ResendOtpViaEmailButton />
						</div>
						</div>
					</div>
				</OtpModalActions>
			</OtpModal>			
		)}

			{/* <OtpModal open={isOtpModalShown} onClose={hideOtpModal}>
				<OtpModalBody>
					<OtpText>
						<div className={styles.otpHeader}>One Time PIN</div>
					</OtpText>
					<OtpSubText>
						<div className={styles.otpModalSubtext}>Enter 6-digit code sent to the number ending .....</div>
					</OtpSubText>
					<OtpSubText>
						<div>
							<OtpInput
								value={otp}
								numInputs={6}
								onChange={handleOTPChange}
								renderInput={renderInput}
								shouldAutoFocus={true}
								placeholder=""
								renderSeparator=""
								containerStyle={{ display: 'flex', justifyContent: 'center' }}
								inputStyle={{ width: '2em',
											height: '2em',
											margin: '0 0.5em',
											fontSize: '1.5em',
											textAlign: 'center',
											border: 'none',
											borderBottom: '1px solid #000'
										}}
								inputType="text"
							/>
						</div>
					</OtpSubText>
				</OtpModalBody>
				<OtpModalActions>
					<PrimaryButton
						className={styles.otpModalBtn}
						style={{}}
						onClick={() => {
							hideOtpModal();
							console.log("Done OTP: "+otp);
							doneBtnOnClick.action();
						}}
					>
						Submit
					</PrimaryButton>
				</OtpModalActions>
			</OtpModal> */}

      	{isSuccessModalShown && (
			<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>
						<div className={styles.successHeader}>Success!</div>
						<div className={styles.successBody}>{successMessage}</div>
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.successModalBtn}
						onClick={() => {
							hideSuccessModal();
							doneBtnOnClick.action();
						}}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
		)}
		{isErrorModalShown && (
			<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
				<ErrorModalBody>
					<div className={styles.errorHeader}>Timeout Error!</div>
					<div className={styles.errorBody}>{errorMessage}</div>
					<div className={styles.errorFooter}>Please try again</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						fullWidth
						onClick={() => {
							hideErrorModal();
							retryBtnOnClick.action();
						}}
						className={styles.errorModalBtn}
					>
						Retry
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
		)}
      {!isLoading && (
        <>
			{
				allChannelData.data.length > 0 && allChannelData ? (
					allChannelData.data.map((channel: any, index: number) => (
						<PrimaryCard
							key={channel.id}
							name={channel.name}
							logo={channel.logo}
							status={'Active'}
							isSelected={channel.isSelected}
							onClick={() => {
								setChannelData(channel.id)
								setChannelData(channel.name)
							}}
						/>
					))
				) : allChannelData.data.length <= 0 ?
				console.log('No channel available: ' + allChannelData.data.length) : null
			}
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Grid column size={3} of={8} >
							<PrimaryButton
							// disabled={shouldDisableSubmission(allValues)}
							onClick={() => {
								otpModalOnClick.action();
							}}
							fullWidth
							>
							Regenerate Client Credentials
							</PrimaryButton>
				</Grid>
			</div>
          <div className={styles.channelCredentialsTableContainer}>
            <ChannelCredentialsTable
              	columns={CREDENTIAL_COLUMNS(handleOnClickViewLogs)}
				// data={data}
				// count={count}
				data={mockData}
				count={mockData.length}
				pageSize={pageSize}
				page={page}
				onPageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ChannelCredentials;