import React from 'react';
import { AccordionItemProps } from '../../types';
import ToggleButton from 'containers/ChannelManagement/Channel/ChannelProducts/ProductTypeContainer/ProductScrollableList/ToggleButton';
import styles from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Accordion/Accordion.module.css';
import cx from 'classnames';
import IconResolver from 'helpers/IconResolver';
const AccordionItem: React.FC<AccordionItemProps> = ({
	index,
	subIndex,
	isSubExpanded,
	listItemClass,
	accordionBtnClass,
	expandedClass,
	subSectionItemClass,
	handleSubAccordionClick,
	subSection,
	toggleButtonClass,
	toggleBtnDivClass,
	hasEditPermission,
	section,
	handleToggleOnClick,
	handleToggleOnChange,
	accordionContent2Class,
	lineDeviderClass,
}) => {
	const onClickSubSection = (index: number, subIndex: number) => {
		handleSubAccordionClick(index, subIndex);
	};
	return (
		<li key={subIndex} className={listItemClass}>
			<button
				className={cx(accordionBtnClass, {
					[expandedClass]: isSubExpanded,
				})}
				onClick={() => onClickSubSection(index, subIndex)}
				aria-controls={`accordion-item-${index}-${subIndex}`}
				aria-expanded={isSubExpanded}
				role="switch"
			>
				<div className={subSectionItemClass}>
					<span className="slds-current-color slds-col">
						<IconResolver
							className={cx('slds-icon_xx-small', 'slds-p-left_xx-small')}
							path={`utility/${isSubExpanded ? 'chevronright' : 'chevrondown'}`}
						/>
					</span>
					<strong>{subSection.name}</strong>
				</div>
			</button>
			<div className={cx('slds-clearfix', toggleButtonClass)}>
				<div className={cx('slds-float_right', toggleBtnDivClass)}>
					<ToggleButton
						isDisabled={
							!hasEditPermission &&
							(section.isEnabled !== 'Enabled' ? true : false)
						}
						isChecked={subSection.enabled}
						id={subIndex}
						onClickBtn={() => handleToggleOnClick(index, subIndex)}
						onChangeBtn={handleToggleOnChange}
					/>
				</div>
			</div>
			{isSubExpanded && (
				<div
					id={`accordion-item-${index}-${subIndex}`}
					className={accordionContent2Class}
				>
					{subSection.terminals.length > 0 ? (
						subSection.terminals.map((subsubsection, subSubIndex) => (
							<div key={subSubIndex}>
								{subsubsection.name}
								<div className={cx('slds-clearfix', toggleButtonClass)}>
									<div className="slds-float_right">
										<ToggleButton
											isDisabled={
												!hasEditPermission &&
												(!subSection.enabled ||
													(section.isEnabled !== 'Enabled' ? true : false))
											}
											isChecked={subsubsection.enabled}
											id={subSubIndex}
											onClickBtn={() =>
												handleToggleOnClick(index, subIndex, subSubIndex)
											}
											onChangeBtn={handleToggleOnChange}
										/>
									</div>
								</div>
							</div>
						))
					) : (
						<div key={subIndex} className={styles.noItem}>
							No Terminals available
						</div>
					)}
				</div>
			)}
			<hr className={lineDeviderClass} />
		</li>
	);
};

export default AccordionItem;
