import Grid from 'components/Grid/Grid';
import PasswordField from 'components/Inputs/PasswordField/PasswordField';
import TextField from 'components/Inputs/TextField/TextField';
import Section, { SectionRow } from 'components/Section/Section';
import { PartnerReportsFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';
import { useFormContext } from 'react-hook-form';
import { FileTransferProtocolProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SFTP/FileTransferProtocol/types';

const FileTransferProtocol: React.FC<FileTransferProtocolProps> = ({
	name,
	disabled,
	title,
}) => {
	const { control } = useFormContext<PartnerReportsFormData>();
	const namePrefix = `${name}.baseInfo` as const;
	return (
		<Section title={title}>
			<SectionRow verticalAlign="start">
				<Grid column size={1} of={3}>
					<TextField
						label="Host"
						required
						disabled={disabled}
						control={control}
						name={`${namePrefix}.host`}
					/>
				</Grid>
				<Grid container column verticalAlign="start" size={1} of={3}>
					<TextField
						label="Port"
						required
						disabled={disabled}
						control={control}
						name={`${namePrefix}.port`}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<TextField
						label="Username"
						required
						disabled={disabled}
						control={control}
						name={`${namePrefix}.username`}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<PasswordField
						label="Password"
						required
						disabled={disabled}
						control={control}
						name={`${namePrefix}.password`}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Path"
						required
						disabled={disabled}
						control={control}
						name={`${namePrefix}.path`}
					/>
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default FileTransferProtocol;
