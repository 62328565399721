import { FC, useState, useEffect } from 'react';
import container from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeContainer.module.css';
import serviceFee from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeTable/ServiceFeeTable.module.css';
import { ServiceFeeContainerProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import ServiceFeeTable from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeTable/ServiceFeeTable';
import {
	FEE_COLUMNS,
	RATE_COLUMNS,
	TIER_COLUMNS,
	TIER_RATE_COLUMNS,
	CHANNEL_TYPE_COLUMNS,
	TIER_COLUMNS_FIELD,
	CHANNEL_TYPE_COLUMS_FIELD,
	CHANNEL_TYPE_RATE_COLUMNS,
	REGULAR_PERCENTAGE_COLUMNS,
} from 'containers/ChannelManagement/Channel/ChannelProducts/const';
import ChannelTable from 'containers/ChannelManagement/Channel/ChannelTable/ChannelTable';

const ServiceFeeContainer: FC<ServiceFeeContainerProps> = ({
	itemKey,
	data,
	selectedChannel,
	permission,
	serviceFees,
	customStyles
}) => {
	const [nonShareableColumn, setNonShareableColumn] = useState({
		isPercentage: false,
	});

	const styles = customStyles ? customStyles : {
		...container,
		...serviceFee,
	};

	const [serviceFeeData, setServiceFeeData] = useState([]);

	useEffect(() => {
		if (serviceFees) {
			setServiceFeeData(serviceFees);
		}
	}, [serviceFees]);

	useEffect(() => {
		const config = (
			(serviceFeeData &&
				serviceFeeData.length > 0 &&
				serviceFeeData.find(
					(v: { config: any; shares: []; values: [] }) =>
						v.config.product_id === data.productId
				)) ||
			{}
		).config;
		setNonShareableColumn({
			isPercentage: config && config.non_shareable_rate_type === 'PERCENTAGE',
		});
	}, [serviceFeeData, data.productId]);

	const calculateTotalAmountForSfShare = (sfShares) => {
		const configuration = sfShares && sfShares.config;
		const schemeType = configuration?.scheme_type || '';
		const rateType = configuration?.tier_rate_type;
		const baseType = configuration?.tier_base_type;
		const nonShareableRateType = configuration?.non_shareable_rate_type;
		const specialRateType = configuration?.special_rate_type;
		return (
			sfShares &&
			sfShares.values &&
			sfShares.values.map((item) => {
				let total_amount_for_sf_share = '0';
				let total_amount_for_sf_share_rate = '0';

				if (schemeType === 'TIER') {
					if (rateType === 'FIXED_RATE') {
						total_amount_for_sf_share = Math.abs(
							item.total_fixed_rate - item.non_share_fixed_rate
						).toFixed(2);
					} else if (rateType === 'PERCENTAGE') {
						total_amount_for_sf_share_rate = Math.abs(
							item.total_percentage_rate - item.non_share_percentage
						).toFixed(0);
					}
				} else if (schemeType === 'SPECIAL') {
					if (specialRateType === 'FIXED_RATE') {
						total_amount_for_sf_share = Math.abs(
							item.total_fixed_rate - item.non_share_fixed_rate
						).toFixed(2);
					} else if (specialRateType === 'PERCENTAGE') {
						total_amount_for_sf_share_rate = Math.abs(
							item.total_percentage_rate - item.non_share_percentage
						).toFixed(0);
					}
				} else if (schemeType === 'REGULAR') {
					total_amount_for_sf_share = Math.abs(
						item.total_fixed_rate - item.non_share_fixed_rate
					).toFixed(2);
				} else if (schemeType === 'PERCENTAGE') {
					total_amount_for_sf_share_rate = Math.abs(
						item.total_percentage_rate - item.non_share_percentage
					).toFixed(0);
				}

				if (nonShareableColumn && nonShareableColumn.isPercentage) {
					return { ...item, total_amount_for_sf_share_rate };
				} else {
					return { ...item, total_amount_for_sf_share };
				}
			})
		);
	};

	const [activeColumn, setActiveColumn] = useState<Array<any>>([]);
	const [activeFieldColumn, setActiveFieldColumn] = useState<Array<any>>([]);
	const [
		activeFieldColumnRegularPercentage,
		setActiveFieldColumnRegularPercentage,
	] = useState<Array<any>>([]);
	useEffect(() => {
		const config =
			serviceFeeData &&
			(serviceFeeData.find((v) => v.config.product_id === data.productId) || {})
				.config;
		if (config && config.scheme_type) {
			switch (config.scheme_type) {
				case 'TIER':
					if (nonShareableColumn && nonShareableColumn.isPercentage) {
						setActiveColumn(TIER_RATE_COLUMNS);
					} else {
						setActiveColumn(TIER_COLUMNS);
					}
					setActiveFieldColumn(TIER_COLUMNS_FIELD);
					break;
				case 'SPECIAL':
					if (nonShareableColumn && nonShareableColumn.isPercentage) {
						setActiveColumn(CHANNEL_TYPE_RATE_COLUMNS);
					} else {
						setActiveColumn(CHANNEL_TYPE_COLUMNS);
					}
					setActiveFieldColumn(CHANNEL_TYPE_COLUMS_FIELD);
					break;
				case 'REGULAR':
					if (nonShareableColumn && nonShareableColumn.isPercentage) {
						setActiveColumn(RATE_COLUMNS);
					} else {
						setActiveColumn(FEE_COLUMNS);
					}
					setActiveFieldColumnRegularPercentage(REGULAR_PERCENTAGE_COLUMNS);
					break;
				case 'PERCENTAGE':
					setActiveColumn(RATE_COLUMNS);
					setActiveFieldColumnRegularPercentage(REGULAR_PERCENTAGE_COLUMNS);
					break;
				default:
					setActiveColumn([]);
					setActiveFieldColumn([]);
					break;
			}
		}
	}, [serviceFeeData, nonShareableColumn, itemKey, data.productId]);

	useEffect(() => {
		console.log(
			'serviceFeeData: ',
			serviceFeeData,
			calculateTotalAmountForSfShare(
				serviceFeeData.find((v) => v.config.product_id === data.productId)
			)
		);
	}, [serviceFeeData]);

	return (
		<div
			id={`accordion-item-${itemKey}-${data.productId}`}
			className={styles.accordionContent}
		>
			<div className={styles.serviceFeeContainer}>
				{serviceFeeData &&
				serviceFeeData.length > 0 &&
				activeColumn &&
				activeColumn.length > 0 ? (
						<ChannelTable
							itemKey={`table-item-${itemKey}`}
							data={calculateTotalAmountForSfShare(
								serviceFeeData.find((v) => v.config.product_id === data.productId)
							)}
							columnsParam={activeColumn}
							key={`${itemKey}-${data.productId}-${Math.random() * 1000000}`}
							tableClassName={styles.table}
							bodyClassName={styles.tableBody}
							headerClassName={styles.tableHeader}
							containerClassName={styles.tableContainerStyle}
							customStyles={customStyles}
						/>
				) : (
					<div>Service Fee Table not available</div>
				)}

				<hr className={styles.lineDevider} />
				{serviceFeeData &&
				serviceFeeData.length > 0 &&
				activeFieldColumn &&
				activeFieldColumn.length > 0 ? (
					<ServiceFeeTable
						itemKey={itemKey}
						data={calculateTotalAmountForSfShare(
							serviceFeeData.find((v) => v.config.product_id === data.productId)
						)}
						title="Service Fee Share"
						columns={activeFieldColumn}
						nonShareableColumn={nonShareableColumn}
						sfConfig={serviceFeeData}
						permission={permission}
						selectedChannel={selectedChannel}
						product={data}
						key={`${itemKey}-${data.productId}`}
							customStyles={customStyles ? customStyles : undefined}
					/>
				) : serviceFeeData &&
				  serviceFeeData.length > 0 &&
				  activeFieldColumnRegularPercentage &&
				  activeFieldColumnRegularPercentage.length > 0 ? (
					<ServiceFeeTable
						itemKey={itemKey}
						data={calculateTotalAmountForSfShare(
							serviceFeeData.find((v) => v.config.product_id === data.productId)
						)}
						title="Service Fee Share"
						columns={activeFieldColumnRegularPercentage}
						nonShareableColumn={nonShareableColumn}
						sfConfig={serviceFeeData}
						permission={permission}
						selectedChannel={selectedChannel}
						product={data}
						key={`${itemKey}-${data.productId}`}
					/>
				) : (
					<div>Service Fee Fields not available</div>
				)}

				<hr className={styles.lineDeviderEnd} />
			</div>
		</div>
	);
};

export default ServiceFeeContainer;
