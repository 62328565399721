import { Modal } from '@salesforce/design-system-react';
import { ReactComponent as ApproveIcon } from 'assets/icons/ic-approve.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/ic-reject.svg';
import Button from 'components/Buttons/Button';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ErrorModal, {
    ErrorModalActions,
    ErrorModalBody,
} from 'components/Modal/ErrorModal';
import SuccessModal, { SuccessModalActions, SuccessModalBody, SuccessText } from "components/Modal/SuccessModal";
import React, { useState } from "react";
import { restrictSpecialChars } from "utils/common";
import styles from './styles.module.css';
import { ErrorMessage } from './types';
type ApproveRejectProps = {
    isOpen
    hideApprove
    approveReject
    rowData: Array<any>
    isApprove
}
export const ApproveRejectModal: React.FC<ApproveRejectProps> = ({ isOpen = false, hideApprove, approveReject, rowData, isApprove }) => {
    const [remarks, setRemarks] = useState<string>('');
    const headingFunction = () => {
        if (rowData.length == 1) {
            return `${isApprove ? 'Approve' : 'Reject'} Request`
        } else {
            return `Batch ${isApprove ? 'Approve' : 'Reject'}`
        }
    }
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={hideApprove}
            ariaHideApp={false}
            headerClassName={''}
            containerClassName={styles.modalContainer}
            contentClassName={''}
            heading={
                headingFunction()
            }
            footer={
                <Footer hideApprove={hideApprove} approveReject={approveReject} disabled={!(remarks.length > 0)} remarks={remarks} isApprove={isApprove} />
            }
        >   
            {rowData.length > 1 && (
                <div className={styles.bodyHeader}>
                <span>
                        Are you sure you want to {isApprove ? 'Approve' : 'Reject'} the selected <strong>{rowData.length}</strong> requests?
                    </span>
                </div>
            )}

            {rowData.length == 1 && (
                <div className={styles.bodyHeader}>
                    <span>
                        Are you sure you want to {isApprove ? 'Approve' : 'Reject'} <strong>{rowData[0].name || rowData[0].channelAccountName}</strong>?
                    <br />
                    If yes, please input remarks.
                </span>
            </div>
            )}
            <div className={styles.bodyContent}>
                <div className="slds-form-element">
                    <label className="slds-form-element__label">
                        Remarks
                        <abbr className="slds-required" title="required">
                            *
                        </abbr>
                    </label>
                    <div className="slds-form-element__control">
                        <textarea
                            className={'slds-textarea' + ' ' + styles.remarks}
                            maxLength={255}
                            onInput={restrictSpecialChars}
                            value={remarks}
                            onChange={value => setRemarks(value.target.value)}
                        ></textarea>
                    </div>
                </div>
                <em className={styles.remarksSubLabel}>
                    *Maximum of 255 characters only.
                </em>
            </div>
        </Modal>
    )
}

type RejectProps = {
    name?
}
export const RejectModal: React.FC<RejectProps> = ({ name }) => {
    return (
        <h1>Reject!</h1>
    )
}

type FooterProps = {
    hideApprove
    approveReject
    disabled
    remarks
    isApprove
}
const Footer: React.FC<FooterProps> = ({ hideApprove, approveReject, disabled, remarks, isApprove }) => {
    return (
        <div className={styles.approvalModalFooter}>
            <OutlineButton
                onClick={() => {
                    hideApprove();
                }}
                className={styles.approvalModalBtn}
            >
                Cancel
            </OutlineButton>
            <PrimaryButton
                onClick={() => {
                    approveReject(remarks, false, isApprove);
                }}
                className={styles.approvalModalBtn}
                disabled={disabled}
            >
                Confirm
            </PrimaryButton>
        </div>
    )
}
type ApproveErrorModalProps = {
    isErrorModalShown
    hideErrorModal
    errorMessage: ErrorMessage
    retry?
}
export const ApproveErrorModal: React.FC<ApproveErrorModalProps> = ({
    isErrorModalShown,
    hideErrorModal,
    errorMessage,
    retry,
}) => {
    return (
        <ErrorModal open={isErrorModalShown} onClose={hideErrorModal} ariaHideApp={false}>
            <ErrorModalBody>
                <div className={styles.errorBodyContent}>
                    <div className={styles.errorHeader}>{errorMessage.header}</div>
                    <div className={styles.errorBody}>{errorMessage.message}</div>
                </div>
            </ErrorModalBody>
            <ErrorModalActions>
                <PrimaryButton
                    fullWidth
                    onClick={() => {
                        errorMessage.isCommonError ? hideErrorModal()
                            : retry(errorMessage.type);
                    }}
                    className={styles.errorModalBtn}
                >
                    {errorMessage.isCommonError ? 'Okay' : 'Retry'}
                </PrimaryButton>
            </ErrorModalActions>
        </ErrorModal>
    )
}
type ApproveSuccessModalProps = {
    isSuccessModalShown
    hideSuccessModal
    successMessage
}
export const ApproveSuccessModal: React.FC<ApproveSuccessModalProps> = ({ isSuccessModalShown, hideSuccessModal, successMessage }) => {
    return (
        <SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal} ariaHideApp={false}>
            <SuccessModalBody>
                <SuccessText>
                    <div className={styles.successHeader}>Success!</div>
                    <div className={styles.successBody}>{successMessage}</div>
                </SuccessText>
            </SuccessModalBody>
            <SuccessModalActions>
                <PrimaryButton
                    className={styles.successModalBtn}
                    onClick={() => hideSuccessModal()}
                >
                    Done
                </PrimaryButton>
            </SuccessModalActions>
        </SuccessModal>
    )
}
type PreHeaderProps = {
    selectedRows: Array<any>
    showApprove?
    showReject?
    returnANode?
}
export const PreHeader: React.FC<PreHeaderProps> = ({ selectedRows, showApprove, showReject, returnANode }) => {
    if (selectedRows.length > 0) {
        return (
            <>
                <div className={styles.leftAlign}>
                    <label className={styles.preHeaderLabel}>
                        {selectedRows.length}
                        {selectedRows.filter((row) => row.checked).length === 1
                            ? ' request'
                            : ' requests'}{' '}
                        selected
                    </label>
                </div>

                <Button
                    className={styles.btnApprove}
                    onClick={showApprove}
                >
                    <ApproveIcon className={styles.btnApproveIcon} /> Approve
                </Button>

                <Button
                    className={styles.btnReject}
                    onClick={showReject}
                >
                    <RejectIcon className={styles.btnRejectIcon} /> Reject
                </Button>
            </>)
    } else return null
}