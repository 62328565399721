import {
	BranchAddressType,
	BranchBasicInformationType,
	BranchContactDetailsType,
	BranchOperatingScheduleType,
	BranchType,
} from 'containers/ChannelManagement/Branch/BranchForm/types';

export const BRANCH_BASIC_INFORMATION_DEFAULT_VALUES: BranchBasicInformationType =
	{
		channelAccountName: '',
		channelAccountCode: '',
		branchName: '',
		branchCode: '',
		channelTransactionType: '',
		channelType: '',
		posType: '',
		settlementSetup: '',
		storeType: '',
		storeFormat: '',
		machineLocation: '',
		pldtTellerCode: '',
		cignalTellerCode: '',
		meralcoPaymentCenterCode: '',
	};

export const BRANCH_ADDRESS_DEFAULT_VALUES: BranchAddressType = {
	locationBase: 'LOCAL',
	country: '175',
	area: '',
	region: '',
	stateOrProvince: '',
	municipalityOrCity: '',
	barangay: '',
	buildingNameOrNumber: '',
	street: '',
	zipCode: '',
};

export const BRANCH_CONTACT_DETAILS_DEFAULT = {
	name: '',
	department: '',
	emailAddress: [],
	mobileNumber: [],
	position: '',
	telephoneNumber: [],
};

export const BRANCH_CONTACT_DETAILS_DEFAULT_VALUES: BranchContactDetailsType = [
	BRANCH_CONTACT_DETAILS_DEFAULT,
];

export const BRANCH_OPERATING_SCHEDULE: BranchOperatingScheduleType = {
	schedule: [
		{ day: 'MONDAY', startTime: null, endTime: null, isSelected: false },
		{ day: 'TUESDAY', startTime: null, endTime: null, isSelected: false },
		{ day: 'WEDNESDAY', startTime: null, endTime: null, isSelected: false },
		{ day: 'THURSDAY', startTime: null, endTime: null, isSelected: false },
		{ day: 'FRIDAY', startTime: null, endTime: null, isSelected: false },
		{ day: 'SATURDAY', startTime: null, endTime: null, isSelected: false },
		{ day: 'SUNDAY', startTime: null, endTime: null, isSelected: false },
	],
	depositConsolidation: '',
	taxPayerType: '',
	activationDate: '',
	remarks: '',
};

export const BRANCH_DEFAULT_VALUES: BranchType = {
	branchBasicInformation: BRANCH_BASIC_INFORMATION_DEFAULT_VALUES,
	branchAddress: BRANCH_ADDRESS_DEFAULT_VALUES,
	branchContactDetails: BRANCH_CONTACT_DETAILS_DEFAULT_VALUES,
	branchOperatingSchedule: BRANCH_OPERATING_SCHEDULE,
};
