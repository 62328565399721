import yup from "utils/formSchemas/common";

export const channelAccreditationItemSchema = yup.object({}).shape({
    label: yup.string().nullable().optional(),
    name: yup.string().nullable().optional(),
    file: yup.string().nullable().optional(),
    filename: yup.string().nullable().optional(),
    objectKey: yup.string().nullable().optional(),
    status: yup.string().nullable().optional().oneOf(['Uploading', 'Uploaded', 'Upload Failed', 'Not Yet Uploaded', '']),
})

export const channelAccreditationSchema = yup.object({}).shape({
    birCertificateForm: channelAccreditationItemSchema,
    corpSecretaryCert: channelAccreditationItemSchema,
    businessPermit: channelAccreditationItemSchema,
    companyProfile: channelAccreditationItemSchema,
    govtId: channelAccreditationItemSchema,
    genInfoSheet: channelAccreditationItemSchema,
    nonDisclosure: channelAccreditationItemSchema,
    secRegCert: channelAccreditationItemSchema,
    secArticleInc: channelAccreditationItemSchema,
    secondaryLicense: channelAccreditationItemSchema,
    signedNonDisclosure: channelAccreditationItemSchema,
    auditedFinancialStmt: channelAccreditationItemSchema,
    incomeTax: channelAccreditationItemSchema,
    cbciAmlQuestion: channelAccreditationItemSchema,
    prevAuditedFinancialStmt: channelAccreditationItemSchema,
    dataPrivacyPia: channelAccreditationItemSchema,
    dataPrivacyPat: channelAccreditationItemSchema,
    thirdPartySecurityCheck: channelAccreditationItemSchema,
    bondCopy: channelAccreditationItemSchema,
    accreditationFee: channelAccreditationItemSchema,
    annualFee: channelAccreditationItemSchema,
})
