import client from 'helpers/ApiClient';
import { useQuery } from 'react-query';

export const useGetChannelAudit = (id: number | undefined, type: string | undefined, params: any,) => {
	return useQuery('audit', async () => {
			if(type == 'terminal'){
				const response = await client.get(
					//`/v2/terminals/${id}/audit-logs`, { params }
					`/v2/terminals/336/audit-logs`, { params }
				);
				return response.data;
			} else if (type == 'branch'){
				const response = await client.get(
					//`/v2/branches/${id}/audit-logs`, { params }
					`/v2/branches/196/audit-logs`, { params }
				);
				return response.data;
			} else if (type === 'channel'){
				const response = await client.get(
					//`/v2/channels/${id}/audit-logs`, { params }
					`/v2/channels/247/audit-logs`, { params }
				);
				return response.data;
			}
	});
};

export const useGetChannelLogs = (id: number | undefined, logId: number | undefined, type: string | undefined, params: any,) => {
	return useQuery('logs', async () => {
		if(logId != undefined && logId != null && logId >= 0){
			if(type == 'terminal'){
				const response = await client.get(
					//`/v2/terminals/${id}/audit-logs/${logId}`, { params }
					`/v2/terminals/336/audit-logs/${logId}`, { params }
				);
				return response.data;
			} else if (type == 'branch'){
				const response = await client.get(
					//`/v2/branches/${id}/audit-logs/${logId}`, { params }
					`/v2/branches/196/audit-logs/${logId}`, { params }
				);
				return response.data;
			} else if (type === 'channel'){
				const response = await client.get(
					///v2/channels/${id}/audit-logs/${logId}`, { params }
					`/v2/channels/247/audit-logs/${logId}`, { params }
				);
				return response.data;
			}
		}
	});
};