import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';

import { Modal } from '@salesforce/design-system-react';
import Table from 'components/CWSTable/Table';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';

import client from 'helpers/ApiClient';
import { formatDate, formatParams } from 'utils/common';
import {
	useErrorModal,
	useLoader,
	useTablePagination,
	useToggle,
} from 'utils/hooks';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import ChannelDetailsTable from './ChannelDetailsTable';
import {
	ChannelBranch,
	PaginationOptions,
	AuditTrail,
	AuditTrailListResp,
	AuditTrailListFilter,
	ChannelLogDetails,
} from '../types';
import { BFA_STATUS_LABEL } from '../constants';
import styles from '../ChannelDetails/ChannelDetails.module.css';

const ChannelAuditTrail: React.FC<ChannelBranch> = ({
	channelId,
	isRefetchData,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [data, setData] = useState<AuditTrail>();
	const [filter, setFilter] = useState<PaginationOptions>({});
	const [logDetails, setLogDetails] = useState([]);

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		value: isChannelAuditModalVisible,
		valueOn: showChannelAuditModal,
		valueOff: hideChannelAuditModal,
	} = useToggle();

	const fetchAuditTrailList = async (params?: AuditTrailListFilter) => {
		hideErrorModal();
		if (!isRefetchData) showLoadingMessage();

		try {
			const result: AuditTrailListResp = await client.get(
				`v2/bfa-admin-portal/channels/${channelId}/audit-trail`,
				{ params }
			);

			setData(result?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchAuditTrailList(params)
			);
		} finally {
			hideLoading();
		}
	};

	const mapViewLogs: any = (details, oldValue, newValue) => {
		if (!oldValue || !newValue) return [];

		const viewLogArray: ChannelLogDetails[] = [];

		//IF CHANNEL IS UPDATED
		if (oldValue?.branches) {
			const channelLog = {
				channelName: oldValue.name || '',
				branchName: '-',
				tpa: '-',
				oldValues: startCase(BFA_STATUS_LABEL[oldValue.status || '']),
				newValues: startCase(BFA_STATUS_LABEL[newValue.status || '']),
			};

			viewLogArray.push(channelLog);

			oldValue.branches.forEach(
				(
					{ name: branchName, status: branchStatus, terminals },
					branchIndex
				) => {
					const tpaLogs = terminals
						? terminals.map(({ tpaId, status: tpaStatus }, tpaIndex) => {
								return {
									channelName: channelLog.channelName,
									branchName: branchName,
									tpa: tpaId,
									oldValues: startCase(BFA_STATUS_LABEL[tpaStatus || '']),
									newValues: startCase(
										BFA_STATUS_LABEL[
											newValue.branches[branchIndex]?.terminals[tpaIndex]
												?.status || ''
										]
									),
								};
						  })
						: [];

					const branchLog = {
						channelName: channelLog.channelName,
						branchName: branchName,
						tpa: '-',
						oldValues: startCase(BFA_STATUS_LABEL[branchStatus || '']),
						newValues: startCase(
							BFA_STATUS_LABEL[newValue.branches[branchIndex].status || '']
						),
					};
					viewLogArray.push(branchLog, ...tpaLogs);
				}
			);
		}
		//IF BRANCH IS UPDATED
		else if (oldValue?.terminals) {
			const branchesLog = {
				channelName: details.channelName || '',
				branchName: oldValue.name,
				tpa: '-',
				oldValues: startCase(BFA_STATUS_LABEL[oldValue.status || '']),
				newValues: startCase(BFA_STATUS_LABEL[newValue.status || '']),
			};
			const terminalsLog: ChannelLogDetails[] = oldValue.terminals.map(
				({ tpaId, status: tpaStatus }, tpaIndex) => {
					return {
						channelName: details.channelName || '',
						branchName: oldValue.name,
						tpa: tpaId,
						oldValues: startCase(BFA_STATUS_LABEL[tpaStatus || '']),
						newValues: startCase(
							BFA_STATUS_LABEL[newValue.terminals[tpaIndex]?.status || '']
						),
					};
				}
			);
			viewLogArray.push(branchesLog, ...terminalsLog);
		}
		//IF TERMINAL IS UPDATED
		else {
			const terminalsLog = {
				channelName: details.channelName || '',
				branchName: details.branchName || '',
				tpa: oldValue.tpaId,
				oldValues: startCase(BFA_STATUS_LABEL[oldValue.status || '']),
				newValues: startCase(BFA_STATUS_LABEL[newValue.status || '']),
			};

			viewLogArray.push(terminalsLog);
		}

		return viewLogArray;
	};

	const handleOnClickViewLogs = (row) => {
		setLogDetails(
			mapViewLogs(
				{
					channelName: row?.original?.channelName,
					branchName: row?.original?.branchName,
					auditType: row?.original?.auditType,
				},
				row?.original?.oldValues,
				row?.original?.newValues
			)
		);
		showChannelAuditModal();
	};

	const handleTableFetch = (params) => {
		const newFilter = formatParams({ ...filter, ...params });
		setFilter(newFilter);
		fetchAuditTrailList(newFilter);
	};
	const tablePaginationProps = useTablePagination(handleTableFetch, data?.meta);

	const tableProps = {
		columns: [
			{
				Header: 'Date and Time',
				id: 'createdAt',
				sortable: false,
				width: '25%',
				accessor: ({ createdAt }) => formatDate(createdAt),
			},
			{
				Header: 'Logged By',
				id: 'loggedBy',
				sortable: false,
				width: '12.5%',
			},
			{
				Header: 'Action',
				id: 'action',
				sortable: false,
				width: '25%',
			},
			{
				Header: 'Source IP',
				id: 'ipAddress',
				sortable: false,
				width: '12.5%',
			},
			{
				Header: 'Remarks',
				id: 'remarks',
				sortable: false,
				width: '12.5%',
			},
			{
				Header: '',
				id: 'logs',
				sortable: false,
				width: '12.5%',
				Cell: ({ row }) => (
					<div className={styles.actionContainer}>
						<div
							className={styles.action}
							onClick={() => handleOnClickViewLogs(row)}
						>
							View Logs
						</div>
					</div>
				),
			},
		],
		scrollable: true,
		data: data?.data,
		className: styles.tableContainerTabContent,
		isNotEvenRowColor: true,
		...tablePaginationProps,
	};

	const VIEW_LOGS_COLUMNS = [
		{
			Header: 'Channel',
			id: 'channelName',
			sortable: false,
			width: '20%',
		},
		{
			Header: 'Branch',
			id: 'branchName',
			sortable: false,
			width: '20%',
		},
		{
			Header: 'TPAID',
			id: 'tpa',
			sortable: false,
			width: '20%',
		},
		{
			Header: 'Old Values',
			id: 'oldValues',
			sortable: false,
			width: '20%',
		},
		{
			Header: 'New Values',
			id: 'newValues',
			sortable: false,
			width: '20%',
		},
	];

	useEffect(() => {
		if (hasUserPermission('channel.audit-trail')) {
			fetchAuditTrailList();
		}
	}, []);

	useEffect(() => {
		if (isRefetchData) {
			fetchAuditTrailList();
		}
	}, [isRefetchData]);

	return (
		<div>
			<ChannelDetailsTable tableProps={tableProps} />
			<Modal
				ariaHideApp={false}
				isOpen={isChannelAuditModalVisible}
				onRequestClose={hideChannelAuditModal}
				size="medium"
				align="center"
			>
				<div className={styles.customModalHeader}>View Logs</div>
				<Table
					columns={VIEW_LOGS_COLUMNS}
					data={logDetails}
					showPagination={false}
					isNotEvenRowColor={false}
					preHeader={null}
					modalIsOpen={false}
				/>
			</Modal>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</div>
	);
};
export default ChannelAuditTrail;
