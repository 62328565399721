import SelectField from 'components/Inputs/SelectField/SelectField';
import { SelectFieldProps } from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import { useMemo } from 'react';
import { useProvinceQuery } from 'utils/queries/location';

export type ProvinceSelectProps = {
	selectFieldProps?: SelectFieldProps;
};

const ProvinceSelect: React.FC<ProvinceSelectProps> = ({
	selectFieldProps,
}) => {
	const { data, isLoading } = useProvinceQuery();
	const options = useMemo(
		() =>
			data?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [],

		[data]
	);

	return (
		<SelectField
			label="State/Province"
			isLoading={isLoading}
			options={options}
			{...selectFieldProps}
		/>
	);
};

export default ProvinceSelect;
