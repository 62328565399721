import * as yup from 'yup';
import {
	defaultInvalidCharacter,
	hasSpecialCharacter,
	selectDefaultRequiredTemplate,
} from 'utils/formSchemas/common';

import {
	LOCAL,
	INTERNATIONAL,
	COUNTRY,
	AREA,
	AREA_VALUES,
	AREA_ERROR_MSG,
	AREA_LOCAL_MSG,
	REGION,
	REGION_VALUES,
	REGION_ERROR_MSG,
	REGION_LOCAL_MSG,
	STATE_PROVINCE,
	MUNICIPALITY_CITY,
	BARANGAY,
	BUILDING_NAME_NO,
	STREET,
	ZIP_CODE,
} from './const';

import {
	MAX_CHAR_255,
	MAX_CHAR_255_MSG,
} from 'containers/ChannelManagement/Channel/ChannelForm/const';
import { YupLab } from 'utils/yupLab';

const ChannelAddressEntrySchema = new YupLab(
	yup.object().shape({
		locationBase: yup.string().oneOf([LOCAL, INTERNATIONAL]),
		countryId: yup
			.string()
			.label(COUNTRY)
			.when('locationBase', {
				is: INTERNATIONAL,
				then: (s) => s.required(selectDefaultRequiredTemplate),
				otherwise: (s) => s.transform(() => '1').notRequired(),
			}),
		area: yup
			.string()
			.label(AREA)
			.required()
			.oneOf(AREA_VALUES, AREA_ERROR_MSG)
			.when('locationBase', {
				is: LOCAL,
				then: (s) => s.required(AREA_LOCAL_MSG),
			}),
		regionId: yup
			.string()
			.label(REGION)
			.required()
			.oneOf(REGION_VALUES, REGION_ERROR_MSG)
			.when('locationBase', {
				is: LOCAL,
				then: (s) => s.required(REGION_LOCAL_MSG),
			}),
		provinceId: yup
			.string()
			.label(STATE_PROVINCE)
			.required()
			.when('locationBase', {
				is: LOCAL,
				then: (s) => s.required(selectDefaultRequiredTemplate),
			}),
		cityId: yup
			.string()
			.label(MUNICIPALITY_CITY)
			.required()
			.when('locationBase', {
				is: LOCAL,
				then: (s) => s.required(selectDefaultRequiredTemplate),
			}),
		barangayId: yup
			.string()
			.label(BARANGAY)
			.required()
			.when('locationBase', {
				is: LOCAL,
				then: (s) => s.required(selectDefaultRequiredTemplate),
			}),
		buildingName: yup
			.string()
			.label(BUILDING_NAME_NO)
			.when('locationBase', {
				is: LOCAL,
				then: (s) =>
					s
						.required()
						.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
						.test('', defaultInvalidCharacter, (v: any) =>
							hasSpecialCharacter(v)
						),
			}),
		street: yup
			.string()
			.label(STREET)
			.required()
			.when('locationBase', {
				is: LOCAL,
				then: (s) =>
					s
						.required()
						.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
						.test('', defaultInvalidCharacter, (v: any) =>
							hasSpecialCharacter(v)
						),
			}),
		zipCode: yup.string().label(ZIP_CODE),
	})
);

export const defaultAddressValue =
	ChannelAddressEntrySchema.objectSchema.getDefaultFromShape();

export const ChannelAddressSchema = new YupLab(
	yup
		.array()
		.of(ChannelAddressEntrySchema.objectSchema)
		.default([defaultAddressValue])
);
export const channelAddressInitlValues =
	ChannelAddressSchema.arraySchema.getDefault();

console.log('TEST', channelAddressInitlValues);
export type ChannelAddressType = ReturnType<
	(typeof ChannelAddressSchema.schema)['cast']
>;
