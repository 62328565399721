import Checkbox from 'components/Checkbox/Checkbox';
import Grid from 'components/Grid/Grid';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import TimeField from 'components/Inputs/TimeField/TimeField';
import Section, { SectionRow } from 'components/Section/Section';
import {
	DEPOSIT_CONSOLIDATION_OPTIONS,
	TAX_PAYER_TYPE_OPTIONS,
} from 'containers/ChannelManagement/Branch/BranchForm/OperatingSchedule/constants';
import { OperatingScheduleProps } from 'containers/ChannelManagement/Branch/BranchForm/OperatingSchedule/types';
import { BranchType } from 'containers/ChannelManagement/Branch/BranchForm/types';
import moment from 'moment';
import { useFieldArray, useFormContext } from 'react-hook-form';

const OperatingSchedule: React.FC<OperatingScheduleProps> = ({
	isDisabled,
}) => {
	const parentPathName = 'branchOperatingSchedule.schedule';
	const { control } = useFormContext<BranchType>();
	const { fields } = useFieldArray({
		control,
		name: parentPathName,
	});
	return (
		<>
			<Section title={'Operating Schedule'}>
				{fields.map((field, index) => (
					<SectionRow key={field.id}>
						<Grid column size={2} of={12}>
							<Checkbox
								label={field.day}
								control={control}
								name={`${parentPathName}.${index}.isSelected`}
								disabled={isDisabled}
							></Checkbox>
						</Grid>
						<Grid column size={2} of={12}>
							<TimeField
								label=""
								required
								control={control}
								name={`${parentPathName}.${index}.startTime`}
								placeholder="Start Time"
								formatValue={(value) => {
									const to12HourFormat = moment(value, ['HH:mm']).format(
										'hh:mm'
									);
									return to12HourFormat;
								}}
								disabled={isDisabled}
							/>
						</Grid>
						<div>to</div>
						<Grid column size={2} of={12}>
							<TimeField
								label={''}
								required
								control={control}
								name={`${parentPathName}.${index}.endTime`}
								placeholder="End Time"
								formatValue={(value) => {
									const to12HourFormat = moment(value, ['HH:mm']).format(
										'hh:mm'
									);
									return to12HourFormat;
								}}
								disabled={isDisabled}
							/>
						</Grid>
					</SectionRow>
				))}
				<br />
				<SectionRow>
					<Grid column size={4} of={12}>
						<SelectField
							label="Deposit Consolidation"
							name={`branchOperatingSchedule.depositConsolidation`}
							control={control}
							placeholder="Select Deposit Consolidation"
							options={DEPOSIT_CONSOLIDATION_OPTIONS}
							disabled={isDisabled}
						></SelectField>
					</Grid>
					<Grid column size={4} of={12}>
						<SelectField
							label="Tax Payer Type"
							name={`branchOperatingSchedule.taxPayerType`}
							control={control}
							placeholder="Select Tax Payer Type"
							required
							options={TAX_PAYER_TYPE_OPTIONS}
							disabled={isDisabled}
						/>
					</Grid>
					<Grid column size={4} of={12}>
						<DatePickerField
							label="Activation Date"
							name={`branchOperatingSchedule.activationDate`}
							control={control}
							required
							placeholder="MM/DD/YYYY"
							disabled={isDisabled}
						/>
					</Grid>
				</SectionRow>
				<SectionRow>
					<Grid column size={12} of={12}>
						<TextField
							label="Remarks"
							name={`branchOperatingSchedule.remarks`}
							multiLine
							rows={5}
							resizable={true}
							control={control}
							disabled={isDisabled}
						/>
					</Grid>
				</SectionRow>
			</Section>
		</>
	);
};

export default OperatingSchedule;
