import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useToggle } from 'utils/hooks';
import {} from 'react-table';
import table from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeTable/ServiceFeeTable.module.css';
import btnStyle from 'components/PartnerDetails/PartnerDetails.module.css';
import { ReactComponent as EditIcon } from 'assets/icons/ic-save.svg';
import { ServiceFeeTableProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import ChannelTable from 'containers/ChannelManagement/Channel/ChannelTable/ChannelTable';
import { Button } from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import ChannelProductModal from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal';
import { saveUpdateShare } from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductQuery';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';

const ServiceFeeTable: FC<ServiceFeeTableProps> = ({
	itemKey,
	columns,
	data,
	title,
	serviceFeeType,
	nonShareableColumn,
	control,
	sfConfig,
	permission,
	selectedChannel,
	product,
	customStyles
}) => {
	const _userInfo = useSelector<ReducerStateType, string>(
		(state) => state.userInfo
	);
	const [enableEdit, setEnableEdit] = useState<boolean>(true);
	const [showConfirmationModal, setShowConfirmationModal] =
		useState<boolean>(false);
	const [requestPayload, setRequestPayload] = useState({});
	const [save, setSave] = useState(false);
	const [existingShare, setExistingShare] = useState([]);
	const [isReset, setIsReset] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState(<></>);
	const [share, setShare] = useState([
		{
			productId: 0,
			id: 0,
			partnerShare: 0,
			bayadShare: 0,
			hasError: false,
			message: '',
		},
	]);

	const styles = customStyles ? customStyles : {
		...table,
		...btnStyle,
	};

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isErrorRetryShowing,
		valueOn: showErrorRetry,
		valueOff: hideErrorRetry,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	useEffect(() => {
		if (sfConfig && sfConfig.length > 0) {
			setExistingShare(
				sfConfig.map((item) => {
					return {
						...item,
						shares: item.shares.map((val) => {
							return {
								productId: val.product_id,
								id: val.tier_number || val.channel_type_id,
								partnerShare: val.partner_share,
								bayadShare: val.bayad_share,
								hasError: false,
								message: '',
							};
						}),
					};
				})
			);
		}
	}, [sfConfig]);

	useEffect(() => {
		if (isReset && existingShare && existingShare.length > 0) {
			const extractedShares = existingShare.flatMap((item) => item.shares);
			setShare(extractedShares);
			setIsReset(false);
		}
	}, [existingShare, isReset]);

	const setPartnerShareAmount = useCallback(
		(event, name, rowData) => {
			const newShareValue = event.target.value;
			const isPercentage = nonShareableColumn.isPercentage;
			if (isPercentage && (newShareValue < 0 || newShareValue > 100)) {
				setShare((prevShare) =>
					prevShare.map((item) => {
						if (
							item.productId === product.productId &&
							item.id === (rowData.tier_number || rowData.channel_type_id)
						) {
							return {
								...item,
								partnerShare: 0,
								bayadShare: 0,
								hasError: true,
								message: 'Input value 0-100',
							};
						}
						return item;
					})
				);
				return;
			}

			setShare((prevShare) => {
				const updatedShare = prevShare.map((item) => {
					if (
						item.productId === product.productId &&
						item.id === (rowData.tier_number || rowData.channel_type_id)
					) {
						const updatedPartnerShare = parseFloat(newShareValue);
						const partnerShare =
							!isNaN(updatedPartnerShare) && isFinite(updatedPartnerShare)
								? updatedPartnerShare
								: 0;

						return {
							...item,
							partnerShare,
							bayadShare: Math.abs(
								parseFloat(partnerShare) -
									parseFloat(
										isPercentage
											? rowData.total_amount_for_sf_share_rate
											: rowData.total_amount_for_sf_share
									)
							).toFixed(2),
							hasError: false,
							message: '',
						};
					}
					return item;
				});

				const isNewRow = !prevShare.some(
					(item) =>
						item.productId === product.productId &&
						item.id === (rowData.tier_number || rowData.channel_type_id)
				);
				if (isNewRow && newShareValue !== '.00' && newShareValue !== '') {
					updatedShare.push({
						productId: product.productId,
						id: rowData.tier_number || rowData.channel_type_id,
						partnerShare: newShareValue,
						bayadShare: Math.abs(
							parseFloat(newShareValue) -
								parseFloat(
									isPercentage
										? rowData.total_amount_for_sf_share_rate
										: rowData.total_amount_for_sf_share
								)
						).toFixed(2),
						hasError: false,
						message: '',
					});
				}

				return updatedShare;
			});
			event.target.focus();
		},
		[nonShareableColumn, share, product.productId]
	);

	const [regularOrPercentageState, setRegularOrPercentage] = useState('');
	const tableProps = useMemo(() => {
		const regularOrPercentage = sfConfig.find(
			(v) => v.config.product_id === product.productId
		).config.scheme_type;
		setRegularOrPercentage(regularOrPercentage);
		return {
			data: data,
			columnsParam: columns,
			key: itemKey,
			tableClassName:
				regularOrPercentage === 'REGULAR' ||
				regularOrPercentage === 'PERCENTAGE'
					? styles.tableRegular
					: styles.table,
			bodyClassName:
				regularOrPercentage === 'REGULAR' ||
				regularOrPercentage === 'PERCENTAGE'
					? styles.tableBodyNoborder
					: styles.tableBody,
			headerClassName:
				regularOrPercentage === 'REGULAR' ||
				regularOrPercentage === 'PERCENTAGE'
					? styles.tableHeaderNoborder
					: styles.tableHeader,
			containerClassName:
				regularOrPercentage === 'REGULAR' ||
				regularOrPercentage === 'PERCENTAGE'
					? styles.tableContainerRegular
					: styles.tableContainerStyle,
			onChange: setPartnerShareAmount,
			serviceFeeType: serviceFeeType,
			toEdit: enableEdit,
			shares: share.filter((v) => v.productId === product.productId),
			nonShareableColumn: nonShareableColumn,
			control: control,
		};
	}, [
		data,
		columns,
		itemKey,
		styles.table,
		styles.tableBody,
		styles.tableHeader,
		setPartnerShareAmount,
		serviceFeeType,
		enableEdit,
		share,
		nonShareableColumn,
		control,
		product.productId,
		sfConfig,
		styles.tableBodyNoborder,
		styles.tableHeaderNoborder,
	]);

	const handleCancelBtnClick = () => {
		setEnableEdit(!enableEdit);
		setIsReset(true);
	};

	const [shareData, setShareData] = useState({
		valid: false,
		data: [],
	});

	useEffect(() => {
		setShareData({
			valid: !share.find(
				(v) => v.hasError === true && v.productId === product.productId
			)?.hasError
				? share.filter((v) => v.productId === product.productId).length ===
				  data.length
				: false,
			data: data,
		});
	}, [share, data, product.productId]);

	const saveServiceFeeShare = () => {
		setShowConfirmationModal(true);
	};

	const handleCancelSubmit = () => {
		setShowConfirmationModal(false);
		setIsReset(true);
	};

	const handleClickRetry = () => {
		// setSave(true);
	};

	const handleConfirm = useCallback(() => {
		setShowConfirmationModal(false);
		setLoadingMessage('Please wait while we save shares.');
		showLoading();
		const config = sfConfig.find(
			(v) => v.config.product_id === product.productId
		);
		let submittedBy = '';
		let submittedByEmail = '';
		if (config.shares.length === 0) {
			submittedBy = _userInfo.username;
			submittedByEmail = _userInfo.emailAddress;
		}
		const payload = config.values.map((item, index) => {
			const shareItem = share.find(
				(v) =>
					v.productId === product.productId &&
					v.id === (item.tier_number || item.channel_type_id)
			) || { partnerShare: 0, bayadShare: 0 };
			const pshare = shareItem.partnerShare || 0;
			const bshare = shareItem.bayadShare || 0;
			return {
				channel_id: selectedChannel.id,
				product_id: product.productId,
				tier_number: item.tier_number,
				channel_type_id: item.channel_type_id,
				share_type: config.config.non_shareable_rate_type,
				partner_share: pshare,
				bayad_share: bshare,
				total_share: Math.abs(parseFloat(pshare) + parseFloat(bshare)).toFixed(
					2
				),
				remarks: 'test', // not included on saving but existing column
				updated_by: _userInfo.username,
				updated_by_email: _userInfo.emailAddress,
				submitted_by: submittedBy,
				submitted_by_email: submittedByEmail,
			};
		});
		setRequestPayload({
			share: payload,
		});
		setSave(true);
	}, [
		sfConfig,
		share,
		product.productId,
		_userInfo.username,
		_userInfo.emailAddress,
		selectedChannel.id,
		setRequestPayload,
		setSave,
		setLoadingMessage,
		showLoading,
	]);

	useEffect(() => {
		const submitShare = async () => {
			try {
				const res = await saveUpdateShare(
					requestPayload,
					selectedChannel.id,
					product.productId
				);
				hideLoading();
				setSuccessMessage('Service fee share successfully submitted.');
				showSuccessModal();
			} catch (err) {
				const additionalMessage =
					err?.response?.data?.message &&
					err?.response?.data?.message.split('for product')[0];
				hideLoading();
				setErrorMessage(
					<>
						<strong>Shares not submitted: </strong> {additionalMessage}
					</>
				);
				showErrorRetry();
			} finally {
				setSave(false);
			}
		};

		if (save && requestPayload.share.length > 0) {
			submitShare();
		}
	}, [save, requestPayload, selectedChannel.id, product.productId]);

	return (
		<>
			<div>
				<div className={styles.tableTitle}>
					{title}
					<div className={styles.rightPanel}>
						<div className={styles.edit}>
							<Button
								className={cx(
									regularOrPercentageState === 'REGULAR' ||
										regularOrPercentageState === 'PERCENTAGE'
										? styles.inLineRegular
										: styles.inLine,
									styles.linkBtn,
									styles.edit
								)}
								onClick={(v) => {
									setEnableEdit(!enableEdit);
								}}
							>
								<EditIcon />
								{enableEdit ? 'Edit' : 'Exit Edit Mode'}
							</Button>
						</div>
					</div>
				</div>
				<ChannelTable
					{...tableProps}
					itemKey={`table-item-${itemKey}`}
					permission
				/>

				{!enableEdit && (
					<ChannelProductModal
						heading={'Service Fee Share Request'}
						removeHeading={
							regularOrPercentageState !== 'REGULAR' &&
							regularOrPercentageState !== 'PERCENTAGE'
						}
						isOpen={!enableEdit}
						message={''}
						key={`service-fee-${itemKey}-${selectedChannel.id}`}
						cancelBtnLabel="Cancel"
						confirmBtnLabel="Submit"
						onCancelBtnClick={handleCancelBtnClick}
						onClose={handleCancelBtnClick}
						onConfirmBtnClick={() => {
							setEnableEdit(!enableEdit);
							saveServiceFeeShare();
						}}
						showTablefield
						isPrimaryBtnDisabled={permission && !shareData.valid}
						tableContent={
							<div
								style={{
									overflowX: 'auto',
									margin: '0 auto',
									marginBottom: '1vh',
								}}
							>
								{regularOrPercentageState !== 'REGULAR' &&
									regularOrPercentageState !== 'PERCENTAGE' && (
										<div className={styles.contentHeader}>
											Service Fee Share Request
										</div>
									)}
								<div
									style={
										regularOrPercentageState !== 'REGULAR' &&
										regularOrPercentageState !== 'PERCENTAGE'
											? { margin: '1.9vh' }
											: { margin: '0' }
									}
								>
									<ChannelTable
										{...tableProps}
										tableClassName={
											regularOrPercentageState === 'REGULAR' ||
											regularOrPercentageState === 'PERCENTAGE'
												? styles.tableSmall
												: styles.tableBordered
										}
										itemKey={`table-item-${itemKey}`}
										permission={!permission}
									/>
								</div>
							</div>
						}
						headerClassName={styles.headerContainer}
						contentClassName={styles.contentContainer}
						containerClassName={
							regularOrPercentageState === 'REGULAR' ||
							regularOrPercentageState === 'PERCENTAGE'
								? styles.tableSmall
								: styles.modalContainer
						}
					/>
				)}
			</div>

			{showConfirmationModal && (
				<ChannelProductModal
					isOpen={showConfirmationModal}
					onClose={handleCancelSubmit}
					isDisabled={isLoading}
					heading={<h2 className={styles.customHeading}>Submit Request</h2>}
					message={
						<h2 className={styles.bodyHeader}>
							Are you sure you want to submit the request?
						</h2>
					}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Confirm"
					onCancelBtnClick={handleCancelSubmit}
					onConfirmBtnClick={handleConfirm}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalConfirmation}
					contentClassName={styles.modal}
				/>
			)}

			{isLoading && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}
			<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>{successMessage}</SuccessText>
					<div className={styles.subTextNext}>
						You may click the Done button.
					</div>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.doneButton}
						onClick={() => {
							hideSuccessModal();
						}}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			<ErrorModal open={isErrorRetryShowing} onClose={hideErrorRetry}>
				<ErrorModalBody className={styles.errorBody}>
					<div className={styles.errorHeader}>
						<b>Timeout Error!</b>
					</div>
					<div className={styles.errorText}>
						<div>{errorMessage}</div>
						<div>Please check your request and try again</div>
					</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.contactDetailsErrorCloseBtn}
						onClick={() => {
							hideErrorRetry();
							handleClickRetry();
						}}
					>
						Okay
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default ServiceFeeTable;
