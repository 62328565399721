import HTTP from "../helpers/ApiClient";

function refresh(){
    const refreshToken = localStorage.getItem("refreshToken");
    const req = JSON.stringify({
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        redirect_uri: "",
        client_id: process.env.REACT_APP_APP_KEY,
    });

    HTTP.post(process.env.REACT_APP_ACCESS_TOKEN_URL, req)
    .then(data => {
        localStorage.setItem("token", data["data"]["access_token"]);
        localStorage.setItem("refreshToken", data["data"]["refresh_token"]);
    })
    .catch(error => { console.error(error); });
}

function showSessionExpiry(dispatch){
    const timeout = process.env.REACT_APP_TOKEN_SESSION_EXPIRY * 1000 * 60;
    window.sessionTimeout = setTimeout(() =>{
        dispatch()
    }, timeout)
}
export{refresh, showSessionExpiry};