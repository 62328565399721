import { useEffect, useState } from 'react';
import client from 'helpers/ApiClient';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { SLDSDropdownOptionType } from 'types';
import {
	useGetBranchList,
	useGetTerminalList,
} from 'containers/ChannelManagement/List/query';

export const useList = () => {
	const [channelTypeFilter, setChannelTypeFilter] = useState<
		SLDSDropdownOptionType[]
	>([{ value: '', label: '' }]);

	const channelDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedChannel ?? {}
	);
	const branchDetails = useSelector(
		(state: ReducerStateType) => state.channels.selectedBranch ?? {}
	);
	const channelFilter = useSelector(
		(state: ReducerStateType) => state.channels.channelFilter
	);
	const branchFilter = useSelector(
		(state: ReducerStateType) => state.channels.branchFilter
	);
	const terminalFilter = useSelector(
		(state: ReducerStateType) => state.channels.terminalFilter
	);

	const branchListQuery = useGetBranchList(branchFilter, channelDetails.id);
	const terminalListQuery = useGetTerminalList(
		terminalFilter,
		branchDetails.id
	);

	useEffect(() => {
		const getChannelTypeFilter = async () => {
			const response = await client.get('/v2/channel-types');
			const transformData = response.data?.data.map(({ id, name }) => ({
				value: id,
				label: name,
			}));
			setChannelTypeFilter(transformData);
		};
		getChannelTypeFilter();
	}, []);

	return {
		channelTypeFilter,
		channelDetails,
		branchDetails,
		channelFilter,
		branchFilter,
		terminalFilter,
		branchListQuery,
		terminalListQuery,
	};
};
