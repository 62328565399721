import Configurations from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/Configurations';
import FtpContainer from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/FtpContainer';
import FileTransferProtocol from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SFTP/FileTransferProtocol/FileTransferProtocol';
import { NAME } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SFTP/constants';
import { SftpProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SFTP/types';

const SFTP: React.FC<SftpProps> = ({ disabled }) => {
	return (
		<>
			<FileTransferProtocol name={NAME} title="SFTP/FTP" disabled={disabled} />
			<FtpContainer name={NAME} disabled={disabled} />
			<Configurations
				name={`${NAME}.uploadConfiguration`}
				title="Upload Configuration"
				disabled={disabled}
			/>
		</>
	);
};

export default SFTP;
