import { createSlice } from '@reduxjs/toolkit';

type Params = {
	search?: string;
	category?: string;
};

const initialState = {
	category: '',
	search: '',
	billers: [],
	selectedBiller: null,
	currentBillerTab: 'primaryInformation',
};
const billerList = createSlice({
	name: 'billers',
	initialState,
	reducers: {
		prepare: (state: any, { payload }) => {
			if (payload.category != null) {
				state.category = payload.category;
			}

			if (payload.search != null) {
				state.search = payload.search;
			}
		},
		load: (state, payload: any) => {
			state.billers = payload.result.data;
		},
		error: (state, payload) => {},
		selectBiller: (state: any, { payload }) => {
			state.billers = state.billers.map((biller: any) => {
				console.log(payload, 'SELECED BILLER');
				if (biller.shortName === payload.selected) {
					state.selectedBiller = biller;
					return {
						...biller,
						isSelected: true,
					};
				}
				return {
					...biller,
					isSelected: false,
				};
			});
		},
		setCurrentBillerTab: (state: any, { payload }) => {
			state.currentBillerTab = payload;
		},
	},
});

const { prepare, load, error, selectBiller, setCurrentBillerTab } =
	billerList.actions;

const getBillers = (payload: Params = {}) => {
	return {
		types: [prepare.type, load.type, error.type],
		promise: (client: any, state: any) => {
			const params: Params = {};
			const { category, search } = state.billers;

			if (category !== '') {
				params.category = category;
			}

			if (search !== '') {
				params.search = search;
			}

			return client.get('/v1/biller/list', {
				params,
			});
		},
		payload,
	};
};

export { getBillers, selectBiller, setCurrentBillerTab };

export default billerList.reducer;
