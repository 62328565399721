import * as yup from 'yup';
import {
	defaultInvalidCharacter,
	hasSpecialCharacter,
} from 'utils/formSchemas/common';
import {
	email,
	mobileNo,
	telNo,
} from 'containers/ChannelManagement/Channel/ChannelForm/ChannelFormSchema';
import {
	MAX_CHAR_255,
	MAX_CHAR_255_MSG,
} from 'containers/ChannelManagement/Channel/ChannelForm/const';
import { POSITION, DEPARTMENT } from './const';
import { YupLab } from 'utils/yupLab';

export const ChannelContactDetailsEntrySchema = new YupLab(yup.object().shape({
	name: yup
		.string()
		.required('Input Name of Channel Contact Person'),
	position: yup
		.string()
		.label(POSITION)
		.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
		.nullable()
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	department: yup
		.string()
		.label(DEPARTMENT)
		.max(MAX_CHAR_255)
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	email,
	telNo,
	mobileNo,
}));

export const defaultContactDetailsValue =
	ChannelContactDetailsEntrySchema.schema.getDefault();

export const ChannelContactDetailsSchema = yup
	.array()
	.of(ChannelContactDetailsEntrySchema.schema)
	.default([
		{
			mobileNo: [],
			email: [],
		},
	]);

export const channelContactDetailsValues = ChannelContactDetailsSchema;
export type PrimartyInfoType = ReturnType<
	(typeof ChannelContactDetailsSchema)['cast']
>;
