import React, { useState } from 'react'
import moment from 'moment';
import styles from 'containers/ChannelManagement/Terminal/Tab/style.module.css'
import TerminalForm from 'containers/ChannelManagement/Terminal/Form/TerminalForm';
import ConfirmationModal from 'containers/ChannelManagement/Terminal/Modal/ConfirmationModal';
import TerminalStatusSelect from 'containers/ChannelManagement/Terminal/SelectField/TerminalStatusSelect'
import { useToggle } from 'utils/hooks';
import { ReactComponent as EditIcon } from 'assets/icons/ic-edit.svg';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks'
import { Button } from '@salesforce/design-system-react/module/components';
import { TerminalDetailsProps } from 'containers/ChannelManagement/Terminal/types';

const TerminalDetails: React.FC<TerminalDetailsProps> = ({
    refetchTerminal,
}) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(false)

    const {
        terminalFormValues,
        terminalInfoPayload,
        handleChange
    } = useTerminal()

    const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

    const handleExitMode = () => {
        setIsDisabled(!isDisabled)
        hideConfirmModal()
    }

    const confirmModalBodyText = (
        <>
        {terminalFormValues?.data?.spmNumber === terminalInfoPayload?.spmNumber &&
        terminalFormValues?.data?.activationDate === terminalInfoPayload?.activationDate &&
        terminalFormValues?.data?.channelTransactionType === terminalInfoPayload?.channelTransactionType &&
        terminalFormValues?.data?.channelType === terminalInfoPayload?.channelType &&
        terminalFormValues?.data?.channelTypeId === terminalInfoPayload?.channelTypeId &&
        terminalFormValues?.data?.posType === terminalInfoPayload?.posType &&
        terminalFormValues?.data?.meralcoPaymentCenterId === terminalInfoPayload?.meralcoPaymentCenterId &&
        terminalFormValues?.data?.remarks === terminalInfoPayload?.remarks
        ? (
            <>
                <br /><br />Are you sure you want to exit edit mode?
            </>
        )
        : (
            <>
                <br />Are you sure you want to exit without saving?<br />
                Any unsaved work will be lost.
            </>
        )}
        </>
    )

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.leftPanel}>
                        <div className={styles.title}>
                            {terminalFormValues?.data?.tpaId ? terminalFormValues?.data?.tpaId : 'TPAID'}
                        </div>
                        <div className={styles.status}>
                            <div className={styles.statusLabel}>Terminal Status:</div>
                            <div className={styles.statusField}>
                                <TerminalStatusSelect
                                    value={terminalFormValues?.data?.status}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                    {Object.keys(terminalFormValues).length !== 0 && (
                        <div className={styles.rightPanel}>
                            <div className={styles.edit}>
                                <Button
                                    className={styles.linkBtn + ' ' + styles.edit}
                                    onClick={() => {
                                        !isDisabled ? setIsDisabled(!isDisabled) : showConfirmModal()
                                    }}
                                >
                                    <EditIcon />
                                    {!isDisabled ? ' Edit Terminal Details' : 'Exit Edit Mode'}
                                </Button>
                            </div>
                            <div className={styles.dates}>
                                <em className={styles.dateText}>
                                    {'Last updated at ' + moment.utc(terminalFormValues?.data?.updatedAt).format('MM/DD/YYYY hh:mm:ss A')}
                                    {' by ' + terminalFormValues?.data?.updatedBy}
                                </em>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.body}>
                    <TerminalForm
                        terminalInfoPayload={terminalInfoPayload}
                        terminalFormValues={terminalFormValues}
                        onChange={handleChange}
                        disabled={!isDisabled}
                        refetchTerminal={refetchTerminal}
                    />
                </div>
            </div>
            <ConfirmationModal
                open={isConfirmModalShowing}
                onClose={hideConfirmModal}
                heading='Exit Edit Mode?'
                body={confirmModalBodyText}
                cancelButton='Cancel'
                submitButton='Confirm'
                handleSubmit={handleExitMode}
            />
        </>
    )
}

export default TerminalDetails