import styles from './DatePickerField.module.css';

import { Control, Controller } from 'react-hook-form';
import SLDSDatepicker from 'components/Inputs/Datepicker/SLDSDatepicker';
import cx from 'classnames';
import moment from 'moment';
import { DATE_FORMAT_ISO_8601 } from 'utils/common';

type Props = {
	name: string;
	label: string;
	control?: Control<any>;
	placeholder?: string | null;
	disabled?: boolean;
	shouldUnregister?: boolean;
	required?: boolean;
	className?: any;
};

const DatePickerField: React.FC<Props> = ({
	label,
	name,
	control,
	placeholder,
	shouldUnregister = false,
	required,
	className = '',
	...rest
}) => {
	return (
		<>
			<Controller
				name={name}
				control={control}
				shouldUnregister={shouldUnregister}
				render={({
					field: { value: v, onChange, ...fieldRest },
					fieldState: { error },
				}) => {
					const formattedValue =
						v &&
						(moment(v, 'MM/DD/YYYY', true).isValid() ||
							moment(v, 'YYYY-MM-DD', true).isValid())
							? moment(v).format('MM/DD/YYYY')
							: '';
					return (
						<div>
							<SLDSDatepicker
								className={cx({ [styles.errorDateField]: !!error }, className)}
								value={formattedValue}
								{...fieldRest}
								{...rest}
								placeholder={placeholder || label}
								label={
									<label>
										{label}
										{required && (
											<span style={{ color: '#d93025', marginLeft: '0.1vw' }}>
												*
											</span>
										)}
									</label>
								}
								controlled
								onBlur={(event) => {
									event.stopPropagation();
									onChange(event.currentTarget.value);
								}}
								onChange={onChange}
							/>

							{error && (
								<div
									className={cx({
										'slds-has-error': !!error,
									})}
								>
									<div
										className={cx(styles.errorText, 'slds-form-element__help')}
									>
										{error?.message}
									</div>
								</div>
							)}
						</div>
					);
				}}
			/>
		</>
	);
};

export default DatePickerField;
