import React from 'react';
import styles from './Switch.module.css';

type SwitchProps = {
	name: string;
	checked: boolean;
	onChange: (data: boolean) => void;
};

const Switch: React.FC<SwitchProps> = ({
	name,
	checked,
	onChange,
}) => {
	return (
		<div className={styles.switchContainer}>
			<label className={styles.toggleSwitchLabel} htmlFor={name}>
				<input
					className={styles.toggleSwitchCheckbox}
					id={name}
					type="checkbox"
					checked={checked}
					onChange={(e) => onChange(e.target.checked)}
				/>
				<span className={styles.toggleSwitchButton} />
			</label>
		</div>
	);
};

export default Switch;
