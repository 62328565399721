import React, { useState } from 'react';
import ErrorModal, { ErrorModalBody } from 'components/Modal/ErrorModal';
import SuccessModal, { SuccessModalBody } from 'components/Modal/SuccessModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import styles from 'containers/ChannelManagement/Terminal/style.module.css';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import TerminalForm from 'containers/ChannelManagement/Terminal/Form/TerminalForm';
import ConfirmationModal from 'containers/ChannelManagement/Terminal/Modal/ConfirmationModal';
import { useToggle } from 'utils/hooks';
import { useList } from 'containers/ChannelManagement/List/hooks';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks'
import { Modal } from '@salesforce/design-system-react/module/components';
import { ReactComponent as SaveDraftIcon } from 'assets/icons/ic-save.svg';
import { TerminalModalProps } from 'containers/ChannelManagement/Terminal/types';
import { UseSubmitTerminal, UseDraftTerminal } from 'containers/ChannelManagement/Terminal/query'


const TerminalModal: React.FC<TerminalModalProps> = ({
    open,
    onClose = () => {},
}) => {
    const {
        value: isLoading,
        valueOn: showLoading,
        valueOff: hideLoading,
    } = useToggle();

    const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

    const {
		value: isConfirmDraftShowing,
		valueOn: showConfirmDraft,
		valueOff: hideConfirmDraft,
	} = useToggle();

    const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

    const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

    const {
        terminalListQuery,
    } = useList()

    const {
        refetch: refetchTerminalList,
    } = terminalListQuery

    const {
        terminalInfoPayload,
        handleChange
    } = useTerminal()

    const [tabStatus, setTabStatus] = useState({
		primaryInformation: { disabled: false, finished: false },
		contractDetails: { disabled: true, finished: false },
	})

    const handleSubmit = async () => {
        showLoading()
        await UseSubmitTerminal(terminalInfoPayload)
            .then(() => {
                hideLoading()
                refetchTerminalList && refetchTerminalList()
                showSuccessModal()
            })
            .catch(() => {
                hideLoading()
                showErrorModal()
            })
    }

    const handleDraft = async () => {
        showLoading()
        await UseDraftTerminal(terminalInfoPayload)
            .then(() => {
                hideLoading()
                showSuccessModal()
            })
            .catch(() => {
                hideLoading()
                showErrorModal()
            })
    }

    const heading = (
        <div className={styles.header}>
        <div className={styles.titleLeftPanel}>
            <div className={styles.titleTextContainer}>
                {'Add New Terminal'}
            </div>
        </div>
        <div className={styles.titleRightPanel}>
            <div className={styles.titleActionBtnContainer}>
                <OutlineButton
                    className={styles.btn}
                    onClick={() => showConfirmDraft()}
                >
                    <SaveDraftIcon className={styles.btnIcon}/>
                    Save as Draft
                </OutlineButton>
            </div>
            <div className={styles.titleActionTextContainer}>
                <em className={styles.titleActionText}>
                    {'Last updated at ' + 'TODO'}
                    {' by ' + 'TODO'}
                </em>
            </div>
        </div>
        </div>
    )
    
    const footer = (
        <div className={styles.footer}>
            <div className={styles.footerRightPanel}>
                <PrimaryButton
                    onClick={() => handleSubmit()}
                    className={styles.btn}
                >
                    Submit
                </PrimaryButton>
            </div>
        </div>
    )

    const handleConfirmClose = () => {
		hideConfirmModal()
		onClose()
	};

    const handleSuccessButton = () => {
        hideSuccessModal()
        hideConfirmModal()
        hideConfirmDraft()
        onClose()
    }

    const confirmModalBodyText = (
        <>
            <br />You can continue your progress by saving this as draft,<br />
            otherwise you may need to create a new registration.<br />
            Would you like to save as draft?
        </>
    )

    const confirmDraftBodyText = (
        <>
            <br />Would you like to save this as draft?
        </>
    )

    const successSubmitBodyText = (
        <>
            <div className={styles.subtext}>
                Your work was saved as draft. You may continue<br />
                your progress anytime by going to <b>Drafts</b><br />
                add selecting the partner under the draft list.<br /><br /><br />
            </div>
            <PrimaryButton
                className={styles.successBtn}
                onClick={handleSuccessButton}
            >
                Done
            </PrimaryButton>
        </>
    )

    const successSaveBodyText = (
        <>
            <div className={styles.subtext}>
                You have created a new terminal.<br /><br /><br /><br /><br />
            </div>
            <PrimaryButton
                className={styles.successBtn}
                onClick={handleSuccessButton}
            >
                Okay
            </PrimaryButton>
        </>
    )

    const successModalBodyText = (
        <>
            <div className={styles.confirmationHeader}>Success!</div>
            {
                isConfirmModalShowing || isConfirmDraftShowing
                ? successSubmitBodyText
                : isSuccessModalOpen
                ? successSaveBodyText
                : ''
            }
        </>
    )

    const errorModalBodyText = (
        <>
            <div className={styles.confirmationHeader}>Timeout Error!</div>
            <div className={styles.subtext}>
                A problem occurred while saving as draft.<br />
                Please try again.<br /><br /><br /><br />
            </div>
            <PrimaryButton
                className={styles.successBtn}
                onClick={hideErrorModal}
            >
                Retry
            </PrimaryButton>
        </>
    )

    return (
        <>
            <Modal
                isOpen={open}
                onRequestClose={() =>
					tabStatus?.primaryInformation?.finished
						? onClose()
						: showConfirmModal()
                }
                headerClassName={styles.headerContainer}
                size="medium"
                contentClassName={styles.modal}
                heading={heading}
                footer={footer}
            >
                <div className={styles.body}>
                    <TerminalForm
                        terminalInfoPayload={terminalInfoPayload}
                        onChange={handleChange}
                    />
				</div>
            </Modal>
            <ConfirmationModal
                open={isConfirmModalShowing}
                onClose={handleConfirmClose}
                heading='Are you done?'
                body={confirmModalBodyText}
                cancelButton='No'
                submitButton='Yes, save as draft'
                handleSubmit={handleDraft}
            />
            <ConfirmationModal
                open={isConfirmDraftShowing}
                onClose={hideConfirmDraft}
                heading='Save as Draft'
                body={confirmDraftBodyText}
                cancelButton='Back'
                submitButton='Yes'
                handleSubmit={handleDraft}
            />
            <SuccessModal
                open={isSuccessModalOpen}
                onClose={hideSuccessModal}
            >
                <SuccessModalBody>
                    {successModalBodyText}
                </SuccessModalBody>
            </SuccessModal>
            <ErrorModal
				open={isErrorModalOpen}
				onClose={hideErrorModal}
			>
				<ErrorModalBody>
                    {errorModalBodyText}
				</ErrorModalBody>
			</ErrorModal>
            <FullPageLoader open={isLoading} message={'Please wait'} />
        </>
    )
}

export default TerminalModal