import cx from 'classnames';
import PrimaryCard from 'components/Cards/PrimaryCard/PrimaryCard';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ScrollableList from 'containers/ChannelManagement/List/ScrollableList';
import { useGetChannelList } from 'containers/ChannelManagement/List/query';
import {
	ChannelManagementListProps,
	Filter,
} from 'containers/ChannelManagement/List/types';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	activeType,
	branchListFilter,
	channelListFilter,
	selectChannel,
	selectedBranch,
	selectedChannel,
	selectedTerminal,
	setBranchFormValues,
	terminalListFilter,
} from 'redux/modules/channelList';
import { clearFilter } from 'redux/modules/products';
import { useList } from './hooks';

const ChannelManagementList: React.FC<ChannelManagementListProps> = ({
	addNewChannelButtonClick,
	addNewBranchButtonClick,
	addNewTerminalButtonClick,
}) => {
	const initialFilterFields: Filter = {
		typeItemChecked: [],
		searchFilter: '',
		statusItemChecked: [],
	};

	const [allChannels, setAllChannels] = useState<{ data: any[] }>({ data: [] });
	const [allBranches, setAllBranches] = useState<{ data: any[] }>({ data: [] });
	const [allTerminals, setAllTerminals] = useState<{ data: any[] }>({
		data: [],
	});
	const [filterFields, setFilterFields] = useState<Filter>(initialFilterFields);
	const [selectedChannelName, setSelectedChannelName] = useState<string>();
	const [selectedBranchName, setSelectedBranchName] = useState<string>();
	const [selectedTerminalName, setSelectedTerminalName] = useState<string>();
	const [showChannelList, setShowChannelList] = useState<boolean>(true);
	const [showBranchList, setShowBranchList] = useState<boolean>(false);
	const [showTerminalList, setShowTerminalList] = useState<boolean>(false);
	const dispatch = useDispatch();

	const pageParam = {
		[showChannelList ? 'search' : showBranchList ? 'name' : 'tpaId']:
			filterFields.searchFilter ? filterFields.searchFilter : '',
		category: filterFields.typeItemChecked
			? filterFields.typeItemChecked.join('|')
			: '',
		status: filterFields.statusItemChecked
			? filterFields.statusItemChecked.join('|')
			: '',
	};

	const {
		channelTypeFilter,
		channelDetails,
		branchDetails,
		channelFilter,
		branchFilter,
		terminalFilter,
		branchListQuery,
		terminalListQuery,
	} = useList();

	const filterDebounce = debounce((value) => {
		setFilterFields(value);
	}, 700);

	const {
		data: channelList,
		isLoading: isChannelLoading,
		isSuccess: isChannelSuccess,
		refetch: refetchChannel,
	} = useGetChannelList(channelFilter);

	useEffect(() => {
		refetchChannel();
		if (isChannelSuccess) {
			setAllChannels(channelList);
		}
	}, [isChannelSuccess, filterFields]);

	useEffect(() => {
		if (branchListQuery.isSuccess) {
			setAllBranches(branchListQuery.data);
		}
	}, [branchListQuery.isSuccess, channelDetails.id, branchFilter]);

	useEffect(() => {
		if (terminalListQuery.isSuccess) {
			setAllTerminals(terminalListQuery.data);
		}
	}, [terminalListQuery.isSuccess, branchDetails.id, terminalFilter]);

	const scrollableListProps = useMemo(
		() => ({
			primaryButtonOnClick: showChannelList
				? addNewChannelButtonClick
				: showBranchList
				? addNewBranchButtonClick
				: addNewTerminalButtonClick,
			primaryButtonTitle: showChannelList
				? 'Add New Channel'
				: showBranchList
				? 'Add New Branch'
				: 'Add New Terminal',
			listTitle: showChannelList
				? 'Channel List'
				: showBranchList
				? 'Branch List'
				: 'Terminal List',
			dropdownOptions: channelTypeFilter,
			onFilterChange: (value: Filter) => filterDebounce(value),
			searchPlaceholder: showChannelList
				? 'Search Channel Name'
				: showBranchList
				? 'Search Branch Name'
				: 'Search Terminal Name',
			clearFilter: () => {
				setFilterFields(initialFilterFields);
			},
			selectedChannelName: selectedChannelName,
			selectedBranchName: selectedBranchName,
			selectedTerminalName: selectedTerminalName,
			setSelectedChannelName: setSelectedChannelName,
			setSelectedBranchName: setSelectedBranchName,
			setSelectedTerminalName: setSelectedTerminalName,
			setShowChannel: setShowChannelList,
			setShowBranch: setShowBranchList,
			setShowTerminal: setShowTerminalList,
		}),
		[
			clearFilter,
			channelTypeFilter,
			selectedChannelName,
			selectedBranchName,
			selectedTerminalName,
		]
	);

	const noListAvailable = (desc: string) => (
		<span
			className={cx(
				'slds-text-body_small slds-align_absolute-center slds-m-around_medium'
			)}
		>
			{desc}
		</span>
	);

	return (
		<ScrollableList {...scrollableListProps}>
			<FullPageLoader
				open={
					isChannelLoading ||
					terminalListQuery.isLoading ||
					branchListQuery.isLoading
				}
				message={'Loading'}
			/>
			{allChannels.data.length > 0 && showChannelList
				? allChannels.data.map((channel: any, index: number) => (
						<PrimaryCard
							key={channel.id}
							name={channel.name}
							logo={channel.logo}
							status={channel.status}
							isSelected={channel.isSelected}
							onClick={() => {
								selectChannel({ selected: channel.id, cnname: channel.name });
								dispatch(
									channelListFilter({
										channelFilter: {
											search: '',
											category: '',
											status: '',
										},
									})
								);
								dispatch(selectedChannel({ channel }));
								dispatch(selectedBranch({ branch: {} }));
								dispatch(setBranchFormValues({}));
								dispatch(activeType({ type: 'channel' }));
								setSelectedChannelName(channel.name);
								setShowChannelList(false);
								setShowBranchList(true);
							}}
						/>
				  ))
				: allChannels.data.length <= 0
				? noListAvailable('No channel available')
				: null}
			{allBranches?.data.length > 0 && showBranchList
				? allBranches?.data.map((branch: any) => (
						<PrimaryCard
							key={branch.id}
							name={branch.name}
							logo={''}
							status={branch.status}
							onClick={() => {
								dispatch(
									branchListFilter({
										branchFilter: {
											name: '',
											posType: '',
											storeType: '',
											storeFormat: '',
											machineLocation: '',
										},
									})
								);
								dispatch(selectedBranch({ branch }));
								dispatch(selectedTerminal({ terminal: {} }));
								dispatch(activeType({ type: 'branch' }));
								setSelectedBranchName(branch.name);
								setShowBranchList(false);
								setShowTerminalList(true);
							}}
						/>
				  ))
				: showBranchList
				? noListAvailable('No branch available')
				: null}
			{allTerminals?.data.length > 0 && showTerminalList
				? allTerminals?.data.map((terminal: any) => (
						<PrimaryCard
							key={terminal.id}
							name={terminal.tpaId}
							logo={''}
							status={terminal.status}
							onClick={() => {
								dispatch(
									terminalListFilter({
										terminalFilter: {
											tpaId: '',
										},
									})
								);
								dispatch(selectedTerminal({ terminal }));
								dispatch(activeType({ type: 'terminal' }));
								setSelectedTerminalName(terminal.tpaId);
							}}
						/>
				  ))
				: showTerminalList
				? noListAvailable('No terminal available')
				: null}
		</ScrollableList>
	);
};

export default ChannelManagementList;
