import yup from "utils/formSchemas/common";
import { collectionServiceAgreementSchema } from "./CollectionServiceAgreement/CollectionServiceAgreementSchema";
import { collectionDepositoryAccountSchema } from "components/CollectionDepositoryAccount/CollectionDepositoryAccountScema";
import { termsAndDurationSchema } from "./TermsAndDuration/TermsAndDurationSchema";
import { valueAddedTaxSchema } from "./ValueAddedTax/ValueAddedTaxSchema";
import { channelAccreditationSchema } from "./AccreditationRequirements/AccreditationRequirementsSchema";
import { YupLab } from "utils/yupLab";

const wrapCollectionServiceAgreementSchema = new YupLab(collectionServiceAgreementSchema)

const wrapCollectionSchema = new YupLab(collectionDepositoryAccountSchema)
const defaultCollectionValue = wrapCollectionSchema.objectSchema.getDefaultFromShape();
const wrapCollectionSchemaArray = new YupLab(
    yup
        .array()
        .of(wrapCollectionSchema.objectSchema)
        .default([defaultCollectionValue])
)
const wrapTermsAndDurationSchema = new YupLab(termsAndDurationSchema)

const wrapValueAddedTax = new YupLab(valueAddedTaxSchema)

const wrapChannelAccreditationSchema = new YupLab(channelAccreditationSchema)

export const ContractDetailsSchema = yup.object({}).shape({
    collectionServiceAgreement: wrapCollectionServiceAgreementSchema.schema,
    cashCollection: wrapCollectionSchemaArray.arraySchema,
    checkCollection: wrapCollectionSchemaArray.arraySchema,
    termsAndDuration: wrapTermsAndDurationSchema.schema,
    valueAddedTax: wrapValueAddedTax.schema,
    channelAccreditation: wrapChannelAccreditationSchema.schema,
});

export const warpContractDetailsSchema = new YupLab(ContractDetailsSchema)
export type ContractDetailsType = ReturnType<typeof warpContractDetailsSchema.schema.cast>