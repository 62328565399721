import React, { useState, useEffect, useRef } from 'react';
import { startCase } from 'lodash';

import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ExpandedSection from 'components/CWSTable/ExpandableRow/ExpandedSection';

import client from 'helpers/ApiClient';
import { useErrorModal, useLoader, useTablePagination } from 'utils/hooks';
import { formatParams } from 'utils/common';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import ChannelDetailsTable from './ChannelDetailsTable';
import ActionCell from './ActionCell';
import ExpandableRow from './ExpandableRow';

import {
	ListBranchResp,
	Branch,
	ChannelBranch,
	PaginationOptions,
	TableRef,
} from '../types';
import { BFA_STATUS_LABEL } from '../constants';
import styles from './ChannelDetailsBody.module.css';

const TABLE_WIDTH = 90 / 3;

const BRANCH_TPA_COLUMNS = (channelId, fetchBranches, width) => {
	return [
		{
			Header: '',
			id: 'expandButton',
			width: '10%',
			Cell: ({ row }) => {
				return (
					<ExpandedSection
						disabled={!row?.original?.totalTerminals}
						styles={styles}
						className={styles.expandButtonIcon}
					>
						<ExpandableRow
							tabName="branchTpaId"
							channelId={channelId}
							branchId={row?.original.id}
							branchName={row?.values.name}
							data={row?.original?.terminals}
							toggleActiveValue="Active"
							refetchTable={fetchBranches}
							width={width}
						/>
					</ExpandedSection>
				);
			},
		},
		{
			Header: 'Branch/TPAID',
			id: 'name',
			width: `${TABLE_WIDTH}%`,
			sortable: true,
			Cell: ({ value }) => <div className={styles.idCell}>{value}</div>,
		},
		{
			Header: 'BFA Status',
			id: 'bfaStatus',
			width: `${TABLE_WIDTH}%`,
			sortable: true,
			Cell: ({ value }) => (
				<div
					className={
						value === 'ACTIVE' ? styles.statusEnabled : styles.statusDisabled
					}
				>
					{startCase(BFA_STATUS_LABEL[value])}
				</div>
			),
		},
		{
			Header: 'Actions',
			id: 'actions',
			width: `${TABLE_WIDTH}%`,
			Cell: ({ row }) => (
				<ActionCell
					name={row?.values.name}
					initialToggleValue={row?.values.bfaStatus === 'ACTIVE'}
					channelId={channelId}
					branchId={row?.original.id}
					branchName={row?.values.name}
					confirmModalContents={{
						header: 'Branch',
						bodyHeader: row?.values.name,
					}}
					refetchTable={fetchBranches}
				/>
			),
		},
	];
};

const ChannelBranchTpa: React.FC<ChannelBranch> = ({
	channelId,
	isRefetchData,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [branches, setBranches] = useState<Branch>();
	const [filter, setFilter] = useState<PaginationOptions>({});
	const [width, setWidth] = useState<number>(0);

	const tableReference = useRef<TableRef>(null);

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const fetchBranches = async ({ channelId, params }) => {
		hideErrorModal();
		if (!isRefetchData) showLoadingMessage();

		try {
			const response: ListBranchResp = await client.get(
				`/v2/bfa-admin-portal/channels/${channelId}/branches`,
				{ params }
			);
			if (response?.error) {
				throw new Error(response?.error?.message);
			}

			setBranches(response?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchBranches({ channelId, params })
			);
		} finally {
			hideLoading();
		}
	};

	const handleTableFetch = (params) => {
		const newFilter = formatParams({ ...filter, ...params });
		setFilter(newFilter);
		fetchBranches({ channelId, params: newFilter });
	};

	const tablePaginationProps = useTablePagination(
		handleTableFetch,
		branches?.meta
	);

	const tableProps = {
		columns: BRANCH_TPA_COLUMNS(channelId, handleTableFetch, width),
		data: branches?.data || [],
		className: styles.tableContainerTabContent,
		isAllRowColorWhite: true,
		ref: tableReference,
		isOverflowHidden: true,
		...tablePaginationProps,
	};

	const filterProps = {
		onSearch: handleTableFetch,
		padding: '0 0 1vw 0',
	};

	useEffect(() => {
		if (hasUserPermission('list.branch')) {
			fetchBranches({
				channelId,
				params: {},
			});
		}
	}, []);

	useEffect(() => {
		if (isRefetchData) {
			fetchBranches({
				channelId,
				params: {},
			});
		}
	}, [channelId, isRefetchData]);

	// This is solely intended to obtain the table width for aligning it with the width of the expandedSection component.
	// This will update width when the screen has changed and set to expandedSection > HeadExpandableRow for component resizing.
	useEffect(() => {
		const updateParentWidth = () => {
			if (tableReference.current) {
				setWidth(tableReference.current.clientWidth);
			}
		};

		window.addEventListener('resize', updateParentWidth);
		updateParentWidth();

		return () => {
			window.removeEventListener('resize', updateParentWidth);
		};
	}, []);

	return (
		<div>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ChannelDetailsTable tableProps={tableProps} filterProps={filterProps} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</div>
	);
};
export default ChannelBranchTpa;
