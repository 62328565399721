import Grid from 'components/Grid/Grid';
import CheckboxGroup from 'components/Inputs/CheckboxGroup/CheckboxGroup';
import Label from 'components/Inputs/Label/Label';
import TextField from 'components/Inputs/TextField/TextField';
import Section, { SectionRow } from 'components/Section/Section';
import { useMemo } from 'react';
import {
	Control,
	UseFormSetValue,
	UseFormSetError,
	UseFormGetValues,
	Path,
} from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { BusinessRulesFormData } from '../BusinessRules';
import { FixMeLater } from 'types';
import styles from './RequiredDocuments.module.css';

type Props<T extends BusinessRulesFormData> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
	setError: UseFormSetError<T>;
	getValues: UseFormGetValues<T>;
};

const required_document = [
	{
		label: 'Statement of Account (SOA)',
		value: 'SOA',
	},
	{
		label: 'SOA/Short Message Servuce (SMS) and Bayad Form',
		value: 'SOA_SMS_BAYAD_FORM',
	},
	{
		label: 'Online/Digital',
		value: 'ONLINE',
	},
	{
		label: 'Bayad Form',
		value: 'BAYAD_FORM',
	},
	{
		label: 'SMS',
		value: 'SMS',
	},
	{
		label: 'Other Forms',
		value: 'OTHER',
	},
];

const RequiredDocuments = <T extends BusinessRulesFormData>({
	control,
	disabled,
	setValue,
}: Props<T>): JSX.Element => {
	const name = 'product_business_rule';

	const requiredDocuments = useWatch({
		control,
		name: `${name}.required_documents` as Path<T>,
	});

	const showOtherForms = useMemo(() => {
		if (!requiredDocuments) return false;
		if (!Array.isArray(requiredDocuments)) return false;
		return (requiredDocuments as string[]).includes('OTHER');
	}, [requiredDocuments]);

	return (
		<Section
			title={
				<Label required>
					<span className={styles.sectionTitle}>Required Documents</span>
				</Label>
			}
		>
			<SectionRow>
				<Grid column size={1} of={1}>
					<CheckboxGroup
						showLabel={false}
						parentName={name}
						name={`${name}.required_documents`}
						label="Documents"
						control={control}
						options={required_document}
						displayAll={true}
						disabled={disabled}
						fitHorizontally={true}
						setValue={setValue}
						onChange={(val) => {
							if (!(Array.isArray(val) && val.includes('OTHER'))) {
								setValue(
									`${name}.other_required_documents` as Path<T>,
									'' as FixMeLater
								);
							}
						}}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}></Grid>
				<Grid column size={1} of={3}></Grid>
				<Grid column size={1} of={3}>
					{showOtherForms && (
						<TextField
							label=""
							name={`${name}.other_required_documents`}
							control={control}
							placeholder="Other Forms"
							disabled={disabled}
							shouldUnregister
						/>
					)}
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default RequiredDocuments;
