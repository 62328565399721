import React, { useEffect, useState } from 'react'
import Section, { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import SelectField from 'components/Inputs/SelectField/SelectField';
import DatePickerField from 'components/Inputs/Datepicker/DatePickerField'
import styles from 'containers/ChannelManagement/Terminal/style.module.css';
import { useFormContext, Controller } from "react-hook-form";
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import { TerminalDetails } from 'containers/ChannelManagement/Terminal/types';
import { TernimalInformationProps } from 'containers/ChannelManagement/Terminal/types';
import {
    MachineLocation,
    ChannelTransactionType,
    ChannelTypePhysical,
    ChannelTypeDigital,
    PosType,
    SettlementSetup,
    MeralcoPaymentCenterCode
} from 'utils/lookup'
import moment from 'moment';

const TerminalInformation: React.FC<TernimalInformationProps> = ({
    terminalFormValues,
    terminalInfoPayload,
    onChange,
    disabled,
}) => {
    const { control, watch, setValue } = useFormContext<TerminalDetails>()
    const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(false)

    const {
        channelFormValues,
        branchFormValues,
    } = useTerminal()

    const {
        spmNumber,
        activationDate,
        channelTransactionType,
        channelType,
        posType,
        meralcoPaymentCenterId,
        remarks
    } = watch()

    useEffect(() => {
        onChange && onChange('spmNumber', spmNumber)
        onChange && onChange('activationDate', activationDate ? moment(activationDate).format('YYYY-MM-DD') : activationDate)
        onChange && onChange('channelTransactionType', channelTransactionType)
        onChange && onChange('channelTypeId', channelType)
        onChange && onChange('channelType', [...ChannelTypeDigital, ...ChannelTypePhysical].find(option => option.value === channelType)?.label)
        onChange && onChange('posType', posType)
        onChange && onChange('meralcoPaymentCenterId', meralcoPaymentCenterId)
        onChange && onChange('remarks', remarks)
        if (remarks && remarks.length > 1000) {
            setIsRemarksEmpty(true)
        } else {
            setIsRemarksEmpty(false)
        }
    }, [watch,
        spmNumber,
        activationDate,
        channelTransactionType,
        channelType,
        meralcoPaymentCenterId,
        remarks])

    useEffect(() => {
        setValue('channelAccountName', terminalFormValues?.data?.channelAccountName ?? channelFormValues?.primaryInfo?.channelPrimaryInfo?.name)
        setValue('channelAccountCode', terminalFormValues?.data?.channelAccountCode ?? channelFormValues?.primaryInfo?.channelPrimaryInfo?.tpaAccount)
        setValue('branchName', terminalFormValues?.data?.branchName ?? branchFormValues?.data?.name)
        setValue('branchCode', terminalFormValues?.data?.branchCode ?? branchFormValues?.data?.branchCode)
        setValue('tpaId', terminalFormValues?.data?.tpaId)
        setValue('machineLocation', terminalFormValues?.data?.machineLocation ?? branchFormValues?.data?.branchPrimaryInfos?.machineLocation)
        setValue('spmNumber', terminalFormValues?.data?.spmNumber)
        setValue('activationDate', terminalFormValues?.data?.activationDate)
        setValue('channelTransactionType', terminalFormValues?.data?.channelTransactionType ?? channelFormValues?.primaryInfo?.channelPrimaryInfo?.channelTransactionType)
        setValue('channelType', terminalFormValues?.data?.channelTypeId ?? channelFormValues?.primaryInfo?.channelPrimaryInfo?.channelType)
        setValue('posType', terminalFormValues?.data?.posType ?? branchFormValues?.data?.branchPrimaryInfos?.posType)
        setValue('settlementSetup', terminalFormValues?.data?.settlementSetup ?? channelFormValues?.primaryInfo?.channelPrimaryInfo?.settlementSetup)
        setValue('meralcoPaymentCenterId', terminalFormValues?.data?.meralcoPaymentCenterId ?? branchFormValues?.data?.branchPrimaryInfos?.meralcoPaymentCenterId)
        setValue('remarks', terminalFormValues?.data?.remarks)
    }, [watch, channelFormValues, branchFormValues, terminalFormValues, disabled])

    const handleMachineLocation = (value) => {
        onChange && onChange('machineLocation', value)
    }

    const handleChannelTransactionType = (value) => {
        onChange && onChange('channelTransactionType', value)
        setValue('channelType',
            value === terminalFormValues?.data?.channelTransactionType
            ? terminalFormValues?.data?.channelTypeId
            : channelFormValues?.primaryInfo?.channelPrimaryInfo?.channelType)
        onChange && onChange('channelType',
            value === terminalFormValues?.data?.channelTransactionType
            ? [...ChannelTypeDigital, ...ChannelTypePhysical].find(option => option.value === terminalFormValues?.data?.channelTypeId)?.label
            : [...ChannelTypeDigital, ...ChannelTypePhysical].find(option => option.value === channelFormValues?.primaryInfo?.channelPrimaryInfo?.channelType)?.label)
        onChange && onChange('channelTypeId',
            value === terminalFormValues?.data?.channelTransactionType
            ? terminalFormValues?.data?.channelTypeId
            : channelFormValues?.primaryInfo?.channelPrimaryInfo?.channelType)
        setValue('posType',
            value === terminalFormValues?.data?.channelTransactionType
            ? terminalFormValues?.data?.posType
            : branchFormValues?.data?.branchPrimaryInfos?.posType)
        onChange && onChange('posType',
            value === terminalFormValues?.data?.channelTransactionType
            ? terminalFormValues?.data?.posType
            : '')
    }

    const handleChannelType = (value) => {
        onChange && onChange('channelType', [...ChannelTypeDigital, ...ChannelTypePhysical].find(option => option.value === value)?.label)
        onChange && onChange('channelTypeId', value)
    }

    const handlePosType = (value) => {
        onChange && onChange('posType', value)
    }

    const handleSettlementSetup = (value) => {
        onChange && onChange('settlementSetup', value)
    }

    const handleMeralcoPaymentCenterId = (value) => {
        onChange && onChange('meralcoPaymentCenterId', value)
    }

    return (
        <Section title="Terminal Information">
            <SectionRow>
                <Grid column size={2} of={3}>
                    <TextField
                        label="Channel Account Name"
                        name="channelAccountName"
                        control={control}
                        disabled={disabled}
                        readOnly
                    />
                </Grid>
                <Grid column size={1} of={3}>
                    <TextField
                        label="Channel Account Code"
                        name={"channelAccountCode"}
                        control={control}
                        disabled={disabled}
                        readOnly
                    />
                </Grid>
            </SectionRow>
            <SectionRow>
                <Grid column size={1} of={3}>
                    <TextField
                        label="Branch Name"
                        name={"branchName"}
                        control={control}
                        disabled={disabled}
                        readOnly
                    />
                </Grid>
                <Grid column size={1} of={3}>
                    <TextField
                        label="Branch Code"
                        name={"branchCode"}
                        control={control}
                        disabled={disabled}
                        readOnly
                    />
                </Grid>
                <Grid column size={1} of={3}>
                    <TextField
                        required={!disabled}
                        label="TPAID"
                        name="tpaId"
                        control={control}
                        disabled={disabled}
                        readOnly
                    />
                </Grid>
            </SectionRow>
            <SectionRow>
                <Grid column size={1} of={3}>
                    <SelectField
                        label="Machine Location"
                        name="machineLocation"
                        control={control}
                        options={MachineLocation}
                        onChange={handleMachineLocation}
                        disabled
                    />
                </Grid>
                <Grid column size={1} of={3}>
                    <div className={styles.spm}>
                        <div className={styles.spmText}>
                            SPM-&nbsp;&nbsp;
                        </div>
                        <div className={styles.spmTextField}>
                            <TextField
                                required={!disabled}
                                label="SPM Number"
                                name="spmNumber"
                                control={control}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid column size={1} of={3}>
                    <DatePickerField
                        required={!disabled}
                        label="Activation Date"
                        name="activationDate"
                        control={control}
                        placeholder="MM/DD/YYYY"
                        disabled={disabled}
                    />
                </Grid>
            </SectionRow>
            <SectionRow>
                <Grid column size={1} of={3}>
                    <SelectField
                        label="Channel Transaction Type"
                        name="channelTransactionType"
                        control={control}
                        options={ChannelTransactionType}
                        onChange={handleChannelTransactionType}
                        disabled={disabled}
                    />
                </Grid>
                <Grid column size={1} of={3}>
                    <SelectField
                        label="Channel Type"
                        name="channelType"
                        control={control}
                        options={
                            terminalInfoPayload?.channelTransactionType === 'PHYSICAL'
                            ? ChannelTypePhysical
                            : ChannelTypeDigital
                        }
                        onChange={handleChannelType}
                        disabled={disabled}
                    />
                </Grid>
                <Grid column size={1} of={3}>
                    {terminalInfoPayload?.channelTransactionType === 'PHYSICAL' && (
                        <SelectField
                            required
                            label="POS Type"
                            name="posType"
                            control={control}
                            options={PosType}
                            onChange={handlePosType}
                            disabled={disabled}
                        />
                    )}
                </Grid>
            </SectionRow>
            <SectionRow>
                <Grid column size={1} of={3}>
                    <SelectField
                        label="Settlement Setup"
                        name="settlementSetup"
                        control={control}
                        options={SettlementSetup}
                        onChange={handleSettlementSetup}
                        disabled
                    />
                </Grid>
                <Grid column size={1} of={3}>
                    <SelectField
                        required={!disabled}
                        label="Meralco Payment Center Code"
                        name="meralcoPaymentCenterId"
                        control={control}
                        options={MeralcoPaymentCenterCode}
                        onChange={handleMeralcoPaymentCenterId}
                        disabled={disabled}
                    />
                </Grid>
            </SectionRow>
            <SectionRow>
                <Grid column size={3} of={3}>
                    <div>
                        <label className={'slds-form-element__label' + ' ' + styles.remarksLabel}>
                            Remarks
                        </label>
                        <div className="slds-form-element__control">
                            <Controller
                                name="remarks"
                                control={control}
                                render={({ field }) => (
                                    <textarea
                                        className={'slds-textarea' + ' ' + styles.remarks}
                                        disabled={disabled}
                                        {...field}
                                    />
                                )}
                            />
                            {isRemarksEmpty && (
								<div className={'slds-form-element__help' + ' ' + styles.remarksError}>
									<div>You can only input a max of 1000 characters.</div>
								</div>
							)}
                        </div>
                    </div>
                </Grid>
            </SectionRow>
        </Section>
    )
}

export default TerminalInformation