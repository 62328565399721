import { useState } from 'react';
import { useToggle } from 'utils/hooks';
import { useFormContext, useWatch } from 'react-hook-form';
import cx from 'classnames';
import { BsPlus } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';
import Button from 'components/Buttons/Button';
import Grid from 'components/Grid/Grid';
import Section, { SectionRow } from 'components/Section/Section';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import UploadLogoModal from 'components/UploadLogoModal/UploadLogoModal';
import {
	BusinessGroup,
	ChannelTransactionType,
	ChannelTypePhysical,
	ChannelTypeDigital,
	BusinessType,
	PosType,
	SettlementSetup,
} from 'utils/lookup';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/ChannelBasicInformation/ChannelBasicInformation.module.css';
import { UploadLogoResponse } from 'components/UploadLogoModal/UploadLogoResponse';
import { BasicInformationProps, LogoFieldProps } from './types';
import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
import Input from 'components/Channel/Input/Input';
import { PrimaryInformationSchema } from '../PrimaryInformationSchema';
import RadioGroup from 'components/Channel/Radio/RadioGroup';

const LogoField: React.FC<LogoFieldProps> = ({
	onSuccess,
	image,
	disabled,
}) => {
	const {
		value: isUploadLogoModalOpen,
		valueOn: showUploadLogoModal,
		valueOff: hideUploadLogoModal,
	} = useToggle();

	return (
		<div className={styles.logoFieldContainer}>
			<div className={cx(styles.avatar, { [styles.hasImage]: !!image })}>
				{image ? (
					<img src={image} className={styles.image} alt="biller-logo" />
				) : (
					<FaUserCircle />
				)}
				{disabled ? (
					<Button className={styles.uploadBtn}>
						<BsPlus />
					</Button>
				) : (
					<Button className={styles.uploadBtn} onClick={showUploadLogoModal}>
						<BsPlus />
					</Button>
				)}
			</div>
			<div className={styles.logoLabelContainer}>
				<div className={styles.logoLabel}>Upload Logo</div>
				<div className={styles.logoSubtext}>
					<em>*Maximum file size accepted:</em>
					<span className={styles.logoSubtextValue}>10MB</span>
				</div>
				<div className={styles.logoSubtext}>
					<em>*File types accepted:</em>
					<span className={styles.logoSubtextValue}>.jpg, .jpeg, .png</span>
				</div>
			</div>
			<UploadLogoModal
				open={isUploadLogoModalOpen}
				onClose={hideUploadLogoModal}
				onSuccess={onSuccess}
				url="/v1/channels/logo"
				action={undefined}
				selectedProductId={undefined}
			/>
		</div>
	);
};

const BasicInformation: React.FC<BasicInformationProps> = ({ disabled }) => {
	const { control, setValue } = useFormContext();

	const inCfs = useWatch({
		control,
		name: `primaryInfo.inCfs`,
	});

	const channelTransactionType = useWatch({
		control,
		name: `primaryInfo.channelTransactionType`,
	});
	const channelType = useWatch({
		control,
		name: `primaryInfo.channelType`,
	});

	const businessType = useWatch({
		control,
		name: `primaryInfo.businessType`,
	});

	const isOtherBusinessType = true;
	// businessType &&
	// !BusinessType.find(
	// 	(type) => type === businessType && 'others' !== businessType.toLowerCase()
	// );

	const channelTypeOptions: SelectOption[] =
		channelTransactionType && channelTransactionType.toLowerCase() === 'digital'
			? ChannelTypeDigital
			: ChannelTypePhysical;

	const isBusinessTypeShown = true;
	// channelType && channelType?.toLowerCase() === 'partner';

	const isOtherBusinessTypeShown = true;
	// businessType &&
	// (businessType.toLowerCase() === 'other' || isOtherBusinessType);

	const isShowThirdSection = isBusinessTypeShown;

	const [logo, setLogo] = useState('');

	const handleUploadSuccess = (data: UploadLogoResponse) => {
		const { objectKey, url, file } = data;
		setValue('basicInformation.logo', {
			objectKey,
			url,
			filename: file?.name,
		});

		setLogo(URL.createObjectURL(data.file));
	};

	const TIN = () => (
		<Grid column size={1} of={3}>
			<TextField
				label="TIN"
				disabled={disabled}
				required
				control={control}
				name="basicInformation.tin"
			/>
		</Grid>
	);

	const prefixSchemaName = 'primaryInfo.';

	return (
		<Section title="Channel Basic Information">
			<SectionRow verticalAlign="end">
				<Grid column size={1} of={3}>
					<LogoField
						onSuccess={handleUploadSuccess}
						image={logo}
						onClick={() => console.log('Upload Clicked')}
						disabled={disabled}
					/>
				</Grid>
				<Grid column size={2} of={3}>
					<Input
						name={`${prefixSchemaName}channelAccountName`}
						label={PrimaryInformationSchema.getLabel(
							`${prefixSchemaName}channelAccountName`
						)}
						required={PrimaryInformationSchema.isRequired(
							`${prefixSchemaName}channelAccountName`
						)}
						disabled={false}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<RadioGroup
						name="primaryInfo.inCfs"
						label={PrimaryInformationSchema.getLabel('primaryInfo.inCfs')}
						required={PrimaryInformationSchema.isRequired('primaryInfo.inCfs')}
						defaultValue={PrimaryInformationSchema.getDefaultValue(
							'primaryInfo.inCfs'
						)}
						disabled={false}
						options={[
							{
								label: 'Yes',
								value: '1',
							},
							{
								label: 'No',
								value: '0',
							},
						]}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<Input
						name="primaryInfo.channelAccountCode"
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.channelAccountCode'
						)}
						required={PrimaryInformationSchema.isRequired(
							'primaryInfo.channelAccountCode'
						)}
						disabled={false}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.partnershipType'
						)}
						disabled={disabled}
						control={control}
						required
						placeholder={PrimaryInformationSchema.getLabel(
							'primaryInfo.partnershipType'
						)}
						name="primaryInfo.partnershipType"
						options={BusinessGroup.map((i) => ({ label: i, value: i }))}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.businessGroup'
						)}
						disabled={disabled}
						control={control}
						required
						placeholder="Select Business Group"
						name="primaryInfo.businessGroup"
						options={BusinessGroup.map((i) => ({ label: i, value: i }))}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.channelTransactionType'
						)}
						disabled={disabled}
						control={control}
						required
						placeholder="Select Channel Transaction Type"
						name="primaryInfo.channelTransactionType"
						options={ChannelTransactionType}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel('primaryInfo.channelType')}
						// disabled={disabled || !channelTransactionType}
						control={control}
						required
						placeholder="Select Channel Type"
						name="primaryInfo.channelType"
						options={channelTypeOptions}
					/>
				</Grid>
				{/* Not Defined on Mock ups */}
				{/* {isBusinessTypeShown ? (
					<Grid column size={1} of={3}>
						<SelectField
							label="Business Type"
							disabled={disabled}
							control={control}
							required
							placeholder="Select Business Type"
							name="primaryInfo.businessType"
							defaultValue={
								disabled && isOtherBusinessType ? 'Other' : businessType
							}
							options={BusinessType.map((i) => ({ label: i, value: i }))}
						/>
					</Grid>
				) : (
					<></>
					// <TIN />
				)} */}
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel('primaryInfo.posType')}
						// disabled={disabled || !channelTransactionType}
						control={control}
						required
						placeholder="Select POS Type"
						name="primaryInfo.posType"
						options={PosType}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.settlementSetup'
						)}
						// disabled={disabled}
						control={control}
						required
						placeholder="Select Settlement Setup"
						name="primaryInfo.settlementSetup"
						options={SettlementSetup}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label={PrimaryInformationSchema.getLabel(
							'primaryInfo.businessType'
						)}
						// disabled={disabled}
						control={control}
						required
						placeholder="Select Business Type"
						name="primaryInfo.businessType"
						options={BusinessType}
					/>
				</Grid>
			</SectionRow>
			{isShowThirdSection && (
				<SectionRow>
					{isOtherBusinessTypeShown && (
						<Grid column size={1} of={3}>
							<TextField
								label="Other Business Type"
								disabled={disabled}
								required
								control={control}
								defaultValue={disabled && isOtherBusinessType && businessType}
								name="primaryInfo.otherBusinessType"
							/>
						</Grid>
					)}
				</SectionRow>
			)}
			<SectionRow>
				<Grid column size={1} of={3}>
					<Input
						name="primaryInfo.tin"
						label={PrimaryInformationSchema.getLabel('primaryInfo.tin')}
						required={PrimaryInformationSchema.isRequired('primaryInfo.tin')}
						disabled={false}
					/>
					{/* <TextField
						label="TIN"
						disabled={disabled}
						required
						control={control}
						name="basicInformation.tin"
					/> */}
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default BasicInformation;
