import React from 'react';
import { Tooltip } from '@salesforce/design-system-react';
import Switch from './Switch';
import styles from './Switch.module.css';

type SwitchProps = {
	name: string;
	checked: boolean;
	onChange: (data: boolean) => void;
	toolTipContent: string;
};

const SwitchWithTooltip: React.FC<SwitchProps> = ({
	name,
	checked,
	onChange,
	toolTipContent,
}) => {
	return (
		<Tooltip
			content={toolTipContent}
			align="bottom"
			dialogClassName={styles.tooltip}
			toolTipContent={toolTipContent}
		>
			<a>
				<Switch name={name} checked={checked} onChange={onChange}/>
			</a>
		</Tooltip>
	);
};

export default SwitchWithTooltip;
