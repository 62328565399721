import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import clientMiddleware from './middleware/clientMiddleware';
import client from '../helpers/ApiClient';
import reducers, { ReducerStateType } from './modules/reducers';
import { History } from 'history';

type Config = {
	reducer: ReducerStateType;
	middleware: (value: any) => any;
	preloadedState?: any;
};
export default function createStore(
	initialState: Partial<ReducerStateType> | null = null,
	history: History
): any {
	const config: Config = {
		reducer: reducers(history),
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().prepend(clientMiddleware(client)),
	};

	if (initialState) {
		config.preloadedState = initialState;
	}

	return configureStore(config as any);
}
