import cx from 'classnames';
import SelectField from 'components/Inputs/SelectField/SelectField';
import styles from 'containers/ChannelManagement/Terminal/SelectField/style.module.css';
import {
    TerminalStatusSelectProps,
    TerminalStatusOption
} from 'containers/ChannelManagement/Terminal/types';

const TerminalStatusSelect: React.FC<TerminalStatusSelectProps> = ({
	value,
	disabled,
	control,
	name,
	onSelect,
}) => {
    const status_option: TerminalStatusOption[] = [
        {
            label: 'Active',
            value: 'ACTIVE',
            className: cx(styles.active),
        },
        {
            label: 'On-going Integration',
            value: 'ON_GOING',
            className: cx(styles.draft),
        },
        {
            label: 'Approved for Integration',
            value: 'APPROVED',
            className: cx(styles.approved),
        },
        {
            label: 'Ready for Activation',
            value: 'READY',
            className: cx(styles.forActivation),
        },
        {
            label: 'Suspended',
            value: 'SUSPENDED',
            className: cx(styles.suspended),
        },
        {
            label: 'Deactivated',
            value: 'DEACTIVATED',
            className: cx(styles.deactivated),
        },
        {
            label: 'Terminated',
            value: 'TERMINATED',
            className: cx(styles.terminated),
        },
        {
            label: 'Rejected',
            value: 'REJECTED',
            className: cx(styles.rejected),
            isHidden: true,
        },
        {
            label: 'Draft',
            value: 'DRAFT',
            className: cx(styles.draft),
            isHidden: true,
        },
        {
            label: 'For Review',
            value: 'FOR_REVIEW',
            className: cx(styles.forReview),
            isHidden: true,
        },
    ];

	const match = status_option.find((option) => option.value == value);

	return (
		<SelectField
			className={cx(styles.select, match ? match.className : '')}
			options={status_option}
			size="x-small"
			control={control}
			name={name}
			placeholder="Terminal Status"
			buttonClassName={styles.button}
			value={value}
			onChange={(val) => {
				onSelect && onSelect(val.value);
			}}
			disabled={disabled}
		/>
	);
};

export default TerminalStatusSelect;
