export const LOCAL = 'LOCAL';
export const INTERNATIONAL = 'INTERNATIONAL';
export const COUNTRY = 'Country';
export const AREA = 'Area';
export const AREA_VALUES = ['GMA', 'NOL', 'SOL', 'VIS', 'MIN', 'Nationwide'];
export const AREA_ERROR_MSG = 'Invalid Area. Please select a valid area.';
export const AREA_LOCAL_MSG = 'Area is required when location base is local.';
export const REGION = 'Region';
export const REGION_VALUES = [
	'Region I',
	'Region II',
	'Region III',
	'Region IV-A',
	'Region IV-B',
	'Region V',
	'Region VI',
	'Region VII',
	'Region VIII',
	'Region IX',
	'Region X',
	'Region XI',
	'Region XII',
	'Region XIII',
	'CAR',
	'NCR',
	'BARMM',
];
export const REGION_ERROR_MSG = 'Invalid region. Please select a valid region.';
export const REGION_LOCAL_MSG = 'Region is required when location base is local.';
export const STATE_PROVINCE = 'State/Province';
export const MUNICIPALITY_CITY = 'Municipality/City';
export const BARANGAY = 'Barangay';
export const BUILDING_NAME_NO = 'Building Name/No';
export const STREET = 'Street';
export const ZIP_CODE = 'Zip Code';
