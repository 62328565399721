import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import ChannelDetails from 'containers/ChannelManagement/Channel/ChannelDetails/ChannelDetails';
import ChannelProductsTabComponent from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProducts';
import ChannelIntegration from 'containers/ChannelManagement/Channel/ChannelIntegration/ChannelIntegration';
import ChannelAuditTrail from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrail';
const VIEW_CHANNEL_SCOPE_NAME = 'bip.get.channel.primary.info';

const ChannelTab: React.FC = () => {
	const scopes: any = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);

	const viewChannelScope = useMemo(
		() =>
			(scopes as { scope: string }[]).find(
				({ scope }) => scope === VIEW_CHANNEL_SCOPE_NAME
			),
		[scopes]
	);
	const panelPlaceholderProps = {
		title: 'No Selected Channel',
		subtitle: 'Please select a channel.',
	};
	const primaryTabProps = {
		tabs: [
			{
				title: 'Channel Performance',
				component: <PanelPlaceholder {...panelPlaceholderProps} />,
			},
			{
				title: 'Channel Details',
				component: <ChannelDetails />,
			},
			{
				title: 'Products',
				// lazy load if not yet selected
				component:
					selectedIndex === 1 ? (
						<ChannelProductsTabComponent />
					) : (
						<PanelPlaceholder {...panelPlaceholderProps} />
					),
			},
			{
				title: 'Audit Trail',
				component: <ChannelAuditTrail initialValues={{}} {...panelPlaceholderProps} />,
			},
			{
				title: 'Channel Integration',
				component: <ChannelIntegration data={{}} 
				showConfirmationMessage={
					function (header: any, message: any, onConfirmBtnClick?: (() => void) | undefined, onClose?: (() => void) | undefined, confirmBtnLabel?: string | undefined, closeBtnLabel?: string | undefined): void {
					throw new Error('Function not implemented.');
				} }  />,
			},
		],
		defaultIndex: 0,
	};

	const indexing = primaryTabProps.tabs.splice(
		primaryTabProps.tabs.findIndex((v) => v.title === 'Channel Details'),
		1
	);
	const storage = localStorage.getItem('userInfo');
	if (storage != null) {
		if (viewChannelScope) {
			primaryTabProps.tabs.splice(1, 0, {
				title: 'Channel Details',
				component: <ChannelDetails />,
			});
		} else {
			indexing;
		}
	}
	return <PrimaryTab {...primaryTabProps} onSelect={setSelectedIndex} />;
};

export default ChannelTab;
