import React, { useEffect, useState, useCallback } from 'react';
import styles from './Accordion.module.css';
import cx from 'classnames';
import ToggleButton from '../../ProductTypeContainer/ProductScrollableList/ToggleButton';
import { useHasUserPermission } from 'utils/permissions';
import IconResolver from 'helpers/IconResolver';
import { AccordionProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import client from 'helpers/ApiClient';
import { BASE_PATH } from 'containers/ChannelManagement/Channel/ChannelProducts/const';
const Accordion: React.FC<AccordionProps> = ({
	title,
	enabled = false,
	onExpandAction,
	partner,
	content,
	accordionKey,
	onClickBtn,
	onChangeBtn,
	channel,
	sectionData,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [partnerDetails, setPartnerDetails] = useState<any>([]);
	const hasUserPermission = useHasUserPermission('channels');
	const hasEditPermission = hasUserPermission('edit');

	useEffect(() => {
		if (channel) {
			setIsExpanded(false);
			setPartnerDetails([]);
		}
	}, [channel]);

	useEffect(() => {
		if (isExpanded && partner) {
			setPartnerDetails(partner);
		}
	}, [isExpanded, partner]);

	// const [partnerBranchAndTerminal, setPartnerBranchAndTerminal] = useState([]);
	const getPartnerBranchAndTerminal = useCallback(async () => {
		if (partner) {
			try {
				//set loading here
				const response = await client.get(
					BASE_PATH + `${channel.id}/products/${partner.productId}`
				);
				setPartnerDetails((prevData) => ({
					...prevData,
					data: response.data.data,
				}));
			} catch (error) {
				console.error('Error fetching partners branch and terminal: ', error);
			}
		}
	}, [channel.id, partner.productId]);

	useEffect(() => {
		if (isExpanded && partnerDetails && partnerDetails.data) {
			sectionData(partnerDetails, partner.productId);
		}
	}, [isExpanded, partnerDetails, partner.productId, sectionData]);

	useEffect(() => {
		if (isExpanded) {
			getPartnerBranchAndTerminal();
		}
		// Cleanup function to reset partnerDetails
		return () => {
			setPartnerDetails(partner);
		};
	}, [isExpanded, getPartnerBranchAndTerminal]);

	const handleAccordionClick = () => {
		onExpandAction(accordionKey);
		setIsExpanded(!isExpanded);
	};

	const handleToggleOnClick = () => {
		onClickBtn(accordionKey);
	};

	const handleInputChange = (
		index: number,
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		onChangeBtn(accordionKey, e);
	};
	return (
		<>
			<section
				key={`${channel.id}-${accordionKey}`}
				className={styles.accordionSection}
			>
				<button
					className={cx(styles.accordionButton, {
						[styles.expanded]: isExpanded,
					})}
					onClick={handleAccordionClick}
					aria-controls={`accordion-item-${channel.id}-${accordionKey}`}
					aria-expanded={isExpanded}
					role="switch"
				>
					<div className={styles.sectionLabel}>
						<span className="slds-current-color slds-col">
							<IconResolver
								className={cx('slds-icon_xx-small', 'slds-p-left_xx-small')}
								path={`utility/${isExpanded ? 'chevronright' : 'chevrondown'}`}
							/>
						</span>
						<strong>{title}</strong>
					</div>
				</button>
				<div className={cx('slds-clearfix', styles.toggleButton)}>
					<div className="slds-float_right">
						<ToggleButton
							isDisabled={hasEditPermission}
							isChecked={enabled}
							id={accordionKey}
							onClickBtn={handleToggleOnClick}
							onChangeBtn={handleInputChange}
						/>
					</div>
				</div>

				{isExpanded && (
					<div
						id={`accordion-item-${accordionKey}`}
						className={styles.accordionContent}
					>
						<div>{content}</div>
					</div>
				)}
			</section>
		</>
	);
};

export default Accordion;
