import Grid from 'components/Grid/Grid';
import Label from 'components/Inputs/Label/Label';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import Section, { SectionRow } from 'components/Section/Section';
import {
	SelectFieldProps,
	TextFieldProps,
} from 'containers/ChannelManagement/Branch/BranchForm/Address/types';
import {
	CHANNEL_TRANSACTION_TYPE_OPTIONS,
	CHANNEL_TYPE_DIGITAL,
	CHANNEL_TYPE_PHYSIAL,
	MACHINE_LOCATION_OPTION,
	MERALCO_PAMENT_CENTER_CODE_OPTIONS,
	NAME_OF_CHANNEL_ACCOUNT_CODE,
	NAME_OF_CHANNEL_ACCOUNT_NAME,
	NAME_OF_CHANNEL_TRANSACTION_TYPE,
	NAME_OF_CHANNEL_TYPE,
	NAME_OF_POS_TYPE,
	NAME_OF_SETTLEMENT_SETUP,
	PHYSICAL,
	POST_TYPE_OPTIONS,
	SETTLEMENT_SETUP_OPTIONS,
	STORE_FORMAT_OPTIONS,
	STORE_TYPE_OPTIONS,
} from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/constants';
import { autoSetDefaults } from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/utils';
import {
	BRANCH_BASIC_INFORMATION,
	BRANCH_CODE,
	BRANCH_NAME,
	CHANNEL_ACCOUNT_CODE,
	CHANNEL_ACCOUNT_NAME,
	CHANNEL_TRANSACTION_TYPE,
	CHANNEL_TYPE,
	CIGNAL_TELLER_CODE,
	MACHINE_LOCATION,
	MERALCO_PAYMENT_CENTER_CODE,
	PLDT_TELLER_CODE,
	POS_TYPE,
	SETTLEMENT_SETUP,
	STORE_FORMAT,
	STORE_TYPE,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';
import _ from 'lodash';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FixMeLater } from 'types';
import styles from './BranchBasicInformation.module.css';

export type BranchBasicInformationProp = {
	sectionTitle?: string;
	channelAccountName?: TextFieldProps;
	channelAccountCode?: TextFieldProps;
	branchName?: TextFieldProps;
	branchCode?: TextFieldProps;
	channelTransactionType?: SelectFieldProps;
	channelType?: SelectFieldProps;
	posType?: SelectFieldProps;
	settlementSetup?: SelectFieldProps;
	storeType?: SelectFieldProps;
	storeFormat?: SelectFieldProps;
	machineLocation?: SelectFieldProps;
	pldtTellerCode?: TextFieldProps;
	cignalTellerCode?: TextFieldProps;
	meralcoPaymentCenterCode?: SelectFieldProps;
	isDisabledAllEditableFields?: boolean;
};

const BranchBasicInformation: React.FC<BranchBasicInformationProp> = ({
	sectionTitle,
	channelAccountName,
	channelAccountCode,
	branchName,
	branchCode,
	channelTransactionType,
	channelType,
	posType,
	settlementSetup,
	storeType,
	storeFormat,
	machineLocation,
	pldtTellerCode,
	cignalTellerCode,
	meralcoPaymentCenterCode,
	isDisabledAllEditableFields,
}) => {
	const channelPrimaryInfo = useSelector((state: FixMeLater) => {
		return state.channels.channelFormValues.primaryInfo.channelPrimaryInfo;
	});

	const { control, setValue } = useFormContext();

	const selectedChannelId = useSelector((state: FixMeLater) => {
		return state.channels.selectedChannel.id as number;
	});

	const channelTransactionTypeCurrentValue = useWatch({
		name: NAME_OF_CHANNEL_TRANSACTION_TYPE,
	});

	const channelTypeOptions =
		channelTransactionTypeCurrentValue === PHYSICAL
			? CHANNEL_TYPE_PHYSIAL
			: CHANNEL_TYPE_DIGITAL;

	const channelTypeOptionsFinal = channelTransactionTypeCurrentValue
		? channelTypeOptions
		: [];

	useEffect(() => {
		autoSetDefaults(setValue, channelPrimaryInfo);
	}, [channelPrimaryInfo, selectedChannelId, setValue]);

	return (
		<>
			<Section title={sectionTitle || 'Branch Basic Information'}>
				<SectionRow verticalAlign="start">
					<Grid column size={8} of={12} verticalAlign="start">
						<TextField
							label={_.startCase(CHANNEL_ACCOUNT_NAME)}
							name={NAME_OF_CHANNEL_ACCOUNT_NAME}
							control={control}
							disabled
							placeholder=""
							{...channelAccountName}
						/>
					</Grid>
					<Grid column size={4} of={12} verticalAlign="start">
						<TextField
							label={_.startCase(CHANNEL_ACCOUNT_CODE)}
							control={control}
							name={NAME_OF_CHANNEL_ACCOUNT_CODE}
							disabled
							placeholder=""
							{...channelAccountCode}
						/>
					</Grid>
				</SectionRow>

				<SectionRow verticalAlign="start">
					<Grid column size={4} of={12} verticalAlign="start">
						<TextField
							label={_.startCase(BRANCH_NAME)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${BRANCH_NAME}`}
							required
							disabled={isDisabledAllEditableFields}
							{...branchName}
						/>
					</Grid>
					<Grid column size={4} of={12} verticalAlign="start">
						<TextField
							label={_.startCase(BRANCH_CODE)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${BRANCH_CODE}`}
							disabled
							placeholder=""
							{...branchCode}
						/>
					</Grid>
					<Grid column size={4} of={12} verticalAlign="start">
						<SelectField
							label={_.startCase(CHANNEL_TRANSACTION_TYPE)}
							control={control}
							name={NAME_OF_CHANNEL_TRANSACTION_TYPE}
							options={CHANNEL_TRANSACTION_TYPE_OPTIONS}
							required
							placeholder={`Select ${_.startCase(CHANNEL_TRANSACTION_TYPE)}`}
							disabled={isDisabledAllEditableFields}
							{...channelTransactionType}
						/>
					</Grid>
				</SectionRow>

				<SectionRow verticalAlign="start">
					<Grid column size={4} of={12} verticalAlign="start">
						<SelectField
							label={_.startCase(CHANNEL_TYPE)}
							control={control}
							name={NAME_OF_CHANNEL_TYPE}
							options={channelTypeOptionsFinal}
							required
							placeholder={`Select ${_.startCase(CHANNEL_TYPE)}`}
							disabled={isDisabledAllEditableFields}
							{...channelType}
						/>
					</Grid>
					<Grid column size={4} of={12} verticalAlign="start">
						<SelectField
							label={_.startCase(SETTLEMENT_SETUP)}
							control={control}
							name={NAME_OF_SETTLEMENT_SETUP}
							options={SETTLEMENT_SETUP_OPTIONS}
							disabled
							placeholder=""
							{...settlementSetup}
						/>
					</Grid>
					<Grid column size={4} of={12} verticalAlign="start">
						{channelTransactionTypeCurrentValue === PHYSICAL && (
							<SelectField
								label={_.startCase(POS_TYPE)}
								control={control}
								name={NAME_OF_POS_TYPE}
								options={POST_TYPE_OPTIONS}
								required
								placeholder={`Select ${_.startCase(POS_TYPE)}`}
								disabled={isDisabledAllEditableFields}
								{...posType}
							/>
						)}
					</Grid>
				</SectionRow>

				<SectionRow verticalAlign="start">
					<Grid column size={4} of={12} verticalAlign="start">
						<SelectField
							label={_.startCase(STORE_TYPE)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${STORE_TYPE}`}
							options={STORE_TYPE_OPTIONS}
							required
							placeholder={`Select ${_.startCase(STORE_TYPE)}`}
							disabled={isDisabledAllEditableFields}
							{...storeType}
						/>
					</Grid>
					<Grid column size={4} of={12} verticalAlign="start">
						<SelectField
							label={_.startCase(STORE_FORMAT)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${STORE_FORMAT}`}
							options={STORE_FORMAT_OPTIONS}
							required
							placeholder={`Select ${_.startCase(STORE_FORMAT)}`}
							disabled={isDisabledAllEditableFields}
							{...storeFormat}
						/>
					</Grid>
					<Grid column size={4} of={12} verticalAlign="start">
						<SelectField
							label={_.startCase(MACHINE_LOCATION)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${MACHINE_LOCATION}`}
							options={MACHINE_LOCATION_OPTION}
							required
							placeholder={`Select ${_.startCase(MACHINE_LOCATION)}`}
							disabled={isDisabledAllEditableFields}
							{...machineLocation}
						/>
					</Grid>
				</SectionRow>

				<SectionRow verticalAlign="start">
					<Grid column size={4} of={12} verticalAlign="start">
						<Label>{_.startCase(PLDT_TELLER_CODE)}</Label>
						<div className={styles.subtext}>
							<em>If left blank, Channel cannot process PLDT biller.</em>
						</div>
						<TextField
							label={''}
							placeholder={_.startCase(PLDT_TELLER_CODE)}
							name={`${BRANCH_BASIC_INFORMATION}.${PLDT_TELLER_CODE}`}
							control={control}
							disabled={isDisabledAllEditableFields}
							{...pldtTellerCode}
						/>
					</Grid>
					<Grid column size={4} of={12} verticalAlign="start">
						<Label>{_.startCase(CIGNAL_TELLER_CODE)}</Label>
						<div className={styles.subtext}>
							<em>If left blank, Channel cannot process Cignal biller.</em>
						</div>
						<TextField
							label={''}
							placeholder={_.startCase(CIGNAL_TELLER_CODE)}
							name={`${BRANCH_BASIC_INFORMATION}.${CIGNAL_TELLER_CODE}`}
							control={control}
							disabled={isDisabledAllEditableFields}
							{...cignalTellerCode}
						/>
					</Grid>
					<Grid column size={4} of={12} verticalAlign="start">
						<div className={styles.subtext}>
							<em>ㅤ</em>
						</div>
						<SelectField
							label={_.startCase(MERALCO_PAYMENT_CENTER_CODE)}
							control={control}
							name={`${BRANCH_BASIC_INFORMATION}.${MERALCO_PAYMENT_CENTER_CODE}`}
							options={MERALCO_PAMENT_CENTER_CODE_OPTIONS}
							required
							disabled={isDisabledAllEditableFields}
							{...meralcoPaymentCenterCode}
						/>
					</Grid>
				</SectionRow>
			</Section>
		</>
	);
};

export default BranchBasicInformation;
