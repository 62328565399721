import Grid from 'components/Grid/Grid';
import { EmailTagInput } from 'components/TagInput/TagInput';
import { SmtpProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SMTP/types';
import { PartnerReportsFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';
import { useFormContext } from 'react-hook-form';
import styles from './style.module.css';

const EmailAddressFields: React.FC<SmtpProps> = ({ disabled }) => {
	const { control, trigger } = useFormContext<PartnerReportsFormData>();
	return (
		<Grid container gutters className={styles.emailAddressContainer}>
			<Grid column size={1} of={2}>
				<EmailTagInput
					required
					control={control}
					name="smtp.senderEmail"
					label="Sender Email Address"
					disabled
				/>
			</Grid>
			<Grid column size={1} of={2}>
				<EmailTagInput
					required
					control={control}
					name="smtp.receiverEmail"
					label="Receiver Email Address"
					disabled={disabled}
					onInputChange={() => trigger('smtp.receiverEmail')}
				/>
			</Grid>
		</Grid>
	);
};

export default EmailAddressFields;
