import { parseInt } from 'lodash';
import { common } from './common';
import * as yup from 'yup';
import { transformCash } from 'utils/formSchemas/common';

export type product_business_rule = {
	payment_mode?: string[];
	cash_multiple_bills?: string;
	check_multiple_bills?: string;
	cash_check_multiple_bills?: string;
	credit_multiple_bills?: string;
	pos_type?: string;
	accepted_check_types?: string[];
	other_check_types?: string;
	required_documents?: string[];
	other_required_documents?: string;
	area_restriction?: string;
	memo?: string;
	min_amount_cash?: number;
	max_amount_cash?: number;
	min_amount_check?: number;
	max_amount_check?: number;
	payment_accepted?: string[];
	payment_acceptance_policy?: string;
	grace_period?: number;
} & common;

export const product_business_rule_schema = yup.object().shape({
	payment_mode: yup
		.array()
		.nullable()
		.min(1, 'Select Payment Mode.')
		.label('Payment Mode')
		.required('Select Payment Mode.'),
	cash_multiple_bills: yup
		.string()
		.nullable()
		// .transform((value) => {
		// 	if (!value) return '0';

		// 	const isValidValue = (v) => ['1', '0'].includes(v?.trim() ?? '');
		// 	if (typeof value === 'string') {
		// 		if (isValidValue(value)) return value;
		// 	}

		// 	if (Array.isArray(value)) {
		// 		if (isValidValue(value[0])) return value[0];
		// 	}

		// 	return '0';
		// })
		.label('Multiple Bills Acceptance')
		.optional(),
	check_multiple_bills: yup
		.string()
		.nullable()
		.label('Multiple Bills Acceptance')
		.optional(),
	cash_check_multiple_bills: yup
		.string()
		.nullable()
		.label('Multiple Bills Acceptance')
		.optional(),
	credit_multiple_bills: yup
		.string()
		.nullable()
		.label('Multiple Bills Acceptance')
		.optional(),
	pos_type: yup
		.string()
		.nullable()
		.label('POS Type')
		.when('payment_mode', (payment_modes = '', schema) => {
			if (payment_modes && payment_modes.includes('CREDIT')){
				return schema.min(1, 'Select POS Type.').required('Select POS Type.');
			} else {
				return schema.optional();
			}
		}),
	accepted_check_types: yup
		.array()
		.nullable()
		.label('Accepted Check Types')
		.when('payment_mode', (payment_modes = '', schema) => {
			if (
				payment_modes &&
				(payment_modes.includes('CHECK') ||
					payment_modes.includes('CASH_CHECK'))
			) {
				return schema
					.min(1, 'Select Accepted Check Types.')
					.required('Select Accepted Check Types.');
			}
			return schema.optional();
		}),
	other_check_types: yup
		.string()
		.nullable()
		.label('Other Check Types')
		.typeError('Input Other Check Types.')
		.when('accepted_check_types', (accepted_check_types = '', schema) => {
			if (
				accepted_check_types &&
				accepted_check_types.includes('OTHER_CHECK')
			) {
				return schema.required('Input Other Check Types.');
			} else {
				return schema.optional();
			}
		}),
	required_documents: yup
		.array()
		.nullable()
		.min(1, 'Select Required Documents.')
		.label('Required Documents')
		.required('Select Required Documents.'),
	other_required_documents: yup
		.string()
		.nullable()
		.typeError('Input Other Forms.')
		.label('Other Forms')
		.when('required_documents', (required_documents = '', schema) => {
			if (required_documents && required_documents.includes('OTHER')) {
				return schema.required('Input Other Forms.');
			} else {
				return schema.optional();
			}
			//causing white screen when schema is return (unknown issue)
		}),
	area_restriction: yup
		.string()
		.nullable()
		.label('Area Restriction')
		.required('Select Area Restriction.'),
	memo: yup
		.string()
		.nullable()
		.label('Memo')
		.when('area_restriction', {
			is: '1',
			then: (s) => s.required('Upload Memo.'),
			otherwise: (s) => s.optional(),
		}),
	min_amount_cash: yup
		.number()
		.nullable()
		.label('Minimum Cash Amount')
		.min(0.01, 'Input Minimum Amount at least 0.01')
		.transform(transformCash)
		.when('payment_mode', (payment_modes = '', schema) => {
			if (
				payment_modes &&
				(payment_modes.includes('CASH') || payment_modes.includes('CASH_CHECK'))
			)
				return schema.required('Input Minimum Amount.');
			return schema.optional();
		}),
	max_amount_cash: yup
		.number()
		.nullable()
		.label('Maximum Cash Amount')
		.transform(transformCash)
		.when('payment_mode', (payment_modes = '', schema) => {
			if (
				payment_modes &&
				(payment_modes.includes('CASH') || payment_modes.includes('CASH_CHECK'))
			)
				return schema
					.when('min_amount_cash', (min_amount_cash = NaN, s) => {
						return s.test({
							test: (max_amount_cash) =>
								min_amount_cash > 0 && max_amount_cash > min_amount_cash,
							message: 'Input Maximum Amount greater than Minimum Amount.',
						});
					})
					.required('Input Maximum Amount.');
			return schema.optional();
		}),
	min_amount_check: yup
		.number()
		.nullable()
		.label('Minimum Check Amount')
		.min(0.01, 'Input Minimum Amount at least 0.01')
		.transform(transformCash)
		.when('payment_mode', (payment_modes = '', schema) => {
			if (
				payment_modes &&
				(payment_modes.includes('CHECK') ||
					payment_modes.includes('CASH_CHECK'))
			)
				return schema.required('Input Minimum Amount.');
			return schema.optional();
		}),
	max_amount_check: yup
		.number()
		.nullable()
		.label('Maximum Check Amount')
		.transform(transformCash)
		.when('payment_mode', (payment_modes = '', schema) => {
			if (
				payment_modes &&
				(payment_modes.includes('CHECK') ||
					payment_modes.includes('CASH_CHECK'))
			) {
				return schema
					.when('min_amount_check', (min_amount_check = NaN, s) => {
						return s.test({
							test: (max_amount_check) =>
								min_amount_check > 0 && max_amount_check > min_amount_check,
							message: 'Input Maximum Amount greater than Minimum Amount.',
						});
					})
					.required('Input Maximum Amount.');
			}
			return schema.optional();
		}),
	payment_accepted: yup
		.array()
		.nullable()
		.min(1, 'Select Payment Accepted.')
		.label('Payment Accepted')
		.required('Select Payment Accepted.'),
	payment_acceptance_policy: yup
		.string()
		.nullable()
		.label('Payment Acceptance Policy')
		.when('$currentProductId', (currentProductId, schema) => {
			// 2 is Buy Load
			return currentProductId === 2
				? schema
				: schema.required('Select Payment Acceptance Policy.');
		}),
	grace_period: yup.lazy((value) =>
		value === ''
			? yup.string().required('Input Grace Period (Days) at least 1.')
			: yup
					.number()
					.nullable()
					.label('Grace Period')
					.when('payment_acceptance_policy', {
						is: 'BEYOND_DUE',
						then: (s) =>
							s
								.required('Input Grace Period (Days) at least 1.')
								.min(1, 'Input Grace Period (Days) at least 1.')
								.max(999, 'Input Grace Period (Days) not greater than 999.')
								.typeError('Input Grace Period (Days) must be a number.'),
					})
	),
});
