import { Button, IconSettings, Tooltip } from '@salesforce/design-system-react';
import cx from 'classnames';
import Table from 'components/CWSTable/Table';
import { DraftsTableProps } from 'containers/ChannelManagement/Drafts/DraftsTable/types';
import {
	BRANCH_NAME,
	CHANNEL_ACCOUNT_NAME,
	DATE_CREATED,
	DATE_UPDATED,
	REMARKS,
	STATUS,
	SUBMITTED_BY,
	TPAID,
	UPDATED_BY,
} from 'containers/ChannelManagement/Drafts/constants';
import { FixMeLater } from 'types';
import { formatDate } from 'utils/common';
import styles from './DraftsTable.module.css';

export const Cell: React.FC<{ value: string }> = ({ value }) => {
	return <div className={styles.rowBreak}>{value}</div>;
};

const DraftsTable: React.FC<DraftsTableProps> = ({
	tableStates,
	onPageChange,
	onSort,
}) => {
	const DRAFT_COLUMNS: Array<FixMeLater> = [
		{
			Header: 'Date & Time Created',
			id: DATE_CREATED,
			sortable: true,
			width: '14%',
			accessor: (columns) => formatDate(columns[DATE_CREATED] || ''),
		},
		{
			Header: 'Submitted By',
			id: SUBMITTED_BY,
			sortable: true,
			width: '10%',
			Cell,
		},
		{
			Header: 'Channel Account Name',
			id: CHANNEL_ACCOUNT_NAME,
			sortable: true,
			width: '11%',
			Cell,
		},
		{
			Header: 'Branch Name',
			id: BRANCH_NAME,
			sortable: true,
			width: '10%',
			Cell,
		},
		{
			Header: 'TPAID',
			id: TPAID,
			sortable: true,
			width: '7%',
			Cell,
		},
		{
			Header: 'Date Updated',
			id: DATE_UPDATED,
			sortable: true,
			width: '14%',
			accessor: (columns) => formatDate(columns[DATE_UPDATED] || ''),
		},
		{
			Header: 'Updated By',
			id: UPDATED_BY,
			sortable: true,
			width: '9%',
			Cell,
		},
		{
			Header: 'Status',
			id: STATUS,
			width: '9%',
			sortable: false,
			Cell: ({ value }) => {
				return <div className={styles.redStatus}>{value}</div>;
			},
		},
		{
			Header: 'Remarks',
			id: REMARKS,
			width: '11%',
			sortable: false,
			Cell: ({ value }) => {
				return (
					<>
						<Tooltip
							id="tooltip"
							align="bottom left"
							content={value}
							triggerClassName={styles.toolTip}
							position="overflowBoundaryElement"
						>
							<div>{value}</div>
						</Tooltip>
					</>
				);
			},
		},
		{
			Header: () => <div className={cx(styles.centerAlign)}>Action</div>,
			id: 'actions',
			width: '5%',
			accessor: (values) => values,
			Cell: () => {
				return (
					<>
						<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
							<div className={cx('slds-grid')}>
								<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
									<Button
										assistiveText={{ icon: 'Icon Bare Small' }}
										iconCategory="utility"
										iconName="picklist_type"
										iconSize="small"
										iconVariant="bare"
										iconClassName={cx(styles.iconBtn, styles.iconOrange)}
										onClick={() => alert('Action')}
										variant="icon"
									/>
								</div>
							</div>
						</IconSettings>
					</>
				);
			},
		},
	];

	return (
		<>
			<Table
				columns={DRAFT_COLUMNS}
				data={tableStates.data}
				count={tableStates.count}
				pageSize={tableStates.pageSize}
				sort={tableStates.sortOrder}
				sortBy={tableStates.sortBy}
				page={tableStates.page}
				onPageChange={onPageChange}
				onSort={onSort}
				preHeader={''}
				modalIsOpen={false}
				isNotEvenRowColor={false}
			/>
		</>
	);
};

export default DraftsTable;
