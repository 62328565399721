import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setCurrentBillerTab } from 'redux/modules/products';
import { FaCheckCircle } from 'react-icons/fa';
import cx from 'classnames';
import styles from './PartnerForm.module.css';
import PrimaryInformation from './Tabs/PrimaryInformation/PrimaryInformation';
import ContractDetails from './Tabs/ContractDetails/ContractDetails';
import ServiceFeeSettings from './Tabs/ServiceFee/ServiceFeeSettings';
import BusinessRules from './Tabs/BusinessRules/BusinessRules';
import { product } from 'utils/models/product';
import ReportsTab from './Tabs/Reports';
import { partnerModalTabs } from 'constants/partner_modal_tabs';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { showAccessDeniedModal } from 'redux/modules/access';
import { useHasUserPermission } from 'utils/permissions';
import { ReducerStateType } from 'redux/modules/reducers';
import { FixMeLater, TPartnerModalConfirmProps } from 'types';
import { LoaderState } from 'components/PartnerModal/PartnerModal';

type TabLabelProps = {
	label: string;
	done?: boolean;
	disabled?: boolean;
	action?: string;
};

export const TabLabel: React.FC<TabLabelProps> = ({
	label,
	done,
	disabled,
	action,
}) => {
	return (
		<div
			className={cx(
				action !== 'VIEW' &&
					styles.tabLabel + ' ' + { [styles.tabDisabled]: disabled }
			)}
		>
			{label}
			{done && <FaCheckCircle className={cx(styles.tabCheck, {})} />}
		</div>
	);
};

export type PartnerFormProps = {
	action?: string;
	tabStatus?: TabStatus;
	disabled?: boolean;
	contentClassName?: string;
	currentBillerTab?: string;
	setCurrentBillerTab: (tabName: string) => void;
	onSave?: (values: any) => Promise<any>;
	onSubmit?: (
		values: any,
		saveType: string | LoaderState | null
	) => Promise<any>;
	data: product;
	showSuccess?: any;
	showFail?: any;
	isPrimaryInfoValid?: boolean;
	isContractDetailsValid?: boolean;
	isServiceFeeValid?: boolean;
	isBusinessRulesValid?: boolean;
	isReportsValid?: boolean;
	errorMessage?: string;
	setLastAutoSaved?: SetStateAction<any>;
	showConfirmationMessage: TPartnerModalConfirmProps;
	modalOpen?: boolean;
};

type PartialRecord<K extends keyof any, T> = {
	[P in K]?: T;
};

type Tabs = {
	primaryInformation?: any;
	contractDetails?: any;
	serviceFeeSettings?: any;
	businessRules?: any;
	reports?: any;
};

type TabStatusValue = { finished?: boolean; disabled?: boolean };

export type TabStatus = PartialRecord<keyof Tabs, TabStatusValue>;

const initTabStatus: TabStatus = {
	primaryInformation: { disabled: false },
	contractDetails: { disabled: false },
	serviceFeeSettings: { disabled: false },
	businessRules: { disabled: false },
	reports: { disabled: false },
};

const PartnerForm: React.FC<PartnerFormProps> = ({
	tabStatus = initTabStatus,
	contentClassName = '',
	data,
	setCurrentBillerTab,
	currentBillerTab,
	isPrimaryInfoValid,
	isContractDetailsValid,
	isServiceFeeValid,
	isBusinessRulesValid,
	isReportsValid,
	onSubmit,
	modalOpen,
	...props
}) => {
	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);
	const hasUserPermission = useHasUserPermission('products');
	const dispatch = useDispatch();
	const [showView, setShowView] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);

	function findIndexName(selected: number): string {
		const idx = partnerModalTabs.findIndex((tab) => tab.index == selected);
		return partnerModalTabs[idx].name;
	}

	// permission handling for editing partner details
	const hasEditPermission = useCallback(
		(scope: string) => {
			return hasUserPermission(productCategory, `edit.${scope}`);
		},
		[hasUserPermission, productCategory]
	);

	// permission handling for viewing partner details
	const hasViewPermission = useCallback(
		(scope: string) => {
			return hasUserPermission(productCategory, `view.${scope}`);
		},
		[hasUserPermission, productCategory]
	);

	useEffect(() => {
		// console.log('currentBillerTab', currentBillerTab);
		if (!currentBillerTab) return;

		const index = partnerModalTabs.find((tab) => tab.name === currentBillerTab);

		if (index) setSelectedIndex(index.index);

		const isEditMode = props.action === 'EDIT';

		if (!isEditMode) return;

		const hasViewPermission_ = hasViewPermission(currentBillerTab);

		if (!hasViewPermission_ && showView !== hasViewPermission_) {
			dispatch(showAccessDeniedModal());
		}

		setShowView(hasViewPermission_);
	}, [currentBillerTab, dispatch, hasViewPermission, props.action, showView]);

	// handling of view permission
	// only need to trigger on 1st mount
	useEffect(() => {
		if (!currentBillerTab) return;

		const isViewMode = props.action === 'VIEW';

		if (!isViewMode) {
			setShowView(true);
			return;
		}

		const hasViewPermission_ = hasViewPermission(currentBillerTab);

		if (!hasViewPermission_) {
			dispatch(showAccessDeniedModal());
		}

		setShowView(hasViewPermission_);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Tabs
			className={styles.tabs}
			onSelect={(i: number) => {
				const billerTab = findIndexName(i);

				const hasViewPermission_ = hasViewPermission(billerTab);

				const isViewMode = props.action === 'VIEW';
				const isEditMode = props.action === 'EDIT';

				setShowView(isViewMode || isEditMode ? hasViewPermission_ : true);

				const hasEditPermission_ = hasEditPermission(billerTab);

				if (
					((isViewMode || isEditMode) && !hasViewPermission_) ||
					(isEditMode && !hasEditPermission_)
				) {
					dispatch(showAccessDeniedModal());

					if (isEditMode && !hasEditPermission_) {
						return;
					}
				}

				setCurrentBillerTab(billerTab);
			}}
			selectedIndex={selectedIndex}
		>
			<TabsPanel
				label={
					<TabLabel
						label="Primary Information"
						done={tabStatus.primaryInformation?.finished || isPrimaryInfoValid}
						action={props.action}
					/>
				}
				disabled={tabStatus.primaryInformation?.disabled}
			>
				{showView && (
					<div className={cx(styles.content, contentClassName)}>
						<PrimaryInformation {...props} data={data} onSubmit={onSubmit} />
					</div>
				)}
			</TabsPanel>
			<TabsPanel
				label={
					<TabLabel
						label="Contract Details"
						done={tabStatus.contractDetails?.finished || isContractDetailsValid}
						action={props.action}
					/>
				}
				disabled={tabStatus.contractDetails?.disabled}
			>
				{showView && (
					<div className={cx(styles.content, contentClassName)}>
						<ContractDetails {...props} data={data} onSubmit={onSubmit} />
					</div>
				)}
			</TabsPanel>
			<TabsPanel
				label={
					<TabLabel
						label="Service Fee Settings"
						done={tabStatus.serviceFeeSettings?.finished || isServiceFeeValid}
						action={props.action}
					/>
				}
				disabled={tabStatus.serviceFeeSettings?.disabled}
			>
				{showView && (
					<div className={cx(styles.content, contentClassName)}>
						<ServiceFeeSettings {...props} data={data} onSubmit={onSubmit} modalIsOpen={modalOpen} />
					</div>
				)}
			</TabsPanel>
			<TabsPanel
				label={
					<TabLabel
						label="Business Rules"
						done={tabStatus.businessRules?.finished || isBusinessRulesValid}
						action={props.action}
					/>
				}
				disabled={tabStatus.businessRules?.disabled}
			>
				{showView && (
					<div className={cx(styles.content, contentClassName)}>
						<BusinessRules {...props} data={data} onSubmit={onSubmit} />
					</div>
				)}
			</TabsPanel>
			<TabsPanel
				label={
					<TabLabel
						label="Reports"
						done={tabStatus.reports?.finished || isReportsValid}
						action={props.action}
					/>
				}
				disabled={tabStatus.reports?.disabled}
			>
				{showView && (
					<div className={cx(styles.content, contentClassName)}>
						<ReportsTab {...props} data={data} onSubmit={onSubmit} />
					</div>
				)}
			</TabsPanel>
		</Tabs>
	);
};

export default connect(
	(state: Record<string, FixMeLater>) => ({
		currentBillerTab: state.products.currentBillerTab,
		isPrimaryInfoValid: state.products.primaryInfo?.isValid,
		isContractDetailsValid: state.products.contractDetails?.isValid,
		isServiceFeeValid: state.products.serviceFeeSettings?.isValid,
		isBusinessRulesValid: state.products.businessRules?.isValid,
		isReportsValid: state.products.reports?.isValid,
	}),
	{ setCurrentBillerTab }
)(PartnerForm);
