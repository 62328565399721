import React from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import styles from 'containers/ChannelManagement/Channel/ChannelTable/ChannelTable.module.css';
import AmountField from 'components/Inputs/TextField/AmountField';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import { InputIcon } from '@salesforce/design-system-react';
import { ReactComponent as PercentIcon } from 'assets/icons/ic-percent.svg';
import { CHANNEL_TYPES } from 'containers/ChannelManagement/Channel/ChannelProducts/const';

function ChannelTable({
	itemKey,
	data,
	columnsParam,
	tableClassName,
	headerClassName,
	bodyClassName,
	containerClassName,
	showPagination,
	onChange,
	shares,
	serviceFeeType,
	toEdit,
	nonShareableColumn,
	control,
	permission,
	customStyles
}: {
	itemKey?: string;
	data: any[];
	columnsParam: any[];
	tableClassName?: string;
	headerClassName?: string;
	bodyClassName?: string;
	containerClassName?: string;
	showPagination?: boolean;
	onChange?: (data: any, name: string, rowData: any) => void;
	shares?: any;
	serviceFeeType?: string;
	toEdit?: boolean;
	nonShareableColumn?: any;
	control?: any;
	permission?: boolean;
		customStyles?: any
}) {
	const columns = React.useMemo(() => columnsParam, [columnsParam]);
	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
		useTable(
			{
				columns,
				data,
			},
			useSortBy,
			usePagination
		);

	const onChangeValue = (value, name, rowData) => {
		if (onChange) {
			onChange(value, name, rowData);
		}
	};

	return (
		<div key={itemKey} className={containerClassName}>
			<table {...getTableProps()} className={tableClassName}>
				<thead style={{ maxWidth: '10px' }}>
					{headerGroups.map((headerGroup, i) => (
						<tr key={i} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, j) => (
								<th
									key={j}
									{...column.getHeaderProps()}
									className={headerClassName}
									style={{
										maxWidth: customStyles ? '50px' : column.width,
										minWidth: customStyles ? '20px' : column.width,
										padding: '15px',
									}}
								>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} className={bodyClassName}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr key={i} {...row.getRowProps()}>
								{row.cells.map((cell, j) => (
									<td
										key={j}
										{...cell.getCellProps()}
										style={{ padding: '15px' }}
										className={
											cell.column.id.includes('sf_share')
												? styles.shareBgColor
												: ''
										}
									>
										{cell.column.id === 'partner_share' ||
										cell.column.id === 'bayad_share' ? (
											<AmountField
												label=""
												isFieldTable
												value={
													cell.column.id === 'partner_share'
														? parseFloat(
																shares.find(
																	(item) =>
																		item.productId ===
																			row.original.product_id &&
																		item.id ===
																			(row.original.tier_number ||
																				row.original.channel_type_id)
																)?.partnerShare || ''
														  ) || ''
														: parseFloat(
																shares.find(
																	(item) =>
																		item.productId ===
																			row.original.product_id &&
																		item.id ===
																			(row.original.tier_number ||
																				row.original.channel_type_id)
																)?.bayadShare || ''
														  ) || ''
												}
												key={row.original.id}
												placeholder={
													cell.column.id === 'partner_share' ||
													cell.column.id === 'bayad_share'
														? '0.00'
														: ''
												}
												name={cell.column.id}
												onChange={(value) =>
													onChangeValue(value, cell.column.id, row.original)
												}
												formatValue={(value) => {
													if (value === '' || isNaN(value)) {
														return '';
													}
													const numericValue = parseFloat(value);
													if (!isNaN(numericValue)) {
														return numericValue.toFixed(2);
													}
													return '';
												}}
												disabled={
													permission ||
													toEdit ||
													(!toEdit && cell.column.id === 'bayad_share')
												}
												iconRight={
													nonShareableColumn.isPercentage && (
														<InputIcon
															assistiveText={{
																icon: 'none',
															}}
															name="percent"
															category="utility"
														>
															<PercentIcon />
														</InputIcon>
													)
												}
												control={control}
												error={
													cell.column.id === 'partner_share'
														? shares.find(
																(item) =>
																	item.productId === row.original.product_id &&
																	item.id ===
																		(row.original.tier_number ||
																			row.original.channel_type_id)
														  )?.message || ''
														: ''
												}
											/>
										) : cell.column.id.includes('subsidized_rate') ||
										  cell.column.id.includes('pass_on_rate') ||
										  cell.column.id.includes('percentage') ||
										  cell.column.id.includes('sf_share_rate') ? (
											`${cell.value !== null ? cell.value : 0}%`
										) : cell.value !== null ? (
											cell.column.id === 'channel_type_id' ? (
												CHANNEL_TYPES.find((v) => v.id === cell.value)?.name
											) : cell.column.id.includes('fee') ||
											  cell.column.id.includes('rate') ? (
												parseFloat(cell.value).toFixed(2)
											) : (
												cell.render('Cell')
											)
										) : (
											0
										)}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			{showPagination && <>PAGE HERE</>}
		</div>
	);
}

export default ChannelTable;
