import Thead from "../Table/Thead";

export default function billersColumnConfig(setValue, changeShowAll) {
  return [
    {
      Header: (props) => {
        return <Thead {...props}>Biller Name</Thead>;
      },
      accessor: ({ id, name }) => {
        return { id, name };
      },
      Cell: ({ cell, value }) => {
        return (
          <div
            style={{ width: "167px", marginRight: "20px", cursor: "pointer" }}
            {...cell.getCellProps()}
            onClick={() => {
              setValue("filter.billerId", {
                value: value.id,
                label: value.name,
              });
              changeShowAll({ show: false });
            }}
          >
            {value.name}
          </div>
        );
      },

      id: "name",
    },
  ];
}
