import yup, { defaultInvalidCharacter, hasSpecialCharacter } from '../common';
import _ from 'lodash';
import moment from 'moment';

const errorMsg = {
	voidReasonRequired: 'Select Void Reason.',
	waiverRequired: 'This checkbox is required.',
	attachmentRequired: 'At least 1 attachment should be uploaded',
};

const voidTransactionSchema = yup.object().shape({
	billerType: yup.string().label('Biller Type'),
	dayOfCancellation: yup.string().label('For within the Day Cancellation'),
	dateOfDeposit: yup
		.string()
		.label('Date of Deposit')
		.test('is-valid-date-time', 'Input in date format.', (value) => {
			const date = value?.trim();
			const isDateValid = moment(date, 'M/D/YYYY', true).isValid();
			const isTimeValid = moment(date, 'M/D/YYYY HH:mm', true).isValid();
			return _.isEmpty(date) || isDateValid || isTimeValid;
		}),
	depositoryBankAccountNumber: yup
		.string()
		.label('Depository Bank Account No.')
		.max(255)
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	voidRequestorName: yup
		.string()
		.label('Name of Void Requestor')
		.required()
		.max(255, 'You can only input a max of 255 characters.')
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	voidRequestorContact: yup
		.string()
		.label('Contact Number of Void Requestor')
		.required()
		.matches(/^([0-9]\d*)$/, 'Input valid contact number.')
		.max(12, 'You can only input a 12 numeric characters.'),
	voidReasons: yup.object().shape({
		options: yup
			.array()
			.min(1, errorMsg.voidReasonRequired)
			.required(errorMsg.voidReasonRequired),
		otherVoidReason: yup
			.string()
			.label('Others')
			.when(['options'], (options, schema) => {
				if (options === undefined || options.length === 0) return schema;
				return options.find((v: string) => v?.toLowerCase() === 'others')
					? schema
							.required()
							.max(255, 'You can only input a max of 255 characters.')
					: schema;
			}),
	}),
	incidentReport: yup
		.string()
		.required()
		.label('Incident Report')
		.max(1000)
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	isResponsibilityChecked: yup
		.bool()
		.required(errorMsg.waiverRequired)
		.oneOf([true], errorMsg.waiverRequired),
});

export default voidTransactionSchema;
