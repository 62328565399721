import * as yup from 'yup';
import { name, email, mobileNo, telNo } from 'containers/ChannelManagement/Channel/ChannelForm/ChannelFormSchema';
import { YupLab } from 'utils/yupLab';

export const ChannelOfficerSchema = new YupLab(yup.object().shape({
	name,
	email,
	telNo,
	mobileNo,
}));

export const channelAccountOfficerValues =
	ChannelOfficerSchema.schema.getDefault();
export type ChannelOfficerType = ReturnType<
	(typeof ChannelOfficerSchema.schema)['cast']
>;
