import { FaUserCircle } from 'react-icons/fa';
import { BsPlus } from 'react-icons/bs';
import cx from 'classnames';
import styles from './BasicInformation.module.css';
import Button from 'components/Buttons/Button';
import Section, { SectionRow } from 'components/Section/Section';
import TextField from 'components/Inputs/TextField/TextField';
import { Industries, LegalStructure } from 'utils/lookup';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { PrimaryInformationFormData } from '../../PrimaryInformation';
import SelectDatepicker, {
	DateValue,
} from 'components/Inputs/Datepicker/SelectDatepicker';
import { SelectFieldWithOthers } from 'components/Inputs/SelectFieldWithOthers/SelectFieldsWithOthers';
import Grid from 'components/Grid/Grid';
import { useEffect, useState } from 'react';
import { useToggle } from 'utils/hooks';
import {
	addThousandsSeparator,
	isANumber,
} from 'components/Format/NumberFormatter';
import UploadLogoModal from '../../../../../UploadLogoModal/UploadLogoModal';
import { UploadLogoResponse } from 'components/UploadLogoModal/UploadLogoResponse';

type LogoFieldProps = {
	onClick: () => void;
	onSuccess: (data: UploadLogoResponse) => void;
	image?: string;
	disabled?: boolean;
};

export type BasicInformationFields = {
	logo?: {
		objectKey: string;
		url: string;
		filename?: string;
	};
	merchantId?: string;
	billerTradeName?: string;
	merchantTradeName?: string;
	billerShortName?: string;
	billerCode?: string;
	industry?: string;
	otherIndustry?: string;
	subBrands?: string;
	website?: string;
	legalStructure?: string;
	otherLegalStructure?: string;
	tin?: string;
	consumerBaseCount?: string;
	placeOfIncorporation?: string;
	dateOfIncorporation?: DateValue;
};

const LogoField: React.FC<LogoFieldProps> = ({
	onSuccess,
	onClick,
	image,
	disabled,
}) => {
	const {
		value: isUploadLogoModalOpen,
		valueOn: showUploadLogoModal,
		valueOff: hideUploadLogoModal,
	} = useToggle();

	return (
		<div className={styles.logoFieldContainer}>
			<div className={cx(styles.avatar, { [styles.hasImage]: !!image })}>
				{image ? (
					<img src={image} className={styles.image} alt="biller-logo" />
				) : (
					<FaUserCircle />
				)}
				{disabled ? (
					<Button className={styles.uploadBtn}>
						<BsPlus />
					</Button>
				) : (
					<Button className={styles.uploadBtn} onClick={showUploadLogoModal}>
						<BsPlus />
					</Button>
				)}
			</div>
			<div className={styles.logoLabelContainer}>
				<div className={styles.logoLabel}>Upload Logo</div>
				<div className={styles.logoSubtext}>
					<em>*Maximum file size accepted:</em>
					<span className={styles.logoSubtextValue}>10MB</span>
				</div>
				<div className={styles.logoSubtext}>
					<em>*File types accepted:</em>
					<span className={styles.logoSubtextValue}>.jpg, .jpeg, .png</span>
				</div>
			</div>
			<UploadLogoModal
				open={isUploadLogoModalOpen}
				onClose={hideUploadLogoModal}
				onSuccess={onSuccess}
				url='/v1/biller-logo/url'
			/>
		</div>
	);
};

type Props = {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	showOtherIndustry?: boolean;
	showOtherLegalStructure?: boolean;
	disabled?: boolean;
};

function useToggleOthersField(
	value: any,
	predicate: (v: any) => boolean,
	callback?: () => void
) {
	const {
		value: isOthersShown,
		valueOn: showOtherIndustry,
		valueOff: hideOtherIndustry,
	} = useToggle();

	useEffect(() => {
		if (predicate(value)) {
			showOtherIndustry();
		} else {
			hideOtherIndustry();
			callback && callback();
		}
	}, [value]);

	return isOthersShown;
}

const BasicInformation: React.FC<Props> = ({ control, setValue, disabled }) => {
	const industry = useWatch({ control, name: `basicInformation.industry` });
	const legalStructure = useWatch({
		control,
		name: `basicInformation.legalStructure`,
	});

	const isOtherIndustryShown = useToggleOthersField(
		industry,
		(v) => v && v.toLowerCase() === 'others',
		() =>
			setValue('basicInformation.otherIndustry', '', { shouldValidate: true })
	);
	const isOtherLegalStructureShown = useToggleOthersField(
		legalStructure,
		(v) => v && v.toLowerCase() === 'others',
		() =>
			setValue('basicInformation.otherLegalStructure', '', {
				shouldValidate: true,
			})
	);

	const [logo, setLogo] = useState('');

	const handleUploadSuccess = (data: UploadLogoResponse) => {
		const { objectKey, url, file } = data;
		setValue('basicInformation.logo', {
			objectKey,
			url,
			filename: file?.name,
		});

		setLogo(URL.createObjectURL(data.file));
	};

	return (
		<Section title="Biller Basic Information">
			<SectionRow verticalAlign="end">
				<Grid column size={1} of={3}>
					<LogoField
						onSuccess={handleUploadSuccess}
						image={logo}
						onClick={() => console.log('Upload Clicked')}
						disabled={disabled}
					/>
				</Grid>
				<Grid container column verticalAlign="start" size={2} of={3}>
					<TextField
						label="Merchant ID"
						required
						disabled={disabled}
						control={control}
						name="basicInformation.merchantId"
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<TextField
						label="Biller Trade Name"
						required
						disabled={disabled}
						control={control}
						name="basicInformation.billerTradeName"
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Merchant Trade Name"
						required
						disabled={disabled}
						control={control}
						name="basicInformation.merchantTradeName"
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Biller Short Name"
						required
						disabled={disabled}
						control={control}
						name="basicInformation.billerShortName"
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<TextField
						label="Biller Code"
						required
						disabled={disabled}
						control={control}
						name="basicInformation.billerCode"
					/>
				</Grid>
				<Grid
					container
					column
					size={2}
					of={3}
					className={styles.selectWithOthersContainer}
				>
					<Grid
						column
						size={isOtherIndustryShown ? 2 : 1}
						of={isOtherIndustryShown ? 3 : 2}
					>
						<SelectFieldWithOthers
							label="Industry"
							required
							showOthersInputField={isOtherIndustryShown}
							disabled={disabled}
							control={control}
							name="basicInformation.industry"
							othersInputName="basicInformation.otherIndustry"
							options={Industries.map((i) => ({
								label: i,
								value: i,
							}))}
						/>
					</Grid>
					<Grid column size={1} of={isOtherIndustryShown ? 3 : 2}>
						<TextField
							label="Sub-Brands"
							disabled={disabled}
							optional
							control={control}
							name="basicInformation.subBrands"
						/>
					</Grid>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<TextField
						label="Place of Incorporation"
						disabled={disabled}
						required
						control={control}
						name="basicInformation.placeOfIncorporation"
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectDatepicker
						label="Date of Incorporation"
						disabled={disabled}
						required
						name="basicInformation.dateOfIncorporation"
						control={control}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="Website Address"
						disabled={disabled}
						optional
						control={control}
						name="basicInformation.website"
					/>
				</Grid>
			</SectionRow>
			<Grid container gutters>
				<Grid
					container
					column
					size={2}
					of={3}
					className={styles.selectWithOthersContainer}
				>
					<Grid
						column
						size={isOtherLegalStructureShown ? 2 : 1}
						of={isOtherLegalStructureShown ? 3 : 2}
					>
						<SelectFieldWithOthers
							label="Legal Structure"
							disabled={disabled}
							required
							showOthersInputField={isOtherLegalStructureShown}
							control={control}
							name="basicInformation.legalStructure"
							othersInputName="basicInformation.otherLegalStructure"
							options={LegalStructure.map((i) => ({
								label: i,
								value: i,
							}))}
						/>
					</Grid>
					<Grid column size={1} of={isOtherLegalStructureShown ? 3 : 2}>
						<TextField
							label="TIN"
							disabled={disabled}
							required
							control={control}
							name="basicInformation.tin"
						/>
					</Grid>
				</Grid>

				<Grid column size={1} of={3}>
					<TextField
						label="Consumer Base Count"
						disabled={disabled}
						required
						control={control}
						name="basicInformation.consumerBaseCount"
						formatValue={(v) => {
							try {
								const sanitized = v.replaceAll(',', '');
								if (!isANumber(sanitized)) return v;
								return addThousandsSeparator(sanitized, 0, ',', true);
							} catch (error) {
								return v;
							}
						}}
					/>
				</Grid>
			</Grid>
		</Section>
	);
};

export default BasicInformation;