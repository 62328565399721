import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './FilePreview.module.css';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/ic-download2.svg';
import { ReactComponent as ViewIcon } from 'assets/icons/ic-view.svg';
import { ReactComponent as RetryIcon } from 'assets/icons/ic-retry.svg';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import HTTP from '../../helpers/ApiClient';
import axios, { CancelTokenSource } from 'axios';
import FileThumbnail from './FileThumbnail';
import { getContentType } from 'utils/fileTypes';
import { ReducerStateType } from 'redux/modules/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { resetToken, setIsInProgress } from 'redux/modules/timeout';

type Props = {
	file: File & { status?: string };
	fileName: string;
	url: string;
	fileKey?: string;
	index: number;
	onView?: () => void;
	onDelete?: (fileKey, uploadRequest) => void;
	emitSuccess?: (file_name) => void;
	updateFileStatus: (s, i) => void;
	isMainError?: boolean;
	status?: string;
	uploadUrl: string;
	timeout?: boolean;
};

const FilePreview: React.FC<Props> = ({
	file,
	fileName,
	url,
	fileKey,
	index,
	onView = () => {},
	onDelete = (fileKey) => {
		return fileKey;
	},
	emitSuccess = (file_name) => {
		return file_name;
	},
	updateFileStatus,
	isMainError,
	status,
	uploadUrl,
}) => {
	const [isHover, setHover] = useState(false);
	const [progress, setProgress] = useState(1);
	const [isTimeout, setIsTimeout] = useState(false);
	const fileUrl = url;
	const uploadRequest = useSelector<ReducerStateType>(
		(state) => state.timeout.uploadRequest
	) as CancelTokenSource;
	const dispatch = useDispatch();

	const failedToUpload = 'Failed to Upload';
	const [errorMessage, setErrorMessage] = React.useState(failedToUpload);

	useEffect(() => {
		if (!isMainError && status === 'retry') {
			uploadFile();
		}
	}, [isMainError]);

	const doHover = (e: any) => {
		setHover(true);
	};

	const stopHover = (e: any) => {
		setHover(false);
	};

	const emitView = () => {
		onView();
	};

	const emitDelete = () => {
		onDelete(fileKey, uploadRequest);
	};

	const onSuccess = (file_name) => {
		emitSuccess(file_name);
	};

	const uploadFile = () => {
		setIsTimeout(false);
		let uploadTick = 0;
		dispatch(setIsInProgress(true));
		HTTP.get(uploadUrl, {
			cancelToken: uploadRequest?.token ?? null,
			params: {
				fileName: file?.name,
				contentType: file?.type || getContentType(file),
			},
			onUploadProgress: (progressEvent) => {
				const totalLength = progressEvent.lengthComputable
					? progressEvent.total
					: progressEvent.target.getResponseHeader('content-length') ||
					  progressEvent.target.getResponseHeader(
							'x-decompressed-content-length'
					  );
				if (totalLength !== null) {
					setProgress(Math.round(progressEvent.loaded * 10) / totalLength);
					uploadTick++;
				}
			},
		})
			.then((response) => {
				const urlApiResponse = response;
				const formData = new FormData();
				Object.entries(response.data.presigned_keys.fields).forEach(
					([key, value]: any) => {
						formData.append(key, value);
					}
				);
				formData.append('file', file);
				axios
					.post(response.data.presigned_keys.url, formData, {
						cancelToken: uploadRequest?.token,
						onUploadProgress: (progressEvent) => {
							const totalLength = progressEvent.lengthComputable
								? progressEvent.total
								: progressEvent.target.getResponseHeader('content-length') ||
								  progressEvent.target.getResponseHeader(
										'x-decompressed-content-length'
								  );

							if (totalLength !== null) {
								setProgress(
									10 + Math.round(progressEvent.loaded * 90) / totalLength
								);
								uploadTick++;
							}
						},
						...response.data.presigned_keys.fields,
					})
					.then(() => {
						onSuccess(urlApiResponse.data.file_name);
						updateFileStatus('success', index);

						if (uploadTick < 3) {
							let _percent = 0;
							const interval = setInterval(() => {
								_percent += 2;
								setProgress(_percent);
								if (_percent == 100) clearInterval(interval);
							}, 30);
						}
					})
					.catch((err) => {
						if (!axios.isCancel(err)) {
							setIsTimeout(true);
							updateFileStatus('failed', index);
						}
					})
					.finally(() => {
						dispatch(setIsInProgress(false));
						dispatch(resetToken());
					});
			})
			.catch((error) => {
				if (!axios.isCancel(error)) {
					setIsTimeout(true);
					setErrorMessage(failedToUpload);
					updateFileStatus('failed', index);
				}
				dispatch(setIsInProgress(false));
			})
			.finally(() => {
				dispatch(resetToken());
			});
	};

	useEffect(() => {
		setProgress(100);
	}, [isTimeout]);

	useEffect(() => {
		if (isTimeout || file?.status === 'failed') return;
		if (fileKey == '' && status === 'uploading') {
			uploadFile();
		}
	}, [isTimeout, file]);

	const onRefresh = () => {
		uploadFile();
	};

	const handleDownload = () => {
		axios
			.get(fileUrl, {
				responseType: 'blob',
				headers: {
					'Cache-Control': 'no-cache',
				},
			})
			.then((response) => {
				const f = fileName.substring(fileName.lastIndexOf('/') + 1);
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', f);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
	};

	return (
		<>
			<div>
				<div
					className={styles.fileCard}
					onMouseEnter={(e) => doHover(e)}
					onMouseLeave={(e) => stopHover(e)}
				>
					<div className={styles.mainDiv} style={{ pointerEvents: 'none' }}>
						<div className={styles.previewContainer}>
							<FileThumbnail
								fileType={fileName?.split('.').slice(-1)[0]}
								filePath={fileUrl}
							/>
						</div>
						<div className={styles.labelContainer}>
							<ProgressBar completed={progress} />
							<div
								className={
									isHover
										? styles.fileNameContainerHovered
										: styles.fileNameContainer
								}
							>
								{fileName}
							</div>
						</div>
					</div>
					<div
						className={cx(
							{ [styles.shown]: !isTimeout && isHover },
							styles.hoverDiv
						)}
					>
						<div className={styles.buttonContainer}>
							<ViewIcon
								className={cx(styles.previewButton)}
								style={{
									marginRight: '5px',
								}}
								onClick={emitView}
							/>
							{file != null ? (
								<DeleteIcon
									className={styles.previewButton}
									onClick={emitDelete}
								/>
							) : (
								<DownloadIcon
									className={styles.previewButton}
									onClick={handleDownload}
								/>
							)}
						</div>
					</div>
					<div
						className={cx(styles.hoverDiv, {
							[styles.shown]: isTimeout || progress < 100,
						})}
					>
						<div className={styles.buttonContainerDelete}>
							<DeleteIcon
								className={styles.previewButton}
								onClick={emitDelete}
							/>
						</div>

						{isTimeout && (
							<RetryIcon
								className={cx(styles.previewButton, styles.refreshButton)}
								onClick={onRefresh}
							/>
						)}
					</div>
				</div>
				{isTimeout && (
					<span style={{ color: 'red', font: '10px' }}>{errorMessage}</span>
				)}
			</div>
		</>
	);
};

export default React.memo(FilePreview);
