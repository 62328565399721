import React from 'react';
import cx from 'classnames';

import Table from 'components/CWSTable/Table';

import PartnerDetailsToolbar from './PartnerDetailsToolbar';
import { PartnerDetailsTableProps } from './types';
import styles from './PartnerDetailsBody.module.css';

const PartnerDetailsTable: React.FC<PartnerDetailsTableProps> = ({
	tableProps,
	filterProps,
}) => {
	const {
		columns,
		data,
		count,
		pageSize,
		onPageChange,
		onSort,
		sort,
		sortBy,
		page,
		showPagination,
		isAllRowColorWhite,
		className,
		ref,
	} = { ...tableProps };
	return (
		<div>
			<PartnerDetailsToolbar {...filterProps} />
			<Table
				columns={columns}
				data={data}
				count={count}
				pageSize={pageSize}
				onPageChange={onPageChange}
				onSort={onSort}
				sort={sort}
				sortBy={sortBy}
				page={page}
				className={cx(styles.tableContainer, className && className)}
				preHeader={null}
				modalIsOpen={false}
				isNotEvenRowColor={false}
				overFlow="hidden"
				showPagination={showPagination}
				isAllRowColorWhite={isAllRowColorWhite}
				tableWidthRef={ref}
			/>
		</div>
	);
};
export default PartnerDetailsTable;
