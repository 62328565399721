import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import PartnerDetails from 'components/PartnerDetails/PartnerDetails';
import PartnerAuditTrailList from 'components/PartnerAuditTrailList/PartnerAuditTrailList';
import { useState } from 'react';

const PartnerTab: React.FC = () => {
	// Add own state
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const primaryTabProps = {
		tabs: [
			{
				title: 'Partner Details',
				component: <PartnerDetails />,
			},
			{
				title: 'Audit Trail',
				scope: 'audit-trail',
				// lazy load if not yet selected
				component: selectedIndex === 1 ? <PartnerAuditTrailList /> : <></>,
			},
		],
		defaultIndex: 0,
	};

	return <PrimaryTab {...primaryTabProps} onSelect={setSelectedIndex} />;
};

export default PartnerTab;
