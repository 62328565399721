import BranchAddress from 'containers/ChannelManagement/Branch/BranchForm/BranchAddress/BranchAddress';
import BranchBasicInformation from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/BranchBasicInformation';
import BranchContactDetails from 'containers/ChannelManagement/Branch/BranchForm/BranchContactDetails/BranchContactDetails';
import OperatingSchedule from 'containers/ChannelManagement/Branch/BranchForm/OperatingSchedule/OperatingSchedule';
import { BranchFormProps } from 'containers/ChannelManagement/Branch/BranchForm/types';
import Divider from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/Divider/Divider';

const BranchForm: React.FC<BranchFormProps> = ({
	branchBasicInformationProp,
	branchAddress,
	branchContactDetails,
	operatingScheduleProps,
}) => {
	return (
		<>
			<BranchBasicInformation {...branchBasicInformationProp} />
			<Divider></Divider>
			<BranchAddress {...branchAddress}></BranchAddress>
			<Divider></Divider>
			<BranchContactDetails maxEntries={5} {...branchContactDetails} />
			<Divider></Divider>
			<OperatingSchedule {...operatingScheduleProps}></OperatingSchedule>
		</>
	);
};

export default BranchForm;
