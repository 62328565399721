import React from 'react';
import cx from 'classnames';
import styles from './ProductScrollableList.module.css';
import { ToggleButtonProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';

const ToggleButton: React.FC<ToggleButtonProps> = ({
	isDisabled,
	id,
	isChecked,
	onClickBtn,
	onChangeBtn,
}) => {
	const handleButtonClick = () => {
		onClickBtn(id);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChangeBtn(id, e);
	};

	return (
		<div className={styles.toggleButtonContainer}>
			<label className="slds-checkbox_toggle slds-grid">
				<input
					disabled={isDisabled}
					type="checkbox"
					name={`checkbox-toggle-${id}`}
					value={`checkbox-toggle-${id}`}
					aria-describedby={`checkbox-toggle-${id}`}
					checked={isChecked}
					onClick={handleButtonClick}
					onChange={handleInputChange}
				/>
				<span
					id={`checkbox-toggle-${id}`}
					className="slds-checkbox_faux_container"
					aria-live="assertive"
				>
					<span
						className={cx(
							styles.checkboxColor,
							'slds-checkbox_faux',
							styles.toggleButton
						)}
					></span>
					<span className="slds-checkbox_on"></span>
					<span className="slds-checkbox_off"></span>
				</span>
			</label>
		</div>
	);
};

export default ToggleButton;
