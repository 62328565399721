import { yupResolver } from '@hookform/resolvers/yup';
import {
	Tabs,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import Grid from 'components/Grid/Grid';
import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';
import client from 'helpers/ApiClient';
import { useEffect, useState, useMemo } from 'react';
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { connect, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { setCurrentBillerTab } from 'redux/modules/products';
import { FixMeLater, TPartnerModalConfirmProps } from 'types';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import PanelPlaceholder from 'components/Placeholders/PanelPlaceholder/PanelPlaceholder';

import { useList } from 'containers/ChannelManagement/List/hooks';
import styles from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrail.module.css';
import ChannelAuditTrailTable from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrailTable';
import ChannelAuditTrailStatusSelect from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditTrailStatusSelect';
//import ChannelBranchAuditModal from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/TableModal/ChannelBranchAuditModal';
import ChannelAuditLogsModal from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/LogsModal/ChannelAuditLogsModal';
import { useGetChannelAudit, useGetChannelLogs } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/AuditTrail/ChannelAuditQuery';

import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';

import SuccessModal, {
	SuccessModalBody,
	SuccessText,
	SuccessModalActions,
} from 'components/Modal/SuccessModal';
import axios from 'axios';

export type ChannelCredentialsFormData = {

};

type Props = {
	initialValues: ChannelCredentialsFormData;
	disabled?: boolean;
	onSubmit?: (values: any) => void;
};

const ChannelAuditTrail: React.FC<Props> = ({
	...props
}) => {
	const channelState = useSelector<ReducerStateType>((state) => state.channels);

	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [auditDetailId, setAuditDetailId] = useState();

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [count, setCount] = useState(0);
	const errorMessageDuringLoading = 'A problem occurred with loading the data';

	// export to types.ts
	interface ListSelection {
		id: number;
		name: string;
		logo: string;
		status: string;
		tpaAccount: string;
	}
	  
	const selectedChannel = useSelector<ReducerStateType, ListSelection>(
		(state) => state.channels.selectedChannel
	);

	const selectedBranch = useSelector<ReducerStateType, ListSelection>(
		(state) => state.channels.selectedBranch
	);

	const selectedTerminal = useSelector<ReducerStateType, ListSelection>(
		(state) => state.channels.selectedTerminal
	);

	const activeType: any = useSelector<ReducerStateType>(
		(state) => state.channels.activeType
	);

    const [tableFilter, setTableFilter] = useState({
        limit: 25,
        page: 1,
    });
	
	const panelPlaceholderProps = {
		title: 'No Selected Channel',
		subtitle: 'Please select a channel.',
	};

	const selectedId = () => {
		let id: number | undefined  = undefined;
		if (activeType === "terminal") {
			id = selectedTerminal?.id;
		} else if (activeType === "branch") {
			id = selectedBranch?.id;
		} else if (activeType === "channel") {
			id = selectedChannel?.id;
		}
		return id;
	};

	const { data: auditListData, isLoading: isQueryLoading, error, refetch: refetchAuditList  } = useGetChannelAudit(selectedId(), activeType, tableFilter);
	const { data: auditDetailData, isLoading: isQueryLoadingDetail, refetch: refecthAuditData  } = useGetChannelLogs(selectedId(), auditDetailId, activeType, tableFilter);

	useEffect(() => {
		refetchAuditList()
	  }, [refecthAuditData, selectedId(), activeType, tableFilter]);

	  useEffect(() => {
		refecthAuditData()
	  }, [refecthAuditData, auditDetailId]);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isChannelBranchAuditModalVisible,
		valueOn: showChannelBranchAuditModal,
		valueOff: hideChannelBranchAuditModal,
	} = useToggle();

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
		showErrorModal();
	};

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const handleOnClickViewLogs = async (row) => {
		setAuditDetailId(row.original.id);
		showChannelBranchAuditModal();
	};

	const handlePageChange = async (_page, _pageSize) => {
		const params = { page, pageSize };
		const { page: _, pageSize: __ } = params;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
		}
	};

	console.log("Audit Selected Channel: " + selectedChannel);

	console.log("Audit Selected Branch: " + selectedBranch);

	const mockData = [
		{
			created_at: '2023-07-07T12:34:56Z',
			logged_by: 'Old Branch Name',
			action: 'New Branch Name',
			source_ip: 'Old Branch Name',
			remark: 'New Branch Name',
		},
		{
			created_at: '2023-07-07T12:34:56Z',
			logged_by: 'Old Branch Name',
			action: 'New Branch Name',
			source_ip: 'Old Branch Name',
			remark: 'New Branch Name',
		},
		{
			created_at: '2023-07-07T12:34:56Z',
			logged_by: 'Old Branch Name',
			action: 'New Branch Name',
			source_ip: 'Old Branch Name',
			remark: 'New Branch Name',
		},
		{
			created_at: '2023-07-07T12:34:56Z',
			logged_by: 'Old Branch Name',
			action: 'New Branch Name',
			source_ip: 'Old Branch Name',
			remark: 'New Branch Name',
		},
		// Add more sample data objects as needed
	];

	const AUDIT_TRAIL_COLUMNS = (handleViewLogs) => [
		{
			Header: 'Date & Time',
			id: 'createdAt',
			sortable: false,
			width: '20%',
			accessor: ({ created_at }) => formatDate(created_at),
		},
		{
			Header: 'Logged By',
			id: 'loggedBy',
			sortable: false,
			width: '16%',
		},
		{
			Header: 'Action',
			id: 'action',
			sortable: false,
			width: '20%',
		},
		{
			Header: 'Source IP',
			id: 'ipAddress',
			sortable: false,
			width: '16%',
		},
		{
			Header: 'Remarks',
			id: 'remarks',
			sortable: false,
			width: '16%',
		},
		{
			Header: '',
			id: 'logs',
			sortable: false,
			width: '12%',
			Cell: ({ row }) => (
				<div className={styles.actionContainer}>
					<div
						className={cx(styles.action)}
						onClick={() => handleOnClickViewLogs(row)}
					>
					View Logs
					</div>
				</div>
			),
		},
	];

	return selectedBranch ? (
		<>
			{isLoading && <FullPageLoader open={isLoading} message={loadingMessage} />}
			{isSuccessModalShown && (
				<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								hideSuccessModal();
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>Timeout Error!</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorFooter}>Please try again</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								hideErrorModal();
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
		  { !isLoading && (
			<>
			<Grid container gutters="xx-small">
				<Grid column>
					<div className={styles.statusContainer}>
						<div className={styles.statusLabel}>Channel Status:</div>
						<div className={styles.statusField}>
							<ChannelAuditTrailStatusSelect value="FOR_ACTIVATION"/>
						</div>
					</div>
				</Grid>
			</Grid>
				<div className={styles.channelCeretificateTableContainer}>
					{!isQueryLoading && auditListData ? (
						<ChannelAuditTrailTable
							columns={AUDIT_TRAIL_COLUMNS(handleOnClickViewLogs)}
							data={auditListData.data}
							pageSize={pageSize}
							page={page}
							count={count}
							onPageChange={(page, limit) => {
							setTableFilter({ ...tableFilter, page, limit });
							}}
						/>
						) : isQueryLoading ? (
						<h1>Loading....</h1>
						) : (
							<PanelPlaceholder {...panelPlaceholderProps} />
					)}
				</div>
			</>
		  )}
		  {!isQueryLoadingDetail && auditDetailData ? (
			// <ChannelBranchAuditModal
			// 	isOpen={isChannelBranchAuditModalVisible}
			// 	data={[auditDetailData.data.result]}
			// 	onClose={() => {
			// 		hideChannelBranchAuditModal();
			// 	}}
				
			// />
			<ChannelAuditLogsModal
				listId={selectedId()}
				detailId={auditDetailId}
				activeType={activeType}
				isOpen={isChannelBranchAuditModalVisible}
				data={[auditDetailData.data]}
				onClose={() => {
					hideChannelBranchAuditModal();
				}}
				
			/>
			  ) : isQueryLoading ? (
				<PanelPlaceholder {...panelPlaceholderProps} />
			  ) : (
				  <div></div>
		  )}
		  
		</>
	  ) : (
		<PanelPlaceholder {...panelPlaceholderProps} />
	);
};

export default connect(
	(state: Record<string, FixMeLater>) => ({
		selectedChannel: state.channels.selectedChannel,
		selectedBranch: state.channels.selectedBranch,
	})
)(ChannelAuditTrail);
