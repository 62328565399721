import { DEFAULT_MAX_CHARS, FILE_FORMATS } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/constants';
import { checkTimeStampInterval } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/utils';
import { FixMeLater } from 'types';
import yup, {
	multipleEmailSchema,
	selectDefaultRequiredTemplate,
} from 'utils/formSchemas/common';
import { ValidationError } from 'yup';

export const csrColumnSchema = yup.object({
	id: yup.string().required(),
	value: yup.string().optional(),
	code: yup.string().optional(),
	sample: yup.string().optional(),
	is_required: yup.boolean().optional(),
	ordinal: yup.number().required(),
});

const requiredStringWithMax = yup.string().max(DEFAULT_MAX_CHARS).required();

const transferProtocolInformation = yup.object({
	host: requiredStringWithMax.label('Host'),
	port: requiredStringWithMax.label('Port'),
	username: requiredStringWithMax.label('Username'),
	password: requiredStringWithMax.label('Password'),
	path: requiredStringWithMax.label('Path'),
});

const filenameFormat = yup
	.string()
	.label('File Name Format')
	.oneOf(FILE_FORMATS)
	.required(selectDefaultRequiredTemplate);

const uploadSchedule = yup
	.string()
	.label('Upload Schedule')
	.oneOf(['SAME', 'NEXT'])
	.required(selectDefaultRequiredTemplate);

const uploadFrequency = yup
	.number()
	.label('Upload Frequency')
	.typeError('Input numeric value from 1-25')
	.min(1, 'Input value greater than 0')
	.max(25, ({ max }) => `Input value less than or equal to ${max}`)
	.required()
	.default(1);

const uploadConfiguration = yup.object({
	filenameFormat,
	uploadSchedule,
	uploadFrequency,
	timestamps: yup
		.array()
		.of(
			yup.object({
				generationTime: yup
					.string()
					.label('Generation Time')
					.required()
					.when('timeOfUpload', (timeOfUpload, s) => {
						return s.test(
							'generationTime-custom-validation',
							'Generation Time and Time of Upload should be set with atleast 30 minutes interval to consider the processing time of the report.',
							(value) => checkTimeStampInterval(value, timeOfUpload)
						);
					})
					.test({
						name: 'generationTime-custom-validation',
						test: (value, context) => {
							let testResult: boolean | ValidationError = true;

							//-------------Unique Validation-------------
							//converted as 'FixMeLater' because I need to use the 'from' variable
							//updating yup to latest version can access 'from' directly
							const ctx = context as FixMeLater;
							//'from' is an array holding all the parents schema and value
							if (ctx.from !== undefined) {
								//access the timestamps array values
								const timestamps = ctx.from[1].value.timestamps;
								//get the index of the current iteration while validating each item in the array
								//other item in the array will be validated as well but whatever the result is ignored
								const idx = ctx.options.index as number;
								//remove the current item to avoid self comparing later
								const timestampsFiltered = timestamps?.filter(
									(item, index) => index !== idx
								);
								//transform array to contains array of codes only
								const codesArray = timestampsFiltered?.map(
									(item) => item.generationTime
								);
								//check if current value already exist
								if (codesArray?.includes(value as string)) {
									testResult = context.createError({
										message: 'Selected time already used.',
									});
								}
							}

							return testResult;
						},
					}),
				timeOfUpload: yup
					.string()
					.nullable()
					.label('Time of Upload')
					.required()
					.test({
						name: 'timeOfUpload-custom-validation',
						test: (value, context) => {
							let testResult: boolean | ValidationError = true;
							//-------------Time Interval Validation-------------
							const generationTime = context.parent.generationTime;
							const timeOfUpload = value;
							if (!checkTimeStampInterval(generationTime, timeOfUpload))
								testResult = context.createError({
									message:
										'Time of Upload should be set with atleast 30 minutes interval to consider the processing time of the report.',
								});

							//-------------Unique Validation-------------
							//converted as 'FixMeLater' because I need to use the 'from' variable
							//updating yup to latest version can access 'from' directly
							const ctx = context as FixMeLater;
							//'from' is an array holding all the parents schema and value
							if (ctx.from !== undefined) {
								//access the timestamps array values
								const timestamps = ctx.from[1].value.timestamps;
								//get the index of the current iteration while validating each item in the array
								//other item in the array will be validated as well but whatever the result is ignored
								const idx = ctx.options.index as number;
								//remove the current item to avoid self comparing later
								const timestampsFiltered = timestamps?.filter(
									(item, index) => index !== idx
								);
								//transform array to contains array of codes only
								const codesArray = timestampsFiltered?.map(
									(item) => item.timeOfUpload
								);
								//check if current value already exist
								if (codesArray?.includes(value as string)) {
									testResult = context.createError({
										message: 'Selected time already used.',
									});
								}
							}

							return testResult;
						},
					}),
				coveredSchedule: yup
					.string()
					.label('Covered Schedule')
					.required(selectDefaultRequiredTemplate),
				coveredTime: yup.object({
					from: yup
						.string()
						.label('Covered Time From')
						.required('Enter covered time'),
					to: yup
						.string()
						.label('Covered Time To')
						.required('Enter covered time'),
				}),
			})
		)
		.min(1),
});

const csrConfiguration = uploadConfiguration.shape({
	columns: yup.array().of(csrColumnSchema),
});

const baseSchema = yup.object({
	csrConfiguration,
	uploadConfiguration,
});

const sftpSchema = yup.object({
	baseInfo: transferProtocolInformation,
	csrConfiguration,
	uploadConfiguration,
});

const smtpSchema = baseSchema.shape({
	senderEmail: yup.string().label('Sender Email Address').email(),
	receiverEmail: multipleEmailSchema.label('Receiver Email Address'),
});

export const schema = yup.object({
	sftp: sftpSchema,
	smtp: smtpSchema,
});
