import Grid from 'components/Grid/Grid';
import { SelectFieldWithOthers } from 'components/Inputs/SelectFieldWithOthers/SelectFieldsWithOthers';
import TimeInput from 'components/Inputs/TimeField/TimeField';
import { SectionRow } from 'components/Section/Section';
import { TimeEntryProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/TimeEntry/types';
import { appendSuffix } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/TimeEntry/utils';
import { PartnerReportsFormData } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';
import { useFormContext } from 'react-hook-form';
import styles from './style.module.css';
import { COVERED_SCHEDULE } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/Configurations/TimeEntry/constants';

const TimeEntry: React.FC<TimeEntryProps> = ({
	name,
	labelSuffix,
	disabled,
}) => {
	const { control } = useFormContext<PartnerReportsFormData>();

	return (
		<SectionRow className={styles.timeContainer}>
			<Grid column container vertical className={styles.time}>
				<Grid column container>
					<Grid column size={1} of={2}>
						<TimeInput
							label={appendSuffix('Generation Time', labelSuffix)}
							required
							control={control}
							name={`${name}.generationTime`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={2}>
						<TimeInput
							label={appendSuffix('Time of Upload', labelSuffix)}
							required
							control={control}
							name={`${name}.timeOfUpload`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
				</Grid>
				<Grid column container>
					<Grid column size={1} of={3}>
						<SelectFieldWithOthers
							label={appendSuffix('Covered Schedule', labelSuffix)}
							required
							control={control}
							name={`${name}.coveredSchedule`}
							shouldUnregister
							options={COVERED_SCHEDULE}
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<TimeInput
							label={appendSuffix('Covered Time (from)', labelSuffix)}
							required
							control={control}
							name={`${name}.coveredTime.from`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
					<Grid column size={1} of={3}>
						<TimeInput
							label={appendSuffix('Covered Time (to)', labelSuffix)}
							required
							control={control}
							name={`${name}.coveredTime.to`}
							shouldUnregister
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>
		</SectionRow>
	);
};

export default TimeEntry;
