import React from "react";
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import cx from 'classnames';
import styles from './Reports.module.css'
const primaryTabProps = {
    tabs: [
        {
            title: 'Product',
            component: '',
        },
        {
            title: 'Channel',
            component: '',
        },
        {
            title: 'Reconciliation',
            component: '',
        },
        {
            title: 'Accounting',
            component: '',
        },
        {
            title: 'RPA',
            component: '',
        },
        {
            title: 'Other Reports',
            component: '',
        },
    ],
    defaultIndex: 1,
};
const Reports: React.FC = (props) => {
    return (
        <div className={cx('slds-card', styles.paperContainer)}>
            <div className='slds-grid sls-grid_align-spread'>
                <div className={cx('slds-col', styles.title)}>Reports</div>
            </div>
            <div className='slds-grid sls-grid_align-spread'>
                <PrimaryTab {...primaryTabProps} />
            </div>
        </div>
    )
}
export default Reports