import { ReactComponent as AddIcon } from 'assets/icons/ic-add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete.svg';
import Button from "components/Buttons/Button";
import CollectionDepositoryAccountForm from "components/CollectionDepositoryAccount/CollectionDepositoryAccountForm";
import Section from "components/Section/Section";
import { ReactNode, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useToggle } from 'utils/hooks';
import styles from './style.module.css';
import SuccessModal, { SuccessModalActions, SuccessModalBody, SuccessText } from 'components/Modal/SuccessModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ErrorModal, { ErrorModalActions, ErrorModalBody, ErrorSubText, ErrorText } from 'components/Modal/ErrorModal';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { ContractDetailsType } from '../ContractDetailsSchemas';

type Props = {
    title: string;
    disabled: boolean;
    name: any;
    addButtonTitle?: string;
}

const CollectionDepositoryAccount: React.FC<Props> = ({ disabled = false, title, name, addButtonTitle }) => {
    const [successMessage, setSuccessMessage] = useState<ReactNode>('');
    const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => { } });
    const [errorHeader, setErrorHeader] = useState<ReactNode>('');
    const [errorMessage, setErrorMessage] = useState<ReactNode>('');
    const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => { } });
    const [confirmationHeader, setConfirmationHeader] = useState<ReactNode>('');
    const [confirmationMessage, setConfirmationMessage] = useState<ReactNode>('');
    const [onConfirmationClose, setOnConfirmationClose] = useState({
        action: () => { },
    });
    const [confirmBtnOnClick, setConfirmBtnOnClick] = useState({
        action: () => { },
    });
    const { control, watch } = useFormContext<ContractDetailsType>();
    const { append, remove, fields } = useFieldArray({
        control,
        name,
    })
    const {
        value: isConfirmationModalOpen,
        valueOn: showConfirmationModal,
        valueOff: hideConfirmationModal,
    } = useToggle();
    const {
        value: isErrorModalOpen,
        valueOn: showErrorModal,
        valueOff: hideErrorModal,
    } = useToggle();
    const {
        value: isSuccessModalOpen,
        valueOn: showSuccessModal,
        valueOff: hideSuccessModal,
    } = useToggle();
    const showConfirmationMessage = (
        header: any,
        message: any,
        onConfirmBtnClick?: () => void,
        onClose?: () => void
    ) => {
        setConfirmationHeader(header);
        setConfirmationMessage(message);
        showConfirmationModal();
        setConfirmBtnOnClick({
            action: () => {
                hideConfirmationModal();
                onConfirmBtnClick && onConfirmBtnClick();
            },
        });
        setOnConfirmationClose({
            action: () => {
                hideConfirmationModal();
                onClose && onClose();
            },
        });
    };
    const handleAddEntry = () => {
        append({
            accountName: '',
            accountNumber: '',
            accountType: '',
            bankBranch: '',
            bankName: '',
        })
    }
    const showSuccessMessage = (message: any, onDoneBtnClick?: () => void) => {
        setSuccessMessage(message);
        showSuccessModal();
        setDoneBtnOnClick({
            action: () => {
                hideSuccessModal();
                onDoneBtnClick && onDoneBtnClick();
            },
        });
    };
    const handleConfirmDeleteEntry = (index: number) => {
        if (index) {
            showConfirmationMessage(
                `Delete ${title} ${index + 1}`,
                <>
                    <span>
                        Are you sure you want to delete{' '}
                        <span className={styles.confirmTextName}>
                            {title} {index + 1}?
                        </span>
                    </span>
                </>,
                () => {
                    remove(index);
                    showSuccessMessage(
                        <div className={styles.successModalText}>
                            {title} {index + 1} successfully deleted.
                        </div>
                    );
                    autoScroll('head');
                }
            );
        }
    };

    const lastEntryRef = useRef<HTMLDivElement>(null!);
    const firstEntryRef = useRef<HTMLDivElement>(null!);

    const autoScroll = (scrollAction: string) => {
        if (scrollAction === 'tail') {
            lastEntryRef.current &&
                lastEntryRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (scrollAction === 'head') {
            firstEntryRef.current &&
                firstEntryRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            {
                fields.map((field, index) => {
                    const n = `${name}[${index}]`
                    const titleIncrement = `${title} ${index > 0 ? ` ${index + 1}` : ''}`
                    return (
                        <Section
                            key={field.id}
                            titleClassName={styles.sectionTitle}
                            title={() => {
                                return (
                                    <>
                                        {titleIncrement}
                                        <div style={{ float: 'right' }}>
                                            {index === 0 && !disabled ? (
                                                <Button
                                                    fullWidth
                                                    onClick={() => {
                                                        handleAddEntry();
                                                        autoScroll('tail');
                                                    }}
                                                    className={styles.addButton}
                                                    disabled={disabled}
                                                >
                                                    <AddIcon /> {addButtonTitle ? addButtonTitle : 'Add account'}
                                                </Button>
                                            ) : (
                                                !disabled && (
                                                    <Button
                                                        onClick={() => handleConfirmDeleteEntry(index)}
                                                        disabled={disabled}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    </>
                                );
                            }}
                        >
                            <CollectionDepositoryAccountForm name={n} control={control} />
                        </Section>
                    )
                })
            }

            {isSuccessModalOpen && (
                <SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
                    <SuccessModalBody>
                        <SuccessText>
                            <div className={styles.successHeader}>{successMessage}</div>
                        </SuccessText>
                    </SuccessModalBody>
                    <SuccessModalActions>
                        <PrimaryButton
                            className={styles.successModalBtn}
                            onClick={() => {
                                doneBtnOnClick.action();
                            }}
                        >
                            Done
                        </PrimaryButton>
                    </SuccessModalActions>
                </SuccessModal>
            )}
            {isErrorModalOpen && (
                <ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
                    <ErrorModalBody>
                        <ErrorText>{errorHeader}</ErrorText>
                        <ErrorSubText>{errorMessage}</ErrorSubText>
                    </ErrorModalBody>
                    <ErrorModalActions>
                        <PrimaryButton
                            fullWidth
                            onClick={() => {
                                retryBtnOnClick.action();
                            }}
                            className={styles.errorModalBtn}
                        >
                            Close
                        </PrimaryButton>
                    </ErrorModalActions>
                </ErrorModal>
            )}
            {isConfirmationModalOpen && (
                <ConfirmationModal
                    isOpen={isConfirmationModalOpen}
                    heading={confirmationHeader}
                    message={confirmationMessage}
                    onClose={() => {
                        onConfirmationClose.action();
                    }}
                    onCancelBtnClick={() => {
                        onConfirmationClose.action();
                    }}
                    onConfirmBtnClick={() => {
                        confirmBtnOnClick.action();
                    }}
                    cancelBtnLabel="Back"
                />
            )}
        </>
    )
}

export default CollectionDepositoryAccount