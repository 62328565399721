import VoidListFilter from './VoidListFilter';
import VoidListTable from './VoidListTable';
import HTTP from 'helpers/ApiClient';
import { useEffect, useMemo, useRef, useState } from 'react';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useToggle } from 'utils/hooks';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './VoidList.module.css';
import { resolveValue } from 'utils/common';
import { useSelector, useDispatch } from 'react-redux';
import { get as _get } from 'lodash';
import moment from 'moment';
import { showAccessDeniedModal } from 'redux/modules/access';
import { calibrateDate } from 'utils/date';
import VoidRequestModal from './VoidRequestModal';
import { UserInfoUtil } from 'utils/session';
import ConfirmModal from 'components/Modal/ConfirmModal';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import { Spinner } from '@salesforce/design-system-react';
import SuccessModalContainer from 'components/Modal/SuccessModalContainer';

const CREATE_CHANNEL_WALLET_SCOPE_NAME = 'cws.wallet.create';

type TInitialVal = {
	dateFrom: any;
	dateTo: any;
	channelName: string;
	voidReason: string;
	walletType: string;
	status: string;
	transactionNumber: any;
};
const initialValues: TInitialVal = {
	dateFrom: null,
	dateTo: null,
	channelName: '',
	voidReason: '',
	walletType: '',
	status: '',
	transactionNumber: '',
};
type TValues = {
	page: number;
	limit: number;
	sortBy: string;
	sort: any;
	dateCreatedFrom: any;
	dateCreatedTo: any;
	dateFromAndTo: string;
	channelName: string;
	status: string;
	transactionNumber: string;
	voidReason: string;
};

type SelectedValues = {
	account_number: string;
	amount: number;
	biller_name: string;
	biller_type: string;
	branch: string;
	check_no: string;
	contact_number: string;
	created_at: string;
	deposit_date: string;
	depository_account_number: string;
	incident_report: string;
	payment_method: string;
	reason: string;
	reference_id: string;
	request_date: string;
	requestor_name: string;
	status: string;
	tpaid: string;
	transaction_date: string;
	transaction_reference_number: string;
};
const InitialSelectedValues = {
	account_number: '',
	amount: 0,
	biller_name: '',
	biller_type: '',
	branch: '',
	check_no: '',
	contact_number: '',
	created_at: '',
	deposit_date: '',
	depository_account_number: '',
	incident_report: '',
	payment_method: '',
	reason: '',
	reference_id: '',
	request_date: '',
	requestor_name: '',
	status: '',
	tpaid: '',
	transaction_date: '',
	transaction_reference_number: '',
};

async function getVoidList(values: any = {}) {
	const {
		page = 1,
		limit = 25,
		sortBy,
		sort,
		dateCreatedFrom,
		dateCreatedTo,
		dateFromAndTo,
		channelName,
		status,
		transactionNumber,
		voidReason,
	}: TValues = values;

	const params = {
		sortBy,
		sort,
		page,
		limit,
		dateCreatedFrom,
		dateCreatedTo,
		dateFromAndTo,
		channelName: resolveValue(channelName),
		status: resolveValue(status),
		transactionNumber: resolveValue(transactionNumber),
		voidReason,
	};

	const result = await HTTP.get('/v2/transaction/void/request', { params });
	return result.data;
}

const VoidList = () => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [sortBy, setSortBy] = useState('date_created');
	const [sort, setSort] = useState('desc');

	const { username, emailAddress } = UserInfoUtil.get();

	// modal states
	const [confirmApproveModal, setConfirmApproveModal] = useState(false);
	const [confirmRejectModal, setConfirmRejectModal] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [successModalMessage, setSuccessModalMessage] = useState('');
	const [errorModalMessage, setErrorModalMessage] = useState('');

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	/** Void status change modal toggle */
	const {
		value: isVoidStatusChangeErrorModalOpen,
		valueOn: showVoidStatusChangeErrorModal,
		valueOff: hideVoidStatusChangeErrorModal,
	} = useToggle();

	const {
		value: isVoidStatusChanging,
		valueOn: enableVoidStatusLoading,
		valueOff: disableVoidStatusLoading,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const voidRetryCallback = useRef<(() => Promise<void>) | null>(null);

	const dispatch = useDispatch();

	const [lastParams, setLastParams] = useState({});

	const scopes = useSelector((state) =>
		_get(state, ['userInfo', 'scopes'], [])
	);

	const createChannelWalletScope = useMemo(
		() =>
			scopes.find(
				({ scope }: any) => scope === CREATE_CHANNEL_WALLET_SCOPE_NAME
			),
		[scopes]
	);

	const fetchVoids = async (values = {}, retry = false) => {
		const doRequest = async (p: any) => {
			try {
				showLoading();
				setLastParams(p);
				const result = await getVoidList(p);
				const { meta } = result;
				setData(result.data);
				setPage(meta.page);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e: any) {
				if (e.response.status === 403) {
					dispatch(showAccessDeniedModal());
				} else {
					showErrorModal();
				}
			} finally {
				hideLoading();
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const {
			dateFrom: dateCreatedFrom,
			dateTo: dateCreatedTo,
			...rest
		}: any = values;

		const dates = calibrateDate(dateCreatedFrom, dateCreatedTo);

		const params = {
			sortBy,
			sort,
			page,
			limit: pageSize,
			...dates,
			...rest,
			...values,
		};

		await doRequest(params);
	};
	useEffect(() => {
		fetchVoids();
	}, []);

	const handleFilterSubmit = async (values: any) => {
		const {
			channelName = {},
			dateFrom,
			dateTo,
			serviceType,
			status,
			transactionNumber,
			voidReason,
		} = values;
		await fetchVoids({
			channelName: channelName.label,
			dateFrom,
			dateTo,
			serviceType,
			status,
			transactionNumber,
			voidReason,
		});
	};

	const handlePageChange = async (page: number, pageSize: number) => {
		setPage(page);
		setPageSize(pageSize);
		await fetchVoids({...lastParams, page, limit: pageSize });
	};

	const handleSort = async (sortBy: string, sort: any) => {
		setSortBy(sortBy);
		setSort(sort);
		await fetchVoids({ sortBy, sort });
	};

	const handleRetry = () => {
		hideErrorModal();
		if (lastParams) {
			fetchVoids(lastParams, true);
		}
	};

	const [isVoidRequestModalOpen, setIsVoidRequestModalOpen] = useState(false);
	const [selectedVoidRequest, setSelectedVoidRequest] =
		useState<SelectedValues>(InitialSelectedValues);

	/** Triggers upon viewing void request on a modal */
	const handleViewRequest = (voidRequest: any = null) => {
		if (voidRequest) {
			setSelectedVoidRequest(voidRequest);
			setIsVoidRequestModalOpen(true);
		} else {
			setSelectedVoidRequest(InitialSelectedValues);
			setIsVoidRequestModalOpen(false);
		}
	};

	/**
	 * Main method to change void request status
	 */
	const handleChangeRequestStatus = async (
		voidRequest: any,
		status: 'Approved' | 'Reject'
	) => {
		enableVoidStatusLoading();
		try {
			const data = {
				voidRequestInfo: {
					status,
					biller_type: voidRequest?.biller_type,
					for_cancellation_within_day: voidRequest?.for_cancellation_within_day,
					reason: voidRequest?.reason,
					username,
					emailAddress,
				},
			};
			await HTTP.post(
				`/v2/transaction/void/${voidRequest?.transaction_reference_number}/approve-reject`,
				data
			);

			if (status === 'Approved') {
				setSuccessModalMessage("You've approved the void request");
			}

			if (status === 'Reject') {
				setSuccessModalMessage("You've rejected the void request");
			}
			setSuccessModal(true);
			fetchVoids();
			setSelectedVoidRequest(InitialSelectedValues);
			setIsVoidRequestModalOpen(false);
			hideVoidStatusChangeErrorModal();
		} catch (err: any) {
			if (err?.message === 'Network Error' || err?.response?.status === 400) {
				const voidAction =
					status.toLowerCase() === 'approved' ? 'approve' : 'reject';
				setErrorModalMessage(`Failed to ${voidAction} void request`);
				voidRetryCallback.current = async () => {
					await handleChangeRequestStatus(voidRequest, status);
				};
				showVoidStatusChangeErrorModal();
			}
		} finally {
			disableVoidStatusLoading();
			setConfirmApproveModal(false);
			setConfirmRejectModal(false);
		}
	};

	/** Opens up a modal to confirm request */
	const verifyChangeRequestStatus = async (
		voidRequest: any,
		status: 'Approved' | 'Reject'
	) => {
		setSelectedVoidRequest(voidRequest);
		if (status === 'Approved') {
			setConfirmApproveModal(true);
		}
		if (status === 'Reject') {
			setConfirmRejectModal(true);
		}
	};

	return (
		<>
			<VoidListFilter
				initialValues={initialValues}
				onSubmit={handleFilterSubmit}
				canCreateChannelWallet={!!createChannelWalletScope}
				initialArray={[]}
			/>
			<VoidListTable
				page={page}
				data={data}
				pageSize={pageSize}
				count={count}
				onPageChange={handlePageChange}
				onView={(value) => {
					setSelectedVoidRequest(value);
					setIsVoidRequestModalOpen(true);
				}}
				onSort={handleSort}
				sortBy={sortBy}
				sort={sort}
				onVoidStatusChange={verifyChangeRequestStatus}
			/>

			{isVoidRequestModalOpen && (
				<VoidRequestModal
					isDisplayOnly={true}
					selectedVoidRequest={selectedVoidRequest}
					isOpen={isVoidRequestModalOpen}
					onClose={handleViewRequest}
					onVoidStatusChange={verifyChangeRequestStatus}
				/>
			)}

			<FullPageLoader
				open={isLoading}
				message="Please wait while void request list is being loaded"
			/>

			{/* Confirm approve void request modal */}
			{confirmApproveModal && (
				<ConfirmModal
					open={confirmApproveModal}
					isLoading={isVoidStatusChanging}
					headerText={'Approve Void Request'}
					bodyText={[
						`Are you sure you want to approve Void Request ${selectedVoidRequest?.reference_id}`,
					]}
					onClose={() => {
						setConfirmApproveModal(false);
					}}
					confirmButton={{
						name: 'Approve',
						event: () =>
							handleChangeRequestStatus(selectedVoidRequest, 'Approved'),
					}}
					cancelButton={{
						name: 'Cancel',
						event: () => {
							setConfirmApproveModal(false);
						},
					}}
				/>
			)}

			{/* Confirm reject void request modal */}
			{confirmRejectModal && (
				<ConfirmModal
					open={confirmRejectModal}
					isLoading={isVoidStatusChanging}
					headerText={'Reject Void Request'}
					bodyText={[
						`Are you sure you want to reject Void Request ${selectedVoidRequest?.reference_id}`,
					]}
					onClose={() => {
						setConfirmRejectModal(false);
					}}
					confirmButton={{
						name: 'Reject',
						event: () =>
							handleChangeRequestStatus(selectedVoidRequest, 'Reject'),
					}}
					cancelButton={{
						name: 'Cancel',
						event: () => {
							setConfirmRejectModal(false);
						},
					}}
				/>
			)}

			{/* Success modal for void transaction */}
			{successModal && (
				<SuccessModal
					open={successModal}
					onClose={() => setSuccessModal(false)}
					className={styles.successModalContainer}
				>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successMessage}>{successModalMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.addressErrorCloseBtn}
							onClick={() => setSuccessModal(false)}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}

			{/* Error modal for void request status change */}
			{isVoidStatusChangeErrorModalOpen && (
				<ErrorModal
					open={isVoidStatusChangeErrorModalOpen}
					onClose={hideVoidStatusChangeErrorModal}
				>
					<ErrorModalBody>
						<div className={styles.errorBody}>
							<div className={styles.errorModalMessage}>
								{errorModalMessage}
							</div>
						</div>
						<div className={styles.errorFooter}>Please try again</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							disabled={isVoidStatusChanging}
							onClick={() => {
								if (voidRetryCallback?.current) {
									voidRetryCallback?.current();
								}
							}}
							className={styles.retryBtn}
						>
							{isVoidStatusChanging ? (
								<Spinner variant="base" size="small" />
							) : (
								'Retry'
							)}
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}

			{/* Error modal for not loading void request list */}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorBody}>
							<div>A problem occurred while</div>
							<div>loading the void request list.</div>
						</div>
						<div className={styles.errorFooter}>Please try again</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={handleRetry}
							className={styles.retryBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
		</>
	);
};

export default VoidList;
