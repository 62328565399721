import { LOCAL } from 'containers/ChannelManagement/Branch/BranchForm/BranchAddress/constants';
import {
	AREA,
	BARANGAY,
	BRANCH_CODE,
	BRANCH_NAME,
	BUILDING_NAME_OR_NUMBER,
	BUILDING_NAME_OR_NUMBER_LABEL,
	CHANNEL_ACCOUNT_CODE,
	CHANNEL_ACCOUNT_NAME,
	CHANNEL_TRANSACTION_TYPE,
	CHANNEL_TYPE,
	CIGNAL_TELLER_CODE,
	COUNTRY,
	LOCATION_BASE,
	MACHINE_LOCATION,
	MERALCO_PAYMENT_CENTER_CODE,
	MUNICIPALITY_OR_CITY,
	MUNICIPALITY_OR_CITY_LABEL,
	PLDT_TELLER_CODE,
	POS_TYPE,
	REGION,
	SETTLEMENT_SETUP,
	STATE_OR_PROVINCE,
	STATE_OR_PROVINCE_LABEL,
	STORE_FORMAT,
	STORE_TYPE,
	STREET,
	ZIP_CODE,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';
import _ from 'lodash';
import moment from 'moment';
import { YupMessage } from 'types';
import yup, {
	defaultMax255CharSpiel,
	defaultOnlyAlphaChar,
	yupValidateEmails,
} from 'utils/formSchemas/common';
import { validateLandlineNumber, validateMobile } from 'utils/validation';

export const branchBasicInformation = yup.object({
	[CHANNEL_ACCOUNT_NAME]: yup.string(),
	[CHANNEL_ACCOUNT_CODE]: yup.string(),
	[BRANCH_NAME]: yup
		.string()
		.required()
		.label(_.startCase(BRANCH_NAME))

		.max(255, 'You can only input a max of 255 characters.')
		.matches(/^[a-z|A-Z|0-9\s]*$/, {
			excludeEmptyString: false,
			message: `This field only allows alphanumeric characters.`,
		}),
	[BRANCH_CODE]: yup.string(),
	[CHANNEL_TRANSACTION_TYPE]: yup
		.string()
		.required()
		.label(_.startCase(CHANNEL_TRANSACTION_TYPE)),
	[CHANNEL_TYPE]: yup.string().required().label(_.startCase(CHANNEL_TYPE)),
	[POS_TYPE]: yup.string().required().label(_.startCase(POS_TYPE)),
	[SETTLEMENT_SETUP]: yup.string(),
	[STORE_TYPE]: yup.string().required().label(_.startCase(STORE_TYPE)),
	[STORE_FORMAT]: yup.string().required().label(_.startCase(STORE_FORMAT)),
	[MACHINE_LOCATION]: yup
		.string()
		.required()
		.label(_.startCase(MACHINE_LOCATION)),
	[PLDT_TELLER_CODE]: yup
		.string()
		.max(3, 'You can only input a max of 3 alphanumeric characters.')
		.matches(/^[a-z|A-Z|0-9\s]*$/, {
			excludeEmptyString: false,
			message: `This field only allows alphanumeric characters.`,
		}),
	[CIGNAL_TELLER_CODE]: yup
		.string()
		.max(3, 'You can only input a max of 3 alphanumeric characters.')
		.matches(/^[a-z|A-Z|0-9\s]*$/, {
			excludeEmptyString: false,
			message: `This field only allows alphanumeric characters.`,
		}),
	[MERALCO_PAYMENT_CENTER_CODE]: yup
		.string()
		.required()
		.label(_.startCase(MERALCO_PAYMENT_CENTER_CODE)),
});

export const branchAddress = yup.object({
	[LOCATION_BASE]: yup.string(),
	[COUNTRY]: yup
		.string()
		.required(`Select ${_.startCase(COUNTRY)}`)
		.label(_.startCase(COUNTRY)),
	[AREA]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${_.startCase(AREA)}`),
	}),
	[REGION]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${_.startCase(REGION)}`),
	}),
	[STATE_OR_PROVINCE]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${STATE_OR_PROVINCE_LABEL}`),
	}),
	[MUNICIPALITY_OR_CITY]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${MUNICIPALITY_OR_CITY_LABEL}`),
	}),
	[BARANGAY]: yup.string().when(LOCATION_BASE, {
		is: LOCAL,
		then: (schema) => schema.required(`Select ${_.startCase(BARANGAY)}`),
	}),
	[BUILDING_NAME_OR_NUMBER]: yup
		.string()
		.max(255, 'You have reached maximum character input.')
		.matches(/^[a-z|A-Z|0-9\s]*$/, {
			excludeEmptyString: false,
			message: `This field only allows alphanumeric characters.`,
		})
		.label(BUILDING_NAME_OR_NUMBER_LABEL)
		.when(LOCATION_BASE, {
			is: LOCAL,
			then: (schema) => schema.required(),
		}),
	[STREET]: yup
		.string()
		.max(255, 'You have reached maximum character input.')
		.matches(/^[a-z|A-Z|0-9\s]*$/, {
			excludeEmptyString: false,
			message: `This field only allows alphanumeric characters.`,
		}),
	[ZIP_CODE]: yup.string(),
});

export const branchContactDetails = yup
	.array()
	.ensure()
	.of(
		yup.object({
			name: yup
				.string()
				.max(255, defaultMax255CharSpiel)
				.required()
				.matches(/^[a-z|A-Z|0-9\s]*$/, {
					excludeEmptyString: false,
					message: defaultOnlyAlphaChar,
				})
				.label('Name of Branch Contact Person'),
			position: yup
				.string()
				.max(255, defaultMax255CharSpiel)
				.required()
				.matches(/^[a-z|A-Z|0-9\s]*$/, {
					excludeEmptyString: false,
					message: defaultOnlyAlphaChar,
				})
				.label('Position'),
			department: yup
				.string()
				.max(255, defaultMax255CharSpiel)
				.required()
				.matches(/^[a-z|A-Z|0-9\s]*$/, {
					excludeEmptyString: false,
					message: defaultOnlyAlphaChar,
				})
				.label('Department'),
			emailAddress: yup
				.array()
				.nullable()
				.of(yup.string().nullable())
				.ensure()
				.label('Email Address')
				.min(1, ({ label }) => `Input ${label}`)
				.test('isValidEmail', yupValidateEmails),
			telephoneNumber: yup
				.array()
				.nullable()
				.of(yup.string().nullable())
				.ensure()
				.label('Telephone Number')
				.test(
					'isValidLandlineNumber',
					({ value }: YupMessage): string => {
						const count = value?.filter(
							(a: string) => !validateLandlineNumber(a || '')
						).length;

						const reducedValue = value.reduce(
							(acc: number, val: string) => acc + (val ? val.length : 0),
							0
						);

						const totalLength = value ? reducedValue : 0;

						if (totalLength >= 255) {
							return 'You can only input a max of 255 characters.';
						}
						if (count >= 1) {
							return `${count} ${
								count > 1 ? 'inputs are' : 'input is'
							} invalid telephone number format`;
						}
						return '';
					},
					(v): boolean => {
						if (!v) return true;
						return v.every((a) => validateLandlineNumber(a || '', v.length));
					}
				),
			mobileNumber: yup
				.array()
				.nullable()
				.of(yup.string().nullable())
				.ensure()
				.label('Mobile Number')
				.min(1, ({ label }) => `Input ${label}`)
				.test(
					'isValidMobileNumber',
					({ value }: YupMessage): string => {
						const count = value?.filter(
							(a: string) => !validateMobile(a || '')
						).length;

						const reducedValue = value.reduce(
							(acc: number, val: string) => acc + (val ? val.length : 0),
							0
						);
						const totalLength = value ? reducedValue : 0;

						if (totalLength >= 255) {
							return 'You can only input a max of 255 characters.';
						}
						if (!count) return '';

						return 'Mobile number should be in +639XXXXXXXXX format.';
					},
					(v): boolean => {
						if (!v) return true;
						return v.every((a) => validateMobile(a || '', v.length));
					}
				),
		})
	);

export const branchOperatingSchedule = yup.object({
	schedule: yup.array().of(
		yup.object({
			day: yup.string(),
			startTime: yup
				.string()
				.nullable()
				.when('isSelected', {
					is: true,
					then: (schema) => schema.required(),
				})
				.label('Start Time'),
			endTime: yup
				.string()
				.nullable()
				.when('isSelected', {
					is: true,
					then: (schema) => schema.required(),
				})
				.label('End Time'),
			isSelected: yup.boolean(),
		})
	),
	depositConsolidation: yup.string(),
	taxPayerType: yup.string().required().label('Tax Payer Type'),
	activationDate: yup
		.string()
		.required()
		.label('Activation Date')
		.matches(/^\d{2}\/\d{2}\/\d{4}$/, {
			excludeEmptyString: false,
			message: `Only input numeric characters in MM/DD/YYYY format`,
		})
		.test({
			test: (value) => {
				const valueMoment = moment(value).format('MM/DD/YYYY');
				const nowMoment = moment().format('MM/DD/YYYY');
				const result = moment(valueMoment).isSameOrAfter(nowMoment);
				return result;
			},
			message: 'Only select date today or a later date.',
		}),
	remarks: yup
		.string()
		.max(1000, 'You can only input a max of 1000 characters.'),
});

export const branchSchema = yup.object({
	branchBasicInformation,
	branchAddress,
	branchContactDetails,
	branchOperatingSchedule,
});
