import Address from 'containers/ChannelManagement/Branch/BranchForm/Address/Address';
import {
	DIGITAL,
	INTERNATIONAL,
	LOCAL,
	NAME_OF_AREA,
	NAME_OF_BARANGAY,
	NAME_OF_LOCATION_BASE,
	NAME_OF_MUNICIPALITY_OR_CITY,
	NAME_OF_STATE_OR_PROVINCE,
	NATIONWIDE,
	PHILIPPINES_VALUE,
} from 'containers/ChannelManagement/Branch/BranchForm/BranchAddress/constants';
import { BranchAddressProps } from 'containers/ChannelManagement/Branch/BranchForm/BranchAddress/types';
import { constructValueConfig } from 'containers/ChannelManagement/Branch/BranchForm/BranchAddress/utils';
import { NAME_OF_CHANNEL_TRANSACTION_TYPE } from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/constants';
import {
	AREA,
	BARANGAY,
	BRANCH_ADDRESS,
	BUILDING_NAME_OR_NUMBER,
	COUNTRY,
	LOCATION_BASE,
	MUNICIPALITY_OR_CITY_LABEL,
	REGION,
	STATE_OR_PROVINCE_LABEL,
	STREET,
	ZIP_CODE,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';
import _ from 'lodash';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Country } from 'utils/queries/location';

const BranchAddress: React.FC<BranchAddressProps> = ({
	address,
	isDisabledAllEditableFields,
}) => {
	const { control, setValue } = useFormContext();

	const currentLocationBaseValue = useWatch({
		name: NAME_OF_LOCATION_BASE,
	});

	const selectedProvince = useWatch({
		name: NAME_OF_STATE_OR_PROVINCE,
	});

	const selectedCity = useWatch({
		name: NAME_OF_MUNICIPALITY_OR_CITY,
	});

	const selecteBarangay = useWatch({
		name: NAME_OF_BARANGAY,
	});

	const channelTransactionTypeCurrentValue = useWatch({
		name: NAME_OF_CHANNEL_TRANSACTION_TYPE,
	});

	useEffect(() => {
		if (currentLocationBaseValue === LOCAL) {
			setValue(
				`${BRANCH_ADDRESS}.${COUNTRY}`,
				PHILIPPINES_VALUE,
				constructValueConfig(true)
			);
		}
		if (currentLocationBaseValue === INTERNATIONAL) {
			setValue(`${BRANCH_ADDRESS}.${COUNTRY}`, '');
		}
	}, [currentLocationBaseValue, setValue]);

	useEffect(() => {
		if (channelTransactionTypeCurrentValue === DIGITAL) {
			setValue(NAME_OF_AREA, NATIONWIDE);
		}
	}, [channelTransactionTypeCurrentValue, setValue]);

	return (
		<>
			<Address
				control={control}
				isAreaToZipCodeVisible={currentLocationBaseValue === LOCAL}
				sectionTitle="Branch Address"
				{...address}
				locationBase={{
					name: `${BRANCH_ADDRESS}.${LOCATION_BASE}`,
					disabled: isDisabledAllEditableFields,
					...address?.locationBase,
				}}
				country={{
					countryFilter:
						currentLocationBaseValue === INTERNATIONAL
							? (country: Country) => country.country_code !== 'PH'
							: () => true,
					...address?.country,
					selectFieldProps: {
						name: `${BRANCH_ADDRESS}.${COUNTRY}`,
						disabled:
							isDisabledAllEditableFields || currentLocationBaseValue === LOCAL,
						...address?.country?.selectFieldProps,
					},
				}}
				area={{
					name: NAME_OF_AREA,
					placeholder: `Select ${_.startCase(AREA)}`,
					disabled: isDisabledAllEditableFields,
					...address?.area,
				}}
				region={{
					...address?.region,
					selectFieldProps: {
						placeholder: `Select ${_.startCase(REGION)}`,
						name: `${BRANCH_ADDRESS}.${REGION}`,
						disabled: isDisabledAllEditableFields,
						...address?.region?.selectFieldProps,
					},
				}}
				stateOrProvince={{
					...address?.stateOrProvince,
					selectFieldProps: {
						name: NAME_OF_STATE_OR_PROVINCE,
						placeholder: `Select ${STATE_OR_PROVINCE_LABEL}`,
						disabled: isDisabledAllEditableFields,
						...address?.stateOrProvince?.selectFieldProps,
					},
				}}
				municipalityOrCity={{
					provinceId: selectedProvince,
					...address?.municipalityOrCity,
					selectFieldProps: {
						disabled: isDisabledAllEditableFields || !selectedProvince,
						name: NAME_OF_MUNICIPALITY_OR_CITY,
						placeholder: `Select ${MUNICIPALITY_OR_CITY_LABEL}`,

						...address?.municipalityOrCity?.selectFieldProps,
					},
				}}
				barangay={{
					cityId: selectedCity,
					...address?.barangay,
					selectFieldProps: {
						disabled: isDisabledAllEditableFields || !selectedCity,
						name: NAME_OF_BARANGAY,
						placeholder: `Select ${_.startCase(BARANGAY)}`,
						...address?.barangay?.selectFieldProps,
					},
				}}
				street={{
					name: `${BRANCH_ADDRESS}.${STREET}`,
					disabled: isDisabledAllEditableFields,
					...address?.street,
				}}
				buildingNameOrNumber={{
					name: `${BRANCH_ADDRESS}.${BUILDING_NAME_OR_NUMBER}`,
					disabled: isDisabledAllEditableFields,
					...address?.buildingNameOrNumber,
				}}
				zipCode={{
					barangayId: selecteBarangay,
					...address?.zipCode,
					selectFieldProps: {
						disabled: true,
						name: `${BRANCH_ADDRESS}.${ZIP_CODE}`,
						...address?.zipCode?.selectFieldProps,
					},
				}}
			></Address>
		</>
	);
};

export default BranchAddress;
