import React, { useCallback, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { debounce } from 'lodash';
import cx from 'classnames';

import LookupField from '../LookupField/LookupField';
import Label from '../Label/Label';

import HTTP from 'helpers/ApiClient';
import { useToggle } from 'utils/hooks';

import ExpandedView from './ExpandedView';
import { LookupFieldProps, Option, Data, ListResponse, Filters } from './types';
import styles from './LookupFieldWithExpanded.module.css';

const SEE_MORE_THRESHOLD = 10;

const LookupFieldWithExpanded: React.FC<LookupFieldProps> = (props) => {
	const {
		control,
		name,
		displayName,
		apiRoute,
		isSearchable,
		className,
		labelClassName,
		onChange: handleOnChange,
		additionalParams = {},
	} = props;

	const [page, setPage] = useState<number>(1);
	const [filter, setFilter] = useState<Filters>({});
	const [count, setCount] = useState<number>(0);
	const [options, setOptions] = useState<Option[]>([]);
	const [data, setData] = useState<Data[]>([]);

	const [inputValue, setInputValue] = useState<string>('');
	const [selectedValue, setSelectedValue] = useState<Option | undefined>();
	const [isFocus, setIsFocus] = useState(false);

	const isFocusRef = useRef<boolean>(isFocus);

	const {
		value: isLoading,
		valueOn: startLoading,
		valueOff: stopLoading,
	} = useToggle();

	const {
		value: isDropdownLoading,
		valueOn: startDropdownLoading,
		valueOff: stopDropdownLoading,
	} = useToggle();

	const {
		value: isChannelModalOpen,
		valueOn: openChannelModal,
		valueOff: closeChannelModal,
	} = useToggle();

	const fetchOptions = async (params?: Filters, isModalOpen = false) => {
		isModalOpen ? startLoading() : startDropdownLoading();
		setFilter({ ...filter, ...params });

		const getParams = { ...filter, ...params };
		if (getParams?.name === '') delete getParams.name; // remove name filter if empty string
		if (getParams?.page === 1) delete getParams.page; // remove page if value is default / 1

		try {
			const { data } = await HTTP.get(apiRoute, {
				params: { ...getParams, ...additionalParams },
			});

			const rawData = data?.result || data?.data;
			setData(rawData);
			setOptions(
				rawData
					?.slice(0, SEE_MORE_THRESHOLD)
					.map(({ id: value, name: label }) => ({
						label,
						value,
					}))
			);

			setCount(data?.meta?.total || data?.result?.length);
		} catch (e) {
			setOptions([]);
		} finally {
			stopLoading();
			stopDropdownLoading();
			!isModalOpen || openChannelModal();
		}
	};

	const handleSearch = useCallback(
		debounce((value) => {
			const v = value || '';
			if (
				(v.length >= 3 && isFocusRef.current) ||
				(v === '' && inputValue === '' && isFocusRef.current)
			) {
				fetchOptions({ name: v });
			}
		}, 500),
		[]
	);

	const handleFocus = () => {
		isFocusRef.current = true;
		fetchOptions({ page: 1 });
	};

	const onInputChange = (value) => {
		const v = value || '';
		setInputValue(v);
		handleSearch(v);
	};

	const handleMenuOpen = async () => {
		setPage(1);
	};

	const onPageChange = async (page: number) => {
		setPage(page);
		fetchOptions({ page }, true);
	};

	return (
		<>
			<Label className={labelClassName}>{displayName}</Label>
			<Controller
				name={name}
				control={control}
				render={({ field, fieldState: { error, isDirty } }) => {
					const { onChange, ...rest } = field;
					const onSelect = (value) => {
						onChange(value.label);
						setInputValue(value.label);
						setSelectedValue(value);
						setOptions((prevOptions) => {
							const updatedOptions = prevOptions.map((option) => {
								if (option.value === value.value) {
									return value;
								}
								return option;
							});
							return updatedOptions;
						});
						handleOnChange && handleOnChange(value);
					};

					const handleChange = (value) => {
						if (value?.label) {
							onChange(value?.label);
							setSelectedValue(value);
							handleOnChange && handleOnChange(value);
						}
					};

					return (
						<>
							<LookupField
								{...rest}
								onChange={handleChange}
								isLoading={isDropdownLoading}
								options={options}
								hasSeeMore={count > SEE_MORE_THRESHOLD}
								placeholder={isDropdownLoading ? '' : displayName || ''}
								onInputChange={onInputChange}
								onMenuOpen={handleMenuOpen}
								inputValue={inputValue}
								filterOption={() => true}
								seeMoreClick={openChannelModal}
								isSearchable={isSearchable}
								hasError={!!error}
								classNames={className}
								onFocus={handleFocus}
								onBlur={() => {
									isFocusRef.current = false;
								}}
								value={isDirty ? selectedValue : undefined}
							/>

							{error && (
								<div
									className={cx({
										'slds-has-error': !!error,
									})}
								>
									<div
										className={cx(styles.errorText, 'slds-form-element__help')}
									>
										{error?.message}
									</div>
								</div>
							)}

							<ExpandedView
								open={isChannelModalOpen}
								onClose={closeChannelModal}
								data={data}
								count={count}
								page={page}
								onPageChange={onPageChange}
								onSelect={onSelect}
								displayName={displayName || ''}
								isLoading={isLoading}
							/>
						</>
					);
				}}
			/>
		</>
	);
};

export default LookupFieldWithExpanded;
