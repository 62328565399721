import { yupResolver } from '@hookform/resolvers/yup';
import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import { TabLabel } from 'components/BillerForm/BillerForm';
import styles from 'components/BillerForm/BillerForm.module.css';
import {
	PartnerReportsFormData,
	schema as reportsSchema,
} from 'components/PartnerForm/Tabs/Reports';
import PrimaryInformationTab from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/PrimaryInformation/PrimaryInformationTab';
import ReportsTab from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/ReportsTab';
import { REPORTS_FORM_DEFAULT_VALUES } from 'containers/ChannelManagement/Channel/ChannelForm/const';
import { ChannelFormProps } from 'containers/ChannelManagement/Channel/ChannelForm/types';
import { FormProvider, useForm } from 'react-hook-form';
import { CONTRACT_DETAILS_DEFAULT_VALUE } from './Tabs/ContractDetails/ContractDetailsConstants';
import ContractDetailsTab from './Tabs/ContractDetails/ContractDetailsTab';
import {
	primartyInfoInitlValues,
	PrimaryInfoType,
	PrimaryInformationSchema,
} from './Tabs/PrimaryInformation/PrimaryInformationSchema';
import { useFormErrorHandling } from './hooks';
import { useSelector } from 'react-redux';
import { Reducer } from '@reduxjs/toolkit';
import { ReducerStateType } from 'redux/modules/reducers';
import { useCreateChannel } from './ChannelFormQuery';
import { useState } from 'react';
import {
	ContractDetailsSchema,
	ContractDetailsType,
} from './Tabs/ContractDetails/ContractDetailsSchemas';
import { isEmpty } from 'lodash';
import { FixMeLater } from 'types';

// const initTabStatus = {
// 	primaryInformation: { disabled: false, finished: false },
// 	contractDetails: { disabled: true },
// };

const ChannelForm: React.FC<ChannelFormProps> = ({
	// tabStatus = initTabStatus,
	autoSaveValue,
	contentClassName = '',
	onChange,
}) => {
	const data = autoSaveValue?.data;
	const primaryInfo = data?.primaryInfo;
	const contractDetails = data?.contractDetails;
	const reports = data?.reports;

	const primaryAutoSave: FixMeLater = {
		primaryInfo,
		contractDetails,
		reports,
	};

	const primaryData = !isEmpty(primaryAutoSave) ? primaryAutoSave :   primartyInfoInitlValues;

	const channelFormValues: FixMeLater = useSelector<ReducerStateType>(
		(state) => state.channels.channelFormValues
	);

	const [autosaveTracker, setAutoSaveTracker] = useState({
		activeTab: 'primary',
		primaryInfo: {},
		isDirtyPrimary: { current: false, previous: true },
		contractDetails: {},
		isDirtyContract: false,
		reports: {},
		isDirtyReports: false,
	});

	console.log("ASD", primaryAutoSave)
	const primaryForm = useForm<PrimaryInfoType>({
		mode: 'all',
		resolver: yupResolver(PrimaryInformationSchema.objectSchema),
		defaultValues: primaryData,
	});

	useFormErrorHandling(primaryForm, 'primaryForm');

	const {
		getValues,
		formState: { isDirty },
	} = primaryForm;

	const contractForm = useForm<ContractDetailsType>({
		mode: 'all',
		resolver: yupResolver(ContractDetailsSchema),
		defaultValues: channelFormValues.contract ?? CONTRACT_DETAILS_DEFAULT_VALUE,
	});
	useFormErrorHandling(contractForm, 'contractForm');

	const reportsForm = useForm<PartnerReportsFormData>({
		mode: 'all',
		resolver: yupResolver(reportsSchema),
		defaultValues: channelFormValues.contract ?? REPORTS_FORM_DEFAULT_VALUES,
	});
	useFormErrorHandling(reportsForm, 'reportsForm');

	return (
		<Tabs className={styles.tabs}>
			<TabsPanel
				label={
					<TabLabel
						label="Primary Information"
						done={
							true
							// tabStatus.primaryInformation?.finished
						}
					/>
				}
				disabled={false}
			>
				<div className={cx(styles.content, contentClassName)}>
					<FormProvider {...primaryForm}>
						<PrimaryInformationTab
							// {...props}
							onChange={onChange}
						/>
					</FormProvider>
				</div>
			</TabsPanel>
			<TabsPanel
				label="Contract Details"
				disabled={false} // TODO: Remove force enable after implementation
			>
				<div className={cx(styles.content, contentClassName)}>
					<FormProvider {...contractForm}>
						<ContractDetailsTab disabled={false} />
					</FormProvider>
				</div>
			</TabsPanel>
			<TabsPanel
				label="Reports"
				disabled={false} // TODO: Remove force enable after implementation
			>
				<div className={cx(styles.content, contentClassName)}>
					<FormProvider {...reportsForm}>
						<ReportsTab disabled={false} />
					</FormProvider>
				</div>
			</TabsPanel>
		</Tabs>
	);
};

export default ChannelForm;
