import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import accordionContainer from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/AccordionContainer/AccordionContainer.module.css';
import accordion from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Accordion/Accordion.module.css';
import customModalStyles from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal.module.css';
import Accordion from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Accordion/Accordion';
import Navigation from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Navigation/Navigation';
import SearchFilter from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/SearchFilter/SearchFilter';
import { useToggle } from 'utils/hooks';
import { useHasUserPermission } from 'utils/permissions';
import { getProductServiceFee } from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductQuery';
import {
	AccordionContainerProps,
	PartnerListDataType,
	EnableDisableState,
} from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import ServiceFeeContainer from 'containers/ChannelManagement/Channel/ChannelProducts/ServiceFeeContainer/ServiceFeeContainer';
import Pagination from 'components/CWSTable/Pagination';
import AccordionItem from 'containers/ChannelManagement/Channel/ChannelProducts/PartnerProductDetails/Accordion/AccordionItem';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ChannelProductModal from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal';
import {
	enableDisablePartner,
	enableDisableBranch,
	enableDisableTerminal,
} from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductQuery';
import client from 'helpers/ApiClient';
import { BASE_PATH } from 'containers/ChannelManagement/Channel/ChannelProducts/const';
import cx from 'classnames';

const AccordionContainer: React.FC<AccordionContainerProps> = ({
	data,
	content,
	selectedChannel,
	isEnabledFilter,
}) => {
	const [expandedIndices, setExpandedIndices] = useState<string[]>([]);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [modalMessage, setModalMessage] = useState<React.ReactNode>(<></>);
	const [modalHeading, setModalHeading] = useState('');
	const hasUserPermission = useHasUserPermission('channels');
	const hasEditPermission = hasUserPermission('edit');
	const [remarks, setRemarks] = useState('');
	const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(true);
	const [isEnabled, setIsEnabled] = useState(false);
	const [accordionData, setAccordionData] = useState<Array<any>>([]);
	const [filterValue, setFilterValue] = useState('');
	const [pageNumber, setPageNumber] = useState(1);
	const [pageLimit, setPageLimit] = useState(25);
	const [isInitialLoad, setIsInitialLoad] = useState(false);
	const [serviceFeeShareData, setServiceFeeShareData] = useState();
	const [errorMessage, setErrorMessage] = useState({
		title: '',
		message: <></>,
	});
	const [partnerList, setPartnerList] = useState<PartnerListDataType>({
		data: [],
		page: 0,
		pageSize: 0,
		total: 0,
	});
	const [forEnableDisable, setForEnableDisable] = useState<EnableDisableState>(
		{}
	);
	const [errorRemarks, setErrorRemarks] = useState({
		hasError: false,
		status: '',
	});
	const styles = {
		...accordionContainer,
		...accordion,
		...customModalStyles,
	};

	useEffect(() => {
		if (selectedChannel) {
			setExpandedIndices([]);
		}
	}, [selectedChannel]);

	// useEffect(() => {
	// 	setEnableFilter(isEnabledFilter);
	// }, [isEnabledFilter]);

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorRetryShowing,
		valueOn: showErrorRetry,
		valueOff: hideErrorRetry,
	} = useToggle();

	const mockSelectedProductType = 1; // mock untill test data is available

	useEffect(() => {
		const getPartnerList = async () => {
			showLoadingMessage('Please wait while we load data');
			let isEnableProp = '';
			const searchFilter =
				(isEnabledFilter && isEnabledFilter.searchFilter) || '';
			const filter = isEnabledFilter && isEnabledFilter.statusItemChecked;
			if (filter && filter.length === 1) {
				isEnableProp = filter[0] === 'Enabled' ? 'true' : 'false';
			}

			try {
				const response = await client.get(
					BASE_PATH +
						`${selectedChannel.id}/products/types/${mockSelectedProductType}?page=${pageNumber}&limit=${pageLimit}&search=${searchFilter}&isEnabled=${isEnableProp}`
				);
				setPartnerList(response.data);
				if (
					(searchFilter !== '' || (filter && filter.length > 0)) &&
					response.data.data.length === 0 &&
					pageNumber !== 1
				) {
					setPageNumber(1);
				}
			} catch (error) {
				console.error('Error fetching partner list: ', error);
			}
			hideLoading();
		};
		if (selectedChannel.id && isInitialLoad) {
			getPartnerList();
		} else {
			setIsInitialLoad(true);
		}

		return () => {
			setPartnerList({
				data: [],
				page: 1,
				pageSize: 25,
				total: partnerList.total,
			});
		};
	}, [
		selectedChannel.id,
		mockSelectedProductType,
		pageNumber,
		pageLimit,
		isEnabledFilter,
	]);

	useEffect(() => {
		// resets and populate accordionData when selectedChannel is change
		if (partnerList.data && selectedChannel) {
			const updatedData = partnerList.data.map((data) => {
				return { ...data, tabName: 'enableDisable' };
			});
			setAccordionData(updatedData);
		}

		return () => {
			// clean accordionData when selectedChannel or partnerList is changed
			setAccordionData([]);
		};
	}, [partnerList, selectedChannel]);

	const handleChangeOfRemarks = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setIsRemarksEmpty(
			/^\s+$/.test(event.target.value) || event.target.value === ''
		);
		setRemarks(event.target.value);
	};

	const deepCopy = (obj: any) => {
		if (typeof obj !== 'object' || obj === null) {
			return obj;
		}

		if (Array.isArray(obj)) {
			return obj.map((item) => deepCopy(item));
		}

		const newObj: any = {};
		for (const key in obj) {
			newObj[key] = deepCopy(obj[key]);
		}

		return newObj;
	};

	const showConfigShareError = () => {
		setErrorMessage({
			title: 'Please set the Service Fee Share',
			message: <></>,
		});
		showErrorRetry();
	};

	const handleToggleOnClick = (
		index: number,
		subIndex?: number,
		subSubIndex?: number
	) => {
		const updatedData = deepCopy(accordionData);
		// use serviceFeeDatas else call API
		const serviceFeeDatas = serviceFeeShareData.find(
			(v) => v.config.product_id === updatedData[index].productId
		)?.shares;
		if (subIndex !== undefined && subSubIndex === undefined) {
			updatedData[index].data.branches[subIndex].enabled =
				!updatedData[index].data.branches[subIndex].enabled;
			// to add temporary enable disable of all terminal
			updateItem(
				updatedData[index].data.branches[subIndex],
				'branches',
				updatedData[index].productId,
				null,
				updatedData
			);
		} else if (subIndex !== undefined && subSubIndex !== undefined) {
			updatedData[index].data.branches[subIndex].terminals[
				subSubIndex
			].enabled =
				!updatedData[index].data.branches[subIndex].terminals[subSubIndex]
					.enabled;
			updateItem(
				updatedData[index].data.branches[subIndex].terminals[subSubIndex],
				'terminals',
				updatedData[index].productId,
				updatedData[index].data.branches[subIndex].id,
				updatedData
			);
		} else {
			updatedData[index].isEnabled =
				updatedData[index].isEnabled === 'Enabled' ? 'Disabled' : 'Enabled';
			// to add temporary enable disable of all branch and terminal
			if (
				updatedData[index].isEnabled === 'Enabled' &&
				(!serviceFeeDatas || serviceFeeDatas.length === 0)
			) {
				showConfigShareError();
			} else {
				updateItem(updatedData[index], 'partner', null, null, updatedData);
			}
		}
	};

	const updateItem = (
		item: any,
		level: any,
		partnerId?: any,
		branchId?: any,
		data?: any
	) => {
		switch (level) {
			case 'partner':
				setModalSettings(item, 'Partner');
				setForEnableDisable({ partnerId: item.productId, update: data });
				break;
			case 'branches':
				setModalSettings(item, 'Branch');
				setForEnableDisable({
					partnerId: partnerId,
					branchId: item.id,
					update: data,
				});
				break;
			case 'terminals':
				setModalSettings(item, 'Terminal');
				setForEnableDisable({
					partnerId: partnerId,
					branchId: branchId,
					terminal: item.id,
					update: data,
				});
				break;
		}
	};

	const setModalSettings = (item: any, level: string) => {
		let setEnabled;
		level === 'Terminal' || level === 'Branch'
			? (setEnabled = item.enabled)
			: (setEnabled = item.isEnabled === 'Enabled');
		setModalMessage(
			<>
				Are you sure you want to
				{setEnabled ? ' enable ' : ' disable '}
				<strong>{item.name}</strong>?
			</>
		);
		setModalHeading(`${setEnabled ? 'Enable ' : 'Disable '} ${level}`);
		setShowConfirmationModal(true);
		setIsEnabled(setEnabled);
	};

	const handleToggleOnChange = (
		index: number,
		e: ChangeEvent<HTMLInputElement>
	) => {
		return;
	};

	const onClickTab = (tab: any, index) => {
		const changeActiveTabOnIndex = [...accordionData];
		changeActiveTabOnIndex[index].tabName = tab.tabName;
		setAccordionData(changeActiveTabOnIndex);
		if (tab.tabName === 'serviceFee') {
			getProductServiceFees(
				selectedChannel.id,
				changeActiveTabOnIndex[index].productId
			);
		}
	};

	const handleSubAccordionClick = useCallback(
		(index: number, subIndex?: number) => {
			const getItemKey = (i: number, subI?: number) =>
				subI !== undefined ? `${i}-${subI}` : i.toString();

			const itemKey = getItemKey(index, subIndex);
			const isExpanded = expandedIndices.includes(itemKey);
			setExpandedIndices((expandedIndices) =>
				isExpanded
					? expandedIndices.filter((key) => key !== itemKey)
					: [...expandedIndices, itemKey]
			);
		},
		[expandedIndices]
	);
	const handleConfirmBtnClick = () => {
		setShowConfirmationModal(false);
		if (
			forEnableDisable.branchId !== undefined &&
			forEnableDisable.terminal === undefined
		) {
			enableDisableBranchQuery();
		} else if (
			forEnableDisable.branchId !== undefined &&
			forEnableDisable !== undefined
		) {
			enableDisableTerminalQuery();
		} else {
			enableDisablePartnerQuery();
		}
	};
	const handleSuccessResponse = (message) => {
		setSuccessMessage(message);
		showSuccessModal();
		clearRemarks();
	};

	const handleErrorResponse = () => {
		setErrorMessage({
			title: 'Timeout Error!',
			message: (
				<>
					<div>A problem occured while saving the data.</div>{' '}
					<div>Please try again.</div>
				</>
			),
		});
		showErrorRetry();
		clearRemarks();
	};

	const setLoaderMessage = (level) => {
		const action = isEnabled ? 'enable' : 'disable';
		showLoadingMessage(`Please wait while we mark the ${level} as ${action}`);
	};
	const enableDisablePartnerQuery = async () => {
		setLoaderMessage('partner');
		enableDisablePartner(
			selectedChannel.id,
			forEnableDisable.partnerId,
			isEnabled,
			{ remarks: remarks }
		)
			.then(() => {
				handleSuccessResponse(`Partner ${isEnabled ? 'Enabled' : 'Disabled'}`);
				setAccordionData(forEnableDisable.update);
			})
			.catch(() => {
				handleErrorResponse();
			})
			.finally(() => {
				hideLoading();
			});
	};

	const enableDisableBranchQuery = async () => {
		setLoaderMessage('branch');
		enableDisableBranch(
			forEnableDisable.branchId,
			forEnableDisable.partnerId,
			isEnabled,
			{ remarks: remarks }
		)
			.then(() => {
				handleSuccessResponse(`Branch ${isEnabled ? 'Enabled' : 'Disabled'}`);
				setAccordionData(forEnableDisable.update);
			})
			.catch(() => {
				handleErrorResponse();
			})
			.finally(() => {
				hideLoading();
			});
	};

	const enableDisableTerminalQuery = async () => {
		setLoaderMessage('branch');
		enableDisableTerminal(
			forEnableDisable.terminal,
			forEnableDisable.partnerId,
			isEnabled,
			{ remarks: remarks }
		)
			.then(() => {
				handleSuccessResponse(`Branch ${isEnabled ? 'Enabled' : 'Disabled'}`);
				setAccordionData(forEnableDisable.update);
			})
			.catch(() => {
				handleErrorResponse();
			})
			.finally(() => {
				hideLoading();
			});
	};

	const handleClickRetry = () => {
		// retry option here
	};

	const handleCancelBtnClick = () => {
		setShowConfirmationModal(false);
		clearRemarks();
	};

	const clearRemarks = () => {
		setRemarks('');
		setIsRemarksEmpty(true);
	};

	const filterBranch = (filter: any, sections: any) => {
		return (
			sections.branches &&
			sections.branches.filter((section) =>
				section.name.toLowerCase().includes(filter.toLowerCase())
			)
		);
	};

	const setSectionData = useCallback((section, productId) => {
		setAccordionData((prevAccordionData) => {
			const updatedData = prevAccordionData.map((val) => {
				if (val.productId === productId && val.data === undefined) {
					return section;
				}
				return val;
			});
			return updatedData;
		});
	}, []);

	/**
	 * @function: When partner is clicked from enable to disable,
	 * all branches and terminals should be disabled
	 * @param section : product with branches and terminals
	 * */
	const cascadeDisabled = (section) => {};

	const getPageNumberOptions = (
		pageLimit: number,
		totalItems: number
	): number[] => {
		const totalPages = Math.ceil(totalItems / pageLimit);
		const pageNumberOptions: number[] = [];

		for (let i = 1; i <= totalPages; i++) {
			pageNumberOptions.push(i);
		}
		return pageNumberOptions;
	};

	const goToLastPage = (pageLimit: number, totalItems: number) => {
		const last = Math.ceil(totalItems / pageLimit);
		setPageNumber(last);
	};

	const handleOnExpandAction = (index) => {
		const resetActiveTabOnIndex = [...accordionData];
		resetActiveTabOnIndex[index].tabName = 'enableDisable';
		setAccordionData(resetActiveTabOnIndex);
	};

	const getProductServiceFees = async (channelId, productId) => {
		try {
			const result = await getProductServiceFee(channelId, productId);
			if (result) {
				setServiceFeeShareData((prev) => {
					const exists =
						prev &&
						prev.find(
							(v) => v.config.product_id === result.data.data.config.product_id
						);
					const prevData = prev || [];
					return exists ? prev : [...prevData, result.data.data];
				});
			}
		} catch (err) {
			console.log('Error: ', err?.response);
		}
	};

	return (
		<>
			<div className={styles.header}>
				<div>
					{data.title}
					<span className={styles.action}>{data.action}</span>
				</div>
			</div>
			<div className={styles.relativeContainer}>
				<div className={styles.body}>
					{accordionData && accordionData.length > 0 ? (
						accordionData.map((section: any, index: number) => {
							const filteredBranches =
								section.data &&
								section.data.branches &&
								filterBranch(filterValue, section.data);
							const branchesToRender =
								filteredBranches || (section.data && section.data.branches);
							return (
								section && (
									<Accordion
										title={section.productName}
										enabled={section.isEnabled === 'Enabled' ? true : false}
										accordionKey={index}
										onExpandAction={handleOnExpandAction}
										onClickBtn={handleToggleOnClick}
										onChangeBtn={handleToggleOnChange}
										key={index}
										channel={selectedChannel}
										partner={section}
										sectionData={setSectionData}
										content={
											<>
												{section &&
												section.data &&
												section.data.branches.length > 0 ? (
													<ul className={styles.navSection}>
														<Navigation
															tabs={[
																{
																	title: 'Enable/Disable',
																	name: 'enableDisable',
																},
																{ title: 'Service Fee', name: 'serviceFee' },
															]}
															itemKey={index}
															onClickTab={onClickTab}
															content={
																<>
																	{section.tabName === 'enableDisable' ? (
																		<>
																			<div className={styles.searchBox}>
																				<SearchFilter
																					onFilterChange={() => {}}
																					key={selectedChannel}
																					size={12}
																					textBoxName="filterBranch.branchName"
																					textBoxPlaceholder="Search Branch Name"
																					onChange={(e) => setFilterValue(e)}
																				/>
																			</div>
																			<div
																				key={index}
																				className={styles.expandedContent}
																			>
																				{branchesToRender &&
																					branchesToRender.map(
																						(
																							subSection: any,
																							subIndex: number
																						) => {
																							const itemKey = `${index}-${subIndex}`;
																							const isSubExpanded =
																								expandedIndices.includes(
																									itemKey
																								);
																							return (
																								<AccordionItem
																									key={subIndex}
																									index={index}
																									subIndex={subIndex}
																									handleSubAccordionClick={
																										handleSubAccordionClick
																									}
																									listItemClass={
																										styles.listItem
																									}
																									accordionBtnClass={
																										styles.accordionButton
																									}
																									expandedClass={
																										styles.expanded
																									}
																									isSubExpanded={isSubExpanded}
																									subSectionItemClass={
																										styles.subSectionItem
																									}
																									subSection={subSection}
																									section={section}
																									toggleButtonClass={
																										styles.toggleButton
																									}
																									toggleBtnDivClass={
																										styles.toggleBtnDiv
																									}
																									handleToggleOnChange={
																										handleToggleOnChange
																									}
																									handleToggleOnClick={
																										handleToggleOnClick
																									}
																									lineDeviderClass={
																										styles.lineDevider
																									}
																									hasEditPermission={
																										hasEditPermission
																									}
																									accordionContent2Class={
																										styles.accordionContent2
																									}
																								/>
																							);
																						}
																					)}
																			</div>
																		</>
																	) : (
																		<ServiceFeeContainer
																			key={`service-fee-${section.data.productId}`}
																			itemKey={index}
																			data={section}
																			selectedChannel={selectedChannel}
																			permission={!hasEditPermission}
																			serviceFees={serviceFeeShareData}
																		/>
																	)}
																</>
															}
														/>
													</ul>
												) : (
													<div
														key={index}
														className={cx(styles.navSection, styles.noItem)}
													>
														{<>No branch and terminal available</>}
													</div>
												)}
											</>
										}
									/>
								)
							);
						})
					) : (
						<div className={styles.accordionMessage}>
							Please wait while we load partner list
						</div>
					)}
				</div>
			</div>
			<div className={styles.footer}>
				<Pagination
					page={partnerList.page}
					canPreviousPage={true}
					canNextPage={true}
					pageOptions={getPageNumberOptions(pageLimit, partnerList.total)}
					previousPage={() => setPageNumber(pageNumber - 1)}
					gotoPage={(v) => {
						setPageNumber(v === 0 ? 0 : v + 1);
					}}
					nextPage={(v) => {
						setPageNumber(pageNumber + 1);
					}}
					handlePageCount={(v) => setPageLimit(v)}
					pageIndexProps={
						partnerList.page > 0 ? partnerList.page - 1 : partnerList.page
					}
					count={partnerList.total}
					goToFirst={() => setPageNumber(0)}
					goToLast={() => goToLastPage(pageLimit, partnerList.total)}
					pageCount={partnerList.total}
					pageSize={partnerList.pageSize}
					key={1}
					showFrom={true}
				/>
			</div>
			{showConfirmationModal && (
				<ChannelProductModal
					isOpen={true}
					onClose={handleCancelBtnClick}
					isDisabled={isLoading}
					heading={<h2 className={styles.customHeading}>{modalHeading}</h2>}
					message={<h2 className={styles.bodyHeader}>{modalMessage}</h2>}
					isPrimaryBtnDisabled={isRemarksEmpty}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Confirm"
					onCancelBtnClick={handleCancelBtnClick}
					onConfirmBtnClick={handleConfirmBtnClick}
					showRemarks
					error={errorRemarks}
					handleChangeOfRemarks={handleChangeOfRemarks}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
				/>
			)}
			{isLoading && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}
			<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>{successMessage}</SuccessText>
					<div className={styles.subTextNext}>
						You may click the Done button.
					</div>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.doneButton}
						onClick={() => {
							hideSuccessModal();
						}}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			<ErrorModal open={isErrorRetryShowing} onClose={hideErrorRetry}>
				<ErrorModalBody className={styles.errorBody}>
					<div className={styles.errorHeader}>
						<b>{errorMessage.title}</b>
					</div>
					<div className={styles.errorText}>
						<div>{errorMessage.message}</div>
					</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.contactDetailsErrorCloseBtn}
						onClick={() => {
							hideErrorRetry();
							handleClickRetry();
						}}
					>
						Okay
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default AccordionContainer;
