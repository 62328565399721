import yup, {
	defaultInvalidCharacter,
	hasSpecialCharacter,
	multipleContactNumberSchema,
	multipleEmailSchema,
} from 'utils/formSchemas/common';
import { YupMessage } from 'types';
import { validateLandlineNumber } from 'utils/validation';

import {
	NAME_LABEL,
	MAX_CHAR_255,
	MAX_CHAR_255_MSG,
	TELEPHONE_LABEL,
	TOTAL_LENGTH_MSG,
	INVALID_TEL_MSG,
	INVALID_TEL_MSG_SINGLE,
	CHANNEL_CONTACT_LABEL,
	EMAIL_LABEL
} from './const';

export const name = yup
	.string()
	.label(NAME_LABEL)
	.max(MAX_CHAR_255, MAX_CHAR_255_MSG)
	.required()
	.test('', defaultInvalidCharacter, (v?: string) => {
		if (v === undefined) {
			return false;
		}
		return hasSpecialCharacter(v);
	});

export const email = multipleEmailSchema.label(EMAIL_LABEL);

export const telNo = yup
	.array()
	.nullable()
	.of(yup.string().nullable())
	.optional()
	.label(TELEPHONE_LABEL)
	.test(
		'isValidLandlineNumber',
		({ value }: YupMessage): string => {
			const count = value?.filter(
				(a: string) => !validateLandlineNumber(a || '')
			).length;

			const totalLength = value
				? value.reduce(
						(acc: number, val: string) => acc + (val ? val.length : 0),
						0
				  )
				: 0;

			if (totalLength >= MAX_CHAR_255) {
				return TOTAL_LENGTH_MSG;
			}
			if (count >= 1) {
				return `${count} ${
					count > 1 ? INVALID_TEL_MSG : INVALID_TEL_MSG_SINGLE
				}`;
			}
			return '';
		},
		(v: string[] | undefined): boolean => {
			if (!v) return true;
			return v.every((a) => validateLandlineNumber(a || '', v.length));
		}
	);

export const mobileNo = multipleContactNumberSchema.label(
	CHANNEL_CONTACT_LABEL
);
