import React from 'react';
import cx from 'classnames';

import Table from 'components/CWSTable/Table';

import ChannelDetailsToolbar from './ChannelDetailsToolbar';
import { ChannelTabProps } from '../types';
import styles from './ChannelDetailsBody.module.css';

const ChannelDetailsTable: React.FC<ChannelTabProps> = ({
	tableProps,
	filterProps,
}) => {
	const {
		columns,
		className,
		isNotEvenRowColor = false,
		ref,
		isOverflowHidden,
	} = { ...tableProps };
	return (
		<div>
			{filterProps && <ChannelDetailsToolbar {...filterProps} />}
			<Table
				{...tableProps}
				columns={columns}
				className={cx(
					isOverflowHidden && styles.tableOverflow,
					className && className
				)}
				preHeader={null}
				modalIsOpen={false}
				isNotEvenRowColor={isNotEvenRowColor}
				overFlow="hidden"
				tableWidthRef={ref}
			/>
		</div>
	);
};
export default ChannelDetailsTable;
