import React, { useEffect, useState } from 'react'
import Section, { SectionRow } from 'components/Section/Section';
import ErrorModal, { ErrorModalBody } from 'components/Modal/ErrorModal';
import SuccessModal, { SuccessModalBody } from 'components/Modal/SuccessModal';
import Grid from 'components/Grid/Grid';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import TextField from 'components/Inputs/TextField/TextField';
import styles from 'containers/ChannelManagement/Terminal/style.module.css';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useToggle } from 'utils/hooks';
import { useFormContext, Controller } from "react-hook-form";
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import { Modal } from '@salesforce/design-system-react/module/components';
import { TerminalDetails } from 'containers/ChannelManagement/Terminal/types';
import { UseUpdateTerminal } from 'containers/ChannelManagement/Terminal/query';
import { TernimalIntegrationProps } from 'containers/ChannelManagement/Terminal/types';

const TerminalIntegration: React.FC<TernimalIntegrationProps> = ({
    terminalFormValues,
    terminalInfoPayload,
    onChange,
    disabled,
    refetchTerminal,
}) => {
    const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(false)

    const {
        control,
        watch,
        setValue
    } = useFormContext<TerminalDetails>()

    const {
        tokenIdReg,
        remarks,
    } = watch()

    const {
        terminalDetails,
    } = useTerminal()

    const {
        value: isLoading,
        valueOn: showLoading,
        valueOff: hideLoading,
    } = useToggle();

    const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

    const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

    const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

    useEffect(() => {
        onChange && onChange('tokenIdReg', tokenIdReg)
        onChange && onChange('remarks', remarks)
        if (remarks && remarks.length > 1000) {
            setIsRemarksEmpty(true)
        } else {
            setIsRemarksEmpty(false)
        }
    }, [watch, tokenIdReg, remarks])

    useEffect(() => {
        setValue('tokenIdReg', terminalFormValues?.data?.tokenIdReg)
    }, [setValue, terminalFormValues, disabled])

    const handleSave = async () => {
        showLoading()
        await UseUpdateTerminal(terminalDetails.id, terminalInfoPayload)
            .then(() => {
                hideLoading()
                refetchTerminal && refetchTerminal()
                hideConfirmModal()
                showSuccessModal()
            })
            .catch(() => {
                hideLoading()
                hideConfirmModal()
                showErrorModal()
            })
    }

    const confirmModalBodyText = (
        <>
            <br />Are you sure you want to save the changes made?<br />
            if yes, please input remarks.<br />
            <div className={styles.intergrationModalBody}>
                <label className={'slds-form-element__label' + ' ' + styles.remarksLabel}>
                    Remarks
                </label>
                <div className="slds-form-element__control">
                    <Controller
                        name="remarks"
                        control={control}
                        render={({ field }) => (
                            <textarea
                                className={'slds-textarea' + ' ' + styles.remarks}
                                disabled={disabled}
                                {...field}
                            />
                        )}
                    />
                    <em className={styles.remarksSubText}>
                        *Maximum of 1000 characters only.
                    </em>
                    {isRemarksEmpty && (
                        <div className={'slds-form-element__help' + ' ' + styles.remarksError}>
                            <div>You can only input a max of 1000 characters.</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )

    const footer = (
        <div className={styles.footer}>
            <div className={styles.footerRightPanel}>
                <OutlineButton
                    onClick={hideConfirmModal}
                    className={styles.btn}
                >
                    Cancel
                </OutlineButton>
                <PrimaryButton
                    onClick={handleSave}
                    className={styles.btn}
                >
                    Confirm
                </PrimaryButton>
            </div>
        </div>
    )

    const successModalBodyText = (
        <div className={styles.confirmationSaveChanges}>
            The changes you made were<br />
            successfully saved!<br /><br />
            <PrimaryButton
                className={styles.successBtn}
                onClick={hideSuccessModal}
            >
                Done
            </PrimaryButton>
        </div>
    )

    const errorModalBodyText = (
        <>
            <div className={styles.confirmationHeader}>Timeout Error!</div>
            <div className={styles.subtext}>
                A problem occurred while saving your changes.<br />
                Please try again.<br /><br /><br /><br />
            </div>
            <PrimaryButton
                className={styles.successBtn}
                onClick={hideErrorModal}
            >
                Retry
            </PrimaryButton>
        </>
    )

    const isTerminalFormValuesEmpty = terminalFormValues !== null && typeof terminalFormValues === 'object' && Object.keys(terminalFormValues).length !== 0;

    return (
        <>
            <Section title="Integration">
                <SectionRow>
                    <Grid column size={1} of={3}>
                        <TextField
                            required
                            label="Token ID Registration"
                            name="tokenIdReg"
                            control={control}
                            disabled={!!terminalFormValues?.data?.tokenIdReg || disabled}
                        />
                    </Grid>
                </SectionRow>
            </Section>
            {isTerminalFormValuesEmpty && !disabled
            ? <>
                <hr className={styles.footerDivider} />
                <div className={styles.footer}>
                    <div className={styles.footerRightPanel}>
                        <PrimaryButton
                            className={styles.btnSave}
                            onClick={showConfirmModal}
                        >
                            Save
                        </PrimaryButton>
                    </div>
                </div>
            </>
            : null}
            <Modal
                isOpen={isConfirmModalShowing}
                onRequestClose={hideConfirmModal}
                heading="Save Changes"
                containerClassName={styles.confirmModalContainer}
                footer={footer}
            >
                <div className={styles.intergrationConfirmationBody}>
                    {confirmModalBodyText}
                </div>
            </Modal>
            <SuccessModal
                open={isSuccessModalOpen}
                onClose={hideSuccessModal}
            >
                <SuccessModalBody>
                    {successModalBodyText}
                </SuccessModalBody>
            </SuccessModal>
            <ErrorModal
				open={isErrorModalOpen}
				onClose={hideErrorModal}
			>
				<ErrorModalBody>
                    {errorModalBodyText}
				</ErrorModalBody>
			</ErrorModal>
            <FullPageLoader open={isLoading} message={'Please wait while changes are being saved.'} />
        </>
    )
}

export default TerminalIntegration