import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import { useEffect } from 'react';
import { useForm, UseFormSetValue, useWatch } from 'react-hook-form';
import ServiceFeeSchemaTable from './ServiceFeeSchemaTable/ServiceFeeSchemaTable';

export type ServiceFeeSchemaType = 'REGULAR' | 'TIER' | 'PERCENTAGE';

type BillingType = 'PASS_ON' | 'SUBSIDIZED';
export type ServiceFeeSettingsFormData = {
	withSfSettlementDeduction: boolean;
	frequencyType: 'DAILY' | 'MONTHLY' | 'YEARLY';
	schemaType: ServiceFeeSchemaType;
	tierCount: number;
	tier: [];
	serviceFeeBillingType: BillingType[];
	withNonShareableAmount: boolean;
};

const SchemaTypes: Array<{
	label: string;
	value: ServiceFeeSchemaType;
}> = [
	{
		label: 'Regular',
		value: 'REGULAR',
	},
	{
		label: 'Tier',
		value: 'TIER',
	},
	{
		label: 'Percentage',
		value: 'PERCENTAGE',
	},
];

type SchemaWatcherArgs = {
	setValue: UseFormSetValue<ServiceFeeSettingsFormData>;
	schemaType: ServiceFeeSchemaType;
};
const useSchemaWatcher = ({ setValue, schemaType }: SchemaWatcherArgs) => {
	useEffect(() => {
		if (schemaType !== 'TIER') {
			setValue('tierCount', 0);
		}
	}, [schemaType]);
};

const ServiceFeeSettings: React.FC = () => {
	const {
		control,
		setValue,
		clearErrors,
		formState: { isDirty, isValid, errors },
		handleSubmit,
		reset,
		setError,
	} = useForm<ServiceFeeSettingsFormData>({
		mode: 'all',
		defaultValues: {},
		// resolver: yupResolver(primaryInformationSchema),
	});

	const schemaType = useWatch({ control, name: 'schemaType' });
	const tierCount = useWatch({ control, name: 'tierCount' });
	useSchemaWatcher({ schemaType, setValue });

	const serviceFeeSchemaTableProps = {
		schemaType,
		tierCount,
		control,
	};

	return (
		<>
			<SelectField
				label="Service Fee Schema Type"
				required
				options={SchemaTypes}
				name="schemaType"
				control={control}
			/>
			{schemaType === 'TIER' && (
				<TextField control={control} label="No. of Tiers" name="tierCount" />
			)}
			{/* <ServiceFeeSchemaTable {...serviceFeeSchemaTableProps} /> */}
		</>
	);
};

export default ServiceFeeSettings;
