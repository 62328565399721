import React from 'react'

const TerminalAuditTrail: React.FC = () => {
    return (
        <>
            <div>
                terminal audit
            </div>
        </>
    )
}

export default TerminalAuditTrail