import {
	Button,
	Input,
	InputIcon,
	Modal,
} from '@salesforce/design-system-react/module/components';
import styles from '../UploadLogoModal/UploadLogoModal.module.css';
import React, { useEffect } from 'react';
import HTTP from '../../helpers/ApiClient';
import cx from 'classnames';
import axios from 'axios';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useToggle } from 'utils/hooks';
import { UploadLogoResponse } from './UploadLogoResponse';
import { ReactComponent as RefreshIcon } from 'assets/icons/ic-refresh.svg';
import { sleep } from 'utils/common';

type Props = {
	open?: boolean;
	onClose?: () => void;
	onSuccess?: (value: UploadLogoResponse) => void;
	url: string;
	action: any;
	selectedProductId: any;
};

const UploadLogoModal: React.FC<Props> = ({
	open,
	onClose = () => {},
	onSuccess = (value: UploadLogoResponse) => value,
	url,
	action,
	selectedProductId,
}) => {
	// const uploadURL = "/products/document";
	const [selectedFile, setSelectedFile] = React.useState<File>();
	const [selectedFileName, setSelectedFileName] = React.useState('Select File');
	const [formErrors, setFormErrors] = React.useState('');
	const {
		value: isLoading,
		valueOn: startLoading,
		valueOff: stopLoading,
	} = useToggle();
	const {
		value: isRetryEnabled,
		valueOn: enableRetry,
		valueOff: disableRetry,
	} = useToggle();
	const {
		value: isSuccessModalOpen,
		valueOn: openSuccessModal,
		valueOff: closeSuccessModal,
	} = useToggle();

	const acceptableTypes = ['image/png', 'image/jpeg', 'image/jpg'];
	const maxSize = 10000000;

	const handleOnSuccess = (data: UploadLogoResponse) => {
		disableRetry();
		onSuccess(data);
	};

	const onFileChange = (event: any) => {
		// Update the state
		setSelectedFile(event.target.files[0]);
	};

	const handleClose = () => {
		setSelectedFileName('Select File');
		setSelectedFile(undefined);
		stopLoading();
		setFormErrors('');
		disableRetry();
		onClose();
	};

	const validateLogoCall = () => {
		//call api
		startLoading();
		HTTP.get(url, {
			params: {
				fileName: selectedFile?.name,
				contentType: selectedFile?.type,
			},
		})
			.then((response) => {
				uploadLogoCall(response.data);
			})
			.catch((error) => {
				console.log(error);
				stopLoading();
			});
	};

	const handleInquireUpload = (logoData, index = 0) => {
		const lowerCaseAction = action.toString().toLowerCase();
		let params;
		if (lowerCaseAction === 'edit') {
			params = {
				key: logoData.file_name,
				type: 'product_logo_active',
				id: selectedProductId,
			};
		} else if (
			lowerCaseAction === 'draft' ||
			lowerCaseAction === 'rejected' ||
			lowerCaseAction === 'add' ||
			lowerCaseAction === 'approval'
		) {
			params = {
				key: logoData.file_name,
				type: 'product_logo_draft',
			};
		} else {
			params = {
				key: logoData.file_name,
				type: 'product_logo_draft',
			};
		}

		const url = '/v2/uploads/status';
		HTTP.get(url, {
			params,
		})
			.then(async ({ data }) => {
				const status = data.status;
				//set status to uploaded once status of file is clean
				if (status === 'SCAN_CLEAN') {
					const respBody: any = {
						file: selectedFile,
						objectKey: logoData.file_name,
						url: '',
					};
					handleOnSuccess(respBody);
					openSuccessModal();
					onClose();
				} else {
					await sleep(10000);
					//Check if index is less than to the try limit
					if (index < 5) {
						//add index and call the function again
						index += 1;
						handleInquireUpload(logoData, index);
						return;
					} else {
						console.log('error');
					}
				}
			})
			.catch((error) => {
				console.log(error);
				setFormErrors('Upload Failed.');
				enableRetry();
			})
			.finally(() => {
				stopLoading();
			});
	};

	const uploadLogoCall = (data) => {
		const url = data.presigned_keys.url;
		const formData = new FormData();
		Object.entries(data.presigned_keys.fields).forEach(([key, value]: any) => {
			formData.append(key, value);
		});
		formData.append('file', selectedFile ? selectedFile : '');
		//call api
		const UNINTERCEPTED_HTTP = axios.create({
			baseURL: process.env.REACT_APP_API_ENDPOINT,
			headers: {
				'Content-Type': 'application/json',
				'x-bayad-platform-id': process.env.REACT_APP_APP_KEY,
			},
		});

		if (selectedFile) {
			startLoading();
			UNINTERCEPTED_HTTP.post(url, formData)
				.then((response) => {
					handleInquireUpload(data);
				})
				.catch((error) => {
					console.log(error);
					setFormErrors('Upload Failed.');
					enableRetry();
					stopLoading();
				});
		}
	};

	useEffect(() => {
		let currErrors = '';
		if (selectedFile !== undefined && selectedFile !== null) {
			if (!acceptableTypes.includes(selectedFile.type)) {
				currErrors = 'Accepted formats are .jpeg or .png';
			} else if (selectedFile.size > maxSize) {
				currErrors = 'The maximum file size accepted is 10MB.';
			}

			setFormErrors(currErrors);

			if (currErrors !== '') {
				setSelectedFile(undefined);
			} else {
				setSelectedFileName(selectedFile.name);
				validateLogoCall();
			}
		}
	}, [selectedFile]);

	const hiddenFileInput = React.useRef<HTMLInputElement>(null);

	const handleClick = () => {
		hiddenFileInput.current?.click();
	};

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={handleClose}
				headerClassName={styles.headerContainer}
				size="small"
				containerClassName={styles.modalContainer}
				contentClassName={styles.modal}
				dismissOnClickOutside={false}
				heading="Upload Logo"
			>
				<div style={{ width: '100%', border: '1px solid #dddbda' }}></div>
				<section className="slds-p-around_large">
					<div className={styles.logoSubtext}>
						<em>*Maximum file size accepted:</em>
						<span className={styles.logoSubtextValue}>10MB</span>
					</div>
					<div className={styles.logoSubtext}>
						<em>*File types accepted:</em>
						<span className={styles.logoSubtextValue}>.jpg, .jpeg, .png</span>
					</div>
					<div className={styles.fileInput}>
						<div style={{ width: '45vmax' }}>
							<Input
								id="disabled-input-id"
								value={selectedFileName}
								styleInput={{
									backgroundColor: 'white',
								}}
								className={
									selectedFileName == 'Select File' ? styles.gray : styles.black
								}
								errorText={formErrors}
								hasSpinner={isLoading}
								iconRight={
									<button
										className={cx(styles.refreshIcon, {
											'slds-input__icon slds-input__icon_right': true,
											[styles.hideClass]: !isRetryEnabled || isLoading,
										})}
										style={{ backgroundColor: 'transparent' }}
									>
										<RefreshIcon onClick={validateLogoCall} />
									</button>
								}
							/>
						</div>

						<Button
							onClick={handleClick}
							style={{
								marginLeft: '2vmax',
								borderColor: '#ff000085',
								color: '#ff000085',
							}}
						>
							Browse
						</Button>
					</div>
					<input
						type="file"
						onChange={onFileChange}
						style={{ display: 'none' }}
						ref={hiddenFileInput}
						accept=".jpg,.jpeg,.png"
					/>
				</section>
			</Modal>
			<SuccessModal open={isSuccessModalOpen} onClose={closeSuccessModal}>
				<SuccessModalBody>
					<SuccessText>Logo successfully uploaded.</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={closeSuccessModal}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
		</>
	);
};

export default UploadLogoModal;
