import {
	NAME_OF_CHANNEL_ACCOUNT_CODE,
	NAME_OF_CHANNEL_ACCOUNT_NAME,
	NAME_OF_CHANNEL_TRANSACTION_TYPE,
	NAME_OF_CHANNEL_TYPE,
	NAME_OF_POS_TYPE,
	NAME_OF_SETTLEMENT_SETUP,
} from 'containers/ChannelManagement/Branch/BranchForm/BranchBasicInformation/constants';
import { FixMeLater } from 'types';

export const autoSetDefaults = (
	setValue: (
		name: string,
		value: string,
		options?: Partial<{
			shouldValidate: boolean;
			shouldDirty: boolean;
			shouldTouch: boolean;
		}>
	) => void,
	channelPrimaryInfo: FixMeLater
): void => {
	setValue(NAME_OF_CHANNEL_ACCOUNT_NAME, channelPrimaryInfo?.name || '');
	setValue(NAME_OF_CHANNEL_ACCOUNT_CODE, channelPrimaryInfo?.tpaAccount || '');
	setValue(
		NAME_OF_CHANNEL_TRANSACTION_TYPE,
		channelPrimaryInfo?.channelTransactionType || ''
	);
	setValue(NAME_OF_CHANNEL_TYPE, channelPrimaryInfo?.channelType || '');
	setValue(NAME_OF_POS_TYPE, channelPrimaryInfo?.posType);
	setValue(NAME_OF_SETTLEMENT_SETUP, channelPrimaryInfo?.settlementSetup);
};
