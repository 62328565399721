import { Modal } from '@salesforce/design-system-react/module/components';
import RenderByType from '../common/RenderByType';
import { ReactComponent as Icon } from 'assets/icons/ic-otp.svg';
import styles from './OtpModal.module.css';
import { ReactNode } from 'react';
import Grid from 'components/Grid/Grid';
import cx from 'classnames';

type OtpModalSectionProps = {
	children: ReactNode;
};

export const OtpModalBody: React.FC<OtpModalSectionProps> = ({
	children,
	...props
}) => {
	return <div {...props}>{children}</div>;
};
export const OtpModalActions: React.FC<OtpModalSectionProps> = ({
	children,
	...props
}) => {
	return <div {...props}>{children}</div>;
};
export const OtpModalSubActions: React.FC<OtpModalSectionProps> = ({
	children,
	...props
}) => {
	return <div {...props}>{children}</div>;
};

const OtpIcon: React.FC = () => <Icon className={styles.otpIcon} />;

type OtpModalProps = {
	open?: boolean;
	onClose?: () => void;
	Icon?: React.FC;
	children: ReactNode;
	larger?: boolean;
};

export const OtpText: React.FC = ({ children }) => (
	<div className={styles.otpModalText}>{children}</div>
);
export const OtpSubText: React.FC = ({ children }) => (
	<div className={styles.otpModalSubtext}>{children}</div>
);

export const ResendOtpButton: React.FC = () => (
  <button className={styles.resendOtpButton} style={{ color: 'blue' }}>
    Resend OTP
  </button>
);

export const ResendOtpViaEmailButton: React.FC = () => (
  <button className={styles.resendOtpViaEmailButton} style={{ color: 'blue' }}>
    Resend OTP Via Email
  </button>
);

const OtpModal: React.FC<OtpModalProps> = ({
	open,
	onClose,
	Icon = OtpIcon,
	children,
	larger = false,
  }) => {
	return (
	  <Modal
		isOpen={open}
		onRequestClose={onClose}
		headerClassName={styles.otpModalHeader}
		size="small"
		containerClassName={styles.otpModalContainer}
		contentClassName={cx(styles.otpModal, larger ? styles.larger : '')}
	  >
		<Grid vertical verticalAlign="center" className={styles.otpModalContent}>
		  {Icon && (
			<Grid column className={styles.otpModalIconContainer}>
			  <Icon />
			</Grid>
		  )}
		  <Grid column className={styles.otpModalBody}>
			<RenderByType type={OtpModalBody}>{children}</RenderByType>
		  </Grid>
		  <Grid column className={styles.otpModalActions}>
			<RenderByType type={OtpModalActions}>
			  {children}
			</RenderByType>
		  </Grid>
		  <Grid column className={styles.otpModalSubActions}>
			<RenderByType type={OtpModalSubActions}>
			  <ResendOtpButton />
			  <ResendOtpViaEmailButton />
			</RenderByType>
		  </Grid>
		</Grid>
	  </Modal>
	);
  };
  
  

export default OtpModal;