import {
	SideHeaderProps,
	SideHeaderValues,
} from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrReportPreview/types';

export function useSideHeaders({
	merchantId,
	businessName,
	merchantName,
	generationDate,
}: SideHeaderProps): SideHeaderValues {
	return [
		{
			label: 'Merchant ID',
			value: merchantId,
		},
		{
			label: 'Business Name',
			value: businessName,
		},
		{
			label: 'Merchant Name',
			value: merchantName,
		},
		{
			label: 'Generation Date',
			value: generationDate,
		},
	];
}
