import React, { useState } from 'react'
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab'
import TerminalDetails from 'containers/ChannelManagement/Terminal/Tab/TerminalDetails'
import TerminalAuditTrail from 'containers/ChannelManagement/Terminal/Tab/TerminalAuditTrail'
import { TerminalTabProps } from 'containers/ChannelManagement/Terminal/types';

const TerminalTab: React.FC<TerminalTabProps> = ({
    refetchTerminal,
}) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const primaryTabProps = {
		tabs: [
			{
				title: 'Terminal Details',
				component: <TerminalDetails refetchTerminal={refetchTerminal} />,
			},
			{
				title: 'Audit Trail',
				// scope: 'audit-trail',
				component: selectedIndex === 1 ? <TerminalAuditTrail /> : null,
			},
		],
		defaultIndex: 0,
	}
    return <PrimaryTab {...primaryTabProps} onSelect={setSelectedIndex} />;
}

export default TerminalTab