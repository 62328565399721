import { ReactComponent as AddIcon } from 'assets/icons/ic-add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/ic-delete.svg';
import Button from 'components/Buttons/Button';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Grid from 'components/Grid/Grid';
import TextField from 'components/Inputs/TextField/TextField';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import errorModalStyles from 'components/Modal/ErrorModal.module.css';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import successModalStyles from 'components/Modal/SuccessModal.module.css';
import Section, { SectionRow } from 'components/Section/Section';
import {
	EmailTagInput,
	MobileNumberTagInput,
	TelephoneNumberTagInput,
} from 'components/TagInput/TagInput';
import { BranchContactDetailsEntryProps } from 'containers/ChannelManagement/Branch/BranchForm/BranchContactDetails/types';
import { BranchType } from 'containers/ChannelManagement/Branch/BranchForm/types';
import { BRANCH_CONTACT_DETAILS_DEFAULT } from 'containers/ChannelManagement/Branch/constants';
import { ReactNode, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useToggle } from 'utils/hooks';
import branchContactDetailsStyles from './BranchContactDetails.module.css';

const styles = {
	...branchContactDetailsStyles,
	...successModalStyles,
	...errorModalStyles,
};

const BranchContactDetails: React.FC<BranchContactDetailsEntryProps> = ({
	maxEntries,
	disabled,
}) => {
	const name = 'branchContactDetails';
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({
		action: () => undefined,
	});
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({
		action: () => undefined,
	});
	const [onConfirmationClose, setOnConfirmationClose] = useState({
		action: () => undefined,
	});
	const [confirmBtnOnClick, setConfirmBtnOnClick] = useState({
		action: () => undefined,
	});
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [confirmationHeader, setConfirmationHeader] = useState<ReactNode>('');
	const [confirmationMessage, setConfirmationMessage] = useState<ReactNode>('');
	const lastEntryRef = useRef<HTMLDivElement | null>(null);
	const firstEntryRef = useRef<HTMLDivElement | null>(null);
	const { control } = useFormContext<BranchType>();
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});
	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();
	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();
	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const showErrorMessage = (
		header: string,
		message: string,
		onRetryBtnClick?: () => void
	) => {
		setErrorHeader(header);
		setErrorMessage(message);
		showErrorModal();
		setRetryBtnOnClick({
			action: () => {
				hideErrorModal();
				onRetryBtnClick && onRetryBtnClick();
			},
		});
	};

	const showSuccessMessage = (
		message: ReactNode,
		onDoneBtnClick?: () => void
	) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	const showConfirmationMessage = (
		header: ReactNode,
		message: ReactNode,
		onConfirmBtnClick?: () => void,
		onClose?: () => void
	) => {
		setConfirmationHeader(header);
		setConfirmationMessage(message);
		showConfirmationModal();
		setConfirmBtnOnClick({
			action: () => {
				hideConfirmationModal();
				onConfirmBtnClick && onConfirmBtnClick();
			},
		});
		setOnConfirmationClose({
			action: () => {
				hideConfirmationModal();
				onClose && onClose();
			},
		});
	};

	const handleAddEntry = () => {
		if (maxEntries && fields.length >= maxEntries) {
			showErrorMessage(
				'Failed to Add Branch Contact Details',
				'You have reached max no. of accepted Branch Contact Details'
			);
			return;
		}
		append(BRANCH_CONTACT_DETAILS_DEFAULT);
	};

	const handleConfirmDeleteEntry = (index: number) => {
		if (index) {
			showConfirmationMessage(
				`Delete Branch Contact Details ${index + 1}`,
				<>
					<span>
						Are you sure you want to delete{' '}
						<span className={styles.confirmTextName}>
							Branch Contact Details {index + 1}?
						</span>
					</span>
				</>,
				() => {
					remove(index);
					showSuccessMessage(
						<div className={styles.successModalText}>
							Branch Contact Details {index + 1} successfully deleted.
						</div>
					);
					autoScroll('head');
				}
			);
		}
	};

	const autoScroll = (scrollAction: string) => {
		if (scrollAction === 'tail') {
			lastEntryRef.current &&
				lastEntryRef.current.scrollIntoView({ behavior: 'smooth' });
		}
		if (scrollAction === 'head') {
			firstEntryRef.current &&
				firstEntryRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<>
			{fields.map((field, index) => {
				const n = `${name}.${index}`;
				return (
					<>
						{index === fields.length - 2 && <div ref={firstEntryRef} />}
						<Section
							key={field.id}
							titleClassName={styles.sectionTitle}
							title={() => {
								return (
									<>
										{`Branch Contact Details${
											index > 0 ? ` ${index + 1}` : ''
										}`}
										<div style={{ float: 'right' }}>
											{index === 0 && !disabled ? (
												<Button
													fullWidth
													onClick={() => {
														handleAddEntry();
														autoScroll('tail');
													}}
													className={styles.addButton}
													disabled={disabled}
												>
													<AddIcon /> Add Contact Details
												</Button>
											) : (
												!disabled && (
													<Button
														onClick={() => handleConfirmDeleteEntry(index)}
														disabled={disabled}
													>
														<DeleteIcon />
													</Button>
												)
											)}
										</div>
									</>
								);
							}}
						>
							<SectionRow>
								<Grid column size={1} of={1}>
									<TextField
										label="Name"
										required
										name={`${n}.name`}
										control={control}
										disabled={disabled}
									/>
								</Grid>
								<Grid column size={1} of={2}></Grid>
							</SectionRow>
							<SectionRow>
								<Grid column size={1} of={2}>
									<TextField
										label="Position"
										required
										name={`${n}.position`}
										control={control}
										disabled={disabled}
									/>
								</Grid>
								<Grid column size={1} of={2}>
									<TextField
										label="Department"
										required
										name={`${n}.department`}
										control={control}
										disabled={disabled}
									/>
								</Grid>
							</SectionRow>
							<SectionRow>
								<Grid column size={1} of={2}>
									<EmailTagInput
										required
										control={control}
										name={`${n}.emailAddress`}
										disabled={disabled}
									/>
								</Grid>
							</SectionRow>
							<SectionRow>
								<Grid column size={1} of={2}>
									<TelephoneNumberTagInput
										control={control}
										name={`${n}.telephoneNumber`}
										disabled={disabled}
									/>
								</Grid>
								<Grid column size={1} of={2}>
									<MobileNumberTagInput
										required
										secondarySubtext="* Mobile Number should start with +639"
										control={control}
										name={`${n}.mobileNumber`}
										disabled={disabled}
										validateType="639"
									/>
								</Grid>
							</SectionRow>
							{index === fields.length - 1 && <div ref={lastEntryRef} />}
						</Section>
					</>
				);
			})}
			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalOpen && (
				<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
					<ErrorModalBody>
						<ErrorText>{errorHeader}</ErrorText>
						<ErrorSubText>{errorMessage}</ErrorSubText>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Close
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					heading={confirmationHeader}
					message={confirmationMessage}
					onClose={() => {
						onConfirmationClose.action();
					}}
					onCancelBtnClick={() => {
						onConfirmationClose.action();
					}}
					onConfirmBtnClick={() => {
						confirmBtnOnClick.action();
					}}
					cancelBtnLabel="Back"
				/>
			)}
		</>
	);
};

export default BranchContactDetails;
