import Grid from 'components/Grid/Grid';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import UploadField from 'components/Inputs/UploadField/UploadField';
import Section, { SectionRow } from 'components/Section/Section';
import { boolean } from 'constants/boolean';
import {
	Control,
	UseFormSetValue,
	UseFormClearErrors,
	UseFormGetValues,
} from 'react-hook-form';
import styles from './AreaRestriction.module.css';
import client from 'helpers/ApiClient';
import { useToggle } from 'utils/hooks';
import FilePreviewModal from 'components/UploadAttachments/FilePreviewModal';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { ReactComponent as DownloadIcon } from 'assets/icons/ic-download4.svg';
import { Tooltip } from '@salesforce/design-system-react';
import { fileIconExtComponents } from 'components/FileUploadTable/FileUploadTable';
import ResponseModal from 'components/Modal/ResponseModal';
import { downloadFile } from 'utils/common';
import { isEmpty } from 'lodash';

type Props = {
	control: Control<any>;
	disabled?: boolean;
	setValue: UseFormSetValue<any>;
	clearErrors: UseFormClearErrors<any>;
	action?: string;
	getValues: UseFormGetValues<any>;
	inquireType?: string;
};

const AreaRestriction: React.FC<Props> = ({
	action,
	control,
	disabled,
	setValue,
	clearErrors,
	getValues,
	inquireType,
}) => {
	const name = 'product_business_rule';

	const {
		value: isFileModalOpen,
		valueOn: showFileModal,
		valueOff: hideFileModal,
	} = useToggle();
	const [selectedFile, setSelectedFile] = useState({});
	const productTypeId = useSelector<ReducerStateType>(
		(state) => state.sidebar.itemId
	);
	const hasMemoView =
		!isEmpty(getValues(`${name}.memo`)?.trim() ?? null) &&
		(action === 'VIEW' || action === 'DRAFT' || action === 'APPROVAL') &&
		disabled;
	const memoFileName = !isEmpty(getValues(`${name}.memo`)?.trim() ?? null)
		? getValues(`${name}.memo`).trim().slice(37)
		: null;

	const [response, setResponse] = useState({
		isOpen: false,
		icon: fileIconExtComponents.csv,
		bodyText: '',
		bodyHeader: 'File is being downloaded',
		respondButton: {
			name: 'Done',
			event: () => {
				setResponse({
					...response,
					isOpen: false,
				});
			},
		},
		isRespondButton2Shown: false,
		small: true,
	});
	return (
		<Section title="Area Restriction">
			<SectionRow>
				<Grid column size={1} of={2}>
					<RadioGroup
						name={`${name}.area_restriction`}
						control={control}
						options={boolean}
						label="With Area Restriction?"
						disabled={disabled}
					/>
				</Grid>

				<Grid column size={1} of={2}>
					<Grid container>
						<div className={styles.memoLabelContainer}>
							<div className={styles.memoLabel}>Memo Attachment</div>
							{hasMemoView ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										maxWidth: '25.5vw',
									}}
								>
									<a
										className="commonText"
										style={{ wordBreak: 'break-word' }}
										onClick={() => {
											client
												.get(
													`v2/products/${productTypeId}/${getValues(
														`${name}.product_id`
													)}/document/view/memo`,
													{
														params: {
															key: getValues(`${name}.memo`),
															is_draft: false,
														},
													}
												)
												.then(({ data: { download_url } }) => {
													if (download_url) {
														setSelectedFile({
															fileName: memoFileName,
															url: download_url,
														});
														showFileModal();
													}
												});
										}}
									>
										{memoFileName}
									</a>

									<Tooltip
										id="tooltip"
										align="bottom"
										content="Download"
										triggerClassName={styles.toolTip}
									>
										<DownloadIcon
											onClick={() => {
												const fileExt = getValues(`${name}.memo`)
													.split('.')
													.pop();

												client
													.get(
														`v2/products/${productTypeId}/${getValues(
															`${name}.product_id`
														)}/document/download/memo`,
														{
															params: {
																key: getValues(`${name}.memo`),
																is_draft: false,
															},
														}
													)
													.then(({ data: { download_url } }) => {
														if (download_url) {
															setResponse({
																...response,
																icon:
																	fileIconExtComponents[fileExt] ||
																	fileIconExtComponents.doc,
																isOpen: true,
															});
															downloadFile(download_url, memoFileName);
														}
													});
											}}
										/>
									</Tooltip>
								</div>
							) : (
								<>
									<div className={styles.memoSubtext}>
										<em className={styles.memoSubtextLabel}>
											*Maximum file size:
										</em>
										<span className={styles.memoSubtextValue}>25MB</span>
									</div>
									<div className={styles.memoSubtext}>
										<em className={styles.memoSubtextLabel}>
											*Allowed file types:
										</em>
										<span className={styles.memoSubtextValue}>
											.doc, .docx, .pdf, .jpeg, .jpg, .png
										</span>
									</div>
								</>
							)}
						</div>
					</Grid>
					<Grid container>
						{!hasMemoView && (
							<UploadField
								link="/v2/products/document"
								name={`${name}.memo`}
								placeholder={!hasMemoView ? memoFileName : null}
								control={control}
								setValue={setValue}
								clearErrors={clearErrors}
								disabled={disabled}
								inquireType={inquireType}
								id={getValues(`${name}.product_id`)} //fix this
								getValues={getValues}
								statusUrl={'/v2/uploads/status'}
								documentUrl={'/v2/products/document'}
							/>
						)}
					</Grid>
					{isFileModalOpen && (
						<FilePreviewModal
							open={isFileModalOpen}
							onClose={hideFileModal}
							file={selectedFile}
						/>
					)}
					<ResponseModal {...response} />
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default AreaRestriction;
