import Section, { SectionRow } from 'components/Section/Section';
import {
	Control,
	UseFormClearErrors,
	UseFormSetError,
	UseFormSetValue,
	UseFormGetValues,
} from 'react-hook-form';
import styles from './AccreditationRequirements.module.css';
import { Tooltip } from '@salesforce/design-system-react';
import FileUploadTable from 'components/FileUploadTable/FileUploadTable';
import { product_accreditations } from 'constants/accreditation_requirement';
import { FixMeLater } from 'types';
import { ContractDetailsFormData } from '../../ContractDetails';
import { ReactNode } from 'react';

type Props<T extends ContractDetailsFormData> = {
	control: Control<T>;
	disabled?: boolean;
	setValue: UseFormSetValue<T>;
	getValue: UseFormGetValues<T>;
	clearErrors: UseFormClearErrors<T>;
	setError: UseFormSetError<T>;
	errors?: any;
	onLoadData: FixMeLater;
	inquireType?: string;
	id?: number;
};

const AccreditationRequirements = <T extends ContractDetailsFormData>({
	disabled,
	setValue,
	inquireType,
	id,
	...rest
}: Props<T>): JSX.Element => {
	return (
		<Section
			title={
				<div className={styles.accreditatioRequirementsTitle}>
					Accreditation Requirements
					<Tooltip
						align="bottom left"
						content={
							<>
								Please comply with the following requirements when creating a
								new partner: <br></br>
								<br></br>
								-Non-disclosure Agreement (NDA) <br></br>
								-Requirement 1<br></br>
								-Requirement 2
							</>
						}
					/>
				</div>
			}
		>
			<SectionRow className={styles.noMarginBottom}>
				<FileUploadTable
					mainSetValue={setValue}
					name="product_accreditation"
					disabled={disabled}
					data={product_accreditations}
					onChange={() => {
						null;
					}}
					fileFormats={['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png']}
					maxFileSize="25"
					maxFileSizeUnit="MB"
					inquireType={inquireType}
					id={id}
					{...rest}
				/>
			</SectionRow>
		</Section>
	);
};

export default AccreditationRequirements;
