import client from 'helpers/ApiClient';
import { useQuery } from 'react-query';
import { GET_CHANNEL_LIST, GET_BRANCH_LIST, GET_TERMINAL_LIST } from './const';

export const useGetChannelList = (params: any) => {
	return useQuery([GET_CHANNEL_LIST, params], async () => {
		const response = await client.get('/v2/channels/', { params });
		return response.data;
	});
};

export const useGetBranchList = (params: any, channelId: number) => {
	return useQuery([GET_BRANCH_LIST, params, channelId], async () => {
		const response = await client.get(`/v2/channels/${channelId}/branches`, {
			params,
		});
		return response.data;
	});
};

export const useGetTerminalList = (params: any, branchId: number) => {
	return useQuery([GET_TERMINAL_LIST, params, branchId], async () => {
		const response = await client.get(`/v2/branches/${branchId}/terminals`, {
			params,
		});
		return response.data;
	});
};
