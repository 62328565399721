import * as yup from 'yup';
import { ChannelBasicInformationSchema } from './ChannelBasicInformation/ChannelBasicInformationSchema';
import { YupLab } from 'utils/yupLab';

export const objectSchema = yup.object().shape({
	primaryInfo: ChannelBasicInformationSchema.schema,
});

export const PrimaryInformationSchema = new YupLab(objectSchema);
export const primartyInfoInitlValues = PrimaryInformationSchema.schema.cast({});
export type PrimaryInfoType = yup.InferType<typeof objectSchema>
