import { useEffect } from 'react';
import { useList } from 'react-use';

type UseMutlipleSelectReturn<T> = {
	entries: T[];
	setEntries: (entries: T[]) => void;
	resetEntries: () => void;
	filterEntries: (
		fn: (entry: T, index?: number, array?: T[]) => boolean
	) => void;
	selectedEntries: T[];
	setSelectedEntries: (entries: T[]) => void;
	allSelected: boolean;
	isSelected: (entry: T) => boolean;
	toggle: (entry: T) => void;
	toggleAll: (value?: boolean) => boolean;
	select: (entry: T) => boolean;
	unselect: (entry: T) => void;
	selectAll: () => void;
	unselectAll: () => void;
};

type UseMultipleSelectConfig<T> = {
	frozenEntries: T[];
	initialValue: T[];
	onChange?: (values: T[]) => void;
};

export default function useMultipleSelect<T>(
	pool: T[],
	extractUniqueIdentifier: (entry: T) => string | number, // entries in the pool must have a unique identifier
	config: Partial<UseMultipleSelectConfig<T>> = {}
): UseMutlipleSelectReturn<T> {
	const { frozenEntries = [], initialValue = [] } = config;

	const initialSelected = frozenEntries.concat(
		initialValue.filter((x) => !isFrozen(x))
	);
	const [selectedEntries, { push, filter: filterSelectedEntries, set }] =
		useList<T>(initialSelected);

	useEffect(() => {
		config.onChange?.(selectedEntries);
	}, [selectedEntries]);

	function isFrozen(entry: T | string) {
		const uniqueIdentifier = resolveUniqueIdentifier(entry);
		return frozenEntries
			.map(extractUniqueIdentifier)
			.includes(uniqueIdentifier);
	}

	const [entries, { set: setEntries, reset: resetEntries }] = useList<T>(pool);

	function filterEntries(predicate: (entry: T) => boolean) {
		setEntries(pool.filter(predicate));
	}

	function setSelectedEntries(entriesToSelect: T[]) {
		const ids = entriesToSelect.map(extractUniqueIdentifier);
		set(
			pool.filter((p) => {
				const isToBeSelected = ids.includes(extractUniqueIdentifier(p));
				return isToBeSelected || isFrozen(p);
			})
		);
	}

	const selectedUniqueIdentifiers = selectedEntries.map(
		extractUniqueIdentifier
	);

	function resolveUniqueIdentifier(value: string | T) {
		return typeof value === 'string' ? value : extractUniqueIdentifier(value);
	}

	function isSelected(entry: T | string) {

		return (
			isFrozen(entry) ||
			selectedUniqueIdentifiers.includes(resolveUniqueIdentifier(entry))
		);
	}

	function select(entry: T) {
		// already selected
		if (isSelected(entry)) return false;
		push(entry);
		return true;
	}

	function unselect(value: string | T) {
		if (isFrozen(value)) return;

		filterSelectedEntries(
			(entry) =>
				extractUniqueIdentifier(entry) !== resolveUniqueIdentifier(value)
		);
	}

	// select if not selected, unselect if already selected
	function toggle(selectedEntry: T) {
		const alreadySelected = !select(selectedEntry);

		if (alreadySelected) {
			filterSelectedEntries(
				(entry) =>
					extractUniqueIdentifier(selectedEntry) !==
					extractUniqueIdentifier(entry)
			);
		}
	}

	const allSelected = pool.every((entry) =>
		selectedUniqueIdentifiers.includes(extractUniqueIdentifier(entry))
	);

	function selectAll() {
		set(pool);
	}

	function unselectAll() {
		set(pool.filter(isFrozen));
	}

	function toggleAll(select?: boolean) {
		const shouldSelectAll = select === undefined ? !allSelected : select;
		if (shouldSelectAll) {
			selectAll();
			return true;
		}
		unselectAll();
		return false;
	}

	return {
		entries,
		setEntries,
		selectedEntries,
		resetEntries,
		filterEntries,
		setSelectedEntries,
		allSelected,
		isSelected,
		toggle,
		toggleAll,
		select,
		unselect,
		selectAll,
		unselectAll,
	};
}
