import { BranchType } from 'containers/ChannelManagement/Branch/BranchForm/types';
import { BranchResponseType } from 'containers/ChannelManagement/Branch/BranchTab/BranchDetails/types';

export const transformBranchResponseToBranchType = (
	response: BranchResponseType
): BranchType => {
	const transformed: BranchType = {
		branchBasicInformation: {
			channelAccountName: '',
			channelAccountCode: '',
			branchName: response?.data?.name || '',
			branchCode: response?.data?.branchCode || '',
			channelTransactionType: '', //TODO BE did not implement yet
			channelType: '', //TODO BE did not implement yet
			posType: response?.data?.branchPrimaryInfos?.posType || '',
			settlementSetup: '', //TODO BE did not implement yet
			storeType: response?.data?.branchPrimaryInfos?.storeType || '',
			storeFormat: response?.data?.branchPrimaryInfos?.storeFormat || '',
			machineLocation:
				response?.data?.branchPrimaryInfos?.machineLocation || '',
			pldtTellerCode: response?.data?.branchPrimaryInfos?.pldtCode || '',
			cignalTellerCode: response?.data?.branchPrimaryInfos?.cignalCode || '',
			meralcoPaymentCenterCode: String(
				response?.data?.branchPrimaryInfos?.meralcoPaymentCenterId || ''
			),
		},
		branchAddress: {
			locationBase: response?.data?.branchAddresses[0]?.locationBase || '',
			country: String(response?.data?.branchAddresses[0]?.countryId || ''),
			area: response?.data?.branchAddresses[0]?.area || '',
			region: String(response?.data?.branchAddresses[0]?.regionId || ''),
			stateOrProvince: String(
				response?.data?.branchAddresses[0]?.provinceId || ''
			),
			municipalityOrCity: String(
				response?.data?.branchAddresses[0]?.cityId || ''
			),
			barangay: String(response?.data?.branchAddresses[0]?.barangayId || ''),
			buildingNameOrNumber:
				response?.data?.branchAddresses[0]?.buildingName || '',
			street: response?.data?.branchAddresses[0]?.street || '',
			zipCode: String(response?.data?.branchAddresses[0]?.zipCodeId || ''),
		},
		branchContactDetails:
			response?.data?.branchContactDetails.map((item) => {
				return {
					name: item?.name || '',
					department: item?.department || '',
					emailAddress: item?.emailAddress?.split(',') || [],
					mobileNumber: item?.mobileNo?.split(',') || [],
					position: item?.position,
					telephoneNumber: item?.telNo?.split(',') || [],
				};
			}) || [],
		branchOperatingSchedule: {
			schedule:
				response?.data?.branchOperatingSchedule?.map((item) => {
					return {
						day: item.days,
						endTime: item.endTime,
						startTime: item.startTime,
						isSelected: true,
					};
				}) || [],
			depositConsolidation:
				response?.data?.branchPrimaryInfos?.depositConsolidation || '',
			taxPayerType: response?.data?.branchPrimaryInfos?.taxPayerType || '',
			activationDate: response?.data?.branchPrimaryInfos?.activationDate || '',
			remarks: response?.data?.branchPrimaryInfos?.remarks || '',
		},
	};
	return transformed;
};
