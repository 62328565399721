import { SelectOption } from 'components/Inputs/SelectField/SelectField';
import {
	BRANCH_BASIC_INFORMATION,
	CHANNEL_ACCOUNT_CODE,
	CHANNEL_ACCOUNT_NAME,
	CHANNEL_TRANSACTION_TYPE,
	CHANNEL_TYPE,
	POS_TYPE,
	SETTLEMENT_SETUP,
} from 'containers/ChannelManagement/Branch/BranchForm/constants';

export const POST_TYPE_OPTIONS: SelectOption[] = [
	{
		value: 'ASENSO',
		label: 'Asenso',
	},
	{
		value: 'BFA',
		label: 'BFA',
	},
	{
		value: 'PCS',
		label: 'PCS',
	},
	{
		value: 'BIOS',
		label: 'BIOS',
	},
	{
		value: 'KIOSK',
		label: 'Kiosk/Self-Service',
	},
	{
		value: 'FRONT_END',
		label: 'Own Front-end System',
	},
];

export const STORE_TYPE_OPTIONS: SelectOption[] = [
	{
		value: 'FULL-BOOTH',
		label: 'Full Booth',
	},
	{
		value: 'BOOTH',
		label: 'Booth',
	},
];

export const STORE_FORMAT_OPTIONS: SelectOption[] = [
	{
		value: 'STANDALONE',
		label: 'Standalone',
	},
	{
		value: 'MALL-BASED',
		label: 'Mall-based',
	},
];

export const MACHINE_LOCATION_OPTION: SelectOption[] = [
	{
		value: 'INSIDE',
		label: 'Inside Biller Office',
	},
	{
		value: 'OUTSIDE',
		label: 'Outside Biller Office',
	},
];

export const MERALCO_PAMENT_CENTER_CODE_OPTIONS: SelectOption[] = [
	{
		value: '1080001',
		label: 'BAYAD – ATM',
	},
	{
		value: '080002',
		label: 'BAYAD – PHONE',
	},
	{
		value: '080004',
		label: 'BAYAD – BATCH (MECOA)',
	},
	{
		value: '080008',
		label: 'BAYAD - INTERNET – RTP (Bayad Online)',
	},
	{
		value: '080009',
		label: 'BAYAD - MOBILE – RTP (Bayad App)',
	},
	{
		value: '080012',
		label: 'BAYAD – ODC (On Demand Charging)',
	},
	{
		value: '080015',
		label: 'BAYAD - APM (INSIDE MERALCO) - RTP',
	},
	{
		value: '080016',
		label: 'BAYAD - APM (OUTSIDE MERALCO) - RTP',
	},
	{
		value: '080017',
		label: 'BAYAD PARTNER - OTC - RTP',
	},
	{
		value: '080018',
		label: 'BAYAD PARTNER - DIGITAL – RTP',
	},
	{
		value: '080019',
		label: 'BAYAD - OTC - RTP',
	},
	{
		value: '080020',
		label: 'BAYAD BANK PARTNER - OTC - RTP',
	},
	{
		value: '080021',
		label: 'BAYAD BANK PARTNER - DIGITAL - RTP',
	},
	{
		value: '080022',
		label: 'BAYAD BANK PARTNER - OTC - BATCH',
	},
	{
		value: '080023',
		label: 'BAYAD BANK PARTNER - DIGITAL – BATCH',
	},
	{
		value: '080024',
		label: 'BAYAD BANK PARTNER - OTC - RTP VIA INSTAPAY',
	},
	{
		value: '080025',
		label: 'BAYAD BANK PARTNER - DIGITAL - RTP VIA INSTAPAY',
	},
	{
		value: '080026',
		label: 'BAYAD BANK PARTNER - OTC AND DIGITAL - RTP VIA INSTAPAY',
	},
	{
		value: '080027',
		label: 'BAYAD PARTNER - OTC - RTP VIA INSTAPAY',
	},
	{
		value: '080028',
		label: 'BAYAD PARTNER - DIGITAL - RTP VIA INSTAPAY',
	},
	{
		value: '080029',
		label: 'BAYAD PARTNER - OTC AND DIGITAL - RTP VIA INSTAPAY',
	},
];

export const CHANNEL_TRANSACTION_TYPE_OPTIONS: SelectOption[] = [
	{
		value: 'DIGITAL',
		label: 'Digital',
	},
	{
		value: 'PHYSICAL',
		label: 'Physical',
	},
];

export const CHANNEL_TYPE_PHYSIAL: SelectOption[] = [
	{
		value: '1',
		label: 'Center',
	},
	{
		value: '2',
		label: 'Partner',
	},
	{
		value: '3',
		label: 'Agent',
	},
	{
		value: '4',
		label: 'Machine (Inside Office)',
	},
	{
		value: '5',
		label: 'Machine (Outside Office)',
	},
	{
		value: '6',
		label: 'Collect',
	},
];

export const CHANNEL_TYPE_DIGITAL: SelectOption[] = [
	{
		value: '7',
		label: 'Digital (Online/Mobile App)',
	},
];

export const SETTLEMENT_SETUP_OPTIONS: SelectOption[] = [
	{
		value: 'PREFUNDED',
		label: 'Prefunded',
	},
	{
		value: 'BONDED',
		label: 'Bonded',
	},
];

export const NAME_OF_CHANNEL_ACCOUNT_NAME = `${BRANCH_BASIC_INFORMATION}.${CHANNEL_ACCOUNT_NAME}`;
export const NAME_OF_CHANNEL_ACCOUNT_CODE = `${BRANCH_BASIC_INFORMATION}.${CHANNEL_ACCOUNT_CODE}`;
export const NAME_OF_CHANNEL_TRANSACTION_TYPE = `${BRANCH_BASIC_INFORMATION}.${CHANNEL_TRANSACTION_TYPE}`;
export const NAME_OF_CHANNEL_TYPE = `${BRANCH_BASIC_INFORMATION}.${CHANNEL_TYPE}`;
export const NAME_OF_POS_TYPE = `${BRANCH_BASIC_INFORMATION}.${POS_TYPE}`;
export const NAME_OF_SETTLEMENT_SETUP = `${BRANCH_BASIC_INFORMATION}.${SETTLEMENT_SETUP}`;
export const PHYSICAL = 'PHYSICAL';
