import { Tooltip } from "@salesforce/design-system-react";
import FileUploadTable from "components/FileUploadTable/FileUploadTable";
import Section, { SectionRow } from "components/Section/Section";
import { useFormContext } from "react-hook-form";
import { ACCREDITATION_REQUIREMENTS_OPTIONS } from "../ContractDetailsConstants";
import { ContractDetailsType } from "../ContractDetailsSchemas";
import styles from './AccreditationRequirements.module.css';


type Props = {
    title: string;
    name: string;
    disabled: boolean;
}
const AccreditationRequirements: React.FC<Props> = ({ disabled, name, title }) => {
    const { control, setValue } = useFormContext<ContractDetailsType>();
    // data is the selected Channel this is only a mock data fix later
    const id = undefined; // ID is the selected channel
    const data = {
        status: 'DRAFT'
        , product_contract: {
            product_accreditation: {
                product_id: 1
            }
        }
    }
    const inquireType =
        !['DRAFT', 'FOR_REVIEW', 'REJECTED'].includes(data.status) &&
            (0 || data.product_contract.product_accreditation.product_id)
            ? 'product_document_active'
            : 'product_document_draft';
    return (
        <Section
            title={
                <div className={styles.accreditatioRequirementsTitle}>
                    {title}
                    <Tooltip
                        align="bottom left"
                        content={
                            <>
                                Please comply with the following requirements when creating a
                                new partner: <br></br>
                                <br></br>
                                -Non-disclosure Agreement (NDA) <br></br>
                                -Requirement 1<br></br>
                                -Requirement 2
                            </>
						}
                    />
                </div>
            }
        >
            <SectionRow className={styles.noMarginBottom}>
                <FileUploadTable
                    mainSetValue={setValue}
                    name={name}
                    disabled={disabled}
                    data={ACCREDITATION_REQUIREMENTS_OPTIONS}
                    onChange={() => {
                        null;
                    }}
                    fileFormats={['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png']}
                    maxFileSize="25"
                    maxFileSizeUnit="MB"
                    inquireType={inquireType}
                    channel={true}
                    id={id}
                //id={id}
                //{...rest}
                />
            </SectionRow>
        </Section>
    )
}

export default AccreditationRequirements