/* eslint-disable prefer-const */
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ApprovalListFilter from './ApprovalListFilter';
import ApprovalListTable from './ApprovalListTable';
import cx from 'classnames';
import Button from 'components/Buttons/Button';
import {
	IconSettings,
	Button as SFButton,
	Modal,
	Tooltip,
} from '@salesforce/design-system-react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import HTTP from 'helpers/ApiClient';
import { useToggle } from 'utils/hooks';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { formatDate, resolveValue } from 'utils/common';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './Approval.module.css';
import { showAccessDeniedModal } from 'redux/modules/access';
import Checkbox from 'components/Checkbox/Checkbox';
import { ReactComponent as ApproveIcon } from 'assets/icons/ic-approve.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/ic-reject.svg';
import {
	setSelectIndividual,
	setSelectAll,
} from '../../../redux/modules/approval';
import PartnerModal from 'components/PartnerModal/PartnerModal';
import OutlineButton from 'components/Buttons/OutlineButton';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import { status_option } from 'components/PartnerStatusSelect/PartnerStatusSelect';
import { mapViewPartner } from 'components/PartnerDetails/PartnerDetails';
import { FixMeLater } from 'types';
import { useHasUserPermission } from 'utils/permissions';
import { ReducerStateType } from 'redux/modules/reducers';
import { restrictSpecialChars } from 'utils/common';

interface ICell {
	value: any;
	cell?: any;
}

const INITIAL_VALUES = {
	createdAt: '',
	updatedAt: '',
	status: '',
};

const Cell: React.FC<{ value: any }> = ({ value }) => (
	<div className={styles.rowBreak}>{value}</div>
);
type GetApprovalListArgs = {
	createdBy?: string;
	createdAt?: string | null;
	updatedAt?: string | null;
	partnerName?: string;
	page?: number;
	pageSize?: number;
	status?: string;
	limit?: number;
	sortBy?: string;
	sort?: string;
};

type Props = {
	username?: any;
	product_type_id: any;
};

type FetchApprovalListArgs = Omit<
	GetApprovalListArgs,
	'dateCreatedFrom' | 'dateCreatedTo'
>;

const Approval: React.FC<Props> = ({ product_type_id }) => {
	const productShortName: any = useSelector<ReducerStateType>(
		(state) =>
			state.products.trackPrimaryInfo?.product_primary_info?.product_short_name
	);
	const [data, setData] = useState<Array<any>>([]);
	const [prevData, setPrevData] = useState<Array<any>>([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [filterParams, setFilterParams] = useState<GetApprovalListArgs>({});
	const [isSearch, setIsSearch] = useState(false);
	const [sortBy, setSortBy] = useState('created_at');
	const [sort, setSort] = useState('desc');
	const [lastParams, setLastParams] = useState<any>({});
	const [checkAll, setCheckAll] = useState<boolean>(false);
	const [approvalData, setApprovalData] = useState({});
	const [currentPartnerName, setCurrentPartnerName] = useState('');
	const [currentPartnerId, setCurrentPartnerId] = useState('');
	const [currentNoSelectedPartners, setCurrentNoSelectedPartners] = useState(0);
	const [currentPartnerIds, setCurrentPartnerIds] = useState<string[]>([]);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [isConfirmEnabled, setIsConfirmEnabled] = useState(true);
	const [isConfirmReject, setIsConfirmReject] = useState(true);
	const [isApprovalRemarksEmpty, setIsApprovalRemarksEmpty] =
		useState<boolean>(true);
	const [isRejectionRemarksEmpty, setIsRejectionRemarksEmpty] =
		useState<boolean>(true);

	const [isBatchApprovalRemarksEmpty, setIsBatchApprovalRemarksEmpty] =
		useState<boolean>(true);
	const [isBatchRejectionRemarksEmpty, setIsBatchRejectionRemarksEmpty] =
		useState<boolean>(true);

	let approvalRemarksField = React.createRef<HTMLTextAreaElement>();
	let rejectionRemarksField = React.createRef<HTMLTextAreaElement>();
	let batchApprovalRemarksField = React.createRef<HTMLTextAreaElement>();
	let batchRejectionRemarksField = React.createRef<HTMLTextAreaElement>();
	let remarks = '';
	const maxPartnerHeaderSpiel = 'Maximum Partner Selected';

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);
	const hasUserPermission = useHasUserPermission('products');

	const {
		hasApprovePermission,
		hasRejectPermission,
		hasApprovalListPermission,
	} = useMemo(() => {
		return {
			hasApprovePermission: hasUserPermission(
				productCategory,
				'approval.approve'
			),
			hasRejectPermission: hasUserPermission(
				productCategory,
				'approval.reject'
			),
			hasApprovalListPermission: hasUserPermission(
				productCategory,
				'list.approval'
			),
		};
	}, [hasUserPermission, productCategory]);

	//For Approval
	const handleChangeOfApprovalRemarks = (event) =>
		setIsApprovalRemarksEmpty(
			event.target.value.match(/^\s+$/) || event.target.value === ''
		);
	const onRequestCloseApproval = () => {
		setIsApprovalRemarksEmpty(true);
		hideApprovalModal();
	};

	//For Rejection
	const handleChangeOfRejectionRemarks = (event) =>
		setIsRejectionRemarksEmpty(
			event.target.value.match(/^\s+$/) || event.target.value === ''
		);
	const onRequestCloseRejection = () => {
		setIsRejectionRemarksEmpty(true);
		hideRejectionModal();
	};

	//For Batch Approval
	const handleChangeOfBatchApprovalRemarks = (event) =>
		setIsBatchApprovalRemarksEmpty(event.target.value === '');
	const onRequestCloseBatchApproval = () => {
		setIsBatchApprovalRemarksEmpty(true);
		hideBatchApprovalModal();
	};

	//For Batch Rejection
	const handleChangeOfBatchRejectionRemarks = (event) =>
		setIsBatchRejectionRemarksEmpty(event.target.value === '');
	const onRequestCloseBatchRejection = () => {
		setIsConfirmEnabled(true);
		setIsConfirmReject(true);
		setIsBatchRejectionRemarksEmpty(true);
		hideBatchRejectionModal();
	};

	const onCheckAll = (v) => {
		setCheckAll(v);
		const temp = data;
		const checkedRows = () => temp.filter((d) => d.checked == true);

		temp.forEach((row: any) => {
			// if true/checked (only 10)
			if (v && row.status == 'FOR_REVIEW' && checkedRows().length <= 9) {
				row.checked = true;
			}
			//if false/unchecked
			else {
				row.checked = false;
			}
		});
		setPrevData(temp);
	};

	const onCheckIndividual = (row, v) => {
		//only 10 max select rows
		const checkedRows = prevData.filter((d) => d.checked == true);
		if (checkedRows.length > 9 && v === true) {
			setErrorHeader(maxPartnerHeaderSpiel);
			setErrorMessage('Only max of 10 partners can be processed at a time.');
			showErrorModal();
			return;
		}
		// if all selected, check select all checkbox
		if (checkedRows.length === 9 && v === true) {
			setCheckAll(true);
		}

		const selectedRow: Array<any> = prevData;
		setPrevData(selectedRow);

		const temp: Array<any> = data;
		temp[row.id]['checked'] = v;
		temp.forEach((row) => {
			if (!row['checked'] && checkAll) {
				setCheckAll(false);
			}
			setData([...temp]);
		});
	};

	const clearSelectedRow = () => {
		const temp = prevData.map((row) => {
			row.checked = false;
			return row;
		});
		setPrevData(temp);
	};

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isApprovalModalShown,
		valueOn: showApprovalModal,
		valueOff: hideApprovalModal,
	} = useToggle();

	const {
		value: isRejectionModalShown,
		valueOn: showRejectionModal,
		valueOff: hideRejectionModal,
	} = useToggle();

	const {
		value: isBatchApprovalModalShown,
		valueOn: showBatchApprovalModal,
		valueOff: hideBatchApprovalModal,
	} = useToggle();

	const {
		value: isBatchRejectionModalShown,
		valueOn: showBatchRejectionModal,
		valueOff: hideBatchRejectionModal,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isPartnerModalShown,
		valueOn: showPartnerModal,
		valueOff: hidePartnerModal,
	} = useToggle();

	const dispatch = useDispatch();

	const APPROVAL_COLUMNS = (
		onCheckAll: any,
		onCheckIndividual,
		checkState: any
	) => [
		{
			Header: () => {
				if (
					data.filter((partner) => partner.status === 'FOR_REVIEW').length !== 0
				) {
					return (
						<div>
							<Checkbox
								label=""
								type="checkbox"
								onChange={(v) => {
									onCheckAll(v);
								}}
								checked={checkState}
								disabled={[hasApprovePermission, hasRejectPermission].every(
									(val) => val === false
								)}
							/>
						</div>
					);
				}
			},
			id: 'checked',
			Cell: ({ row }) => {
				if (row.values.status.value == 'FOR_REVIEW') {
					return (
						<div>
							<Checkbox
								label=""
								type="checkbox"
								onChange={(v) => onCheckIndividual(row, v)}
								checked={row.original.checked}
								disabled={[hasApprovePermission, hasRejectPermission].every(
									(val) => val === false
								)}
							/>
						</div>
					);
				}
				return null;
			},
			width: '4%',
		},
		{
			Header: 'Date & Time Created',
			id: 'created_at',
			sortable: true,
			width: '14%',
			accessor: ({ created_at }) => formatDate(created_at),
		},
		{
			Header: 'Submitted by',
			id: 'submitted_by',
			sortable: true,
			width: '11%',
		},
		{
			Header: 'Partner Name',
			id: 'name',
			sortable: true,
			width: '12%',
		},
		{
			Header: 'Date Updated',
			id: 'updated_at',
			sortable: true,
			width: '16%',
			accessor: ({ updated_at }) => formatDate(updated_at),
		},
		{
			Header: 'Updated by',
			id: 'updated_by',
			sortable: true,
			width: '13%',
		},
		{
			Header: 'Status',
			id: 'status',
			sortable: true,
			width: '10%',
			accessor: ({ status }) => {
				return status_option.find(({ value }) => value === status);
			},
			Cell: ({ cell, value }) => {
				if (!value) {
					return null;
				}
				return (
					<div {...cell.getCellProps()} className={value.className}>
						{value.label}
					</div>
				);
			},
		},
		{
			Header: 'Remarks',
			id: 'remarks',
			sortable: false,
			Cell: ({ value }: ICell) => {
				return (
					<>
						<Tooltip
							id="tooltip"
							align="bottom left"
							content={value}
							triggerClassName={styles.toolTip}
							position="overflowBoundaryElement"
						>
							<div>{value}</div>
						</Tooltip>
					</>
				);
			},
		},
		{
			Header: (props) => (
				<div className={cx(styles.actionHeader, styles.centerAlign)}>
					Action
				</div>
			),
			id: 'actions',
			accessor: (values: any) => values,
			Cell: ({ value }: ICell) => {
				return (
					<>
						<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
							<div className={cx(styles.actionCell, 'slds-grid')}>
								<div
									className={cx(
										'slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center',
										styles.actionBtnContainer
									)}
								>
									<SFButton
										assistiveText={{ icon: 'Icon Bare Small' }}
										className={styles.crossIcon}
										iconCategory="utility"
										iconName="picklist_type"
										iconSize="small"
										iconVariant="bare"
										iconClassName={cx(styles.iconBtn)}
										onClick={() => {
											getApprovalValues(value.id, value.updated_by);
										}}
										variant="icon"
									/>
									{value.status === 'FOR_REVIEW' ? (
										<>
											{hasApprovePermission && (
												<Button
													onClick={() => {
														setCurrentPartnerName(value.name);
														setCurrentPartnerId(value.id);
														showApprovalModal();
													}}
												>
													<FaCheck
														className={styles.iconBtn}
														color="#6fcf6c"
														size="1.3em"
													/>
												</Button>
											)}
											{hasRejectPermission && (
												<Button
													onClick={() => {
														setCurrentPartnerName(value.name);
														setCurrentPartnerId(value.id);
														showRejectionModal();
													}}
												>
													<FaTimes
														className={styles.iconBtn}
														color="#db312c"
														size="1.3em"
													/>
												</Button>
											)}
										</>
									) : null}
								</div>
							</div>
						</IconSettings>
					</>
				);
			},
		},
	];

	const getApprovalList = async function (values: GetApprovalListArgs = {}) {
		const {
			createdBy,
			createdAt,
			updatedAt,
			partnerName,
			status,
			page,
			pageSize,
			limit,
			sortBy,
			sort,
		} = values;

		const params = {
			createdBy,
			createdAt,
			updatedAt,
			partnerName,
			status,
			page,
			pageSize,
			limit,
			sortBy,
			sort,
		};
		const result = await HTTP.get(`/v2/products/${product_type_id}/approval`, {
			params,
		});
		return result.data;
	};

	const getApprovalValues = async function (id: string, updated_by: string) {
		showLoadingMessage(
			"Please wait while the selected request's details is being loaded"
		);
		await HTTP.get(`/v2/products/${product_type_id}/${id}/approval-details`)
			.then((resp) => {
				const result = resp.data.data;
				const data = result;
				if (!data) return;

				const formattedValues: FixMeLater = {
					id: data.id,
					status: data.status,
					logo_url: data.logo_url,
					code: data.code,
					...mapViewPartner(data, true),
					updated_at: data.updated_at,
					updated_by: updated_by,
				};
				setApprovalData(formattedValues);
				setCurrentPartnerName(data.product_primary_info.product_trade_name);
				showPartnerModal();
			})
			.catch(() => {
				showErrorMessage(
					'Timeout Error!',
					"An error occurred while trying to load selected partner's details",
					() => {
						getApprovalValues(id, updated_by);
					}
				);
			})
			.finally(() => {
				hideLoading();
			});
	};

	const fetchApprovalList = async (
		values: FetchApprovalListArgs = {},
		retry = false,
		withLoader = true
	) => {
		const doRequest = async (p) => {
			try {
				withLoader &&
					showLoadingMessage('Please wait while approval list is being loaded');
				setLastParams(p);
				const result = await getApprovalList(p);
				const { meta } = result;
				let { data: newData } = result;

				const isDataPresent = newData.some((newItem) =>
					data.some((existingItem) => existingItem.id === newItem.id)
				);
				if (!isDataPresent) {
					newData = newData.map((x) => {
						const matchingRow = prevData.find((row) => row.id === x.id);
						x.checked = matchingRow ? matchingRow.checked : false;
						return x;
					});
					setPrevData([...data, ...newData]);
				}
				setData(newData);
				setPage(meta.page);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e: any) {
				if (e.response.status === 403) {
					dispatch(showAccessDeniedModal());
				} else {
					if (withLoader) {
						showErrorMessage(
							'Timeout Error!',
							'An error occurred while trying to load approval list',
							() => {
								if (lastParams) {
									clearSelectedRow();
									fetchApprovalList(lastParams, true);
								}
							}
						);
						return;
					}
				}
				throw e;
			} finally {
				setIsSearch(false);
				withLoader && hideLoading();
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const { ...rest } = values;

		const params = {
			sortBy,
			sort,
			page,
			limit: pageSize,
			...rest,
			...values,
		};
		await doRequest(params);
	};

	const initialFetch = async () => {
		if (!hasApprovalListPermission) {
			dispatch(showAccessDeniedModal());
			return;
		}

		try {
			showLoadingMessage('Please wait while approval list is being loaded');
			await fetchApprovalList({}, false, false);
		} catch (e: any) {
			if (e.response.status === 403) {
				dispatch(showAccessDeniedModal());
			} else {
				showErrorMessage(
					'Timeout Error!',
					'An error occurred while trying to load approval list'
				);
			}
		} finally {
			hideLoading();
		}
	};

	const onDisableConfirmHandler = () => {
		const remarks = batchApprovalRemarksField.current?.value?.trim();
		if (!remarks || remarks === '') {
			setIsConfirmEnabled(true);
			return;
		}
		setIsConfirmEnabled(false);
	};

	const batchApprove = async (ids: string[]) => {
		const remarks = batchApprovalRemarksField.current?.value?.trim();

		showLoadingMessage(
			'Please wait while we mark the selected requests as approved'
		);

		const params = {
			product_ids: ids,
			remarks: remarks,
		};
		await HTTP.post(
			`/v2/products/${resolveValue(product_type_id)}/approval/batch-approve`,
			params
		)
			.then(() => {
				showSuccessMessage('Partner requests are approved.', () => {
					if (lastParams) {
						clearSelectedRow();
						fetchApprovalList(lastParams, true);
					}
					hideSuccessModal();
				});
				hideErrorModal();
			})
			.catch((e: any) => {
				showErrorMessage(
					'Timeout Error!',
					'A problem occurred while approving partner requests.',
					null,
					() => {
						batchApprove(ids);
					}
				);
			})
			.finally(() => {
				hideBatchApprovalModal();
				hideLoading();
			});
	};

	const onDisableConfirm = () => {
		const remarks = batchRejectionRemarksField.current?.value?.trim();
		if (!remarks || remarks === '') {
			setIsConfirmReject(true);
			return;
		}
		setIsConfirmReject(false);
	};

	const batchReject = async (ids: string[]) => {
		const remarks = batchRejectionRemarksField.current?.value?.trim();

		showLoadingMessage(
			'Please wait while we mark the selected requests as rejected'
		);

		const params = {
			product_ids: ids,
			remarks: remarks,
		};
		await HTTP.post(
			`/v2/products/${resolveValue(product_type_id)}/approval/batch-reject`,
			params
		)
			.then(() => {
				showSuccessMessage('Partner requests are rejected.', () => {
					if (lastParams) {
						clearSelectedRow();
						fetchApprovalList(lastParams, true);
					}
					hideSuccessModal();
				});
				hideErrorModal();
			})
			.catch((e: any) => {
				showErrorMessage(
					'Timeout Error!',
					'A problem occurred while rejecting partner requests.',
					null,
					() => {
						batchReject(ids);
					}
				);
			})
			.finally(() => {
				hideBatchRejectionModal();
				hideLoading();
			});
	};

	const approve = async (id: string) => {
		showLoadingMessage('Please wait while we mark the request as approved');

		if (approvalRemarksField.current?.value) {
			remarks = approvalRemarksField.current?.value;
		}

		const params = { remarks: remarks };
		await HTTP.post(
			`/v2/products/${resolveValue(product_type_id)}/${resolveValue(
				id
			)}/approve`,
			params
		)
			.then(() => {
				showSuccessMessage(
					<>
						{currentPartnerName} status is now{' '}
						<b className={styles.boldText}>Approved.</b>
					</>,
					() => {
						if (lastParams) {
							clearSelectedRow();
							fetchApprovalList(lastParams, true);
						}
						hideSuccessModal();
					}
				);
				hideErrorModal();
			})
			.catch((e: any) => {
				showErrorMessage(
					'Timeout Error!',
					<>
						A problem occurred while approving{' '}
						<b className={styles.boldText}>{currentPartnerName}</b>.
					</>,
					null,
					() => {
						approve(id);
					}
				);
			})
			.finally(() => {
				hideApprovalModal();
				hideLoading();
			});
	};

	const reject = async (id: string) => {
		showLoadingMessage('Please wait while we mark the request as rejected');
		if (rejectionRemarksField.current?.value) {
			remarks = rejectionRemarksField.current?.value;
		}
		const params = { remarks: remarks };

		await HTTP.post(
			`/v2/products/${resolveValue(product_type_id)}/${resolveValue(
				id
			)}/reject`,
			params
		)
			.then(() => {
				showSuccessMessage(
					<>
						{currentPartnerName} status is now{' '}
						<b className={styles.boldText}>Rejected.</b>
					</>,
					() => {
						if (lastParams) {
							clearSelectedRow();
							fetchApprovalList(lastParams, true);
						}
						hideSuccessModal();
					}
				);
				hideErrorModal();
			})
			.catch((e: any) => {
				showErrorMessage(
					'Timeout Error!',
					<>
						A problem occurred while rejecting{' '}
						<b className={styles.boldText}>{currentPartnerName}</b>.
					</>,
					null,
					() => {
						reject(id);
					}
				);
			})
			.finally(() => {
				hideRejectionModal();
				hideLoading();
			});
	};

	useEffect(() => {
		initialFetch();
	}, []);

	const handlePageChange = async (_page, _pageSize) => {
		const { page: _, pageSize: __, ...rest } = filterParams;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			setFilterParams((prevState) => {
				return { ...prevState, page: _page };
			});
			await fetchApprovalList({ page: _page, limit: _pageSize, ...rest });
		}
	};

	const handleSort = async (sortBy, sort) => {
		setSortBy(sortBy);
		setSort(sort);
		setPage(1);
		await fetchApprovalList({ sortBy, sort, page: 1, ...filterParams });
	};

	const handleFilterSubmit = async (values, _isSearch) => {
		const { createdAt, updatedAt, status, partnerName } = values;

		const params = {
			partnerName,
			createdAt,
			updatedAt,
			status,
			page: 1,
			pageSize,
		};

		setPage(1);
		setFilterParams(params);
		await fetchApprovalList(params);
		setIsSearch(_isSearch);
	};

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const showErrorMessage = (
		header: any,
		message: any,
		instruction?: any,
		onRetryBtnClick?: () => void
	) => {
		setErrorHeader(header);
		setErrorMessage(message);
		setErrorInstruction(instruction || 'Please try again.');
		showErrorModal();
		setRetryBtnOnClick({
			action: () => {
				onRetryBtnClick && onRetryBtnClick();
			},
		});
	};

	const showSuccessMessage = (message: any, onDoneBtnClick?: () => void) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	return (
		<>
			<ApprovalListFilter
				initialValues={INITIAL_VALUES}
				onSubmit={handleFilterSubmit}
			/>
			<ApprovalListTable
				data={data}
				count={count}
				pageSize={pageSize}
				onPageChange={handlePageChange}
				onSort={handleSort}
				sort={sort}
				sortBy={sortBy}
				page={page}
				isSearch={isSearch}
				columns={APPROVAL_COLUMNS(onCheckAll, onCheckIndividual, checkAll)}
				preHeader={
					prevData.filter((row) => row.checked).length > 0 && (
						<>
							<div className={styles.leftAlign}>
								<label className={styles.preHeaderLabel}>
									{prevData.filter((row) => row.checked).length}
									{prevData.filter((row) => row.checked).length === 1
										? ' request'
										: ' requests'}{' '}
									selected
								</label>
							</div>
							{hasApprovePermission && (
								<Button
									className={styles.btnApprove}
									onClick={(e) => {
										const rows = prevData.filter((row) => row.checked);
										setCurrentPartnerIds(rows.map((i) => i.id));
										setCurrentNoSelectedPartners(rows.length);
										showBatchApprovalModal();
									}}
								>
									<ApproveIcon className={styles.btnApproveIcon} /> Approve
								</Button>
							)}
							{hasRejectPermission && (
								<Button
									className={styles.btnReject}
									onClick={(e) => {
										const rows = prevData.filter((row) => row.checked);
										setCurrentPartnerIds(rows.map((i) => i.id));
										setCurrentNoSelectedPartners(rows.length);
										showBatchRejectionModal();
									}}
								>
									<RejectIcon className={styles.btnRejectIcon} /> Reject
								</Button>
							)}
						</>
					)
				}
			/>
			{isLoading && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}
			{isSuccessModalShown && (
				<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorBodyContent}>
							<div className={styles.errorHeader}>{errorHeader}</div>
							<div className={styles.errorBody}>{errorMessage}</div>
							<div className={styles.errorFooter}>{errorInstruction}</div>
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								errorHeader == maxPartnerHeaderSpiel
									? hideErrorModal()
									: retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							{errorHeader == maxPartnerHeaderSpiel ? 'Okay' : 'Retry'}
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{isPartnerModalShown && (
				<PartnerModal
					open={isPartnerModalShown}
					onClose={() => {
						hidePartnerModal();
						fetchApprovalList(filterParams, false);
					}}
					data={approvalData}
					disabled={true}
					action={'APPROVAL'}
				/>
			)}
			{isApprovalModalShown && (
				<Modal
					isOpen={isApprovalModalShown}
					onRequestClose={onRequestCloseApproval}
					disable={isLoading}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
					heading={<h2 className={styles.approveHeading}>Approve Partner</h2>}
					footer={
						<div className={styles.approvalModalFooter}>
							<OutlineButton
								onClick={() => {
									hideApprovalModal();
								}}
								className={styles.approvalModalBtn}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								onClick={() => {
									approve(currentPartnerId);
								}}
								className={styles.approvalModalBtn}
								disabled={isApprovalRemarksEmpty}
							>
								Confirm
							</PrimaryButton>
						</div>
					}
				>
					<div className={styles.bodyHeader}>
						<span>
							Are you sure you want to approve <b>{currentPartnerName}</b>?
							<br />
							If yes, please input remarks.
						</span>
					</div>
					<div className={styles.bodyContent}>
						<div className="slds-form-element">
							<label className="slds-form-element__label">
								Remarks
								<abbr className="slds-required" title="required">
									*
								</abbr>
							</label>
							<div className="slds-form-element__control">
								<textarea
									className={'slds-textarea' + ' ' + styles.remarks}
									maxLength={255}
									onInput={restrictSpecialChars}
									ref={approvalRemarksField}
									onChange={handleChangeOfApprovalRemarks}
								></textarea>
							</div>
						</div>
						<em className={styles.remarksSubLabel}>
							*Maximum of 255 characters only.
						</em>
					</div>
				</Modal>
			)}
			{isRejectionModalShown && (
				<Modal
					isOpen={isRejectionModalShown}
					onRequestClose={onRequestCloseRejection}
					disable={isLoading}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
					heading={<h2 className={styles.customHeading}>Reject Partner</h2>}
					footer={
						<div className={styles.rejectionModalFooter}>
							<OutlineButton
								onClick={() => {
									hideRejectionModal();
								}}
								className={styles.rejectionModalBtn}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								onClick={() => {
									reject(currentPartnerId);
								}}
								className={styles.rejectionModalBtn}
								disabled={isRejectionRemarksEmpty}
							>
								Confirm
							</PrimaryButton>
						</div>
					}
				>
					<div className={styles.bodyHeader}>
						Are you sure you want to reject{' '}
						<strong>{currentPartnerName}</strong>?
						<br />
						If yes, please input remarks.
					</div>
					<div className={styles.bodyContent}>
						<div className="slds-form-element">
							<label className="slds-form-element__label">
								Remarks
								<abbr className="slds-required" title="required">
									*
								</abbr>
							</label>
							<div className="slds-form-element__control">
								<textarea
									className={'slds-textarea' + ' ' + styles.remarks}
									maxLength={255}
									onInput={restrictSpecialChars}
									ref={rejectionRemarksField}
									onChange={handleChangeOfRejectionRemarks}
								></textarea>
							</div>
						</div>
						<em className={styles.remarksSubLabel}>
							*Maximum of 255 characters only.
						</em>
					</div>
				</Modal>
			)}
			{isBatchApprovalModalShown && (
				<Modal
					className={styles.approvalModal}
					isOpen={isBatchApprovalModalShown}
					onRequestClose={onRequestCloseBatchApproval}
					disable={isLoading}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
					heading="Batch Approve"
					footer={
						<div className={styles.approvalModalFooter}>
							<OutlineButton
								onClick={() => {
									onRequestCloseBatchApproval();
								}}
								className={styles.approvalModalBtn}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								disabled={isConfirmEnabled}
								onClick={() => {
									batchApprove(currentPartnerIds);
								}}
								className={styles.approvalModalBtn}
							>
								Confirm
							</PrimaryButton>
						</div>
					}
				>
					<div className={styles.bodyHeader}>
						Are you sure you want to approve the selected
						<b> ( {currentNoSelectedPartners} ) </b> requests?
					</div>
					<div className={styles.bodyContent}>
						<div className="slds-form-element">
							<label className="slds-form-element__label">
								<abbr className="slds-required" title="required">
									*
								</abbr>
								Remarks
							</label>
							<div className="slds-form-element__control">
								<textarea
									className={'slds-textarea' + ' ' + styles.remarks}
									maxLength={255}
									ref={batchApprovalRemarksField}
									onInput={restrictSpecialChars}
									onChange={onDisableConfirmHandler}
								></textarea>
							</div>
						</div>
						<em className={styles.remarksSubLabel}>
							*Maximum of 255 characters only.
						</em>
					</div>
				</Modal>
			)}
			{isBatchRejectionModalShown && (
				<Modal
					className={styles.rejectionModal}
					isOpen={isBatchRejectionModalShown}
					onRequestClose={onRequestCloseBatchRejection}
					disable={isLoading}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
					heading="Batch Reject"
					footer={
						<div className={styles.rejectionModalFooter}>
							<OutlineButton
								onClick={() => {
									onRequestCloseBatchRejection();
								}}
								className={styles.rejectionModalBtn}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								disabled={isConfirmReject}
								onClick={() => {
									batchReject(currentPartnerIds);
								}}
								className={styles.rejectionModalBtn}
							>
								Confirm
							</PrimaryButton>
						</div>
					}
				>
					<div className={styles.bodyHeader}>
						Are you sure you want to reject the selected
						<b> ( {currentNoSelectedPartners} )</b> requests?
					</div>
					<div className={styles.bodyContent}>
						<div className="slds-form-element">
							<label className="slds-form-element__label">
								Remarks
								<abbr className="slds-required" title="required">
									*
								</abbr>
							</label>
							<div className="slds-form-element__control">
								<textarea
									className={'slds-textarea' + ' ' + styles.remarks}
									maxLength={255}
									ref={batchRejectionRemarksField}
									onChange={onDisableConfirm}
									onInput={restrictSpecialChars}
								></textarea>
							</div>
						</div>
						<em className={styles.remarksSubLabel}>
							*Maximum of 255 characters only.
						</em>
					</div>
				</Modal>
			)}
		</>
	);
};

export default connect((state: any) => ({
	product_type_id: state.sidebar.itemId,
	username: state.userInfo.username,
}))(Approval);
