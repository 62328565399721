import Divider from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/Divider/Divider';
import SFTP from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SFTP/SFTP';
import SMTP from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/SMTP/SMTP';
import { ReportsTabProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/types';

const ReportsTab: React.FC<ReportsTabProps> = ({ disabled }) => {
	return (
		<>
			<SFTP disabled={disabled} />
			<Divider />
			<SMTP disabled={disabled} />
		</>
	);
};

export default ReportsTab;
