import CsrColumnCheckbox from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/CsrColumnCheckbox';
import { CsrColumn } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/CsrColumnCheckbox/types';
import { ControlledCsrColumnCheckboxProps } from 'containers/ChannelManagement/Channel/ChannelForm/Tabs/Reports/FtpContainer/CsrColumnTemplate/types';
import { FieldValues, useController } from 'react-hook-form';
import { FixMeLater } from 'types';

export function CsrColumnTemplate<T extends FieldValues>({
	control,
	name,
	onChange,
	...rest
}: ControlledCsrColumnCheckboxProps<T>): FixMeLater {
	const {
		field: { value, onChange: hookOnChange },
	} = useController({ control, name });

	function handleChange(entries: CsrColumn[]) {
		hookOnChange(entries);
		onChange?.(entries);
	}

	return (
		<CsrColumnCheckbox
			value={value as CsrColumn[]}
			onChange={handleChange}
			control={control}
			name={name}
			{...rest}
		/>
	);
}
