import Grid from 'components/Grid/Grid';
import Section, { SectionRow } from 'components/Section/Section';
import {
	Control,
	UseFormSetValue,
	UseFormClearErrors,
	UseFormSetError,
	useController,
} from 'react-hook-form';
import { ReactComponent as CashIcon } from 'assets/icons/ic-cash.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/ic-check.svg';
import { ReactComponent as CashCheckIcon } from 'assets/icons/ic-cashcheck.svg';
import { ReactComponent as CreditCardIcon } from 'assets/icons/ic-creditcard.svg';
import { connect, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import {
	Checkbox as TCheckbox,
	SubCheckboxProps,
} from './PaymentModeCheckbox/Checkbox';
import useMultipleSelect from './PaymentModeCheckbox/useMultiSelect';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import styles from './PaymentMode.module.css';
import { useEffect, useMemo } from 'react';
import cx from 'classnames';

type Props = {
	control: Control<any>;
	disabled?: boolean;
	setValue: UseFormSetValue<any>;
	clearErrors: UseFormClearErrors<any>;
	setError: UseFormSetError<any>;
	product_type_id: any;
	products: any;
	trigger?: any;
};

const PAYMENT_MODES = ['CASH', 'CHECK', 'CASH_CHECK', 'CREDIT'];
const POS_TYPES = ['PARTNER_POS', 'BAYAD_POS'];

type ControlledSubCheckboxProps = SubCheckboxProps &
	Pick<Props, 'control'> & { name: string };

const ControlledSubCheckbox: React.FC<ControlledSubCheckboxProps> = ({
	control,
	name,
	...rest
}) => {
	const {
		field: { onChange, value = 0 },
	} = useController({ name, control });

	return (
		<TCheckbox.SubCheckbox
			onClick={() => onChange(value == 0 ? 1 : 0)}
			{...rest}
			checked={value == 1}
		/>
	);
};

const PosCheckbox: React.FC<
	Pick<Props, 'control'> & { name: string; disabled?: boolean, isCreditSelected: boolean, setValue }
> = ({ control, name, disabled, isCreditSelected, setValue }) => {
	const {
		field: { onChange, value = [] },
		formState: { defaultValues },
		fieldState: { error, isDirty },
	} = useController({ name: `${name}.pos_type`, control });
	const _initValues = _get(defaultValues ?? {}, `${name}.pos_type`, []);
	const { toggle } = useMultipleSelect(POS_TYPES, (e) => e, {
		onChange: (v: string | string[]) => {
			const arr = Array.isArray(v) ? v : v?.split(',');
			onChange(arr.filter(Boolean).join(','));

		},
		initialValue: _initValues
			? Array.isArray(_initValues)
				? _initValues
				: _initValues?.split(',')
			: [],
	});
	const isSelected = (v: string) => {
		if (!value) return false;
		const arr = Array.isArray(value) ? value : value?.split(',');
		return arr.includes(v);
	};
	useEffect(() => {
		if (!isCreditSelected) {
			isSelected('BAYAD_POS') ? toggle('BAYAD_POS') : null;
			isSelected('PARTNER_POS') ? toggle('PARTNER_POS') : null;
		}
	}, [isCreditSelected])

	return (
		<>
			<TCheckbox.SubCheckbox
				checked={isSelected('BAYAD_POS')}
				onClick={() => toggle('BAYAD_POS')}
				label="Bayad POS"
				disabled={disabled}
			/>
			<TCheckbox.SubCheckbox
				checked={isSelected('PARTNER_POS')}
				onClick={() => toggle('PARTNER_POS')}
				label="Partner POS"
				disabled={disabled}
			/>
			{!(value?.length > 0) && isCreditSelected &&
				<div className="slds-has-error">
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						<em>*Please select POS type</em>
					</div>
				</div>
			}
		</>
	);
};

const PaymentModeCheckboxGroup = ({
	control,
	name,
	disabled,
	setValue,
}: Pick<Props, 'control' | 'setValue'> & {
	name: string;
	disabled?: boolean;
}) => {
	const paymentModeName = `${name}.payment_mode`;
	const {
		field: { onChange, value: paymentMode },
		formState: { defaultValues, errors },
	} = useController({ control, name: paymentModeName });
	// const {
	// 	fieldState: { error },
	// } = useController({ control, name: `${name}.pos_type` });
	const _initValues = _get(defaultValues ?? {}, paymentModeName, []);
	const {
		isSelected: isSelectFromUseMultiple,
		selectedEntries,
		allSelected: allMainCheckboxSelected,
		toggle,
		toggleAll,
	} = useMultipleSelect(PAYMENT_MODES, (e) => e, {
		onChange,
		initialValue: _initValues
			? Array.isArray(_initValues)
				? _initValues
				: _initValues?.split(',')
			: [],
	});

	const productTypeId = useSelector<ReducerStateType>(
		(state) => state.sidebar.itemId
	);

	const disabledMultipleBills = productTypeId !== 1;

	const makeClickHandler = (value: string) => () => {
		toggle(value);
	};

	// const cashMultipleBills = useWatch({
	// 	control,
	// 	name: `${name}.cash_multiple_bills`,
	// });
	// const checkMultipleBills = useWatch({
	// 	control,
	// 	name: `${name}.check_multiple_bills`,
	// });
	// const cashCheckMultipleBills = useWatch({
	// 	control,
	// 	name: `${name}.cash_check_multiple_bills`,
	// });
	// const creditMultipleBills = useWatch({
	// 	control,
	// 	name: `${name}.credit_multiple_bills`,
	// });
	// const posType = useWatch({ control, name: `${name}.pos_type` });

	// //not used anymore, this line just caused the EV-5675 issue
	// const allSubcheckboxesSelected = useMemo(() => {
	// 	const allPosTypeSelected = POS_TYPES.every((p) => posType?.includes(p));
	// 	const allMultipleBillsChecked = [
	// 		cashMultipleBills,
	// 		checkMultipleBills,
	// 		cashCheckMultipleBills,
	// 		creditMultipleBills,
	// 	].every((c) => c == 1);
	// 	return allMultipleBillsChecked && allPosTypeSelected;
	// }, [
	// 	cashMultipleBills,
	// 	checkMultipleBills,
	// 	cashCheckMultipleBills,
	// 	creditMultipleBills,
	// 	posType,
	// 	paymentMode,
	// ]);

	const handleSelectAllClick = () => {
		toggleAll(!allSelected);
	};

	const isSelected = (v: string) => {
		if (!paymentMode) return false;
		const valueSet = new Set(
			Array.isArray(paymentMode) ? paymentMode : paymentMode.split(',')
		);
		return valueSet.has(v);
	};

	// reset all subcheckboxes when main checkbox is unchecked
	useEffect(() => {
		selectedEntries;
		if (!isSelectFromUseMultiple('CASH')) {
			setValue(`${name}.cash_multiple_bills`, 0, { shouldDirty: true });
		}
		if (!isSelectFromUseMultiple('CHECK')) {
			setValue(`${name}.check_multiple_bills`, 0, { shouldDirty: true });
		}
		if (!isSelectFromUseMultiple('CASH_CHECK')) {
			setValue(`${name}.cash_check_multiple_bills`, 0, { shouldDirty: true });
		}
		if (!isSelectFromUseMultiple('CREDIT')) {
			setValue(`${name}.credit_multiple_bills`, 0, { shouldDirty: true });
		}
	}, [selectedEntries]);

	const allSelected = useMemo(() => {
		return PAYMENT_MODES.every((p) => paymentMode?.includes(p));
	}, [toggleAll, handleSelectAllClick, isSelected, paymentMode]);
	return (
		<div>
			{!disabled && (
				<TCheckbox.SubCheckbox
					checked={allSelected}
					onClick={handleSelectAllClick}
					label="Select All Payment Mode"
					className={styles.selectAll}
				/>
			)}
			<TCheckbox.Group>
				<TCheckbox
					onClick={makeClickHandler('CASH')}
					icon={<CashIcon width="35" height="35" />}
					label="Cash"
					checked={isSelected('CASH')}
					disabled={disabled}
				>
					<ControlledSubCheckbox
						control={control}
						name={`${name}.cash_multiple_bills`}
						label="Accepts Multiple Bills"
						disabled={disabledMultipleBills || !isSelected('CASH')}
					/>
				</TCheckbox>
				<TCheckbox
					onClick={makeClickHandler('CHECK')}
					checked={isSelected('CHECK')}
					icon={<CheckIcon width="35" height="35" />}
					label="Check"
					disabled={disabled}
				>
					<ControlledSubCheckbox
						control={control}
						name={`${name}.check_multiple_bills`}
						label="Accepts Multiple Bills"
						disabled={disabledMultipleBills || !isSelected('CHECK')}
					/>
				</TCheckbox>
				<TCheckbox
					icon={<CashCheckIcon width="35" height="35" />}
					label="Cash + Check"
					onClick={makeClickHandler('CASH_CHECK')}
					checked={isSelected('CASH_CHECK')}
					disabled={disabled}
				>
					<ControlledSubCheckbox
						control={control}
						name={`${name}.cash_check_multiple_bills`}
						label="Accepts Multiple Bills"
						disabled={disabledMultipleBills || !isSelected('CASH_CHECK')}
					/>
				</TCheckbox>
				<TCheckbox
					icon={<CreditCardIcon width="35" height="35" />}
					label="Credit Card"
					onClick={makeClickHandler('CREDIT')}
					checked={isSelected('CREDIT')}
					disabled={disabled}
				>
					<PosCheckbox
						control={control}
						name={name}
						disabled={!isSelected('CREDIT')}
						isCreditSelected={isSelected('CREDIT')}
						setValue={setValue}
					/>
					<ControlledSubCheckbox
						control={control}
						name={`${name}.credit_multiple_bills`}
						label="Accepts Multiple Bills"
						disabled={disabledMultipleBills || !isSelected('CREDIT')}
					/>

				</TCheckbox>
			</TCheckbox.Group>
		</div>
	);
};

const PaymentMode: React.FC<Props> = ({ control, disabled, setValue }) => {
	const name = 'product_business_rule';
	const {
		fieldState: { error },
	} = useController({ control, name: `${name}.payment_mode` });
	return (
		<Section title="Payment Mode">
			<SectionRow>
				<Grid column size={1} of={1}>
					<PaymentModeCheckboxGroup
						disabled={disabled}
						control={control}
						name={name}
						setValue={setValue}
					/>
				</Grid>
			</SectionRow>
			{error && (
				<div className="slds-has-error">
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						{error?.message}
					</div>
				</div>
			)}
		</Section>
	);
};

export default connect((state: any) => ({
	product_type_id: state.sidebar.itemId,
	products: state.sidebar.products,
}))(PaymentMode);
