
import cx from 'classnames';
import Table from "components/CWSTable/Table";
import Checkbox from 'components/Checkbox/Checkbox';
import FullPageLoader from "components/Loader/FullPageLoader/FullPageLoader";
import ChannelModal from "containers/ChannelManagement/Channel/ChannelModal/ChannelModal";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useToggle } from "utils/hooks";
import Actions from "../../Actions";
import ApprovalTabFilter, { getUniqueValues } from "../../ApprovalTabFilter";
import { ApproveErrorModal, ApproveRejectModal, ApproveSuccessModal, PreHeader } from "../../ChannelApprovalModals";
import { UseApproveRejectChannel, UseGetChannelContract, UseGetChannelPrimaryInfo, UseGetChannelReports, useGetChannelApprovalList } from "../../ChannelApprovalQuery";
import { APPROVAL_LIST_FILTER, LOADING_MESSAGE, TABLE_COLUMNS, TABLE_FILTER } from "../../constants";
import styles from '../../styles.module.css';
import { ChannelFilter, ChannelTableRowData, ErrorMessage, OptionValues } from "../../types";
import { useDispatch, useSelector } from 'react-redux';
import { resetChannelFormValues, setChannelFormValues } from 'redux/modules/channelList';

type Props = {
    name: string,
    selectedIndex: number
}
const ChannelApprovalTab: React.FC<Props> = ({ name, selectedIndex }) => {
    const dispatch = useDispatch()
    const { watch, reset, control, getValues } = useFormContext<ChannelFilter>();

    const [tableFilter, setTableFilter] = useState({ ...TABLE_FILTER });
    const [approvalListParam, setApprovalListParam] = useState<typeof APPROVAL_LIST_FILTER | typeof TABLE_FILTER>({ ...APPROVAL_LIST_FILTER, ...TABLE_FILTER });
    const [errorMessage, setErrorMessage] = useState<ErrorMessage>({ header: '', message: '', type: '', isCommonError: true });
    const [successMessage, setSuccessMessage] = useState<any>('');
    const [approvalRemarks, setApprovalRemarks] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<ChannelTableRowData[]>([]);
    const [isAllRowsSelected, setAllRowsSelected] = useState<any>(false);
    const [tableData, setTableData] = useState<ChannelTableRowData[]>([]);
    const [queryData, setQueryData] = useState<any>();
    const [optionValues, setOptionValues] = useState<OptionValues>({})
    const {
        value: isLoading,
        valueOn: showLoading,
        valueOff: hideLoading,
    } = useToggle();
    const {
        value: isChannelDetailsOpen,
        valueOn: showChannelDetailsModal,
        valueOff: hideChannelDetailsModal,
    } = useToggle();
    const {
        value: isApprove,
        valueOn: showApprove,
        valueOff: hideApprove,
    } = useToggle();
    const {
        value: isReject,
        valueOn: showReject,
        valueOff: hideReject,
    } = useToggle();
    const {
        value: isError,
        valueOn: showIsError,
        valueOff: hideIsError,
    } = useToggle();
    const {
        value: isSuccess,
        valueOn: showIsSuccess,
        valueOff: hideIsSuccess,
    } = useToggle();
    const {
        value: isBatchApprove,
        valueOn: showBatchApprove,
        valueOff: hideBatchApprove,
    } = useToggle()
    const {
        value: isBatchReject,
        valueOn: showBatchReject,
        valueOff: hideBatchReject,
    } = useToggle()
    const approvalListQuery = useGetChannelApprovalList(approvalListParam);
    const filterDebounce = useCallback(
        debounce(() => {
            const { channelTabFilter } = getValues()
            setApprovalListParam(prevValues => ({ ...prevValues, ...channelTabFilter, ...tableFilter }))
            approvalListQuery.refetch()
        }, 500), [tableFilter, approvalListParam, selectedIndex]);
    useEffect(() => {
        reset();
    }, [selectedIndex])
    useEffect(() => {
        if (selectedIndex == 0) { filterDebounce() } else return;
    }, [watch('channelTabFilter.search'), tableFilter, selectedIndex]);

    useEffect(() => {
        if (selectedIndex == 0) {
            if (approvalListQuery.isSuccess) {
                setQueryData(approvalListQuery.data)
                setTableData(prev => (approvalListQuery.data.data.map(element => ({ ...element, checked: false }))))
                setOptionValues({
                    optionsSubmittedBy: getUniqueValues(approvalListQuery.data.data, 'submittedBy'),
                    optionsChannelAccount: getUniqueValues(approvalListQuery.data.data, 'name')
                })
                hideLoading()
            }
            if (approvalListQuery.isFetching) {
                setQueryData({})
                setTableData([])
                showLoading()
            }
            if (approvalListQuery.isError) {
                showIsError()
                setErrorMessage({ header: "Network Error", message: '', isCommonError: true })
                hideLoading();
            }
        }
    }, [approvalListQuery.isLoading, approvalListQuery.isSuccess, approvalListQuery.isError, approvalListQuery.isFetching])

    const handleAction = async (action: string, value: ChannelTableRowData) => {
        if (action == 'details') {
            dispatch(resetChannelFormValues())
            showLoading();
            try {
                const [primaryInfo, contractDetails, reports] = await Promise.all([
                    UseGetChannelPrimaryInfo(value.id),
                    UseGetChannelContract(value.id),
                    UseGetChannelReports(value.id)
                ])
                dispatch(setChannelFormValues({
                    primaryInfo: primaryInfo.data,
                    contractDetails: contractDetails.data,
                    reports: reports.data
                }))
                showChannelDetailsModal();
                hideLoading();
            } catch (err) { console.log(err) }
        }
        if (action == 'approve') {
            setSelectedRows([{ ...value, checked: true }])
            showApprove();
        }
        if (action == 'reject') {
            setSelectedRows([{ ...value, checked: true }])
            showReject();
        }

    }

    const approve = async (remarks: string, isRetry: boolean, isApprove: boolean) => {
        setApprovalRemarks(remarks)
        setSelectedRows(tableData.filter(element => element.checked))
        const idList = selectedRows.map(element => { return element.id })
        if (selectedRows.length > 0) {
            showLoading()
            await UseApproveRejectChannel(idList, remarks, isApprove)
                .then(() => {
                    hideLoading()
                    if (selectedRows.length > 1) {
                        setSuccessMessage(
                            <>Partner requests are {isApprove ? 'Approved' : 'Rejected'}.</>
                        )
                        showIsSuccess();
                        isApprove ? hideBatchApprove() : hideBatchReject()
                        approvalListQuery.refetch()
                    }
                    if (selectedRows.length == 1) {
                        setSuccessMessage(
                            <>{selectedRows[0].name} is now <strong>{isApprove ? 'Approved' : 'Rejected'}</strong></>
                        )
                        showIsSuccess();
                        isApprove ? hideApprove() : hideReject()
                        approvalListQuery.refetch()
                    }
                })
                .catch((_response) => {
                    hideLoading();
                    setErrorMessage({ header: 'Something went wrong.', message: '', type: isApprove ? 'Approved' : 'Rejected', isCommonError: false });
                    isRetry ? null : showIsError();
                })
        }
        return
    }
    const retry = (param) => {
        approve(approvalRemarks, true, (param == 'approve'))
    }
    const updatedColumn = TABLE_COLUMNS.map(element => {
        if (element.id === 'action') {
            return {
                ...element,
                Header: () => (
                    <div className={cx(styles.centerAlign)}>
                        Action
                    </div>
                ),
                accessor: (values: any) => values,
                Cell: ({ value }) => {
                    return <Actions value={value} action={handleAction} />
                }

            }
        }
        if (element.id === 'status') {
            return {
                ...element,
                accessor: ({ status }) => status.toLowerCase()
                    .split("_")
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
                Cell: ({ value }) => {
                    return <div className={styles[value.replaceAll(' ', '')]}>{value}</div>;
                },
            }
        }
        if (element.id === 'checked') {
            return {
                ...element,
                Header: () => {
                    return (
                        <div>
                            <Checkbox
                                label=''
                                type='checkbox'
                                onChange={(v) => {
                                    const updateData = tableData.map((element, index) => {
                                        if (element.status == 'FOR_REVIEW' && index < 10) {
                                            return { ...element, checked: v }
                                        }
                                        return { ...element, checked: false }
                                    })
                                    setTableData(updateData)
                                    setAllRowsSelected(v)
                                }}
                                checked={isAllRowsSelected}
                            />
                        </div>
                    )
                },
                accessor: (values: any) => values,
                Cell: ({ value }) => {
                    if (value.status == 'FOR_REVIEW') {
                        return (
                            <div>
                                <Checkbox
                                    label=""
                                    type="checkbox"
                                    onChange={(v) => {
                                        if (tableData.filter(element => element.checked).length < 10) {
                                            const updateData = tableData.map((element) => {
                                                if (value.id == element.id) {
                                                    return { ...element, checked: v }
                                                }
                                                return element
                                            })
                                            setTableData(updateData)
                                        } else {
                                            showIsError()
                                            setErrorMessage({ header: '', message: 'Only max of 10 request can be processed at a time', isCommonError: true })
                                        }
                                    }}
                                    checked={value.checked}
                                />
                            </div>
                        )
                    } else return null
                }
            }
        }
        return element
    })

    return (
        <>
            <ApprovalTabFilter control={control} name={name} reset={reset} filter={filterDebounce} optionValues={optionValues} />
            <Table
                columns={updatedColumn}
                data={tableData}
                count={queryData?.total}
                pageSize={queryData?.pageSize}
                page={queryData?.page}
                sortBy={'createdAt'}
                onPageChange={(page, limit) => { setTableFilter(prev => ({ ...prev, page, limit })); }}
                onSort={(sortBy, sort) => { setTableFilter(prev => ({ ...prev, sortBy, sort: sort.toLowerCase() })); }}
                isNotEvenRowColor={false}
                modalIsOpen={false}
                preHeader={
                    <PreHeader selectedRows={
                        tableData.filter(element => element.checked)
                    }
                        showApprove={showBatchApprove}
                        showReject={showBatchReject}
                    />}
                withRowField={false}
            />
            {isLoading && (
                <FullPageLoader open={isLoading} message={LOADING_MESSAGE} />
            )}
            {isChannelDetailsOpen &&
                <ChannelModal onClose={hideChannelDetailsModal} open={isChannelDetailsOpen} />
            }
            {isApprove && (
                <ApproveRejectModal isOpen={isApprove} hideApprove={hideApprove} approveReject={approve} rowData={selectedRows} isApprove={true} />
            )}
            {isReject && (
                <ApproveRejectModal isOpen={isReject} hideApprove={hideReject} approveReject={approve} rowData={selectedRows} isApprove={false} />
            )}
            {isError && (
                <ApproveErrorModal errorMessage={errorMessage} hideErrorModal={hideIsError} isErrorModalShown={isError} retry={retry} />
            )}
            {isSuccess && (
                <ApproveSuccessModal hideSuccessModal={hideIsSuccess} isSuccessModalShown={isSuccess} successMessage={successMessage} />
            )}
            {isBatchApprove && (
                <ApproveRejectModal isOpen={isBatchApprove} hideApprove={hideBatchApprove} approveReject={approve} rowData={tableData.filter(element => element.checked)} isApprove={true} />
            )}
            {isBatchReject && (
                <ApproveRejectModal isOpen={isBatchReject} hideApprove={hideBatchReject} approveReject={approve} rowData={tableData.filter(element => element.checked)} isApprove={false} />
            )}
        </>
    )
}
export default ChannelApprovalTab