import SelectField from 'components/Inputs/SelectField/SelectField';
import { useMemo } from 'react';
import { useProvinceFilterQuery } from 'utils/queries/location';
import { LocationSelectProps } from '.';

const ProvinceSelectFilter: React.FC<LocationSelectProps> = ({
	control,
	name,
	label = 'State/Province',
	disabled,
	regionId,
	defaultValue,
	...rest
}) => {
	const { data, isLoading } = useProvinceFilterQuery(
		'provinces',
		{
			regionId,
		},
		{ enabled: !!regionId }
	);
	const options = useMemo(
		() =>
			data?.map(({ id: value, name: label }) => ({
				label,
				value,
			})) || [],

		[data]
	);

	return (
		<SelectField
			label={label}
			control={control}
			name={`${name}.province`}
			required
			isLoading={isLoading}
			defaultValue={defaultValue}
			options={options}
			{...rest}
			disabled={disabled}
		/>
	);
};

export default ProvinceSelectFilter;
