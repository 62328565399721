import Grid from 'components/Grid/Grid';
import CheckboxGroup from 'components/Inputs/CheckboxGroup/CheckboxGroup';
import Label from 'components/Inputs/Label/Label';
import TextField from 'components/Inputs/TextField/TextField';
import Section, { SectionRow } from 'components/Section/Section';
import { useEffect, useReducer } from 'react';
import {
	Control,
	UseFormSetValue,
	UseFormClearErrors,
	UseFormSetError,
	UseFormGetValues,
	useController,
} from 'react-hook-form';
import { useToggle } from 'utils/hooks';
import { product_business_rule } from 'utils/models/product_business_rule';
import styles from './AcceptedCheckTypes.module.css';

type Props = {
	control: Control<any>;
	disabled?: boolean;
	setValue: UseFormSetValue<any>;
	getValue: UseFormGetValues<any>;
	setError: UseFormSetError<any>;
	getValues: UseFormGetValues<any>;
};

const check_types = [
	{
		label: 'Local Clearing Check',
		value: 'LOCAL_CLEAR_CHECK',
	},
	{
		label: 'Regional Check',
		value: 'REGION_CHECK',
	},
	{
		label: 'Out-of-Town Check',
		value: 'OUT_OF_TOWN_CHECK',
	},
	{
		label: "Local Cashier's/Manager's Check",
		value: 'LOCAL_CASHIER_CHECK',
	},
	{
		label: 'Dividend Check',
		value: 'DIVIDEND_CHECK',
	},
	{
		label: 'Other Check Types',
		value: 'OTHER_CHECK',
	},
];

const AcceptedCheckTypes: React.FC<Props> = ({
	control,
	disabled,
	setValue,
	getValue,
	setError,
	getValues,
}) => {
	const name = 'product_business_rule';
	const {
		value: isOtherCheckTypesVisible,
		valueOn: showOtherCheckTypes,
		valueOff: hideOtherCheckTypes,
	} = useToggle();

	return (
		<Section
			title={
				<Label required>
					<span className={styles.sectionTitle}>Accepted Check Types</span>
				</Label>
			}
		>
			<SectionRow>
				<Grid column size={1} of={1}>
					<CheckboxGroup
						showLabel={false}
						parentName={name}
						name={`${name}.accepted_check_types`}
						label="Check Types"
						control={control}
						options={check_types}
						displayAll={true}
						fitHorizontally={true}
						disabled={disabled}
						setValue={setValue}
						onChange={(val) => {
							if (Array.isArray(val) && val.includes('OTHER_CHECK')) {
								showOtherCheckTypes();
							} else {
								hideOtherCheckTypes();
								setValue(`${name}.other_check_types`, '');
							}
						}}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}></Grid>
				<Grid column size={1} of={3}></Grid>
				<Grid column size={1} of={3}>
					{(!!getValues(`${name}.other_check_types`) ||
						isOtherCheckTypesVisible) && (
						<TextField
							label=""
							name={`${name}.other_check_types`}
							control={control}
							placeholder="Other Check Types"
							disabled={disabled}
						/>
					)}
				</Grid>
			</SectionRow>
		</Section>
	);
};

export default AcceptedCheckTypes;
