import {
	Button,
	Modal,
	Textarea,
} from '@salesforce/design-system-react/module/components';
import React, {
	ReactNode,
	useEffect,
	useMemo,
	useRef,
	useState,
	useCallback,
} from 'react';
import { TabStatus } from '../PartnerForm/PartnerForm';
import PrimaryButton from '../Buttons/PrimaryButton';
import OutlineButton from '../Buttons/OutlineButton';
import partnerModalStyles from './PartnerModal.module.css';
import successModalStyles from '../Modal/SuccessModal.module.css';
import errorModalStyles from '../Modal/ErrorModal.module.css';
import HTTP from 'helpers/ApiClient';
import { useDispatch, useSelector, connect } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { PrimaryInformationFormData } from 'components/PartnerForm/Tabs/PrimaryInformation/PrimaryInformation';
import { FixMeLater, TPartnerModalConfirmProps } from 'types';
import { useToggle } from 'utils/hooks';
import {
	setCurrentBillerTab,
	setLastActionButton,
	setSubmittingAll,
	setDrafting,
	setIsSampleValues,
	clearFetchedForms,
} from 'redux/modules/products';
import { ReactComponent as SaveDraftIcon } from 'assets/icons/ic-save.svg';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import { Country } from 'utils/queries/location';
import PartnerForm from 'components/PartnerForm/PartnerForm';
import { product_address } from 'utils/models/product_address';
import { partnerModalTabs } from 'constants/partner_modal_tabs';
import { ReactComponent as ApproveIcon } from 'assets/icons/ic-approve.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/ic-reject.svg';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import {
	DATE_FORMAT_ISO_8601,
	formatDate,
	formatObjectKeys,
	replaceDeepKeys,
	replaceNoValuesObj,
	resolveValue,
	restrictSpecialChars,
} from 'utils/common';
import PartnerStatusSelect from 'components/PartnerStatusSelect/PartnerStatusSelect';
import cx from 'classnames';
import { mapServiceFeeSettings } from 'components/PartnerForm/Tabs/ServiceFee/ServiceFeeSettings';
import client from 'helpers/ApiClient';
import { sampleProductRequestPayload } from 'constants/samplePayloads';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { showAccessDeniedModal } from 'redux/modules/access';
import { useHasUserPermission } from 'utils/permissions';
import { debounce } from 'lodash';
import { convertDate } from 'utils/date';

const styles = {
	...partnerModalStyles,
	...successModalStyles,
	...errorModalStyles,
};

type Props = {
	disabled?: boolean;
	action?: string;
	open?: boolean;
	isSaveDraftHovered?: boolean;
	onClose?: () => void;
	data: any;
	product_type_id: string | number;
	products: Array<any>;
	setLastActionButton: (s: any) => void;
	onApproveClick?: () => void;
	onRejectClick?: () => void;
	onSubmitClick?: () => void;
	setSubmittingAll: (v?: boolean) => void;
	setDrafting: (v?: boolean) => void;
	setIsSampleValues: (v?: boolean) => void;
	isFormValid: boolean;
	currentPartnerTab?: string;
	clearFetchedForms: () => void;
	isFormEdited?: boolean;
	fromAutoSave?: boolean;
};

export type FormState = {
	isValid: boolean;
	isDirty: boolean;
};

export enum LoaderState {
	ShowLoader,
	HideLoader,
}

type Action = { type: 'update'; payload: FormState };
export type Dispatch = (action: Action) => void;

const Footer: React.FC<{
	action: 'ADD' | string;
	tabIndexNames;
	onApproveClick: () => void;
	onRejectClick: () => void;
	onSubmitClick: () => void;
	data?: any;
	isDisabled?: boolean;
	isFormValid: boolean;
}> = ({
	action,
	onApproveClick,
	onRejectClick,
	onSubmitClick,
	data,
	isDisabled,
	isFormValid,
}) => {
	const currentBillerTab = useSelector<ReducerStateType>(
		(state) => state.products.currentBillerTab
	);
	const dispatch = useDispatch();
	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);
	const hasUserPermission = useHasUserPermission('products');
	const { hasApprovePermission, hasRejectPermission } = useMemo(() => {
		return {
			hasApprovePermission: hasUserPermission(
				productCategory,
				'approval.approve'
			),
			hasRejectPermission: hasUserPermission(
				productCategory,
				'approval.reject'
			),
		};
	}, [hasUserPermission, productCategory]);
	const onClickBack = () => {
		const idx = partnerModalTabs.findIndex(
			(tab) => tab.name == currentBillerTab
		);
		dispatch(setCurrentBillerTab(partnerModalTabs[idx - 1].name));
	};
	const onClickNext = () => {
		const idx = partnerModalTabs.findIndex(
			(tab) => tab.name == currentBillerTab
		);
		dispatch(setCurrentBillerTab(partnerModalTabs[idx + 1].name));
	};

	return (
		<div className={styles.footer}>
			<div className={styles.footerLeftPanel}>
				{currentBillerTab != 'primary-info' && (
					<OutlineButton onClick={onClickBack} className={styles.btn}>
						Back
					</OutlineButton>
				)}
			</div>
			<div className={styles.footerRightPanel}>
				{action === 'APPROVAL' && data?.status === 'FOR_REVIEW' && (
					<>
						{hasRejectPermission && (
							<Button
								className={cx(styles.btn, styles.btnReject)}
								onClick={onRejectClick}
							>
								<RejectIcon className={styles.btnRejectIcon} /> Reject
							</Button>
						)}
						{hasApprovePermission && (
							<Button
								className={cx(styles.btn, styles.btnApprove)}
								onClick={onApproveClick}
							>
								<ApproveIcon className={styles.btnApproveIcon} /> Approve
							</Button>
						)}
					</>
				)}
				{currentBillerTab != 'reports' && (
					<PrimaryButton onClick={onClickNext} className={styles.btn}>
						Next
					</PrimaryButton>
				)}
				{!isDisabled && (
					<PrimaryButton
						onClick={onSubmitClick}
						className={styles.btn}
						disabled={!isFormValid}
					>
						Submit
					</PrimaryButton>
				)}
			</div>
		</div>
	);
};

const initialState = {
	isValid: false,
	isDirty: false,
};

export const BillerModalContext = React.createContext<{
	state: FormState;
	dispatch: Dispatch;
}>({ state: initialState, dispatch: () => {} });

type AutoSavedData = {
	date: number;
	username: string;
};

export type SuccessResponse = {
	status: 'OK';
	saveType?: 'DRAFT' | 'NEXT';
	autoSavedData?: AutoSavedData;
};
export type TimeoutResponse = {
	status: 'TIMEOUT';
	autoSavedData?: AutoSavedData;
};
export type FailedResponse = {
	status: 'FAILED';
	errors: string[];
	autoSavedData?: AutoSavedData;
};

export type PrimaryInfoSaveResponse =
	| SuccessResponse
	| FailedResponse
	| TimeoutResponse;

export const mapPrimaryInfo = (
	values: Record<string, unknown> = {}
): Record<string, unknown> => {
	const {
		product_code_type,
		product_affiliates,
		product_addresses = [],
		product_contact_details = [],
		product_officer,
		product_primary_info,
		...rest
	} = values as PrimaryInformationFormData & {
		philippines: Country;
		product_primary_info: FixMeLater;
	};

	const logo = values?.logo || {
		objectKey: null,
		url: 'https://stg-bc-api-images.s3-ap-southeast-1.amazonaws.com/biller-logos/250/MECOR.png',
		filename: null,
	};

	const { code: merchantId } = values;

	return {
		logo,
		merchantId,
		affiliates: product_affiliates,
		...formatObjectKeys(product_primary_info, 'CAMEL'),
		dateOfIncorporation: formatDate(
			product_primary_info?.date_of_incorporation,
			DATE_FORMAT_ISO_8601
		),
		productCode:
			product_code_type === 'AUTO'
				? 'AUTO'
				: product_primary_info?.product_code ?? '',
		productCodeType: product_code_type,
		addresses: product_addresses?.map((address: product_address) => {
			if (address.location_base === 'LOCAL') {
				return {
					id: address.id,
					locationBase: address.location_base,
					buildingNo: address.building_no,
					street: address.street,
					addressCategory: address.address_category,
					barangayId: address.barangay_id,
					countryId: address.country_id,
					cityId: address.city_id,
					provinceId: address.province_id,
					zipCodeId:
						address?.zip_code_id ||
						(address?.zip_code_obj && address?.zip_code_obj?.value),
				};
			}
			return { ...address, zipCode: '' };
		}),
		contactDetails: product_contact_details?.map((c) => ({
			...c,
			city_id: c.area_handled,
		})),
		accountOfficer: product_officer,
		...rest,
	};
};

async function savePrimaryInformation(
	values: PrimaryInformationFormData,
	productId,
	productType,
	saveType
): Promise<PrimaryInfoSaveResponse> {
	const { username: userName } = JSON.parse(
		localStorage.getItem('userInfo') || ''
	);
	const { ...rest } = values as PrimaryInformationFormData & {
		philippines: Country;
	};

	const { objectKey = null, url = values.logo || null, filename = null } = {};
	const body = {
		userName,
		productType,
		primaryInfo: {
			logo: { objectKey, url, filename },
			categoryId: values.category_id,
			otherCategory: values.other_category,
			productCode:
				values.product_code_type === 'AUTO'
					? 'AUTO'
					: values?.product_primary_info?.product_code ?? '',

			subBrands: values.sub_brand || null,
			website: values.website_address || null,
			legalStructure: values.legal_structure,
			addresses: values.product_addresses?.map((address: product_address) => {
				if (address.location_base === 'LOCAL') {
					return {
						locationBase: address.location_base,
						buildingNo: address.building_no,
						street: address.street,
						addressCategory: address.address_category,
						barangayId: address.barangay_id,
						countryId: address.country_id,
						cityId: address.city_id,
						provinceId: address.province_id,
						zipCodeId: address.zip_code_id,
					};
				}
				return { ...address, zipCode: '' };
			}),
			contactDetails: values.product_contact_details?.map((c) => ({
				...c,
				cityId: c.area_handled,
			})),
			accountOfficer: values.product_officer,
			...rest,
			merchantId: values.code,
			originalValues: saveType === 'AUTOSAVE' ? values : undefined,
		},
		serviceFeeSettings: { tierBaseType: null, tierRateType: null },
	};

	try {
		let autoSavedData;
		if (saveType === 'AUTOSAVE') {
			const { data } = await HTTP.post(`/v2/autosave/products`, body);
			autoSavedData = {
				date: data?.data?.created_at,
				username: data?.data?.username,
			};
		}
		//TODO : Check if update to v2
		else if (saveType === 'DRAFT')
			await HTTP.post(`/v2/products/drafts/${values.code || productId}`, body); //TODO : Check if update to v2

		return {
			status: 'OK',
			saveType,
			autoSavedData,
		};
	} catch (error: any) {
		if (!error.status && !error.response) {
			return {
				status: 'TIMEOUT',
			};
		}

		const response = error.response;

		if (response.status == 400) {
			return {
				status: 'FAILED',
				errors: response.data.errors || [],
			};
		}

		return {
			status: 'FAILED',
			errors: response.data,
		};
	}
}

const PartnerModal: React.FC<Props> = ({
	disabled = false,
	action,
	open,
	onClose = () => {},
	data,
	product_type_id,
	products,
	setLastActionButton = () => {},
	setSubmittingAll,
	setDrafting,
	setIsSampleValues,
	isFormValid,
	clearFetchedForms,
	isFormEdited,
	fromAutoSave = false,
}) => {
	const dispatch = useDispatch();

	const productShortName: any = useSelector<ReducerStateType>(
		(state) =>
			state.products.trackPrimaryInfo?.product_primary_info?.product_short_name
	);
	const status: FixMeLater = useSelector<ReducerStateType>(
		(state) => state.form.status
	);
	const productType = useSelector<ReducerStateType>(
		(state) => state.sidebar.itemId
	);
	const productId: any = data.id;
	const currentBillerTab = useSelector<ReducerStateType>(
		(state) => state.products.currentBillerTab
	);

	const [loaderMessage, setLoaderMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [errorAdditional, setErrorAdditional] = useState<ReactNode>('');
	const [confirmationHeader, setConfirmationHeader] = useState<ReactNode>('');
	const [confirmationMessage, setConfirmationMessage] = useState<ReactNode>('');
	const [onConfirmationClose, setOnConfirmationClose] = useState({
		action: () => {},
	});
	const [confirmBtnOnClick, setConfirmBtnOnClick] = useState({
		action: () => {},
	});
	const [confirmBtnLabel, setConfirmBtnLabel] = useState('');
	const [closeBtnLabel, setCloseBtnLabel] = useState('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [currentPartnerCode, setCurrentPartnerCode] = useState('');
	const [isDisabled, setIsDisabled] = useState(disabled);
	const [lastAutoSaved, setLastAutoSaved] = useState<AutoSavedData>();

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);
	const hasUserPermission = useHasUserPermission('products');

	const {
		hasAddPermission,
		hasResubmitPermission,
		hasSaveDraftPermission,
		hasEditDraftPermission,
	} = useMemo(() => {
		return {
			hasAddPermission: hasUserPermission(productCategory, 'submit'),
			hasResubmitPermission: hasUserPermission(productCategory, 'resubmit'),
			hasSaveDraftPermission: hasUserPermission(productCategory, 'save.draft'),
			hasEditDraftPermission: hasUserPermission(productCategory, 'edit.draft'),
		};
	}, [hasUserPermission, productCategory]);

	const rejectRemarksRef = useRef('');
	const approveRemarksRef = useRef('');

	const [isApprovalRemarksEmpty, setIsApprovalRemarksEmpty] =
		useState<boolean>(true);
	const [isRejectionRemarksEmpty, setIsRejectionRemarksEmpty] =
		useState<boolean>(true);

	//For Rejection
	const handleChangeOfRejectionRemarks = (event) => {
		setIsRejectionRemarksEmpty(
			event.target.value.match(/^\s+$/) || event.target.value === ''
		);
	};
	const onRequestCloseRejection = () => {
		setIsRejectionRemarksEmpty(true);
		hideRejectionModal();
	};

	//For Approval
	const handleChangeOfApprovalRemarks = (event) => {
		setIsApprovalRemarksEmpty(
			event.target.value.match(/^\s+$/) || event.target.value === ''
		);
	};
	const onRequestCloseApproval = () => {
		setIsApprovalRemarksEmpty(true);
		hideApprovalModal();
	};

	const {
		value: isLoading,
		valueOn: showLoader,
		valueOff: hideLoader,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const {
		value: isApprovalModalShown,
		valueOn: showApprovalModal,
		valueOff: hideApprovalModal,
	} = useToggle();

	const {
		value: isRejectionModalShown,
		valueOn: showRejectionModal,
		valueOff: hideRejectionModal,
	} = useToggle();

	const initTabStatus = {
		primaryInformation: { disabled: false, finished: false },
		contractDetails: { disabled: false, finished: false },
		serviceFeeSettings: { disabled: false, finished: false },
		businessRules: { disabled: false, finished: false },
		reports: { disabled: false, finished: false },
	};

	const [tabStatus, setTabStatus] = useState<TabStatus | undefined>(
		initTabStatus
	);

	useEffect(() => {
		setTabStatus(initTabStatus);
		dispatch(setCurrentBillerTab(partnerModalTabs[0].name));
	}, [open]);

	useEffect(() => {
		setTabStatus(initTabStatus);
		dispatch(setCurrentBillerTab(partnerModalTabs[0].name));
	}, [open]);

	useEffect(() => {
		setCurrentPartnerCode('');
		clearFetchedForms();
		return () => {
			debounce(clearFetchedForms, 600)();
			setCurrentBillerTab('primary-info');
			setIsSampleValues(false);
		};
	}, []);

	const handleSubmit = async (
		values: PrimaryInformationFormData,
		saveType: string | LoaderState | null
	): Promise<PrimaryInfoSaveResponse> => {
		try {
			setLoaderMessage('Please wait while we save your progress');
			showLoader();
			let response;
			if (
				saveType === LoaderState.HideLoader ||
				saveType === LoaderState.ShowLoader
			) {
				saveType === LoaderState.ShowLoader ? showLoader() : hideLoader();
				return response;
			} else {
				const productType = products.find((p) => p.id == product_type_id)?.code;
				const response = await savePrimaryInformation(
					values,
					product_type_id,
					productType,
					saveType
				);

				if (response?.autoSavedData) {
					setLastAutoSaved(response?.autoSavedData);
				}
				if (response.status === 'OK') {
					if (saveType === 'SAVE') {
						setTabStatus((prev) => ({
							...prev,
							primaryInformation: {
								...prev?.primaryInformation,
								finished: true,
							},
						}));
						showSuccessMessage(
							<>
								<div>Updates on {idx?.label} are successfully saved!</div>
								{currentBillerTab === 'reports' ? (
									<div className={styles.subtext}>
										Once all details are provided, <br />
										you may click the <b> Submit </b>
										button
									</div>
								) : (
									<div className={styles.subtext}>
										You may click the <b>Next</b> button
									</div>
								)}
							</>
						);
					} else if (saveType === 'AUTOSAVE') {
						console.log('Auto saved!');
					} else if (saveType === 'DRAFT') {
						showSuccessMessage(
							<>
								Your work was saved as draft. You may continue your progress
								anytime by going to <b>Drafts</b> and selecting the partner
								under the drafts list.
							</>
						);
					}
				} else if (response.status === 'TIMEOUT') {
					showErrorMessage(
						'Timeout Error!',
						'A problem occured while submitting the data'
					);
					setRetryBtnOnClick({
						action: () => {
							handleSubmit(values, saveType);
						},
					});
				}
			}
			hideLoader();
			return response;
		} catch (e) {
			console.log(e);
			hideLoader();
			return {
				status: 'FAILED',
				errors: [],
			};
		} finally {
			// hideLoader();
		}
	};

	const handleSaveTab = async (
		values: any
	): Promise<PrimaryInfoSaveResponse> => {
		try {
			let response: any = [];
			const productType = products.find((p) => p.id == product_type_id)?.code;
			const saveType = 'SUBMIT';
			if (currentBillerTab === 'primary-info') {
				response = await savePrimaryInformation(
					values,
					product_type_id,
					productType,
					saveType
				);
				response.status = 'OK';
			}
			if (currentBillerTab === 'contract-details') {
				// response = await saveContractDetails(values, FixMeLater);
				response.status = 'OK';
			}
			if (currentBillerTab === 'service-fee') {
				// response = await saveServiceFeeSettings(values, FixMeLater);
				response.status = 'OK';
			}
			if (currentBillerTab === 'business-rules') {
				// response = await saveBusinessRules(values, FixMeLater);
				response.status = 'OK';
			}
			if (currentBillerTab === 'reports') {
				// response = await saveReports(values, FixMeLater);
				response.status = 'OK';
			}

			if (response.status === 'TIMEOUT') {
				showErrorMessage(
					'Timeout Error!',
					'A problem occured while submitting the data'
				);
				setRetryBtnOnClick({
					action: () => {
						handleSaveTab(values);
					},
				});
			}
			return response;
		} catch (e) {
			console.log(e);
			return {
				status: 'FAILED',
				errors: [],
			};
		}
	};

	const handleOnClose = () => {
		if (action == 'ADD' || action == 'DRAFT') {
			if (isFormEdited && hasEditDraftPermission) {
				dispatch(setDrafting(true));
				showConfirmationMessage(
					'Are you done?',
					<>
						You can continue your progress by saving this as draft, <br></br>
						otherwise you may need to create a new registration. <br></br>
						Would you like to save as draft?
					</>,
					() => {
						if (!hasSaveDraftPermission) {
							dispatch(showAccessDeniedModal());
							return;
						}

						draftRef.current();
						setLastActionButton('DRAFT');
						if (action == 'APPROVAL' || action == 'DRAFT') {
							setIsDisabled(true);
						}
					},
					() => {
						onClose();
					},
					'Yes, save as draft',
					'No'
				);
				return;
			}
		}
		if (action == 'APPROVAL') {
			setIsDisabled(true);
		}
		onClose();
	};

	const showErrorMessage = useCallback(
		(
			header: any,
			message: any,
			instruction?: any,
			additional?: any,
			onRetryBtnClick?: () => void
		) => {
			setErrorHeader(header);
			setErrorMessage(message);
			setErrorInstruction(instruction || 'Please try again.');
			setErrorAdditional(additional);
			showErrorModal();
			setRetryBtnOnClick({
				action: () => {
					hideErrorModal();
					onRetryBtnClick && onRetryBtnClick();
				},
			});
		},
		[hideErrorModal, showErrorModal]
	);

	const showSuccessMessage = useCallback(
		(message: any, onDoneBtnClick?: () => void) => {
			setSuccessMessage(message);
			showSuccessModal();
			setDoneBtnOnClick({
				action: () => {
					hideSuccessModal();
					onDoneBtnClick && onDoneBtnClick();
				},
			});
		},
		[hideSuccessModal, showSuccessModal]
	);

	const showConfirmationMessage: TPartnerModalConfirmProps = (
		header: ReactNode | string,
		message: ReactNode | string,
		onConfirmBtnClick?: () => void,
		onClose?: () => void,
		confirmBtnLabel?: string,
		closeBtnLabel?: string
	) => {
		setConfirmationHeader(header);
		setConfirmationMessage(message);
		showConfirmationModal();
		setConfirmBtnLabel(confirmBtnLabel || 'Confirm');
		setCloseBtnLabel(closeBtnLabel || 'Cancel');
		setConfirmBtnOnClick({
			action: () => {
				hideConfirmationModal();
				onConfirmBtnClick && onConfirmBtnClick();
			},
		});
		setOnConfirmationClose({
			action: () => {
				hideConfirmationModal();
				onClose && onClose();
			},
		});
	};

	const getCurrentPartnerCode = () => {
		return (
			data?.primary_info?.code ||
			products.find((p) => p.id == product_type_id)?.code
		);
	};

	const approve = async (approveRemarks) => {
		setLoaderMessage('Please wait while we mark the request as approved');
		showLoader();

		await HTTP.post(
			`/v2/products/${resolveValue(product_type_id)}/${resolveValue(
				data.id
			)}/approve`,
			{
				remarks: approveRemarks,
			}
		)
			.then(() => {
				showSuccessMessage(
					<>
						{productShortName} status is now{' '}
						<b className={styles.boldText}>Approved</b>.
					</>,
					() => {
						onClose && onClose();
					}
				);
			})
			.catch((e: any) => {
				showErrorMessage(
					'Timeout Error!',
					<>
						A problem occurred while approving{' '}
						<b className={styles.boldText}>{productShortName}</b>.
					</>,
					null,
					null,
					() => approve(approveRemarks)
				);
			})
			.finally(() => {
				hideApprovalModal();
				hideLoader();

				approveRemarksRef.current = '';
			});
	};

	const reject = async (rejectRemarks) => {
		setLoaderMessage('Please wait while we mark the request as rejected');
		showLoader();

		await HTTP.post(
			`/v2/products/${resolveValue(product_type_id)}/${resolveValue(
				data.id
			)}/reject`,
			{
				remarks: rejectRemarks,
			}
		)
			.then(() => {
				showSuccessMessage(
					<>
						{productShortName} status is now{' '}
						<b className={styles.boldText}>Rejected</b>.
					</>,
					() => {
						onClose && onClose();
					}
				);
			})
			.catch((e: any) => {
				showErrorMessage(
					'Timeout Error!',
					<>
						A problem occurred while rejecting{' '}
						<b className={styles.boldText}>{productShortName}</b>.
					</>,
					null,
					null,
					() => reject(rejectRemarks)
				);
			})
			.finally(() => {
				hideRejectionModal();
				hideLoader();

				rejectRemarksRef.current = '';
			});
	};

	const formData: any = useSelector<ReducerStateType>((state) => {
		return {
			// ...sampleProductRequestPayload,
			productType: getCurrentPartnerCode(),
			code: state.products.primaryInfo.code,
			primaryInfo: mapPrimaryInfo(state.products.primaryInfo),
			contractDetails: replaceDeepKeys(
				state.products.contractDetails,
				/product_|_obj/g,
				'',
				'CAMEL'
			),
			serviceFeeSettings: formatObjectKeys(
				mapServiceFeeSettings(state.products.serviceFeeSettings),
				'CAMEL'
			),
			businessRules: formatObjectKeys(state.products.businessRules, 'CAMEL'),
			reports: formatObjectKeys(state.products.reports, 'SNAKE'),
			// reports: formatObjectKeys(
			// 	mapReports(
			// 		formatObjectKeys(FEMockProductPayload.reports as any, 'CAMEL')
			// 	),
			// 	'SNAKE'
			// ),
		};
	});

	const currentFormData: any = useSelector<ReducerStateType>((state) => {
		return {
			productType: getCurrentPartnerCode(),
			code: state.products.primaryInfo.code,
			primaryInfo: mapPrimaryInfo(state.products.primaryInfo),
			contractDetails: replaceDeepKeys(
				state.products.contractDetails,
				/product_|_obj/g,
				'',
				'CAMEL'
			),
			serviceFeeSettings: formatObjectKeys(
				mapServiceFeeSettings(state.products.serviceFeeSettings),
				'CAMEL'
			),
			businessRules: formatObjectKeys(state.products.businessRules, 'CAMEL'),
			reports: formatObjectKeys(state.products.reports, 'SNAKE'),
		};
	});

	const submitRef = useRef(async () => {
		setLoaderMessage(
			`Please wait while we submit the request for the created partner (${productShortName}) approval`
		);
		showLoader();
		const method = action == 'ADD' ? 'post' : 'post';
		const url =
			action == 'ADD'
				? `/v2/products/${productType}/submit`
				: action == 'APPROVAL'
				? `/v2/products/${productType}/${productId}/resubmit`
				: `/v2/products/${productType}/${productId}/submit`;

		let form = action == 'ADD' ? formData : currentFormData;
		form = replaceNoValuesObj(form);

		client[method](url, form)
			.then(async () => {
				showSuccessMessage(
					'Your request was submitted for approval.',
					async () => {
						await HTTP.delete(`v2/autosave/products`); // delete autosave if draft save
						onClose && onClose();
					}
				);
			})
			.catch((e: any) => {
				showErrorMessage(
					'Timeout Error!',
					'A problem occurred while trying to submit partner.',
					null,
					null,
					submitRef.current
				);
			})
			.finally(() => {
				hideLoader();
			});
	});

	useEffect(() => {
		submitRef.current = async () => {
			setLoaderMessage(
				`Please wait while we submit the request for the created partner (${productShortName}) approval`
			);
			showLoader();
			const method = action == 'ADD' ? 'post' : 'post';
			const url =
				action == 'ADD'
					? `/v2/products/${productType}/submit`
					: action == 'APPROVAL'
					? `/v2/products/${productType}/${productId}/resubmit`
					: `/v2/products/${productType}/${productId}/submit`;
			let form = action == 'ADD' ? formData : currentFormData;
			form = replaceNoValuesObj(form);

			client[method](url, form)
				.then(() => {
					showSuccessMessage(
						'Your request was submitted for approval.',
						async () => {
							await HTTP.delete(`v2/autosave/products`); // delete autosave if draft save
							onClose && onClose();
						}
					);
				})
				.catch((e: any) => {
					showErrorMessage(
						'Timeout Error!',
						'A problem occurred while trying to submit partner.',
						null,
						null,
						submitRef.current
					);
				})
				.finally(() => {
					hideLoader();
				});
		};
	}, [
		action,
		currentFormData,
		currentPartnerCode,
		formData,
		hideLoader,
		onClose,
		productId,
		productType,
		showErrorMessage,
		showLoader,
		showSuccessMessage,
		productShortName,
	]);

	const handlerSubmitRef = useRef(handleSubmit);

	useEffect(() => {
		handlerSubmitRef.current = handleSubmit;
	}, [formData]);

	const draftRefCurrent = async () => {
		const method = action == 'ADD' ? 'post' : 'put';
		const url =
			action == 'ADD'
				? `/v2/products/${productType}/drafts`
				: `/v2/products/${productType}/${productId}/drafts`;
		let form = action == 'ADD' ? currentFormData : currentFormData;

		//remove empty string
		form = replaceNoValuesObj(form);

		if (form.code) {
			setLoaderMessage('Please wait while we save your draft');
			showLoader();
			client[method](url, form)
				.then((resp) => {
					showSuccessMessage(
						<>
							Your work was saved as draft. You may continue your progress
							anytime by going to <b>Drafts</b> and selecting the partner under
							the drafts list
						</>,
						async () => {
							onClose && onClose();
						}
					);
				})
				.catch((errors) => {
					if (errors.message.includes('Network')) {
						showErrorMessage(
							'Timeout Error!',
							'A problem occurred while saving as draft.',
							null,
							null,
							draftRef.current
						);
					} else {
						const errorMsg = errors.response.data.message;

						if (errorMsg.includes('Merchant ID already exists')) {
							showErrorMessage('Error!', 'Merchant ID is already existing');
						} else if (errorMsg.includes('Partner short name')) {
							showErrorMessage(
								'Error!',
								'Partner short name is already existing'
							);
						} else if (errorMsg.includes('ShortName')) {
							showErrorMessage(
								'Incomplete Partner Details',
								'Partner Name is required to save this as draft.',
								'Primary Information tab.',
								'Please input the Partner Name under the'
							);
						} else {
							showErrorMessage(
								'Timeout Error!',
								'A problem occurred while saving as draft.',
								null,
								null,
								draftRef.current
							);
						}
					}
				})
				.finally(() => {
					dispatch(setDrafting(false));
					hideLoader();
				});
		} else {
			showErrorMessage('Error!', 'Please enter a merchant ID');
		}
	};

	const draftRef = useRef(draftRefCurrent);

	useEffect(() => {
		draftRef.current = draftRefCurrent;
	}, [currentFormData]);

	const idx = partnerModalTabs.find((tab) => tab.name == currentBillerTab);
	action = action || 'ADD';

	let lastUpdated, updatedBy;
	if (lastAutoSaved) {
		lastUpdated = convertDate(lastAutoSaved?.date, 'EPOCH');
		updatedBy = lastAutoSaved?.username;
	} else if (data?.updated_at && typeof data?.updated_at == 'string') {
		lastUpdated = convertDate(data?.updated_at, 'ISO');
		updatedBy = data?.updated_by;
	} else if (action === 'ADD' && data?.autoSavedData?.date && fromAutoSave) {
		lastUpdated = convertDate(data?.autoSavedData?.date, 'EPOCH');
		updatedBy = data?.autoSavedData?.username;
	}

	const confirmationModalMessageBody = (action, productShortName) => {
		if (action === 'APPROVAL') {
			return (
				<>
					<p>
						Are you sure you want to submit the Partner request for approval?
					</p>
				</>
			);
		} else {
			return (
				<>
					<p>
						Are you sure you want to submit the created partner (
						<b>{productShortName}</b>) for approval?
					</p>
				</>
			);
		}
	};

	return (
		<>
			<FullPageLoader open={isLoading} message={loaderMessage} />
			<Modal
				isOpen={open}
				disable={isLoading}
				onRequestClose={handleOnClose}
				headerClassName={styles.headerContainer}
				containerClassName={styles.modalContainer}
				contentClassName={styles.modal}
				size="medium"
				// dismissOnClickOutside={false}
				heading={
					<div className={styles.header}>
						<div className={styles.titleLeftPanel}>
							<div className={styles.titleTextContainer}>
								{(action == 'ADD' || action == 'DRAFT') && 'Add New Partner'}
								{action == 'APPROVAL' && 'Partner Details'}
							</div>
							{(action == 'APPROVAL' || action == 'DRAFT') && (
								<div className={styles.titleStatusContainer}>
									<p className={styles.titleStatusLabel}>Partner Status:</p>
									<PartnerStatusSelect value={data?.status} disabled={true} />
								</div>
							)}
						</div>
						<div className={styles.titleRightPanel}>
							{data?.status !== 'FOR_REVIEW' && data?.status !== 'APPROVED' && (
								<div className={styles.titleActionBtnContainer}>
									{action != 'APPROVAL' && !isDisabled && (
										<OutlineButton
											onClick={() => {
												if (!hasSaveDraftPermission) {
													dispatch(showAccessDeniedModal());
													return;
												}

												dispatch(setDrafting(true));
												showConfirmationMessage(
													'Save as Draft',
													'Would you like to save this as draft?',
													() => {
														draftRef.current();
														setLastActionButton('DRAFT');
														if (action == 'APPROVAL' || action == 'DRAFT') {
															setIsDisabled(true);
														}
													},
													() => {},
													'Yes',
													'Back'
												);
											}}
											className={styles.btn}
										>
											<SaveDraftIcon className={styles.btnIcon} />
											Save as Draft
										</OutlineButton>
									)}
									{isDisabled && (
										<OutlineButton
											onClick={() => {
												if (action === 'DRAFT' && !hasEditDraftPermission) {
													dispatch(showAccessDeniedModal());
													return;
												}

												setIsDisabled(false);
											}}
											className={styles.btn}
										>
											<SaveDraftIcon className={styles.btnIcon} />
											Edit Partner Details
										</OutlineButton>
									)}
								</div>
							)}
							<div className={styles.titleActionTextContainer}>
								<em className={styles.titleActionText}>
									{lastUpdated && 'Last updated at ' + lastUpdated}
									{updatedBy && ' by ' + updatedBy}
								</em>
							</div>
						</div>
					</div>
				}
				footer={
					<Footer
						action={action}
						tabIndexNames={partnerModalTabs}
						onApproveClick={() => {
							showApprovalModal();
						}}
						onRejectClick={() => {
							showRejectionModal();
						}}
						onSubmitClick={() => {
							if (
								(action === 'ADD' && !hasAddPermission) ||
								(action === 'APPROVAL' && !hasResubmitPermission)
							) {
								dispatch(showAccessDeniedModal());
								return;
							}
							dispatch(setSubmittingAll(true));
							showConfirmationMessage(
								'Submit Partner for Approval',
								confirmationModalMessageBody(action, productShortName),
								() => {
									submitRef.current();
								},
								() => {
									if (action == 'APPROVAL') {
										setIsDisabled(true);
									}
								}
							);
						}}
						data={data}
						isDisabled={isDisabled}
						isFormValid={isFormValid}
					/>
				}
			>
				<div className={styles.body}>
					<PartnerForm
						action={action}
						data={data}
						contentClassName={styles.bodyContent}
						onSubmit={handleSubmit}
						onSave={handleSaveTab}
						tabStatus={tabStatus}
						disabled={isDisabled}
						errorMessage={errorMessage?.toString() || ''}
						setLastAutoSaved={setLastAutoSaved}
						showConfirmationMessage={showConfirmationMessage}
						modalOpen={open}
					/>
				</div>
			</Modal>
			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalOpen && (
				<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>{errorHeader}</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorBody}>{errorAdditional}</div>
						<div className={styles.errorFooter}>{errorInstruction}</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								if (errorAdditional) {
									hideErrorModal();
								} else {
									retryBtnOnClick.action();
								}
							}}
							className={styles.errorModalBtn}
						>
							{errorAdditional ? 'Okay' : 'Retry'}
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					isDisabled={isLoading}
					heading={confirmationHeader}
					message={confirmationMessage}
					onClose={() => {
						onConfirmationClose.action();
					}}
					onCancelBtnClick={() => {
						onConfirmationClose.action();
						// onClose && onClose();
					}}
					onConfirmBtnClick={() => {
						confirmBtnOnClick.action();
					}}
					cancelBtnLabel={closeBtnLabel}
					confirmBtnLabel={confirmBtnLabel}
				/>
			)}
			{isApprovalModalShown && (
				<Modal
					isOpen={isApprovalModalShown}
					onRequestClose={onRequestCloseApproval}
					disable={isLoading}
					headerClassName={styles.approvalModalHeaderContainer}
					containerClassName={styles.approvalModalContainer}
					contentClassName={styles.approvalModal}
					heading={<h2 className={styles.approveHeading}>Approve Partner</h2>}
					footer={
						<div className={styles.approvalModalFooter}>
							<OutlineButton
								onClick={() => {
									onRequestCloseApproval();
								}}
								className={styles.approvalModalBtn}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								disabled={isApprovalRemarksEmpty}
								onClick={() => approve(approveRemarksRef.current)}
								className={styles.approvalModalBtn}
							>
								Confirm
							</PrimaryButton>
						</div>
					}
				>
					<div className={styles.approvalModalBodyHeader}>
						<span>
							Are you sure you want to approve{' '}
							<b>
								{data?.primary_info?.product_primary_info?.product_short_name}
							</b>
							?
							<br />
							If yes, please input remarks.
						</span>
					</div>
					<div className={styles.approvalModalBodyContent}>
						<div className="slds-form-element__control">
							<label className="slds-form-element__label">
								<abbr className="slds-required" title="required">
									*
								</abbr>
								Remarks
							</label>
							<textarea
								className={'slds-textarea' + ' ' + styles.approvalRemarks}
								maxLength={255}
								onChange={(e) => {
									approveRemarksRef.current = e.target.value;
									handleChangeOfApprovalRemarks(e);
								}}
								onInput={restrictSpecialChars}
							></textarea>
						</div>
						<em className={styles.approvalRemarksSubLabel}>
							*Maximum of 255 characters only.
						</em>
					</div>
				</Modal>
			)}
			{isRejectionModalShown && (
				<Modal
					isOpen={isRejectionModalShown}
					onRequestClose={onRequestCloseRejection}
					disable={isLoading}
					headerClassName={styles.rejectionModalHeaderContainer}
					containerClassName={styles.rejectionModalContainer}
					contentClassName={styles.rejectionModal}
					heading={<h2 className={styles.customHeading}>Reject Partner</h2>}
					footer={
						<div className={styles.rejectionModalFooter}>
							<OutlineButton
								onClick={() => {
									onRequestCloseRejection();
								}}
								className={styles.rejectionModalBtn}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								disabled={isRejectionRemarksEmpty}
								onClick={() => reject(rejectRemarksRef.current)}
								className={styles.rejectionModalBtn}
							>
								Confirm
							</PrimaryButton>
						</div>
					}
				>
					<div className={styles.rejectionModalBodyHeader}>
						Are you sure you want to reject{' '}
						<strong>
							{data?.primary_info?.product_primary_info?.product_short_name}
						</strong>
						?<br />
						If yes, please input remarks.
					</div>
					<div className={styles.rejectionModalBodyContent}>
						<label className="slds-form-element__label">
							Remarks
							<abbr className="slds-required" title="required">
								*
							</abbr>
						</label>
						<div className="slds-form-element__control">
							<textarea
								className={'slds-textarea' + ' ' + styles.rejectionRemarks}
								maxLength={255}
								onChange={(e) => {
									rejectRemarksRef.current = e.target.value;
									handleChangeOfRejectionRemarks(e);
								}}
								onInput={restrictSpecialChars}
							></textarea>
						</div>
						<em className={styles.rejectionRemarksSubLabel}>
							*Maximum of 255 characters only.
						</em>
					</div>
				</Modal>
			)}
		</>
	);
};

export default connect(
	(state: any) => ({
		product_type_id: state.sidebar.itemId,
		products: state.sidebar.products,
		isFormValid:
			state.products.primaryInfo?.isValid &&
			state.products.contractDetails?.isValid &&
			state.products.serviceFeeSettings?.isValid &&
			state.products.businessRules?.isValid &&
			state.products.reports?.isValid,
		isFormEdited:
			state.products.primaryInfo?.isDirty ||
			state.products.contractDetails?.isDirty ||
			state.products.serviceFeeSettings?.isDirty ||
			state.products.businessRules?.isDirty ||
			state.products.reports?.isDirty,
	}),
	{
		setLastActionButton,
		setSubmittingAll,
		setDrafting,
		setIsSampleValues,
		clearFetchedForms,
	}
)(PartnerModal);
