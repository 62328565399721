import Grid from "components/Grid/Grid"
import CheckboxGroup from "components/Inputs/CheckboxGroup/CheckboxGroup"
import Section from "components/Section/Section"
import { useFormContext } from "react-hook-form"
import { BUSINESS_LINES_OPTIONS } from "../ContractDetailsConstants"
import { ContractDetailsType } from "../ContractDetailsSchemas"
type Props = {
    title: string;
    name: string;
    disabled: boolean;
}
const OtherBusinessLines: React.FC<Props> = ({ disabled, name, title }) => {
    const { control, setValue } = useFormContext<ContractDetailsType>()
    return (
        <Section title={title}>
            <Grid column size={1} of={1}>
                <CheckboxGroup
                    showLabel={false}
                    parentName={name}
                    name={`${name}.businessLines`}
                    label="Other Business Lines"
                    control={control}
                    options={BUSINESS_LINES_OPTIONS}
                    displayAll={true}
                    fitHorizontally={true}
                    disabled={disabled}
                    setValue={setValue}
                />
            </Grid>
        </Section>
    )
}

export default OtherBusinessLines