import {
	Control,
	useForm,
	UseFormClearErrors,
	UseFormSetValue,
} from 'react-hook-form';
import styles from './PrimaryInformation.module.css';
import BillerAddress, {
	BillerAddressFields,
} from './sections/BillerAddress/BillerAddress';
import BasicInformation, {
	BasicInformationFields,
} from './sections/BasicInformation/BasicInformation';
import BillerContactDetails, {
	BillerContactDetailsFields,
} from './sections/BillerContactDetails/BillerContactDetails';
import AccountOfficer, {
	AccountOfficerFields,
} from './sections/AccountOfficer/AccountOfficer';
import { yupResolver } from '@hookform/resolvers/yup';
import primaryInformationSchema from 'utils/formSchemas/billerForm/primaryInformation';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import {
	submitted as submittedAC,
	update as updateAC,
} from 'redux/modules/form';

export type PrimaryInformationFormData = {
	basicInformation?: BasicInformationFields;
	billerAddress?: BillerAddressFields;
	billerContactDetails?: BillerContactDetailsFields;
	accountOfficer?: AccountOfficerFields;
};

export type PrimaryInformationSectionProps = {
	setValue: UseFormSetValue<any>;
	clearErrors: UseFormClearErrors<any>;
	initialValues: PrimaryInformationFormData;
	control: Control<any>;
};

type Props = {
	initialValues: PrimaryInformationFormData;
	disabled?: boolean;
	onSubmit?: (values: any) => void;
};

const PrimaryInformation: React.FC<Props> = ({
	initialValues,
	disabled,
	onSubmit,
}) => {
	const {
		control,
		setValue,
		clearErrors,
		formState: { isDirty, isValid, errors },
		handleSubmit,
		reset,
		setError,
	} = useForm<PrimaryInformationFormData>({
		mode: 'all',
		defaultValues: initialValues,
		resolver: yupResolver(primaryInformationSchema),
	});

	const dispatch = useDispatch();
	const submitting = useSelector<ReducerStateType>(
		(state) => state.form.status.submitting
	);

	useEffect(() => {
		if (submitting) {
			handleSubmit(
				async (values) => {
					if (onSubmit) {
						const response = (await onSubmit(values)) as unknown as any;

						if (response.status === 'FAILED') {
							const { errors = [] } = response;

							let alreadyFocused = false;

							if (errors.includes('merchant_id')) {
								setError(
									'basicInformation.merchantId',
									{
										type: 'manual',
										message: 'Merchant ID is already existing',
									},
									{ shouldFocus: true }
								);
								alreadyFocused = true;
							}
							if (errors.includes('biller_code')) {
								setError(
									'basicInformation.billerCode',
									{
										type: 'manual',
										message: 'Biller Code is already existing',
									},
									{ shouldFocus: !alreadyFocused }
								);
							}
						} else {
							reset(initialValues, { keepValues: true });
						}
					} else {
						reset(initialValues, { keepValues: true });
					}
				},
				(errors, e) => {
					console.log(errors, e);
				}
			)();
			dispatch(submittedAC());
		}
	}, [dispatch, handleSubmit, onSubmit, submitting]);

	useEffect(() => {
		dispatch(updateAC({ isValid, isDirty }));
	}, [isValid, isDirty, dispatch]);

	return (
		<div className={styles.container}>
			<BasicInformation
				control={control}
				setValue={setValue}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<BillerAddress
				control={control}
				setValue={setValue}
				clearErrors={clearErrors}
				maxEntries={20}
				initialValues={initialValues}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<BillerContactDetails
				control={control}
				setValue={setValue}
				clearErrors={clearErrors}
				maxEntries={20}
				initialValues={initialValues}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<AccountOfficer control={control} disabled={disabled} />
		</div>
	);
};

export default PrimaryInformation;
