import React, { useState, useMemo, ChangeEvent, useEffect } from 'react';
import { useToggle } from 'utils/hooks';
import scrollableListStyles from './ProductScrollableList.module.css';
import customModalStyles from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal.module.css';
import cx from 'classnames';
import { useHasUserPermission } from 'utils/permissions';
import ChannelProductModal from '../../ChannelProductsModal/ChannelProductModal';
import ToggleButton from './ToggleButton';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { ProductScrollableListProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import HTTP from 'helpers/ApiClient';
import { BASE_PATH } from 'containers/ChannelManagement/Channel/ChannelProducts/const';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

const ProductScrollableList: React.FC<ProductScrollableListProps> = ({
	name,
	channel,
	activePage,
}) => {
	const shouldRenderProducts = Boolean(channel?.products?.length);
	const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(true);
	const [showEnableModal, setshowEnableModal] = useState(false);
	const [showDisableModal, setshowDisableModal] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [isEnable, setIsEnable] = useState(true);
	const [productIndex, setProductIndex] = useState(-1);
	const [remarks, setRemarks] = useState('');
	const [activeProduct, setActiveProduct] = useState();
	const [errorRemarks, setErrorRemarks] = useState({
		hasError: false,
		status: '',
	});
	const [products, setProducts] = useState<any[]>([]);

	useEffect(() => {
		setProducts(channel.products);
	}, [channel.products]);

	const hasUserPermission = useHasUserPermission('channels');

	const handleToggleOnChange = (
		index: number,
		e: ChangeEvent<HTMLInputElement>
	) => {};

	const styles = {
		...scrollableListStyles,
		...customModalStyles,
	};

	const { hasEditPermission } = useMemo(() => {
		return {
			hasEditPermission: hasUserPermission('edit'),
		};
	}, [hasUserPermission]);

	const syntheticEvent = (status: boolean) => {
		return {
			target: {
				checked: status,
			},
		} as ChangeEvent<HTMLInputElement>;
	};

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const handleChangeOfRemarks = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setIsRemarksEmpty(
			/^\s+$/.test(event.target.value) || event.target.value === ''
		);
		setRemarks(event.target.value);
	};

	const handleToggleOnClick = (index: number) => {
		if (!products[index].enabled) {
			setProductIndex(index);
			setshowEnableModal(true);
			setIsEnable(true);
		} else {
			setProductIndex(index);
			setshowDisableModal(true);
			handleToggleOnChange(index, syntheticEvent(products[index].enabled));
			setIsEnable(!isEnable);
		}
	};
	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const enableDisable = async (productType: number, isEnable: boolean) => {
		const action = isEnable ? 'enable' : 'disable';
		showLoadingMessage(`Please wait while we mark the product as ${action}`);
		const params = { remarks: remarks };
		await HTTP.put(
			BASE_PATH + `${channel.data.id}/products/types/${productType}/${action}`,
			params
		)
			.then(() => {
				setActiveProduct(products[productIndex]);
				showSuccessModal();
				handleToggleOnChange(productIndex, syntheticEvent(isEnable));
			})
			.catch(() => {
				showErrorRetry();
				setActiveProduct(products[productIndex]); // for retry purpose
				handleToggleOnChange(productIndex, syntheticEvent(!isEnable));
			})
			.finally(() => {
				hideLoading();
			});
	};
	const handleConfirmBtnClick = () => {
		if (showEnableModal) {
			checkRemarks(remarks);
			enableDisable(products[productIndex].id, true);
			hideModal();
			setshowEnableModal(!showEnableModal);
		} else {
			checkRemarks(remarks);
			enableDisable(products[productIndex].id, false);
			hideModal();
			setshowDisableModal(!showDisableModal);
		}
	};
	const checkRemarks = (remarks: string) => {
		if (remarks.length === 0) {
			setErrorRemarks({
				hasError: true,
				status: 'Please input remarks.',
			});
			return;
		}
	};
	const handleCancelBtnClick = () => {
		hideModal();
		handleToggleOnChange(productIndex, syntheticEvent(!activeProduct?.enabled));
		showEnableModal ? setshowEnableModal(false) : setshowDisableModal(false);
		setProductIndex(-1);
		console.log('modal should close end of function', showEnableModal);
	};

	const hideModal = () => {
		setRemarks('');
		setIsRemarksEmpty(true);
		setErrorRemarks({ hasError: false, status: '' });
	};

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorRetryShowing,
		valueOn: showErrorRetry,
		valueOff: hideErrorRetry,
	} = useToggle();

	const handleClickRetry = () => {};

	const nextPage = (action: boolean) => {
		if (action) {
			activePage(false, products[productIndex]);
			setActiveProduct(undefined);
			setProductIndex(-1);
			hideSuccessModal();
		} else {
			setProductIndex(-1);
			hideSuccessModal();
		}
	};

	return shouldRenderProducts ? (
		<div className={styles.scrollableContainer}>
			{products &&
				products.map((type, index) => (
					<div key={index} className={styles.productType}>
						{type.name}
						<div className={cx('slds-clearfix', styles.toggleButton)}>
							<div className="slds-float_right">
								<ToggleButton
									isDisabled={
										channel.data.status !== 'DEACTIVATED' && hasEditPermission
									}
									isChecked={products[index].enabled}
									id={index}
									onClickBtn={handleToggleOnClick}
									onChangeBtn={handleToggleOnChange}
								/>
							</div>
						</div>
					</div>
				))}
			{showEnableModal && (
				<ChannelProductModal
					isOpen={true}
					onClose={handleCancelBtnClick}
					isDisabled={isLoading}
					heading={<h2 className={styles.customHeading}>Enable Product?</h2>}
					message={
						<h2 className={styles.bodyHeader}>
							Are you sure you want to enable{' '}
							<strong>{products[productIndex].name}</strong>?
						</h2>
					}
					isPrimaryBtnDisabled={isRemarksEmpty}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Confirm"
					onCancelBtnClick={handleCancelBtnClick}
					onConfirmBtnClick={handleConfirmBtnClick}
					showRemarks
					error={errorRemarks}
					handleChangeOfRemarks={handleChangeOfRemarks}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
				/>
			)}

			{showDisableModal && (
				<ChannelProductModal
					isOpen={true}
					onClose={handleCancelBtnClick}
					isDisabled={isLoading}
					heading={<h2 className={styles.customHeading}>Disable Product?</h2>}
					message={
						<h2 className={styles.bodyHeader}>
							Are you sure you want to disable{' '}
							<strong>{activeProduct?.name}</strong>?
						</h2>
					}
					isPrimaryBtnDisabled={isRemarksEmpty}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Confirm"
					onCancelBtnClick={handleCancelBtnClick}
					onConfirmBtnClick={handleConfirmBtnClick}
					showRemarks
					error={errorRemarks}
					handleChangeOfRemarks={handleChangeOfRemarks}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
				/>
			)}
			<>
				{isLoading && (
					<FullPageLoader open={isLoading} message={loadingMessage} />
				)}
				<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div>Successfully {isEnable ? 'Enabled' : 'Disabled'}</div>
						</SuccessText>
						<div className={styles.subTextNext}>
							{activeProduct && activeProduct.code} is now{' '}
							{isEnable ? 'enabled.' : 'disabled.'}
						</div>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.doneButton}
							onClick={() => {
								nextPage(isEnable);
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
				<ErrorModal open={isErrorRetryShowing} onClose={hideErrorRetry}>
					<ErrorModalBody className={styles.errorBody}>
						<div className={styles.errorHeader}>
							<b>Timeout Error!</b>
						</div>
						<div className={styles.errorText}>
							<div>A problem occured while saving the data.</div>
							<div>Please try again.</div>
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							className={styles.contactDetailsErrorCloseBtn}
							onClick={() => {
								hideErrorRetry();
								handleClickRetry();
							}}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			</>
		</div>
	) : null;
};
export default ProductScrollableList;
