import SelectField from 'components/Inputs/SelectField/SelectField';
import cx from 'classnames';
import styles from 'containers/ChannelManagement/Channel/ChannelIntegration/ChannelStatusSelect.module.css';
import { connect } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useWatch } from 'react-hook-form/dist/useWatch';

type Props = {
	value?: string;
	disabled?: boolean;
	control?: any;
	name?: string;
	onSelect?: (val: any) => void;
};

type StatusOption = {
	label: string;
	value: string;
	className: string;
	isHidden?: boolean;
};

export const status_option: StatusOption[] = [
	{
		label: 'Active',
		value: 'ACTIVE',
		className: cx(styles.active),
	},
	{
		label: 'For Activation',
		value: 'FOR_ACTIVATION',
		className: cx(styles.forActivation),
	},
];

const ChannelStatusSelect: React.FC<Props> = ({
	value,
	disabled,
	control,
	name,
	onSelect,
}) => {
	const match = status_option.find((option) => option.value == value);

	return (
		<SelectField
			className={cx(styles.select, match ? match.className : '')}
			options={status_option}
			size="x-small"
			control={control}
			name={name}
			placeholder="Channel Status"
			buttonClassName={styles.button}
			value={value}
			onChange={(val) => {
				onSelect && onSelect(val.value);
			}}
			disabled={disabled}
		></SelectField>
	);
};

export default ChannelStatusSelect;
