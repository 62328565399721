import React from 'react'
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import styles from 'containers/ChannelManagement/Terminal/style.module.css';
import { Modal } from '@salesforce/design-system-react/module/components';
import { ConfirmationModalProps } from 'containers/ChannelManagement/Terminal/types';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    open,
    onClose,
    heading,
    body,
    cancelButton,
    submitButton,
    handleSubmit
}) => {

    const footer = (
        <div className={styles.footer}>
            <div className={styles.footerRightPanel}>
                <OutlineButton
                    onClick={onClose}
                    className={styles.btn}
                >
                    {cancelButton}
                </OutlineButton>
                <PrimaryButton
                    onClick={handleSubmit}
                    className={styles.btn}
                >
                    {submitButton}
                </PrimaryButton>
            </div>
        </div>
    )

    return (
        <>
            <Modal
                isOpen={open}
                onRequestClose={onClose}
                heading={heading}
                containerClassName={styles.confirmModalContainer}
                footer={footer}
            >
                <div className={styles.confirmationBody}>
                    {body}
                </div>
            </Modal>
        </>
    )
}

export default ConfirmationModal