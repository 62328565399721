import yup, {
	defaultInvalidCharacter,
	hasSpecialCharacter,
	selectDefaultRequiredTemplate,
} from '../common';
import _ from 'lodash';

import { isANumber, isPositive } from 'components/Format/NumberFormatter';
import { string } from 'yup';

const createAdjustmentForm = yup.object().shape({
	walletType: yup
		.string()
		.label('Wallet Type')
		.when('adjustmentRadioType', {
			is: '0',
			then: yup.string().required('Wallet Type'),
		}),
	partnerName: yup
		.string()
		.label('Partner Name')
		.when('adjustmentRadioType', {
			is: '0',
			then: yup.string().required('Partner Name'),
		}),
	walletId: yup
		.string()
		.label('Wallet ID')
		.when('adjustmentRadioType', {
			is: '0',
			then: yup.string().required('Wallet ID'),
		}),
	transactionNo: yup
		.object({
			hasPending: string(),
			label: string(),
			value: string(),
		})
		.nullable()
		.label('Transaction Reference No.')
		.test(
			'has-pending',
			"There's already a created adjustment request for this transaction pending for approval",
			(v) => {
				return v?.hasPending == '1' ? false : true;
			}
		),
	adjustmentTypeField: yup
		.string()
		.label('Adjustment Type')
		.when('adjustmentRadioType', {
			is: '0',
			then: yup.string().required('Adjustment Type'),
		}),
	adjustmentRadioType: yup.string().default('0').required(),
	amountForAdjustment: yup
		.string()
		.label('Amount for Adjustment')
		.transform((curr) => curr.replaceAll(',', ''))
		.when('adjustmentRadioType', {
			is: '0',
			then: yup.string().required('Amount for Adjustment'),
		}),
	remarks: yup
		.string()
		.label('Remarks')
		.max(255)
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	adjustmentFile: yup
		.mixed()
		.label('File')
		.when('adjustmentRadioType', {
			is: '1',
			then: yup
				.mixed()
				.required('File')
				.test('type', 'Invalid file type', (value: File) => {
					console.log(value);
					if (!value) return false;
					const fileExt = value?.name?.split('.').pop();
					const isCSV =
						fileExt === 'csv' &&
						(value?.type === 'application/vnd.ms-excel' ||
							value?.type === 'text/csv');
					return isCSV;
				})
				.test(
					'fileSize',
					'Maximum allowed file size per attachement exceeded. Only 15 mb per attachment is allowed.',
					(value: File) => value && value.size < 1024 * 1024 * 16
				)
				.test(
					'countLength',
					'Maximum allowed number of requests in the imported file have already exceeded.',
					async (value: File) => {
						if (value) {
							const isExceeded: boolean = await new Promise((resolve, _) => {
								try {
									const reader = new FileReader();
									reader.onload = (e) => {
										if (e?.target?.result) {
											const value: string = e?.target?.result.toString();
											resolve(value.trim().split('\n').length > 200);
										}
									};
									value && reader.readAsText(value);
								} catch {
									console.log("Can't read the file");
									resolve(false);
								}
							});
							return !isExceeded;
						}
						return false;
					}
				),
		}),
});

export default createAdjustmentForm;
