import { isEmpty } from 'lodash';
import { isNumeric } from 'utils/common';
import yup, { selectDefaultRequiredTemplate } from '../common';
import { customErrorMessage } from './customErrorMessage';
const subsidizedOrPassOnValidation = ({ v, ctx, label, scheme }: any) => {
	const { createError } = ctx;
	const {
		scheme_type: schemeType,
		billing_type: serviceFeeBillingType = {},
		non_shareable_rate_type: nonShareableRateType,
		with_non_shareable_amount: withNonShareableAmount,
		tier_rate_type: tierRateType,
		special_rate_type: specialRateType,
		regular,
		tiers,
		percentage,
		special,
	} = ctx?.from[1].value;

	const isSelectedPercentage =
		nonShareableRateType === 'PERCENTAGE' ||
		schemeType === 'PERCENTAGE' ||
		tierRateType === 'PERCENTAGE' ||
		specialRateType === 'PERCENTAGE';
	const isFieldNonShareable = label.includes('Non-Shareable');

	// To compare if field being check contains nonshareable and if the selected nonshareable amount is true
	// (non shareable rates not required if non share amount is 0 or NO)
	if (isFieldNonShareable && withNonShareableAmount == '0') return true;

	const isFieldPercentage = () => {
		if (isFieldNonShareable && label.includes('Percentage')) return true;
		if (!isFieldNonShareable && label.includes('Rate')) return true;
		return false;
	};
	const fieldBillingType = label.includes('Pass-on')
		? 'PASS_ON'
		: label.includes('Subsidized')
		? 'SUBSIDIZED'
		: undefined;
	const isFieldAllowedChecked = fieldBillingType
		? serviceFeeBillingType[fieldBillingType]
		: true;
	const totalRateLabel = label.includes('Fixed') ? 'Fixed' : 'Percentage';

	// Filters - this is to avoid validation of hidden fields
	// To check if the field (pass on or subsidized) is allowed for validation (if value is selected)
	if (!isFieldAllowedChecked) return true;
	// To check if field's scheme is the same as the selected scheme type
	if (schemeType && schemeType !== scheme) return true;
	// To compare if the selected Rate is the same as the field's rate
	if (isSelectedPercentage !== isFieldPercentage()) return true;

	// All fields
	if (!v || v == '.00')
		return createError({
			message: customErrorMessage(`Input ${label}`, '10px'),
		}); // v==.00 is temporary fix

	//Fixed Rates only
	if (v && !isSelectedPercentage && v?.indexOf('-') == 0 && v != '-0')
		// Ask QA if it's better to just remove characters
		return createError({
			message: customErrorMessage('Input Positive Value', ''),
		});

	//Percentage Rates only
	if (
		v &&
		isSelectedPercentage &&
		(parseFloat(v?.replaceAll(',', '')) < 0 ||
			Math.floor(parseFloat(v?.replaceAll(',', '')) * 100) / 100 > 100)
	)
		return createError({
			message: customErrorMessage('Input value from 0-100', ''),
		});

	// GET subsidized and pass on values
	const isNonShareableLarger = () => {
		const subsidizedIndex = isSelectedPercentage
			? 'subsidized_rate'
			: 'subsidized_fee';
		const passOnIndex = isSelectedPercentage ? 'pass_on_rate' : 'pass_on_fee';
		const nonShareableIndex = isSelectedPercentage
			? 'non_share_percentage'
			: 'non_share_fixed_rate';
		const index = ctx?.path.substring(
			ctx?.path.indexOf('[') + 1,
			ctx?.path.lastIndexOf(']')
		);
		const isSchemeRegular = schemeType === 'REGULAR';
		const isSchemeTier = schemeType === 'TIER';
		const isSchemePercentage = schemeType === 'PERCENTAGE';
		const isSchemeSpecial = schemeType === 'SPECIAL';

		const feeParser = (fee: string) =>
			fee ? parseFloat(fee?.replaceAll(',', '')) : 0;

		const objectParser = (selectedScheme: string, ind: string) =>
			selectedScheme ? selectedScheme[ind ? ind : ''] : 0;

		const nonShareableValues = {
			regular: feeParser(objectParser(regular, nonShareableIndex)),
			tier: feeParser(objectParser(tiers[index], nonShareableIndex)),
			percentage: feeParser(objectParser(percentage, nonShareableIndex)),
			special: feeParser(objectParser(special[index], nonShareableIndex)),
		};
		const subsidizedValues = {
			regular: feeParser(objectParser(regular, subsidizedIndex)),
			tier: feeParser(objectParser(tiers[index], subsidizedIndex)),
			percentage: feeParser(objectParser(percentage, subsidizedIndex)),
			special: feeParser(objectParser(special[index], subsidizedIndex)),
		};
		const passOnValues = {
			regular: feeParser(objectParser(regular, passOnIndex)),
			tier: feeParser(objectParser(tiers[index], passOnIndex)),
			percentage: feeParser(objectParser(percentage, passOnIndex)),
			special: feeParser(objectParser(special[index], passOnIndex)),
		};

		if (isSchemeRegular) {
			return (
				nonShareableValues.regular >
				subsidizedValues.regular + passOnValues.regular
			);
		} else if (isSchemeTier) {
			return (
				nonShareableValues.tier > subsidizedValues.tier + passOnValues.tier
			);
		} else if (isSchemePercentage) {
			return (
				nonShareableValues.percentage >
				subsidizedValues.percentage + passOnValues.percentage
			);
		} else if (isSchemeSpecial) {
			return (
				nonShareableValues.special >
				subsidizedValues.special + passOnValues.special
			);
		}

		return false;
	};

	//Non-shareable rates only
	if (
		v &&
		isFieldNonShareable &&
		nonShareableRateType &&
		isNonShareableLarger()
	)
		return createError({
			message: customErrorMessage(
				`${label} should be less than or equal to Total ${totalRateLabel} Rate`,
				''
			),
		});

	return true;
};

const lowerUpperValidation = ({ v, ctx, label }: any) => {
	const { createError } = ctx;
	const { scheme_type: schemeType, tiers = [] } = ctx?.from[1].value;

	if (!v && schemeType === 'TIER')
		return createError({
			message: customErrorMessage(`Input ${label} Value`, '10px'),
		});

	if (v && schemeType === 'TIER') {
		if (!(Number.isInteger(parseFloat(v)) && parseFloat(v) >= 0))
			return createError({
				message: customErrorMessage('Input Whole Number Value', '10px'),
			}); // Only Positive Value
		if (!isNumeric(v))
			return createError({
				message: customErrorMessage('Input Valid Number', '10px'),
			}); // Only Valid Number

		const path = ctx.path.split('.')[1];
		const otherPath = path === 'upper_limit' ? 'lower_limit' : 'upper_limit';
		const index = ctx.path.substring(
			ctx.path.indexOf('[') + 1,
			ctx.path.lastIndexOf(']')
		);

		const duplicateValues = tiers.filter(
			(t, i) => t[path] == v || t[otherPath] == v
		);
		if (duplicateValues.length > 1)
			return createError({
				message: customErrorMessage(
					'Value should be unique in Lower and Upper Limit Columns',
					'9.5px'
				),
			});

		const tier = tiers[index];
		const uLimit = parseInt(tier['upper_limit']);
		const lLimit = parseInt(tier['lower_limit']);
		const isFieldLowerlimit = label === 'Lower Limit';

		if (uLimit && lLimit) {
			const limitError = uLimit <= lLimit;

			if (limitError && isFieldLowerlimit)
				return createError({
					message: customErrorMessage(
						'Value should be less than Upper Limit value',
						'10px'
					),
				});
			if (limitError && !isFieldLowerlimit)
				return createError({
					message: customErrorMessage(
						'Value should be greater than Lower Limit value',
						'10px'
					),
				});
		}
	}
	return true;
};

const regularSchema = yup
	.object()
	.nullable()
	.shape({
		subsidized_fee: yup
			.string()
			.nullable()
			.label('Subsidized Fee')
			.test('required', (v, ctx) =>
				subsidizedOrPassOnValidation({
					v,
					ctx,
					label: 'Subsidized Fee',
					scheme: 'REGULAR',
				})
			),
		subsidized_rate: yup
			.string()
			.nullable()
			.label('Subsidized Rate')
			.test('required', (v, ctx) =>
				subsidizedOrPassOnValidation({
					v,
					ctx,
					label: 'Subsidized Rate',
					scheme: 'REGULAR',
				})
			),
		pass_on_fee: yup
			.string()
			.nullable()
			.label('Pass-on Fee')
			.test('required', (v, ctx) =>
				subsidizedOrPassOnValidation({
					v,
					ctx,
					label: 'Pass-on Fee',
					scheme: 'REGULAR',
				})
			),
		pass_on_rate: yup
			.string()
			.nullable()
			.label('Pass-on Rate')
			.test('required', (v, ctx) =>
				subsidizedOrPassOnValidation({
					v,
					ctx,
					label: 'Pass-on Rate',
					scheme: 'REGULAR',
				})
			),
		non_share_fixed_rate: yup
			.string()
			.nullable()
			.label('Non-Shareable Fixed Rate')
			.test('required', (v, ctx) =>
				subsidizedOrPassOnValidation({
					v,
					ctx,
					label: 'Non-Shareable Fixed Rate',
					scheme: 'REGULAR',
				})
			),
		non_share_percentage: yup
			.string()
			.nullable()
			.label('Non-Shareable Percentage')
			.test('required', (v, ctx) =>
				subsidizedOrPassOnValidation({
					v,
					ctx,
					label: 'Non-Shareable Percentage',
					scheme: 'REGULAR',
				})
			),
	});

const tierSchema = yup
	.array()
	.nullable()
	.of(
		yup.object().shape({
			lower_limit: yup
				.string()
				.nullable()
				.label('Lower Limit')
				.test('lower-limit-validation', (v, ctx) =>
					lowerUpperValidation({
						v,
						ctx,
						label: 'Lower Limit',
						scheme: 'TIER',
					})
				),
			upper_limit: yup
				.string()
				.nullable()
				.label('Upper Limit')
				.test('upper-limit-validation', (v, ctx) =>
					lowerUpperValidation({
						v,
						ctx,
						label: 'Upper Limit',
						scheme: 'TIER',
					})
				),
			subsidized_fee: yup
				.string()
				.nullable()
				.label('Subsidized Fee')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Subsidized Fee',
						scheme: 'TIER',
					})
				),
			subsidized_rate: yup
				.string()
				.nullable()
				.label('Subsidized Rate')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Subsidized Rate',
						scheme: 'TIER',
					})
				),
			pass_on_fee: yup
				.string()
				.nullable()
				.label('Pass-on Fee')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Pass-on Fee',
						scheme: 'TIER',
					})
				),
			pass_on_rate: yup
				.string()
				.nullable()
				.label('Pass-on Rate')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Pass-on Rate',
						scheme: 'TIER',
					})
				),
			non_share_fixed_rate: yup
				.string()
				.nullable()
				.label('Non-Shareable Fixed Rate')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Non-Shareable Fixed Rate',
						scheme: 'TIER',
					})
				),
			non_share_percentage: yup
				.string()
				.nullable()
				.label('Non-Shareable Percentage')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Non-Shareable Percentage',
						scheme: 'TIER',
					})
				),
		})
	);

const percentageSchema = yup
	.object()
	.nullable()
	.shape({
		subsidized_rate: yup
			.string()
			.nullable()
			.label('Subsidized Rate')
			.test('required', (v, ctx) =>
				subsidizedOrPassOnValidation({
					v,
					ctx,
					label: 'Subsidized Rate',
					scheme: 'PERCENTAGE',
				})
			),
		pass_on_rate: yup
			.string()
			.nullable()
			.label('Pass-on Rate')
			.test('required', (v, ctx) =>
				subsidizedOrPassOnValidation({
					v,
					ctx,
					label: 'Pass-on Rate',
					scheme: 'PERCENTAGE',
				})
			),

		non_share_percentage: yup
			.string()
			.nullable()
			.label('Non-Shareable Percentage')
			.test('required', (v, ctx) =>
				subsidizedOrPassOnValidation({
					v,
					ctx,
					label: 'Non-Shareable Percentage',
					scheme: 'PERCENTAGE',
				})
			),
	});

const specialSchema = yup
	.array()
	.nullable()
	.of(
		yup.object().shape({
			subsidized_fee: yup
				.string()
				.nullable()
				.label('Subsidized Fee')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Subsidized Fee',
						scheme: 'SPECIAL',
					})
				),
			subsidized_rate: yup
				.string()
				.nullable()
				.label('Subsidized Rate')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Subsidized Rate',
						scheme: 'SPECIAL',
					})
				),
			pass_on_fee: yup
				.string()
				.nullable()
				.label('Pass-on Fee')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Pass-on Fee',
						scheme: 'SPECIAL',
					})
				),
			pass_on_rate: yup
				.string()
				.nullable()
				.label('Pass-on Rate')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Pass-on Rate',
						scheme: 'SPECIAL',
					})
				),
			non_share_fixed_rate: yup
				.string()
				.nullable()
				.label('Non-Shareable Fixed Rate')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Non-Shareable Fixed Rate',
						scheme: 'SPECIAL',
					})
				),
			non_share_percentage: yup
				.string()
				.nullable()
				.label('Non-Shareable Percentage')
				.test('required', (v, ctx) =>
					subsidizedOrPassOnValidation({
						v,
						ctx,
						label: 'Non-Shareable Percentage',
						scheme: 'SPECIAL',
					})
				),
		})
	);

const serviceFeeSchema = yup.object().shape({
	billing_type: yup
		.mixed()
		.nullable()
		.label('Service Fee Billing Type')
		.test(
			'required',
			'Select Service Fee Billing Type',
			(v: { PASS_ON: boolean; SUBSIDIZED: boolean }) => {
				if (isEmpty(v)) return true;
				if (v?.PASS_ON || v?.SUBSIDIZED) return true;
				return false;
			}
		),
	vat: yup
		.string()
		.nullable()
		.label('Value Added Tax')
		.required(selectDefaultRequiredTemplate),
	scheme_type: yup
		.string()
		.nullable()
		.label('Service Fee Scheme Type')
		.required(selectDefaultRequiredTemplate)
		.test('check-non-shareable', (v: any, ctx?: any) => {
			const { createError } = ctx;
			const { non_shareable_rate_type = '' } = ctx?.from[0].value;

			if (non_shareable_rate_type === 'FIXED_RATE' && v === 'PERCENTAGE')
				return createError({
					message: customErrorMessage(
						'Non-Shareable Rate Type should be Percentage for this scheme',
						''
					),
				});
			return true;
		}),

	with_non_shareable_amount: yup
		.string()
		.nullable()
		.label('With Non-Shareable Amount')
		.required(selectDefaultRequiredTemplate),
	non_shareable_rate_type: yup
		.string()
		.nullable()
		.label('Non-Shareable Rate Type'),
	tier_base_type: yup
		.string()
		.nullable()
		.label('Tier Base Type')
		.when('scheme_type', {
			is: 'TIER',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	tier_count: yup
		.string()
		.nullable()
		.label('number of tiers')
		.transform((v) => (v ? `${parseInt(v)}` : v))
		.when('scheme_type', {
			is: 'TIER',
			then: (s) =>
				s.required().test('whole', (v: any, ctx) => {
					const { createError } = ctx;

					if ((!isNumeric(v) || parseFloat(v) % 1 != 0) && !!v)
						return createError({ message: 'Input Valid Number' });
					if (parseInt(v) <= 0 || parseInt(v) > 50)
						return createError({ message: 'Input value from 1-50' });
					return true;
				}),
		}),
	tier_rate_type: yup
		.string()
		.nullable()
		.label('Tier Rate Type')
		.when(['scheme_type', 'tier_base_type'], {
			is: (s, t) => s === 'TIER',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	special_rate_type: yup
		.string()
		.nullable()
		.label('Special Rate Type')
		.when('scheme_type', {
			is: (s, t) => s === 'SPECIAL',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	with_sf_settle_deduction: yup
		.string()
		.nullable()
		.label('SF Settlement Deduction on Collections Setting')
		.required(selectDefaultRequiredTemplate),
	frequency_type: yup
		.string()
		.nullable()
		.label('Frequency Type')
		.when('with_sf_settle_deduction', {
			is: '1',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	frequency_day: yup
		.string()
		.nullable()
		.label('Day')
		.when(['frequency_type', 'with_sf_settle_deduction'], {
			is: (f, w) => (f === 'MONTHLY' || f === 'YEARLY') && w == '1',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	frequency_month: yup
		.string()
		.nullable()
		.label('Month')
		.when(['frequency_type', 'with_sf_settle_deduction'], {
			is: (f, w) => f === 'YEARLY' && w == '1',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	// TABLE SCHEMAS
	regular: regularSchema,
	tiers: tierSchema,
	percentage: percentageSchema,
	special: specialSchema,
});

export default serviceFeeSchema;
