import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '@salesforce/design-system-react/components/modal';
import { ReactComponent as SaveDraftIcon } from 'assets/icons/ic-save.svg';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import BranchForm from 'containers/ChannelManagement/Branch/BranchForm/BranchForm';
import { branchSchema } from 'containers/ChannelManagement/Branch/BranchForm/BranchSchema';
import SuccessModal from 'containers/ChannelManagement/Branch/BranchForm/SuccessModal/SuccessModal';
import { BranchType } from 'containers/ChannelManagement/Branch/BranchForm/types';
import {
	postForBranch,
	transformBranchFormToPayload,
} from 'containers/ChannelManagement/Branch/BranchForm/utils';
import { BRANCH_DEFAULT_VALUES } from 'containers/ChannelManagement/Branch/constants';
import {
	BranchModalProps,
	Status,
} from 'containers/ChannelManagement/Branch/types';
import ErrorModal from 'containers/ChannelManagement/Drafts/ErrorModal/ErrorModal';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FixMeLater } from 'types';
import { useToggle } from 'utils/hooks';
import styles from './BranchModal.module.css';

const BranchModal: React.FC<BranchModalProps> = ({ open, onClose }) => {
	const [isSaveAsDraft, setIsSaveAsDraft] = useState<boolean>(false);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const channelAccountId = useSelector(
		(state: FixMeLater) =>
			state.channels.channelFormValues.primaryInfo.id as number
	);

	const status = useRef<Status | ''>('');
	const confirmationHeader = useRef<string>('');
	const confirmationMessage = useRef<string>('');
	const confirmationCancelBtnLabel = useRef<string>('');
	const confirmationConfirmBtnLabel = useRef<string>('');
	const errorHeader = useRef<string>('');
	const errorMessage = useRef<string>('');
	const successMessage = useRef<string>('');
	const successOkayLabel = useRef<string>('');

	const branchForm = useForm<BranchType>({
		mode: 'all',
		defaultValues: BRANCH_DEFAULT_VALUES,
		resolver: yupResolver(branchSchema),
	});

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const {
		value: isShowErrorModal,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	useEffect(() => {
		if (isSaveAsDraft) {
			const saveAsDraft = async (data: BranchType) => {
				const branchName = branchForm.getValues(
					'branchBasicInformation.branchName'
				);

				if (!branchName) {
					errorHeader.current = 'Incomplete Branch Details';
					errorMessage.current = `Branch Name is required to save this as draft.
						Please input the Branch Name under the Branch Basic Information section.`;
					showErrorModal();
					return;
				}

				showLoading();
				try {
					await postForBranch(
						transformBranchFormToPayload(data, 'ACTIVE', channelAccountId),
						'drafts'
					);

					successMessage.current = `Your work was saved as draft. 
						You may continue your progress anytime by going 
						to Drafts and selecting the partner under the draft list.`;
					successOkayLabel.current = 'Done';
					showSuccessModal();
				} catch (error) {
					errorHeader.current = 'Timeout Error!';
					errorMessage.current =
						'A Problem occurred while saving as draft. Please try again.';
					showErrorModal();
				}
				hideLoading();
			};

			saveAsDraft(branchForm.getValues());
			setIsSaveAsDraft(false);
		}
	}, [
		branchForm,
		channelAccountId,
		hideLoading,
		isSaveAsDraft,
		showErrorModal,
		showLoading,
		showSuccessModal,
	]);

	useEffect(() => {
		if (isSubmit) {
			const submit = async (data: BranchType) => {
				showLoading();
				try {
					await postForBranch(
						transformBranchFormToPayload(data, 'ACTIVE', channelAccountId),
						'submit'
					);

					successMessage.current = 'Your have created a new branch.';
					successOkayLabel.current = 'Okay';
					showSuccessModal();
				} catch (error) {
					errorHeader.current = 'Timeout Error!';
					errorMessage.current =
						'A Problem occurred while adding the branch. Please try again.';
					showErrorModal();
				}
				hideLoading();
			};

			branchForm.handleSubmit((data) => {
				submit(data);
			})();

			setIsSubmit(false);
		}
	}, [
		branchForm,
		channelAccountId,
		hideLoading,
		isSubmit,
		showErrorModal,
		showLoading,
		showSuccessModal,
	]);

	const updateCurrentRefs = (
		currentStatus: Status,
		currentConfirmationHeader: string,
		currentConfirmationMessage: string,
		currentConfirmationCancelBtnLabel: string,
		currentConfirmationConfirmBtnLabel: string
	) => {
		status.current = currentStatus;
		confirmationHeader.current = currentConfirmationHeader;
		confirmationMessage.current = currentConfirmationMessage;
		confirmationCancelBtnLabel.current = currentConfirmationCancelBtnLabel;
		confirmationConfirmBtnLabel.current = currentConfirmationConfirmBtnLabel;
	};

	return (
		<>
			<Modal
				isOpen={open}
				onRequestClose={() => {
					updateCurrentRefs(
						'EXITING',
						'Are you done?',
						`You can continue your progress by saving this as draft,
						otherwise you may need to create a new registration.
						Would you like to save as draft?`,
						'No',
						'Yes, save as draft'
					);
					showConfirmationModal();

					// branchForm.reset();
					// onClose?.();
				}}
				headerClassName={styles.headerContainer}
				contentClassName={styles.modal}
				size="medium"
				heading={
					<div className={styles.header}>
						<div className={styles.titleLeftPanel}>
							<div className={styles.titleTextContainer}>
								{'Add New Branch'}
							</div>
						</div>
						<div className={styles.titleRightPanel}>
							<div className={styles.titleActionBtnContainer}>
								<OutlineButton
									onClick={() => {
										updateCurrentRefs(
											'DRAFTING',
											'Save as Draft',
											'Would you like to save this as draft?',
											'Back',
											'Yes'
										);
										showConfirmationModal();
									}}
									className={styles.btn}
								>
									<SaveDraftIcon className={styles.btnIcon} />
									Save as Draft
								</OutlineButton>
							</div>
							<div className={styles.titleActionTextContainer}>
								<em className={styles.titleActionText}>
									{'Last updated at ' + 'TODO'}
									{' by ' + 'TODO'}
								</em>
							</div>
						</div>
					</div>
				}
				footer={
					<div className={styles.footer}>
						<div className={styles.footerRightPanel}>
							<PrimaryButton
								onClick={() => {
									updateCurrentRefs(
										'SUBMITTING',
										'Add Branch',
										'Are you sure you want to add branch?',
										'Cancel',
										'Confirm'
									);
									showConfirmationModal();
								}}
								className={styles.btn}
							>
								Submit
							</PrimaryButton>
						</div>
					</div>
				}
			>
				<FormProvider {...branchForm}>
					<BranchForm />
				</FormProvider>
			</Modal>
			{isLoading && (
				<FullPageLoader open={isLoading} message={'Please wait...'} />
			)}
			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					heading={confirmationHeader.current}
					message={confirmationMessage.current}
					onClose={() => {
						hideConfirmationModal();
					}}
					onCancelBtnClick={() => {
						hideConfirmationModal();
						if (status.current === 'EXITING') {
							branchForm.reset();
							onClose?.();
						}
					}}
					onConfirmBtnClick={() => {
						hideConfirmationModal();

						if (status.current === 'DRAFTING' || status.current === 'EXITING') {
							setIsSaveAsDraft(true);
						}
						if (status.current === 'SUBMITTING') {
							setIsSubmit(true);
						}
					}}
					cancelBtnLabel={confirmationCancelBtnLabel.current}
					confirmBtnLabel={confirmationConfirmBtnLabel.current}
				/>
			)}
			{isShowErrorModal && (
				<ErrorModal
					open={isShowErrorModal}
					onClose={hideErrorModal}
					errorHeader={errorHeader.current}
					errorMessage={errorMessage.current}
					onRetry={() => {
						hideErrorModal();

						if (status.current === 'DRAFTING') {
							setIsSaveAsDraft(true);
						}
						if (status.current === 'SUBMITTING') {
							setIsSubmit(true);
						}
					}}
				/>
			)}
			{isSuccessModalOpen && (
				<SuccessModal
					open={isSuccessModalOpen}
					onClose={hideSuccessModal}
					successMessage={successMessage.current}
					onOkay={hideSuccessModal}
					okayLabel={successOkayLabel.current}
				></SuccessModal>
			)}
		</>
	);
};

export default BranchModal;
