import {
	Modal,
	Spinner,
} from '@salesforce/design-system-react/module/components';
import {
	closeModal,
	closeLoader,
	openLoader,
} from '../../redux/modules/walletReplenishment';
import styles from '../WalletReplenishmentModal/WalletReplenishmentModal.module.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import UploadSuccessModal, {
	UploadSuccessModalActions,
	UploadSuccessModalBody,
	UploadSuccessText,
	UploadSuccessSubText,
} from 'components/Modal/UploadSuccessModal';
import UploadSuccessErrorModal, {
	UploadSuccessErrorModalActions,
	UploadSuccessErrorModalBody,
	UploadSuccessErrorText,
	UploadSuccessErrorSubText,
} from 'components/Modal/UploadSuccessErrorModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useToggle } from 'utils/hooks';
import SelectField from 'components/Inputs/SelectField/SelectField';
import { yupResolver } from '@hookform/resolvers/yup';
import walletReplenishmentForm from 'utils/formSchemas/walletReplenishmentForm/walletReplenishmentForm';
import Grid from '../Grid/Grid';
import { SectionRow } from 'components/Section/Section';
import TextField, { UploadField } from 'components/Inputs/TextField/TextField';
import client from 'helpers/ApiClient';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import { limitInput } from 'components/Format/NumberFormatter';
import OutlineButton from '../Buttons/OutlineButton';
import AmountField from 'components/Inputs/TextField/AmountField';
import {
	TextFieldFormatter,
	truncateDecimal,
} from 'components/Inputs/TextField/TextFieldFormatter';
import { ReducerStateType } from 'redux/modules/reducers';
import { v1 as uuidv1 } from 'uuid';
import UploadAttachments from 'components/UploadAttachments/UploadAttachments';
import axios from 'axios';
import timeout, {
	cancelRequest,
	TimeoutSliceState,
} from 'redux/modules/timeout';

import { ON_CREATE_BATCH_WAL_REP } from '../../graphql/queries';
import { useSubscription } from '@apollo/client';
import Table from 'components/CWSTable/Table';
import { ReactComponent as Icon } from 'assets/icons/ic-scanning.svg';

type WalletReplenishmentFormData = any;

type WalletReplenishmentProps = {
	walletReplenishment: {
		showModal: boolean;
		data: {
			details: any;
			logs: any;
		};
		isLoading: boolean;
	};
};
type FooterProps = {
	handleSubmit: any;
	closeModal: any;
	resetUploadData: any;
	isLoading: boolean;
	isSubmitButtonDisabled: boolean;
	isUpload?: boolean;
	isUploading?: boolean;
	isCloseDisabled?: boolean;
};

const Footer: React.FC<FooterProps> = ({
	isLoading,
	handleSubmit,
	closeModal,
	isSubmitButtonDisabled,
	isUpload,
	isUploading,
	resetUploadData,
	isCloseDisabled,
}) => {
	return (
		<div className={styles.footer}>
			<PrimaryButton
				onClick={() => {
					closeModal();
					resetUploadData();
				}}
				className={styles.btn}
				disabled={isLoading || isCloseDisabled}
			>
				Cancel
			</PrimaryButton>
			<PrimaryButton
				onClick={handleSubmit}
				className={styles.btn}
				disabled={isSubmitButtonDisabled || isLoading}
			>
				{isUpload ? (
					isUploading ? (
						<div>
							Uploading{' '}
							<Spinner
								size="x-small"
								variant="base"
								assistiveText={{ label: 'Uploading...' }}
							/>
						</div>
					) : (
						'Upload'
					)
				) : (
					'Submit'
				)}
			</PrimaryButton>
		</div>
	);
};

type UserInfoProps = {
	userInfo: {
		firstname: string;
		lastname: string;
		username: string;
	};
};

type Props = {
	showModal: WalletReplenishmentProps['walletReplenishment']['showModal'];
	userInfo: UserInfoProps['userInfo'];
	isLoading: boolean;
	closeModal: () => void;
	closeLoader: () => void;
	selectedId?: any;
	openLoader: () => void;
	successSubmit: () => void;
	reloadTable: () => void;
};

export const generalErrorInfo = {
	text: 'Failed Adjustment Request',
	subtext: 'Something went wrong with your request. Would you like to retry?',
	type: 'GENERAL_ERROR',
};

export const scanningErrorInfo = {
	text: 'Scanning',
	subtext:
		'Attached file/s is still being scanned for virus.\nPlease retry in a few moment.',
	type: 'SCANNING_ERROR',
};

const WalletReplenishmentModal: React.FC<Props> = ({
	showModal,
	closeModal,
	isLoading,
	openLoader,
	closeLoader,
	userInfo,
	successSubmit,
	reloadTable,
}) => {
	const [uuid, setUuid] = useState();
	const [partnerNames, setPartnerNames] = useState<any[]>([]);
	const [walletIds, setWalletIds] = useState<any[]>([]);
	const [banks, setBanks] = useState([]);
	const [productWallets, setProductWallets] = useState<any[]>([]);
	const [isSelectLoading, setIsSelectLoading] = useState({
		partnerName: false,
		walletId: false,
	});
	const [fileList, setFileList] = useState([]);
	const [fileNamePresigned, setFileNamePresigned] = useState<string | null>(
		null
	);
	const [ackId, setAckId] = useState<string | null>(null);
	const [errorDupIds, setErrorDupIds] = useState<Array<any>>([]);

	const uploadCtrl = new AbortController();

	const cancelToken: any = axios.CancelToken.source();

	const [isUploadAttachmentReady, setIsUploadAttachmentReady] = useState(true);

	const [errorModalInfo, setErrorModalInfo] = useState<{
		text: string;
		subtext: string;
		type: string;
	}>({ text: '', subtext: '', type: '' });

	const {
		value: isSuccessModalOpen,
		valueOn: openSuccessModal,
		valueOff: closeSuccessModal,
	} = useToggle();

	const {
		value: isErrorShowing,
		valueOn: showErrorShowing,
		valueOff: hideErrorShowing,
	} = useToggle();

	const {
		value: isAttachmentError,
		valueOn: attachmentErrorTrue,
		valueOff: attachmentErrorFalse,
	} = useToggle();

	const {
		value: isUploading,
		valueOn: showUploading,
		valueOff: hideUploading,
	} = useToggle();

	const {
		value: isSuccessWithError,
		valueOn: showIsSuccessWithError,
		valueOff: hideIsSuccessWithError,
	} = useToggle();

	const {
		value: isSuccessUpload,
		valueOn: showIsSuccessUpload,
		valueOff: hideIsSuccessUpload,
	} = useToggle();
	const {
		value: isWarningDuplicate,
		valueOn: showIsWarningDuplicate,
		valueOff: hideIsWarningDuplicate,
	} = useToggle();

	const {
		value: isCloseDisabled,
		valueOn: disableClose,
		valueOff: enableClose,
	} = useToggle(false);

	const [batchTimeout, setBatchTimeout] = useState(setTimeout(() => {}));

	const scopes: any = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	);

	// const validateAttachments = () => {
	// 	if (fileList.length == 0) {
	// 		attachmentErrorTrue();
	// 	}
	// };

	useEffect(() => {
		if (fileList.length > 0) {
			attachmentErrorFalse();
		}
	}, [fileList]);

	const options = {
		variables: { ack_id: ackId },
		shouldResubscribe: true,
	};

	const { data, error } = useSubscription(ON_CREATE_BATCH_WAL_REP, options);

	useEffect(() => {
		if (data && data.onCreateBatchRequest.progress === 'DONE') {
			closeModal();
			hideUploading();

			if (
				data.onCreateBatchRequest.error_records_count === 0 &&
				data.onCreateBatchRequest.total_records > 0
			) {
				showIsSuccessUpload();
				return;
			}
			if (data.onCreateBatchRequest.error_details) showIsSuccessWithError();
		}
	}, [data, error]);

	const {
		control,
		handleSubmit,
		reset,
		clearErrors,
		watch,
		setValue,
		setError,
		formState: { isValid },
	} = useForm<WalletReplenishmentFormData>({
		mode: 'all',
		resolver: yupResolver(walletReplenishmentForm),
		context: {
			scopes: scopes,
		},
	});
	useEffect(() => {
		(async () => {
			const _walletType = watch('walletType');
			if (_walletType) {
				//reset values
				setValue('partnerName', '');
				setValue('walletId', '');

				let res;
				if (_walletType == 'cws') {
					setIsSelectLoading({
						...isSelectLoading,
						partnerName: true,
					});
					res = await client.get(`/v1/wallets/product-wallets`);
					setProductWallets(res.data.result);
					setPartnerNames(
						res.data.result.map((_pN) => ({ label: _pN.name, value: _pN.name }))
					);
					setIsSelectLoading({
						...isSelectLoading,
						partnerName: false,
					});
				} else {
					setIsSelectLoading({
						...isSelectLoading,
						partnerName: true,
					});
					res = await client.get(
						`/v1/cws/util/accounts?walletType=${_walletType}`
					);
					setPartnerNames(
						res.data.result.map((_pN) => ({ label: _pN.name, value: _pN.id }))
					);
					setIsSelectLoading({
						...isSelectLoading,
						partnerName: false,
					});
				}
			}
		})();
	}, [watch('walletType')]);

	useEffect(() => {
		(async () => {
			const _partnerId = watch('partnerName');
			const _walletType = watch('walletType');

			if (_walletType !== 'cws') {
				if (_partnerId) {
					setIsSelectLoading({
						...isSelectLoading,
						walletId: true,
					});
					const res = await client.get(
						`/v1/wallets/filter?channelId=${_partnerId}`
					);
					setWalletIds(
						res.data.result.map((_w) => ({
							label: _w.wallet_identifier,
							value: _w.wallet_identifier,
						}))
					);

					if (res.data.result.length === 1) {
						setValue('walletId', res.data.result[0].wallet_identifier);
					} else {
						setValue('walletId', '');
					}
					setIsSelectLoading({
						...isSelectLoading,
						walletId: false,
					});
				}
			} else {
				if (_partnerId) {
					if (productWallets) {
						const _defaultWalletId = productWallets
							.filter((wallet) => wallet.name == _partnerId)
							.map((_w) => ({
								label: _w.wallet_identifier,
								value: _w.wallet_identifier,
							}));

						setWalletIds(_defaultWalletId);
						if (_defaultWalletId.length == 1) {
							setValue('walletId', _defaultWalletId[0].value);
						} else {
							setValue('walletId', '');
							clearErrors('walletId');
						}
					}
				}
			}
		})();
	}, [watch('partnerName')]);

	const dispatch = useDispatch();
	const { isInProgress } = useSelector<ReducerStateType>(
		(state) => state.timeout
	) as TimeoutSliceState;

	const resetUploadData = () => {
		setFileNamePresigned(null);
		setAckId(null);
		cancelToken.cancel();
	};

	useEffect(() => {
		setFileNamePresigned(null);
		enableClose();

		if (!showModal) {
			clearTimeout(batchTimeout);
			setBatchTimeout(setTimeout(() => {}));
		}

		setUuid(uuidv1());
		(async () => {
			const res = await client.get(`/v1/cws/util/banks`);
			setBanks(res.data.result.map((_b) => ({ label: _b.name, value: _b.id })));
		})();

		setFileList([]);
		if (isInProgress) dispatch(cancelRequest());
		if (!showModal) {
			cancelToken.cancel();
			reset({});
			hideUploading();
			setErrorDupIds([]);
		}
	}, [showModal]);

	const replenishmentType = watch('replenishmentType');

	const onSubmit = async (values: any, validateDup = 1) => {
		if (replenishmentType === '0') {
			const amount: any = document.getElementById('walletAmount');
			const _walletReplenishment = {
				channel_id: values.partnerName,
				wallet_id: values.walletId,
				bank_id: values.bankName,
				bank_ref_number: values.bankReferenceNumber,
				amount: amount.value,
				remarks: values.replenishRemarks || '',
				child_acc_number: values.childAccountNumber || '',
				mode_of_payment: values.modeOfPayment,
				current_user: {
					userName: userInfo.username,
				},
				uuid: userInfo.username + uuid,
				attachments: fileList,
			};

			openLoader();
			const req = client.post(
				`/v2/wallet-replenishments`,
				_walletReplenishment
			);

			req
				.then(() => {
					closeLoader();
					reset();
					closeModal();
					hideErrorShowing();
					openSuccessModal();
				})
				.catch((err) => {
					closeLoader();
					showErrorShowing();
					if (
						axios.isAxiosError(err) &&
						err.response?.status === 400 &&
						err?.response?.data?.errors?.['SCAN_IN_PROGRESS']?.length > 0
					) {
						setErrorModalInfo(scanningErrorInfo);
						return;
					}
					setErrorModalInfo(generalErrorInfo);
				});
		}
		if (replenishmentType === '1' && values.replenishmentFile) {
			showUploading();
			let upload;

			if (!fileNamePresigned && validateDup) {
				upload = await client
					.get(
						`/v1/wallet-replenishments/batch-upload/url?fileName=${values.replenishmentFile.name}&contentType=${values.replenishmentFile.type}`,
						{ cancelToken: cancelToken.token }
					)
					.then(async (response) => {
						const presignedData = response.data.presigned_keys;
						if (response.data.presigned_keys.statusCode >= 400) {
							const errorBody = JSON.parse(response.data.presigned_keys.body);
							switch (errorBody.errorCode) {
								case 'GENERIC_ERROR':
									setError(
										'replenishmentFile',
										{
											type: 'manual',
											message: errorBody.message,
										},
										{ shouldFocus: true }
									);
									break;
							}
							return null;
						}
						const data = new FormData();
						Object.entries(presignedData.fields).forEach(
							([key, value]: any) => {
								data.append(key, value);
							}
						);
						data.append('file', values.replenishmentFile);
						return await axios
							.post(presignedData.url, data, {
								headers: {
									'x-bayad-platform-id': process.env.REACT_APP_APP_KEY,
								},
								cancelToken: cancelToken.token,
								...presignedData.fields,
							})
							.then((uploadRes) => {
								return { presignRes: response, uploadRes };
							})
							.catch((e) => {
								showErrorShowing();
								setErrorModalInfo(generalErrorInfo);
								return e;
							});
					})
					.catch((e) => {
						if ({ ...e.response }.status == 403) {
							hideUploading();
							return;
						}
						e.response;
					});

				if (isErrorShowing || !upload) {
					hideUploading();
					return;
				}

				if (
					{ ...upload.presignRes }.status === undefined &&
					!{ ...upload.presignRes }.data?.file_name
				) {
					showErrorShowing();
					setErrorModalInfo(generalErrorInfo);
				}

				if ({ ...upload.uploadRes }.status !== 204) {
					setError(
						'replenishmentFile',
						{
							type: 'manual',
							message: 'Failed to upload.',
						},
						{ shouldFocus: true }
					);
					return;
				}

				setFileNamePresigned(upload.presignRes.data.file_name);

				setBatchTimeout(
					setTimeout(() => {
						batchValidateDup(validateDup, upload);
					}, 28000)
				);
			} else {
				batchValidateDup(validateDup, upload);
			}
		}
	};

	const batchValidateDup = (validateDup, upload) => {
		disableClose();
		client
			.post(
				`/v1/wallet-replenishments/batch-upload${
					validateDup ? '?validateDup=true' : ''
				}`,
				{
					file_name: fileNamePresigned || upload.presignRes.data.file_name,
					validateDup,
					cancelToken: cancelToken.token,
				}
			)
			.then((res) => {
				setAckId(res.data.ack_id);
			})
			.catch((e) => {
				enableClose();
				hideUploading();
				console.log('Error Upload: ', { ...e });
				const response = { ...e.response };
				const reponseData = { ...response }.data;
				switch ({ ...reponseData }.errorCode) {
					case 'GENERIC_ERROR':
						setError(
							'replenishmentFile',
							{
								type: 'manual',
								message: e.response.data.message,
							},
							{ shouldFocus: true }
						);
						break;
					case 'SCAN_ERROR':
						setError(
							'replenishmentFile',
							{
								type: 'manual',
								message: 'Scanning in progress...',
							},
							{ shouldFocus: true }
						);
						break;
					case 'DUPLICATE_ERROR':
						setErrorDupIds({ ...reponseData }.errors.duplicate_wallets);
						showIsWarningDuplicate();
						break;
					case 'MAX_RECORDS_ERROR':
						setError(
							'replenishmentFile',
							{
								type: 'manual',
								message:
									'Maximum allowed of users exceeded. Only 200 users are allowed.',
							},
							{ shouldFocus: true }
						);
						break;
					case 'EMPTY_FILE_ERROR':
						setError(
							'replenishmentFile',
							{
								type: 'manual',
								message: 'The file is empty.',
							},
							{ shouldFocus: true }
						);
						break;
					case 'FILE_TYPE_ERROR':
						setError(
							'replenishmentFile',
							{
								type: 'manual',
								message: 'Invalid file type.',
							},
							{ shouldFocus: true }
						);
						break;
					default:
						showErrorShowing();
						setErrorModalInfo(generalErrorInfo);
				}
			});
	};

	const closeWindows = () => {
		clearDropdownData();
		closeModal();
	};

	const closeSuccess = () => {
		clearDropdownData();
		closeSuccessModal();
		successSubmit();
	};

	const closeSuccessWithError = () => {
		hideIsSuccessWithError();
		successSubmit();
	};

	const clearDropdownData = () => {
		setPartnerNames([]);
		setWalletIds([]);
	};

	useEffect(() => {
		return () => {
			clearErrors();
			setErrorDupIds([]);
			setFileNamePresigned(null);
		};
	}, []);

	useEffect(() => {
		clearErrors();
	}, [replenishmentType]);

	return (
		<>
			<Modal
				disableClose={isCloseDisabled}
				isOpen={showModal}
				onRequestClose={closeWindows}
				size="small"
				footer={
					<Footer
						isSubmitButtonDisabled={
							!isValid ||
							(!isUploadAttachmentReady && replenishmentType === '0')
						}
						handleSubmit={handleSubmit((v) => onSubmit(v))}
						closeModal={closeWindows}
						isLoading={isLoading}
						isUpload={replenishmentType === '1'}
						isUploading={isUploading}
						resetUploadData={resetUploadData}
						isCloseDisabled={isCloseDisabled}
					/>
				}
			>
				<div className={cx('slds-card', styles.container)}>
					<div className={styles.topSticky}>
						<h3 className={styles.header}>Add New Replenishment</h3>
						<RadioGroup
							label=""
							options={[
								{
									label: 'Single',
									value: '0',
								},
								{
									label: 'Batch',
									value: '1',
								},
							]}
							name="replenishmentType"
							control={control}
							isVertical={true}
							defaultValue={'0'}
						/>
					</div>
					<br />

					{/* For Batch Upload */}
					{replenishmentType === '1' ? (
						<div
							className={cx('slds-col slds-size_12-of-12', styles.walletInput)}
						>
							<SectionRow className={styles.walletRepUploadFieldContainer}>
								<UploadField
									link="/v1/wallet-replenishments/batch-upload/url"
									label="Upload File"
									name="replenishmentFile"
									helperText="You can batch import a max of 200 wallet replenishment requests per csv file using this feature."
									control={control}
									onChange={watch}
									setValue={setValue}
									clearErrors={clearErrors}
									fileFormats={['csv']}
									skipFileUpload
								/>
							</SectionRow>
						</div>
					) : (
						<>
							<div
								className={cx('slds-col slds-size_6-of-12', styles.walletInput)}
							>
								<SelectField
									label="Wallet Type"
									control={control}
									required
									placeholder="Select Wallet Type"
									name="walletType"
									options={[
										{ label: 'Channel Wallet - Prefunded', value: 'prefunded' },
										{ label: 'Channel Wallet - Bonded', value: 'bonded' },
										{ label: 'Product Wallet', value: 'cws' },
									]}
								/>
							</div>
							<div
								className={cx(
									'slds-col slds-size_12-of-12',
									styles.walletInput
								)}
							>
								<SelectField
									label="Partner Name"
									control={control}
									required
									placeholder="Select Partner Name"
									name="partnerName"
									options={[...partnerNames]}
									isLoading={isSelectLoading.partnerName}
								/>
							</div>
							<div
								className={cx(
									'slds-col slds-size_12-of-12',
									styles.walletInput
								)}
							>
								<SectionRow>
									<Grid column size={1} of={2}>
										<SelectField
											label="Wallet ID"
											control={control}
											required
											placeholder="Select Wallet ID"
											name="walletId"
											options={[...walletIds]}
											isLoading={isSelectLoading.walletId}
										/>
									</Grid>
									<Grid column size={1} of={2}>
										<SelectField
											label="Bank Name"
											control={control}
											required
											placeholder="Select Bank Name"
											name="bankName"
											options={[...banks]}
										/>
									</Grid>
								</SectionRow>
							</div>
							<div
								className={cx(
									'slds-col slds-size_12-of-12',
									styles.walletInput
								)}
							>
								<SectionRow>
									<Grid column size={1} of={2}>
										<TextField
											name="bankReferenceNumber"
											label="Bank Reference Number"
											control={control}
											required
											formatValue={(element) => {
												const max_chars = 255;
												return limitInput(element, max_chars);
											}}
										/>
									</Grid>
									<Grid column size={1} of={2}>
										<TextField
											name="childAccountNumber"
											label="Child Account Number"
											control={control}
											formatValue={(element) => {
												const max_chars = 255;
												return limitInput(element, max_chars);
											}}
										/>
									</Grid>
								</SectionRow>
								<SectionRow>
									<Grid column size={1} of={2}>
										<SelectField
											label="Mode of Payment"
											control={control}
											required
											placeholder="Select Mode of Payment"
											name="modeOfPayment"
											options={[
												{ label: 'Cash', value: 'cash' },
												{ label: 'Check Deposit', value: 'check' },
												{ label: 'Online Transfer', value: 'online_transfer' },
											]}
										/>
									</Grid>
								</SectionRow>
							</div>
							<div
								className={cx(
									'slds-col slds-size_12-of-12',
									styles.walletInput
								)}
							>
								<AmountField
									name="amount"
									control={control}
									label="Amount"
									id="walletAmount"
									placeholder="0.00"
									formatValue={TextFieldFormatter.amountFormat}
									required
								/>
							</div>
							<div
								className={cx(
									'slds-col slds-size_12-of-12',
									styles.walletInput
								)}
							>
								<UploadAttachments
									changeFileList={(val) => setFileList(val)}
									secondaryLabel="*For faster approval, kindly attach here proof or supporting documents
											for you request."
									displayError={isAttachmentError}
									uploadUrl="v1/wallet-replenishments/url"
									// withLoader
									setIsUploadAttachmentReady={setIsUploadAttachmentReady}
								/>
							</div>
							<div
								className={cx(
									'slds-col slds-size_12-of-12',
									styles.walletInput
								)}
							>
								<TextField
									name="replenishRemarks"
									label="Remarks"
									control={control}
									multiLine
									rows={5}
									formatValue={(element) => {
										const max_chars = 255;
										return limitInput(element, max_chars);
									}}
								/>
							</div>
						</>
					)}
				</div>
			</Modal>
			{/* Thank you! Success Modal */}
			<SuccessModal
				open={isSuccessModalOpen}
				onClose={closeSuccessModal}
				larger={true}
			>
				<SuccessModalBody>
					<SuccessText>
						<div className={styles.successHeader}>Thank you!</div>
						<div className={styles.subtext}>
							Your replenishment request has been received.
							<br />
							We will update you through email on the status of your request.
						</div>
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={closeSuccess}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			{/* Upload Success Modal */}
			{isSuccessWithError && (
				<UploadSuccessErrorModal
					open={isSuccessWithError}
					onClose={closeSuccessWithError}
					larger={true}
				>
					<UploadSuccessErrorModalBody>
						<UploadSuccessErrorText>
							<div className={styles.uploadSuccessErrorBody}>
								<div className={styles.uploadSuccessErrorText}>
									{data.onCreateBatchRequest.success_records_count} out of{' '}
									{data.onCreateBatchRequest.total_records} replenishments
									uploaded successfully!
								</div>
								<div
									className={styles.uploadSuccessErrorSubText}
									style={{ opacity: '0.9' }}
								>
									The following errors were found:
								</div>
								<br />
								<div className={styles.tErrorUpload}>
									<Table
										columns={[
											{
												Header: 'Row',
												id: 'row',
												Cell: ({ value }) => value,
												width: '30%',
											},
											{
												Header: 'Error Description 	',
												id: 'error',
												Cell: ({ value }) => value,
												width: '70%',
											},
										]}
										data={Object.entries(
											JSON.parse(data.onCreateBatchRequest.error_details)
										).map(([key, value]: any) => {
											return { row: key, error: value.join(', ') };
										})}
										count={10}
										pageSize={10}
										onPageChange={() => {}}
										onSort={() => {}}
										page={10}
										showPagination={false}
										preHeader={null}
										modalIsOpen={false}
									/>
								</div>
							</div>
						</UploadSuccessErrorText>
					</UploadSuccessErrorModalBody>
					<UploadSuccessErrorModalActions>
						<PrimaryButton
							className={styles.addressUploadErrorCloseBtn}
							onClick={() => {
								resetUploadData();
								closeSuccessWithError();
							}}
						>
							Close
						</PrimaryButton>
					</UploadSuccessErrorModalActions>
				</UploadSuccessErrorModal>
			)}
			{isWarningDuplicate && (
				<UploadSuccessModal
					open={!isSuccessWithError}
					onClose={hideIsSuccessWithError}
					larger={true}
				>
					<UploadSuccessModalBody>
						<SuccessText>
							<div
								className={styles.successHeader}
								style={{ textAlign: 'center' }}
							>
								Add New Replenishment
							</div>
							<hr
								style={{
									borderTop: '1px solid gray',
									opacity: '0.7',
									marginTop: '10px',
									marginBottom: '10px',
									width: '100%',
								}}
							/>
							<div
								className={styles.successText}
								style={{
									opacity: '0.8',
									textAlign: 'center',
									padding: '0.8vw 1.6vw 0.1vw 1.6vw',
								}}
							>
								You have a duplicate request under the listed wallet ID/s. Would
								you like to proceed with the existing upload or re-upload a new
								file?
							</div>
							<br />

							<div className={styles.tableErrorUpload}>
								<Table
									columns={[
										{
											Header: 'Wallet ID',
											id: 'walletIds',
											Cell: ({ value }) => value,
											width: '100%',
										},
									]}
									data={errorDupIds.map((id) => ({ walletIds: id }))}
									count={10}
									pageSize={10}
									onPageChange={() => {}}
									onSort={() => {}}
									page={10}
									showPagination={false}
									preHeader={null}
									modalIsOpen={false}
								/>
							</div>
						</SuccessText>
						<hr
							style={{
								borderTop: '1px solid gray',
								opacity: '0.7',
								marginTop: '25px',
								marginBottom: '-20px',
								width: '100%',
								padding: '1vw',
							}}
						/>
					</UploadSuccessModalBody>
					<UploadSuccessModalActions>
						<div
							style={{
								marginTop: '0',
								paddingBottom: '15px',
								paddingRight: '10px',
							}}
						>
							<PrimaryButton
								className={styles.outlineSuccessBtn}
								onClick={() => {
									setError(
										'replenishmentFile',
										{
											type: 'manual',
											message: 'Duplicate wallet found!',
										},
										{ shouldFocus: true }
									);
									resetUploadData();
									hideIsWarningDuplicate();
									hideUploading();
								}}
							>
								Re-Upload
							</PrimaryButton>
							<PrimaryButton
								className={styles.addressErrorCloseBtn}
								onClick={() => {
									handleSubmit((v) => onSubmit(v, 0))();
									hideIsWarningDuplicate();
								}}
							>
								Proceed
							</PrimaryButton>
						</div>
					</UploadSuccessModalActions>
				</UploadSuccessModal>
			)}
			{/* Success Modal */}
			{isSuccessUpload && (
				<SuccessModal
					open={isSuccessUpload}
					onClose={hideIsSuccessUpload}
					larger={true}
				>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>
								Success!
								<br />
								You've uploaded{' '}
								{data.onCreateBatchRequest.success_records_count} replenishments
							</div>
							<div className={styles.subtext}>
								Your request is subject for approval. We will notify you via
								email when your adjustment request is approved.
							</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.addressErrorCloseBtn}
							onClick={() => {
								reloadTable();
								hideIsSuccessUpload();
								resetUploadData();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{/* Error Modal */}
			<ErrorModal
				open={isErrorShowing}
				onClose={hideErrorShowing}
				Icon={
					errorModalInfo.type === scanningErrorInfo.type ? (
						<Icon className={styles.scanningIcon} />
					) : null
				}
			>
				<ErrorModalBody>
					<ErrorText>{errorModalInfo.text}</ErrorText>
					<ErrorSubText>
						<span
							style={{
								...(errorModalInfo.type === scanningErrorInfo.type && {
									whiteSpace: 'pre',
								}),
							}}
						>
							{errorModalInfo.subtext}
						</span>
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					{errorModalInfo.type === scanningErrorInfo.type ? (
						<PrimaryButton
							className={styles.walletAdjustmentErrorCloseBtn}
							onClick={hideErrorShowing}
						>
							Close
						</PrimaryButton>
					) : (
						<>
							<OutlineButton
								className={styles.contactDetailsErrorCloseBtn}
								onClick={() => {
									hideErrorShowing();
									closeModal();
								}}
								disabled={isLoading}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								className={styles.contactDetailsErrorCloseBtn}
								onClick={handleSubmit((v) => onSubmit(v))}
								disabled={isLoading}
							>
								Retry
							</PrimaryButton>
						</>
					)}
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default connect(
	(state: WalletReplenishmentProps & UserInfoProps) => ({
		showModal: state.walletReplenishment.showModal,
		details: state.walletReplenishment.data.details,
		isLoading: state.walletReplenishment.isLoading,
		userInfo: state.userInfo,
	}),
	{ closeModal, closeLoader, openLoader }
)(WalletReplenishmentModal);
