import * as yup from 'yup';
import {
	defaultInvalidCharacter,
	hasSpecialCharacter,
	selectDefaultRequiredTemplate,
} from 'utils/formSchemas/common';

import {
	CHANNEL_ACCOUNT_NAME,
	CHANNEL_ACCOUNT_CODE,
	BUSINESS_GROUP,
	PARTNER_SHIP_TYPE,
	CHANNEL_TRANSACTION_TYPE,
	CHANNEL_TYPE,
	POS_TYPE,
	SETTLEMENT_SETUP,
	BUSINESS_TYPE,
	OTHER_BUSINESS_TYPE,
	TIN,
	TIN_REGEX,
	TIN_MESSAGE,
	PARTNER_LOWER_CASE,
	OTHERS_LOWER_CASE,
	CUSTOMER_FRONTLINE_SOLUTIONS,
} from './const';
import { MAX_CHAR_255 } from 'containers/ChannelManagement/Channel/ChannelForm/const';
import { YupLab } from 'utils/yupLab';
import { ChannelContactDetailsSchema } from '../ChannelContactDetails/ChannelContactDetailsSchema';
import { ChannelAddressSchema } from '../ChannelAddress/ChannelAddressSchema';
import { ChannelOfficerSchema } from '../ChannelOfficer/ChannelOfficerSchema';

export const objectSchema = yup.object().shape({
	logo: yup.object().shape({
		objectKey: yup.string(),
	}),
	channelAccountName: yup
		.string()
		.label(CHANNEL_ACCOUNT_NAME)
		.required()
		.max(MAX_CHAR_255)
		.test('', defaultInvalidCharacter, (v?: string) => {
			if (v === undefined) {
				return false;
			}
			return hasSpecialCharacter(v);
		}),
	channelAccountCode: yup.string().label(CHANNEL_ACCOUNT_CODE),
	inCfs: yup
		.mixed()
		.label(CUSTOMER_FRONTLINE_SOLUTIONS)
		.required()
		.default('1'),
	partnershipType: yup
		.string()
		.label(BUSINESS_GROUP)
		.required()
		.max(MAX_CHAR_255),
	businessGroup: yup
		.string()
		.label(PARTNER_SHIP_TYPE)
		.required()
		.max(MAX_CHAR_255),
	channelTransactionType: yup
		.string()
		.label(CHANNEL_TRANSACTION_TYPE)
		.required(selectDefaultRequiredTemplate),
	channelType: yup.string().label(CHANNEL_TYPE).required(),
	posType: yup.string().label(POS_TYPE).required().max(MAX_CHAR_255),
	settlementSetup: yup
		.string()
		.label(SETTLEMENT_SETUP)
		.required()
		.max(MAX_CHAR_255),
	businessType: yup
		.string()
		.label(BUSINESS_TYPE)
		.when('channelType', {
			is: (channelType?: string) => {
				return (
					channelType && channelType.toLowerCase() === PARTNER_LOWER_CASE
				);
			},
			then: yup.string().required(selectDefaultRequiredTemplate),
		}),
	otherBusinessType: yup
		.string()
		.label(OTHER_BUSINESS_TYPE)
		.when('businessType', {
			is: (businessType?: string) => {
				return (
					businessType && businessType.toLowerCase() === OTHERS_LOWER_CASE
				);
			},
			then: yup
				.string()
				.required()
				.test('', defaultInvalidCharacter, (v: any) =>
					hasSpecialCharacter(v)
				),
		})
		.max(MAX_CHAR_255),
	tin: yup.string().label(TIN).required().matches(TIN_REGEX, {
		excludeEmptyString: true,
		message: TIN_MESSAGE,
	}),
	channelAddresses: ChannelAddressSchema.arraySchema,
	channelContactDetails: ChannelContactDetailsSchema,
	channelOfficer: ChannelOfficerSchema.schema,
});

export const ChannelBasicInformationSchema = new YupLab(
	objectSchema
);

export const channelAddressValues =
	ChannelBasicInformationSchema.schema.getDefault();
export type PrimartyInfoType =  yup.InferType<typeof objectSchema
>;
