import { AxiosResponse } from 'axios';
import {
	BranchAddressPayloadType,
	BranchAddressType,
	BranchContactDetailsPayloadType,
	BranchContactDetailsType,
	BranchOperatingScheduleType,
	BranchPayloadType,
	BranchScheduleType,
	BranchType,
} from 'containers/ChannelManagement/Branch/BranchForm/types';
import client from 'helpers/ApiClient';
import { FixMeLater } from 'types';

export const transformBranchAddressToPayload = (
	branchFormAddress: BranchAddressType
): BranchAddressPayloadType[] => {
	const branchAddressPayload: BranchAddressPayloadType = {
		locationBase: branchFormAddress.locationBase || '',
		countryId: Number(branchFormAddress.country),
		area: branchFormAddress.area || '',
		regionId: Number(branchFormAddress.region),
		provinceId: Number(branchFormAddress.stateOrProvince),
		cityId: Number(branchFormAddress.municipalityOrCity),
		barangayId: Number(branchFormAddress.barangay),
		buildingName: branchFormAddress.buildingNameOrNumber || '',
		street: branchFormAddress.street || '',
		zipCodeId: Number(branchFormAddress.zipCode),
	};
	return [branchAddressPayload];
};

export const transformBranchContactDetailsToPayload = (
	branchContactDetails: BranchContactDetailsType
): BranchContactDetailsPayloadType[] => {
	const branchContactDetailsPayload: BranchContactDetailsPayloadType[] = [];

	branchContactDetails?.map((item) => {
		const branchContactDetailsItem: BranchContactDetailsPayloadType = {
			name: item.name || '',
			position: item.position || '',
			department: item.department || '',
			emailAddress: item.emailAddress.map((email) => email || ''),
			telNo: item.telephoneNumber.map((tel) => tel || ''),
			mobileNo: item.mobileNumber.map((mobile) => mobile || ''),
		};
		branchContactDetailsPayload.push(branchContactDetailsItem);
	});

	return branchContactDetailsPayload;
};

export const transformOperatingScheduleToPayload = (
	branchOperatingSchedule: BranchOperatingScheduleType
): BranchScheduleType[] => {
	const branchSchedulePayload: BranchScheduleType[] = [];

	branchOperatingSchedule.schedule
		?.filter((item) => item.isSelected)
		.map((item) => {
			const branchScheduleItem: BranchScheduleType = {
				days: item.day || '',
				startTime: item.startTime || '',
				endTime: item.endTime || '',
			};
			branchSchedulePayload.push(branchScheduleItem);
		});
	return branchSchedulePayload;
};

export const transformBranchFormToPayload = (
	branchFormValues: BranchType,
	status: string,
	channelAccountId: number
): BranchPayloadType => {
	const result: BranchPayloadType = {
		primaryInfo: {
			branchName: branchFormValues.branchBasicInformation.branchName || '',
			accountId: channelAccountId,
			status: status || '',
			pldtCode: branchFormValues.branchBasicInformation.pldtTellerCode || '',
			cignalCode:
				branchFormValues.branchBasicInformation.cignalTellerCode || '',
			storeFormat: branchFormValues.branchBasicInformation.storeFormat || '',
			storeType: branchFormValues.branchBasicInformation.storeType || '',
			posType: branchFormValues.branchBasicInformation.posType || '',
			machineLocation:
				branchFormValues.branchBasicInformation.machineLocation || '',
			depositConsolidation:
				branchFormValues.branchOperatingSchedule.depositConsolidation || '',
			taxPayerType: branchFormValues.branchOperatingSchedule.taxPayerType || '',
			meralcoPayment_center_id:
				branchFormValues.branchBasicInformation.meralcoPaymentCenterCode || '',
			remarks: branchFormValues.branchOperatingSchedule.remarks || '',
			activationDate:
				branchFormValues.branchOperatingSchedule.activationDate || '',
			branchAddresses: transformBranchAddressToPayload(
				branchFormValues.branchAddress
			),
			branchContactDetails: transformBranchContactDetailsToPayload(
				branchFormValues.branchContactDetails
			),
			branchOperatingSchedule: transformOperatingScheduleToPayload(
				branchFormValues.branchOperatingSchedule
			),
		},
	};
	return result;
};

export const postForBranch = async (
	primaryInfo: BranchPayloadType,
	draftsOrSubmit: 'drafts' | 'submit'
): Promise<AxiosResponse<FixMeLater>> => {
	const fetchResult = await client.post(
		`/v2/branches/${draftsOrSubmit}`,
		primaryInfo
	);
	return fetchResult;
};
