export const allowedFileTypes: string[] = [
	'doc',
	'docx',
	'pdf',
	'jpg',
	'jpeg',
	'png',
];

type ContentType = {
	doc: string;
	docx: string;
	pdf: string;
	jpg: string;
	jpeg: string;
	png: string;
};

export const contentTypes: ContentType = {
	doc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	pdf: 'application/pdf',
	jpg: 'image/jpeg',
	jpeg: 'image/jpeg',
	png: 'image/png',
};

export const checkFileType: (File) => boolean = (file: File) => {
	return allowedFileTypes.indexOf(getFileType(file)) == -1 ? false : true;
};

export const getFileType: (File) => string = (file: File) => {
	return file.name?.split('.').slice(-1)[0];
};

export const getContentType: (File) => string = (file: File) => {
	const fileType = getFileType(file);
	return contentTypes[fileType];
};
