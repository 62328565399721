import { React, Component } from "react";
import styles from "./SessionTimeoutModal.module.css";
import { refresh } from "../../utils/sessionTimeout";
import { logOut } from "../../utils/logout";
import {
  Modal,
  Button,
} from "@salesforce/design-system-react/module/components";

class SessionTimeoutModal extends Component {
  constructor(props) {
    super(props);
    this.handleKeepMeLoggedIn = this.handleKeepMeLoggedIn.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      remainingTime: 60,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState(
        {
          remainingTime: this.state.remainingTime - 1,
        },
        () => {
          if (this.state.remainingTime === 0) {
            this.logout(true);
          }
        }
      );
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleKeepMeLoggedIn() {
    refresh();
    window.resetTimeout = true;
    this.props.toggleModal();
  }

  logout(isExpired) {
    logOut(isExpired);
    this.props.toggleModal();
  }

  render() {
    const content = (
      <section>
        <div className="slds-m-around_medium">
          <h5 className={styles.sessionHeader}>
            Your session is about to expire!
          </h5>
          <div className={styles.sessionText}>
            You will be logged out in{" "}
            <span style={{ color: "#F26122" }}>{this.state.remainingTime}</span>{" "}
            seconds.
            <br /> Do you want to stay logged in?
          </div>
        </div>
      </section>
    );

    return (
      <Modal
        disableClose
        footer={[
          <Button
            label="Yes, Keep Me Logged In"
            className={styles.sessionButton}
            onClick={this.handleKeepMeLoggedIn}
          />,
          <Button
            label="No, Logout"
            className={styles.sessionButton}
            onClick={() => this.logout(false)}
          />,
        ]}
        prompt="error"
        isOpen={true}
      >
        {content}
      </Modal>
    );
  }
}

export default SessionTimeoutModal;
