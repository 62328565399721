import Grid, { TGrid } from 'components/Grid/Grid';
import cx from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './Section.module.css';

type Props = {
	title?: string | ReactNode;
	className?: string;
	titleClassName?: string;
	children?: ReactNode;
};

export const SectionTitle: React.FC = ({ children }) => {
	return <div className={styles.title}>{children}</div>;
};

const Section: React.FC<Props> = ({
	title: Title,
	className,
	titleClassName,
	children,
}) => {
	return (
		<div className={cx(styles.container + ' ' + (className && className))}>
			{Title && (
				<div className={titleClassName ? titleClassName : styles.title}>
					{typeof Title === 'function' ? <Title /> : Title}
				</div>
			)}
			<div>{children}</div>
		</div>
	);
};

export const SectionRow: React.FC<TGrid> = ({
	children,
	className = '',
	...rest
}) => (
	<Grid
		container
		gutters
		verticalAlign="start"
		className={cx(styles.row, className)}
		{...rest}
	>
		{children}
	</Grid>
);

export default Section;
