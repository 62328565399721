import React, {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	getProducts,
	getCategories,
	selectPartner,
	CategoriesPayloadTypes,
	clearFilter,
	clearProducts,
} from '../../redux/modules/products';
import { connect, useDispatch, useSelector } from 'react-redux';
import PartnerScrollableList from './PartnerScrollableList';
import PrimaryCardPartner from 'components/Cards/PrimaryCard/PrimaryCardPartner';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { showAccessDeniedModal } from 'redux/modules/access';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useToggle } from 'utils/hooks';

type Props = {
	addNewPartnerButtonClick: () => void;
	products: Array<CategoriesPayloadTypes>;
	getProducts?: any;
	selectPartner: any;
	getCategories: () => void;
	categories?: Array<any>;
	clearFilter: () => void;
	isFormEdited?: boolean;
	IsDisabled?: boolean;
};

const PartnerList: React.FC<Props> = ({
	addNewPartnerButtonClick,
	products,
	getProducts: load,
	selectPartner: onSelectProduct,
	getCategories,
	categories,
	clearFilter,
	isFormEdited,
	IsDisabled,
}) => {
	const dispatch = useDispatch();

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);

	const hasUserPermission = useHasUserPermission('products');

	const { hasPartnerListPermission, hasPartnerViewPrimaryInfoPermission } =
		useMemo(() => {
			return {
				hasPartnerListPermission: !productCategory
					? true
					: hasUserPermission(productCategory, 'list'),
				hasPartnerViewPrimaryInfoPermission: !productCategory
					? true
					: hasUserPermission(productCategory, 'view.primary-info'),
			};
		}, [hasUserPermission, productCategory]);

	const onLoadPartnerList = useCallback(
		(payload?: { category?: string; search?: string; status?: string }) => {
			const { category, search, status } = payload || {};

			if (hasPartnerListPermission) {
				load({
					category,
					search,
					status,
				});
			} else {
				dispatch(showAccessDeniedModal());
			}
		},
		[dispatch, hasPartnerListPermission, load]
	);

	const scrollableListProps = useMemo(
		() => ({
			primaryButtonOnClick: addNewPartnerButtonClick,
			primaryButtonTitle: 'Add New Partner',
			listTitle: 'Partner List',
			dropdownOptions: categories || [],
			dropdownPlaceholder: 'Industry',
			dropdownOnSelect: (value: any, search: any, statusItemChecked: any) => {
				onLoadPartnerList({
					category: value,
					status: statusItemChecked.join('|'),
					search: search,
				});
			},
			dropdownOnClick: () => {
				onLoadPartnerList({ category: '' });
			},
			searchFieldOnChange: (
				value: any,
				statusItemChecked: any,
				categoriesItemChecked: any
			) => {
				onLoadPartnerList({
					search: value,
					status: statusItemChecked.join('|'),
					category: categoriesItemChecked.join('|'),
				});
			},
			filterByCategories: (v: string) => {
				onLoadPartnerList({ category: v });
			},
			filterByStatuses: (
				v: string,
				search: any,
				categoriesItemChecked: any
			) => {
				onLoadPartnerList({
					status: v,
					search: search,
					category: categoriesItemChecked.join('|'),
				});
			},
			clearFilter: (search: string) => {
				clearFilter();
				onLoadPartnerList({ search: search });
			},
			searchPlaceholder: 'Search Partner Name',
			hasPartnerListPermission,
		}),
		[
			addNewPartnerButtonClick,
			categories,
			clearFilter,
			hasPartnerListPermission,
			onLoadPartnerList,
		]
	);

	useEffect(() => {
		dispatch(clearProducts());
		getCategories();
	}, [dispatch, getCategories]);

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const [confirmationHeader, setConfirmationHeader] = useState<
		ReactNode | string
	>('');
	const [confirmationMessage, setConfirmationMessage] = useState<ReactNode>('');
	const [onConfirmationClose, setOnConfirmationClose] = useState({
		action: () => {},
	});
	const [confirmBtnOnClick, setConfirmBtnOnClick] = useState({
		action: () => {},
	});

	const showConfirmationMessage = (
		header: any,
		message: any,
		onConfirmBtnClick?: () => void,
		onClose?: () => void
	) => {
		setConfirmationHeader(header);
		setConfirmationMessage(message);
		setConfirmBtnOnClick({
			action: () => {
				onConfirmBtnClick && onConfirmBtnClick();
			},
		});
		setOnConfirmationClose({
			action: () => {
				onClose && onClose();
			},
		});
		showConfirmationModal();
	};

	return (
		<PartnerScrollableList {...scrollableListProps}>
			{products.map((product: any, index: number) => (
				<PrimaryCardPartner
					key={index}
					name={product.name}
					logo={product.logo_url}
					status={product.status}
					isSelected={product.isSelected}
					onClick={() => {
						if (!IsDisabled && isFormEdited) {
							showConfirmationMessage(
								'Disregard Changes?',
								<>
									<div>Are you sure you want to Exit without saving?</div>
									<div> Any unsaved work will be lost.</div>{' '}
								</>,

								() => {
									onSelectProduct({ selected: product.name });
									hideConfirmationModal();
								},
								() => {
									hideConfirmationModal();
								}
							);
							return;
						} else if (!IsDisabled && !isFormEdited) {
							showConfirmationMessage(
								'Exit Edit Mode?',
								<>
									<div>Are you sure you want to exit Edit Mode?</div>
								</>,

								() => {
									onSelectProduct({ selected: product.name });
									hideConfirmationModal();
								},
								() => {
									hideConfirmationModal();
								}
							);
							return;
						}
						if (!hasPartnerViewPrimaryInfoPermission) {
							dispatch(showAccessDeniedModal());
						}
						onSelectProduct({ selected: product.name });
					}}
				/>
			))}

			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					// isDisabled={isLoading}
					heading={confirmationHeader}
					message={confirmationMessage}
					onClose={hideConfirmationModal}
					onCancelBtnClick={() => {
						onConfirmationClose.action();
					}}
					onConfirmBtnClick={() => {
						confirmBtnOnClick.action();
					}}
				/>
			)}
		</PartnerScrollableList>
	);
};

export default connect(
	(state: any) => ({
		products: state.products.productList,
		categories: state.products.categories,
		IsDisabled: state.form.status.isDisabled,
		isFormEdited:
			state.products.primaryInfo?.isDirty ||
			state.products.contractDetails?.isDirty ||
			state.products.reports?.isDirty ||
			state.products.serviceFeeSettings?.isDirty ||
			state.products.businessRules?.isDirty,
	}),
	{ getProducts, selectPartner, getCategories, clearFilter }
)(PartnerList);
